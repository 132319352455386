import React, {useState} from 'react'

import {MAX_FILE_UPLOAD_SIZE} from '@posh/types'
import {CommunityCategory} from 'apis/Community'
import {uploadImage} from 'apis/Util/useUploadImage'
import {PictureState} from 'components/form/ImagePicker'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'

import {Input} from '../steps'

interface AviSelectorProps {
  activeStep: Input
  saveAndAdvanceStep: (
    {
      value,
    }: {
      value?: string
    },
    input: Input,
  ) => void
  inputValues: {[key: string]: string}
  setInputValues: (inputValues: {[key: string]: string}) => void
  communityCategory: CommunityCategory
}

const AviSelector = (props: AviSelectorProps) => {
  const {activeStep, saveAndAdvanceStep, inputValues, setInputValues, communityCategory} = props
  const [aviFile, setAviFile] = useState<PictureState | null>(null)
  const {showToast} = useToast()

  const handleAviOnChange = (icon: File) => {
    if (!icon) return null

    if (icon.size > MAX_FILE_UPLOAD_SIZE) {
      showToast({type: 'error', title: 'Image too large, must be under 8MB'})
      return null
    }
    const imageUrl = URL.createObjectURL(icon)
    setAviFile({file: icon, url: imageUrl})
    return {icon, url: imageUrl}
  }

  const handleAviOnSubmit = async () => {
    if (!aviFile?.file) return saveAndAdvanceStep({}, activeStep)
    const uploadedImage = await uploadImage(aviFile.file)
    communityCategory === 'venue'
      ? setInputValues({...inputValues, coverImage: uploadedImage})
      : setInputValues({...inputValues, avi: uploadedImage})
    if (uploadedImage) saveAndAdvanceStep({value: uploadedImage}, activeStep as Input)
  }

  return (
    <div className='CommunityInput CommunityInput--Avi'>
      <div className={`AddUserContainer`}>
        <PoshImage
          className={`AddUserContainer${aviFile?.url ? '-AddedUser' : '-AddUser'}`}
          src={aviFile?.url ? aviFile.url : 'https://images.posh.vip/b2/add-user+1.png'}
        />
      </div>
      <label className='Upload'>
        <input
          className='CommunityProfilePicture-input'
          type='file'
          accept='image/*'
          multiple={false}
          onChange={event => {
            handleAviOnChange(event.target.files![0])
          }}
        />
        <PoshImage className='Upload-Img' src='https://images.posh.vip/b2/upload.png' />
        Upload Image
      </label>
      {activeStep && (
        <div className='CommunityNextStepBtn'>
          <PoshImage src='https://images.posh.vip/b2/next.svg' onClick={() => handleAviOnSubmit()} />
        </div>
      )}
    </div>
  )
}

export default AviSelector
