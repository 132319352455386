import React, {CSSProperties} from 'react'

import Color from 'color'

import styles from './TableRowLabel.module.scss'

interface TableRowLabelProps {
  text: string
  color: Color
}

const getColoredContainerStyle = (color: Color): CSSProperties => {
  const lightenedColor = color.lighten(0.75)
  return {
    border: `1px solid ${color.alpha(0.8).toString()}`,
    backgroundColor: `${color.alpha(0.1).toString()}`,
    boxShadow: `0px 0px 4px 0px ${color.alpha(0.25).toString()}`,
    color: `${lightenedColor.alpha(0.9).toString()}`,
  }
}

export const TableRowLabel = (props: TableRowLabelProps) => {
  const {text, color} = props

  const containerColorStyle = getColoredContainerStyle(color)

  return (
    <div className={styles.RowLabel} style={containerColorStyle}>
      {text}
    </div>
  )
}
