import React, {useState} from 'react'

import Input from 'components/form/Input'
import {TextFieldVariants} from 'components/form/TextField'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

export type TextInputCheckoutProps = {
  field_key: string
  value?: string
  field_type: 'input'
  variant?: TextFieldVariants
  placeholder: string
  isCustomField?: boolean
  nativeProps: React.InputHTMLAttributes<HTMLInputElement>
}

export const TextInputCheckout = (props: TextInputCheckoutProps) => {
  const {value: initialValue, field_key, isCustomField, placeholder, nativeProps} = props
  const {updateCartFormField, updateCheckoutResponses, checkoutResponses} = useLiveCartContext()
  const [value, setValue] = useState<string>(initialValue ?? '')

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (!isCustomField) {
      updateCartFormField(field_key, e.target.value)
    }
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (isCustomField) {
      const newCheckoutResponses = checkoutResponses?.map(response => {
        if (response.fieldId.toString() === field_key) {
          return {...response, answer: e.target.value}
        }
        return response
      })
      updateCheckoutResponses(newCheckoutResponses)
    }
  }
  return <Input placeholder={placeholder} value={value} onChange={onChange} onBlur={onBlur} {...nativeProps} />
}
