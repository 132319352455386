import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CommunityArtistRes, StaffMemberRes, VenueRes} from '.'
export type FetchCommunityEntityResponse = VenueRes | CommunityArtistRes | StaffMemberRes

async function fetchCommunityEntity(): Promise<FetchCommunityEntityResponse> {
  const response = await PoshApi.fetchCommunityEntity()
  return response.data
}

export default function useFetchCommunityEntity() {
  return useQuery(['fetchCommunityEntities'], () => fetchCommunityEntity(), {
    refetchOnMount: false,
  })
}
