import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CheckPhoneCodeParams} from '.'

export interface CheckSignupCodeResponse {
  token: string
}

export async function checkSignUpCode(params: CheckPhoneCodeParams): Promise<CheckSignupCodeResponse> {
  const response = await PoshApi.checkSignUpCode(params)
  return response.data
}

export function useCheckSignupCode() {
  return useMutation<CheckSignupCodeResponse, any, CheckPhoneCodeParams>(params => checkSignUpCode(params))
}
