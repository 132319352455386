import React, {PropsWithChildren} from 'react'
import {FieldValues, UseFormReturn} from 'react-hook-form'

import {FormBase, FormBaseProps} from '../FormBase/FormBase'
import {CheckboxGroup} from '../Inputs/CheckboxGroup/CheckboxGroup'
import {NumberInput} from '../Inputs/NumberInput/NumberInput'
import {RadioGroup} from '../Inputs/RadioGroup/RadioGroup'
import {Select} from '../Inputs/Select/Select'
import {InputLabel} from '../Inputs/shared/InputLabel/InputLabel'
import {Switch} from '../Inputs/Switch/Switch'
import {TextInput} from '../Inputs/TextInput/TextInput'
import {ToggleableInput} from '../Inputs/ToggleableInput/ToggleableInput'
import {DynamicFormConfig} from '../internals/types'

import styles from './Form.module.scss'

interface FormProps<T extends FieldValues> extends Omit<FormBaseProps<T>, 'children'> {
  inputConfig: DynamicFormConfig<T>[]
}

/**
 * The Form component is a wrapper around FormBase that provides a common layout for forms.
 * WARNING: Please don't include any personal information/data when using the persistToStorage feature.
 *
 * @param props.children A function that receives the form object and returns the form elements.
 * @param props.defaultValues The default values for the form.
 * @param props.schema The schema for the form.
 * @param props.onSubmit The function to call when the form is submitted.
 * @param props.onCancel The function to call when the form is canceled.
 * @param props.isSubmitting Whether the form is submitting.
 * @param props.submitText The text to display on the submit button.
 * @param props.warnUnsavedChanges Whether to warn the user if they try to close the tab or refresh the tab with unsaved changes.
 * @param props.persistToStorage An object containing the key and storage to persist the form to.
 */
export const Form = <T extends FieldValues>(props: FormProps<T>) => {
  const {inputConfig, ...formBaseProps} = props

  return (
    <FormBase {...formBaseProps}>
      {form => (
        <>
          {inputConfig.map(input => {
            if ('toggleable' in input && input.toggleable) {
              const {enableText, disableText} = input.toggleable
              return (
                <FormInputRow key={input.key} label={input.label} subLabel={input.subLabel}>
                  {/**
                   * eslint-disable-next-line @typescript-eslint/ban-ts-comment
                   * @ts-ignore */}
                  <ToggleableInput form={form} name={input.key} offLabel={disableText} onLabel={enableText}>
                    {getInputComponent(input, form)}
                  </ToggleableInput>
                </FormInputRow>
              )
            }
            return (
              <FormInputRow key={input.key} label={input.label} subLabel={input.subLabel}>
                {getInputComponent(input, form)}
              </FormInputRow>
            )
          })}
        </>
      )}
    </FormBase>
  )
}

export const FormInputRow = ({label, subLabel, children}: PropsWithChildren<{label?: string; subLabel?: string}>) => {
  return (
    <div className={styles.FormInputRow}>
      {label && <InputLabel label={label} subtitle={subLabel} />}
      {children}
    </div>
  )
}

const getInputComponent = <T extends FieldValues>(inputConfig: DynamicFormConfig<T>, form: UseFormReturn<T>) => {
  switch (inputConfig.type) {
    case 'text': {
      const {type: _textType, ...textInputProps} = inputConfig
      return <TextInput.Controlled {...textInputProps} name={inputConfig.key} control={form.control} />
    }
    case 'number': {
      const {type: _numberType, ...numberInputProps} = inputConfig
      return <NumberInput.Controlled {...numberInputProps} name={inputConfig.key} control={form.control} />
    }
    case 'radio': {
      const {type: _radioType, options} = inputConfig
      return <RadioGroup.Controlled name={inputConfig.key} control={form.control} options={options} />
    }
    case 'select': {
      const {type: _selectType, options} = inputConfig
      return <Select.Controlled name={inputConfig.key} control={form.control} options={options} />
    }
    case 'checkbox': {
      const {type: _checkboxType, options} = inputConfig
      return <CheckboxGroup.Controlled name={inputConfig.key} control={form.control} options={options} />
    }
    case 'switch': {
      const {type: _switchType, ...switchInputProps} = inputConfig
      return <Switch.Controlled {...switchInputProps} name={inputConfig.key} control={form.control} />
    }
    case 'custom': {
      const {render} = inputConfig
      return render(form)
    }
    default:
      return null
  }
}

Form.Custom = FormBase
