import React, {useState} from 'react'

import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

export type CheckInputCheckoutProps = {
  field_key: string
  value?: string
  field_type: 'checkbox'
}

export const CheckInputCheckout = (props: CheckInputCheckoutProps) => {
  const {value: initialValue, field_key} = props
  const {updateCartFormField} = useLiveCartContext()
  const [value, setValue] = useState<boolean>(initialValue === 'true')

  const onChange = () => {
    updateCartFormField(field_key, (!value).toString())
    setValue(!value)
  }
  return <input type='checkbox' onClick={onChange} checked={value} />
}
