import React, {useState} from 'react'

import {MAX_TICKET_QUANTITY} from '@posh/model-types'
import {CurrencyCode} from '@posh/types'
import {ArrowWithDirection} from 'components/assets/Icons'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import {EventVisualsButton} from 'components/PageComponents/EventVisuals/Button/Button'
import {EventVisualsCircleButton} from 'components/PageComponents/EventVisuals/Button/CircleButton'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {CreateEventForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'

import TicketActionRow from '../../../../../../EventPage/TicketActionRow'
import {TicketsContainer} from '../../../../../../EventPage/TicketsContainer'
import {useEventVisualsContext} from '../../context/EventVisualsContext'
import {AdmissionSettingsModal} from './AdmissionSettingsModal'
import {OpenTicketEditorInterface} from './TicketEditorPanel/TicketEditorPanel'

interface TicketsAdmissionSectionProps extends OpenTicketEditorInterface {
  tickets: NonNullable<CreateEventForm['tickets']>
  setTickets: (tickets: CreateEventForm['tickets']) => void
  currencyCode: CurrencyCode
}

const formatTicketsToTicketEventPage = (tickets: NonNullable<CreateEventForm['tickets']>) => {
  return tickets.map((ticket, index) => {
    const onSale = ticket.onSaleUtc ?? new Date().toString()
    const endSale = ticket.endSaleUtc ?? new Date().toString()
    const onSaleUtc = ticket.onSaleUtc ?? new Date().toString()
    const endSaleUtc = ticket.endSaleUtc ?? new Date().toString()
    const closed = ticket.closed ?? false
    const description = ticket.description ?? undefined
    const password = ticket.password ?? undefined
    const purchaseLimit = ticket.purchaseLimit ?? undefined
    const purchaseMin = ticket.purchaseMin ?? undefined
    const price = ticket.price ?? 0
    const quantityAvailable = ticket.quantityAvailable ?? MAX_TICKET_QUANTITY
    const id = ticket.id ?? index.toString()
    return {
      ...ticket,
      onSale,
      endSale,
      onSaleUtc,
      endSaleUtc,
      purchaseLimit,
      purchaseMin,
      id,
      quantityAvailable,
      price,
      totalPrice: price,
      closed,
      description,
      password,
      position: index,
    }
  })
}

const TICKETS_BACKGROUND_COLOR = '#101113'

export const TicketAdmissionSection = (props: TicketsAdmissionSectionProps) => {
  const {tickets, setTickets, currencyCode, openTicketEditor} = props
  const {
    palette: {buttonTextColor, lightmode, accentColor},
  } = useEventVisualsContext()

  const formattedTickets = formatTicketsToTicketEventPage(tickets)

  const [isRSVPWarningVisible, setIsRSVPWarningVisible] = useState(false)
  const [admissionSettingsModalIsOpen, setAdmissionSettingsModalIsOpen] = useState(false)

  const openAdmissionSettingsModal = () => {
    setAdmissionSettingsModalIsOpen(true)
  }

  const closeAdmissionSettingsModal = () => {
    setAdmissionSettingsModalIsOpen(false)
  }

  const switchToRSVP = () => {
    if (isRSVPWarningVisible) {
      setTickets([{name: 'RSVP', price: 0, quantityAvailable: MAX_TICKET_QUANTITY}])
      setIsRSVPWarningVisible(false)
    } else setIsRSVPWarningVisible(true)
    closeAdmissionSettingsModal()
  }

  return (
    <>
      <EventVisualsSection
        headerText='Tickets'
        rightIcon={
          <EventVisualsButton onClick={openAdmissionSettingsModal}>
            <ArrowWithDirection color={buttonTextColor} direction='northEast' />
            Edit Tickets
          </EventVisualsButton>
        }
        underlined>
        <TicketsContainer
          lightmode={lightmode}
          accentColor={accentColor}
          style={{width: '100%', margin: '30 0', backgroundColor: TICKETS_BACKGROUND_COLOR}}>
          {formattedTickets.map((ticket, index) => (
            <TicketActionRow
              key={index}
              ticket={ticket}
              index={index}
              currency={currencyCode}
              lightmode={lightmode}
              accentColor={accentColor}
              customActionButton={
                <EventVisualsCircleButton
                  // Open ticket editor with the non-formatted ticket so we dont include default dummy data in the editor
                  onClick={() => openTicketEditor(tickets[index], index)}
                  accentColor={accentColor}
                  lightMode={lightmode}
                  iconType='pencil'
                  size={12}
                  style={{padding: 8, height: 'auto'}}
                />
              }
            />
          ))}
        </TicketsContainer>
      </EventVisualsSection>
      <AdmissionSettingsModal
        isOpen={admissionSettingsModalIsOpen}
        onClose={closeAdmissionSettingsModal}
        tickets={tickets}
        setTickets={setTickets}
        currencyCode={currencyCode}
        switchToRSVP={switchToRSVP}
        openTicketEditor={openTicketEditor}
      />
      <ConfirmationModal
        isOpen={isRSVPWarningVisible}
        onClose={() => setIsRSVPWarningVisible(false)}
        title='Are you sure you want to switch to RSVP?'
        bodyText='Switching to RSVP will remove all tickets and set the event to RSVP only.'
        onClick={switchToRSVP}
        confirmButtonText='Switch to RSVP'
      />
    </>
  )
}
