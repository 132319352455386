import React from 'react'

import Button, {ButtonProps} from 'components/form/Button'
import {useEventVisualsContext} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'

type EventVisualsButtonProps = ButtonProps

export const EventVisualsButton = (props: EventVisualsButtonProps) => {
  const {children, ...rest} = props
  const {
    palette: {accentColor, buttonTextColor},
  } = useEventVisualsContext()

  return (
    <Button
      {...rest}
      style={{
        backgroundColor: accentColor,
        color: buttonTextColor,
      }}>
      {children}
    </Button>
  )
}
