import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {GroupDispute} from '.'

export async function fetchGroupDisputes(groupId: string): Promise<GroupDispute[]> {
  const response = await PoshApi.fetchGroupDisputes(groupId)
  const data = response.data
  return data.disputes
}

export function useFetchGroupDisputes(groupId: string) {
  return useQuery(['groupDisputes', groupId], () => fetchGroupDisputes(groupId))
}
