import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface AddToMailChimpListParams {
  listId: string
  attendees: string[]
  groupId: string
  chunkId: string
  eventId?: string
}
export interface AddToMailChimpListResponse {
  created: number
  updated: number
  errors: number
  listLink: string
}

async function addToMailChimpList(params: AddToMailChimpListParams): Promise<AddToMailChimpListResponse> {
  const response = await PoshApi.addToMailChimpList(params)
  return response.data
}

export default function useAddToMailChimpList() {
  return useMutation<AddToMailChimpListResponse, unknown, AddToMailChimpListParams>(params =>
    addToMailChimpList(params),
  )
}
