import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {TicketLink} from './types'

export interface RemoveTicketLinkParams {
  parentTicketId: string
  eventId: string
}

export interface RemoveTicketLinkResponse {
  removed: TicketLink
}

async function removeTicketLink(data: RemoveTicketLinkParams): Promise<RemoveTicketLinkResponse> {
  const response = await PoshApi.removeTicketLink(data)
  return response.data
}

export function useRemoveTicketLink(opts?: UseMutationOptions<RemoveTicketLinkResponse, any, RemoveTicketLinkParams>) {
  return useMutation((params: RemoveTicketLinkParams) => removeTicketLink(params), opts)
}
