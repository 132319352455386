import {useParams} from 'react-router-dom'

import {zobjectId} from '@posh/types'
import {useToast} from 'components/toasts/ToastProvider'
import {z} from 'zod'

import {useNavigateToCreateEvent} from '../CreateEvent/useNavigateToCreateEvent'

export type PageParams = Record<'domain' | 'page', string>
export type GroupSettingsPageParams = Record<'category', string>
export type ResourcePageParams = Record<
  'groupId' | 'eventId' | 'eventSection' | 'domain' | 'page' | 'ticketGroupId',
  string
>
export type EventManagementPageParams = Record<'eventId' | 'groupId', string>

export type AffiliatePageParams = Record<'affiliateId', string>
const PendingEventPageParamsSchema = z.object({
  pendingEventId: zobjectId,
  groupId: z.string().optional(),
})
export type PendingEventPageParams = z.infer<typeof PendingEventPageParamsSchema>

export function usePageParams() {
  return useParams<PageParams>()
}

export function useResourcePageParams() {
  return useParams<ResourcePageParams>()
}

export function useAffiliatePageParams() {
  return useParams<AffiliatePageParams>()
}

export function useGroupSettingsPageParams() {
  return useParams<GroupSettingsPageParams>()
}

export function useEventManagementPageParams() {
  const params = useParams<EventManagementPageParams>()
  if (!params.eventId || !params.groupId) {
    throw new Error('Invalid parameters')
  }
  const {eventId, groupId} = params
  return {eventId, groupId}
}

export function usePendingEventPageParams() {
  const {showToast} = useToast()
  const {navigateToCreateEvent} = useNavigateToCreateEvent()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const groupId = urlSearchParams.get('g') ?? undefined
  const urlSlugs = useParams<ResourcePageParams>()
  let pendingEventParams: PendingEventPageParams
  try {
    pendingEventParams = PendingEventPageParamsSchema.parse({...urlSlugs, groupId})
  } catch (e) {
    showToast({
      type: 'error',
      title: 'Invalid parameters please try again',
      subtitle: 'Pending event id is missing from the url',
    })
    navigateToCreateEvent()
    return {
      pendingEventId: undefined,
      groupId: undefined,
    }
  }
  return pendingEventParams
}
