import {useMutation} from '@tanstack/react-query'
import {LongLat} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

import {GalleryImage, Tag} from '.'

export interface UpdateStaffMember {
  id: string
  bio?: string
  website?: string
  email?: string
  hourlyRate?: number
  yearsOfExperience?: number
  avi?: string
  firstName?: string
  lastName?: string
  location?: string
  coordinates?: LongLat
  staffType?: string
  tags?: Tag[]
  galleryImages?: GalleryImage[]
  instagram?: string
  minimumPrice?: number
}

async function updateStaffMember(memberData: UpdateStaffMember) {
  const response = await PoshApi.updateStaffMember(memberData)
  return response.data
}

export default function useUpdateStaffMember() {
  return useMutation((params: UpdateStaffMember) => updateStaffMember(params))
}
