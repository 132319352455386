import {useMutation} from '@tanstack/react-query'
import {TableSeatMap} from 'apis/Events/types'

import PoshApi from '../PoshApi'

export interface ScanTableParams {
  tableId: string
  eventId: string
  tableSeatMap: TableSeatMap
}

async function scanTable(tableId: string, eventId: string, tableSeatMap: TableSeatMap): Promise<Response> {
  const response = await PoshApi.scanTable(tableId, eventId, tableSeatMap)
  const data = response.data
  return data
}
export default function useScanTable() {
  return useMutation((params: ScanTableParams) => scanTable(params.tableId, params.eventId, params.tableSeatMap))
}
