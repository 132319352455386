import React from 'react'
import {useNavigate} from 'react-router-dom'

import {FollowNotificationMetadata} from '@posh/types'
import {PoshImage} from 'components/PoshImage/PoshImage'

export const FollowedByNotification = (props: FollowNotificationMetadata) => {
  const {followerAvi, followerName, followerVerified, followerUsername} = props
  const navigate = useNavigate()

  const onClickProfilePic = () => {
    navigate(`/p/${followerUsername}`)
  }

  return (
    <div className='Notification-content'>
      <PoshImage src={followerAvi} onClick={followerUsername ? onClickProfilePic : undefined} id='profilePic' />
      <p className='text-small' style={{flexGrow: 1}}>
        {followerUsername ? <a href={`/p/${followerUsername}`}>{followerName}</a> : followerName}
        {followerVerified && (
          <span>
            <PoshImage id='verifiedBadge' src='https://images.posh.vip/b2/verify.png' />
          </span>
        )}{' '}
        just followed you. {followerUsername && <a href={`/p/${followerUsername}`}>Click to view their profile.</a>}
      </p>
    </div>
  )
}
