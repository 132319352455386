import React from 'react'

import './styles.scss'

interface MatchAllInputProps {
  matchAll: boolean
  setMatchAll: React.Dispatch<React.SetStateAction<boolean>>
}

const MatchAllInput = (props: MatchAllInputProps) => {
  const {matchAll, setMatchAll} = props
  return (
    <div className='MatchAllInput'>
      <label>Match All</label>
      <input
        className='AccountSettings-checkbox'
        type='checkbox'
        checked={matchAll}
        onChange={() => setMatchAll(!matchAll)}
      />
    </div>
  )
}

export default MatchAllInput
