import React from 'react'

const PoshLock = ({fill}: {fill: string}) => {
  return (
    <svg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_228_11)'>
        <path
          d='M56.25 27H54V18C54 8.07275 45.9272 0 36 0C26.0728 0 18 8.07275 18 18V27H15.75C12.03 27 9 30.0267 9 33.75V65.25C9 68.9733 12.03 72 15.75 72H56.25C59.97 72 63 68.9733 63 65.25V33.75C63 30.0267 59.97 27 56.25 27ZM24.0002 18C24.0002 11.3818 29.3818 6.00018 36 6.00018C42.6182 6.00018 47.9998 11.3818 47.9998 18V27H24.0002V18Z'
          fill={fill}
        />
        <g clipPath='url(#clip1_228_11)'>
          <path
            d='M29.8079 42.0544L23.4772 59.1772L41.512 56.0834L29.8079 42.0544Z'
            fill={fill != 'white' ? 'white' : '#0a0b0d'}
          />
          <path
            d='M26.7493 56.0998L34.1807 36L47.9197 52.468L26.7493 56.0998ZM34.798 39.5731L29.5436 53.7813L44.5092 51.2139L34.798 39.5731Z'
            fill={fill != 'white' ? 'white' : '#0a0b0d'}
          />
          <path d='M31.0699 64L50 55.0036L22 61.102L31.0699 64Z' fill={fill != 'white' ? 'white' : '#0a0b0d'} />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_228_11'>
          <rect width='72' height='72' fill={fill} />
        </clipPath>
        <clipPath id='clip1_228_11'>
          <rect width='28' height='28' fill={fill} transform='translate(22 36)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PoshLock
