import {useQuery} from '@tanstack/react-query'
import {AccountModelPublic, GuestlistPublicProfile} from 'apis/Accounts'
import {Group} from 'apis/Groups'
import PoshApi from 'apis/PoshApi'

import {EventLineUp, EventModel, EventPublic, GroupPublic} from './types'

export interface FetchEventResponse {
  event: EventModel
  group: Group
  accounts: AccountModelPublic[]
  guestlistProfiles?: GuestlistPublicProfile[]
  lineup?: EventLineUp[] | null
  venue?: any
  tableFilterKeys?: string[]
}

export interface FetchEventResponsePublic {
  event: EventPublic
  group: GroupPublic
  guestlistProfiles?: GuestlistPublicProfile[]
  lineup?: EventLineUp[] | null
  venue?: any
  hostName?: string
}

async function fetchEvent(eventId: string): Promise<FetchEventResponse> {
  const response = await PoshApi.fetchEvent(eventId)
  return response.data
}

async function fetchEventByUrl(eventUrl?: string): Promise<FetchEventResponsePublic | null> {
  if (!eventUrl) return null
  const response = await PoshApi.fetchEventByUrl(eventUrl)
  return response.data
}

async function fetchEventByHexUrl(hexUrl?: string): Promise<EventPublic | null> {
  if (!hexUrl) return null
  const response = await PoshApi.fetchEventByHexUrl(hexUrl)
  return response.data
}

export default function useFetchEvent(eventId: string) {
  return useQuery<FetchEventResponse>(['event', eventId], () => fetchEvent(eventId), {
    refetchOnMount: false,
    enabled: !!eventId,
  })
}

export function useFetchEventByUrl(eventUrl?: string) {
  return useQuery<FetchEventResponsePublic | null>(['event', eventUrl], () => fetchEventByUrl(eventUrl))
}

export function useFetchEventByHexUrl(hexUrl?: string) {
  return useQuery<EventPublic | null>(['event', hexUrl], () => fetchEventByHexUrl(hexUrl), {
    refetchOnMount: false,
  })
}
