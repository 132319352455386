import React, {useState} from 'react'

import {NavigationItem} from '../components/shared/NavigationItem'

import styles from './styles.module.scss'

type Selected = 'explore' | 'organizations' | 'orders' | 'account' | 'create-event'

export function PrimaryNavigationMobile() {
  const [selected, setSelected] = useState<Selected>('explore')
  return (
    <div className={styles.PrimaryNavigation}>
      <NavigationItem.Logo.Mobile />
      <NavigationItem.Page.Mobile.Organizations />
      <NavigationItem.CreateEvent.Mobile />
      <NavigationItem.Page.Mobile.Orders />
      <NavigationItem.Account.Mobile
        avi='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50'
        onClick={() => setSelected('account')}
        isSelected={selected === 'account'}
      />
    </div>
  )
}
