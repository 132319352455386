import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {PromoCode} from './types'

export interface UpdatePromoCodeParams {
  eventId: string
  promoCodeId: string
  updates: Partial<Omit<PromoCode, '_id'>>
}

export interface UpdatePromoCodeResponse {
  updated: PromoCode
}

async function updatePromoCode(data: UpdatePromoCodeParams): Promise<UpdatePromoCodeResponse> {
  const response = await PoshApi.updatePromoCode(data)
  return response.data
}
export function useUpdatePromoCode(opts?: UseMutationOptions<UpdatePromoCodeResponse, any, UpdatePromoCodeParams>) {
  return useMutation((params: UpdatePromoCodeParams) => updatePromoCode(params), opts)
}
