import React from 'react'
import {Helmet} from 'react-helmet'

import {getGroupAvi} from '@posh/model-types'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useResourcePageParams} from 'pages/PoshAppLayout'

export function GroupHelmet() {
  const {groupId} = useResourcePageParams()
  const {data: group} = useFetchGroup(groupId!)
  return (
    <Helmet>
      <title>{`Overview - ${group?.name}`}</title>
      <meta property='og:title' content={`Overview - ${group?.name}`} />
      <meta property='og:description' content={`Overview - ${group?.name}`} />
      <meta property='og:image' content={`Overview - ${getGroupAvi(group)}`} />
      <meta property='og:url' content={`https://posh.vip`} />
      <meta property='twitter:title' content={`Overview - ${getGroupAvi(group)}`} />
      <meta property='twitter:description' content={`Overview - ${getGroupAvi(group)}`} />
      <meta property='twitter:image' content={`Overview - ${getGroupAvi(group)}`} />
      <meta property='twitter:card' content='summary_large_image' />
      <meta name='robots' content='noindex, nofollow' />
    </Helmet>
  )
}
