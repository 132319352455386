import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
import {RoleKey} from 'apis/Roles/Role'

import useSessionContext from '../../domains/Auth/SessionContext'
import {GraphInterval} from '.'

interface GraphData {
  labels: string[]
  revenue: number[]
  tickets: number[]
}

export interface GroupGraphResponse {
  totalRevenue: number
  totalTickets: number
  graphData: GraphData
}

export interface GroupAnalyticsProps {
  groupId: string
  roleKey: RoleKey
  graphInterval: GraphInterval
  hostId?: string
  trackingLink?: string
}

export async function getGroupGraphData(props: GroupAnalyticsProps): Promise<GroupGraphResponse> {
  const response = await PoshApi.fetchGroupGraphData(props)
  return response.data
}

export function useFetchGroupGraph(props: GroupAnalyticsProps) {
  const {userId} = useSessionContext()
  if (!userId) throw 'User is not authenticated'
  return useQuery(['groupGraph', props.groupId, props.graphInterval], () => getGroupGraphData(props))
}
