import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchKickBackAmountOptions = ReactQueryOptions['kickbacks']['getEventKickbackAmount']
type FetchKickbackAmountInput = RouterInputs['kickbacks']['getEventKickbackAmount']
export type FetchKickbackAmountResponse = RouterOutput['kickbacks']['getEventKickbackAmount']

export default function useFetchEventKickbackAmount(
  input: FetchKickbackAmountInput,
  options?: FetchKickBackAmountOptions,
) {
  return trpc.kickbacks.getEventKickbackAmount.useQuery(input, {
    ...options,
    enabled: !!input.eventId,
  })
}
