import React, {useState} from 'react'
import {useParams} from 'react-router-dom'

import {useFetchCampaignAnalytics} from 'apis/AdminDashboard/campaigns/useFetchCampaignAnalytics'
import LineGraph from 'components/charts/LineGraph'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import DetailedModal from './DetailedModal'
import NumberItem from './NumberItem'

const Analytics = () => {
  const {linkId} = useParams()
  const {data, isLoading} = useFetchCampaignAnalytics(linkId)
  const [selectedDetail, setSelectedDetail] = useState<string>()

  const summaryData = data?.summaryAnalytics
  const graphData = data?.graphAnalytics

  const navigate = selectedDetail
    ? () => {
        setSelectedDetail(undefined)
      }
    : undefined

  return (
    <>
      <BackAndTitle title={'Campaign Link Analytics'} onNavigate={navigate} />
      {isLoading && <SpinLoader height={40} />}
      <div className='AdminDashboard-analytics-topContainer'>
        {selectedDetail && linkId ? (
          <DetailedModal campaignId={linkId} detailedSummaryClassProperty={selectedDetail} />
        ) : (
          <>
            {Object.keys(summaryData ?? {}).map(title => {
              const items = summaryData ? summaryData[title] : []
              return (
                <>
                  <div className='AdminDashboard-analytics-largeText'>{title}</div>
                  <div className='AdminDashboard-analytics-sectionContainer'>
                    {items.map(item => (
                      <NumberItem
                        key={item.title}
                        value={item.value}
                        title={item.title}
                        iconUrl={item.iconUrl}
                        shouldShowDetailIcon={!!item.detailedSummary}
                        onClick={() => setSelectedDetail(item.detailedSummary)}
                      />
                    ))}
                  </div>
                </>
              )
            })}
            <div className='AdminDashboard-analytics-sectionContainer'>
              {graphData?.map(item => (
                <div key={item.title} style={{height: 400}}>
                  <div className='AdminDashboard-analytics-largeText'>{item.title}</div>
                  <LineGraph
                    canvasStyle={{height: '270px'}}
                    labels={item.labels}
                    data={item.data}
                    responsive={true}
                    displayPrompt={false}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Analytics
