import React, {PropsWithChildren, useEffect, useMemo} from 'react'

import {useGetAvailableFeatures} from '../../apis/Util/useGetAvailableFeatures'
import useSessionContext from '../Auth/SessionContext'
import {AvailableFeatureContext, AvailableFeaturesContext} from './AvailableFeaturesContext'

export function AvailableFeaturesProvider(props: PropsWithChildren) {
  const {children} = props
  const {currentUser} = useSessionContext()
  const {data, isLoading, refetch} = useGetAvailableFeatures()
  const availableFeatures = data?.availableFeatures

  useEffect(() => {
    refetch()
  }, [currentUser])

  const contextState = useMemo<AvailableFeatureContext>(() => {
    return {
      availableFeatures,
      isLoading,
    }
  }, [availableFeatures, isLoading])

  return <AvailableFeaturesContext.Provider value={contextState}>{children}</AvailableFeaturesContext.Provider>
}
