import React from 'react'

import {EventResponse} from 'apis/Accounts/useFetchAllAttendeeOrdersAndEvents'
import {EventPublic} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {downloadCalendar} from 'helpers/downloadCalendarEvent'

const AddToCalendarModal = ({
  event,
  addCalendarModalOpen,
  setAddCalendarModalOpen,
}: {
  event: EventPublic | EventResponse
  addCalendarModalOpen: boolean
  setAddCalendarModalOpen: (value: boolean) => void
}) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const description = `${
    event.shortDescription && event.shortDescription?.length > 0 ? event.shortDescription : event.description ?? ''
  }%0A%0AMore details: ${`https://posh.vip/e/${event.url}`}`

  const calendarStartTime = new Date(event.start as string).toISOString().replace(/[-:]/g, '').replace(/\..+/, '')
  const calendarEndTime = new Date(event.end as string).toISOString().replace(/[-:]/g, '').replace(/\..+/, '')

  const googleCalendarShareLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${
    event.name
  }&dates=${calendarStartTime}/${calendarEndTime}&details=${description}${
    event.venue?.address ? `&location=${event.venue.address}` : ''
  }&sf=true&output=xml`

  const outlookCalendarShareLink = `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&startdt=${calendarStartTime}&enddt=${calendarEndTime}&subject=${
    event.name
  }${event.venue?.address ? `&location=${event.venue.address}` : ''}&body=${description}`
  return (
    <PoshStyledModal isOpen={addCalendarModalOpen} onClose={() => setAddCalendarModalOpen(false)} center noborder>
      <h3>Add to Calendar</h3>
      <div className='buttonWrapper column' style={{padding: '20px 0'}}>
        <a
          href={googleCalendarShareLink}
          onClick={() => {
            trackMixpanelEvent('Google Calendar-  Add to calendar modal')
          }}
          target='_blank'
          rel='noreferrer'>
          <Button>Google Calendar</Button>
        </a>
        <a
          onClick={() => {
            trackMixpanelEvent('Apple Calendar -  Add to calendar modal')
            downloadCalendar(event)
          }}
          target='_blank'
          rel='noreferrer'>
          <Button>Apple Calendar</Button>
        </a>
        <a
          href={outlookCalendarShareLink}
          onClick={() => {
            trackMixpanelEvent('Outlook Calendar -  Add to calendar modal')
          }}
          target='_blank'
          rel='noreferrer'>
          <Button>Outlook Calendar</Button>
        </a>
      </div>
    </PoshStyledModal>
  )
}

export default AddToCalendarModal
