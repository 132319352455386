import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CommunityCategory} from '.'

type FilterType = 'select' | 'checkbox' | 'range'
export interface CommunityFiltersResponse {
  appliesTo: CommunityCategory[]
  label: string
  values?: string[] // for select & checkbox
  defaultMin?: number // for ranges
  defaultMax?: number // for ranges
  type: FilterType
}

async function fetchCommunityFilters(selectedCategory: CommunityCategory): Promise<CommunityFiltersResponse[]> {
  const response = await PoshApi.fetchCommunityFilters(selectedCategory)
  return response.data
}

export default function useFetchCommunityFilters(selectedCategory: CommunityCategory) {
  return useQuery<CommunityFiltersResponse[]>(['community-filters', selectedCategory], () =>
    fetchCommunityFilters(selectedCategory),
  )
}
