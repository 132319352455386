import React from 'react'

import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import OrdersListView from '../OrdersListView'

export const OrdersPage = () => {
  return (
    <div className='GroupPage Orders'>
      <div className='GroupPage-content'>
        <div className='GroupPage-content-inner'>
          <BackAndTitle title={'Orders'} />
          <OrdersListView expanded showFilter={true} />
        </div>
      </div>
    </div>
  )
}
