import {ReactQueryOptions, RouterInputs, trpc} from 'lib/trpc'

type FetchCustomCheckoutFieldsInput = RouterInputs['customCheckoutFields']['fetchCustomCheckoutFields']
type FetchCustomCheckoutFieldsOptions = ReactQueryOptions['customCheckoutFields']['fetchCustomCheckoutFields']

export const useFetchCustomCheckoutFields = (
  input: FetchCustomCheckoutFieldsInput,
  options?: FetchCustomCheckoutFieldsOptions,
) => {
  return trpc.customCheckoutFields.fetchCustomCheckoutFields.useQuery(input, options)
}
