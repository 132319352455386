import {useEffect} from 'react'

import {isSortOption, isWhenOption, isWhereOption, SelectedSecondaryFilter} from '@posh/types'
import {useQueryParams} from 'hooks/QueryParams/useQueryParams'
import {RouterOutput} from 'lib/trpc'
import {EventMarketplaceMainFilters} from 'pages/EventMarketplace/MainFilters'

export const useSetMarketplaceStateFromQueryParams = ({
  setSearch,
  setMainFilters,
  setSecondaryFilters,
  secondaryFilters,
  sidebarFiltersData,
}: {
  setSearch: React.Dispatch<React.SetStateAction<string>>
  setMainFilters: React.Dispatch<React.SetStateAction<EventMarketplaceMainFilters>>
  setSecondaryFilters: React.Dispatch<React.SetStateAction<SelectedSecondaryFilter[]>>
  secondaryFilters: SelectedSecondaryFilter[]
  sidebarFiltersData?: RouterOutput['events']['fetchMarketplaceSidebarFilters']
}) => {
  const {queryParams, setQueryParams} = useQueryParams()
  useEffect(() => {
    if (queryParams.has('search')) {
      setSearch(queryParams.get('search')!)
    }

    const sort = queryParams.get('sort')
    if (sort && isSortOption(sort)) {
      setMainFilters(mainFilters => ({...mainFilters, sort}))
    }

    const when = queryParams.get('when')
    if (when && isWhenOption(when)) {
      setMainFilters(mainFilters => ({...mainFilters, when}))
    }

    const where = queryParams.get('where')
    if (where && isWhereOption(where)) {
      setMainFilters(mainFilters => ({...mainFilters, where}))
    }
  }, [])

  useEffect(() => {
    const secondaryFiltersAlreadySet = secondaryFilters.length !== 0
    if (secondaryFiltersAlreadySet) return
    /* We can't really do static type checking here as then in config file person adding would have to add types
      and their verifiers - this way it auto checks based on fetched filters */
    if (sidebarFiltersData) {
      const secondaryFiltersFromQueryParams: SelectedSecondaryFilter[] = []
      sidebarFiltersData.eventFilters.forEach(filter => {
        const {name, options} = filter
        const filterValues = queryParams.getAll(name)
        const validFilterValues = options.map(option => option.value.toString())
        const validatedFilterValuesFromQueryParams = filterValues.filter(value => validFilterValues.includes(value))
        if (validatedFilterValuesFromQueryParams.length > 0) {
          secondaryFiltersFromQueryParams.push({name, chosenValues: validatedFilterValuesFromQueryParams})
        }
        setSecondaryFilters(secondaryFiltersFromQueryParams)
      })
    }
  }, [sidebarFiltersData])
}
