import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'
import {LayoutGroup, motion} from 'framer-motion'

import {BaseNavigationItemAnimations as Animations} from './animations'

import styles from './styles.module.scss'

export interface DesktopBaseNavigationItemProps {
  body: React.ReactNode
  icon: React.ReactNode
  isSelected: boolean
  isExpanded: boolean
  onClick: () => void
  className?: string
}

export function DesktopBaseNavigationItem(props: PropsWithChildren<DesktopBaseNavigationItemProps>) {
  const {body, icon, isSelected, isExpanded, onClick} = props

  return (
    <LayoutGroup>
      <div>
        <motion.div
          className={classNames([styles.Container, props.className])}
          onClick={e => {
            e.stopPropagation() // Prevent the click from being passed to the parent container, expanding/collapsing the sidebar
            onClick()
          }}
          layout='position'
          animate={Animations.container(isExpanded)}
          data-is-selected={isSelected}
          data-is-expanded={isExpanded}>
          <div className={styles.IconContainer} data-is-selected={isSelected}>
            {icon}
          </div>
          <motion.div className={styles.TitleContainer} animate={Animations.body(isExpanded)} layout='position'>
            {body}
          </motion.div>
        </motion.div>
        {props.children && (
          <motion.div
            initial={false}
            animate={Animations.children(isExpanded, isSelected)}
            className={styles.ChildrenContainer}
            layout='position'>
            {props.children}
          </motion.div>
        )}
      </div>
    </LayoutGroup>
  )
}
