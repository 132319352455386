import React from 'react'

import Input from 'components/form/Input'
import Select from 'components/form/Select'

import {CRUDTableMapObj} from '.'

interface CRUDTableSelectProps {
  item: any
  mapObj: CRUDTableMapObj
}

const CRUDTableSelect = (props: CRUDTableSelectProps) => {
  const {item, mapObj} = props
  const value = item[mapObj.key]

  return (
    <Select value={value} onChange={e => null}>
      <option disabled={true} hidden={true}>
        {mapObj.label}
      </option>
      {mapObj.options?.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </Select>
  )
}

interface CRUDTableCellProps {
  item: any
  mapObj: CRUDTableMapObj
  editing: boolean
  handleUpdate: ({key, value}: {key: string; value: any}) => void
}

const CRUDTableCell = (props: CRUDTableCellProps) => {
  const {item, mapObj, editing, handleUpdate} = props
  const value = item[mapObj.key]

  if (mapObj.type === 'select' && editing) {
    return (
      <td>
        <CRUDTableSelect item={item} mapObj={mapObj} />
      </td>
    )
  }

  if (mapObj.type === 'url') {
    if (editing && mapObj.editable) {
      return (
        <Input
          value={value}
          onChange={e => handleUpdate({key: mapObj.key, value: e.target.value})}
          type='text'
          placeholder={mapObj.label}
        />
      )
    } else {
      return (
        <td>
          <a href={value} target='_blank' rel='noreferrer'>
            {value}
          </a>
        </td>
      )
    }
  }

  if (editing && mapObj.editable) {
    if (mapObj.type === 'multiSelect') {
      const falseValue = value == undefined ? 'false' : value
      return (
        <td>
          <Select onChange={e => handleUpdate({key: mapObj.key, value: e.target.value})}>
            {mapObj.options?.map(obj => (
              <option key={obj} selected={obj == falseValue} value={obj}>
                {obj}
              </option>
            ))}
          </Select>
        </td>
      )
    }
    return (
      <td>
        <Input
          value={value}
          onChange={e => handleUpdate({key: mapObj.key, value: e.target.value})}
          type='text'
          placeholder={mapObj.label}
        />
      </td>
    )
  } else {
    return <td>{value}</td>
  }
}

export default CRUDTableCell
