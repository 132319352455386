import React from 'react'
import {Route, Routes} from 'react-router-dom'

import FraudDashboard from 'pages/AdminDashboardPages/FraudDashboard'
import FraudGroups from 'pages/AdminDashboardPages/FraudDashboard/groups'
import FraudOrders from 'pages/AdminDashboardPages/FraudDashboard/orders'
import FraudTicketTypes from 'pages/AdminDashboardPages/FraudDashboard/ticket-types'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

const FraudAdminRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<FraudDashboard />} />
        <Route path={'/orders'} element={<FraudOrders />} />
        <Route path={'/ticket-types'} element={<FraudTicketTypes />} />
        <Route path={'/groups'} element={<FraudGroups />} />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default FraudAdminRoutes
