import React from 'react'

import {Check} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'

interface CRUDTableRowActionsProps {
  editing: boolean
  setEditing: React.Dispatch<React.SetStateAction<boolean>>
  handleSave: () => Promise<void>
  handleDelete?: () => Promise<void>
  handleView?: () => Promise<void>
  handleCopy?: () => Promise<void>
  handleMoreInfo?: () => Promise<void>
}

const CRUDTableRowActions = (props: CRUDTableRowActionsProps) => {
  const {editing, setEditing, handleSave, handleDelete, handleView, handleCopy, handleMoreInfo} = props

  return (
    <div className='CRUDTableRow-actions'>
      {editing ? (
        <>
          <div onClick={() => handleSave()}>
            <Check />
          </div>
          <div onClick={() => setEditing(false)}>
            <PoshImage src='https://images.posh.vip/b2/cancel.svg' style={{filter: 'invert(0)'}} />
          </div>
        </>
      ) : (
        <>
          <div onClick={() => setEditing(true)}>
            <PoshImage src='https://images.posh.vip/b2/pencil.svg' />
          </div>
          {handleDelete && (
            <div onClick={() => handleDelete()}>
              <PoshImage src='https://images.posh.vip/b2/delete.svg' />
            </div>
          )}
          {handleView && (
            <div onClick={() => handleView()}>
              <PoshImage src='https://images.posh.vip/b2/eye.svg' />
            </div>
          )}
          {handleCopy && (
            <div onClick={() => handleCopy()}>
              <PoshImage src='https://images.posh.vip/b2/clipboard.svg' style={{filter: 'invert(0)'}} />
            </div>
          )}
          {handleMoreInfo && (
            <div onClick={() => handleMoreInfo()}>
              <PoshImage src='https://images.posh.vip/b2/info.svg' style={{filter: 'invert(0)'}} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CRUDTableRowActions
