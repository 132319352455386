import React from 'react'

import {CurrencyCode} from '@posh/types'
import useFetchCheckoutSummary from 'apis/Checkout/useFetchCheckoutSummary'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import {isUndefined} from 'lodash'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

const OrderSummaryTable = ({currency}: {currency?: CurrencyCode}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const cartId = urlParams.get('cartId') as string
  const {selectedPaymentPlan} = useLiveCartContext()
  const {data: summary} = useFetchCheckoutSummary({cartId})
  const {cartInfo, promoCodeInfo, paymentPlansInfo} = summary ?? {}
  const cartItemCharges = cartInfo?.items
  const currencySymbol = getCurrencySymbol(currency)
  const paymentPlanIndex = selectedPaymentPlan
  const isShowingPaymentPlanSummary = !isUndefined(paymentPlanIndex)

  const showPromoCodeDiscount = !isUndefined(promoCodeInfo) && (promoCodeInfo.discount ?? 0 !== 0)
  const cartTotal = cartInfo?.total?.toFixed(2) ?? 0
  const paymentPlanDueToday =
    isShowingPaymentPlanSummary && paymentPlansInfo?.paymentPlans[paymentPlanIndex]![0].amount.toFixed(2)

  return (
    <>
      {summary && (
        <div className='CheckoutPage-Section fade'>
          <h5>Your Order</h5>
          <table className='CheckoutSummary'>
            <tbody>
              {cartItemCharges?.map(cartItemCharge => {
                return (
                  <tr className='CheckoutSummary-Row' key={cartItemCharge.resourceId}>
                    <td>{cartItemCharge.descriptor}</td>
                    <td>{`${currencySymbol}${cartItemCharge.resourceAmount.toFixed(2)}`}</td>
                    <td>{`x${cartItemCharge.quantity}`}</td>
                    <td>{`${currencySymbol}${cartItemCharge.amount.toFixed(2)}`}</td>
                  </tr>
                )
              })}
              {showPromoCodeDiscount && (
                <tr className='CheckoutSummary-DiscountRow'>
                  <td>Discount</td>
                  <td></td>
                  <td></td>
                  <td>{`-${currencySymbol}${promoCodeInfo.discount.toFixed(2)}`}</td>
                </tr>
              )}
              {cartInfo?.feeBreakdown && (
                <>
                  {cartInfo?.feeBreakdown
                    .filter(fee => fee.totalFee > 0)
                    .map(f => (
                      <tr className='CheckoutSummary-FeeRow' key={f.name}>
                        <td>{f.name}</td>
                        <td></td>
                        <td></td>
                        <td>{`${currencySymbol}${f.totalFee.toFixed(2)}`}</td>
                      </tr>
                    ))}
                </>
              )}
              <tr className='CheckoutSummary-TotalRow'>
                <td>Total</td>
                <td></td>
                <td></td>
                <td>{`${currencySymbol}${cartTotal}`}</td>
              </tr>
              {isShowingPaymentPlanSummary && paymentPlanDueToday && (
                <tr className='CheckoutSummary-TotalRow paymentPlan'>
                  <td>Due Today</td>
                  <td></td>
                  <td></td>
                  <td>{`${currencySymbol}${paymentPlanDueToday}`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default OrderSummaryTable
