import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {GalleryModel} from './types'

export async function getGalleryById(eventId: string) {
  const response = await PoshApi.getGalleryById(eventId)
  return response.data.gallery
}

export function useFetchGalleryById(eventId: string) {
  return useQuery<GalleryModel>(['galleryByID', eventId], () => getGalleryById(eventId))
}
