import React from 'react'

import classNames from 'classnames'

interface CartErrorProps {
  error?: string
  center?: boolean
}

export const CartError = (props: CartErrorProps) => {
  const {error, center} = props
  const className = classNames('error', 'text-small', center ? 'center' : 'left')
  if (!error) return null
  return <span className={className}>{error}</span>
}
