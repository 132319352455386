import React from 'react'

import useUpdatePendingApprovals from 'apis/Orders/useUpdatePendingApprovals'
import {useToast} from 'components/toasts/ToastProvider'
import pluralize from 'helpers/pluralize'
import ApprovalButtons, {
  LargeLoadingDeactivatedApprovalButton,
} from 'pages/EventManagementPages/Tickets/ApprovalButtons'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {ApprovalStatus, ApprovalStatusPresentTenseMap, ApproveOrDeny} from '../..'
import useApprovalCrmContext from '../../ApprovalContextProvider/ApprovalCrmContext'
import useApprovalButtons from '../../useApprovalButtons.hooks'

import './styles.scss'

const BulkApprovalActions = () => {
  const {selectedApprovalIds, setSelectedApprovalIds} = useApprovalCrmContext()
  const {expandedType, handleExpandButton, handleCancelConfirmation} = useApprovalButtons()
  const {eventId} = useResourcePageParams()
  const {showToast} = useToast()
  const {mutate: updatePendingApproval, isLoading} = useUpdatePendingApprovals({
    onSuccess: (_, variables) => {
      showToast({
        type: 'success',
        title: `Successfully ${variables.updates[0].status} ${pluralize('order', variables.updates.length)}!`,
      })
    },
    onError: (error, variables) => {
      showToast({
        type: 'error',
        title: `There was an error ${ApprovalStatusPresentTenseMap[variables.updates[0].status]} these ${pluralize(
          'order',
          variables.updates.length,
        )}!`,
        subtitle: error.message,
      })
    },
    onSettled: () => {
      setSelectedApprovalIds([])
    },
  })

  const handleUpdatePendingApprovals = (status: ApproveOrDeny) => {
    if (!selectedApprovalIds) return
    const confirmedStatus: ApprovalStatus = status === 'approve' ? 'approved' : 'denied'
    const updates = selectedApprovalIds.map(id => ({id, status: confirmedStatus}))
    updatePendingApproval({updates, eventId: eventId!})
  }
  return (
    <div className='BulkApprovalActions'>
      {selectedApprovalIds && (
        <div className='BulkApprovalActions-Container'>
          {isLoading ? (
            <LargeLoadingDeactivatedApprovalButton />
          ) : (
            <ApprovalButtons
              variant='large'
              isCrmRow={false}
              expandedType={expandedType}
              handleExpandButton={handleExpandButton}
              handleCancelConfirmation={handleCancelConfirmation}
              handleUpdateApprovalStatus={handleUpdatePendingApprovals}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default BulkApprovalActions
