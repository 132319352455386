import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type TFetchCheckoutSummaryInput = RouterInputs['checkout']['getCheckoutSummary']
type TFetchCheckoutSummaryOptions = ReactQueryOptions['checkout']['getCheckoutSummary']
export type TFetchCheckoutSummaryOutput = RouterOutput['checkout']['getCheckoutSummary']
export type TCheckoutSummaryCustomFields = TFetchCheckoutSummaryOutput['checkoutFieldsAndResponses']
export type TCheckoutSummaryCustomField = NonNullable<TCheckoutSummaryCustomFields>[number]
export type CustomCheckoutFieldFileResponse = Extract<
  NonNullable<TCheckoutSummaryCustomFields>[number],
  {type: 'file_upload'}
>

export default function useFetchCheckoutSummary(
  input: TFetchCheckoutSummaryInput,
  options?: TFetchCheckoutSummaryOptions,
) {
  return trpc.checkout.getCheckoutSummary.useQuery(input, {
    ...options,
    enabled: !!input.cartId,
    retry: false,
  })
}
