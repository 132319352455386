import React from 'react'
import {Control, useController} from 'react-hook-form'

import {GuestListPreview} from 'apis/Events/useGetEventForVisualEditor'
import {ToggleableEye} from 'components/assets/ToggleableEye'
import {WithToolTip} from 'components/form/PoshInfo'
import {EventVisualsInputContainer} from 'components/PageComponents/EventVisuals/Form/Input/shared/InputContainer'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {GuestListIcon} from 'pages/EventPage/Guestlist'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

import styles from './styles.module.scss'

const tooltip = (isHidden: boolean) => `${isHidden ? 'Show' : 'Hide'} who is attending your event.`

interface EventVisualsGuestlistSectionProps {
  control: Control<EventVisualsForm>
  attendees: GuestListPreview
  attendeesCount?: number
}

const EventVisualsExpandedGuestListSection = (props: EventVisualsGuestlistSectionProps) => {
  const {control, attendees} = props
  const {
    palette: {textContrasting},
  } = useEventVisualsContext()
  const {
    field: {onChange: setGuestlistEnabled, value: guestlistEnabled},
  } = useController({
    control,
    name: 'guestlistEnabled',
  })

  const isHidden = !guestlistEnabled

  return (
    <EventVisualsSection.WithEye
      headerText='Guestlist'
      eyeProps={{onClick: () => setGuestlistEnabled(!guestlistEnabled), fill: textContrasting}}
      isHidden={isHidden}
      tooltipInfo={tooltip(isHidden)}
      underlined>
      <p className='noMargin' style={{color: textContrasting}}>
        Your guestlist will appear here
      </p>
      <div className={styles.EventVisualsExpandedGuestListSection}>
        {attendees.map((attendee, index) => (
          <GuestListIcon icon={attendee.avi} key={index} />
        ))}
      </div>
    </EventVisualsSection.WithEye>
  )
}

const ICON_SIZE = 16
const PREVIEW_SIZE = 3

const GuestlistPreview = (props: {attendees: GuestListPreview; color: string}) => {
  const {attendees, color} = props
  return (
    <div className={styles.row} style={{marginLeft: '10px'}}>
      {attendees.slice(0, PREVIEW_SIZE).map((attendee, index) => (
        <PoshImage style={{borderColor: color}} className={styles.overlappingIcon} src={attendee.avi} key={index} />
      ))}
    </div>
  )
}

interface GuestlistNumberAttendingProps {
  attendees: GuestListPreview
  color: string
  attendanceDisplayDisabled: boolean
  setAttendanceDisplayDisabled: (attendanceDisplayDisabled: boolean) => void
}

const GuestlistNumberAttending = (props: GuestlistNumberAttendingProps) => {
  const {attendees, color, attendanceDisplayDisabled, setAttendanceDisplayDisabled} = props
  const othersGoingText = attendanceDisplayDisabled
    ? `and others going`
    : `and ${attendees.length - 1} other${attendees.length > 1 ? 's' : ''} going`
  const attendeesGoingText = `${attendees[0].firstName} ${othersGoingText}`

  const toolTip = `${attendanceDisplayDisabled ? 'Show' : 'Hide'} the number of guests going to your event.`

  return (
    <div className={styles.row}>
      <h6 className='noMargin' style={{color}}>
        {attendeesGoingText}
      </h6>
      <WithToolTip info={toolTip}>
        <ToggleableEye
          fill={color}
          visible={!attendanceDisplayDisabled}
          size={ICON_SIZE}
          onClick={() => setAttendanceDisplayDisabled(!attendanceDisplayDisabled)}
        />
      </WithToolTip>
    </div>
  )
}

const EventVisualsGuestlistPreview = (props: EventVisualsGuestlistSectionProps) => {
  const {control, attendees} = props
  const {
    palette: {textContrastingWithOpacity, accentColor, lightmode},
  } = useEventVisualsContext()
  const {
    field: {value: guestlistEnabled},
  } = useController({
    control,
    name: 'guestlistEnabled',
  })
  const {
    field: {onChange: setAttendanceDisplayDisabled, value: attendanceDisplayDisabled},
  } = useController({
    control,
    name: 'attendanceDisplayDisabled',
  })

  const isHidden = !guestlistEnabled

  return (
    <EventVisualsSection headerText='Guestlist Preview' isHidden={isHidden}>
      <EventVisualsInputContainer style={{justifyContent: 'center'}} accentColor={accentColor} lightMode={lightmode}>
        <div className={styles.EventVisualsGuestListSection}>
          <GuestlistPreview attendees={attendees} color={textContrastingWithOpacity} />
          <GuestlistNumberAttending
            attendees={attendees}
            color={textContrastingWithOpacity}
            attendanceDisplayDisabled={attendanceDisplayDisabled || false}
            setAttendanceDisplayDisabled={setAttendanceDisplayDisabled}
          />
        </div>
      </EventVisualsInputContainer>
    </EventVisualsSection>
  )
}

export class EventVisualsGuestlistSection {
  static Expanded = EventVisualsExpandedGuestListSection
  static Preview = EventVisualsGuestlistPreview
}
