import * as original from '../original/legacy-colors';
import { base, blue, blueDark, blueLight, brand, cyan, error, fuchsia, grayDarkMode, grayIron, grayLightMode, grayTrue, green, greenLight, indigo, rose, success, teal, warning, yellow, } from './palettes';
import { primary } from './affordances';
export const legacyGradients = original.legacyGradients;
export const legacyColors = {
    ...original.legacyColors,
    primary: primary.tokens.enabled,
    // primary: yellow.tokens[400],
    // primaryHalfOpacity: yellow.tokens[400],
    // primaryQuarterOpacity: yellow.tokens[400],
    // primaryTenthOpacity: yellow.tokens[400],
    primaryDark: yellow.tokens[500],
    secondary: cyan.tokens[400],
    // secondaryTenthOpacity: cyan.tokens[400],
    secondaryDark: teal.tokens[900],
    tertiary: rose.tokens[600],
    tertiaryDark: rose.tokens[700],
    communityGreen: greenLight.tokens[500],
    success: greenLight.tokens[500],
    successDark: success.tokens[950],
    error: error.tokens[500],
    warning: yellow.tokens[400],
    lightPurple: fuchsia.tokens[300],
    lightBlue: indigo.tokens[300],
    pink: rose.tokens[400],
    danger: rose.tokens[500],
    green: green.tokens[400],
    blue: teal.tokens[300],
    red: error.tokens[500],
    orange: yellow.tokens[600],
    backgroundBlack: grayTrue.tokens[950],
    backgroundDarker: brand.tokens[900],
    backgroundDark: grayIron.tokens[950],
    backgroundMediumDark: grayIron.tokens[900],
    backgroundMedium: brand.tokens[800],
    backgroundLight: grayIron.tokens[800],
    backgroundPurple: fuchsia.tokens[500],
    backgroundSkyBlue: cyan.tokens[500],
    mailChimpPrimary: yellow.tokens[400],
    white: base.tokens.white,
    offWhite: grayDarkMode.tokens[100],
    greyLight: grayLightMode.tokens[100],
    greyMediumLight: grayLightMode.tokens[100],
    greyHalfOpacity: brand.tokens[300],
    grey: brand.tokens[300],
    black: brand.tokens[950],
    borderDark: brand.tokens[700],
    borderDarker: grayIron.tokens[900],
    badgeBlue: blueLight.tokens[400],
    badgeOrange: yellow.tokens[400],
    badgeGreen: greenLight.tokens[400],
    menColor: blueDark.tokens[400],
    womenColor: fuchsia.tokens[600],
    facebookBlue: blue.tokens[600],
    snapchatYellow: yellow.tokens[400],
    pumpkinOrange: warning.tokens[400],
    contextMenuBackground: grayIron.tokens[900],
};
