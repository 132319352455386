import React from 'react'
import {Control, Controller, FieldValues, Path, SubmitHandler, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {zodResolver} from '@hookform/resolvers/zod'
import {socialMediaSchemae} from '@posh/types'
import {GetGroupOutput} from 'apis/Groups/useGetGroup'
import {useUpdateGroupSettings} from 'apis/Groups/useUpdateGroupSettings'
import Button from 'components/form/Button'
import SingleImagePicker from 'components/form/ImagePicker'
import Input from 'components/form/Input'
import PoshSwitch from 'components/form/PoshSwitch'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {useToast} from 'components/toasts/ToastProvider'
import {dirtyValues} from 'helpers/forms/dirtyValues'
import {useUploadImage} from 'hooks/uploadImage/useUploadImage'
import SaveDiscardBottomBar from 'pages/EventManagementPages/SaveDiscardBottomBar'
import {z} from 'zod'

import {ISettingInputProp, SettingInput} from '..'
import CustomLinks from '../CustomLinks'

interface EditGroupProfileProps {
  groupId: string
  group: GetGroupOutput
}

const UpdateGroupSettingsSchema = z
  .object({
    instagram: z.union([socialMediaSchemae['instagram'], z.literal('')]),
    twitter: z.union([socialMediaSchemae['twitter'], z.literal('')]),
    website: z.union([z.string().url({message: 'Invalid URL'}), z.literal('')]),
    linkedIn: z.union([socialMediaSchemae['linkedIn'], z.literal('')]),
    name: z.string().min(1),
    url: z
      .string()
      .min(1, {message: 'Profile URL is required'})
      .regex(/^[a-z0-9-]+$/i, {message: 'Profile URL may only contain lowercase letters, numbers, and dashes'}),
    displayAttendeeCount: z.boolean().optional(),
    aviLocation: z.union([z.string().url(), z.literal('')]),
    aviImageId: z.union([z.string(), z.literal('')]),
    header: z.union([z.string().url(), z.literal('')]),
    headerImageId: z.union([z.string(), z.literal('')]),
    bio: z.union([z.string(), z.literal('')]),
  })
  .partial()

type UpdateGroupSettingsSchema = z.infer<typeof UpdateGroupSettingsSchema>

export const EditGroupProfile = (props: EditGroupProfileProps) => {
  const {group, groupId} = props
  const {showToast} = useToast()
  const navigate = useNavigate()

  const {mutate: updateGroupSettings} = useUpdateGroupSettings({
    onSuccess: () => {
      showToast({type: 'success', title: 'Successfully updated group settings'})
    },
    onError: error => {
      showToast({type: 'error', title: 'Something went wrong updating your group settings', subtitle: error.message})
    },
  })

  const {uploadImage: uploadHeaderImage} = useUploadImage({
    imageType: 'group-header',
    onSuccess: (imageId, imageUrl) => {
      setValue('header', imageUrl, {shouldDirty: true})
      setValue('headerImageId', imageId, {shouldDirty: true})
    },
  })

  const {uploadImage: uploadAviImage} = useUploadImage({
    imageType: 'group-avi',
    onSuccess: (imageId, imageUrl) => {
      setValue('aviLocation', imageUrl, {shouldDirty: true})
      setValue('aviImageId', imageId, {shouldDirty: true})
    },
  })

  const {register, setValue, formState, handleSubmit, control, reset, watch} = useForm<UpdateGroupSettingsSchema>({
    defaultValues: {
      name: group.name,
      aviLocation: group.aviLocation,
      header: group.header,
      bio: group.bio,
      instagram: group.socials?.instagram,
      twitter: group.socials?.twitter,
      linkedIn: group.socials?.linkedIn,
      website: group.socials?.website,
      url: group.url,
      displayAttendeeCount: group.displayAttendeeCount,
    },
    resolver: zodResolver(UpdateGroupSettingsSchema),
  })
  const watchedGroupUrl = watch('url')

  const onSubmit: SubmitHandler<UpdateGroupSettingsSchema> = data => {
    const updates = dirtyValues(data, formState.dirtyFields)
    updateGroupSettings({groupId, updates})
  }

  const showSaveDiscardButtons = formState.isDirty

  return (
    <>
      <PoshHelmet title={`Profile - ${group.name}`} />
      <div className='Settings'>
        <div className='Settings-header'>
          <Controller
            control={control}
            name='header'
            render={({field}) => (
              <SingleImagePicker
                className='settings-banner'
                value={{url: field.value}}
                showTitle={false}
                title=''
                banner={true}
                didReceiveFile={e => {
                  if (!e.file) throw new Error('Image file does not exist.')
                  uploadHeaderImage(e.file)
                }}
              />
            )}
          />
        </div>
        <div className='Settings-content'>
          <div className='Settings-cols'>
            <div className='Settings-left'>
              <Controller
                control={control}
                name='aviLocation'
                render={({field}) => (
                  <SingleImagePicker
                    className='setting-image'
                    title={'Image'}
                    showTitle={false}
                    didReceiveFile={e => {
                      if (!e.file) throw new Error('Image file does not exist.')
                      uploadAviImage(e.file)
                    }}
                    icon={true}
                    value={{url: field.value}}
                  />
                )}
              />

              <Button
                className='light dark goldHover'
                style={{width: '220px', marginLeft: '20px'}}
                onClick={() => navigate(`/g/${group.url}`)}>
                View Profile
              </Button>
            </div>
            <div className='Settings-right'>
              <Input style={{fontSize: '28px'}} type='text' {...register('name')} />
              <hr />
              <h4>Profile Info</h4>
              <table className='SettingsTable'>
                <ControlledSettingsInput
                  control={control}
                  name='bio'
                  title='Biography'
                  placeHolder='Biography'
                  className='Large'
                  displayErrorMessage
                />
                <ControlledSettingsInput
                  control={control}
                  name='instagram'
                  title='Instagram'
                  placeHolder='Instagram Username'
                  displayErrorMessage
                />
                <ControlledSettingsInput
                  control={control}
                  name='twitter'
                  title='Twitter'
                  placeHolder='Twitter Username'
                  displayErrorMessage
                />
                <ControlledSettingsInput
                  control={control}
                  name='linkedIn'
                  title='LinkedIn'
                  placeHolder='LinkedIn URL'
                  displayErrorMessage
                />
                <ControlledSettingsInput
                  control={control}
                  name='website'
                  title='Website'
                  placeHolder='Website URL'
                  displayErrorMessage
                />
                {/** TODO: Remove these or add them both into the mobile app. Need product decisions. */}
                {/* <SettingInput
                  title={'Location'}
                  placeHolder={'Location'}
                  value={settings.location ?? group?.location}
                  onChangeFunc={e => setSettings({...settings, location: e.target.value})}
                />
                <tr>
                  <td>
                    <label>Profile Accent Color</label>
                  </td>
                  <td>
                    <ColorPicker
                      onChange={e => setSettings({...settings, accentColor: e.target.value})}
                      value={settings.accentColor ?? group?.accentColor ?? '#FFFFFF'}
                    />
                  </td>
                </tr> */}
                <tr>
                  <td>
                    <label>Custom Links</label>
                  </td>
                  <td>
                    <CustomLinks groupLinks={group.links} groupId={groupId} />
                  </td>
                </tr>

                <ControlledSettingsInput
                  control={control}
                  name='url'
                  title='Organization Profile URL'
                  placeHolder='ex. posh-group'
                  displayErrorMessage
                  subTitle={`https://posh.vip/g/${watchedGroupUrl}`}
                />
                <tr>
                  <td>
                    <label>Display Number of Attendees</label>
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name='displayAttendeeCount'
                      render={({field}) => (
                        <PoshSwitch
                          switchOptions={{
                            onChange: value => field.onChange(value),
                            checked: !!field.value,
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <SaveDiscardBottomBar onDiscard={() => reset()} onSave={handleSubmit(onSubmit)} isOpen={showSaveDiscardButtons} />
    </>
  )
}

interface ControlledSettingsInputProps<T extends FieldValues>
  extends Omit<ISettingInputProp, 'onChangeFunc' | 'value'> {
  control: Control<T>
  name: Path<T>
  displayErrorMessage?: boolean
}

const ControlledSettingsInput = <T extends FieldValues>(props: ControlledSettingsInputProps<T>) => {
  const {control, name, displayErrorMessage = false, ...settingInputProps} = props
  return (
    <Controller
      control={control}
      name={name}
      render={({field, fieldState}) => (
        <SettingInput
          {...settingInputProps}
          value={field.value}
          onChangeFunc={e => field.onChange(e)}
          errorMessage={displayErrorMessage ? fieldState.error?.message : undefined}
        />
      )}
    />
  )
}
