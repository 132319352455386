import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface UpdateRefundRequestParams {
  eventId: string
  status: string
  includesFees: boolean
}
export async function updateRefundRequest(params: UpdateRefundRequestParams) {
  const response = await PoshApi.updateRefundRequest(params)
  return response.data
}

export function useUpdateRefundRequest() {
  return useMutation((params: UpdateRefundRequestParams) => updateRefundRequest(params))
}
