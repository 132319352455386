import {Layout} from 'react-grid-layout'

import {Coordinate, Rectangle, StaticDecoration} from 'apis/Events/types'
import includes from 'lodash/includes'

import './styles.scss'

const FAKE_DECORATION: StaticDecoration = {
  title: 'Stage',
  color: '#1A1A1A',
  rectangle: [
    [6, 11],
    [8, 11],
    [8, 1],
    [6, 1],
  ],
}

export interface DecorationCoordinatesAndTitle {
  decoration: StaticDecoration
  topLeft: Coordinate
  coordinates: Coordinate[]
  hasOriginPoint?: boolean
}

interface LayoutResponse {
  layout: Layout[]
  tableIds: string[]
  tableIdMap: {[key: number]: string[]}
  decorationIds: string[]
  decorationMap: {[key: number]: StaticDecoration}
  decorationCoordinatesAndTitle: DecorationCoordinatesAndTitle[]
  xValueToTotalDecorationHeightMap: {[key: number]: number}
}

export interface TableCoordinate {
  id: string
  x: number
  y: number
}

export interface IGroupedTableCoordinate {
  id: string[]
  x: number
  y: number
}

export function generateCoordinatesFromSquare(square: Rectangle) {
  const topLeft = square[0]
  const bottomRight = square[2]
  const coordinates: Coordinate[] = []

  for (let x = topLeft[0]; x <= bottomRight[0]; x++) {
    for (let y = topLeft[1]; y <= bottomRight[1]; y++) {
      coordinates.push([x, y])
    }
  }

  return coordinates
}

export function getCenterCoordinateFromSquare(square: Rectangle) {
  const mid: Coordinate = [0, 0]
  mid[0] = Math.floor((square[0][0] + square[2][0]) / 2)
  mid[1] = Math.round((square[0][1] + square[2][1]) / 2)
  return mid
}

export function generateLayout(
  width: number,
  height: number,
  tableCoordinates: IGroupedTableCoordinate[],
  decorations: StaticDecoration[],
): LayoutResponse {
  const layout = []
  let counter = 0
  const tableIds = []
  const tableIdMap: {[key: number]: string[]} = {}
  const decorationIds = []
  const decorationMap: {[key: number]: StaticDecoration} = {}

  const decorationCoordinatesAndTitle = decorations.map(d => {
    const {rectangle} = d
    const coordinates = generateCoordinatesFromSquare(rectangle)

    return {
      decoration: d,
      topLeft: rectangle[0],
      coordinates: coordinates,
    }
  })

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      const isTable = tableCoordinates.find(coordinate => coordinate.x == x && coordinate.y == y)
      const relevantDecorations = decorationCoordinatesAndTitle.filter(d => {
        const {topLeft} = d
        return topLeft[0] == x && topLeft[1] == y
      })
      const isDecoration = relevantDecorations.length > 0

      let width: number | undefined
      let height: number | undefined

      if (isDecoration) {
        const decoration = relevantDecorations[0].decoration
        const topLeft = decoration.rectangle[0]
        const bottomRight = decoration.rectangle[2]

        width = Math.abs(topLeft[0] - bottomRight[0]) + 1
        height = Math.abs(topLeft[1] - bottomRight[1]) + 1

        decorationIds.push(counter.toString())
        decorationMap[counter] = decoration
      }

      if (isTable) {
        tableIds.push(counter.toString())
        tableIdMap[counter] = isTable.id
      }

      layout.push({
        i: counter.toString(),
        x,
        y,
        w: width ?? 1,
        h: height ?? 1,
      })
      counter++
    }
  }

  const xValueToTotalDecorationHeightMap: {[key: number]: number} = {}

  decorationCoordinatesAndTitle.forEach(d => {
    const xs: number[] = []
    d.coordinates.forEach(c => {
      if (!includes(xs, c[0])) {
        xs.push(c[0])
      }
    })
    const {decoration} = d
    const {rectangle} = decoration
    const topLeft = rectangle[0]
    const bottomRight = rectangle[2]
    const decorationHeight = Math.abs(topLeft[1] - bottomRight[1]) + 1

    xs.forEach(x => {
      const valueAlreadySetForX = includes(Object.keys(xValueToTotalDecorationHeightMap), x.toString())

      if (valueAlreadySetForX) {
        xValueToTotalDecorationHeightMap[x] = xValueToTotalDecorationHeightMap[x] + decorationHeight
      } else {
        xValueToTotalDecorationHeightMap[x] = decorationHeight
      }
    })
  })

  decorationCoordinatesAndTitle.forEach(d => {
    const {decoration} = d
    const {rectangle} = decoration
    const topLeft = rectangle[0]
    const originPointX = d.coordinates.find(c => c[0] == topLeft[0] && c[1] == topLeft[1])![0]

    xValueToTotalDecorationHeightMap[originPointX] -= 1
  })

  return {
    layout,
    tableIds,
    tableIdMap,
    decorationIds,
    decorationMap,
    decorationCoordinatesAndTitle,
    xValueToTotalDecorationHeightMap,
  }
}
