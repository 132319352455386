import React from 'react'

import {CheckboxesInputCheckout, CheckboxesInputCheckoutProps} from './CheckboxesInputCheckout'
import {CheckInputCheckout, CheckInputCheckoutProps} from './CheckInputCheckout'
import {FileUploadInputCheckout, FileUploadInputCheckoutProps} from './FileUploadInputCheckout'
import {PhoneNumberFieldCheckout, PhoneNumberFieldCheckoutProps} from './PhoneNumberFieldCheckout'
import {SelectorInputCheckout, SelectorInputCheckoutProps} from './SelectorInputCheckout'
import {TextInputCheckout, TextInputCheckoutProps} from './TextInputCheckout'
import {CheckoutTypeIdentifier, CheckoutTypes, ExtractCheckoutParameters} from './types'

interface IProps<T extends CheckoutTypeIdentifier> {
  field_type: T
}

type UnionProps<T extends CheckoutTypeIdentifier> = IProps<T> & ExtractCheckoutParameters<CheckoutTypes, T>

function Index<T extends CheckoutTypeIdentifier>(props: UnionProps<T>): JSX.Element {
  const {field_type} = props
  switch (field_type) {
    case 'input':
      return <TextInputCheckout {...(props as unknown as TextInputCheckoutProps)} />
    case 'phone_number':
      return <PhoneNumberFieldCheckout {...(props as unknown as PhoneNumberFieldCheckoutProps)} />
    case 'dropdown':
      return <SelectorInputCheckout {...(props as unknown as SelectorInputCheckoutProps)} />
    case 'checkbox':
      return <CheckInputCheckout {...(props as unknown as CheckInputCheckoutProps)} />
    case 'checkboxes':
      return <CheckboxesInputCheckout {...(props as unknown as CheckboxesInputCheckoutProps)} />
    case 'file_upload':
      return <FileUploadInputCheckout {...(props as unknown as FileUploadInputCheckoutProps)} />
    default:
      return <></>
  }
}

export default Index
