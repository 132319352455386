import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {getGroupAvi} from '@posh/model-types'
import {EventPublic, GroupPublic} from 'apis/Events/types'
import {Group} from 'apis/Groups'
import {useMixpanel} from 'apis/MixPanelHandler'
import classNames from 'classnames'
import Button from 'components/form/Button'
import useModalState from 'components/modals/Modal/useModalState'
import {PoshImage} from 'components/PoshImage/PoshImage'
import VerifiedGroupBadge from 'components/VerifiedGroupBadge'
import {isEmpty} from 'lodash'
import {trackCreateEventEntryPoint} from 'pages/CreateEvent/useNavigateToCreateEvent'
import ContactCuratorModal from 'pages/EventPage/ContactCuratorModal'

import './styles.scss'

interface EventFooterProps {
  group?: Group | GroupPublic
  accentColor?: string
  colorModeModifier?: string
  event?: EventPublic
  isPersonalEvent?: boolean
}

const EventFooter = (props: EventFooterProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {group, accentColor, colorModeModifier, event, isPersonalEvent} = props
  const contactCuratorModal = useModalState()
  const [isShowingContact, setIsShowingContactModal] = useState(false)
  const showContactCuratorModal = () => {
    setIsShowingContactModal(true)
    contactCuratorModal.onOpen()
  }
  const buttonClassNames = classNames(
    {small: !!group?.name && group?.name.length < 25},
    {transparent: colorModeModifier === 'is-lightMode'},
  )
  const navigate = useNavigate()

  return (
    <>
      <div className={`EventPage-footer ${colorModeModifier ?? ''}`}>
        <div className={'EventPage-footer-inner'}>
          {!isPersonalEvent && (
            <div className='EventPage-footer-groupContainer'>
              {group?.aviLocation && (
                <PoshImage
                  className='EventPage-avatar'
                  src={event?.displayGroupImage ? event.displayGroupImage : getGroupAvi(group)}
                  alt='Avatar'
                />
              )}
              <span onClick={() => navigate(`/g/${group?.url}`)} className={'EventPage-groupName ' + colorModeModifier}>
                {event?.displayGroupName ? event.displayGroupName : group?.name}
                {group?.verified && <VerifiedGroupBadge size={18} />}
              </span>
            </div>
          )}
          <Button
            className={buttonClassNames}
            onClick={showContactCuratorModal}
            style={{background: accentColor ?? group?.accentColor ?? 'white'}}>
            Contact {event?.displayGroupName ? event.displayGroupName : group?.name}
          </Button>
          {group && !isEmpty(group.socials) && group.socials.instagram && group.socials.twitter && (
            <div className={'EventPage-footer-socials'}>
              <span>
                Follow {event?.displayGroupName ? event.displayGroupName : group?.name}! &nbsp;&nbsp;&nbsp;&mdash;
              </span>
              {group.socials.instagram && (
                <a
                  className={'EventPage-footerSvg'}
                  href={`https://instagram.com/${group.socials.instagram}`}
                  target={'_blank'}
                  rel='noreferrer'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                    <path
                      fill='#fff'
                      d='M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z'
                    />
                  </svg>
                </a>
              )}
              {group.socials.twitter && (
                <a href={`https://twitter.com/${group.socials.twitter}`} target={'_blank'} rel='noreferrer'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                    <path
                      fill='#fff'
                      d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z'
                    />
                  </svg>
                </a>
              )}
            </div>
          )}
          <hr className={'EventPage-hr'} />
          <div className='EventPage-footer-rowB'>
            <div className='EventPage-footer-rowB-links'>
              <a href='/' target='_blank'>
                <PoshImage src='https://images.posh.vip/b2/rect-type-gray.svg' />
              </a>
              <a href='/tos' target='_blank'>
                Terms Of Service
              </a>
              <a href='/privacy' target='_blank'>
                Privacy Policy
              </a>
            </div>
            <a href='/create?utm_source=event_page' target='_blank'>
              <Button
                style={{
                  background: accentColor ?? group?.accentColor,
                  color: colorModeModifier == 'is-darkMode' ? '#0a0b0d' : '#fff',
                }}
                onClick={() => {
                  trackMixpanelEvent('Create Event- Event page Bottom of page')
                  trackCreateEventEntryPoint('Event Page Footer')
                }}>
                Create Your Own Event
              </Button>
            </a>
          </div>
        </div>
      </div>
      {event?.id && group?.name && (
        <ContactCuratorModal
          isOpen={isShowingContact}
          onClose={() => setIsShowingContactModal(false)}
          groupName={group.name}
          eventId={event.id}
        />
      )}
    </>
  )
}

export default EventFooter
