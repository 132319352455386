import React from 'react'

import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'

import {RequestDetailsProps} from '../types'

export const RequestDetailsModal = (props: RequestDetailsProps) => {
  const {reason, description, supportInstructions, isOpen, onClose, internalNoteForPosh} = props
  return (
    <PoshStyledModal isOpen={isOpen} onClose={onClose}>
      <div className='FullRefundDashboard-Modal'>
        <h1 className='center'>Request Details</h1>
        <div className='FullRefundDashboard-Modal-Content'>
          <p>Refund Reason</p>
          <p className='FullRefundDashboard-Modal-Content-Responses'>{reason}</p>
          <p>Elaborate on the reasoning for this requested refund...</p>
          <p className='FullRefundDashboard-Modal-Content-Responses'>{internalNoteForPosh}</p>
          <p>Write an update for the attendees</p>
          <p className='FullRefundDashboard-Modal-Content-Responses'>{description}</p>
          <p>Where should customers with questions and concerns be directed to?</p>
          <p className='FullRefundDashboard-Modal-Content-Responses'>{supportInstructions}</p>
        </div>
      </div>
    </PoshStyledModal>
  )
}
