import React from 'react'
import {useNavigate} from 'react-router-dom'

import {FilledPhone} from 'components/assets/Icons'

import './PurchaseCustomPhoneNumberButton.styles.scss'

/** Should only be used inside of SMSCampaign/index because of navigation
 */
const PurchaseCustomPhoneNumberButton = () => {
  const navigate = useNavigate()
  return (
    <button className='PurchaseCustomPhoneNumberButton' onClick={() => navigate('../../custom-number')}>
      <FilledPhone className='PurchaseCustomPhoneNumberButton-icon' /> + Purchase Custom Number
    </button>
  )
}

export default PurchaseCustomPhoneNumberButton
