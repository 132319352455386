import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface AddDebitCardParams {
  groupId: string
  token: string
}

const addDebitCard = async (params: AddDebitCardParams) => {
  const response = await PoshApi.addDebitCard(params)
  return response.data
}
export default function useAddDebitCard() {
  return useMutation<unknown, any, AddDebitCardParams>(params => addDebitCard(params))
}
