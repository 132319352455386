import React, {useState} from 'react'

import {useDeleteAccountPoshAdmin} from 'apis/AdminDashboard/accounts/useDeleteAccountPoshAdmin'
import {FetchAccountsAdminOutputItem, useFetchAccounts} from 'apis/AdminDashboard/accounts/useFetchAccounts'
import {useUpdateAccountPoshAdmin} from 'apis/AdminDashboard/accounts/useUpdateAccountPoshAdmin'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import {useToast} from 'components/toasts/ToastProvider'
import {flatten} from 'lodash'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import CRUDTable, {CRUDTableDataMap, CRUDTableMap} from '../CRUDTable'

const AccountTableMap: CRUDTableMap = [
  {
    label: 'First Name',
    key: 'firstName',
    type: 'string',
    editable: true,
  },
  {
    label: 'Last Name',
    key: 'lastName',
    type: 'string',
    editable: true,
  },
  {
    label: 'Email',
    key: 'email',
    type: 'string',
    editable: true,
  },
  {
    label: 'Phone',
    key: 'phone',
    type: 'string',
    editable: true,
  },
  {
    label: 'Instagram Handle',
    key: 'instagram',
    type: 'string',
    editable: true,
  },
  {
    label: 'Sign up Date',
    key: 'signUpDate',
    type: 'string',
    editable: false,
  },
  {
    label: 'Stripe Id',
    key: 'stripeId',
    type: 'string',
    editable: false,
  },
  {
    label: 'Disabled',
    key: 'disabled',
    type: 'string',
    editable: false,
  },
]

export interface AccountData extends CRUDTableDataMap {
  key: 'account'
  data: FetchAccountsAdminOutputItem[]
}

const PoshAccounts = () => {
  const {showToast} = useToast()
  const [searchText, setSearchText] = useState('')
  const [selectedEmail, setSelectedEmail] = useState<string | undefined>(undefined)

  const {data, fetchNextPage, refetch, isLoading: loading} = useFetchAccounts({limit: 100, searchParam: searchText})

  const allAccounts = flatten(data?.pages.map(i => i.items))

  const {mutate: updateAccountMutate} = useUpdateAccountPoshAdmin({
    onSuccess: () => {
      showToast({type: 'success', title: 'Updated account!'})
      refetch()
    },
    onError: error => showToast({type: 'error', title: 'Error updating account: ' + error.message}),
  })

  const {mutate: deleteAccountMutate} = useDeleteAccountPoshAdmin({
    onSuccess: () => {
      showToast({type: 'success', title: 'Deleted account!'})
      refetch()
    },
    onError: error => showToast({type: 'error', title: 'Error deleting account: ' + error.message}),
  })

  const fetchMoreUsers = () => {
    fetchNextPage({pageParam: data?.pages[data?.pages.length - 1].nextCursor ?? undefined})
  }

  const updateAccount = async ({id, item}: {id: string; item?: FetchAccountsAdminOutputItem}) => {
    if (!item) return
    updateAccountMutate({
      userId: item._id,
      email: item.email,
      firstName: item.firstName,
      lastName: item.lastName,
      instagram: item.instagram,
    })
  }

  const copyAccountId = async ({id}: {id: string}) => {
    const account = allAccounts?.find(c => c._id === id)
    if (account) {
      navigator.clipboard.writeText(account.email)
      showToast({type: 'success', title: `Copied user email to clipboard`})
    }
  }

  const deleteAccount = async ({id}: {id: string}) => {
    const account = allAccounts?.find(c => c._id === id)
    if (account) {
      setSelectedEmail(account.email)
    }
  }

  const CRUDPayload: AccountData = {
    key: 'account',
    data: allAccounts as FetchAccountsAdminOutputItem[],
    refetchDataFunction: refetch,
    entityName: 'Posh Account',
    updateFunction: updateAccount,
    copyFunction: copyAccountId,
    deleteFunction: deleteAccount,
  }

  return (
    <>
      <BackAndTitle title={'Posh Accounts'} />
      {loading && <SpinLoader height={40} />}
      <Input
        style={{margin: 'auto', display: 'block', width: '400px'}}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        placeholder='Search'
      />
      <CRUDTable payload={CRUDPayload} map={AccountTableMap} />
      <Button
        style={{margin: 'auto', textAlign: 'center'}}
        onClick={() => {
          fetchMoreUsers()
        }}>
        Load More Users
      </Button>
      {selectedEmail && (
        <ConfirmationModal
          isOpen={!!selectedEmail}
          onClose={() => setSelectedEmail(undefined)}
          title='Are you sure you want to delete this user?'
          bodyText={selectedEmail}
          errorMessage='There was an error deleting this user, please try again.'
          confirmationMessage='Successfully deleted user!'
          confirmButtonText='Delete User'
          onClick={() => deleteAccountMutate({email: selectedEmail})}
        />
      )}
    </>
  )
}

export default PoshAccounts
