import {useQuery} from '@tanstack/react-query'
import {IDispute} from 'apis/Groups'
import PoshApi from 'apis/PoshApi'

export interface DisputeResponse {
  dispute: IDispute
}

export async function fetchDispute(disputeId?: string): Promise<IDispute | null> {
  if (!disputeId) return null
  const response = await PoshApi.fetchDispute(disputeId)
  const data: DisputeResponse = response.data
  return data.dispute
}

export function useFetchDispute(disputeId?: string) {
  return useQuery(['dispute', disputeId], () => fetchDispute(disputeId))
}
