import React from 'react'
import {Route, Routes} from 'react-router-dom'

import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import {CreateEventPlaylistsPage} from './CreateEventPlaylistPage'
import {ManageEventPlaylistsPage} from './ManageEventPlaylistsPage'
import {UpdateEventPlaylistsPage} from './UpdateEventPlaylistPage'

export const ManageEventPlaylistPages = () => {
  return (
    <>
      <PoshHeader desktopOnly />

      <Routes>
        <Route path='/' element={<ManageEventPlaylistsPage />} />
        <Route path='/:playlistId' element={<UpdateEventPlaylistsPage />} />
        <Route path='/create' element={<CreateEventPlaylistsPage />} />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </>
  )
}
