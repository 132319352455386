/* eslint-disable */
import React from 'react'

import './styles.scss'

import Footer from 'components/DashboardFooter'
import MDEditor from '@uiw/react-md-editor'

export default function PrivacyPolicy() {
  const text = `# **POSH™ Privacy Policy**

  Last modified: October 24, 2023
  POSH GROUP INC., together with its affiliates, agents, representatives, consultants, employees, officers, and directors (collectively “POSH” “we,” “us,” or “our”) operates the POSH platform and websites (including  https://posh.vip/) (collectively, “Site”), mobile application (“App”), and related POSH products or services, including any new features and applications (collectively, such services together with the Site and App, the “Services”). 
  Your privacy is critically important to us.  It is POSH's policy to respect your privacy regarding any information we may collect from those who use our Services (“End Users,” “you,” or “your”) or those who may just access our Site without otherwise using our Services (“Visitors”). We have adopted this privacy policy (“Privacy Policy”) to explain what information may be collected on our Services, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Services and does not apply to our collection of information from other sources. This Privacy Policy, together with the Terms of Service posted on our Site, set forth the general rules and policies governing your use of our Services, whether as an End User or a Visitor (collectively, the “Users”). Depending on your activities when visiting our Services, you may be required to agree to additional terms and conditions.
  
  By using the Services, you agree to this Privacy Policy. If you do not agree with our policies and practices, your choice is to not use our Services.
  
  Summary of Data Collection, Disclosure and Sale
  Here is a short summary of data, the categories of data we have collected, disclosed, and / or sold over the last twelve months. We do not sell data, however, and the rest of this Privacy Policy provides more in-depth information on our privacy practices.
  
  Identifiers, such as contact details, such as real name, alias, address, telephone number, unique personal identifiers, online identifiers, IP address, email address, and account name.
  Do we collect? Yes
  Will we disclose this data as part of our business? Yes
  Will we sell this data? No
  Categories of PI described in the California Consumer Privacy Act (including name, email, phone number, billing address, credit card number and/or bank account number, SSN) 
  Do we collect? Yes
  Will we disclose this data as part of our business? Yes
  Will we sell this data? No
  Commercial information: (Transaction information, purchase history, financial details, and payment information)
  Do we collect? Yes
  Will we disclose this data as part of our business? Yes
  Will we sell this data? No
  Geolocation data: (device location)
  Do we collect? Yes
  Will we disclose this data as part of our business? Yes
  Will we sell this data? No
  Internet or other electronic network activity information: (Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements)
  Do we collect? Yes
  Will we disclose this data as part of our business? Yes
  Will we sell this data? No
  Inferences drawn from other personal information to create a profile about a consumer: (for example, an individual’s preferences and characteristics)
  Do we collect? Yes
  Will we disclose this data as part of our business? Yes
  Will we sell this data? No
  Audio, visual or similar information (profile pictures)
  Do we collect? Yes
  Will we disclose this data as part of our business? Yes
  Will we sell this data? No
  Biometric information (face imagery)
  Do we collect? Yes
  Will we disclose this data as part of our business? No
  Will we sell this data? No
  Characteristics of protected classifications under California or federal law (age, ethnicity, gender, date of birth, and marital status)
  Do we collect? No
  Professional or employment related information
  Do we collect? No
  Non-public education information (per the Family Educational Rights and Privacy Act)
  Do we collect? No
  
  INFORMATION THAT POSH COLLECTS
  
  Types of Information Collected
  Personal Data
  “Personal Data” is information by which you may be personally identified.  POSH may collect the following Personal Data from you:
  Name;
  Email;
  Username;
  Phone Number; 
  Profile Photo (optional);
  Social Media Usernames (optional);
  Billing Address;
  Credit Card or Debit Card Number; 
  Bank Account Number;
  Social Security Number (SSN) and/or Employer Identification Number (EIN) (if applicable). 
  
  Your billing address, payment information, or SSN/EIN is collected solely through our third-party vendors or payment processors. Such billing, payment, or identifying information is not collected or stored by POSH. 
  
  Non-Personal Data
  Non-personal data includes any data that cannot be used on its own to identify, trace, or identify a person. We may collect your device information or location information, including IP address, at our discretion. 
  
  When non-Personal Data you give to us is combined with Personal Data we collect about you, it will be treated as Personal Data and we will only use it in accordance with this Privacy Policy.
  
  How we collect information
  We collect information about you in a couple of ways: 
  (1) when you provide it do us directly through an interaction with us; for example
  When you register for the Services;
  When you fill out feedback forms and surveys;
  When you make payments through, or use paid features on, the Services;
  When you import information to the Services; 
  When you contact us for service or support requests. 
  (2) through automated collection methods like cookies and log files;
  (3) when we obtain the information through a third party, including third party data verification entities, payment processors, or from social media sites and other publicly available sources.
  
  Why we collect and how we use your information.  (Legal Basis)
  We collect and use your Personal Data when we have a legitimate purpose to do so, including the following reasons:
  
  to confirm your identity and/or eligibility to use the Services; 
  when it is necessary for the general functioning of the Services, including to facilitate orders, payments, or to contact you; 
  when it is necessary in connection with any contract you have entered into with us (including our Terms of Service or any Supplemental Terms that may apply to your use of the Services) or to take steps prior to entering into a contract with us;
  when it is necessary in connection with any contract you have entered into through the Services (including when you make a purchase from a third-party seller through the Services); 
  when we have obtained your or a third party’s prior consent;
  when we have a legitimate interest in processing your information for the purpose of providing or improving our Services (including when you choose to participate in new features of the Services); 
  when we have a legitimate interest in using the information for the purpose of contacting you, subject to compliance with applicable law; or
  when we have a legitimate interest in using the information for the purpose of detecting, and protecting against, breaches of our policies and applicable laws.
   
  POSH may collect statistics about the behavior of Users of the Services. We may use aggregated (anonymized) information about our Users, and information that does not identify any individual, without restriction, including to report on the trends in usage of our Services. 
  
  Accessing and Controlling Your Information
  If you would like to prevent us from collecting your information completely, you should cease use of our Services. You can also control certain data via these other methods:
  
  Correction capabilities: You have the ability to access and correct certain inaccuracies in your personally identifiable information by logging into the Services and editing the information through your Account or dashboard settings. You may also email us at our email address provided in the Questions and Comments section below to correct such inaccuracies. 
  
  Opt-out of non-essential electronic communications: You may opt out of receiving newsletters and other non-essential messages by using the ‘unsubscribe' function included in all such messages. However, you will continue to receive notices and essential transactional emails.
  
  Optional information: You can always choose not to fill in non-mandatory fields nor not to submit non-mandatory information or materials when you submit any form linked to our services, however this may prevent you from using certain features or engaging in certain activities on the Services.
  
  Under the California Consumer Privacy Act, the Virginia Consumer Data Protection Act, and Nevada’s online privacy laws, residents of these states have statutory data rights. We provide the same control and rights over your data no matter where you choose to live in the United States. As a User of POSH Services, you have the following control over your data:
  
  Right to access: You have the right to access (and obtain a copy of, if required) the categories of personal information that we hold about you, including the information's source, purpose and period of processing, and the persons to whom the information is shared.
  
  Right to rectification: You have the right to update the information we hold about you or to rectify any inaccuracies. Based on the purpose for which we use your information, you can instruct us to add supplemental information about you in our database.
  
  Right to erasure: You have the right to request that we delete your personal information in certain circumstances, such as when it is no longer necessary for the purpose for which it was originally collected.
  
  Right to restriction of processing: You may also have the right to request to restrict the use of your information in certain circumstances, such as when you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
  
  Right to data portability: You have the right to transfer your information to a third party in a structured, commonly used and machine-readable format, in circumstances where the information is processed with your consent or by automated means.
  
  Right to object: You have the right to object to the use of your information in certain circumstances, such as the use of your personal information for direct marketing
  
  Exercise Your Data Rights
  We acknowledge your right to request access, amendment, or deletion of your data. We also recognize that you have the right to prohibit sale of your data. 
  
  You can exercise the rights described above, by sending an email or mail to the addresses listed in the Questions and Comments section below. Only you, or an agent authorized to make a request on your behalf, may make a request related to your personal information. 
  
  We cannot respond to your request if, (i) we cannot verify your identity; or (ii) your request lacks sufficient details to help us handle the request. We will make best efforts to respond to your request withing forty five (45) days of its receipt. If we cannot respond in forty five (45) days, we will inform you, in writing, the reason for the delay and will respond to your request within ninety (90) days. Any information we provide will only cover the twelve (12) -month period preceding the request's receipt. 
  
  We do not charge a fee to process or respond to your request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request. We are not obligated to provide responses to your data requests more than twice in a twelve (12)-month period. 
  
  Users in the European Economic Area (EEA) and the GDPR
  For the purposes of data collection under the General Data Protection Regulation (GDPR), POSH shall be the data controller. If you are an individual resident in EEA, you have the following data protection rights regarding Personal Data:
  You have the right to access, correct, update or request deletion of your Personal Data. You can do so at any time by contacting us.
  You have the right to object to processing of your Personal Data, to ask us to restrict processing of your Personal Data, or to request portability of your Personal Data. Again, you can exercise these rights by contacting us.
  You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails we send you. To opt-out of other forms of marketing (such as postal marketing or telemarketing), please contact us.
  You have that right to revoke consent that was previously given. If we have collected and process your Personal Data with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your Personal Data conducted in reliance on lawful processing grounds other than consent.
  You have the right to complain to a data protection authority about our collection and use of your Personal Data
  
  For more information, please contact your local data protection authority. If you would like to exercise any of the above rights, please contact us at support@posh.vip.
  
  How Long do we Store Personal Data?
  We will only retain your Personal Data for as long as is reasonably necessary to fulfill the purposes for which it is collected, or to comply with applicable legal requirements, or until you ask us to delete your Personal Data. This length of time may vary according to the nature of your relationship with us.
  
  Users under the age of 16
  Our Services are not intended for children, and we do not knowingly collect Personal Data from children under 16. If we learn we have collected or received Personal Data from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at the email address listed below. 
  
  Automated Data Collection Methods
  Cookies. To enrich and perfect your online experience, POSH uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer. We use data collected from Cookies for a variety of reasons, including but not limited to integrations with Google Analytics software such as Optimizely and Mixpanel, and for ad campaign conversion measuring for users of our Site or Services. A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. POSH uses cookies to help POSH identify and track Users, their usage of the Services including http://www.posh.vip, and their website access preferences. Users who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using POSH's websites, with the drawback that certain features of the Services may not function properly without the aid of cookies. By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to POSH's use of cookies.
  
  Log Files. We use means to collect IP addresses, browser types, access times, and physical or device location. We use the information to ensure compliance and to improve our Services, verify identity, and monitor financial transactions.
  
  Advertisements
  Advertisements appearing on our Services may be delivered to Users by our advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by POSH only and does not cover the use of cookies by any third parties, including advertisers.
  
  Do Not Track Settings
  We do not track our Users over time and across third party websites to provide targeted advertising and do not specifically respond to Do Not Track (“DNT”) signals. 
  
  WHO WE SHARE DATA WITH
  We do not sell or otherwise disclose Personal Data specific personal or transactional information to anyone except as described below.
  
  Other Users
  We may share your information with other Users, including your username and email address, in order to enable your use of the Services, including when you make a purchase through our Services from those Users, or when you otherwise authorize us to share your information through the Services.
  
  Affiliates and Subsidiaries
  We may, for our legitimate interests, share your information with entities under common ownership or control with us who will process your information in a manner consistent with this Privacy Policy and subject to appropriate safeguards. Such parent companies, affiliates, or subsidiaries may be located in the United States.
  
  Successors in Interest
  We may, for our legitimate interests, share your information with a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, in which Personal Data about our Users is among the assets transferred. You will be notified of any such change by a prominent notice displayed on our Services or by e-mail. Any successor in interest to this Privacy Policy will be bound to the Privacy Policy at the time of transfer.
  Law enforcement and other governmental agencies
  We may share your information when we believe in good faith that such sharing is reasonably necessary to investigate, prevent, or take action regarding possible illegal activities or to comply with legal process. This may involve the sharing of your information with law enforcement, government agencies, courts, and other organizations.
  Service Providers
  We may, for our legitimate interests, share certain information with contractors, service providers, third party authenticators, and other third parties we use to support our business and who are bound by contractual obligations to keep Personal Data confidential and use it only for the purposes for which we disclose it to them. Some of the functions that our service providers provide are as follows: 
  Host server infrastructure and storage;
  Business analytics services;
  User and identity verification management; 
  Payment processing;
  Site log analytics service for activity, performance, and troubleshooting;
  Marketing, sales, and service management; and 
  Email management services.
  THIRD-PARTY SERVICES AND WEBSITES
  POSH is not responsible for the privacy policies or other practices employed by websites linked to, or from, our Services nor the information or content contained therein, and we encourage you to read the privacy statements of any linked third party. This includes, but is not limited to, the privacy policies or terms of service of any of our service providers. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third-party sites, products or services.
  
  DATA STORAGE AND HOW POSH PROTECTS YOUR INFORMATION
  POSH stores basic User data on our servers including name, email, phone number, username, and social media usernames. Payments are not always required by End Users. If an End User makes a purchase and a payment is required, then payment information is processed and stored by our partners or service providers. Personal Data about Users is stored within the United States and is protected by adequate international transfer agreements if necessary. If you are using the Services from the EEA or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your Personal Data to the United States. The United States may have laws which are different, and potentially not as protective, as the laws of your own country. By providing your Personal Data, you consent to any transfer and processing in accordance with this Privacy Policy. 
  
  POSH employs physical, electronic, and managerial control procedures to safeguard and help prevent unauthorized access to your information. We choose these safeguards based on the sensitivity of the information that we collect, process and store and the current state of technology. Our outsourced service providers who support our operations are also vetted to ensure that they too have the appropriate organizational and technical measures in place to protect your information.
  
  The security of your Personal Data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security. Any transmission of information through the Services is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Services. In the event that there is breach in the information that we hold, we shall notify of such breach via email or via notice on the Services. 
  
  CHANGES TO THE PRIVACY POLICY
  It is our policy to post any changes we make to our Privacy Policy on the Services. If we make material changes to how we treat our Users’ Personal Data, we will notify you by email to the primary email address specified in your account or through a prominent notice on the Site or App. Such changes will be effective when posted. The date the Privacy Policy was last revised is identified at the top of the page. Your continued use of our Services following the posting of any modification to this Privacy Policy shall constitute your acceptance of the amendments to this Privacy Policy. You can choose to delete the App, discontinue use of the Services, and request us to delete the Personal Data we may hold about you if you do not accept any modified version of this Privacy Policy.
  
  QUESTIONS AND COMMENTS
  If you have any questions or comments about this Privacy Policy, please contact us at support@posh.vip or by mail at: 
  
  POSH GROUP INC.
  110 Greene Street, Suite 10N
  New York, New York 10012   
  `

  return (
    <div className='PrivacyPolicy'>
      <div className='info-page-cont'>
        <MDEditor.Markdown className='PrivacyPolicy-markdown' source={text} />
      </div>
      <Footer />
    </div>
  )
}
