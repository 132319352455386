import {useMutation} from '@tanstack/react-query'
import {Tag} from 'apis/Community'
import PoshApi from 'apis/PoshApi'

export interface CreateTagParams {
  name: string
  background: string
  groupID: string
}

export async function addAccountsToTag(tagId: string, accountIds: string[], groupId: string): Promise<Tag> {
  const response = await PoshApi.addAccountsToTag(tagId, accountIds, groupId)
  const data = response.data
  return data.tag
}

export function useAddAccountsToTag() {
  return useMutation((params: {tagId: string; accountIds: string[]; groupId: string}) =>
    addAccountsToTag(params.tagId, params.accountIds, params.groupId),
  )
}
