import React, {useState} from 'react'

import Button from 'components/form/Button'
import {Checkbox} from 'components/form/checkbox/Checkbox'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'

type FilterOption = {
  value: string
  name: string
}

interface FilterModalOptionParams {
  option: FilterOption
  selectedOptions: string[]
  setSelectedOptions: (selectedOptions: string[]) => void
}

const FilterModalOption = (params: FilterModalOptionParams) => {
  const {option, selectedOptions, setSelectedOptions} = params
  return (
    <div className='FilterModal-filterModalOption'>
      <Checkbox
        className='noBackground fullWidth'
        checked={selectedOptions.includes(option.value)}
        label={option.name}
        onChecked={checked => {
          if (checked) {
            setSelectedOptions([...selectedOptions, option.value])
          } else {
            setSelectedOptions(selectedOptions.filter(selected => selected !== option.value))
          }
        }}
      />
    </div>
  )
}

interface FilterModalProps extends PoshStyledModalProps {
  onConfirm: (selected: string[]) => void
  defaultSelected?: string[]
}

const FilterModal = (props: FilterModalProps) => {
  const {onConfirm, defaultSelected} = props
  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultSelected || [])

  const options: FilterOption[] = [
    {name: 'Financed Orders', value: 'financed'},
    {name: 'Refunded Orders', value: 'refunded'},
    {name: 'Scanned Orders', value: 'scanned'},
    {name: 'Disputed Orders', value: 'disputed'},
    {name: 'Table Orders', value: 'tables'},
    {name: 'Canceled Orders', value: 'canceled'},
    {name: 'Kickback Orders', value: 'kickback'},
  ]

  return (
    <PoshStyledModal {...props} className='FilterModal'>
      <h3 className='center'>Apply Filter</h3>
      {options.map((option, index) => (
        <FilterModalOption
          key={index}
          option={option}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      ))}
      <Button className='FilterModal-button' onClick={() => onConfirm(selectedOptions)}>
        Confirm
      </Button>
    </PoshStyledModal>
  )
}

export default FilterModal
