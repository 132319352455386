import React from 'react'

import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

const FraudGroups = () => {
  return (
    <>
      <BackAndTitle title={'Un-verfied Groups Over $500 GMV This Week'} />
    </>
  )
}

export default FraudGroups
