import React from 'react'
import {DropzoneOptions} from 'react-dropzone'

import {MAX_FILE_UPLOAD_SIZE} from '@posh/types'
import classNames from 'classnames'
import {whiteCircularAddUrl} from 'components/assets/Icons'
import {DraggableList} from 'components/DraggableList'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import {CloseIcon} from 'pages/EventManagementPages/Visuals'

import {FileInput} from '../FilePicker'
import IconButton from '../IconButton'
import {PictureState} from '../ImagePicker'

import './index.scss'

interface GalleryEditorProps {
  images: PictureState[]
  setImages: React.Dispatch<React.SetStateAction<PictureState[]>>
  onImageDrop?: (acceptedFiles: File[]) => void
  onImageRemove?: (file: File) => void
}

interface GalleryImageProps {
  image: PictureState
  onRemoveImage: () => void
}

const GalleryImage = (props: GalleryImageProps) => {
  const {image, onRemoveImage} = props
  if (!image.url) return null
  return (
    <div className='GalleryEditor-image'>
      <PoshImage src={image.url} alt={image.url} />
      <IconButton className='transparent GalleryEditor-image-editButton' icon={CloseIcon} onClick={onRemoveImage} />
    </div>
  )
}

const GalleryEditor = (props: GalleryEditorProps) => {
  const {images, setImages, onImageDrop, onImageRemove} = props
  const {showToast} = useToast()
  const emptyImages = !images || images.length === 0

  const handleAccpetedImageDrop = async (acceptedFiles: File[]) => {
    const imageObjects = acceptedFiles.map(async file => {
      const localFileUrl = URL.createObjectURL(file)

      const pictureState: PictureState = {
        url: localFileUrl,
        file: file,
      }
      return pictureState
    })
    const imageObjectsResolved = await Promise.all(imageObjects)

    if (images) setImages([...images, ...imageObjectsResolved])
    else setImages(imageObjectsResolved)

    onImageDrop && onImageDrop(acceptedFiles)
  }

  const dropzoneOptions: DropzoneOptions = {
    accept: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'],
    onDropAccepted: acceptedImages => handleAccpetedImageDrop(acceptedImages),
    onDropRejected: () => showToast({type: 'error', title: 'File over 8mb or invalid file type'}),
    multiple: true,
    maxSize: MAX_FILE_UPLOAD_SIZE,
    noClick: !emptyImages,
  }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) return
    const newImages = [...(images || [])]
    newImages.splice(newIndex, 0, newImages.splice(oldIndex, 1)[0])
    setImages(newImages)
  }

  const onImageRemoveCallBack = (image: PictureState) => {
    setImages(images.filter(i => i !== image))
    if (image.file) onImageRemove && onImageRemove(image.file)
  }

  return (
    <div className='GalleryEditor'>
      <FileInput.Dropzone {...dropzoneOptions}>
        {openPicker => (
          <div className={classNames('GalleryEditor-dropzone', {emptystate: emptyImages})}>
            {emptyImages ? (
              <p>Drag and drop some images here, or click to select images</p>
            ) : (
              <>
                <DraggableList
                  onSortEnd={onSortEnd}
                  className='GalleryEditor-sortable'
                  draggedItemClassName='GalleryEditor-dragged'>
                  {images.map((image, index) => (
                    <GalleryImage
                      key={index}
                      image={image}
                      onRemoveImage={() => {
                        onImageRemoveCallBack(image)
                      }}
                    />
                  ))}
                </DraggableList>
                <PoshImage className='GalleryEditor-addImages' onClick={openPicker} src={whiteCircularAddUrl} />
              </>
            )}
          </div>
        )}
      </FileInput.Dropzone>
    </div>
  )
}

export default GalleryEditor
