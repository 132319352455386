import {useQuery} from '@tanstack/react-query'
import {EventPublic} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

export async function fetchUpcomingEvent(username: string): Promise<EventPublic | null> {
  const response = await PoshApi.fetchUpcomingEvent(username)
  const data = response.data
  return data.upcomingEventResponse
}

export function useFetchUpcomingEvent(username: string) {
  return useQuery(['upcomingEvent', username], () => fetchUpcomingEvent(username), {
    enabled: !!username,
  })
}
