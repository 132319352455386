import React from 'react'
import {Route, Routes} from 'react-router-dom'

import PageNotFound from 'pages/PageNotFound/PageNotFound'

import CommunityApprovals from './CommunityApprovals'

const CommunityAdminRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={'/approve'} element={<CommunityApprovals />} />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default CommunityAdminRoutes
