// Put these types in a package?
import React, {createContext, PropsWithChildren, useCallback, useContext, useState} from 'react'

import {Toast} from './Toast'

import './styles.scss'

export type ToastType = 'error' | 'success' | 'warning' | 'info'

const DEFAULT_TOAST_DURATION_MS = 4000
// Should match up with slide in duration in stylesheet
const TOAST_SLIDE_IN_DURATION_MS = 500
// Should match up with fuse delay in stylesheet
const FUSE_DELAY_DURATION_MS = 150

export interface IToast {
  id: number
  type: ToastType
  title: string
  subtitle?: string
  duration: number
}

export type Toast = Omit<IToast, 'id' | 'duration'>

export interface ToastContext {
  showToast: (toast: Toast, duration?: number) => void
}

const ToastContext = createContext<ToastContext>({
  showToast: () => {},
})

export const useToast = () => useContext(ToastContext)

export const ToastProvider = (props: PropsWithChildren<unknown>) => {
  const {children} = props
  const [toasts, setToasts] = useState<IToast[]>([])

  const hideToast = useCallback((id: number) => {
    setToasts(prev => prev.filter(toast => toast.id !== id))
  }, [])

  const showToast = useCallback(
    (toast: Toast, duration: number = DEFAULT_TOAST_DURATION_MS) => {
      if (toasts.length >= 1) return
      const id = Date.now()
      const adjustedDuration = duration + TOAST_SLIDE_IN_DURATION_MS + FUSE_DELAY_DURATION_MS
      setToasts(prev => [...prev, {...toast, id: id, duration: adjustedDuration}])
      setTimeout(() => {
        hideToast(id)
      }, adjustedDuration)
    },
    [toasts.length],
  )

  return (
    <ToastContext.Provider value={{showToast}}>
      {children}
      <div className='ToastContainer'>
        {toasts.map((toast, index) => (
          <Toast key={index} toast={toast} />
        ))}
      </div>
    </ToastContext.Provider>
  )
}
