import React, {useState} from 'react'

import {OrderTag, OrderTags} from 'apis/Orders'
import Button from 'components/form/Button'
import {Checkbox} from 'components/form/checkbox/Checkbox'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'

import {Text} from '../../Shared/Text/Text'

interface OrderFilterModalProps {
  isOpen: boolean
  onClose: () => void
  filters: OrderTag[]
  onChangeFilters: (filters: OrderTag[]) => void
  isRSVPEvent?: boolean
}

const RSVPTags = ['scanned', 'canceled'] as const

export const OrderFilterModal = (props: OrderFilterModalProps) => {
  const {isOpen, onClose, filters, onChangeFilters, isRSVPEvent} = props

  const [selectedFilters, setSelectedFilters] = useState<OrderTag[]>(filters)

  const onApplyFilters = () => {
    onChangeFilters(selectedFilters)
    onClose()
  }

  const onCloseWrapper = () => {
    setSelectedFilters(filters)
    onClose()
  }

  const resource = isRSVPEvent ? 'RSVPs' : 'Orders'
  const tags = isRSVPEvent ? RSVPTags : OrderTags

  return (
    <PoshStyledModal isOpen={isOpen} onClose={onCloseWrapper}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
        <Text size='xl' bold>
          Filter {resource}
        </Text>

        <div style={{width: '100%'}}>
          {tags
            .filter(tag => tag !== 'scanned')
            .map(filter => (
              <Checkbox
                key={filter}
                label={`${filter.charAt(0).toUpperCase()}${filter.slice(1)} ${resource}`}
                checked={selectedFilters.includes(filter)}
                onChecked={() =>
                  setSelectedFilters(
                    selectedFilters.includes(filter)
                      ? selectedFilters.filter(f => f !== filter)
                      : [...selectedFilters, filter],
                  )
                }
                color='white'
                hideBackground></Checkbox>
            ))}
        </div>

        <Button onClick={onApplyFilters}>Apply Filters</Button>
      </div>
    </PoshStyledModal>
  )
}
