import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface CreateCustomNumberCheckoutSessionParams {
  groupId: string
  groupCustomNumber: string
}

async function createCustomNumberCheckoutSession(params: CreateCustomNumberCheckoutSessionParams) {
  const response = await PoshApi.createCustomNumberCheckoutSession(params)
  return response.data
}

export default function useCreateCustomNumberCheckoutCheckoutSession() {
  return useMutation((params: CreateCustomNumberCheckoutSessionParams) => createCustomNumberCheckoutSession(params))
}
