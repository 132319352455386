import {useQuery} from '@tanstack/react-query'

import PoshApi from '../PoshApi'
import {PermissionResponse} from './Permission'
import {RoleKey, ScopeKey} from './Role'

export async function fetchApplicablePermissionsForRoleAndScope(
  roleKey: RoleKey,
  scopeKey: ScopeKey,
): Promise<PermissionResponse[]> {
  const response = await PoshApi.fetchApplicablePermissionsForRoleAndScope(roleKey, scopeKey)
  const data = response.data
  return data.permissions
}

export default function useFetchApplicablePermissionsForRoleAndScope(roleKey?: RoleKey, scopeKey?: ScopeKey) {
  return useQuery(
    ['applicablePermissions', roleKey, scopeKey],
    () => {
      if (!roleKey || !scopeKey) return
      return fetchApplicablePermissionsForRoleAndScope(roleKey, scopeKey)
    },
    {
      enabled: false,
    },
  )
}
