import React, {createContext, useContext} from 'react'

import {Ticket} from 'apis/Events/types'

import {CartItem} from '../../apis/Carts'
import {TicketsCountState} from './CartContextProvider'

export interface CartContextState {
  ticketsCount: TicketsCountState
  promoCode: string
  trackingLink: string
  tables: string[]
  paymentPlanIndex: number
  addTables: (tables: string[]) => void
  addTable: (tableId: string) => void
  removeTable: (tableId: string) => void
  addTicket: (ticketId: Ticket) => void
  removeTicket: (ticketId: Ticket) => void
  handleSetTicketCount: (ticketId: Ticket, quantity: number) => void
  setPromoCode: React.Dispatch<React.SetStateAction<string>>
  setTrackingLink: React.Dispatch<React.SetStateAction<string>>
  setPaymentPlanIndex: React.Dispatch<React.SetStateAction<number>>
  cartItems: CartItem[]
  resetCart: () => void
}

export const CartContext = createContext<CartContextState | null>(null)

export default function useCartContext() {
  const context = useContext(CartContext)
  return context!
}
