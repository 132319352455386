import React from 'react'

import useFetchAdminGroupDashboard from 'apis/AdminDashboard/useFetchAdminGroupDashboard'

interface GroupRowBProps {
  group: any
}

const GroupRowB = (props: GroupRowBProps) => {
  const {group} = props
  function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return (
    <tr>
      <td>{group.name}</td>
      <td>{group.firstOwner.name}</td>
      <td>{group.numOfEvents}</td>
      <td>${numberWithCommas(group.gmv.toFixed(0))}</td>
    </tr>
  )
}

const GroupDashboard = () => {
  const {data: data, isFetching: isLoading} = useFetchAdminGroupDashboard()
  return (
    <>
      {isLoading && !data ? (
        <div style={{color: 'white'}}>FETCHING</div>
      ) : (
        <div className='AdminDashboard'>
          <table id='AdminDashboard-tableA'>
            <tr>
              <th>Name</th>
              <th>Created By</th>
              <th># of Events</th>
              <th>All-time GMV</th>
            </tr>
            {data && data.length > 0 && data.map((group, index) => <GroupRowB key={index} group={group} />)}
          </table>
        </div>
      )}
    </>
  )
}

export default GroupDashboard
