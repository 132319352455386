import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface UpdatePendingCommunity {
  id: string
  approvalStatus: 'approved' | 'denied'
  communityType: 'venue' | 'artist' | 'staff'
}

async function updatePendingCommunity(params: UpdatePendingCommunity) {
  const response = await PoshApi.updatePendingCommunity(params)
  return response.data
}

export default function useUpdatePendingCommunity() {
  return useMutation((params: UpdatePendingCommunity) => updatePendingCommunity(params))
}
