import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import useFetchEvent from 'apis/Events/useFetchEvent'
import {useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import {useMixpanel} from 'apis/MixPanelHandler'
import getEventFlyer from 'apis/Util/getEventFlyer'
import CopyBlock from 'components/CopyBlock'
import {EventQRCode} from 'components/EventQRCode'
import Button from 'components/form/Button'
import {EventStatusSlider} from 'components/form/EventStatusButton'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useGroupBaseUrl} from 'domains/Groups/helpers'
import moment from 'moment'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import './styles.scss'

export type EventStatus = 'live' | 'draft' | ''

const EventLaunch = () => {
  const {eventId, groupId} = useResourcePageParams()
  const {data: eventData, refetch} = useFetchEvent(eventId!)
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const baseUrl = useGroupBaseUrl()
  const event = eventData?.event

  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const navigate = useNavigate()

  const [status, setStatus] = useState<EventStatus>('')

  useEffect(() => {
    if (status === 'live') trackMixpanelEvent('Go Live- After Create Event Ready To Go Live Page')
    else if (status === 'draft') trackMixpanelEvent('Save Draft-  After Create Event Ready To Go Live Page')
  }, [status])

  const updateEventStatus = async (status: EventStatus) => {
    await updateEvent({
      eventId: eventId!,
      groupId: groupId!,
      eventData: {
        status: status,
      },
    })
    await refetch()
  }

  useEffect(() => {
    if (status === 'draft') updateEventStatus('draft')
    if (status === 'live') updateEventStatus('live')
  }, [status])

  return (
    <>
      <PoshHelmet title={`${event?.name}`} />
      {event && (
        <div className='GroupPage LE'>
          <div className='GroupPage-content'>
            <div className='GroupPage-content-inner'>
              <div className='LE-cols'>
                <div className='LE-cols-left'>
                  <h1>{event?.name} is ready to go live!</h1>
                  <p>
                    Going live means your event will display in your group profile, event search results and trending
                    pages.
                  </p>

                  <div className='LE-statusRow'>
                    <EventStatusSlider status={status} setStatus={setStatus} />
                    {status === 'live' && (
                      <Button
                        className='LE-statusRow-overview Button--dark'
                        onClick={() => navigate(`${baseUrl}/events/${event?._id}/overview`)}>
                        Event Overview &rarr;
                      </Button>
                    )}
                    {status === 'draft' && (
                      <Button
                        className='LE-statusRow-overview Button--dark'
                        onClick={() => navigate(`${baseUrl}/overview`)}>
                        Back to Dashboard &rarr;
                      </Button>
                    )}
                  </div>

                  <hr />

                  <div>
                    <label>Event Page URL</label>
                    <CopyBlock
                      links={`https://posh.vip/e/${event?.url}`}
                      onCopy={() => {
                        trackMixpanelEvent('Copy Link-  After Create Event Ready To Go Live Page')
                      }}
                    />
                  </div>
                  <EventQRCode
                    url={event.url}
                    onDownload={() => {
                      trackMixpanelEvent('QR Code Click-  After Create Event Ready To Go Live Page')
                    }}
                  />
                </div>

                <div className='LE-cols-right'>
                  <PoshImage src={getEventFlyer(event)} className='LE-flyer' />
                  <table>
                    <tr>
                      <td>Event Start</td>
                      <td>
                        {moment
                          .tz(event?.startUtc, event?.timezone ?? 'America/New_York')
                          .format('dddd, MMM D, h:mm A')}
                      </td>
                    </tr>
                    <tr>
                      <td>Event End</td>
                      <td>
                        {moment.tz(event?.endUtc, event?.timezone ?? 'America/New_York').format('dddd, MMM D, h:mm A')}
                      </td>
                    </tr>
                    <tr>
                      <td>Venue</td>
                      <td>{event?.venue.name}</td>
                    </tr>
                  </table>
                  <div className='LE-actions'>
                    <div
                      className='LE-action'
                      onClick={() => {
                        trackMixpanelEvent('View Event-  After Create Event Ready To Go Live Page')
                        return navigate(`/e/${event?.url}`)
                      }}>
                      <div className='LE-action-icon'>
                        <PoshImage src='https://images.posh.vip/b2/eye-gold.svg' />
                      </div>
                      <div className='LE-action-label'>View Event</div>
                    </div>
                    <div
                      className='LE-action'
                      onClick={() => {
                        trackMixpanelEvent('Configure Settings-  After Create Event Ready To Go Live Page')
                        return navigate(`${baseUrl}/events/${event?._id}/settings`)
                      }}>
                      <div className='LE-action-icon'>
                        <PoshImage src='https://images.posh.vip/b2/gear.svg' />
                      </div>
                      <div className='LE-action-label'>Configure Settings</div>
                    </div>
                    <div
                      className='LE-action'
                      onClick={() => {
                        trackMixpanelEvent('Customize Visuals-  After Create Event Ready To Go Live Page')
                        return navigate(`${baseUrl}/events/${event?._id}/visuals`)
                      }}>
                      <div className='LE-action-icon'>
                        <PoshImage src='https://images.posh.vip/b2/paintbrush.svg' />
                      </div>
                      <div className='LE-action-label'>Customize Visuals</div>
                    </div>
                    <div
                      className='LE-action'
                      onClick={() => {
                        trackMixpanelEvent('Invite Team Members-  After Create Event Ready To Go Live Page')
                        return navigate(`${baseUrl}/events/${event?._id}/ team`)
                      }}>
                      <div className='LE-action-icon'>
                        <PoshImage src='https://images.posh.vip/b2/man.svg' />
                      </div>
                      <div className='LE-action-label'>Invite Team Members</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EventLaunch
