import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface FetchKickBackPayoutLinkProps {
  userId: string
  pathname: string
}

const fetchKickBackPayoutLink = async (params: FetchKickBackPayoutLinkProps) => {
  const response = await PoshApi.fetchKickbackPayoutLink(params)
  return response.data
}

export function useFetchKickBackPayoutLink() {
  return useMutation<string, unknown, FetchKickBackPayoutLinkProps>(params => fetchKickBackPayoutLink(params))
}
