import {useQuery} from '@tanstack/react-query'
import {EventPublic, GroupPublic} from 'apis/Events/types'

import PoshApi from '../PoshApi'
export interface GroupUrlResponse {
  group?: GroupPublic
  events: EventPublic[]
}

async function checkgroupUrl(url: string): Promise<GroupUrlResponse> {
  const response = await PoshApi.checkGroupUrl(url)
  return response.data
}

export default function useCheckGroupUrl(url?: string) {
  return useQuery(['groupUrl', url], () => checkgroupUrl(url ?? ''), {
    enabled: !!url,
  })
}
