import React from 'react'

import Gallery from './Gallery'

import './styles.scss'

export default function GalleryManagementPage() {
  return (
    <div className='GalleryManagement'>
      <h1>Event Photo Gallery</h1>
      <p>Upload photos from the event and show them to your attendees!</p>
      <Gallery />
      <div className='GalleryManagement-divider' />
    </div>
  )
}
