import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {AttendeeRowAttributesEvents, CheckoutFieldNameAndId, FetchEventAttendeesParams} from './types'

export interface EventAttendeesResponse {
  attendees: AttendeeRowAttributesEvents[]
  eventHasCustomCheckoutFields: boolean
  checkoutFieldNamesAndIds?: CheckoutFieldNameAndId[]
  hasMore: boolean
}

export async function fetchEventAttendees(params: FetchEventAttendeesParams): Promise<EventAttendeesResponse> {
  const response = await PoshApi.fetchEventAttendees(params)
  const data: EventAttendeesResponse = response.data
  return data
}

export default function useFetchEventAttendees(params: FetchEventAttendeesParams) {
  return useQuery(['eventAttendees', params], () => fetchEventAttendees(params), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}
