import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {AttendeeRowAttributes, FetchGroupAttendeesParams, GroupAttendeesResponse} from './index'

export async function fetchGroupAttendee(
  groupId: string,
  attendeeId: string,
): Promise<AttendeeRowAttributes | undefined> {
  const response = await PoshApi.fetchGroupAttendee(groupId, attendeeId)
  return response.data.attendees
}

export async function fetchGroupAttendees(params: FetchGroupAttendeesParams) {
  const response = await PoshApi.fetchGroupAttendees(params)
  const data: GroupAttendeesResponse = response.data
  return data
}

export function useFetchGroupAttendeesPaged(params: FetchGroupAttendeesParams) {
  return useQuery(['groupAttendeesPaged', params], () => fetchGroupAttendees(params), {keepPreviousData: true})
}
export function useFetchGroupAttendee(groupId: string, attendeeId: string) {
  return useQuery(['groupAttendee', groupId, attendeeId], () => fetchGroupAttendee(groupId, attendeeId), {
    enabled: !!attendeeId,
  })
}
