import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchOrgsOptions = ReactQueryOptions['poshAdmin']['poshAdminOrgsRouter']['fetchOrgs']
type FetchOrgsInput = RouterInputs['poshAdmin']['poshAdminOrgsRouter']['fetchOrgs']
export type FetchOrgsAdminOutput = RouterOutput['poshAdmin']['poshAdminOrgsRouter']['fetchOrgs']
export type FetchOrgsAdminOutputItem = FetchOrgsAdminOutput['items']['0']

export function useFetchOrgs(input: FetchOrgsInput) {
  return trpc.poshAdmin.poshAdminOrgsRouter.fetchOrgs.useInfiniteQuery(input)
}
