import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {OrderReceiptResponse} from '.'

export async function fetchOrderReceiptByCartId(cartId: string): Promise<OrderReceiptResponse> {
  const response = await PoshApi.fetchOrderReceiptByCartId(cartId)
  return response.data
}

export function useFetchOrderReceiptByCartId(cartId: string) {
  return useQuery(['orderReceiptByCartId', cartId], () => fetchOrderReceiptByCartId(cartId), {
    retry: true,
  })
}
