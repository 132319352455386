import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {IPermissionConstraints} from '.'
import {RoleKey, ScopeKey} from './Role'

export interface AddTeamMemberBody {
  accountId: string
  scope: ScopeKey
  role: RoleKey
  assignedBy: string
  entity: string
  parentEntity?: string
  permissionConstraints?: IPermissionConstraints
}

async function addTeamMember(params: AddTeamMemberBody): Promise<string> {
  const response = await PoshApi.addTeamMember(params)
  return response.data.message
}

export default function useAddTeamMember() {
  return useMutation<string, unknown, AddTeamMemberBody>(params => addTeamMember(params))
}
