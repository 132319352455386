import React from 'react'

import {OrderResponse} from '@posh/model-types'

import CheckoutFieldFileResponse from './CheckoutFieldFileResponse'
import CheckoutFieldTextResponse from './CheckoutFieldTextResponse'

const ResponsesMapper = (responses: OrderResponse[], eventId: string) => {
  return responses.map((response, index) => {
    if (response.type === 'file_upload') {
      return <CheckoutFieldFileResponse key={index} response={response} eventId={eventId} />
    } else {
      return <CheckoutFieldTextResponse key={index} {...response} />
    }
  })
}

export default ResponsesMapper
