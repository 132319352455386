import React, {useState} from 'react'
import ReactCodeInput from 'react-code-input'

import {useCheckVerificationCode} from 'apis/Accounts/useCheckVerificationCode'
import {useVerifyPhoneNumber} from 'apis/Accounts/useVerifyPhoneNumber'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {useDimensions} from 'hooks/useDimensions'
import {phoneStyle} from 'pages/NewCheckoutPage/LoginForm/PhoneLoginForm/secondStep'

import './styles.scss'

export default function PhoneVerificationModal(props: PoshStyledModalProps) {
  const [token, setInputToken] = useState('')
  const [loading, setLoading] = useState(false)
  const {mutateAsync: sendPhoneVerificationCode} = useVerifyPhoneNumber()
  const {mutateAsync: checkVerificationCode} = useCheckVerificationCode()
  const {currentUser, userId, setCurrentUser} = useSessionContext()
  const [hasSentMessage, setHasSentMessage] = useState(false)
  const {showToast} = useToast()
  const [successMessage, setSuccessMessage] = useState('')
  const {isMobile} = useDimensions()

  const setPhoneVerification = () => {
    const storageString = localStorage.getItem('currentUser') as string
    const storageObject = JSON.parse(storageString)
    storageObject['phoneVerified'] = true
    localStorage.setItem('currentUser', JSON.stringify(storageObject))
    setCurrentUser(storageObject)
  }

  const onSendText = async () => {
    setLoading(true)
    setSuccessMessage('')
    if (userId) {
      const response = await sendPhoneVerificationCode()
      if (response) {
        setHasSentMessage(true)
        setLoading(false)
      } else
        showToast({
          type: 'error',
          title:
            'There was something wrong with sending your verification code. Please make sure you are sending it to the correct number.',
        })
      setLoading(false)
    }
  }
  const onVerify = async () => {
    setLoading(true)
    if (userId) {
      setSuccessMessage('')
      if (token.length !== 6) {
        showToast({type: 'error', title: 'Please input a valid verification code'})
        setLoading(false)
      } else {
        const isVerified = await checkVerificationCode({token})
        if (isVerified) {
          setPhoneVerification()
          setSuccessMessage('Phone number successfully verified!')
          setLoading(false)
        } else {
          showToast({type: 'error', title: 'Your verification code is incorrect or expired, please try again'})
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <PoshStyledModal
        isOpen={props.isOpen}
        onClose={() => {
          props.onClose()
        }}>
        {successMessage && <p style={{color: 'green', fontWeight: 'lighter', textAlign: 'center'}}>{successMessage}</p>}
        {hasSentMessage ? (
          <>
            <h3 className='center'>Sent verification code to {currentUser!.phone}</h3>
            <div className='VerifyModal-inputWrapper'>
              <ReactCodeInput
                className='VerifyModal-codeInput'
                type='number'
                fields={6}
                name='phoneCode'
                inputMode={'tel'}
                inputStyle={phoneStyle(isMobile)}
                value={token}
                onChange={e => setInputToken(e)}
              />
              <Button isLoading={loading} disabled={loading} onClick={onVerify}>
                Verify
              </Button>
            </div>
            <p className='VerifyModal-resend'>
              Didn&apos;t receive a code?&nbsp;
              <a
                onClick={() => {
                  onSendText()
                }}>
                <u>Resend code</u>
              </a>
            </p>
          </>
        ) : (
          <>
            <h3 className='center'>Verify Phone Number</h3>
            {loading ? (
              <div className='VerifyModal-loading'>
                <SpinLoader height={30} />
              </div>
            ) : (
              <Button
                className='fullWidth'
                style={{marginBottom: '20px'}}
                onClick={() => {
                  onSendText()
                }}>
                Send Verification code
              </Button>
            )}
          </>
        )}
      </PoshStyledModal>
    </>
  )
}
