import * as datadog from './datadog'
import * as sentry from './sentry'

export type MonitoringProps = {
  env: string
}

export function mount(props: MonitoringProps) {
  datadog.mount(props)
  sentry.mount(props)
}
