import React from 'react'

import {PayoutsDisabledNotificationMetadata} from '@posh/types'
import {AlertSolid} from 'components/assets/Icons'

export const PayoutsDisabledNotification = (props: PayoutsDisabledNotificationMetadata) => {
  const {orgId, orgName} = props

  const ORG_OVERVIEW_URL = `/admin/groups/${orgId}/overview`
  const ORG_FINANCE_URL = `/admin/groups/${orgId}/finance`

  return (
    <div className='Notification-content'>
      <AlertSolid className='Notification-content-warning' />
      <p className='text-small'>
        Payouts for <a href={ORG_OVERVIEW_URL}>{orgName}</a> have been disabled, please go to the{' '}
        <a href={ORG_FINANCE_URL}>Finance Tab</a> in your organization to set this up.
      </p>
    </div>
  )
}
