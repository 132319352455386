// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountStatusContainer__4GHdB{display:flex;flex-direction:column;gap:10px}.OnboardingRequested_requirementsList__eU-0a{max-width:100%;word-wrap:break-word}", "",{"version":3,"sources":["webpack://./src/pages/GroupPage/Finance/AccountStatusCard.module.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,6CACE,cAAA,CACA,oBAAA","sourcesContent":[".AccountStatusContainer {\n  display:flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.OnboardingRequested_requirementsList {\n  max-width: 100%;\n  word-wrap: break-word;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountStatusContainer": "AccountStatusContainer__4GHdB",
	"OnboardingRequested_requirementsList": "OnboardingRequested_requirementsList__eU-0a"
};
export default ___CSS_LOADER_EXPORT___;
