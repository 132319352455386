import React from 'react'
import {useParams} from 'react-router-dom'

import {useFetchEventByHexUrl} from 'apis/Events/useFetchEvent'
import EventPage from 'pages/EventPage'

const ShortEventUrlPage = () => {
  const {hexUrl} = useParams<{hexUrl: string}>()
  const {data: event} = useFetchEventByHexUrl(hexUrl)

  return <>{event && event.url && <EventPage eventUrlProps={event.url} />}</>
}
export default ShortEventUrlPage
