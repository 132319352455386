import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchCampaignDetailedAnalyticsOptions =
  ReactQueryOptions['poshAdmin']['poshAdminCampaignRouter']['fetchCampaignDetailedAnalytics']
type FetchCampaignDetailedAnalyticsInput =
  RouterInputs['poshAdmin']['poshAdminCampaignRouter']['fetchCampaignDetailedAnalytics']
export type FetchCampaignDetailedAnalyticsAdminOutput =
  RouterOutput['poshAdmin']['poshAdminCampaignRouter']['fetchCampaignDetailedAnalytics']

export function useFetchCampaignDetailedAnalytics(campaignId?: string, detailedSummaryClassProperty?: string) {
  return trpc.poshAdmin.poshAdminCampaignRouter.fetchCampaignDetailedAnalytics.useQuery(
    {campaignId: campaignId!, detailedSummaryClassProperty: detailedSummaryClassProperty!},
    {
      enabled: !!campaignId && !!detailedSummaryClassProperty,
    },
  )
}
