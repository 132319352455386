import {OrderResponse} from '@posh/model-types'

const isMultipleEventResponses = (
  responses: any,
): responses is {eventName: string; eventResponses?: OrderResponse[]}[] => {
  // Perform a type check to determine if responses is a nested array of ({eventName: string} & OrderResponse) objects
  return !!responses[0].eventName
}

export default isMultipleEventResponses
