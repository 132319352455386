import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useCreateEventPlaylist} from 'apis/Events/playlists/manage/useCreateEventPlaylist'
import {useToast} from 'components/toasts/ToastProvider'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import {EventPlaylistForm} from './components/EventPlaylistForm'

import styles from './CreateEventPlaylistPage.module.scss'

export const CreateEventPlaylistsPage = () => {
  const navigate = useNavigate()
  const {showToast} = useToast()
  const {mutate: createEventPlaylist, isLoading: isCreatingEventPlaylist} = useCreateEventPlaylist({
    onSuccess: () => {
      navigate('/manage-event-playlists')
    },
    onError: err => {
      showToast({title: 'Failed to create playlist', subtitle: err.message, type: 'error'})
    },
  })

  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageContent}>
        <BackAndTitle title='Create Event Playlist' center />
        <EventPlaylistForm
          submitText='Create'
          onSubmit={createEventPlaylist}
          isSubmitting={isCreatingEventPlaylist}
          persistToStorage={{key: 'create-event-playlist-form', storage: window.sessionStorage}}
        />
      </div>
    </div>
  )
}
