import React from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {useRemovePromoCode} from 'apis/Events/useRemovePromoCode'
import {useUpdatePromoCode} from 'apis/Events/useUpdatePromoCode'
import {Form} from 'components/DynamicForm/Form/Form'
import Button from 'components/form/Button'
import {TableIcon} from 'components/TableUI/TableIcons/TableIcon'
import {useToast} from 'components/toasts/ToastProvider'

import {PromoCode} from './PromoCodesSection'
import {getUpdatePromoCodeFormConfig, getUpdatePromoCodeFormSchema, TicketMapping} from './utils/form-configs'

interface PromoCodeEditFormProps {
  eventId: string
  currentPromoCode: PromoCode
  promoCodes: PromoCode[]
  ticketMapping: TicketMapping[]
  onClose: () => void
}

export const PromoCodeEditForm = (props: PromoCodeEditFormProps) => {
  const {eventId, currentPromoCode, promoCodes, ticketMapping, onClose} = props

  const {showToast} = useToast()
  const queryClient = useQueryClient()

  const invalidateAndClose = () => {
    queryClient.invalidateQueries(['eventPromoCodes'])
    onClose()
  }

  const {mutate: removePromoCode} = useRemovePromoCode({
    onSuccess: () => {
      invalidateAndClose()
    },
    onError: err => {
      showToast({type: 'error', title: `Something went wrong when removing promo code: ${err.response.data.error}`})
    },
  })

  const {mutate: updatePromoCode, isLoading: isUpdatingPromoCode} = useUpdatePromoCode({
    onSuccess: ({updated}) => {
      showToast({type: 'success', title: `Updated promo code: ${updated.code}`})
      invalidateAndClose()
    },
    onError: err => {
      showToast({type: 'error', title: `Something went wrong when updating promo code: ${err.response.data.error}`})
    },
  })

  const inputConfig = getUpdatePromoCodeFormConfig(ticketMapping)
  const schema = getUpdatePromoCodeFormSchema(promoCodes, currentPromoCode)

  return (
    <>
      <Form
        inputConfig={inputConfig}
        schema={schema}
        onSubmit={data => {
          updatePromoCode({
            eventId,
            promoCodeId: data.id,
            updates: {
              code: data.code,
              type: data.type,
              value: data.value,
              limit: data.limit ?? 0,
              limitedToTicket: data.limitedTo,
              disabled: !data.enabled,
            },
          })
        }}
        submitText='Save Changes'
        isSubmitting={isUpdatingPromoCode}
        onCancel={onClose}
        defaultValues={{
          id: currentPromoCode.id,
          code: currentPromoCode.code,
          type: currentPromoCode.discount.type,
          value: currentPromoCode.discount.value,
          limit: currentPromoCode.limit,
          limitedTo: currentPromoCode.limitedTo,
          enabled: currentPromoCode.enabled,
        }}
      />
      {currentPromoCode.uses === 0 && (
        <Button
          className='fit destructive'
          onClick={() => removePromoCode({eventId, promoCodeId: currentPromoCode.id})}>
          <TableIcon name='trash' width={16} height={16} />
          Delete Promo Code
        </Button>
      )}
    </>
  )
}
