import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface UpdateKickBackValueProps {
  id: string
  value: number
}

const updateKickBackValue = async (params: UpdateKickBackValueProps) => {
  const response = await PoshApi.updateKickBackValue(params)
  return response.data
}

export function useUpdateKickBackValue() {
  return useMutation<unknown, unknown, UpdateKickBackValueProps>(params => updateKickBackValue(params))
}
