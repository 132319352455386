import React, {useMemo, useState} from 'react'

import {DEFAULT_ACCOUNT_AVI} from '@posh/model-types'
import {useQueryClient} from '@tanstack/react-query'
import useFetchEvent from 'apis/Events/useFetchEvent'
import {useMixpanel} from 'apis/MixPanelHandler'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import moment from 'moment'
import AddTeamMemberWrapperComponent from 'pages/GroupPage/Team/AddTeamMemberWrapperComponent'

import {TeamMemberResponse} from '../../../apis/Roles'
import useFetchTeam from '../../../apis/Roles/useFetchTeam'
import {requirePermissionAndScope} from '../../../components/RequirePermissions'
import {ColumnConfig} from '../../../components/TableUI/CRUDTable/internals/types'
import {TableCell} from '../../../components/TableUI/TableCell/TableCell'
import useSessionContext from '../../../domains/Auth/SessionContext'
import {useResourcePageParams} from '../../PoshAppLayout'
import TeamManagementModal from '../TeamManagementModal'
import {
  TeamManagementModalContextProvider,
  TeamManagementModalOptions,
} from '../TeamManagementModal/TeamManagementModalContext'

interface TableFormattedTeamMemberResponse extends TeamMemberResponse {
  accountInfo: {
    avi: string
    name: string
  }
}

export default function TeamsSection() {
  const {eventId} = useResourcePageParams()
  const {userId} = useSessionContext()
  const {data: eventScopeTeam, dataUpdatedAt, refetch} = useFetchTeam(eventId!, 'event')
  const {data: event} = useFetchEvent(eventId!)
  const queryClient = useQueryClient()
  const [isShowingAddTeamModal, setIsShowingAddTeamModal] = useState(false)
  const [teamManagementModalOptions, setTeamManagementModalOptions] = useState<TeamManagementModalOptions>({})
  const [isShowingTeamManagementModal, setIsShowingTeamManagementModal] = useState(false)

  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const onClickAddTeamMember = () => {
    trackMixpanelEvent('Add Team Member - Curator Event Team Page', {eventStatus: event?.event.status ?? ''})
    setIsShowingAddTeamModal(true)
  }

  const onClickContinue = () => {
    trackMixpanelEvent('Continue Click- Event Team Non-Posh User Modal', {eventStatus: event?.event.status ?? ''})
  }

  const onClickAddTeamMemberInModal = (role: string) => {
    trackMixpanelEvent('Add Role- Event Team Non-Posh User Modal', {
      eventStatus: event?.event.status ?? '',
      teamMemberRole: role,
    })
  }

  const formattedEventScopeTeam = useMemo<TableFormattedTeamMemberResponse[] | undefined>(() => {
    return eventScopeTeam?.map(teamMember => {
      return {
        ...teamMember,
        accountInfo: {
          avi: teamMember?.avi ?? DEFAULT_ACCOUNT_AVI,
          name: `${teamMember?.firstName} ${teamMember?.lastName}`,
        },
      }
    })
  }, [eventScopeTeam])
  const allRoleKeys = useMemo(() => {
    return formattedEventScopeTeam?.map(teamMember => teamMember.accountRole.roleKey) ?? []
  }, [formattedEventScopeTeam])

  const columnConfigs = useMemo<ColumnConfig<TableFormattedTeamMemberResponse>[]>(() => {
    return [
      {
        header: 'Member Name',
        key: 'accountInfo',
        render: accountInfo => {
          return (
            <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
              <PoshImage src={accountInfo.avi} alt='avatar' style={{width: 30, height: 30, borderRadius: '50%'}} />
              <TableCell.Text text={accountInfo.name} bold />
            </div>
          )
        },
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        header: 'Email',
        key: 'email',
        render: email => <TableCell.Text text={email} />,
      },
      {
        header: 'Access Level',
        key: 'accountRole',
        render: role => (
          <TableCell.MultiOption allOptions={allRoleKeys} selectedOptions={[role.roleKey]} getText={option => option} />
        ),
      },
      {
        header: 'Date Added',
        key: 'dateCreated',
        render: dateCreated => <TableCell.Text text={moment(dateCreated).fromNow()} />,
        sortConfig: {
          defaultSort: false,
          sortFn(a, b) {
            return moment(a).diff(moment(b))
          },
        },
      },
      {
        header: 'Last Login',
        key: 'lastLogin',
        render: lastLogin => <TableCell.Text text={lastLogin ? moment(lastLogin).fromNow() : `Hasn't logged in`} />,
        sortConfig: {
          defaultSort: false,
          sortFn(a, b) {
            return moment(a).diff(moment(b))
          },
        },
      },
    ]
  }, [formattedEventScopeTeam])

  return (
    <TeamManagementModalContextProvider entityId={eventId!} scope={'event'} options={teamManagementModalOptions}>
      <div className='eventSection'>
        <CRUDTable
          data={formattedEventScopeTeam ?? []}
          lastUpdated={dataUpdatedAt}
          refresh={refetch}
          columns={columnConfigs}
          itemsPerPage={10}
          resourceName='Team Member'
          createButton={{
            title: '+ Add Team Member',
            onClick: onClickAddTeamMember,
          }}
          onClickRow={() => {}}
          actionButtons={[
            {
              icon: 'trash',
              onClick: teamMember => {
                setIsShowingTeamManagementModal(true)
                setTeamManagementModalOptions({
                  fixedPanel: 'delete_team_member',
                  teamMemberToEdit: teamMember,
                })
              },
              displayIf: teamMember => teamMember.accountRole.roleKey !== 'owner' && teamMember.accountId !== userId,
              requiredPermissions: [requirePermissionAndScope('remove_team_member', ['event', 'group'])],
            },
            {
              icon: 'pencil',
              onClick: teamMember => {
                setIsShowingTeamManagementModal(true)
                setTeamManagementModalOptions({
                  fixedPanel: 'edit_permissions',
                  teamMemberToEdit: teamMember,
                })
              },
              displayIf: teamMember => teamMember.accountRole.roleKey !== 'owner' && teamMember.accountId !== userId,
              requiredPermissions: [requirePermissionAndScope('edit_team_member_permissions', ['event', 'group'])],
            },
          ]}
          searchableColumn='email'
        />
      </div>
      <AddTeamMemberWrapperComponent
        isAddingTeamMember={isShowingAddTeamModal}
        onTeamManagementModalClose={() => {
          trackMixpanelEvent('X Click- Event Team Non-Posh User Modal', {eventStatus: event?.event.status ?? ''})
          setIsShowingAddTeamModal(false)
          queryClient.invalidateQueries(['team'])
        }}
        onClickContinue={onClickContinue}
        onClickAddTeamMember={onClickAddTeamMemberInModal}
      />
      <TeamManagementModal
        isOpen={isShowingTeamManagementModal}
        onClose={() => {
          setIsShowingTeamManagementModal(false)
          setTeamManagementModalOptions({})
        }}
      />
    </TeamManagementModalContextProvider>
  )
}
