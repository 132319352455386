import React, {PropsWithChildren} from 'react'

import {CustomEventPageFont} from '@posh/model-types'
import classNames from 'classnames'
import {EventTitleFontStyleProvider} from 'components/Text/EventTitleFontStyleProvider'

import {EventVisualsInputContainer, EventVisualsInputContainerProps} from '../shared/InputContainer'

import styles from './styles.module.scss'

export type EventVisualsTextInputWrapperProps = {
  label?: string
  lightMode: boolean
  accentColor: string
  fontFamily?: CustomEventPageFont
  className?: string
  style?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
  isOutlined?: boolean
  blur?: boolean
}

type TContainer = (props: PropsWithChildren<EventVisualsInputContainerProps>) => JSX.Element

function BaseEventVisualsTextInputWrapper(
  props: PropsWithChildren<EventVisualsTextInputWrapperProps & {Container: TContainer; isOutlined?: boolean}>,
) {
  const {style, isOutlined, accentColor, wrapperStyle, label, lightMode, children, Container, className} = props

  const fontFamily = `${props.fontFamily}, sans-serif` // provides a fallback to sans-serif

  return (
    <div style={wrapperStyle}>
      {label && <label className='noMargin'>{label}</label>}
      <div className={classNames(styles.EventVisualsTextInput, className)} style={style}>
        <Container
          className={classNames([isOutlined ? styles.outlined : ''])}
          accentColor={accentColor}
          lightMode={lightMode}
          blur={props.blur}
          style={{color: accentColor, caretColor: accentColor}}>
          {children}
        </Container>
      </div>
      <EventTitleFontStyleProvider eventTitleFont={fontFamily} />
    </div>
  )
}

export function EventVisualsTextInputWrapper(props: PropsWithChildren<EventVisualsTextInputWrapperProps>) {
  return <BaseEventVisualsTextInputWrapper {...props} Container={EventVisualsInputContainer} />
}

export function Outlined(props: PropsWithChildren<EventVisualsInputContainerProps>) {
  return <BaseEventVisualsTextInputWrapper {...props} Container={EventVisualsInputContainer.Outlined} />
}

function Row(props: PropsWithChildren<EventVisualsInputContainerProps>) {
  return (
    <BaseEventVisualsTextInputWrapper
      {...props}
      Container={EventVisualsInputContainer.Row}
      isOutlined={props.isOutlined}
    />
  )
}

function OutlinedRow(props: PropsWithChildren<EventVisualsInputContainerProps>) {
  return <BaseEventVisualsTextInputWrapper {...props} Container={EventVisualsInputContainer.Outlined.Row} />
}

EventVisualsTextInputWrapper.Outlined = Outlined
EventVisualsTextInputWrapper.Row = Row

Outlined.Row = OutlinedRow
