import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import {Checkbox} from 'components/form/checkbox/Checkbox'

import {InputContainer} from '../shared/InputContainer/InputContainer'
import {ControlledFormProps} from '../shared/types'

import styles from './CheckboxGroup.module.scss'

export interface CheckboxGroupProps<T> {
  value: T[] | undefined
  onChange: (value: T[]) => void
  options: Array<{label: string; value: T}>
  error?: string
  className?: string
}

export const CheckboxGroup = <T,>(props: CheckboxGroupProps<T>) => {
  const {value, onChange, options, error, className, ...rest} = props

  const checkedOptions = value || []

  const isItemChecked = (value: T) => {
    return checkedOptions.includes(value)
  }

  const onChecked = (value: T) => {
    if (checkedOptions.includes(value)) {
      const newCheckedOptions = checkedOptions.filter(option => option !== value)
      onChange(newCheckedOptions)
    } else {
      const newCheckedOptions = [...checkedOptions, value]
      onChange(newCheckedOptions)
    }
  }

  return (
    <InputContainer error={error}>
      <div className={styles.CheckboxGroupContainer}>
        {options.map((option, index) => {
          const {label, value} = option
          return (
            <Checkbox
              key={index}
              label={label}
              checked={isItemChecked(value)}
              onChecked={() => onChecked(value)}
              hideBackground={false}
            />
          )
        })}
      </div>
    </InputContainer>
  )
}

interface ControlledCheckboxGroupProps<T extends FieldValues, K extends Path<T>>
  extends Omit<CheckboxGroupProps<NonNullable<T[K]>[number]>, 'value' | 'onChange' | 'name'>,
    ControlledFormProps<T, K> {}

const ControlledCheckboxGroup = <T extends FieldValues, K extends Path<T>>(
  props: ControlledCheckboxGroupProps<T, K>,
) => {
  const {control, name, rules, ...CheckboxGroupProps} = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState, formState: _formState}) => {
        if (field.value && !Array.isArray(field.value)) {
          throw new Error(
            `The value for the field "${name}" is not an array. CheckboxGroup only supports arrays as values.`,
          )
        }
        const onChange = (value: T[]) => {
          // This is a workaround to trigger onBlur and onChange from the field since the Checkbox component doesn't have an onBlur event.
          field.onBlur()
          field.onChange(value)
        }
        return (
          <CheckboxGroup {...field} onChange={onChange} error={fieldState.error?.message} {...CheckboxGroupProps} />
        )
      }}
    />
  )
}

CheckboxGroup.Controlled = ControlledCheckboxGroup
