import React, {useEffect, useMemo, useState} from 'react'

import {useCreateGallery} from 'apis/Events/useCreateGallery'
import useFetchEvent from 'apis/Events/useFetchEvent'
import {useFetchGalleryById} from 'apis/Events/useFetchGalleryById'
import {updateGallery} from 'apis/Events/useUpdateGallery'
import {grayCircularCloseUrl, whiteCircularAddUrl, whiteCircularPencilUrl} from 'components/assets/Icons'
import CopyBlock from 'components/CopyBlock'
import {MAX_FILES} from 'components/FileUpload/MultiFileUpload/hooks/useMultiFileUploadManager/reducer'
import {FileStatus} from 'components/FileUpload/MultiFileUpload/hooks/useMultiFileUploadManager/types'
import {useMultiFileUploadManager} from 'components/FileUpload/MultiFileUpload/hooks/useMultiFileUploadManager/useMultiFileUploadManager'
import {useUploadFiles} from 'components/FileUpload/MultiFileUpload/hooks/useUploadFiles/useUploadFiles'
import {checkMaxFileSize} from 'components/FileUpload/MultiFileUpload/utils/checkMaxFileSize'
import Button from 'components/form/Button'
import GalleryEditor from 'components/form/GalleryEditor'
import IconButton from 'components/form/IconButton'
import {PictureState} from 'components/form/ImagePicker'
import Input from 'components/form/Input'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import compact from 'lodash/compact'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {GalleryModel, Photographer} from '../../../../apis/Events/types'
import SaveDiscardBottomBar from '../../SaveDiscardBottomBar/index'

import './styles.scss'

const AddIcon = () => <PoshImage className='Visuals-icon small' src={whiteCircularAddUrl} />
const EditIcon = () => <PoshImage className='Visuals-icon' src={whiteCircularPencilUrl} />
const CloseIcon = () => <PoshImage className='Visuals-icon' src={grayCircularCloseUrl} />

const getMaxFileSizeErrorMessage = (file: File): string => {
  return checkMaxFileSize({
    file,
    getFileSizeErrorText: sizeText => `File size must be below ${sizeText}`,
  })
}

const Gallery = () => {
  const {groupId, eventId} = useResourcePageParams()
  const {mutateAsync: createGallery} = useCreateGallery()
  const {data: fetchedGallery, refetch} = useFetchGalleryById(eventId!)
  const [passwordProtected, setPasswordProtected] = useState(!!fetchedGallery?.passwordProtected)
  const [password, setPassword] = useState<string | null>(fetchedGallery?.passwordProtected || null)
  const [description, setDescription] = useState<string | null>(fetchedGallery?.description || null)
  const [photographers, setPhotographers] = useState<Photographer[]>(fetchedGallery?.photographers || [])
  const [instagramTags, setInstagramTags] = useState<string[]>(fetchedGallery?.tagOnInstagram || [])
  const [images, setImages] = useState<PictureState[]>(
    fetchedGallery?.imageUris.map(uri => ({file: null, url: uri} as PictureState)) || [],
  )
  const {data: eventData} = useFetchEvent(eventId!)
  useEffect(() => {
    resetAllValues()
  }, [fetchedGallery])

  const [isLoading, setIsLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploaded, setUploaded] = useState(0)
  const [isEditingInstagramTags, setIsEditingInstagramTags] = useState(false)
  const {showToast} = useToast()

  const gallery: GalleryModel = useMemo(() => {
    return {
      eventID: eventId!,
      groupID: groupId!,
      imageUris: images.map(image => image.url!),
      description: description,
      passwordProtected: password,
      photographers: photographers,
      recap: null,
      tagOnInstagram: instagramTags,
      enabled: images.length > 0,
    }
  }, [eventId, groupId, description, password, photographers, instagramTags, images])

  const [changesMade, setChangesMade] = useState(false)

  const checkIfChangesMade = () => {
    if (passwordProtected != !!fetchedGallery?.passwordProtected) return true
    if (password != (fetchedGallery?.passwordProtected || null)) return true
    if (description != (fetchedGallery?.description || null)) return true
    if (JSON.stringify(photographers) != JSON.stringify(fetchedGallery?.photographers || [])) return true
    if (JSON.stringify(instagramTags) != JSON.stringify(fetchedGallery?.tagOnInstagram || [])) return true
    if (
      JSON.stringify(images) !=
      JSON.stringify(fetchedGallery?.imageUris.map(uri => ({file: null, url: uri} as PictureState)) || [])
    )
      return true
    return false
  }

  useEffect(() => {
    if (checkIfChangesMade()) {
      setChangesMade(true)
    } else {
      setChangesMade(false)
    }
  }, [gallery, images])

  const {addFiles, files, setUploadingFile, setUploadProgress, setUploadSuccess, queueFiles, removeUpload, clearFiles} =
    useMultiFileUploadManager()
  const {status} = useUploadFiles({
    files,
    setUploadProgress,
    setUploadSuccess,
    setUploadingFile,
    metadata: {
      'event-id': eventId!,
      'search-faces': eventData?.event.spark ? 'true' : 'false',
    },
    eventId: eventId!,
    onUploadComplete: async () => {
      const uploadedImageUris = compact(files.map(file => (file.status === 'uploaded' ? file.s3Uri! : undefined)))
      const galleryUpdates = gallery
      const imageUris = [...(fetchedGallery?.imageUris ?? []), ...uploadedImageUris]
      galleryUpdates.imageUris = imageUris
      galleryUpdates.enabled = imageUris.length > 0

      updateOrCreateGallery(galleryUpdates)
      clearFiles()
    },
  })

  const onImageDrop = (acceptedFiles: File[]) => {
    addFiles({
      files: acceptedFiles,
      status: FileStatus.ADDED,
      getFileErrorMessage: getMaxFileSizeErrorMessage,
      onExceedMaxFiles: () => {
        showToast({
          title: 'Max files exceeded',
          subtitle: `You can only upload ${MAX_FILES} files at a time`,
          type: 'error',
        })
      },
    })
  }

  const onImageRemove = (file: File) => {
    removeUpload({id: file.name})
  }

  const updateOrCreateGallery = async (galleryUpdates: GalleryModel) => {
    console.log('updating gallery', galleryUpdates)
    if (!!fetchedGallery) {
      await updateGallery(eventId!, galleryUpdates)
    } else {
      await createGallery({eventId: eventId!, gallery: galleryUpdates})
    }

    await refetch()
    setIsLoading(false)
  }

  const handleSave = async () => {
    setIsLoading(true)
    if (files.length > 0) {
      queueFiles()
    } else {
      updateOrCreateGallery(gallery)
    }
  }

  const resetAllValues = () => {
    setPasswordProtected(!!fetchedGallery?.passwordProtected)
    setPassword(fetchedGallery?.passwordProtected || null)
    setDescription(fetchedGallery?.description || null)

    setPhotographers(fetchedGallery?.photographers || [])
    setInstagramTags(fetchedGallery?.tagOnInstagram || [])
    setImages(fetchedGallery?.imageUris.map(uri => ({file: null, url: uri} as PictureState)) || [])
  }

  const handleDiscard = () => {
    resetAllValues()
    setChangesMade(false)
    clearFiles()
  }

  const galleryUrl = `https://posh.vip/e/${eventData?.event.url}/photos`

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value == '') setDescription(null)
    else setDescription(e.target.value)
  }

  const handleInstagramTagChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setInstagramTags(instagramTags => {
      const copy: typeof instagramTags = JSON.parse(JSON.stringify(instagramTags))
      copy[index] = e.target.value
      return copy
    })
  }

  const handleInstagramTagRemove = (index: number) => () => {
    setInstagramTags(instagramTags => {
      const copy: typeof instagramTags = JSON.parse(JSON.stringify(instagramTags))
      copy.splice(index, 1)
      return copy
    })
  }

  const toggleInstagramTagEditing = () => {
    setIsEditingInstagramTags(isEditingInstagramTags => !isEditingInstagramTags)
  }

  const onViewGallery = () => {
    window.open(galleryUrl, '_blank')
  }

  const onAddInstagram = () => {
    setInstagramTags([...instagramTags, ''])
  }

  return (
    <div className='Gallery'>
      <GalleryEditor images={images} setImages={setImages} onImageDrop={onImageDrop} onImageRemove={onImageRemove} />
      <div className='Gallery-row'>
        <Input
          className='Input'
          onChange={handleDescriptionChange}
          style={{marginRight: '20px', width: '300px'}}
          value={description || ''}
          placeholder='Gallery Description'
        />
      </div>
      <div className='Gallery-row'>
        <div style={{marginBottom: '5px'}}>Public Gallery URL</div>
        <CopyBlock links={galleryUrl} />
        <Button onClick={onViewGallery}>View Gallery</Button>
      </div>
      <div className='Gallery-row'>
        <div style={{marginBottom: '5px'}}>Tag On Instagram</div>
        <div style={{display: 'flex'}}>
          <Button className='dark' onClick={onAddInstagram} disabled={false}>
            <AddIcon />
            <p>Add Instagram</p>
          </Button>
          {instagramTags.length > 0 && (
            <IconButton
              style={{background: 'none', boxShadow: 'none'}}
              icon={EditIcon}
              onClick={toggleInstagramTagEditing}
            />
          )}
        </div>
      </div>
      <div style={{paddingTop: '20px'}} className='Gallery-column'>
        {instagramTags.map((tag, index) => (
          <div key={index} style={{display: 'flex', marginBottom: '15px'}}>
            <Input
              className='Input'
              placeholder='Instagram Username'
              onChange={handleInstagramTagChange(index)}
              value={instagramTags[index]}
            />
            {isEditingInstagramTags && (
              <IconButton
                style={{background: 'none', boxShadow: 'none'}}
                icon={CloseIcon}
                onClick={handleInstagramTagRemove(index)}
              />
            )}
          </div>
        ))}
      </div>
      {isLoading && !isUploading ? (
        <div className='Gallery-savingContainer'>
          <p className='Gallery-savingChanges'>Saving Changes...</p>
          <SpinLoader height={30} />
        </div>
      ) : isLoading && isUploading ? (
        <div className='Gallery-savingContainer'>
          <p className='Gallery-savingChanges'>
            Uploaded {uploaded} of {images.length}
          </p>
          <SpinLoader height={30} />
        </div>
      ) : (
        <SaveDiscardBottomBar isOpen={changesMade} onDiscard={handleDiscard} onSave={handleSave}></SaveDiscardBottomBar>
      )}
    </div>
  )
}

export default Gallery
