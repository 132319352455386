import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetGroupOptions = ReactQueryOptions['groups']['getGroup']
type GetGroupInput = RouterInputs['groups']['getGroup']
export type GetGroupOutput = RouterOutput['groups']['getGroup']['group']

export type IGroupLink = NonNullable<GetGroupOutput['links']>[number]

export function useGetGroup(input: GetGroupInput, opts?: GetGroupOptions) {
  return trpc.groups.getGroup.useQuery(input, opts)
}
