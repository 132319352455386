import React from 'react'
import {Control, useController} from 'react-hook-form'

import {MAX_FILE_UPLOAD_SIZE} from '@posh/types'
import {DraggableList} from 'components/DraggableList'
import {FileInput} from 'components/form/FilePicker'
import {EventVisualsIconButton} from 'components/PageComponents/EventVisuals/Button/IconButton'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsHeaderButtons} from 'components/PageComponents/EventVisuals/Page/Section/headerButtons'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useToast} from 'components/toasts/ToastProvider'
import {useDimensions} from 'hooks/useDimensions'
import {isEmpty} from 'lodash'

import {useEventVisualsContext} from '../../context/EventVisualsContext'
import {GalleryImage} from './components/GalleryImage'

import styles from './styles.module.scss'

type AddImagesCallback = (urls: string[]) => void

function AddImageButton({addImages}: {addImages: AddImagesCallback}) {
  const {showToast} = useToast()
  return (
    <FileInput.WithUploadImage
      multiple
      imageType='venue-photo'
      accept='image/*'
      max={MAX_FILE_UPLOAD_SIZE}
      onError={() => showToast({type: 'error', title: 'File over 8mb or invalid file type'})}
      onSuccess={images => addImages(images.map(i => i.imageUrl))}>
      <EventVisualsIconButton onPress={() => {}} iconType='plus' />
    </FileInput.WithUploadImage>
  )
}

function EmptyState({addImages}: {addImages: AddImagesCallback}) {
  const {isMobile} = useDimensions()
  return (
    <div className={styles.EmptyStateContainer}>
      {Array.from({length: isMobile ? 3 : 5}).map((_, index) => (
        <div className={styles.imageSkeleton} key={index} />
      ))}
      <div className={styles.text}>
        Drop an image here or click
        <AddImageButton addImages={addImages} />
        to add photos
      </div>
    </div>
  )
}

export function EventVisualsVenueImages({control}: {control: Control<EventVisualsForm>}) {
  const {
    field: {value: venueImages, onChange: onChangeVenueImages},
  } = useController({control, name: 'venue.images'})

  const {showToast} = useToast()
  const hasImages = !!venueImages?.length

  const {
    setCurrentlyAddingSection,
    currentlyAddingSection,
    palette: {textContrasting},
  } = useEventVisualsContext()

  const addImages: AddImagesCallback = (urls: string[]) => {
    if (!venueImages) return onChangeVenueImages(urls)
    onChangeVenueImages([...venueImages, ...urls])
  }
  const deleteAll = () => {
    onChangeVenueImages([])
    setCurrentlyAddingSection(undefined)
  }
  const deleteOne = (index: number) => {
    if (!venueImages) return
    const newImages = [...venueImages]
    newImages.splice(index, 1)
    onChangeVenueImages(newImages)
  }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (!venueImages) return
    const newImages = [...venueImages]
    const [removed] = newImages.splice(oldIndex, 1)
    newImages.splice(newIndex, 0, removed)
    onChangeVenueImages(newImages)
  }

  if ((!venueImages || isEmpty(venueImages)) && currentlyAddingSection !== 'gallery') return null

  return (
    <EventVisualsSection
      headerText='Gallery'
      underlined={!!hasImages}
      rightIcon={
        <EventVisualsHeaderButtons
          customAddButton={<AddImageButton addImages={addImages} />}
          deleteButtonProps={{
            onPress: deleteAll,
            disabled: false,
            confirmationModalTitle: 'Are you sure you want to remove all images?',
            confirmationButtonText: 'Delete Images',
            cancelButtonText: 'Cancel',
            deleteWithoutModalOnSectionEmpty: true,
            isSectionEmpty: !hasImages,
          }}
        />
      }>
      <div className={styles.container}>
        <FileInput.Dropzone.WithImageUpload
          className={styles.fileInput}
          style={{borderColor: textContrasting, color: textContrasting}}
          imageType='venue-photo'
          onDropRejected={() => showToast({type: 'error', title: 'File over 8mb or invalid file type'})}
          onSuccess={images => addImages(images.map(i => i.imageUrl))}
          noClick
          multiple>
          {hasImages ? (
            <DraggableList onSortEnd={onSortEnd} className={styles.draggable} draggedItemClassName={styles.draggedItem}>
              {venueImages?.map((image, index) => (
                <GalleryImage key={index} src={image} deleteImage={() => deleteOne(index)} />
              ))}
            </DraggableList>
          ) : (
            <EmptyState addImages={addImages} />
          )}
        </FileInput.Dropzone.WithImageUpload>
      </div>
    </EventVisualsSection>
  )
}
