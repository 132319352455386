import {ReactQueryOptions, RouterInputs, trpc} from 'lib/trpc'

type FetchAllKickbackUsageInput = RouterInputs['kickbacks']['getAllKickbackUsage']
type FetchAllKickbackUsageOptions = ReactQueryOptions['kickbacks']['getAllKickbackUsage']

export default function useFetchAllKickBackUsage(
  input: FetchAllKickbackUsageInput,
  options?: FetchAllKickbackUsageOptions,
) {
  return trpc.kickbacks.getAllKickbackUsage.useQuery(input, options)
}
