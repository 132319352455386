import React from 'react'
import {useNavigate} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {useIsMailChimpUserAuthenticated} from 'apis/MailChimp/useIsMailChimpUserAuthenticated'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useCreatePendingTextBlast} from 'apis/PendingTextBlasts/useCreatePendingTextBlast'
import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import useAttendeesCrmContext from '../AttendeesCrmContext'

interface BlastBottomRowProps {
  campaignInProgress: boolean | undefined
  smsDisabled: boolean | undefined
  setCampaignInProgress: React.Dispatch<React.SetStateAction<boolean>>
  setSmsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setMailChimpModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  forceAllowTextBlasts: boolean | undefined
  onClickSMSBlast?: () => void
  selectedAllAttendees: boolean
}

interface BlastButtonProps {
  onClick: () => void
  title: string
  icon: string
  disabled: boolean
  type: 'sms' | 'email'
}

const BlastButton = (props: BlastButtonProps) => {
  const {onClick, title, icon, disabled, type} = props
  const disabledTooltip = disabled ? `${title} have been disabled for your account. Please contact support.` : undefined

  return (
    <div
      className={classNames('Attendees-Panes-Pane small', {sms: type === 'sms'}, {email: type === 'email'}, {disabled})}
      data-tip={disabledTooltip}
      onClick={onClick}>
      <span>{title}</span>
      <PoshImage src={icon} />
    </div>
  )
}
const BlastBottomRow = (props: BlastBottomRowProps) => {
  const {
    campaignInProgress,
    smsDisabled,
    setCampaignInProgress,
    setMailChimpModalIsOpen,
    forceAllowTextBlasts,
    onClickSMSBlast,
    selectedAllAttendees,
  } = props
  const {trackEvent} = useMixpanel()
  const {selectedAttendeeIds} = useAttendeesCrmContext()
  const {groupId, eventId, domain} = useResourcePageParams()
  const {data: mailChimpAuthStatus, isFetching} = useIsMailChimpUserAuthenticated(groupId || '')
  const navigate = useNavigate()
  const {mutate: createPendingTextBlast} = useCreatePendingTextBlast({
    onSuccess: data => {
      navigate(
        `/${domain}/groups/${groupId}/marketing/smscampaign/${data.pendingTextBlastID}/create${
          Boolean(eventId) ? '?fromEvent=true' : ''
        }`,
      )
    },
  })
  const smsBlastsAllowedForGroup = forceAllowTextBlasts || smsDisabled !== true
  const handleSmsClick = () => {
    onClickSMSBlast?.()
    if (smsBlastsAllowedForGroup) {
      trackEvent('SMS Blast Click- Group Marketing Page', {
        allSelected: selectedAllAttendees,
        numAttendees: selectedAttendeeIds.length,
      })
      createPendingTextBlast({groupId: groupId!, accountIds: selectedAttendeeIds})
    }
  }

  const handleEmailClick = () => {
    setMailChimpModalIsOpen(true)
  }

  const attendeesSelectedTitle =
    selectedAttendeeIds.length > 0
      ? `${selectedAttendeeIds.length} ${selectedAttendeeIds.length === 1 ? 'Attendee' : 'Attendees'} Selected`
      : 'Select some attendees...'

  return (
    <div className='Attendees-Selected'>
      <div className='Attendees-Selected-Modal'>
        <div className='Attendees-Selected-Modal-right'>
          {campaignInProgress && selectedAttendeeIds.length == 0 && (
            <PoshImage
              src='https://images.posh.vip/b2/close.svg'
              className='Attendees-Selected-Modal-x'
              onClick={() => setCampaignInProgress(false)}
            />
          )}
          <p>{attendeesSelectedTitle}</p>
        </div>
        {selectedAttendeeIds.length > 0 && (
          <div className='Attendees-Selected-Modal-Actions'>
            <ReactTooltip className='poshToolTip' effect='solid' place='top' />
            <RequirePermissions
              requiredPermissions={[requirePermissionAndScope('export_to_mailchimp', ['event', 'group'])]}>
              {mailChimpAuthStatus?.isAuthenticated && !isFetching && (
                <BlastButton
                  onClick={handleEmailClick}
                  title='Mailchimp List'
                  icon='https://images.posh.vip/psh-assets/icons/mailchimp-logo.svg'
                  type='email'
                  disabled={mailChimpAuthStatus?.canExportUsers ? false : true}
                />
              )}
            </RequirePermissions>
            <RequirePermissions requiredPermissions={[requirePermissionAndScope('send_blast', ['event', 'group'])]}>
              <BlastButton
                onClick={handleSmsClick}
                title='SMS Blast'
                icon='https://images.posh.vip/b2/1041916.png'
                type='sms'
                disabled={!smsBlastsAllowedForGroup}
              />
            </RequirePermissions>
          </div>
        )}
      </div>
    </div>
  )
}
export default BlastBottomRow
