import React from 'react'

import {StripeBankAccount} from 'apis/Groups/useGetGroupPayouts'
import {Bank, creditCard, deleteIcon, starIcon} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'

interface BankAccountProps {
  bankAccount: StripeBankAccount
  isShowingEditBankAccount: boolean
  onEditBankAccount?: () => void
  onDeleteBankAccount?: () => void
}

const BankAccount = (props: BankAccountProps) => {
  const {bankAccount, isShowingEditBankAccount, onDeleteBankAccount, onEditBankAccount} = props
  const {status, object, bankName, last4, isDefaultBankAccount, availableForInstantPayouts} = bankAccount
  return (
    <div className='Payouts-bankInfoRow'>
      {status == 'active' && <div className='Payouts-activeBanner'>ACTIVE ACCOUNT</div>}
      <div className='Payouts-bankInfoContainer'>
        {object === 'card' ? (
          <PoshImage className='Payouts-creditCard' src={creditCard} />
        ) : (
          <Bank className='Payouts-bankIcon' color='white' />
        )}
        <div>
          <p className='noMargin' style={{color: 'white', lineHeight: 1.2}}>
            {bankName}
          </p>
          <div>{`••••••${last4}`}</div>
          <div className='Payouts-bankInfoRow-accountTags'>
            {isDefaultBankAccount && <div className='Payouts-bankInfoRow-defaultAccount'>Default</div>}
            {availableForInstantPayouts && <div className='Payouts-bankInfoRow-instantTag'>Instant Eligible</div>}
          </div>
        </div>
        {isShowingEditBankAccount && !isDefaultBankAccount && (
          <div className='BankActionItems'>
            <a onClick={onEditBankAccount}>
              <PoshImage src={starIcon} />
            </a>
            <a onClick={onDeleteBankAccount}>
              <PoshImage src={deleteIcon} />
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default BankAccount
