import {useParams} from 'react-router-dom'

import {ReactQueryOptions, RouterInputs, trpc} from 'lib/trpc'

type Options = ReactQueryOptions['pendingTextBlasts']['removePendingTextBlastRecipient']
type InnerMutateInput = Omit<
  RouterInputs['pendingTextBlasts']['removePendingTextBlastRecipient'],
  'groupId' | 'pendingTextBlastId'
>
export const useRemovePendingTextBlastRecipientFromSMSPage = (options?: Options) => {
  const {groupId, blastId} = useParams()
  const mutation = useRemovePendingTextBlastRecipient(options)

  return {
    ...mutation,
    mutate: (input: InnerMutateInput) => {
      mutation.mutate({
        ...input,
        groupId: groupId!,
        pendingTextBlastId: blastId!,
      })
    },
  }
}

export const useRemovePendingTextBlastRecipient = (options?: Options) => {
  return trpc.pendingTextBlasts.removePendingTextBlastRecipient.useMutation(options)
}
