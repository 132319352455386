import React, {useEffect, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {UpdateEventAttributes_DEPRECATED, useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import PoshMarkdownEditor from 'components/form/PoshMarkdownEditor'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import EventSetting from '../EventSetting'
import {BaseEventSettingsProps, EventSettingsComponent} from '../eventSettingsComponent'

export const EventPoliciesSettings = (props: BaseEventSettingsProps) => {
  const {event} = props
  const {eventId, groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const [updateEventAttrs, setUpdateEventAttrs] = useState<UpdateEventAttributes_DEPRECATED>({})
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const [isShowingSave, setIsShowingSave] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!isEmpty(updateEventAttrs)) setIsShowingSave(true)
    else setIsShowingSave(false)
  }, [updateEventAttrs])

  const onSave = async () => {
    try {
      await updateEvent({eventId: eventId!, groupId: groupId!, eventData: updateEventAttrs})
      setIsShowingSave(false)
      queryClient.refetchQueries(['event'])
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
      setIsShowingSave(false)
    }
  }

  const onDiscard = () => {
    setUpdateEventAttrs({})
  }

  return (
    <EventSettingsComponent onDiscard={onDiscard} onSave={onSave} isShowingSave={isShowingSave}>
      <EventSetting
        title={`Event Terms of Service`}
        info={`Attendees will have access to your event's Terms of Service upon checking out.`}
        infoImage={'https://images.posh.vip/b2/Screen+Shot+2022-01-12+at+4.10.16+PM.png'}
        wide>
        <PoshMarkdownEditor
          value={updateEventAttrs.termsOfService ?? event.termsOfService}
          onChange={value => setUpdateEventAttrs({...updateEventAttrs, termsOfService: value})}
        />
      </EventSetting>
    </EventSettingsComponent>
  )
}
