import {useCallback, useEffect, useMemo} from 'react'

import {POSHTextBlastLink} from './types'
import {IMessage} from './types'

export const useMessage = ({
  pendingTextBlast,
  chosenEvent,
  setChosenEvent,
  setMessageForPendingTextBlast,
  blastId,
  groupId,
  currentUser,
  censorMessageContent,
  group,
  isInEventScope,
  organizationLabel,
  isCensored,
  setMessage,
  initialMessageValue,
  setInitialMessageValue,
  message,
  debouncedMessage,
  censorBadLinks,
  parseBlastMessage,
  setIsCensored,
}: IMessage) => {
  useEffect(() => {
    if (!pendingTextBlast) return

    if (initialMessageValue === undefined) {
      const messageFromDB = pendingTextBlast.message?.trim() ?? ''
      setInitialMessageValue(messageFromDB)
      setMessage(messageFromDB)
      setIsCensored(pendingTextBlast.isCensored!)

      const {eventID, eventLink} = pendingTextBlast
      if (eventID && eventLink && chosenEvent === undefined) {
        setChosenEvent({id: eventID, url: eventLink as POSHTextBlastLink})
        setInitialMessageValue(messageFromDB.replace(eventLink, ''))
        setMessage(messageFromDB.replace(eventLink, ''))
      }
    } else {
      setMessageForPendingTextBlast({
        pendingTextBlastId: blastId!,
        message: debouncedMessage,
        groupId: groupId!,
        isCensored,
      })
      censorMessageContent({
        message: debouncedMessage,
        isCensored: isCensored,
      })
    }
  }, [
    chosenEvent,
    initialMessageValue,
    pendingTextBlast,
    blastId,
    debouncedMessage,
    groupId,
    setMessageForPendingTextBlast,
    isCensored,
  ])

  const {messageContainsInappropriateWords, messageContainsBadLink} = useMemo(() => {
    const messageContainsInappropriateWords = isCensored
    const messageContainsBadLink = censorBadLinks(message) !== message && !group?.verified

    return {messageContainsInappropriateWords, messageContainsBadLink}
  }, [group?.verified, message, isCensored])

  debouncedMessage = message

  const onMessageChange = useCallback((message: string) => {
    setMessage(message)
  }, [])

  if (message !== debouncedMessage) {
    setMessageForPendingTextBlast({
      pendingTextBlastId: blastId!,
      message: debouncedMessage,
      groupId: groupId!,
      isCensored,
    })
    censorMessageContent({
      message: debouncedMessage,
      isCensored: isCensored,
    })
  }

  const recipientsSectionSubtitle = useMemo(() => {
    return `This SMS blast will be sent to the ${
      pendingTextBlast?.numberOfRecipients ?? 0
    } attendees that you’ve selected. To select new recipients, return to the ${
      isInEventScope ? 'attendees' : 'marketing'
    } tab. To remove recipients, click below.`
  }, [isInEventScope, pendingTextBlast?.numberOfRecipients])

  const previewMessage = useMemo(
    () =>
      parseBlastMessage(
        organizationLabel + ' ' + (chosenEvent?.url ? debouncedMessage + `${chosenEvent.url}` : debouncedMessage),
        {
          firstName: currentUser?.firstName ?? '',
          lastName: currentUser?.lastName ?? '',
        },
      ),
    [chosenEvent?.url, currentUser, debouncedMessage, organizationLabel],
  )

  return {
    recipientsSectionSubtitle,
    previewMessage,
    onMessageChange,
    messageContainsInappropriateWords,
    messageContainsBadLink,
  }
}
