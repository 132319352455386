import ReactPixel from 'react-facebook-pixel'

export const POSH_PIXEL_ID = '151398092578382'
type PixelTypes =
  | 'Lead'
  | 'CompleteRegistration'
  | 'AddToCart'
  | 'PurchaseInitiated'
  | 'Purchase'
  | 'PurchaseSubtotal'
  | 'ViewContent'
  | 'Search'
  | 'InitiateCheckout'
  | 'AddPaymentInfo'
  | 'AddToWishlist'
  | 'CompleteAccountSignUp'
  | 'CompleteCreateOrganization'
  | 'ReachGroupDashboard'
  | 'Signup'
  | 'DemoRequest'
  | 'PurchaseFailed'
interface PixelData {
  content_category?: string
  content_name?: string
  currency?: string
  value?: number
}

export const facebookPixelTriggerTrack = (pixelType: PixelTypes, data: PixelData, pixelId: string) => {
  ReactPixel.init(pixelId)
  ReactPixel.track(pixelType, data)
}

export const facebookPixelPageView = (pixelId: string) => {
  ReactPixel.init(pixelId)
  ReactPixel.pageView() // For tracking page view
}

export const facebookPixelViewContent = (data: PixelData, pixelId: string) => {
  ReactPixel.init(pixelId)
  ReactPixel.track('ViewContent', data)
}

interface PurchaseProps {
  pixelId: string
  eventName: string
  currency: string
  ticketCount: number
  subtotal?: number
}

type PurchaseSuccessProps = Omit<PurchaseProps, 'subtotal'> & {value: number}

export const trackPurchaseInitiated = (props: PurchaseProps) => {
  const {pixelId, eventName, currency, ticketCount, subtotal} = props
  const data = {
    content_category: `Purchase triggered for POSH event: ${eventName}`,
    content_name: `Initiated Checkout for ${ticketCount} tickets for ${eventName}`,
    currency,
    value: subtotal,
  }
  facebookPixelTriggerTrack('PurchaseInitiated', data, pixelId)
}

export const trackPurchaseFailed = (props: PurchaseProps) => {
  const {pixelId, eventName, currency, ticketCount, subtotal} = props
  const data = {
    content_category: `Purchase failed for POSH event: ${eventName}`,
    content_name: `Purchased Failed ${ticketCount} tickets for ${eventName}`,
    currency,
    value: subtotal,
  }
  facebookPixelTriggerTrack('PurchaseFailed', data, pixelId)
}

export const trackPurchaseSuccess = (props: PurchaseSuccessProps) => {
  const {pixelId, eventName, currency, ticketCount, value} = props
  const data = {
    content_category: `Purchase Success for POSH event: ${eventName}`,
    content_name: `Purchased Success ${ticketCount} tickets for ${eventName}`,
    currency,
    value,
  }
  facebookPixelTriggerTrack('Purchase', data, pixelId)
}

export const trackPurchaseSubtotal = (props: PurchaseSuccessProps) => {
  const {pixelId, eventName, currency, ticketCount, value} = props
  const data = {
    content_category: `Purchase Success for POSH event: ${eventName}`,
    content_name: `Purchased Success ${ticketCount} tickets for ${eventName}`,
    currency,
    value,
  }
  facebookPixelTriggerTrack('PurchaseSubtotal', data, pixelId)
}
