import React from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {Venue} from 'apis/Community'
import useUpdatePendingCommunity from 'apis/Community/useUpdatePendingCommunity'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {isNull} from 'lodash'

import './styles.scss'

interface PendingVenuesProps {
  venues: Venue[]
}
interface PendingVenueProps {
  venue: Venue
}

const PendingVenue = (props: PendingVenueProps) => {
  const {venue} = props
  const {mutateAsync: updatePendingCommunity} = useUpdatePendingCommunity()
  const queryClient = useQueryClient()
  const avi = () => {
    if (venue.galleryImages && venue.galleryImages[0] && !isNull(venue.galleryImages[0].url))
      return venue.galleryImages[0].url
    else return 'https://images.posh.vip/b2/default-avi.jpg'
  }

  const handleUpdate = async (status: 'approved' | 'denied') => {
    await updatePendingCommunity({id: venue._id, approvalStatus: status, communityType: 'venue'})
    queryClient.invalidateQueries(['pendingCommunity'])
  }

  return (
    <div className='PendingCommunityCard'>
      <PoshImage src={avi()} />
      <label>{venue.name}</label>
      <p>Address: {venue.address}</p>
      <p>Email: {venue.email}</p>
      {venue.description && <p>Description: {venue.description}</p>}
      {venue.instagram && <a href={`https://instagram.com/${venue.instagram}`}>Instagram</a>}
      <p>Buyout: {venue.baseBuyout}</p>
      {venue.maxCapacity && <p>Max Cap: {venue.maxCapacity}</p>}
      {venue.website && <a href={venue.website}>Website: {venue.website}</a>}

      <button onClick={() => handleUpdate('approved')} className='PendingCommunityCard-approve'>
        APPROVE
      </button>
      <button onClick={() => handleUpdate('denied')} className='PendingCommunityCard-deny'>
        DENY
      </button>
    </div>
  )
}
const PendingVenues = (props: PendingVenuesProps) => {
  const {venues} = props
  return (
    <>
      <h4>Pending Venues</h4>
      <div className='PendingCommunitySection'>
        {venues.map(venue => (
          <PendingVenue venue={venue} key={venue._id} />
        ))}
      </div>
    </>
  )
}
export default PendingVenues
