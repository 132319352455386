import React, {useEffect, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {UpdateEventAttributes_DEPRECATED, useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import {EventQRCode} from 'components/EventQRCode'
import Input from 'components/form/Input'
import PoshInfo from 'components/form/PoshInfo'
import PoshSwitch from 'components/form/PoshSwitch'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import EventSetting from '../EventSetting'
import {BaseEventSettingsProps, EventSettingsComponent} from '../eventSettingsComponent'

export const EventMarketingSettings = (props: BaseEventSettingsProps) => {
  const {event} = props
  const {eventId, groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const [updateEventAttrs, setUpdateEventAttrs] = useState<UpdateEventAttributes_DEPRECATED>({})
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const [isShowingSave, setIsShowingSave] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!isEmpty(updateEventAttrs)) setIsShowingSave(true)
    else setIsShowingSave(false)
  }, [updateEventAttrs])

  const onSave = async () => {
    try {
      await updateEvent({eventId: eventId!, groupId: groupId!, eventData: updateEventAttrs})
      setIsShowingSave(false)
      queryClient.refetchQueries(['event'])
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
      setIsShowingSave(false)
    }
  }

  const onDiscard = () => {
    setUpdateEventAttrs({})
  }

  return (
    <EventSettingsComponent onDiscard={onDiscard} onSave={onSave} isShowingSave={isShowingSave}>
      <EventSetting title={'Market My Event'}>
        <PoshInfo
          info={"If enabled, your event will be displayed on Posh's marketplace and our partner marketplaces."}
        />
        <PoshSwitch
          switchOptions={{
            checked: updateEventAttrs?.displayOnThirdPartySites ?? event.displayOnThirdPartySites ?? false,
            onChange: checked => setUpdateEventAttrs({...updateEventAttrs, displayOnThirdPartySites: checked}),
          }}
        />
      </EventSetting>
      <EventSetting title={'Facebook Pixel ID'} isTextField={true}>
        <Input
          type='text'
          placeholder={'Facebook Pixel ID'}
          value={updateEventAttrs.fbPixelId ?? event.fbPixelId}
          onChange={e => setUpdateEventAttrs({...updateEventAttrs, fbPixelId: e.target.value})}
        />
      </EventSetting>
      <EventSetting title={'TikTok Pixel ID'} isTextField={true}>
        <Input
          type='text'
          placeholder={'TikTok Pixel ID'}
          value={updateEventAttrs.ttPixelId ?? event.ttPixelId}
          onChange={e => setUpdateEventAttrs({...updateEventAttrs, ttPixelId: e.target.value})}
        />
      </EventSetting>
      <EventSetting
        title={'Google Tag Manager ID'}
        info={'This should be an alphanumeric code also referred to as the Container ID'}
        isTextField={true}>
        <Input
          type='text'
          placeholder={'GWT32LS'}
          value={updateEventAttrs.gtmContainerId ?? event.gtmContainerId}
          onChange={e => setUpdateEventAttrs({...updateEventAttrs, gtmContainerId: e.target.value})}
        />
      </EventSetting>
      {event.url && (
        <EventSetting title={'QR Code'} wide>
          <EventQRCode url={event.url} />
        </EventSetting>
      )}
    </EventSettingsComponent>
  )
}
