import React from 'react'
import {useNavigate} from 'react-router-dom'

import useFetchCommunityEntity from 'apis/Community/useFetchCommunityEntity'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'

const jobTitles = [
  {name: 'Photographers', link: 'Photographer'},
  {name: 'Videographers', link: 'Videographer'},
  {name: 'Security', link: 'Security Guard'},
  {name: 'Bartenders', link: 'Bartender'},
]

const categories = [
  {name: 'Venues', link: 'venue'},
  {name: 'Artists', link: 'artist'},
]

const CommunityFooter = () => {
  const navigate = useNavigate()
  const {data: communityEntity} = useFetchCommunityEntity()
  const hasCommunityEntity = (communityEntity && !!communityEntity.type) ?? false
  return (
    <div className='CommunityFooter'>
      <div className='CommunityFooter-main'>
        <svg viewBox='0 0 100 100' width='100%' preserveAspectRatio={'none'}>
          <linearGradient id='grad1' x1='0%' y1='100%' x2='0%' y2='0%'>
            <stop offset='0%' style={{stopColor: '#C9A207', stopOpacity: 1}} />
            <stop offset='100%' style={{stopColor: '#10121D', stopOpacity: 1}} />
          </linearGradient>
          <path
            d='M 0 20 C 40 20 60 0 100 0 L 100 100 L 0 100 L 0 10'
            preserveAspectRatio={'none'}
            style={{fill: 'url(#grad1)'}}
          />
        </svg>
        <h3>
          <i>Everything</i> you need to host an incredible experience.
        </h3>
        <div className='CommunityFooter-main-links'>
          {jobTitles.map((jobTitle, index) => (
            <a key={index} onClick={() => navigate(`/community/explore?c=staff&j=${jobTitle.link}`)}>
              {jobTitle.name}
            </a>
          ))}
          {categories.map((category, index) => (
            <a key={index} onClick={() => navigate(`/community/explore?c=${category.link}`)}>
              {category.name}
            </a>
          ))}
        </div>
        <div className='CommunityFooter-logo'>
          <PoshImage src='https://images.posh.vip/b2/posh-community-small.png' />
        </div>
      </div>
      {!hasCommunityEntity && (
        <div className='CommunityFooter-supplyCTA'>
          <h4>Want to get booked through Posh Community?</h4>
          <Button>Get Booked</Button>
        </div>
      )}
    </div>
  )
}

export default CommunityFooter
