import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

export type UseFetchPendingTextBlastOutput = RouterOutput['pendingTextBlasts']['fetchPendingTextBlast']
export const useFetchPendingTextBlast = (
  input: RouterInputs['pendingTextBlasts']['fetchPendingTextBlast'],
  options?: ReactQueryOptions['pendingTextBlasts']['fetchPendingTextBlast'],
) => {
  return trpc.pendingTextBlasts.fetchPendingTextBlast.useQuery(input, {
    ...options,
    enabled: !!input.pendingTextBlastId,
  })
}
