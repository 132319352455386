import React, {useEffect, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {Covid19Settings} from 'apis/Events/types'
import {UpdateEventAttributes_DEPRECATED, useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import PoshSwitch from 'components/form/PoshSwitch'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import EventSetting from '../EventSetting'
import {BaseEventSettingsProps, EventSettingsComponent} from '../eventSettingsComponent'

export const EventCovidSettings = (props: BaseEventSettingsProps) => {
  const {event} = props
  const {eventId, groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const [updateEventAttrs, setUpdateEventAttrs] = useState<UpdateEventAttributes_DEPRECATED>({})
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const [isShowingSave, setIsShowingSave] = useState(false)
  const queryClient = useQueryClient()
  const [covid19Settings, setCovid19Settings] = useState<Covid19Settings | undefined>(event.covid19Settings)

  useEffect(() => {
    if (!isEmpty(updateEventAttrs)) setIsShowingSave(true)
    else setIsShowingSave(false)
  }, [updateEventAttrs])

  useEffect(() => {
    if (
      covid19Settings?.vaccinationRequired !== event.covid19Settings?.vaccinationRequired ||
      covid19Settings?.masksRequired !== event.covid19Settings?.masksRequired ||
      covid19Settings?.negativeTestRequired !== event.covid19Settings?.negativeTestRequired ||
      covid19Settings?.temperatureChecked !== event.covid19Settings?.temperatureChecked
    ) {
      setUpdateEventAttrs({
        ...updateEventAttrs,
        covid19Settings: covid19Settings,
      })
    }
  }, [covid19Settings])

  const onSave = async () => {
    try {
      await updateEvent({eventId: eventId!, groupId: groupId!, eventData: updateEventAttrs})
      setIsShowingSave(false)
      queryClient.refetchQueries(['event'])
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
      setIsShowingSave(false)
    }
  }

  const onDiscard = () => {
    setUpdateEventAttrs({})
  }

  return (
    <EventSettingsComponent onDiscard={onDiscard} onSave={onSave} isShowingSave={isShowingSave}>
      <EventSetting title={'Vaccination Required'}>
        <PoshSwitch
          title={''}
          switchOptions={{
            checked:
              covid19Settings?.vaccinationRequired ??
              (event.covid19Settings?.vaccinationRequired ? event.covid19Settings.vaccinationRequired : false),
            onChange: checked => {
              setCovid19Settings({...covid19Settings, vaccinationRequired: checked})
            },
          }}
        />
      </EventSetting>

      <EventSetting title={'Mask Required For Entry'}>
        <PoshSwitch
          title={''}
          switchOptions={{
            checked:
              covid19Settings?.masksRequired ??
              (event.covid19Settings?.masksRequired ? event.covid19Settings.masksRequired : false),
            onChange: checked => {
              setCovid19Settings({...covid19Settings, masksRequired: checked})
            },
          }}
        />
      </EventSetting>

      <EventSetting title={'Negative COVID-19 Test Required'}>
        <PoshSwitch
          title={''}
          switchOptions={{
            checked:
              covid19Settings?.negativeTestRequired ??
              (event.covid19Settings?.negativeTestRequired ? event.covid19Settings.negativeTestRequired : false),
            onChange: checked => {
              setCovid19Settings({...covid19Settings, negativeTestRequired: checked})
            },
          }}
        />
      </EventSetting>

      <EventSetting title={'Temperature Checked On Arrival'}>
        <PoshSwitch
          title={''}
          switchOptions={{
            checked:
              covid19Settings?.temperatureChecked ??
              (event.covid19Settings?.temperatureChecked ? event.covid19Settings.temperatureChecked : false),
            onChange: checked => {
              setCovid19Settings({...covid19Settings, temperatureChecked: checked})
            },
          }}
        />
      </EventSetting>
    </EventSettingsComponent>
  )
}
