import React, {useEffect, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {IPaymentPlan} from 'apis/Events/types'
import {UpdateEventAttributes_DEPRECATED, useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import PoshSwitch from 'components/form/PoshSwitch'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import EventSetting from '../EventSetting'
import {BaseEventSettingsProps, EventSettingsComponent} from '../eventSettingsComponent'
import PaymentPlanSettings from '../PaymentPlanSettings'

export const EventPaymentPlanSettings = (props: BaseEventSettingsProps) => {
  const {event} = props
  const currPlan = event?.paymentPlans
  const pPlanEnabled = currPlan && currPlan[0] && !currPlan[0]?.disabled
  const [isPaymentPlansEnabled, setIsPaymentPlansEnabled] = useState(pPlanEnabled ?? false)
  const {eventId, groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const [updateEventAttrs, setUpdateEventAttrs] = useState<UpdateEventAttributes_DEPRECATED>({})
  const [isShowingSave, setIsShowingSave] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!isEmpty(updateEventAttrs)) {
      setIsShowingSave(true)
    } else {
      setIsShowingSave(false)
    }
  }, [updateEventAttrs])

  const onSave = async () => {
    try {
      await updateEvent({eventId: eventId!, groupId: groupId!, eventData: updateEventAttrs})
      setIsShowingSave(false)
      queryClient.refetchQueries(['event'])
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
      setIsShowingSave(false)
    }
  }

  const onDiscard = () => {
    setUpdateEventAttrs({})
  }

  const setPaymentPlanAttributes = (checked: boolean) => {
    setIsShowingSave(true)
    setIsPaymentPlansEnabled(checked)
    if (event.paymentPlans && event.paymentPlans[0]) {
      const plan = event.paymentPlans[0]
      plan.disabled = isPaymentPlansEnabled
      setUpdateEventAttrs({...updateEventAttrs, paymentPlans: [plan]})
    } else {
      // Create Payment Plan
      const plan: IPaymentPlan = {
        lastInstallmentDate: event.end,
        lastInstallmentDateUtc: event.endUtc,
        disabled: isPaymentPlansEnabled,
      }
      setUpdateEventAttrs({...updateEventAttrs, paymentPlans: [plan]})
    }
    queryClient.refetchQueries(['event'])
  }

  return (
    <EventSettingsComponent onDiscard={onDiscard} onSave={onSave} isShowingSave={isShowingSave}>
      <EventSetting
        title={'Payment Plans Enabled'}
        info={
          'Enabling payment plans allows your attendees to pay in installments for their order leading up to the event'
        }>
        <PoshSwitch
          title={''}
          switchOptions={{
            checked: isPaymentPlansEnabled,
            onChange: checked => {
              setPaymentPlanAttributes(checked)
            },
          }}
        />
      </EventSetting>
      <div className='EventSettings-paymentPlanSettings'>
        {isPaymentPlansEnabled && (
          <PaymentPlanSettings
            event={event}
            updateEventAttributes={updateEventAttrs}
            setUpdateEventAttributes={setUpdateEventAttrs}
          />
        )}
      </div>
    </EventSettingsComponent>
  )
}
