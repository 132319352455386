export const getCheckoutButtonText = ({
  isProcessing,
  isFreeOrder,
  isRSVPEvent,
  isApprovalRequired,
  customCheckoutSubmitText,
}: {
  isProcessing: boolean
  isFreeOrder: boolean
  isRSVPEvent?: boolean
  isApprovalRequired?: boolean
  customCheckoutSubmitText?: string
}) => {
  if (isProcessing) {
    if (isApprovalRequired) {
      return 'PROCESSING REQUEST'
    } else {
      return isFreeOrder ? 'PROCESSING ORDER' : 'PROCESSING PURCHASE'
    }
  }

  if (isApprovalRequired) {
    return isFreeOrder ? 'REQUEST TO ATTEND' : 'REQUEST TO PURCHASE'
  }

  return customCheckoutSubmitText ?? (isRSVPEvent ? 'RSVP' : 'CHECKOUT')
}
