import React, {useState} from 'react'

import {useAddProfileView} from 'apis/Accounts/useAddProfileView'
import {EventParentComment} from 'apis/comments/useFetchEventParentComments'
import {useAddEventComment} from 'apis/Events/useAddEventComment'
import useDeleteEventComment from 'apis/Events/useDeleteEventComment'
import {dots} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import {trpc} from 'lib/trpc'
import {isUndefined} from 'lodash'
import moment from 'moment'
import {ProfilePreviewModalState} from 'pages/EventPage'
import {PreviewProfileForModal} from 'pages/EventPage/Guestlist/ProfilePreviewModal'

import {CommentReplies} from '../CommentReplies'
import EmojiSection from './EmojiSection'

const UserBadges = ({comment}: {comment: EventParentComment}) => {
  return (
    <div className='activityComment-text-name-badges'>
      {comment.isOwner && (
        <div>
          <div>{comment.commenter.firstName} is one of the event curators.</div>
          <PoshImage src='https://images.posh.vip/b2/event-curator.png' style={{filter: 'brightness(0) invert(1)'}} />
        </div>
      )}
      {comment.isPOSHemployee && (
        <div>
          <div>{comment.commenter.firstName} is a Posh team member.</div>
          <PoshImage src='https://images.posh.vip/b2/Posh-Boat-Logo.svg' style={{filter: 'brightness(0) invert(1)'}} />
        </div>
      )}
      {comment.isVerified && (
        <div>
          <div>{comment.commenter.firstName} is verified on Instagram.</div>
          <PoshImage src='https://images.posh.vip/b2/verified+(1).svg' style={{filter: 'brightness(0) invert(1)'}} />
        </div>
      )}
    </div>
  )
}

interface CommentComponentProps {
  comment: EventParentComment
  accentColor: string
  isLightMode: boolean
  eventId: string
  groupId?: string
  isReply: boolean
  shouldShowEmojiPicker?: string
  setShouldShowEmojiPicker?: React.Dispatch<React.SetStateAction<string>>
  setProfilePreviewModal?: React.Dispatch<React.SetStateAction<ProfilePreviewModalState>>
  parentCommentId?: string
  replyToCommentId?: string
  refetch?: () => Promise<void>
  replyEnabled?: boolean
}

const CommentComponent = (props: CommentComponentProps) => {
  const {
    comment,
    accentColor,
    isLightMode,
    eventId,
    groupId,
    isReply,
    shouldShowEmojiPicker,
    setShouldShowEmojiPicker,
    parentCommentId,
    replyToCommentId,
    refetch,
    setProfilePreviewModal,
    replyEnabled = true,
  } = props
  const {accountRoleGroupIdMap} = useSessionContext()
  const [replyInputOpen, setReplyInputOpen] = useState<boolean>(false)
  const [reply, setReply] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [isConfirming, setIsConfirming] = useState<boolean>(false)
  const [expandedComment, setExpandedComment] = useState<string | null>(null)
  const {mutate: createEventComment} = useAddEventComment({
    onSuccess: () => {
      queryClient.comments.fetchEventParentComments.invalidate()
      if (refetch) refetch()
      setErrorMsg('')
      setReplyInputOpen(false)
      setReply('')
    },
    onError: error => {
      setErrorMsg(error.message ?? 'You cannot leave a comment on an event you have not purchased tickets for.')
    },
  })
  const {mutate: addProfileView} = useAddProfileView()

  const queryClient = trpc.useContext()
  const {mutateAsync: deleteEventComment} = useDeleteEventComment()

  const removeEventComment = async () => {
    try {
      await deleteEventComment({eventId, commentId: comment._id})
      queryClient.comments.fetchEventParentComments.invalidate()
      if (refetch) refetch()
      setErrorMsg('')
    } catch (error) {
      setErrorMsg('Message could not be deleted.')
    }
  }

  const openProfile = (profile: PreviewProfileForModal) => {
    setProfilePreviewModal && setProfilePreviewModal({profile, isOpen: true})
    addProfileView({accountId: profile.accountId})
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      createEventComment({
        eventId: eventId,
        value: reply,
        parentComment: parentCommentId,
        replyTo: replyToCommentId,
      })
    }
  }
  moment.locale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: '1s',
      ss: '%ss',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1m',
      MM: '%dM',
      y: '1y',
      yy: '%dY',
    },
  })

  const commentHasReactions = !isUndefined(comment.reactions)
  const shouldShowTrashIcon =
    comment.commenter.isUser ||
    (groupId &&
      accountRoleGroupIdMap &&
      accountRoleGroupIdMap[groupId] &&
      accountRoleGroupIdMap[groupId].roleKey === 'owner')
  const commenter = comment.commenter
  // To Do: Fix data model returned from server
  commenter.id = comment.accountID

  const handleOpenGuestlistModal = () => {
    openProfile({
      accountId: commenter.id,
      profileAvi: commenter.avi,
      instagramVerified: comment.commenterProfile?.instagramVerified ?? false,
      poshVerified: comment.commenterProfile?.poshVerified ?? false,
      firstName: commenter.firstName,
      lastName: commenter.lastName,
      instagram: comment.commenterProfile?.instagram,
      twitter: comment.commenterProfile?.twitter,
    })
  }
  const textColor = isLightMode ? 'black' : 'white'

  return (
    <>
      <div className='activityComment' style={{color: textColor}}>
        <PoshImage className='activityComment-avi' src={commenter.avi} onClick={handleOpenGuestlistModal} />
        <div className='activityComment-text'>
          <div className='activityComment-text-name'>
            {commenter.firstName} {commenter.lastName}
            <UserBadges comment={comment} />
          </div>
          <div className='activityComment-text-body'>{comment.comment}</div>
          <div className={`activityComment-text-actions ${commentHasReactions ? '' : 'noreations'}`}>
            <div style={{display: 'flex', gap: '24px'}}>
              <div className='activityComment-text-actions-time'>{moment(comment.createdAt).fromNow()}</div>
              {replyEnabled && (
                <div
                  className={`activityComment-text-actions-reply ${isLightMode ? 'lightmode' : ''}`}
                  onClick={() => setReplyInputOpen(!replyInputOpen)}>
                  REPLY
                </div>
              )}
              {shouldShowTrashIcon && (
                <div className={`activityComment-text-actions-delete ${isLightMode ? 'lightmode' : ''}`}>
                  {isConfirming ? (
                    <span onClick={() => removeEventComment()}>DELETE</span>
                  ) : (
                    <PoshImage src={dots} onClick={() => setIsConfirming(true)} />
                  )}
                </div>
              )}
            </div>
            <EmojiSection
              setShouldShowEmojiPicker={setShouldShowEmojiPicker}
              shouldShowEmojiPicker={shouldShowEmojiPicker}
              comment={comment}
              eventId={eventId}
              isLightMode={isLightMode}
              refetch={refetch}
            />
          </div>
          {replyInputOpen && (
            <div className={`ActivitySection-commentInput ${isLightMode ? 'lightmode' : ''}`}>
              <input
                type='text'
                placeholder='Leave a reply...'
                value={reply}
                onChange={e => setReply(e.target.value)}
                onKeyDown={e => onKeyDown(e)}></input>
              <div
                style={{background: accentColor}}
                onClick={() =>
                  createEventComment({
                    eventId: eventId,
                    value: reply,
                    parentComment: parentCommentId,
                    replyTo: replyToCommentId,
                  })
                }>
                <PoshImage src='https://images.posh.vip/b2/speech-bubble.svg' />
              </div>
            </div>
          )}
          {errorMsg && <div className='activityComment-text-error'>{errorMsg}</div>}
          {comment.hasReplies && !isReply && (
            <CommentReplies
              comment={comment}
              accentColor={accentColor}
              eventId={eventId}
              groupId={groupId}
              isLightMode={isLightMode}
              setExpandedComment={setExpandedComment}
              expandedComment={expandedComment}
              setShouldShowEmojiPicker={setShouldShowEmojiPicker}
              shouldShowEmojiPicker={shouldShowEmojiPicker}
              setProfilePreviewModal={setProfilePreviewModal}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CommentComponent
