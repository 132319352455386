import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface AddCommentReactionParams {
  eventId: string
  commentId: string
  emoji: string
}

const addCommentReaction = async (params: AddCommentReactionParams) => {
  const response = await PoshApi.addCommentReaction(params)
  return response.data
}

export function useAddCommentReaction() {
  return useMutation((params: AddCommentReactionParams) => addCommentReaction(params), {
    retry: false,
  })
}
