import {ApprovalStatus, CommunityCategory, GalleryImage, Tag} from 'apis/Community'
import {FetchCommunityEntityResponse} from 'apis/Community/useFetchCommunityEntity'
import {LongLat} from 'apis/Events/types'
import moment from 'moment'

export type EntityDetails = {
  type?: CommunityCategory
  id?: string
  title?: string
  email?: string
  image?: string
  tags?: Tag[]
  website?: string
  fee?: number
  yearsOfExperience?: number
  location?: string
  coordinates?: LongLat
  bio?: string
  pictures?: string[]
  instagramName?: string
  openModalClicks?: number
  capacity?: number
  spotifyUrl?: string
  spotifyFollowers?: number
  approvalStatus?: ApprovalStatus
  displayInCommunity?: boolean
  galleryImages?: GalleryImage[]
  url?: string
  minimumPrice?: number
}

export const getGalleryImageUrls = (galleryImages?: GalleryImage[]) => {
  if (galleryImages) return galleryImages.map(image => image.url)
  return []
}

export const getEntityDetails = (entity: FetchCommunityEntityResponse) => {
  const details: EntityDetails = {}
  switch (entity.type) {
    case 'artist':
      details.type = 'artist' as CommunityCategory
      details.id = entity.artist._id
      details.title = entity.artist.artistName
      details.email = entity.artist.email ?? ''
      details.image = entity.artist.avi ?? ''
      details.tags = entity.artist.tags ?? []
      details.website = entity.artist.website ?? ''
      details.fee = entity.artist.baseBookingFee ?? 0
      details.location = entity.artist.location ?? ''
      details.bio = entity.artist.bio ?? ''
      details.spotifyUrl = entity.artist.spotifyUrl ?? ''
      details.spotifyFollowers = entity.artist.spotifyFollowers ?? 0
      details.instagramName = entity.artist.instagram ?? ''
      details.openModalClicks = entity.artist.openModalClicks ?? 0
      details.approvalStatus = entity.artist.approvalStatus ?? 'pending'
      details.displayInCommunity = entity.artist.displayInCommunity ?? false
      details.pictures = getGalleryImageUrls(entity.artist.galleryImages)
      details.url = entity.artist.url ?? ''
      break
    case 'venue':
      details.type = 'venue' as CommunityCategory
      details.id = entity.venue._id
      details.title = entity.venue.name
      details.email = entity.venue.email ?? ''
      details.image = entity.venue.galleryImages[0].url ?? ''
      details.pictures = getGalleryImageUrls(entity.venue.galleryImages)
      details.tags = entity.venue.tags ?? []
      details.website = entity.venue.website ?? ''
      details.fee = entity.venue.baseBuyout ?? 0
      details.location = entity.venue.address ?? ''
      details.bio = entity.venue.description ?? ''
      details.instagramName = entity.venue.instagram ?? ''
      details.capacity = entity.venue.maxCapacity ?? 0
      details.openModalClicks = entity.venue.openModalClicks ?? 0
      details.approvalStatus = entity.venue.approvalStatus ?? 'pending'
      details.displayInCommunity = entity.venue.displayInCommunity ?? false
      details.url = entity.venue.url ?? ''
      break
    case 'staff':
      details.type = 'staff' as CommunityCategory
      details.id = entity.staff._id
      details.title = `${entity.staff.firstName} ${entity.staff.lastName}`
      details.email = entity.staff.email ?? ''
      details.image = entity.staff.avi ?? ''
      details.website = entity.staff.website ?? ''
      details.fee = entity.staff.hourlyRate ?? 0
      details.location = entity.staff.location ?? ''
      details.bio = entity.staff.bio ?? ''
      details.instagramName = entity.staff.instagram ?? ''
      details.openModalClicks = entity.staff.openModalClicks ?? 0
      details.approvalStatus = entity.staff.approvalStatus ?? 'pending'
      details.displayInCommunity = entity.staff.displayInCommunity ?? false
      details.yearsOfExperience = entity.staff.yearsOfExperience ?? 0
      details.tags = entity.staff.tags ?? []
      details.pictures = getGalleryImageUrls(entity.staff.galleryImages)
      details.url = entity.staff.url ?? ''
      details.minimumPrice = entity.staff.minimumPrice ?? 0
      break
  }
  return details
}

export const getTime = (date: Date) => {
  return moment(date).format('hh:mm a')
}

export const getDate = (date: Date) => {
  return moment(date).format('LL')
}

export const getFeeName = (type?: CommunityCategory) => {
  switch (type) {
    case 'venue':
      return 'Base Buyout'
    case 'artist':
      return 'Base Booking Fee'
    case 'staff':
      return 'Hourly Rate'
    default:
      return ''
  }
}
