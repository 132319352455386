import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchEventPinOptions = ReactQueryOptions['events']['fetchEventPins']
type FetchEventPinInput = RouterInputs['events']['fetchEventPins']
export type FetchEventPinOutput = RouterOutput['events']['fetchEventPins']
export type EventPin = FetchEventPinOutput[0]

export const useFetchEventPins = (input: FetchEventPinInput, options?: FetchEventPinOptions) => {
  return trpc.events.fetchEventPins.useQuery(input, options)
}
