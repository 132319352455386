import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchAccountStatusOptions = ReactQueryOptions['poshAdmin']['poshAdminConnectAccountsRouter']['fetchAccountStatus']
type FetchAccountStatusInput = RouterInputs['poshAdmin']['poshAdminConnectAccountsRouter']['fetchAccountStatus']
export type FetchAccountStatusAdminOutput =
  RouterOutput['poshAdmin']['poshAdminConnectAccountsRouter']['fetchAccountStatus']

export function useFetchConnectAccountStatus(connectId?: string) {
  return trpc.poshAdmin.poshAdminConnectAccountsRouter.fetchAccountStatus.useQuery(
    {connectId: connectId!},
    {
      enabled: false,
    },
  )
}
