import React from 'react'
import {Control, useController} from 'react-hook-form'

import {FileInput} from 'components/form/FilePicker'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {PoshImage} from 'components/PoshImage/PoshImage'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

import styles from './styles.module.scss'

interface Props {
  control: Control<EventVisualsForm>
}

const TOOLTIP_INFO =
  'The organizer image and name that is shown on the event page. This does not change the name of your group for other events.'

function useFormValues(control: Props['control']) {
  const {
    field: {value: displayGroupImage, onChange: onChangeDisplayGroupImage},
  } = useController({control, name: 'displayGroupImage'})
  return {displayGroupImage, onChangeDisplayGroupImage}
}

export function EventVisualsGroupDisplaySettingsSection({control}: Props) {
  const {displayGroupImage, onChangeDisplayGroupImage} = useFormValues(control)
  const {
    palette: {accentColor, lightmode: lightMode, textContrasting},
  } = useEventVisualsContext()

  return (
    <EventVisualsSection headerText='Organizer Details' tooltipInfo={TOOLTIP_INFO}>
      <div className={styles.GroupDisplaySettings}>
        <FileInput.WithUploadImage
          imageType='group-avi'
          onSuccess={(_, imageUrl) => onChangeDisplayGroupImage(imageUrl)}>
          <PoshImage
            src={displayGroupImage ?? undefined}
            alt='Group Display Image'
            style={{borderColor: textContrasting}}
          />
        </FileInput.WithUploadImage>
        <div className={styles.inputContainer}>
          <EventVisualsTextInput.Text.Controlled
            control={control}
            name='displayGroupName'
            accentColor={accentColor}
            lightMode={lightMode}
          />
        </div>
      </div>
    </EventVisualsSection>
  )
}
