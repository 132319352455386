import React from 'react'
import {Route, Routes} from 'react-router-dom'

import PageNotFound from 'pages/PageNotFound/PageNotFound'

import KickbackSettings from '../KickbackSettings'
import Kickback from '../Kickstart'
import HostBreakdownHandler from '../TeamMembers/HostBreakdown'

const KickbackRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Kickback />} />
      <Route path='/settings' element={<KickbackSettings />} />
      <Route path='/:affiliateTrackingLink' element={<HostBreakdownHandler />} />
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  )
}

export default KickbackRouter
