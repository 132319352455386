import React, {PropsWithChildren, useState} from 'react'
import {FieldValues, Path, UseFormReturn} from 'react-hook-form'

import {RadioGroup} from '../RadioGroup/RadioGroup'

interface ToggleableInputProps<T extends FieldValues, K extends Path<T>> {
  form: UseFormReturn<T>
  name: K
  onLabel: string
  offLabel: string
}

// Only allows fields that are optional
// (i.e. fields that are not required in the form)
// This is because we want to be able to set the value to undefined (i.e. not set) when the toggle is off
type OptionalPropertyOf<T extends Record<string, any>> = Exclude<
  {
    [K in keyof T]: T extends Record<K, T[K]> ? never : K
  }[keyof T],
  undefined
>

export const ToggleableInput = <T extends FieldValues, K extends Extract<Path<T>, OptionalPropertyOf<T>>>(
  props: PropsWithChildren<ToggleableInputProps<T, K>>,
) => {
  const {form, name, onLabel, offLabel, children} = props

  const watchedValue = form.watch(name)
  const [on, setOn] = useState<boolean>(!!watchedValue)

  const onChange = (status: boolean) => {
    if (!status) {
      // Here we set the value to undefined when the toggle is off
      form.setValue(name, undefined!, {shouldDirty: true, shouldValidate: true})
      setOn(false)
    } else {
      form.resetField(name)
      setOn(true)
    }
  }

  return (
    <>
      <RadioGroup
        value={on}
        options={[
          {label: offLabel, value: false},
          {label: onLabel, value: true},
        ]}
        onChange={status => onChange(status)}
      />
      {on && children}
    </>
  )
}
