import {EventParentComment} from 'apis/comments/useFetchEventParentComments'

export const fakeComments: EventParentComment[] = [
  {
    _id: '12123',
    eventID: 'wefwef',
    accountID: '003',
    comment: 'So excited for this event!!',
    createdAt: '2023-07-05T21:11:41.077+00:00',
    commenter: {
      id: '006',
      firstName: 'Eli',
      lastName: 'Taylor-Lemire',
      avi: 'https://images.posh.vip/b1/attendee-avi/613e22214ada2f0032063090.jpg',
      isUser: false,
      badges: [],
    },
    hasReplies: false,
    isOwner: false,
    isPOSHemployee: false,
    isVerified: false,
  },
  {
    _id: '12122',
    accountID: '002',
    eventID: 'wefwef',
    comment: `OMG you're going Eli???`,
    createdAt: '2023-07-05T21:11:41.077+00:00',
    commenter: {
      id: '005',
      firstName: 'Kendall',
      lastName: 'Jenner',
      avi: 'https://images.posh.vip/b2/Screen+Shot+2022-07-06+at+2.02.54+PM.png',
      isUser: false,
      badges: [],
    },
    hasReplies: false,
    isOwner: false,
    isPOSHemployee: false,
    isVerified: false,
  },
  {
    _id: '12121',
    accountID: '001',
    eventID: 'wefwef',
    comment: `Anyone wanna meet up before the event? 👀`,
    createdAt: '2023-07-05T21:11:41.077+00:00',
    commenter: {
      id: '004',
      firstName: 'David',
      lastName: 'Davidov',
      avi: 'https://images.posh.vip/images/9044a8c1-da1b-4892-987b-e311d78df557.jpg',
      isUser: false,
      badges: [],
    },
    hasReplies: false,
    isOwner: false,
    isPOSHemployee: false,
    isVerified: false,
  },
]
