import React, {useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {Tag} from 'apis/Groups'
import PoshApi from 'apis/PoshApi'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import {useGroupMatchParams} from 'domains/Groups/helpers'

function padZero(str: string, len = 2) {
  len = len || 2
  const zeros = new Array(len).join('0')
  return (zeros + str).slice(-len)
}

export function invertColor(hex: string) {
  if (hex.indexOf('#') === 0) hex = hex.slice(1)
  if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  if (hex.length !== 6) throw new Error('Invalid HEX color.')
  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16)
  return '#' + padZero(r) + padZero(g) + padZero(b)
}

export const UnassignableTag = ({tag, userId}: {tag: Tag; userId?: string}) => {
  const [isHovering, setIsHovering] = useState(false)
  const {groupId} = useGroupMatchParams()
  const queryClient = useQueryClient()
  const handleRemoveTag = async () => {
    if (userId) {
      await PoshApi.removeTag(tag._id, userId, groupId ?? '')
      queryClient.invalidateQueries(['groupAttendeesPaged'])
      queryClient.invalidateQueries(['eventAttendees'])
    }
  }
  return (
    <>
      <button
        className='TagsContainer-hoverButton'
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}>
        <div
          className='TagsContainer-tag-hoverable'
          onClick={() => handleRemoveTag()}
          style={{background: tag.background, color: invertColor(tag.background)}}>
          {isHovering ? 'REMOVE' : tag.name}
        </div>
      </button>
    </>
  )
}

export const DeletableTag = ({tag, onClick, onDelete}: {tag: Tag; onClick?: () => void; onDelete?: () => void}) => {
  const [isHovering, setIsHovering] = useState(false)
  const [isShowingRemoveTag, setIsShowingRemoveTag] = useState(false)
  const {groupId} = useGroupMatchParams()
  const queryClient = useQueryClient()
  const deleteTagFromGroup = async () => {
    await PoshApi.deleteTag(tag._id, groupId ?? '')
    queryClient.invalidateQueries(['groupEventsAndTags'])
  }
  const onCloseConfirmationModal = () => {
    setIsShowingRemoveTag(false)
    queryClient.invalidateQueries(['groupAttendeesPaged'])
    queryClient.invalidateQueries(['eventAttendees'])
  }
  return (
    <>
      <div
        className='TagsContainer-tag'
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{background: tag.background, color: invertColor(tag.background)}}>
        <div className='TagsContainer-tagName' onClick={onClick}>
          {tag.name}
        </div>
        {isHovering && (
          <div
            onClick={() => (onDelete ? onDelete() : setIsShowingRemoveTag(true))}
            className='TagsContainer-removeIcon'>
            ⓧ
          </div>
        )}
      </div>
      <ConfirmationModal
        isOpen={isShowingRemoveTag}
        onClose={() => onCloseConfirmationModal()}
        title='Are you sure you want to delete this tag?'
        bodyText={`Anyone tagged with ${tag.name} will be untagged`}
        errorMessage='There was an error deleting this tag, please try again.'
        confirmationMessage='Successfully deleted tag!'
        confirmButtonText='Delete Tag'
        onClick={() => deleteTagFromGroup()}
      />
    </>
  )
}

export const TagContainer = ({
  tag,
  onClick,
  userId,
  deletable,
  onDelete,
}: {
  tag: Tag
  onClick?: () => void
  userId?: string
  deletable?: boolean
  onDelete?: () => void
}) => {
  return (
    <>
      {deletable ? (
        <UnassignableTag tag={tag} userId={userId} />
      ) : (
        <DeletableTag tag={tag} onClick={onClick} onDelete={onDelete} />
      )}
    </>
  )
}
