import {useQuery} from '@tanstack/react-query'
import {Venue} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

export async function fetchVenue(venueId: string): Promise<Venue> {
  const response = await PoshApi.fetchVenue(venueId)
  const data = response.data
  return data.venue
}

export default function useFetchVenue(venueId?: string) {
  return useQuery(['venue', venueId], () => fetchVenue(venueId!), {
    enabled: !!venueId,
  })
}
