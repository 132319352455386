import React, {ButtonHTMLAttributes, forwardRef} from 'react'

import classNames from 'classnames'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import {GlassView} from '../../GlassView'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
}

const getLoaderColor = (className?: string) => {
  if (className?.includes('primary')) return 'black'
  return undefined
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>(({className, title, children, isLoading, ...props}, ref) => (
  <button
    title={title}
    className={'poshBtn ' + (className || '')}
    ref={ref}
    type='button'
    {...props}
    disabled={isLoading || props.disabled}>
    {children || title}
    {isLoading && <SpinLoader height={20} color={getLoaderColor(className)} />}
  </button>
))

Button.displayName = 'Button'

export default Button

const WHITE_OPACITY = 'rgba(255, 255, 255, 0.2)'
const GlassMorphicButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    color?: string
    glassViewStyle?: React.CSSProperties
  }
>((props, ref) => {
  const {color = WHITE_OPACITY, glassViewStyle, ...rest} = props
  const glassMorphicButtonStyles = {
    backgroundColor: color,
    borderColor: color,
    borderRadius: 50,
    ...glassViewStyle,
  }
  return (
    <GlassView style={glassMorphicButtonStyles} variants={['scaleOnHover']}>
      <Button
        ref={ref}
        {...rest}
        className={classNames(props.className, 'noHover')}
        style={{backgroundColor: 'unset', border: 'none', color: 'white', boxShadow: 'unset', ...props.style}}>
        {rest.children}
      </Button>
    </GlassView>
  )
})

GlassMorphicButton.displayName = 'GlassMorphicButton'

export {GlassMorphicButton}
