import useFetchEvent from 'apis/Events/useFetchEvent'
import {useFetchEventTickets} from 'apis/Events/useFetchEventTickets'
import {useToast} from 'components/toasts/ToastProvider'

export function useRefetchAndGoBack(eventId: string) {
  const {showToast} = useToast()
  const {refetch: refetchEvent, isLoading: isFetchingEvent} = useFetchEvent(eventId)
  const {refetch: refetchEventTickets, isLoading: isFetchingTickets} = useFetchEventTickets(eventId)

  const refetchAndGoBack = async () => {
    try {
      await Promise.all([refetchEvent(), refetchEventTickets()])
      history.back()
    } catch {
      showToast({type: 'error', title: 'There was an error fetching the event'})
    }
  }

  return {refetchAndGoBack, isRefetching: isFetchingEvent || isFetchingTickets}
}
