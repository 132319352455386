import React from 'react'

import {PlusSolid} from 'components/assets/Icons'

import {BaseNavigationItem} from '../BaseNavigationItem'
import {MobileBaseNavigationItemProps} from '../BaseNavigationItem/mobile'
import {useCreateEventPrimaryNavigation} from './navigate'

import styles from '../BaseNavigationItem/styles.module.scss'

export function MobileCreateEventNavigationItem(
  props: Omit<MobileBaseNavigationItemProps, 'isSelected' | 'icon' | 'onClick'>,
) {
  const {navigate} = useCreateEventPrimaryNavigation()
  return (
    <BaseNavigationItem.Mobile
      {...props}
      icon={<PlusSolid />}
      isSelected={false} // the Create Event button does not have a selected state
      className={styles.CreateEvent}
      onClick={navigate}
    />
  )
}
