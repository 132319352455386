import React, {useMemo, useState} from 'react'
import {Control, useController} from 'react-hook-form'

import {CountryCode, CurrencyCode, getCurrencyFromCountry} from '@posh/types'
import {
  CreateEventForm,
  CreateEventTicket,
  EventVisualsForm,
} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {v4} from 'uuid'

import {getCurrencySymbol} from '../../../../../../Util/getCurrencySymbol'
import {RSVPAdmissionSection} from './RSVPAdmissionSection'
import {OpenTicketEditorInterface, TicketEditorPanel} from './TicketEditorPanel/TicketEditorPanel'
import {TicketAdmissionSection} from './TicketsAdmissionSection'

export const determineIsRSVPEvent = (tickets: CreateEventForm['tickets']) => {
  return (
    !!tickets && tickets.length === 1 && tickets[0].price === 0 && !tickets[0].password && tickets[0].name === 'RSVP'
  )
}

type InlineAdmissionComponentProps = OpenTicketEditorInterface & {
  currencyCode: CurrencyCode
  currencySymbol: string
  tickets: CreateEventForm['tickets']
  setTickets: (tickets: CreateEventForm['tickets']) => void
}

const InlineAdmissionComponent = (props: InlineAdmissionComponentProps) => {
  const {tickets, setTickets, currencySymbol, currencyCode, ...rest} = props

  const isRSVPEvent = useMemo(() => {
    return determineIsRSVPEvent(tickets)
  }, [tickets])

  if (isRSVPEvent && !!tickets) return <RSVPAdmissionSection ticket={tickets[0]} setTickets={setTickets} {...rest} />
  if (tickets)
    return <TicketAdmissionSection tickets={tickets} setTickets={setTickets} currencyCode={currencyCode} {...rest} />
  return null
}

interface TicketEditorPanelState {
  isOpen: boolean
  initialTicket?: CreateEventTicket
  ticketEditorIndex?: number
}

interface EventVisualsAdmissionSectionProps {
  control: Control<EventVisualsForm>
  country?: string
}

export const EventVisualsAdmissionSection = (props: EventVisualsAdmissionSectionProps) => {
  const {country = 'US', control} = props
  const {currencySymbol, currencyCode} = useMemo(() => {
    const currencyCode = getCurrencyFromCountry(country as CountryCode)
    return {
      currencySymbol: getCurrencySymbol(currencyCode),
      currencyCode,
    }
  }, [country])

  const [ticketEditorState, setTicketEditorState] = useState<TicketEditorPanelState>({
    isOpen: false,
  })

  const {
    field: {onChange: setTickets, value: tickets},
  } = useController({
    control,
    name: 'tickets',
  })

  const openTicketEditor = (initialTicket?: CreateEventTicket, ticketEditorIndex?: number) => {
    setTicketEditorState({isOpen: true, initialTicket, ticketEditorIndex})
  }

  const closeTicketEditor = () => {
    setTicketEditorState({isOpen: false, initialTicket: undefined, ticketEditorIndex: undefined})
  }

  const addOrEditTicket = (ticket: CreateEventTicket, index?: number) => {
    if (index !== undefined) {
      // Edit existing ticket
      if (!tickets) setTickets([ticket])
      else {
        const newTickets = [...tickets]
        newTickets[index] = ticket
        setTickets(newTickets)
      }
    } else {
      // Add ticket
      tickets ? setTickets([...tickets, ticket]) : setTickets([ticket])
    }
    closeTicketEditor()
  }

  const ticketEditorModalKey = ticketEditorState.initialTicket?.id ?? v4().toString()

  return (
    <>
      <InlineAdmissionComponent
        openTicketEditor={openTicketEditor}
        closeTicketEditor={closeTicketEditor}
        currencyCode={currencyCode}
        currencySymbol={currencySymbol}
        tickets={tickets}
        setTickets={setTickets}
      />
      <TicketEditorPanel
        key={ticketEditorModalKey}
        isOpen={ticketEditorState.isOpen}
        onClose={closeTicketEditor}
        currencySymbol={currencySymbol}
        initialTicket={ticketEditorState.initialTicket}
        ticketEditorIndex={ticketEditorState.ticketEditorIndex}
        onComplete={addOrEditTicket}
      />
    </>
  )
}
