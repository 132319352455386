import React, {useEffect, useState} from 'react'

import {EventTicketAndTicketSold} from 'apis/Events/types'
import useFetchAffiliates from 'apis/Kickback/useFetchAffiliates'
import useFetchAllKickBackUsage from 'apis/Kickback/useFetchAllKickbackUsage'
import useFetchKickBackOffers, {KickbackOffer} from 'apis/Kickback/useFetchKickBackOffers'
import {useMixpanel} from 'apis/MixPanelHandler'
import {addUserFilled, audienceUrl, moneyBag, PlusInCircle} from 'components/assets/Icons'
import {Info} from 'components/assets/MobileIcons'
import Button from 'components/form/Button'
import {WithToolTip} from 'components/form/PoshInfo'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions from 'components/RequirePermissions'
import {useEventMatchParams} from 'domains/Events/helpers'
import {isUndefined} from 'lodash'
import {EditKickbackOffer} from 'pages/KickbackDashboard/EditKickbackOffer'

import {AFFILIATE_KICKBACK_EXPLANATION, PUBLIC_ATTENDEE_KICKBACK_EXPLANATION} from '../constants'
import TopAffilations from '../TopAffilations'
import {KickbackOfferType} from '../types'
import ExistingKickbackCard from './ExistingKickbackCard'
import KickbackOfferLimitModal from './KickbackOfferLimitModal'
import RecentKickbacks from './RecentKickbacks'

import './styles.scss'

interface KickBackOffersTableProps {
  tickets: EventTicketAndTicketSold[]
  setIsAddingKickBack: React.Dispatch<React.SetStateAction<KickbackOfferType | undefined>>
  setIsShowingAddAffiliate: React.Dispatch<React.SetStateAction<boolean>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>
  isFetchingKickbacks: boolean
}

export const KickBackOffersTable = (props: KickBackOffersTableProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {
    tickets,
    setIsAddingKickBack,
    isFetchingKickbacks,
    setIsShowingAddAffiliate,
    setErrorMessage,
    setSuccessMessage,
  } = props
  const {eventId} = useEventMatchParams()
  const {data: affiliates, isFetching: isFetchingAffiliates} = useFetchAffiliates({
    entityType: 'event',
    entity: eventId ?? '',
  })
  const {data: kickBackOffers, refetch} = useFetchKickBackOffers({entityType: 'event', entity: eventId!})
  const {data: recentKickbacks, isFetching: isFetchingRecentKickbacks} = useFetchAllKickBackUsage({eventId: eventId!})
  const [currentKickBacks, setCurrentKickBacks] = useState(kickBackOffers)
  const [editKickback, setEditKickback] = useState<KickbackOffer>()
  const [offerToLimit, setOfferToLimit] = useState<KickbackOffer>()

  useEffect(() => {
    if (kickBackOffers) setCurrentKickBacks(kickBackOffers)
  }, [kickBackOffers])

  const affiliateOffers = currentKickBacks?.filter(k => k.limitedTo && k.limitedTo.length > 0)
  const publicAttendeeOffers = currentKickBacks?.filter(k => !k.limitedTo || k.limitedTo.length === 0)
  const hasAffiliateOffers = affiliateOffers && affiliateOffers.length > 0
  const hasPublicAttendeeOffers = publicAttendeeOffers && publicAttendeeOffers.length > 0
  const onClickAddOffer = (type: KickbackOfferType) => {
    setErrorMessage('')
    setSuccessMessage('')
    trackMixpanelEvent('Kickback Create Offer-  Curator Event Kickback Page Home Header Buttons', {
      kickbackType: type,
    })
    setIsAddingKickBack(type)
  }

  return (
    <>
      {editKickback ? (
        <EditKickbackOffer kickback={editKickback} setEditKickback={setEditKickback} />
      ) : (
        <>
          <div className='KickbackOffersTable-section offers'>
            <div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <h3>Public Offers</h3>
                {hasPublicAttendeeOffers && (
                  <WithToolTip
                    info={`When you have both ticket and order offers active, whichever yields the most (order or tickets) will be applied.`}
                    style={{margin: '5px'}}>
                    <Info width={15} height={15} />
                  </WithToolTip>
                )}
              </div>
              {!hasPublicAttendeeOffers && <p>{PUBLIC_ATTENDEE_KICKBACK_EXPLANATION}</p>}
              {!hasPublicAttendeeOffers && (
                <Button
                  onClick={() => {
                    setErrorMessage('')
                    setSuccessMessage('')
                    trackMixpanelEvent('Kickback Create Offer-  Curator Event Kickback Page Home Header Buttons', {
                      kickbackType: 'public',
                    })
                    setIsAddingKickBack('public')
                  }}
                  isLoading={isFetchingKickbacks}>
                  <PoshImage src={moneyBag} style={{filter: 'brightness(0.1)'}} />
                  Create Public Offer
                </Button>
              )}
              {hasPublicAttendeeOffers && (
                <div className='KickBackOffersTable'>
                  {publicAttendeeOffers.map((k, index) => {
                    return (
                      <ExistingKickbackCard
                        key={index}
                        kickbackOfferResponse={k}
                        tickets={tickets}
                        setEditKickback={setEditKickback}
                        setOfferToLimit={setOfferToLimit}
                      />
                    )
                  })}
                  <PlusInCircle
                    height={40}
                    width={40}
                    color='white'
                    onClick={() => onClickAddOffer('public')}
                    style={{cursor: 'pointer'}}
                  />
                </div>
              )}
            </div>
            <div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <h3>Affiliate Offers</h3>
                {hasAffiliateOffers && (
                  <WithToolTip
                    info={`When you have both ticket and order offers active, whichever yields the most (order or tickets) will be applied.`}
                    style={{margin: '5px'}}>
                    <Info width={15} height={15} />
                  </WithToolTip>
                )}
              </div>
              {!hasAffiliateOffers && (
                <>
                  <p>{AFFILIATE_KICKBACK_EXPLANATION}</p>
                  <Button onClick={() => onClickAddOffer('affiliate')} isLoading={isFetchingKickbacks}>
                    <PoshImage src={audienceUrl} />
                    Create Affiliate Offer
                  </Button>
                </>
              )}
              {hasAffiliateOffers && (
                <div className='KickBackOffersTable'>
                  {affiliateOffers.map((k, index) => {
                    return (
                      <ExistingKickbackCard
                        key={index}
                        kickbackOfferResponse={k}
                        tickets={tickets}
                        setEditKickback={setEditKickback}
                        setOfferToLimit={setOfferToLimit}
                      />
                    )
                  })}
                  <PlusInCircle
                    height={40}
                    width={40}
                    color='white'
                    onClick={() => onClickAddOffer('affiliate')}
                    style={{cursor: 'pointer'}}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='KickbackOffersTable-section' style={{marginTop: '20px'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <h3>Affiliates</h3>
                <WithToolTip
                  info='Affiliates must be assigned to an offer to receive their affiliate link and begin selling tickets for your event.'
                  style={{margin: '5px', marginTop: '10px'}}>
                  <Info width={15} height={15} />
                </WithToolTip>
              </div>
              <RequirePermissions
                requiredPermissions={[
                  {
                    permissionKey: 'add_affiliate',
                    applicableScopes: ['event', 'group'],
                  },
                ]}>
                <Button
                  onClick={() => {
                    trackMixpanelEvent('Kickback Add Affiliate-  Curator Event Kickback Page Home')
                    setIsShowingAddAffiliate(true)
                  }}>
                  <PoshImage src={addUserFilled} style={{filter: 'brightness(0.1)'}} />
                  Add Affiliate
                </Button>
              </RequirePermissions>
            </div>
            {isFetchingAffiliates ? <SpinLoader height={40} /> : <TopAffilations affiliates={affiliates} />}
          </div>
          <div className='KickbackOffersTable-section'>
            <h3>Recent Kickbacks</h3>
            {isFetchingRecentKickbacks ? (
              <SpinLoader height={40} />
            ) : (
              <>
                {recentKickbacks ? (
                  <RecentKickbacks recentKickbacks={recentKickbacks} />
                ) : (
                  <p>No recent orders placed using kickbacks</p>
                )}
              </>
            )}
          </div>
          {offerToLimit && currentKickBacks && affiliates && (
            <KickbackOfferLimitModal
              isOpen={!isUndefined(offerToLimit)}
              offer={offerToLimit}
              onClose={() => setOfferToLimit(undefined)}
              noborder={true}
              existingAffiliates={affiliates}
              refetchKickbackOffers={refetch}
            />
          )}
        </>
      )}
    </>
  )
}
