import React from 'react'

import {OrderModel} from 'apis/Orders'
import {Card} from 'components/Card'
import {PoshImage} from 'components/PoshImage/PoshImage'

const OrderCardTickets = ({order}: {order: OrderModel}) => {
  return (
    <Card title={'Tickets'} animationIndex={0}>
      <table className='OrderPage-ticketsTable'>
        {order.tickets.map((t, index) => (
          <tr className='OrderPage-ticketsTable-ftd' key={index}>
            <td>
              <div className='OrderPage-ticketsTable-iconHolder'>
                <PoshImage src='https://images.posh.vip/b2/ticket+(2).svg' />
              </div>
            </td>
            <td>
              <span className='OrderPage-ticketsTable-name'>{t.name}</span>
              <br />${t.price.toFixed(2)}
            </td>
            <td>x1</td>
            <td>{order.isComplimentaryOrder ? <i>Free</i> : `$${t.price.toFixed(2)}`}</td>
          </tr>
        ))}
        {order.promoCode && order.discount && (
          <tr>
            <td style={{border: 'none'}}></td>
            <td style={{border: 'none'}} className='OrderPage-ticketsTable-promoCode'>
              <span>Promo code &quot;{order.promoCode}&quot; applied</span>
            </td>
            <td style={{border: 'none'}}></td>
            <td style={{border: 'none'}}>-${order.discount.toFixed(2)}</td>
          </tr>
        )}
      </table>
    </Card>
  )
}

export default OrderCardTickets
