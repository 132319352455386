import {useLocation, useNavigate} from 'react-router-dom'

import {browserNavigate} from 'apis/NavigationHelper'

/**
 * This hook returns a function to navigate back to the previous page. If the user has no history on the app,
 * it will navigate to the route provided as a parameter, which defaults to the root route ('/').
 */
export function useDefaultBackNavigation() {
  const navigate = useNavigate()
  const location = useLocation()

  const canGoBack = location.key !== 'default'

  const goBack = (defaultRoute = '/') => {
    if (canGoBack) navigate(-1)
    else browserNavigate(defaultRoute)
  }

  return {canGoBack, goBack}
}
