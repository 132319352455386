import React, {useEffect, useState} from 'react'
import {Editor} from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import classNames from 'classnames'
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js'
import {useDimensions} from 'hooks/useDimensions'
import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js'

import './styles.scss'

interface EditorClassnames {
  wrapperClassName?: string
  editorClassName?: string
  toolbarClassName?: string
}

interface MarkdownEditorProps {
  value?: string
  placeholder?: string
  onChange: (value: string) => void
  classNames?: EditorClassnames
  toolbarOptions?: Record<string, any> // sorry, it's a JS library
}

/**
 * A general, unstyled markdown editor that handles markdown conversion and state management
 */
export function MarkdownEditor(props: MarkdownEditorProps) {
  const {value, onChange, placeholder, classNames, toolbarOptions} = props

  const markdownEditorWithInitialState = () => {
    return EditorState.createWithContent(convertFromRaw(markdownToDraft(value ?? '')))
  }

  const getMarkdown = (editorState: EditorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    return draftToMarkdown(rawContentState)
  }

  const [editorState, setEditorState] = useState(markdownEditorWithInitialState())
  const onEditorStateChange = (editorState: EditorState) => {
    const markdown = getMarkdown(editorState)
    setEditorState(editorState)
    if (value !== markdown) onChange(markdown)
  }

  // On some initial renders, the editor state is not set correctly and only the placeholder is shown
  // This double check ensures that the editor state is set correctly
  useEffect(() => {
    const markdown = getMarkdown(editorState)
    if (value !== markdown) setEditorState(markdownEditorWithInitialState())
  }, [value])

  return (
    <Editor
      editorState={editorState}
      {...classNames}
      onEditorStateChange={onEditorStateChange}
      toolbar={toolbarOptions}
      placeholder={placeholder}
    />
  )
}

interface PoshMarkdownEditorProps extends Omit<MarkdownEditorProps, 'classNames' | 'toolbarOptions'> {
  toolBarSize?: 'small' | 'medium'
  transparent?: boolean
}

const SMALL_TOOLBAR = ['inline']
const MEDIUM_TOOLBAR = ['inline', 'blockType', 'textAlign', 'link', 'emoji', 'image', 'history']
const DEFAULT_TOOLBAR = ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image', 'history']
const getToolbar = (isMobile: boolean, toolBarSize: PoshMarkdownEditorProps['toolBarSize']) => {
  if (isMobile || toolBarSize === 'small') return SMALL_TOOLBAR
  if (toolBarSize === 'medium') return MEDIUM_TOOLBAR
  return DEFAULT_TOOLBAR
}

/**
 * Use this for opinionated styling of the markdown editor
 */
const PoshMarkdownEditor = (props: PoshMarkdownEditorProps) => {
  const {toolBarSize, transparent} = props
  const {isMobile} = useDimensions()
  const options = getToolbar(isMobile, toolBarSize)
  return (
    <MarkdownEditor
      {...props}
      classNames={{
        wrapperClassName: classNames('PoshMarkdownEditorWrapper'),
        editorClassName: classNames('PoshMarkdownEditor', {transparent: transparent}),
        toolbarClassName: classNames('PoshMarkdownEditorToolbar', {transparent: transparent}),
      }}
      toolbarOptions={{options}}
    />
  )
}

export default PoshMarkdownEditor
