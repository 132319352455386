import React from 'react'

import {GroupFormattedPayouts} from 'apis/Groups/useGetGroupPayouts'
import PaginationButtons from 'components/form/PaginationButtons'
import PoshInfo from 'components/form/PoshInfo'

import Badge from '../../../../components/Badge'

import './index.scss'

interface Props {
  data: GroupFormattedPayouts
}

const PayoutsTableRow = (props: {attributes: GroupFormattedPayouts[0]}) => {
  const renderArrivalDate = () => props.attributes.arrival_date
  const renderAmount = () =>
    `$${parseFloat(props.attributes.amount.toString()).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`
  const renderStatus = () => {
    switch (props.attributes.status) {
      case 'PAID':
        return (
          <>
            {props.attributes.method === 'instant' ? (
              <Badge className='Badge--blueGradient'>Instant</Badge>
            ) : (
              <Badge className='Badge--green'>Paid</Badge>
            )}
          </>
        )
      case 'IN TRANSIT':
        return <Badge className='Badge--skyBlue'>In Transit</Badge>
      case 'PENDING':
        return 'PENDING'
      case 'FAILED':
        return 'FAILED'
    }
  }

  const negativeAmount = renderAmount().includes('-')

  return (
    <tr className={`${negativeAmount ? 'pt-row-red' : ''} PayoutsTable`}>
      <td>{renderArrivalDate()}</td>
      <td>
        {renderAmount()}
        {negativeAmount && <PoshInfo info={'Debits are issued to cover refunds or lost disputes.'} />}
      </td>
      <td>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>{renderStatus()}</div>
      </td>
    </tr>
  )
}

interface PayoutsTableProps extends Props {
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  setStartingAfter: React.Dispatch<React.SetStateAction<string>>
  setEndingBefore: React.Dispatch<React.SetStateAction<string>>
}

const PayoutsTable = (props: PayoutsTableProps) => {
  const {data, page, setPage, setStartingAfter, setEndingBefore} = props
  const backButtonDisabled = page === 1 || !data.length
  const nextButtonDisabled = !data.length || data.length < 10

  const handleNextPage = () => {
    setPage(page + 1)
    setStartingAfter(data[data.length - 1].id)
    setEndingBefore('')
  }

  const handlePreviousPage = () => {
    setPage(page - 1 < 1 ? 1 : page - 1)
    setEndingBefore(data[0].id)
    setStartingAfter('')
  }

  return (
    <>
      {data.length ? (
        <>
          <table className='no-padding-outside'>
            <tbody>
              <tr>
                <th>Arrival Date </th>
                <th>Amount</th>
                <th></th>
              </tr>
              {data.map(rowAttr => (
                <PayoutsTableRow key={rowAttr.id} attributes={rowAttr} />
              ))}
            </tbody>
          </table>
          <div style={{display: 'flex', justifyContent: 'center', padding: '15px 0 0'}}>
            <PaginationButtons
              page={page}
              backButtonDisabled={backButtonDisabled}
              nextButtonDisabled={nextButtonDisabled}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
            />
          </div>
        </>
      ) : (
        <div className='Payouts-emptyState'>You currently have no payment history.</div>
      )}
    </>
  )
}

export default PayoutsTable
