import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {Group} from '.'

export interface FetchGroupWithCustomNumberParams {
  groupId: string
  phone: string
}

const fetchGroupWithCustomNumber = async (params: FetchGroupWithCustomNumberParams): Promise<Group> => {
  const response = await PoshApi.fetchGroupWithCustomNumber(params)
  return response.data
}

export function useFetchGroupWithCustomNumber(params: FetchGroupWithCustomNumberParams) {
  return useQuery(['groupWithCustomNumber', params], () => fetchGroupWithCustomNumber(params), {
    enabled: !!params.groupId && !!params.phone,
    retry: 15,
    retryDelay: 1000,
  })
}
