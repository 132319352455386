import React, {useState} from 'react'

import PoshInfo from 'components/form/PoshInfo'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {GMVRankMap} from 'hooks/useRankThreshold'

import './styles.scss'

const getRankInfo = (gmv: number) => {
  const GMVRanks = Object.entries(GMVRankMap).map(([name, rank]) => ({name, ...rank}))
  const currentRank = GMVRanks.find(rank => Math.floor(gmv) >= rank.min && Math.floor(gmv) <= rank.max)
  if (!currentRank) return {currentRank: null, nextRank: null, followingThreeRanks: null}

  const nextRank = GMVRanks[GMVRanks.indexOf(currentRank) + 1]
  const index = GMVRanks.indexOf(currentRank)
  const followingThreeRanks = GMVRanks.slice(index + 1, index + 4)

  return {currentRank, nextRank, followingThreeRanks}
}

const Bar = ({gmv, fullInfo}: {gmv: number; fullInfo?: boolean}) => {
  const {currentRank, nextRank} = getRankInfo(gmv)
  if (!currentRank || !nextRank) return null
  return (
    <div className={`GMVBar ${fullInfo && 'fullInfo'}`}>
      {fullInfo && (
        <div className='GMVBar-info'>{`$${gmv.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })} / $${nextRank.min.toLocaleString(undefined, {maximumFractionDigits: 0})}`}</div>
      )}
      <div className='GMVBar-barCont'>
        {fullInfo && <span>{currentRank.name}</span>}
        <div className={`GMVBar-bar ${fullInfo && 'fullInfo'}`}>
          <div className='GMVBar-bar-holder' style={{width: `${(gmv / nextRank.min!) * 100}%`}}>
            <div className='GMVBar-bar-inner' />
          </div>
        </div>
        {fullInfo && <span>{nextRank?.name}</span>}
      </div>
    </div>
  )
}

const GMVBar = ({gmv}: {gmv: number | undefined}) => {
  if (!gmv) gmv = 0

  const [isTierModalShowing, setIsTierModalShowing] = useState(false)
  const {currentRank, nextRank, followingThreeRanks} = getRankInfo(gmv)

  if (!currentRank || !nextRank) return null

  const remaining = (nextRank.min - gmv).toLocaleString(undefined, {maximumFractionDigits: 0})

  return (
    <div className='TierInfo'>
      <div className='TierInfo-top'>
        <div className='TierInfo-nameHolder'>
          <PoshImage src='https://images.posh.vip/b2/ranking.svg' />
          <div className='TierInfo-name'>
            {currentRank.name}
            <PoshInfo onClick={() => setIsTierModalShowing(true)} />
          </div>
        </div>
        <div className='GMVBar-info'>{`$${gmv.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })} / $${nextRank.min.toLocaleString(undefined, {maximumFractionDigits: 0})}`}</div>
      </div>
      <Bar gmv={gmv} />
      <PoshStyledModal isOpen={isTierModalShowing} onClose={() => setIsTierModalShowing(false)}>
        <h3>Organization Ranking</h3>
        <Bar gmv={gmv} fullInfo />
        <p>
          You are currently a <b>{currentRank.name}</b>. You are ${remaining} in revenue away from becoming a{' '}
          <b>{nextRank.name}</b>.
        </p>
        {currentRank.perks.length > 0 && (
          <>
            <p>You recently unlocked:</p>
            <ul>
              {currentRank.perks.map((perk, i) => (
                <li key={i}>{perk}</li>
              ))}
            </ul>
          </>
        )}
        <h4>Upcoming Ranks</h4>
        <table>
          <tr>
            <th>Rank</th>
            <th>Revenue</th>
            <th>Reward(s)</th>
          </tr>
          {followingThreeRanks?.map(rank => (
            <tr key={rank.name}>
              <td>{rank.name}</td>
              <td>${rank.min.toLocaleString(undefined, {maximumFractionDigits: 0})}</td>
              <td>
                <ul>
                  {rank.perks.map(p => (
                    <li key={p}>{p}</li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </table>
        <p style={{textAlign: 'right', fontSize: '14px'}}>
          <i>*Email rewards@posh.vip to redeem this reward</i>
        </p>
      </PoshStyledModal>
    </div>
  )
}

export default GMVBar
