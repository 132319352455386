import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {GalleryModel} from './types'

export async function getGallery(eventUrl: string) {
  const response = await PoshApi.getGallery(eventUrl)
  return response.data.gallery
}

export function useFetchGallery(eventUrl?: string) {
  return useQuery<GalleryModel>(['gallery', eventUrl], () => getGallery(eventUrl ?? ''), {
    enabled: !!eventUrl,
  })
}
