import React from 'react'
import {Control} from 'react-hook-form'

import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import useSessionContext from 'domains/Auth/SessionContext'

import {EventVisualsAccountInfoSection} from '../AccountInformation/EventVisualsAccountInformationSection'

interface CreateEventOrganizerDetailsProps {
  control: Control<EventVisualsForm>
  defaultCountry?: string
}

/**
 * This component is used to render the Account Information section for the event visuals page (Create Event page)
 */
export const CreateEventOrganizerDetails = (props: CreateEventOrganizerDetailsProps) => {
  const {control, defaultCountry} = props
  const {currentUser} = useSessionContext()
  if (!currentUser) return <EventVisualsAccountInfoSection control={control} defaultCountry={defaultCountry} />
  return null
}
