import {trpc} from 'lib/trpc'

export default function useUpdateCart() {
  const trpcQueryClient = trpc.useContext()
  return trpc.checkout.updateCart.useMutation({
    onSuccess: (_, submittedUpdates) => {
      // only refetch summary if it can affect price breakdown
      if (submittedUpdates.promoCodeValue !== undefined || submittedUpdates.selectedPaymentPlan !== undefined) {
        trpcQueryClient.checkout.getCheckoutSummary.invalidate()
      }
    },
  })
}
