import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
import {trpc} from 'lib/trpc'

export interface UpdateKickBackActivationProps {
  id: string
  enabled: boolean
}

const updateKickBackActivation = async (params: UpdateKickBackActivationProps) => {
  const response = await PoshApi.updateKickBackActivation(params)
  return response.data
}

export function useUpdateKickBackActivation() {
  const qc = trpc.useContext()
  return useMutation<unknown, unknown, UpdateKickBackActivationProps>(params => updateKickBackActivation(params), {
    onSettled: () => {
      qc.kickbacks.getKickbackOffers.invalidate()
    },
  })
}
