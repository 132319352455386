import React, {ReactNode, useRef, useState} from 'react'

import classNames from 'classnames'
import {PlainX} from 'components/assets/Icons'

import './DeleteableBadge.styles.scss'

export interface DeleteableBadgeProps {
  children: ReactNode
  variant: 'red' | 'yellow' | 'blue'
  onClick?: () => void
}

const DeleteableBadge = ({children, variant, onClick}: DeleteableBadgeProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const dimensionsRef = useRef<HTMLSpanElement>(null)

  const onMouseEnter = () => {
    setIsHovered(true)
  }
  const onMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <span
      contentEditable={false}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={dimensionsRef}
      style={{minWidth: dimensionsRef.current?.offsetWidth, minHeight: dimensionsRef.current?.offsetHeight}}
      className={classNames('DeleteableBadge', variant)}>
      {isHovered ? <PlainX width={12} height={12} /> : children}
    </span>
  )
}

export default DeleteableBadge
