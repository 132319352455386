import {useQuery} from '@tanstack/react-query'
import {OrderTicketModel} from 'apis/Orders'

import PoshApi from '../PoshApi'
import {EventTable} from './types'

export interface TransformedBreakdown<T> {
  [key: string]: {
    quantity: number
    collection: T[]
    total: number
  }
}

export interface BreakdownResponse {
  clicks?: number
  tableNameBreakdown: TransformedBreakdown<EventTable>
  promoCodeFrequencyBreakdown: TransformedBreakdown<number>
  ticketTierBreakdown: TransformedBreakdown<OrderTicketModel>
  total: number
  avi?: string
  fullName?: string
}

async function fetchEventHostBreakdown(eventId: string, hostId: string) {
  const response = await PoshApi.hostsBreakdown(eventId, hostId)
  const data: BreakdownResponse = response.data
  return data
}

export function useFetchEventHostBreakdown(eventId?: string, hostId?: string) {
  return useQuery(['hostBreakdown', eventId, hostId], () => fetchEventHostBreakdown(eventId ?? '', hostId ?? ''), {
    enabled: !!hostId && !!eventId,
  })
}

async function fetchEventTrackingLinkBreakdown(eventId: string, trackingLink: string) {
  const response = await PoshApi.trackingLinkBreakdown(eventId, trackingLink)
  const data: BreakdownResponse = response.data
  return data
}

export function useFetchEventTrackingLinkBreakdown(eventId: string, trackingLink?: string) {
  return useQuery(
    ['trackingLinkBreakdown', eventId, trackingLink],
    () => fetchEventTrackingLinkBreakdown(eventId, trackingLink ?? ''),
    {
      enabled: !!trackingLink,
    },
  )
}

async function fetchEventAffiliateTrackingLinkBreakdown(eventId: string, affiliateLink: string) {
  const response = await PoshApi.affiliateTrackingLinkBreakdown(eventId, affiliateLink)
  const data: BreakdownResponse = response.data
  return data
}

export function useFetchEventAffiliateTrackingLinkBreakdown(eventId: string, affiliateLink?: string) {
  return useQuery(
    ['affiliateTrackingLinkBreakdown', eventId, affiliateLink],
    () => fetchEventAffiliateTrackingLinkBreakdown(eventId, affiliateLink ?? ''),
    {
      enabled: !!affiliateLink,
    },
  )
}
