import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type CreateFreeOrderOptions = ReactQueryOptions['checkout']['createFreeOrder']
export type CreateFreeOrderInput = RouterInputs['checkout']['createFreeOrder']
export type CreateFreeOrderOutput = RouterOutput['checkout']['createFreeOrder']

export function useCreateFreeOrder(opts?: CreateFreeOrderOptions) {
  const qc = trpc.useContext()
  return trpc.checkout.createFreeOrder.useMutation({
    ...opts,
    onSettled: (...params) => {
      qc.events.getEventByUrl.invalidate()
      opts?.onSettled?.(...params)
    },
  })
}
