import React from 'react'

import {BaseNavigationItem} from '../BaseNavigationItem'
import {navigationIcons} from './icons'
import {usePageNavigation} from './pageNavigation'

function Organizations() {
  const {navigate, isOnPage} = usePageNavigation().organizations
  return <BaseNavigationItem.Mobile {...navigationIcons.organizations} isSelected={isOnPage} onClick={navigate} />
}

function Orders() {
  const {navigate, isOnPage} = usePageNavigation().orders
  return <BaseNavigationItem.Mobile {...navigationIcons.orders} isSelected={isOnPage} onClick={navigate} />
}

export class MobilePageNavigationItem {
  static Organizations = Organizations
  static Orders = Orders
}
