import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {FetchEventAttendeesParams} from './types'

export type FetchAllEventAttendeesParams = Omit<FetchEventAttendeesParams, 'page'>

export async function fetchEventAttendees(params: FetchAllEventAttendeesParams): Promise<string[]> {
  const response = await PoshApi.fetchAllEventAttendees(params)
  const data = response.data
  return data.attendees as string[]
}

export function useFetchAllEventAttendees() {
  return useMutation<string[], unknown, FetchAllEventAttendeesParams>(params => fetchEventAttendees(params), {
    retry: false,
  })
}
