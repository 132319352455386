import {useState} from 'react'

import {ApproveOrDeny} from '.'

const useApprovalButtons = () => {
  const [expandedType, setExpandedType] = useState<ApproveOrDeny>()
  const handleExpandButton = (which: ApproveOrDeny) => {
    setExpandedType(which)
  }

  const handleCancelConfirmation = () => {
    setExpandedType(undefined)
  }

  return {handleExpandButton, handleCancelConfirmation, expandedType}
}

export default useApprovalButtons
