import React, {useEffect, useState} from 'react'
import {isMobile} from 'react-device-detect'
import {useNavigate} from 'react-router-dom'

import {CommunityCategory, MarketFilters, SortBy} from 'apis/Community'
import {useFetchCommunityByCategory} from 'apis/Community/useFetchCommunityByCategory'
import useFetchCommunityFilters from 'apis/Community/useFetchCommunityFilters'
import {useMixpanel} from 'apis/MixPanelHandler'
import {searchIconUrl} from 'assets/images/s3images'
import TextField, {TextFieldVariants} from 'components/form/TextField'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import PoshHeader from 'pages/OwnerPage/PoshHeader'

import Artists from './Artists'
import DetailsModal, {DetailEntity} from './DetailsModal'
import Filter from './Filter'
import FirstTimeModal from './FirstTimeModal'
import CommunityHelmet from './Helmet'
import StaffGroup from './StaffGroup'
import Venues from './Venues'

import './index.scss'

const Community = ({landingPage, groupNav}: {landingPage?: boolean; groupNav?: boolean}) => {
  const {groupId} = useGroupMatchParams()
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const urlCategory = urlParams.get('c') as CommunityCategory
  const {currentUser} = useSessionContext()
  const urlMarket = urlParams.get('m') as MarketFilters
  const urlSort = urlParams.get('s') as SortBy
  const globalChecks = urlParams.get('g') as string
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const [firstTimeModalIsOpen, setFirstTimeModalIsOpen] = useState(false)
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedDetailEntity, setSelectedDetailEntity] = useState<DetailEntity>({})
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<CommunityCategory>(urlCategory ?? 'staff')
  const [market, setMarket] = useState<MarketFilters>(urlMarket ?? 'All')
  const [sortBy, setSortBy] = useState<SortBy>(urlSort ?? 'Most Popular')
  const [selectedChecks, setSelectedChecks] = useState<string[]>([])
  const [localSearchQuery, setLocalSearchQuery] = useState('')
  const [range, setRange] = React.useState<number[]>([0, 0])
  const {data: filters, isFetching: isFetchingFilters} = useFetchCommunityFilters(selectedCategory)
  const {data: community} = useFetchCommunityByCategory({
    selectedCategory,
    market,
    sortBy,
    selectedChecks,
    searchQuery,
    maxRange: range[1],
    minRange: range[0],
  })

  useEffect(() => {
    setSelectedCategory(urlCategory ?? 'staff')
    setMarket(urlMarket ?? 'All')
    setSortBy(urlSort ?? 'Most Popular')
    setSelectedChecks([])
    setSearchQuery('')
    setRange([0, 0])
  }, [selectedCategory, urlCategory])

  useEffect(() => {
    if (detailsModalOpen) {
      document.documentElement.style.overflow = 'hidden'
    } else document.documentElement.style.overflow = 'unset'
  }, [detailsModalOpen])

  const mobileVenueFilters = filters ? filters[1]?.values : null
  const mobileVenueFiltersSelect = (value: string) => {
    return (
      <option key={value} value={value}>
        {value}
      </option>
    )
  }

  useEffect(() => {
    if (filters) {
      filters.forEach(filter => {
        if (filter.type === 'range') {
          setRange([filter.defaultMin ?? 0, filter.defaultMax ?? 0])
        }
      })
    }
  }, [filters])

  useEffect(() => {
    const delayedDebounce = setTimeout(() => {
      setSearchQuery(localSearchQuery)
    }, 1000)

    return () => clearTimeout(delayedDebounce)
  }, [localSearchQuery])

  const handleGlobalUrlParams = () => {
    if (globalChecks && globalChecks.includes('-')) {
      const jobTitlesArray = globalChecks.split('-')
      setSelectedChecks(jobTitlesArray)
    } else if (globalChecks) {
      setSelectedChecks([globalChecks])
    }
  }

  const handleGlobalSelectedChecks = () => {
    if (selectedChecks.length > 0) {
      const globalChecksString = selectedChecks.join('-')
      navigate(`?c=${selectedCategory}&m=${market}&s=${sortBy}&g=${globalChecksString}`)
    } else navigate(`?c=${selectedCategory}&m=${market}&s=${sortBy}`)
  }

  useEffect(() => {
    if (detailsModalOpen) {
      document.documentElement.style.overflow = 'hidden'
    } else document.documentElement.style.overflow = 'unset'
  }, [detailsModalOpen])

  useEffect(() => {
    handleGlobalSelectedChecks()
  }, [selectedChecks])

  useEffect(() => {
    handleGlobalUrlParams()
  }, [])

  const getCurrentCommunityLength = () => {
    if (community && community.type === 'VenuesResponse') {
      return community.venues.length
    } else if (community && community.type === 'CommunityArtistsResponse') {
      return community.artists.length
    } else if (community && community.type === 'StaffMembersResponse') {
      return community.staffMembers.length
    }
    return 0
  }

  const handleCategorySelection = (category: CommunityCategory) => {
    setSelectedCategory(category)
    navigate(`?c=${category}`)
    const accountEmail = currentUser?.email
    const accountId = currentUser?._id
    const accountName = currentUser?.firstName + ' ' + currentUser?.lastName
    trackMixpanelEvent('Community Tab Clicked', {
      communityCategory: category,
      accountName,
      accountEmail,
      accountId,
    })
    if (selectedChecks.length > 0) {
      const globalChecksString = selectedChecks.join('-')
      navigate(`?c=${category}&m=${market}&s=${sortBy}&g=${globalChecksString}`)
    } else navigate(`?c=${category}&m=${market}&s=${sortBy}`)
  }

  const handleMarketSelection = (market: MarketFilters) => {
    setMarket(market)
    if (selectedChecks.length > 0) {
      const globalChecksString = selectedChecks.join('-')
      navigate(`?c=${selectedCategory}&m=${market}&s=${sortBy}&g=${globalChecksString}`)
    } else navigate(`?c=${selectedCategory}&m=${market}&s=${sortBy}`)
  }

  const handleSortSelection = (sortString: SortBy) => {
    setSortBy(sortString)
    if (selectedChecks.length > 0) {
      const globalChecksString = selectedChecks.join('-')
      navigate(`?c=${selectedCategory}&m=${market}&s=${sortBy}&g=${globalChecksString}`)
    } else navigate(`?c=${selectedCategory}&m=${market}&s=${sortBy}`)
  }

  return (
    <>
      {!landingPage && <CommunityHelmet />}
      <div className='Community'>
        {!landingPage && (
          <>
            {!groupNav && <PoshHeader community />}
            <div className='Community-header'>
              <PoshImage src='https://images.posh.vip/b2/posh-community.png' />
            </div>
          </>
        )}

        <div className='Community-categories'>
          <div
            onClick={() => handleCategorySelection('staff')}
            className={`${selectedCategory === 'staff' ? 'selected' : ''}`}>
            Staff
          </div>
          <div
            onClick={() => handleCategorySelection('venue')}
            className={`${selectedCategory === 'venue' ? 'selected' : ''}`}>
            Venues
          </div>
          <div
            onClick={() => handleCategorySelection('artist')}
            className={`${selectedCategory === 'artist' ? 'selected' : ''}`}>
            Artists
          </div>
        </div>

        <div className='CommunitySearchWindow'>
          <div className='CommunitySearchWindow-filter'>
            {filters && !isFetchingFilters ? (
              <>
                {filters.map((filter, index) => (
                  <Filter
                    key={index}
                    handleMarketSelection={handleMarketSelection}
                    market={market}
                    setSelectedChecks={setSelectedChecks}
                    selectedChecks={selectedChecks}
                    range={range}
                    setRange={setRange}
                    filter={filter}
                  />
                ))}
              </>
            ) : (
              <div>
                <SpinLoader height={50} />
              </div>
            )}
          </div>
          <div className='CommunitySearchWindow-search'>
            <div className='CommunitySearchWindow-search-searchBar'>
              <div className='CommunitySearchWindow-search-searchBar-results'>
                {getCurrentCommunityLength()} results
              </div>
              <div className='CommunitySearchWindow-search-searchBar-searchBarCont'>
                <TextField
                  variant={TextFieldVariants.DARK_OUTLINED}
                  type='text'
                  name='ordernumber'
                  placeholder='Search'
                  onChange={e => setLocalSearchQuery(e.target.value)}
                />
                <PoshImage src={searchIconUrl} />
              </div>
              <select
                onChange={e => handleSortSelection(e.target.value as SortBy)}
                className='CommunitySearchWindow-search-searchBar-dropdown'>
                <option value={'Most Popular'} selected={sortBy === 'Most Popular'}>
                  Most Popular
                </option>
                <option value={'Newest'} selected={sortBy === 'Newest'}>
                  Newest
                </option>
                <option value={'Oldest'} selected={sortBy === 'Oldest'}>
                  Oldest
                </option>
                <option value={'Least Popular'} selected={sortBy === 'Least Popular'}>
                  Least Popular
                </option>
                {selectedCategory === 'venue' && (
                  <>
                    <option value={'Buyout Low to High'} selected={sortBy === 'Buyout Low to High'}>
                      Buyout Low to High
                    </option>
                    <option value={'Buyout High to Low'} selected={sortBy === 'Buyout High to Low'}>
                      Buyout High to Low
                    </option>
                    <option value={'Capacity Low to High'} selected={sortBy === 'Capacity Low to High'}>
                      Capacity Low to High
                    </option>
                    <option value={'Capacity High to Low'} selected={sortBy === 'Capacity High to Low'}>
                      Capacity High to Low
                    </option>
                  </>
                )}
              </select>
            </div>
            {isMobile && (
              <div className='CommunitySearchWindow-search-mobile'>
                <select
                  onChange={e => handleMarketSelection(e.target.value as MarketFilters)}
                  className='CommunitySearchWindow-search-mobile-dropDown'
                  defaultValue={market}>
                  <option value={'All'}>All</option>
                  <option value={'New York'}>New York</option>
                  <option value={'Miami'}>Miami</option>
                  <option value={'Los Angeles'}>Los Angeles</option>
                </select>
                {selectedCategory === 'venue' && (
                  <select
                    onChange={e => setSelectedChecks([e.target.value])}
                    className='CommunitySearchWindow-search-mobile-dropDown'>
                    {mobileVenueFilters?.map(mobileVenueFiltersSelect)}
                  </select>
                )}
                {selectedCategory === 'staff' && (
                  <select
                    onChange={e => setSelectedChecks([e.target.value])}
                    className='CommunitySearchWindow-search-mobile-dropDown'>
                    <option value={'Bartender'}>Bartender</option>
                    <option value={'Photographer'}>Photographer</option>
                    <option value={'Videographer'}>Videographer</option>
                    <option value={'Graphic Designer'}>Graphic Designer</option>
                    <option value={'Security Guard'}>Security Guard</option>
                  </select>
                )}
                {selectedCategory === 'artist' && (
                  <select
                    onChange={e => setSelectedChecks([e.target.value])}
                    className='CommunitySearchWindow-search-mobile-dropDown'>
                    <option value={'DJ'}>DJ</option>
                    <option value={'Instrumentalist'}>Instrumentalist</option>
                    <option value={'Vocalist'}>Videographer</option>
                    <option value={'Band'}>Band</option>
                  </select>
                )}
                {selectedCategory === 'artist' && (
                  <select
                    onChange={e => setSelectedChecks([e.target.value])}
                    className='CommunitySearchWindow-search-mobile-dropDown'>
                    <option value={'House'}>House</option>
                    <option value={'EDM'}>EDM</option>
                    <option value={'Hip-Hop/Rap'}>Hip-Hop/Rap</option>
                    <option value={'Classical'}>Classical</option>
                    <option value={'Rock'}>Rock</option>
                    <option value={'Alternative'}>Alternative</option>
                  </select>
                )}
              </div>
            )}
            {community && (
              <div className='CommunitySearchWindow-search-results'>
                {selectedCategory === 'venue' && community.type === 'VenuesResponse' && (
                  <Venues venues={community.venues} />
                )}
                {selectedCategory === 'staff' && community.type === 'StaffMembersResponse' && (
                  <StaffGroup staffMembers={community.staffMembers} />
                )}
                {selectedCategory === 'artist' && community.type === 'CommunityArtistsResponse' && (
                  <Artists artists={community.artists} isTrending={true} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {detailsModalOpen && (
        <DetailsModal selectedDetailEntity={selectedDetailEntity} setDetailsModalOpen={setDetailsModalOpen} />
      )}
      {firstTimeModalIsOpen && <FirstTimeModal setFirstTimeModalIsOpen={setFirstTimeModalIsOpen} />}
    </>
  )
}

export default Community
