import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {CurrencyCode} from '@posh/types'
import {AttendeeRowAttributesEvents} from 'apis/Events/types'
import {AttendeeRowAttributes} from 'apis/Groups'
import {infoButton} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import {useDimensions} from 'hooks/useDimensions'
import {includes} from 'lodash'
import moment from 'moment'
import mongoose from 'mongoose'
import AttendeeAlerts from 'pages/GroupPage/PageComponents/AttendeeAlerts'
import {useResourcePageParams} from 'pages/PoshAppLayout'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import {AttendeeCheckBox} from '../AttendeeCheckBox'
import AttendeeRowSocials from '../AttendeeRowSocials'
import AttendeeRowTags from '../AttendeeRowTags'
import useAttendeesCrmContext from '../AttendeesCrmContext'

interface AttendeeRowProps {
  rowAttrs: AttendeeRowAttributes | AttendeeRowAttributesEvents
  isHost?: boolean
  currency?: CurrencyCode
  shouldShowCustomCheckoutFields: boolean
  isRSVPEvent?: boolean
}

const AttendeeRow = ({rowAttrs, isHost, currency, shouldShowCustomCheckoutFields, isRSVPEvent}: AttendeeRowProps) => {
  const {isMobile} = useDimensions()
  const {domain, groupId} = useResourcePageParams()
  const {selectedAttendeeIds, setSelectedAttendeeIds, isEventScoped} = useAttendeesCrmContext()
  const navigate = useNavigate()
  const search = useLocation().search
  const showOrderDate = (orderId?: string) => {
    if (orderId) {
      if (moment().isAfter(moment(new mongoose.Types.ObjectId(orderId).getTimestamp()))) {
        const date = moment(new mongoose.Types.ObjectId(orderId).getTimestamp()).format('MMM DD')
        return date
      }
      const date = moment(new mongoose.Types.ObjectId(orderId).getTimestamp()).format('h:mma')
      return date
    }
    return 'Imported User'
  }

  const shouldShowTicket = () => {
    if (!isMobile && !isRSVPEvent) return true
    return false
  }

  const shouldShowSpend = () => {
    if (isHost && !isRSVPEvent) return true
    else if (!isMobile && !isRSVPEvent) return true
    return false
  }

  if (!rowAttrs) return null
  return (
    <tr>
      <td>
        {!isHost && (
          <AttendeeCheckBox
            defaultChecked={includes(selectedAttendeeIds, rowAttrs.id)}
            onChecked={checked => {
              if (checked) setSelectedAttendeeIds([...selectedAttendeeIds, rowAttrs.id])
              if (!checked) setSelectedAttendeeIds([...selectedAttendeeIds.filter(id => id !== rowAttrs.id)])
            }}
          />
        )}
      </td>

      <td colSpan={2} className='AttendeesTable-img-name'>
        {!isMobile && (
          <PoshImage
            src={rowAttrs.avi && rowAttrs.avi !== '' ? rowAttrs.avi : 'https://images.posh.vip/b2/default-avi.jpg'}
          />
        )}
        {rowAttrs.name !== 'undefined undefined' && rowAttrs.name ? rowAttrs.name : <i>No Name</i>}
      </td>
      {shouldShowTicket() && <td>{rowAttrs.ticketsBought}</td>}
      {shouldShowSpend() && (
        <td>
          {getCurrencySymbol(currency)} {rowAttrs.totalSpend.toFixed(1)}0
        </td>
      )}
      <>
        {!isHost && (
          <td>
            <AttendeeRowSocials instagramUrl={rowAttrs.instagramUrl} phoneNumber={rowAttrs.phoneNumber} />
          </td>
        )}
        {!isHost && !isMobile && (
          <td colSpan={shouldShowCustomCheckoutFields ? 1 : 2}>
            {isEventScoped ? (
              <AttendeeRowTags tags={rowAttrs.tags} userId={rowAttrs.id} links={rowAttrs.trackingLinks} />
            ) : (
              <AttendeeRowTags tags={rowAttrs.tags} userId={rowAttrs.id} />
            )}
          </td>
        )}
        <>
          {!isMobile && !isRSVPEvent && <td>{showOrderDate(rowAttrs.lastOrder)}</td>}
          {!isMobile && shouldShowCustomCheckoutFields && (
            <td colSpan={2} className='AttendeesTable-checkoutFieldData'>
              {rowAttrs.checkoutFieldResponse}
            </td>
          )}
          <td>
            <AttendeeAlerts textDisabled={rowAttrs.smsOptIn === false ? true : false} />
          </td>
          {!!rowAttrs.lastOrder && (
            <td className='AttendeesTable-viewOrdersRow'>
              <RequirePermissions
                requiredPermissions={[requirePermissionAndScope('view_attendee', ['group', 'event'])]}>
                <a
                  className='AttendeesTable-viewOrders'
                  onClick={() => navigate(`/${domain}/groups/${groupId}/marketing/attendee/${rowAttrs.id}` + search)}>
                  <PoshImage src={infoButton} />
                </a>
              </RequirePermissions>
            </td>
          )}
        </>
      </>
    </tr>
  )
}

export default AttendeeRow
