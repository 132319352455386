// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvailableSortFields__60Fqj{display:flex;align-items:center;gap:5px}.ChosenSortFields__KJ1ds{display:flex;flex-direction:column;gap:5px}.SortField__FNLc0{display:flex;flex-direction:row;justify-content:space-between;gap:10px;align-items:center;border:1px #fff solid;border-radius:5px;padding:5px}", "",{"version":3,"sources":["webpack://./src/pages/ManageEventPlaylists/components/EventPlaylistSortOrderInput.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,yBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,kBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,kBAAA,CACA,qBAAA,CACA,iBAAA,CACA,WAAA","sourcesContent":[".AvailableSortFields {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n\n.ChosenSortFields {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.SortField {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 10px;\n  align-items: center;\n  border: 1px white solid;\n  border-radius: 5px;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AvailableSortFields": "AvailableSortFields__60Fqj",
	"ChosenSortFields": "ChosenSortFields__KJ1ds",
	"SortField": "SortField__FNLc0"
};
export default ___CSS_LOADER_EXPORT___;
