// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventVisualsLineupSection__9Utxs{display:grid;gap:20px}.EventVisualsLineupItems__AFAa7{display:grid;grid-template-columns:1fr 1fr;gap:20px}.EventVisualsLineupItems__AFAa7>*{margin-bottom:20px}@media(max-width: 800px){.EventVisualsLineupItems__AFAa7{grid-template-columns:1fr}}.EventVisualsLineupItems__AFAa7.dragged__MeW9r{filter:brightness(0.8);cursor:grabbing}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/Lineup/styles.module.scss"],"names":[],"mappings":"AAAA,kCACI,YAAA,CACA,QAAA,CAGJ,gCACI,YAAA,CACA,6BAAA,CACA,QAAA,CAEA,kCACI,kBAAA,CAGJ,yBATJ,gCAUQ,yBAAA,CAAA,CAGJ,+CACI,sBAAA,CACA,eAAA","sourcesContent":[".EventVisualsLineupSection {\n    display: grid;\n    gap: 20px;\n}\n\n.EventVisualsLineupItems {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 20px;\n\n    > * {\n        margin-bottom: 20px;\n    }\n\n    @media (max-width: 800px) {\n        grid-template-columns: 1fr;\n    }\n\n    &.dragged {\n        filter: brightness(0.8);\n        cursor: grabbing;\n    }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EventVisualsLineupSection": "EventVisualsLineupSection__9Utxs",
	"EventVisualsLineupItems": "EventVisualsLineupItems__AFAa7",
	"dragged": "dragged__MeW9r"
};
export default ___CSS_LOADER_EXPORT___;
