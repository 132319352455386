import React from 'react'

import {RenderElementProps} from 'slate-react'

const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{fontSize: 0}}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

const InlineBadge = ({attributes, children}: RenderElementProps) => {
  return (
    <span {...attributes} contentEditable={false} className='ComposeYourMessage-editor-badge'>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </span>
  )
}

export default InlineBadge
