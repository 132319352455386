import React from 'react'

import {CreateBookingRequestData} from 'apis/Community/useCreateBookingRequest'
import {getDate, getTime} from 'pages/CommunityDashboard/dashboardHelpers'

import {DetailEntity} from '..'

interface SuccessfulBookingRequestProps {
  selectedDetailEntity: DetailEntity
  bookingDetails: CreateBookingRequestData
  setDetailsModalOpen: (value: React.SetStateAction<boolean>) => void
}

const SuccessfulBookingRequest = (props: SuccessfulBookingRequestProps) => {
  const {selectedDetailEntity, bookingDetails, setDetailsModalOpen} = props
  return (
    <div className='cDetailsModal-details-success'>
      <p className='cDetailsModal-details-success-header'>{`Your booking request has been sent to ${selectedDetailEntity.title}!`}</p>
      <div className='cDetailsModal-details-success-body'>
        <p>{bookingDetails.location}</p>
        <p>{bookingDetails.startTime && getDate(bookingDetails.startTime)}</p>
        <p>{`${bookingDetails.startTime && getTime(bookingDetails.startTime)} - ${
          bookingDetails.endTime && getTime(bookingDetails.endTime)
        }`}</p>
      </div>
      <button onClick={() => setDetailsModalOpen(false)}>RETURN TO EXPLORE</button>
    </div>
  )
}

export default SuccessfulBookingRequest
