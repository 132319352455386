import React from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {useCreatePromoCode} from 'apis/Events/useCreatePromoCode'
import {Form} from 'components/DynamicForm/Form/Form'
import {useToast} from 'components/toasts/ToastProvider'

import {PromoCode} from './PromoCodesSection'
import {getCreatePromoCodeFormConfig, getCreatePromoCodeFormSchema, TicketMapping} from './utils/form-configs'

interface PromoCodeCreateFormProps {
  eventId: string
  promoCodes: PromoCode[]
  ticketMapping: TicketMapping[]
  onClose: () => void
}

export const PromoCodeCreateForm = (props: PromoCodeCreateFormProps) => {
  const {eventId, promoCodes, ticketMapping, onClose} = props

  const {showToast} = useToast()
  const queryClient = useQueryClient()

  const {mutateAsync: createPromoCode, isLoading: isCreatingPromoCode} = useCreatePromoCode({
    onSuccess: ({created}) => {
      queryClient.invalidateQueries(['eventPromoCodes'])
      showToast({type: 'success', title: `Created promo code: ${created.code}`})
      onClose()
    },
    onError: err => {
      showToast({
        type: 'error',
        title: `Something went wrong when creating promo code.`,
        subtitle: err.response.data.error,
      })
    },
  })

  const inputConfig = getCreatePromoCodeFormConfig(ticketMapping)
  const schema = getCreatePromoCodeFormSchema(promoCodes)

  return (
    <Form
      inputConfig={inputConfig}
      schema={schema}
      onSubmit={data =>
        createPromoCode({
          eventId,
          code: data.code,
          type: data.type,
          value: data.value,
          limit: data.limit ?? 0,
          limitedToTicket: data.limitedTo,
          disabled: false,
        })
      }
      submitText='Create Promo Code'
      isSubmitting={isCreatingPromoCode}
      onCancel={onClose}
      defaultValues={{
        type: 'percentage',
      }}
    />
  )
}
