import React from 'react'

import {CurrencyCode} from '@posh/types'
import Button from 'components/form/Button'
import {TicketSection} from 'components/PageComponents/EventVisuals/Page/Section/ticketSection'
import {CreateEventForm, CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {SlideOutModal, SlideOutModalProps} from 'components/Panel/SlideOutModal'

import {AdmissionSectionTicket} from './AdmissionSectionTicket'
import {OpenTicketEditorInterface} from './TicketEditorPanel/TicketEditorPanel'

import styles from './styles.module.scss'

interface AdmissionSettingsModalProps extends SlideOutModalProps {
  tickets: NonNullable<CreateEventForm['tickets']>
  setTickets: (tickets: CreateEventForm['tickets']) => void
  currencyCode: CurrencyCode
  switchToRSVP: () => void
  openTicketEditor: OpenTicketEditorInterface['openTicketEditor']
}

export const AdmissionSettingsModal = (props: AdmissionSettingsModalProps) => {
  const {tickets, setTickets, currencyCode, switchToRSVP, openTicketEditor, ...rest} = props

  const onEdit = (ticket: CreateEventTicket, index: number) => {
    openTicketEditor(ticket, index)
  }

  const onDuplicate = (ticket: CreateEventTicket) => {
    openTicketEditor({...ticket, id: undefined, name: `Duplicate of ${ticket.name}`})
  }

  const onDelete = (ticket: CreateEventTicket) => {
    if (ticket.id) setTickets(tickets.filter(t => t.id !== ticket.id))
    else setTickets(tickets.filter(t => t.name !== ticket.name))
  }

  return (
    <SlideOutModal {...rest} title='Admission Settings'>
      <TicketSection title='Tickets' style={{overflowX: 'scroll', maxHeight: 500}}>
        {tickets.map((ticket, index) => (
          <AdmissionSectionTicket
            ticket={ticket}
            currencyCode={currencyCode}
            key={index}
            onEdit={ticket => onEdit(ticket, index)}
            onDuplicate={onDuplicate}
            onDelete={tickets.length > 1 ? onDelete : undefined}
          />
        ))}
      </TicketSection>
      <div className={styles.bottomSectionWrapper}>
        <p onClick={switchToRSVP} className='clickable bold underline'>
          Switch to RSVP Event
        </p>
        <div className={styles.buttonWrapper}>
          <Button className='dark' onClick={rest.onClose}>
            Cancel
          </Button>
          <Button className='gold' onClick={() => openTicketEditor()}>
            Create New Ticket
          </Button>
        </div>
      </div>
    </SlideOutModal>
  )
}
