import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FollowAccountOptions = ReactQueryOptions['social']['account']['followAccount']
export type FollowAccountInput = RouterInputs['social']['account']['followAccount']
export type FollowAccountOutput = RouterOutput['social']['account']['followAccount']

export function useFollowAccount(opts?: FollowAccountOptions) {
  const ctx = trpc.useContext()
  return trpc.social.account.followAccount.useMutation({
    ...opts,
    onSettled: (...args) => {
      ctx.social.account.listAccountFollowing.refetch()
      opts?.onSettled?.(...args)
    },
  })
}
