import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {AccountModelPublic} from '.'

export async function fetchAccountByUsername(username: string): Promise<AccountModelPublic | null> {
  const response = await PoshApi.fetchAccountByUsername(username)
  const data = response.data
  return data.account
}

export function useFetchAccountByUsername(username: string) {
  return useQuery(['account', username], () => fetchAccountByUsername(username), {
    enabled: !!username,
  })
}
