import React from 'react'

import {EventPublic} from 'apis/Events/types'
import {EventCard} from 'pages/EventCard'

import './styles.scss'

interface PastEventsProps {
  type: string
  pastEvents: EventPublic[]
}

const PastEvents = ({type, pastEvents}: PastEventsProps) => {
  const header = () => {
    switch (type) {
      case 'organization':
        return 'Past Events'
      default:
        return 'Attended'
    }
  }

  return (
    <>
      <hr />
      <p className='PProfile-subsectionTitle'>{header()}</p>
      <div className='PProfile-upcomingEventsWrapper'>
        {pastEvents.map((event, i) => (
          <EventCard
            backgroundImage={event?.flyer}
            url={event?.url}
            timezone={event?.timezone}
            startUtc={event?.startUtc}
            name={event?.name}
            venueName={type === 'venue' ? event.groupName : event?.venue?.name}
            key={i}
          />
        ))}
      </div>
    </>
  )
}
export default PastEvents
