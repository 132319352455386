import {PermissionKey} from '@posh/types'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

export type HasPermissionEnabledOptions = ReactQueryOptions['permissions']['hasPermissionsEnabled']
export type HasPermissionEnabledInput = RouterInputs['permissions']['hasPermissionsEnabled']
export type HasPermissionEnabledOutput = RouterOutput['permissions']['hasPermissionsEnabled']

export function useHasPermissionEnabled(input: HasPermissionEnabledInput, opts?: HasPermissionEnabledOptions) {
  return trpc.permissions.hasPermissionsEnabled.useQuery(input, opts)
}

export function useCheckPermissions(
  requiredPermissions: PermissionKey[],
  groupId: string,
  eventId?: string,
  opts?: HasPermissionEnabledOptions,
) {
  const permissionCheckHelper = useGetCheckPermissionHelper(groupId, eventId, opts)
  return permissionCheckHelper(requiredPermissions)
}

export function useGetCheckPermissionHelper(groupId: string, eventId?: string, opts?: HasPermissionEnabledOptions) {
  const {data: permissions, isFetching, isLoading} = useHasPermissionEnabled({permissions: '*'}, opts)

  return (requiredPermissions: PermissionKey[]) => {
    if (!requiredPermissions.length) return {hasPermission: false, isFetching, isLoading}

    const hasPermission = requiredPermissions.every(p => {
      const hasAssignedEventScopedPermission = !!permissions?.[eventId ?? 'none']

      if (eventId && hasAssignedEventScopedPermission) return permissions[eventId][p]

      return !!permissions?.[groupId]?.[p]
    })
    return {hasPermission, isFetching, isLoading}
  }
}
