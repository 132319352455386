import React from 'react'

import {TEventPlaylist} from 'apis/Events/playlists/useGetEventPlaylist'

import {EventPlaylistCard} from '../EventPlaylistCard/EventPlaylistCard'

import styles from './EventPlaylistList.module.scss'

interface EventPlaylistList {
  events: TEventPlaylist['events']
  slug: string
  backgroundImagePalette: TEventPlaylist['backgroundImagePalette']
}

export const EventPlaylistList = (props: EventPlaylistList) => {
  const {events, slug, backgroundImagePalette} = props

  return (
    <div className={styles.EventPlaylistList}>
      {events.length > 0 ? (
        <>
          {events.map((event, index) => (
            <EventPlaylistCard
              key={index}
              event={event}
              playlistSlug={slug}
              backgroundImagePalette={backgroundImagePalette}
            />
          ))}
        </>
      ) : (
        <div>
          <h3>No events found</h3>
        </div>
      )}
    </div>
  )
}
