import React, {useMemo} from 'react'

import {Mark} from '@material-ui/core/Slider/Slider'
import {SecondaryFilterProps, SelectedSecondaryFilter} from '@posh/types'
import CheckboxGroup from 'components/form/CheckboxGroup/CheckboxGroup'
import {BaseSelectProps} from 'components/form/CheckboxGroup/CheckboxGroup'
import {MarkedSlider} from 'pages/EventMarketplace/SidebarFilters/MarkedSlider'

type SidebarEventFilterProps = SecondaryFilterProps & {
  onFilterSelected?: (filter: SelectedSecondaryFilter) => void
  defaultValues: SelectedSecondaryFilter['chosenValues']
}

export const SidebarEventFilter = ({
  selectionVariant,
  name,
  options,
  onFilterSelected,
  defaultValues,
}: SidebarEventFilterProps) => {
  const onSingleSelectFilterSelected = (value?: string) => {
    onFilterSelected && onFilterSelected({name, chosenValues: value ? [value] : []})
  }

  const onMultiSelectFilterSelected = (values: string[]) => {
    onFilterSelected && onFilterSelected({name, chosenValues: values})
  }

  const onSliderChanged = (newValue: [number, number]) => {
    onFilterSelected && onFilterSelected({name, chosenValues: newValue.map(v => v.toString())})
  }

  const defaultValuesForSlider = useMemo(() => {
    const hasPotentialSliderValues = selectionVariant === 'slider' && defaultValues.length >= 2
    if (hasPotentialSliderValues) {
      const defaultMinMaxSliderTuple = [parseInt(defaultValues[0]), parseInt(defaultValues[1])] as [number, number]
      return defaultMinMaxSliderTuple
    } else {
      return undefined
    }
  }, [defaultValues, selectionVariant])

  switch (selectionVariant) {
    case 'single-select':
      return (
        <CheckboxGroup
          label={name}
          options={options as BaseSelectProps['options']}
          onOptionChecked={onSingleSelectFilterSelected}
          defaultChecked={defaultValues[0]}
        />
      )
    case 'multi-select':
      return (
        <CheckboxGroup
          label={name}
          options={options as BaseSelectProps['options']}
          onOptionsChecked={onMultiSelectFilterSelected}
          defaultChecked={defaultValues}
          multiSelect
        />
      )
    case 'slider':
      return (
        <MarkedSlider
          label={name}
          marks={options as Mark[]}
          onValueChanged={onSliderChanged}
          defaultValue={defaultValuesForSlider}
        />
      )
  }
}
