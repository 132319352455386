import React from 'react'

import {TextFieldVariants} from 'components/form/TextField'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

import {CartError} from '../CartError'
import CheckoutField from '../CheckoutField/CheckoutField'

const PasswordForm = () => {
  const {isRSVP, cartFormData, formErrors} = useLiveCartContext()
  return (
    <div className={isRSVP ? 'RSVPModal-Section' : 'CheckoutPage-Section'}>
      <CheckoutField
        field_type={'input'}
        field_key={'password'}
        value={cartFormData['password']?.value}
        variant={TextFieldVariants.DARK}
        placeholder={'Password'}
        nativeProps={{type: 'password'}}
        isCustomField={false}
      />
      <CartError error={formErrors.password} />
      <CheckoutField
        field_type={'input'}
        field_key={'confirm_password'}
        value={cartFormData['confirm_password']?.value}
        variant={TextFieldVariants.DARK}
        placeholder={'Confirm Password'}
        nativeProps={{type: 'password'}}
        isCustomField={false}
      />
      <CartError error={formErrors.confirm_password} />
    </div>
  )
}

export default PasswordForm
