import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchAccountsOptions = ReactQueryOptions['poshAdmin']['poshAdminAccountsRouter']['fetchAccounts']
type FetchAccountsInput = RouterInputs['poshAdmin']['poshAdminAccountsRouter']['fetchAccounts']
export type FetchAccountsAdminOutput = RouterOutput['poshAdmin']['poshAdminAccountsRouter']['fetchAccounts']
export type FetchAccountsAdminOutputItem = FetchAccountsAdminOutput['items']['0']

export function useFetchAccounts(input: FetchAccountsInput) {
  return trpc.poshAdmin.poshAdminAccountsRouter.fetchAccounts.useInfiniteQuery(input)
}
