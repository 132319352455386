import {trpc} from 'lib/trpc'

export const useFetchAccountBadges = (accountId: string, enabled = true) => {
  return trpc.accounts.fetchAccountBadges.useQuery(
    {accountId},
    {
      enabled,
    },
  )
}
