import React, {useEffect} from 'react'

import {TextFieldVariants} from 'components/form/TextField'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

import {CartError} from '../CartError'
import CheckoutField from '../CheckoutField/CheckoutField'

interface BillingAddressProps {
  fetchingSummary: boolean
}

const BillingAddressForm = (props: BillingAddressProps) => {
  const {fetchingSummary} = props
  const {cartFormData, formErrors, setFormErrors} = useLiveCartContext()

  const isBillingCountryUS = cartFormData['billing_country']?.value === 'US'
  const isBillingCountryMissing = !cartFormData['billing_country']?.value

  const isShowingStateInput = isBillingCountryUS || isBillingCountryMissing

  useEffect(() => {
    if (formErrors.state) {
      setFormErrors({
        ...formErrors,
        state: undefined,
      })
    }
  }, [formErrors.state, isShowingStateInput, formErrors, setFormErrors])

  return fetchingSummary ? null : (
    <div className='CheckoutPage-Section fade'>
      <p className='CheckoutPage-Section-Title fade' style={{marginBottom: '0'}}>
        Billing Address Line 1
      </p>
      <CheckoutField
        field_type={'input'}
        field_key={'billing_address_line_1'}
        value={cartFormData['billing_address_line_1']?.value}
        variant={TextFieldVariants.DARK}
        placeholder={'221B, Baker Street'}
        nativeProps={{className: 'CheckoutPage-Section-BillingAddressInput'}}
        isCustomField={false}
      />
      <CartError error={formErrors.address} />
      <p className='CheckoutPage-Section-Title fade' style={{marginBottom: '0'}}>
        Line 2
      </p>
      <CheckoutField
        field_type={'input'}
        field_key={'billing_address_line_2'}
        value={cartFormData['billing_address_line_2']?.value}
        variant={TextFieldVariants.DARK}
        placeholder={'Room 237'}
        nativeProps={{className: 'CheckoutPage-Section-BillingAddressInput'}}
        isCustomField={false}
      />
    </div>
  )
}

export default BillingAddressForm
