import { definePalette } from '../../values/palette';
export const primary = definePalette({
    enabled: '#FFFFFF',
    hovered: '#EEEEEE',
    pressed: '#CCCCCC',
    focused: '#FFCC00',
    disabled: '#E5E5E5',
});
export const secondary = definePalette({
    enabled: '#FFCC00',
    hovered: '#FFD633',
    pressed: '#CCA300',
    focused: '#806600',
    disabled: '#E5E5E5',
});
