import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetEventPlaylistsForEditorOptions =
  ReactQueryOptions['events']['playlists']['manage']['getEventPlaylistsForEditor']
type GetEventPlaylistsForEditorInput = RouterInputs['events']['playlists']['manage']['getEventPlaylistsForEditor']
export type GetEventPlaylistsForEditorOutput =
  RouterOutput['events']['playlists']['manage']['getEventPlaylistsForEditor']

export function useGetEventPlaylistsForEditor(
  input: GetEventPlaylistsForEditorInput,
  opts?: GetEventPlaylistsForEditorOptions,
) {
  return trpc.events.playlists.manage.getEventPlaylistsForEditor.useQuery(input, opts)
}
