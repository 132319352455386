import {useReducer} from 'react'

import {uploadManagerStateReducer} from './reducer'
import {FileStatus, MultiFileUploadFile, MultiFileUploadManagerActionTypes} from './types'

export interface UseMultiFileUploadManager {
  addFiles: (params: {
    files: File[]
    status: FileStatus
    getFileErrorMessage: (file: File) => string
    onExceedMaxFiles: () => void
  }) => void
  clearFiles: () => void
  queueFiles: () => void
  setUploadingFile: (params: {id: string}) => void
  setUploadProgress: (params: {id: string; progress: number}) => void
  setUploadSuccess: (params: {id: string; s3Uri: string}) => void
  removeUpload: (params: {id: string}) => void
  files: MultiFileUploadFile[]
  onUploadComplete?: () => void
}
export function useMultiFileUploadManager(): UseMultiFileUploadManager {
  const [{files}, dispatch] = useReducer(uploadManagerStateReducer, {
    files: [],
  })

  const addFiles: UseMultiFileUploadManager['addFiles'] = ({files, status, getFileErrorMessage, onExceedMaxFiles}) => {
    dispatch({
      type: MultiFileUploadManagerActionTypes.ADD_FILES,
      files,
      status,
      getFileErrorMessage,
      onExceedMaxFiles,
    })
  }

  const clearFiles: UseMultiFileUploadManager['clearFiles'] = () => {
    dispatch({
      type: MultiFileUploadManagerActionTypes.CLEAR_FILES,
    })
  }

  const queueFiles: UseMultiFileUploadManager['queueFiles'] = () => {
    dispatch({
      type: MultiFileUploadManagerActionTypes.QUEUE_FILES,
    })
  }

  const setUploadingFile: UseMultiFileUploadManager['setUploadingFile'] = ({id}) => {
    dispatch({
      type: MultiFileUploadManagerActionTypes.SET_STATUS_UPLOADING,
      id,
    })
  }

  const setUploadProgress: UseMultiFileUploadManager['setUploadProgress'] = ({id, progress}) => {
    dispatch({
      type: MultiFileUploadManagerActionTypes.SET_UPLOAD_PROGRESS,
      id,
      progress,
    })
  }

  const setUploadSuccess: UseMultiFileUploadManager['setUploadSuccess'] = ({id, s3Uri}) => {
    dispatch({
      type: MultiFileUploadManagerActionTypes.SET_STATUS,
      id,
      status: FileStatus.UPLOADED,
      s3Uri: s3Uri,
    })
  }

  const removeUpload: UseMultiFileUploadManager['removeUpload'] = ({id}) => {
    dispatch({
      type: MultiFileUploadManagerActionTypes.REMOVE_UPLOAD,
      id,
    })
  }

  return {
    files,
    addFiles,
    clearFiles,
    queueFiles,
    setUploadingFile,
    setUploadProgress,
    setUploadSuccess,
    removeUpload,
  }
}
