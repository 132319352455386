import React from 'react'

import './styles.scss'

const WarningBlock = ({text}: {text: string}) => {
  return (
    <div className='WarningBlock' style={{textDecoration: 'none'}}>
      <p className='text-small'>{text}</p>
    </div>
  )
}

export default WarningBlock
