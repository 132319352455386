import React from 'react'

import {PermissionKey} from '@posh/types'
import {useCheckPermissions} from 'apis/Permissions/useHasPermissionsEnabled'

interface RequirePermissionsProps {
  requiredPermissions: PermissionKey[]
  children: (props: {isLoading: boolean}) => JSX.Element
  fallbackComponent?: JSX.Element
  groupId: string
  eventId?: string
}

export function ServerRequirePermissions({
  children,
  requiredPermissions,
  groupId,
  eventId,
  fallbackComponent,
}: RequirePermissionsProps) {
  try {
    const {hasPermission, isLoading} = useCheckPermissions(requiredPermissions, groupId, eventId, {
      staleTime: 60 * 1000,
    })

    if (!isLoading && !hasPermission) return fallbackComponent ?? null

    return <>{children({isLoading})}</>
  } catch (error) {
    throw new Error(`In RequirePermissions: ${error.message}`)
  }
}
