import React from 'react'

import {TGetGroupForFinancialsOutput, useGetGroupForFinancials} from 'apis/Groups/useGetGroupForFinancials'
import {useSwitchGroupPayoutsInterval} from 'apis/Groups/useSwitchGroupPayoutsInterval'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {generateSupportEmailHref} from 'helpers/generateSupportEmailHref'

import {FinanceCard} from '../FinanceCard'

const SettingsCard = ({
  group,
  autoPayouts,
  statementDescriptor,
}: {
  group: TGetGroupForFinancialsOutput
  autoPayouts: boolean
  statementDescriptor?: string
}) => {
  const {mutate: switchPayoutsInterval, isLoading: isSwitching} = useSwitchGroupPayoutsInterval()
  const {isFetching: isFetchingGroup} = useGetGroupForFinancials(
    {
      groupId: group._id,
    },
    {
      refetchOnMount: false,
    },
  )
  const onClickToDailyPayouts = () => {
    if (group.dailyPayoutsPreviouslyGranted) {
      return switchPayoutsInterval({groupId: group._id, interval: 'daily'})
    }

    return window.open(
      generateSupportEmailHref(
        `Request Daily Payouts for ${group.name}`,
        `Hello support team, I would like to request daily payouts for my organization!
                       \nThis is my Organization's Id: ${group._id}.
                       \nAnd this is our Organization profile page: https://posh.vip/g/${group.url}
                       \nThank you.`,
      ),
      '_blank',
    )
  }
  const isSwitchButtonLoading = isSwitching || isFetchingGroup

  return (
    <FinanceCard title={'Settings'} animationIndex={2}>
      <table className='no-padding-outside'>
        <tr>
          <td>Country</td>
          <td>
            <PoshImage
              src={`https://flagcdn.com/256x192/${group?.country?.toLowerCase()}.png`}
              className='Payouts-country'
            />
          </td>
        </tr>
        <tr>
          <td>Currency</td>
          <td>{group?.currency}</td>
        </tr>
        <tr>
          <td>Statement Descriptor</td>
          <td>{statementDescriptor}</td>
        </tr>
        <tr>
          <td colSpan={2} style={{textAlign: 'center'}}>
            Payout Schedule: {autoPayouts ? 'Daily' : 'Manual'}
          </td>
        </tr>
        {group.canInitiatePayouts && (
          <>
            {!autoPayouts && (
              <tr>
                <td colSpan={2}>
                  <Button onClick={onClickToDailyPayouts} isLoading={isSwitchButtonLoading} className='fullWidth'>
                    {group.dailyPayoutsPreviouslyGranted ? 'Switch to' : 'Request'} Daily Payouts
                  </Button>
                </td>
              </tr>
            )}
            {autoPayouts && (
              <tr>
                <td colSpan={2}>
                  <Button
                    onClick={() => switchPayoutsInterval({groupId: group._id, interval: 'manual'})}
                    isLoading={isSwitchButtonLoading}
                    className='fullWidth'>
                    Switch to Manual Payouts
                  </Button>
                </td>
              </tr>
            )}
          </>
        )}
      </table>
    </FinanceCard>
  )
}

export default SettingsCard
