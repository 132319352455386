import React, {useMemo, useState} from 'react'

import {CurrencyCode} from '@posh/types'
import {EventTicketAndTicketSold, TicketEventPage, TicketLink} from 'apis/Events/types'

import {TicketLinkModal} from './TicketLinkModal'
import {TicketRow} from './ticketRow'

interface TicketListProps {
  tickets: EventTicketAndTicketSold[]
  currency?: CurrencyCode
  isEditingDisabled?: boolean
  timezone?: string
}

export interface ExpandedTicketLink extends TicketLink {
  fromTicketId: string
}

const TicketList = (props: TicketListProps) => {
  const {tickets, currency, isEditingDisabled, timezone} = props

  const [currentlyEditing, setCurrentlyEditing] = useState<string | undefined>()

  const getParentTicket = (ticketId: string) => {
    return tickets.find(t => t.ticket.ticketLink?.nextTicket === ticketId)?.ticket
  }

  const getTicket = (ticketId: string) => {
    return tickets.find(t => t.ticket.id === ticketId)?.ticket
  }

  const showTicketModal = !!currentlyEditing && getTicket(currentlyEditing)

  const filteredTickets = useMemo(() => {
    return tickets.reduce((prevList, {ticket}) => {
      const ticketLink = ticket.ticketLink
      if ((!ticketLink || ticketLink.nextTicket === currentlyEditing) && ticket.id !== currentlyEditing) {
        prevList.push(ticket)
      }
      return prevList
    }, [] as TicketEventPage[])
  }, [tickets, currentlyEditing])

  return (
    <>
      {tickets?.map((t, index) => (
        <TicketRow
          key={t.ticket.id}
          ticket={t}
          currency={currency}
          index={index}
          isEditingDisabled={isEditingDisabled}
          timezone={timezone}
          linkedToText={getParentTicket(t.ticket.id)?.name}
          setCurrentlyEditing={setCurrentlyEditing}
        />
      ))}
      {showTicketModal && (
        <TicketLinkModal
          isOpen={!!currentlyEditing}
          parent={getParentTicket(currentlyEditing)!}
          child={getTicket(currentlyEditing)!}
          filteredTickets={filteredTickets}
          onClose={() => {
            setCurrentlyEditing(undefined)
          }}
          width={780}
        />
      )}
    </>
  )
}

export default TicketList
