import React, {useState} from 'react'

import ConfirmationModal from '../../../../modals/Modal/ConfirmationModal'
import {EventVisualsIconButton} from '../../Button/IconButton'

import styles from './styles.module.scss'

interface HeaderButtonProps {
  onPress: () => void
  disabled: boolean
}

interface DeleteButtonProps extends HeaderButtonProps {
  confirmationModalTitle: string
  confirmationModalSubtitle?: string
  confirmationButtonText: string
  cancelButtonText: string
  deleteWithoutModalOnSectionEmpty?: boolean
  isSectionEmpty?: boolean
}

interface HeaderButtonsProps {
  addButtonProps?: HeaderButtonProps
  customAddButton?: React.ReactNode
  deleteButtonProps?: DeleteButtonProps
}

export const EventVisualsHeaderButtons = (props: HeaderButtonsProps) => {
  const {addButtonProps, customAddButton, deleteButtonProps} = props
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const handleDelete = () => {
    if (!deleteButtonProps) return
    if (deleteButtonProps.deleteWithoutModalOnSectionEmpty && deleteButtonProps.isSectionEmpty)
      return deleteButtonProps.onPress()
    setIsDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false)
  }

  return (
    <>
      <div className={styles.HeaderButtonContainer}>
        {addButtonProps && (
          <EventVisualsIconButton onPress={addButtonProps.onPress} iconType='plus' disabled={addButtonProps.disabled} />
        )}
        {customAddButton}
        {deleteButtonProps && <EventVisualsIconButton onPress={handleDelete} iconType='trash' />}
      </div>
      {deleteButtonProps && isDeleteModalOpen && (
        <ConfirmationModal
          noborder
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onClick={deleteButtonProps.onPress}
          successMessage={false}
          title={deleteButtonProps.confirmationModalTitle}
          bodyText={deleteButtonProps.confirmationModalSubtitle}
          confirmButtonText={deleteButtonProps.confirmationButtonText}
          confirmationMessage=''
        />
      )}
    </>
  )
}
