import React from 'react'

import useFetchKPIs from 'apis/AdminDashboard/useFetchKPIs'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {PoshLogo} from 'components/PoshLogo'
import moment from 'moment'

import './styles.scss'

const KPIDashboard = () => {
  const {data: data, isFetching: isLoading} = useFetchKPIs()

  if (isLoading && !data) return <SpinLoader height={40} />

  return (
    <div className='KPIDash'>
      <div className='KPIDash-header'>
        <PoshLogo />
        <SpinLoader height={50} />
      </div>

      <div className='KPIDash-kpis'>
        <div className='KPIDash-kpis-kpi'>
          {moment().format('MMMM')} GMV: ${data?.gmvThisMonth.toLocaleString(undefined, {minimumFractionDigits: 2})}
        </div>
        <div className='KPIDash-kpis-kpi'>
          GMV Today: ${data?.gmvToday.toLocaleString(undefined, {minimumFractionDigits: 2})}
        </div>
      </div>

      <div className='KPIDash-orderContainer'>
        {data?.recentOrders.map((item, i) => (
          <div className='KPIDash-order' key={i}>
            <PoshImage src={item.avi} alt='avi' />
            <div>
              {item.name} made a ${item.total.toFixed(2)} order for {item.eventName}.
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default KPIDashboard
