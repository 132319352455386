import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export async function verifyPhoneNumber(): Promise<boolean> {
  const response = await PoshApi.verifyPhoneNumber()
  return response.data.success
}

export function useVerifyPhoneNumber() {
  return useMutation(() => verifyPhoneNumber())
}
