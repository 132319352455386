import React from 'react'

import useAccountExistsByEmail from 'apis/Accounts/useAccountExistsByEmail'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {emailCheckoutFormSchema} from 'domains/LiveCart/LiveCartContext'
import useGetCurrentPathWithParams from 'helpers/useGetCurrentPathWithParams'

import {CreateEventFormDataModalStepProps} from '../util/createEventFormData'

export const CreateEventEmailEntryForm = (props: CreateEventFormDataModalStepProps) => {
  const {
    createEventFormData: {
      hasAccount,
      userData: {email},
    },
    setCreateEventFormData,
  } = props
  const {showToast} = useToast()
  const {userId} = useSessionContext()
  const {refetch: validateAccountEmail, data, isFetching: isValidatingEmail} = useAccountExistsByEmail(email)
  const {exists: emailExists} = data ?? {}
  const {encodedPath} = useGetCurrentPathWithParams()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const handleEmailCheckClick = async () => {
    try {
      const validEmail = emailCheckoutFormSchema.validateSyncAt('email', {email}, {abortEarly: false})
      const {exists: emailExists, isUnconfirmed, accountId} = (await validateAccountEmail()).data!
      if (isUnconfirmed && validEmail && emailExists && accountId) {
        window.location.href = `/confirm-account/${accountId}?r=${encodedPath}`
      } else if (validEmail && emailExists === true) {
        setCreateEventFormData(u => ({
          ...u,
          hasAccount: true,
          loginStep: {type: 'loginWithEmail', previous: u.loginStep},
        }))
      } else if (validEmail && emailExists === false) {
        setCreateEventFormData(u => ({
          ...u,
          hasAccount: true,
          loginStep: {type: 'createAccount', previous: u.loginStep},
        }))
      }
    } catch (error: any) {
      const validationError = error.inner[0]
      const {message} = validationError
      showToast({type: 'error', title: message})
    }
  }

  const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      if (!emailExists || userId || !hasAccount) {
        handleEmailCheckClick()
      }
    }
  }

  return (
    <div className='CreateEventLogin'>
      <h4>Enter your email to launch your event!</h4>
      <Input
        value={email}
        onChange={e => setCreateEventFormData(u => ({...u, userData: {...u.userData, email: e.target.value}}))}
        type={'customer'}
        className='fullWidth'
        required={false}
        placeholder={'Email'}
        onKeyDown={e => onKeyDown(e)}
        autoComplete='email'
      />
      <Button
        className='createEventGrad'
        disabled={isValidatingEmail || email?.length === 0}
        onClick={() => {
          trackMixpanelEvent('Create Event Enter Email Continue-  Enter Your Email Modal')
          handleEmailCheckClick()
        }}>
        Continue
      </Button>
    </div>
  )
}
