import {RefundRequestForm} from '@posh/model-types'
import {UseQueryResult} from '@tanstack/react-query'
import {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'

export enum RefundReason {
  VenueIssues = 'Venue Issues',
  WeatherIssues = 'Weather Issues',
  ArtistCancellation = 'Artist Cancellation',
  Other = 'Other',
}

export type MassRefundRequest = {
  reason: RefundReason | ''
} & RefundRequestForm

export interface RefundRequestModalProps extends PoshStyledModalProps {
  triggerCheckRefetch: () => Promise<UseQueryResult>
  isCheckFetching: boolean
}
