import React from 'react'

import styles from './PayoutsBanner.module.scss'

const getBannerCta = ({
  canInitiatePayouts,
  canAcceptPayments,
  needsStripeOnboarding,
}: {
  canInitiatePayouts: boolean
  canAcceptPayments: boolean
  needsStripeOnboarding: boolean
}) => {
  if (!canInitiatePayouts && !canAcceptPayments)
    return 'You cannot sell tickets or initiate payouts at the moment, click here for more information.'
  if (!canAcceptPayments) return 'You cannot sell tickets at the moment, click here for more information.'
  if (!canInitiatePayouts) return 'You cannot initiate payouts at the moment, click here for more information.'
  // If functionality is active but Stripe is asking for onboarding soon
  if (needsStripeOnboarding)
    return 'Your ability to sell tickets or initiate payouts may be disrupted in the near future, click here for more information.'

  return null
}

interface GetGroupPayoutsBannerProps {
  canInitiatePayouts: boolean
  canAcceptPayments: boolean
  needsStripeOnboarding: boolean
  financeTabUrl: string
}

export const getGroupPayoutsBanner = (props: GetGroupPayoutsBannerProps) => {
  const {canInitiatePayouts, canAcceptPayments, financeTabUrl, needsStripeOnboarding} = props

  const cta = getBannerCta({
    canInitiatePayouts,
    canAcceptPayments,
    needsStripeOnboarding,
  })

  if (!cta) return null

  return (
    <a href={financeTabUrl} style={{textDecoration: 'none'}}>
      <div className={styles.PayoutsBanner}>{cta}</div>
    </a>
  )
}
