import React from 'react'

import {MailChimpList} from 'apis/MailChimp/useFetchMailChimpLists'
import Button from 'components/form/Button'
import {useDimensions} from 'hooks/useDimensions'

import {MailChimpLoader} from './mailChimpLoader'
import {useExportAttendeeBatchesToMailchimp} from './useExportAttendeeBatchesToMailchimp'

interface ListSelectorProps {
  lists: MailChimpList[]
  createNewList: () => void
}

interface ListItemProps {
  list: MailChimpList
  onClick: (id: string) => void
}

const ListItem = (props: ListItemProps) => {
  const {list, onClick} = props
  const {isMobile} = useDimensions()
  const {id, name, contacts, dateCreated} = list
  return (
    <tr>
      <td rowSpan={1}>{name}</td>
      {!isMobile && <td rowSpan={1}>{contacts} contacts</td>}
      {!isMobile && <td rowSpan={1}>Created {dateCreated}</td>}
      <td rowSpan={1}>
        <Button onClick={() => onClick(id)} className='dark'>
          Select
        </Button>
      </td>
    </tr>
  )
}

export const ListSelector = (props: ListSelectorProps) => {
  const {lists, createNewList} = props
  const {isAddingToMailChimpList, completedData, handleExportToList} = useExportAttendeeBatchesToMailchimp()
  return (
    <>
      {isAddingToMailChimpList || completedData ? (
        <MailChimpLoader
          isCreatingNewList={false}
          isAddingToMailChimpList={isAddingToMailChimpList}
          completedData={completedData}
        />
      ) : (
        <>
          <h3 className='center noMargin'>Send Attendees to Mailchimp</h3>
          <p className='center'>Select a list to add the selected attendees to, or create a new one.</p>
          <Button disabled={isAddingToMailChimpList} className='dark' onClick={createNewList}>
            + Create New List
          </Button>
          <table className='MailChimpListItems'>
            <tbody>
              {lists.map(list => (
                <ListItem key={list.id} list={list} onClick={id => handleExportToList(id)} />
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  )
}
