import React from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import {instagramUrl, linkedinUrl, twitterUrl} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'
import SocialButton from 'components/SocialButton/SocialButton'

import '../styles.scss'

interface ProfileSocialsProps {
  instagram?: string
  twitter?: string
  linkedIn?: string
  likedByViewer?: boolean
  onClickLikeButton?: () => void
  hideLikeButton: boolean
  numberOfBadges?: number
  location?: 'profile preview modal' | 'profile page'
}

const ProfileSocials = ({
  instagram,
  twitter,
  linkedIn,
  likedByViewer,
  onClickLikeButton,
  hideLikeButton,
  numberOfBadges,
  location,
}: ProfileSocialsProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const handleClick = (provider: string) => {
    if (provider !== 'instagram') return
    if (location === 'profile preview modal') {
      trackMixpanelEvent('Instagram Profile Click- Attendee Guestlist Modal', {numberOfBadges: numberOfBadges ?? 0})
    } else {
      trackMixpanelEvent('Instagram Profile Click- Attendee/Curator Profile Page', {
        numberOfBadges: numberOfBadges ?? 0,
      })
    }
  }

  return (
    <div className='PProfile-socials'>
      {instagram && (
        <a
          href={`https://instagram.com/${instagram}`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleClick('instagram')}>
          <PoshImage src={instagramUrl} />
        </a>
      )}
      {twitter && (
        <a
          href={`https://twitter.com/${twitter}`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleClick('twitter')}>
          <PoshImage src={twitterUrl} />
        </a>
      )}
      {linkedIn && (
        <a href={linkedIn} target='_blank' rel='noreferrer' onClick={() => handleClick('linkedin')}>
          <PoshImage src={linkedinUrl} />
        </a>
      )}
      {!hideLikeButton && <SocialButton size='large' filled={likedByViewer} onClick={onClickLikeButton} />}
    </div>
  )
}

export default ProfileSocials
