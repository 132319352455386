import React, {useMemo, useState} from 'react'

import {CreateEventPinResponse, useCreateEventPin} from 'apis/Events/useCreateEventPin'
import {useDeleteEventPin} from 'apis/Events/useDeleteEventPin'
import {EventPin, useFetchEventPins} from 'apis/Events/useFetchEventPins'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'
import {useToast} from 'components/toasts/ToastProvider'

import {TicketScannerPinModal} from './TicketScannerPinModal'

interface TicketScannersSectionProps {
  eventId?: string
}

export const TicketScannersSection = (props: TicketScannersSectionProps) => {
  const {eventId} = props
  const {showToast} = useToast()
  const {data: eventPins, dataUpdatedAt, refetch} = useFetchEventPins({eventId: eventId!})
  const {mutateAsync: deleteEventPin} = useDeleteEventPin({
    onSuccess: () => {
      refetch()
      setPinToDelete(undefined)
      showToast({title: 'Ticket Scanner Pin Deleted', type: 'success'})
    },
    onError: error => {
      showToast({title: error.message, type: 'error'})
    },
  })
  const [pin, setPin] = useState<CreateEventPinResponse | undefined>(undefined)
  const [pinToDelete, setPinToDelete] = useState<string | undefined>(undefined)

  const {mutateAsync: createEventPin} = useCreateEventPin({
    onSuccess: pin => {
      refetch()
      setPin(pin)
    },
    onError: error => {
      showToast({type: 'error', title: error.message})
    },
  })

  const columnConfigs = useMemo<ColumnConfig<EventPin>[]>(() => {
    return [
      {
        key: 'pin',
        header: 'Pin',
        render: pin => <TableCell.Text text={pin} bold />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        key: 'sentTo',
        header: 'Sent To',
        render: (sentTo: string[]) => <TableCell.Text text={sentTo.join(', ')} />,
      },
    ]
  }, [eventPins])

  return (
    <>
      <div className='eventSection'>
        <CRUDTable
          data={eventPins ?? []}
          lastUpdated={dataUpdatedAt}
          refresh={refetch}
          columns={columnConfigs}
          actionButtons={[
            {
              icon: 'trash',
              onClick: pin => setPinToDelete(pin._id),
            },
            {
              icon: 'info',
              onClick: pin => setPin({pin: pin.pin, id: pin._id}),
            },
          ]}
          createButton={{
            title: '+ Add Ticket Scanner Pin',
            onClick: () => createEventPin({eventId: eventId!}),
          }}
          onClickRow={pin => setPin({pin: pin.pin, id: pin._id})}
          itemsPerPage={5}
          resourceName='Ticket Scanner Pin'
          searchableColumn='pin'
        />
      </div>
      {pin && eventId && (
        <TicketScannerPinModal eventId={eventId} pinResponse={pin} onClose={() => setPin(undefined)} isOpen={!!pin} />
      )}
      {pinToDelete && eventId && (
        <ConfirmationModal
          isOpen={!!pinToDelete}
          title='Are you sure you want to delete this ticket scanner pin?'
          bodyText='This action cannot be undone.'
          onClick={() => deleteEventPin({eventId, pinId: pinToDelete})}
          confirmButtonText='Delete Pin'
          confirmationMessage=''
          onClose={() => setPinToDelete(undefined)}
        />
      )}
    </>
  )
}
