import {useNavigate, useParams} from 'react-router-dom'

import {CreateEventEntryPointOptions, EOptimizelyEvents} from '@posh/types'
import {useIsFeatureEnabled} from 'components/FeatureFlag'

import {trackOptimizelyEvent} from '../../apis/OptimizelyHandler'

type NavigateToCreateEvent =
  | {
      useSearchParams?: false
      groupId?: string
      pendingEventId?: string
      optimizelyEntryPoint?: CreateEventEntryPointOptions
    }
  | {
      useSearchParams: true
      optimizelyEntryPoint?: CreateEventEntryPointOptions
    }

export const trackCreateEventEntryPoint = (entryPoint: CreateEventEntryPointOptions) => {
  trackOptimizelyEvent(EOptimizelyEvents.CREATE_EVENT_ENTRY_POINT, {CreateEventEntryPoint: entryPoint})
}

export const useNavigateToCreateEvent = () => {
  const navigate = useNavigate()
  const canGoToNewCreateEvent = useIsFeatureEnabled('create_event_updated_editor')
  const createEventUrl = '/create'

  const urlParams = useParams()

  const navigateToCreateEvent = (params?: NavigateToCreateEvent) => {
    const {optimizelyEntryPoint} = params || {}

    let groupId: string | undefined
    let pendingEventId: string | undefined
    if (params?.useSearchParams) {
      groupId = urlParams.groupId
    } else {
      groupId = params?.groupId
      pendingEventId = params?.pendingEventId
    }

    const unfilteredQueryParams = {
      g: groupId,
      p: canGoToNewCreateEvent ? pendingEventId : undefined,
    }
    const queryParams = JSON.parse(JSON.stringify(unfilteredQueryParams))
    const searchParams = `?${new URLSearchParams(queryParams).toString()}`
    // Attach the entry point to the user's Optimizely attributes for the Create Event predefined attributes test
    if (optimizelyEntryPoint) trackCreateEventEntryPoint(optimizelyEntryPoint)
    return navigate({pathname: createEventUrl, search: searchParams})
  }
  return {navigateToCreateEvent, createEventUrl}
}
