import React from 'react'
import {useNavigate} from 'react-router-dom'

import {PoshImage} from 'components/PoshImage/PoshImage'
import {useGroupBaseUrl} from 'domains/Groups/helpers'
import {useGroupSettingsPageParams} from 'pages/PoshAppLayout'

import SettingsMap, {SettingsPage} from './map'

import './styles.scss'

const Settings = () => {
  const baseUrl = useGroupBaseUrl()
  const navigate = useNavigate()

  const changePage = (page: SettingsPage) => {
    navigate(`${baseUrl}/settings/${page.route}`)
  }
  const {category: currentCategory} = useGroupSettingsPageParams()

  const currentPage =
    SettingsMap.map(category => category.pages)
      .flat()
      .find(p => p.route === currentCategory) ?? SettingsMap[0].pages[0]

  return (
    <div className='NewGroupPageCont'>
      <div className='NewGroupPageCont-titleCont'>
        <div>
          <h2>Settings</h2>
        </div>
      </div>
      <div className='NewGroupPageCont-panes'>
        <div>
          <div className='NewGroupPageCont-pane NewGroupPageCont-menu'>
            {SettingsMap.map(category => (
              <div key={category.category} className='NewGroupPageCont-menu-category'>
                <h4>{category.category}</h4>
                {category.pages.map(page => (
                  <div
                    key={page.title}
                    onClick={() => changePage(page)}
                    className={`NewGroupPageCont-menu-page ${currentCategory === page.route ? 'selected' : ''}`}>
                    <PoshImage src={page.icon} />
                    <span>{page.title}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className='NewGroupPageCont-stpContent NewGroupPageCont-pane grow'>
            <h3>{currentPage.title}</h3>
            {currentPage.content}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
