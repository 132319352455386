import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface UpdateKickBackOfferLimitProps {
  id: string
  limitedTo?: string[]
}

const updateKickBackOfferLimit = async (params: UpdateKickBackOfferLimitProps) => {
  const response = await PoshApi.updateKickBackOfferLimit(params)
  return response.data
}

export function useUpdateKickBackOfferLimit() {
  return useMutation<unknown, unknown, UpdateKickBackOfferLimitProps>(params => updateKickBackOfferLimit(params))
}
