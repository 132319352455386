import React, {ComponentType, useRef} from 'react'

import classNames from 'classnames'
import {useUploadImage, UseUploadImageParams} from 'hooks/uploadImage/useUploadImage'
import {useUploadMultipleImages, UseUploadMultipleImagesParams} from 'hooks/uploadImage/useUploadMultipleImages'

import {FileDropzone} from './FileDropzone'

import styles from './styles.module.scss'

type FileInputProps = Omit<React.HTMLProps<HTMLInputElement>, 'type' | 'style' | 'ref'> & {
  onChooseFile?: (file: File | File[]) => void
}

/**
 * Wraps an input[type='file'] element and allows for custom styling and children
 */
export function FileInput({onChooseFile, ...props}: FileInputProps) {
  const {children, onChange, multiple = false, className, ...rest} = props
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    fileInputRef.current!.value = ''
    fileInputRef.current?.click()
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && onChooseFile) {
      onChooseFile(multiple ? Array.from(files) : files[0])
    }
    onChange?.(e)
  }

  return (
    <div onClick={handleClick} className={classNames(styles.FileInput, className)}>
      <label htmlFor='file'>{children}</label>
      <input type='file' name='file' ref={fileInputRef} onChange={handleFileChange} multiple={multiple} {...rest} />
    </div>
  )
}

/** Variants limited to specific MIME Types */

type MimeTypeSpecificInputProps = Omit<FileInputProps, 'accept'>

function makeAcceptMimeType(Component: ComponentType<FileInputProps>, mimeType: string) {
  const FileInput = (props: MimeTypeSpecificInputProps) => <Component {...props} accept={mimeType} />
  return FileInput
}

FileInput.Image = makeAcceptMimeType(FileInput, 'image/*')
FileInput.Video = makeAcceptMimeType(FileInput, 'video/*')

/** Variants for handling uploading images to S3 */

/**
 * Uploads multiple images to S3. Accepts one or more files.
 * **Do not use this component directly. Use `FileInput.WithUploadImage` instead.**
 */
function WithUploadMultipleImages(
  props: UseUploadMultipleImagesParams & Omit<FileInputProps, 'onChooseFile' | 'multiple'>,
) {
  const {uploadMultipleImages} = useUploadMultipleImages(props)

  const onChooseFile = (file: File | File[]) => {
    if (Array.isArray(file)) uploadMultipleImages(file)
    else uploadMultipleImages([file])
  }

  return <FileInput.Image onChooseFile={onChooseFile} {...props} multiple />
}

/**
 * Uploads a single image to S3. Accepts one file. If multiple files are passed (which they should not be), only the first is used.
 * **Do not use this component directly. Use `FileInput.WithUploadImage` instead.**
 */
function WithUploadSingleImage(props: UseUploadImageParams & Omit<FileInputProps, 'onChooseFile' | 'multiple'>) {
  const {uploadImage} = useUploadImage(props)

  const onChooseFile = (file: File | File[]) => {
    if (Array.isArray(file)) uploadImage(file[0])
    else uploadImage(file)
  }

  return <FileInput.Image onChooseFile={onChooseFile} {...props} multiple={false} />
}

type WithUploadImageProps = Omit<FileInputProps, 'onChooseFile' | 'multiple'> &
  (({multiple: true} & UseUploadMultipleImagesParams) | ({multiple?: false} & UseUploadImageParams))

function WithUploadImage(props: WithUploadImageProps) {
  if (props.multiple) return <WithUploadMultipleImages {...props} />
  return <WithUploadSingleImage {...props} />
}

FileInput.WithUploadImage = WithUploadImage

// Dropzone
FileInput.Dropzone = FileDropzone
