import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type ListReleasesOptions = ReactQueryOptions['releases']['list']
type ListReleasesInput = RouterInputs['releases']['list']
export type ListReleasesOutput = RouterOutput['releases']['list']
export type ReleaseListItem = ListReleasesOutput['releases'][0]

export function useListReleases(input: ListReleasesInput = {}, options?: ListReleasesOptions) {
  return trpc.releases.list.useQuery(input, options)
}
