export interface IOrderFee {
  name: string
  type: string
  amount: number
  applyTo: string
  totalFee: number
}

export enum PaymentStatus {
  SCHEDULED = 'scheduled',
  REQUIRES_ACTION = 'requires_action',
  FAILED = 'failed',
  PAID = 'paid',
  REFUNDED = 'refunded',
  CANCELED = 'canceled',
}

export interface IOrderPaymentPlan {
  amount: number
  status: PaymentStatus
  date: Date
}

export interface CheckTicketAvailablityParams {
  cartId: string
}
