import React, {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {useFetchUnconfirmedAccount} from 'apis/Accounts/useFetchUnconfirmedAccount'
import Button from 'components/form/Button'
import useSessionContext from 'domains/Auth/SessionContext'

import {ConfirmAccountEditor} from './ConfirmAccountEditor'
import {InvalidateAccountPrompt} from './InvalidAccountPrompt'

import './styles.scss'

export const ConfirmAccount = () => {
  const {accountId} = useParams()
  const {
    data: unconfirmedAccount,
    error,
    isLoading,
  } = useFetchUnconfirmedAccount({accountId: accountId!}, {enabled: Boolean(accountId), retry: 1})
  const {userId} = useSessionContext()
  const navigate = useNavigate()
  const redirectText = userId ? 'Go to Dashboard' : 'Go Home'
  const invalidateAccountQueryParameter = new URLSearchParams(window.location.search).get('invalidate')
  const [shouldShowInvalidatePrompt, setShouldShowInvalidatePrompt] = useState(
    invalidateAccountQueryParameter === 'true',
  )

  return (
    <div className='ConfirmAccount'>
      {isLoading && <p>Loading...</p>}
      {error && (
        <div className='buttonWrapper column center'>
          <p className='error'>
            {error.message === 'Account has already been confirmed'
              ? 'This account has already been confirmed! Press the button below to sign in.'
              : 'There was an issue fetching this account.'}
          </p>
          <Button onClick={() => navigate('/dashboard/account')}>{redirectText}</Button>
        </div>
      )}
      {unconfirmedAccount && !shouldShowInvalidatePrompt && (
        <ConfirmAccountEditor
          unconfirmedAccount={unconfirmedAccount}
          invalidateAccount={() => setShouldShowInvalidatePrompt(true)}
        />
      )}
      {unconfirmedAccount && shouldShowInvalidatePrompt && (
        <InvalidateAccountPrompt
          unconfirmedAccountId={unconfirmedAccount.accountId}
          validateAccount={() => setShouldShowInvalidatePrompt(false)}
        />
      )}
    </div>
  )
}
