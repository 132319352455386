import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CommunityCategory} from '.'

export interface UpdateCommunityClicksParams {
  id: string
  category: CommunityCategory
}

async function updateCommunityClicks(params: UpdateCommunityClicksParams) {
  const response = await PoshApi.updateCommunityClicks(params)
  return response.data
}

export default function useUpdateCommunityClicks() {
  return useMutation((params: UpdateCommunityClicksParams) => updateCommunityClicks(params))
}
