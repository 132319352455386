import React, {useEffect, useState} from 'react'
import Loader from 'react-loader-spinner'

import {FetchCommentRepliesOutput, useFetchCommentReplies} from 'apis/comments/useFetchCommentReplies'
import {EventParentComment} from 'apis/comments/useFetchEventParentComments'
import {ProfilePreviewModalState} from 'pages/EventPage'

import CommentComponent from '../CommentComponent'

interface CommentReplyProps {
  comment: EventParentComment
  accentColor: string
  isLightMode: boolean
  eventId: string
  groupId?: string
  setExpandedComment: React.Dispatch<React.SetStateAction<string | null>>
  expandedComment: string | null
  shouldShowEmojiPicker?: string
  setShouldShowEmojiPicker?: React.Dispatch<React.SetStateAction<string>>
  setProfilePreviewModal?: React.Dispatch<React.SetStateAction<ProfilePreviewModalState>>
}

export const CommentReplies = (props: CommentReplyProps) => {
  const {
    comment,
    accentColor,
    isLightMode,
    eventId,
    groupId,
    setExpandedComment,
    expandedComment,
    shouldShowEmojiPicker,
    setShouldShowEmojiPicker,
    setProfilePreviewModal,
  } = props
  const [replies, setReplies] = useState<FetchCommentRepliesOutput>()
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {mutateAsync: fetchCommentReplies} = useFetchCommentReplies()
  const refetchRepliesRequest = async () => {
    if (expandedComment) {
      const replies = await fetchCommentReplies({eventId, parentCommentId: expandedComment})
      setReplies(replies)
    }
  }
  const fetchRepliesRequest = async () => {
    if (expandedComment) {
      setIsLoading(true)
      const replies = await fetchCommentReplies({eventId, parentCommentId: expandedComment})
      setReplies(replies)
      setIsLoading(false)
    } else {
      setReplies(undefined)
    }
  }
  useEffect(() => {
    setErrorMessage('')
    fetchRepliesRequest().catch((err: any) => {
      const errMessage = err.response.data.error as string
      setErrorMessage(errMessage ?? 'Error fetching replies')
      setIsLoading(false)
    })
  }, [expandedComment])
  return (
    <>
      <div
        className='activityComment-text-viewReplies'
        style={{color: accentColor}}
        onClick={() => (expandedComment ? setExpandedComment(null) : setExpandedComment(comment._id))}>
        {isLoading ? (
          <div className='activityComment-text-viewReplies-loading'>
            <Loader type='TailSpin' color={accentColor} height={10} width={10} />
            <div style={{color: accentColor}}>Loading</div>
          </div>
        ) : (
          <>
            <svg
              fill={accentColor}
              x='0px'
              y='0px'
              width='123.959px'
              height='123.958px'
              viewBox='0 0 123.959 123.958'
              style={{transform: expandedComment ? 'rotate(180deg)' : 'none'}}>
              <g>
                <path d='M117.979,28.017h-112c-5.3,0-8,6.4-4.2,10.2l56,56c2.3,2.3,6.1,2.3,8.401,0l56-56 C125.979,34.417,123.279,28.017,117.979,28.017z' />
              </g>
            </svg>
            {expandedComment ? 'Hide Replies' : 'View Replies'}
          </>
        )}
      </div>
      {errorMessage && <div className='activityComment-text-error'>{errorMessage}</div>}
      {replies && !isLoading && (
        <div className='activityComment-text-replies'>
          {replies.map(reply => (
            <CommentComponent
              key={reply._id}
              comment={reply}
              accentColor={accentColor}
              isLightMode={isLightMode}
              eventId={eventId}
              groupId={groupId}
              isReply={true}
              parentCommentId={comment._id}
              replyToCommentId={reply._id}
              refetch={refetchRepliesRequest}
              shouldShowEmojiPicker={shouldShowEmojiPicker}
              setShouldShowEmojiPicker={setShouldShowEmojiPicker}
              setProfilePreviewModal={setProfilePreviewModal}
            />
          ))}
        </div>
      )}
    </>
  )
}
