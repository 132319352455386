import {RouterOutput, trpc} from 'lib/trpc'

export type OrderAndEventsResponse = RouterOutput['accounts']['getAllAttendeeOrders']
export type OrderAndEvent = OrderAndEventsResponse[0]
export type EventResponse = OrderAndEvent['event']
export type OrderResponse = OrderAndEvent['order']

export function useFetchAllAttendeeOrdersAndEvents() {
  return trpc.accounts.getAllAttendeeOrders.useQuery(undefined)
}
