import React from 'react'

import {EventTicketAndTicketSold} from 'apis/Events/types'
import {KickbackType} from 'apis/Kickback/KickbackOffer'
import {KickbackOffer, KickbackOfferResponse} from 'apis/Kickback/useFetchKickBackOffers'

import './styles.scss'

interface ExistingKickbackCardProps {
  kickbackOfferResponse: KickbackOfferResponse
  tickets: EventTicketAndTicketSold[]
  setEditKickback: React.Dispatch<React.SetStateAction<KickbackOffer | undefined>>
  setOfferToLimit: React.Dispatch<React.SetStateAction<KickbackOffer | undefined>>
}

const ExistingKickbackCard = (props: ExistingKickbackCardProps) => {
  const {kickbackOfferResponse, tickets, setEditKickback, setOfferToLimit} = props
  const kickbackOffer = kickbackOfferResponse
  const totalRevenueGenerated = kickbackOfferResponse.totalRevenueGenerated
  const toolTipText =
    kickbackOffer.limitedTo && kickbackOffer.limitedTo.length > 0
      ? 'Limited to ' +
        kickbackOffer.limitedTo.length +
        ` ${kickbackOffer.limitedTo.length > 1 ? 'affiliates' : 'affiliate'}`
      : 'Can be used by all affiliates'

  const getValue = (type: KickbackType, value: number) => {
    return type === 'percentage' ? `${value}%` : `$${value.toFixed(2)}`
  }

  const getAppliesTo = (kickback: KickbackOffer) => {
    if (kickback.equation === 'perOrder') return 'Per Order'
    else if (kickback.appliesTo && kickback.equation === 'perTicket')
      return `Per ${tickets.find(t => t.ticket.id.toString() === kickback.appliesTo?.toString())?.ticket.name} ticket`
    else return 'Invalid Kickback'
  }

  const isAffiliateKickbackOffer = kickbackOfferResponse.limitedTo?.length ?? 0 > 0

  return (
    <div
      className={`ExistingKickbackCard ${!kickbackOffer.enabled ? 'disabled' : ''}`}
      onClick={() => setEditKickback(kickbackOffer)}>
      <h3 className='noMargin'>{`${getValue(kickbackOffer.type, kickbackOffer.value)}`}</h3>
      <p className='noMargin'>{getAppliesTo(kickbackOffer)}</p>
      <p className='noMargin'>Total Revenue: ${totalRevenueGenerated}</p>
      <div className='ExistingKickbackCard-edit'>
        {!!isAffiliateKickbackOffer && (
          <a
            className='ExistingKickbackCard-add-affiliate'
            onClick={e => {
              e.stopPropagation()
              setOfferToLimit(kickbackOffer)
            }}>
            Edit Affiliates
          </a>
        )}
      </div>
    </div>
  )
}

export default ExistingKickbackCard
