import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CampaignLink} from '.'

async function fetchAdminCampaignLinks(): Promise<CampaignLink[]> {
  const response = await PoshApi.fetchAdminCampaignLinks()
  return response.data as CampaignLink[]
}

export default function useFetcAdminCampaignLinks() {
  return useQuery<CampaignLink[]>(['admin-campaign-links'], () => fetchAdminCampaignLinks(), {
    refetchOnMount: false,
  })
}
