import React, {useState} from 'react'

import {CheckoutFieldNameAndId} from 'apis/Events/types'
import {useFetchAllEventAttendees} from 'apis/Events/useFetchAllEventAttendees'
import useFetchEvent from 'apis/Events/useFetchEvent'
import {useFetchAllGroupAttendees} from 'apis/Groups/useFetchAllGroupAttendees'
import {useMixpanel} from 'apis/MixPanelHandler'
import {RoleKey} from 'apis/Roles/Role'
import PaginationButtons from 'components/form/PaginationButtons'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useDimensions} from 'hooks/useDimensions'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {AttendeeRowAttributes} from '../../../../apis/Groups'
import {useFetchGroup} from '../../../../apis/Groups/useFetchGroup'
import {AttendeeCheckBox} from '../AttendeeCheckBox'
import AttendeeRow from '../AttendeeRow'
import useAttendeesCrmContext from '../AttendeesCrmContext'
import BlastBottomRow from '../BlastBottomRow'
import {ExpandedCheckoutFieldSelector} from '../ExpandedCheckoutFieldSelector'
import {MailChimpExportModal} from '../MailChimpExportModal'

import './styles.scss'

interface AttendeesTableProps {
  attendeeRows: AttendeeRowAttributes[]
  isHost?: boolean
  eventHasCustomCheckoutFields?: boolean
  checkoutFieldNamesAndIds?: CheckoutFieldNameAndId[]
  hasMoreAttendees: boolean
  isFetching: boolean
}

const AttendeesTable = (props: AttendeesTableProps) => {
  const {attendeeRows, isHost, eventHasCustomCheckoutFields, checkoutFieldNamesAndIds, hasMoreAttendees, isFetching} =
    props

  const {
    page,
    setPage,
    selectedAttendeeIds,
    setSelectedAttendeeIds,
    selectedAllAttendees,
    setSelectedAllAttendees,
    filterByData,
    filterByOrder,
    selectedTags,
    selectedEvents,
    matchAllEvents,
    matchAllTags,
    currentCheckoutField,
    selectedTickets,
    selectedTrackingLinks,
    genderFilter,
    matchAllTickets,
    matchAllTrackingLinks,
  } = useAttendeesCrmContext()

  const {isMobile} = useDimensions()
  const {groupId, eventId, domain} = useResourcePageParams()
  const {data: group} = useFetchGroup(groupId)
  const {data: event} = useFetchEvent(eventId ?? '')
  const {mutateAsync: fetchAllGroupAttendees, isLoading} = useFetchAllGroupAttendees()
  const {mutateAsync: fetchAllEventAttendees, isLoading: isEventLoading} = useFetchAllEventAttendees()
  const [isShowingSelectAll, setIsShowingSelectAll] = useState(false)
  const [isDisplayingExpandedCheckoutFields, setIsDisplayingExpandedCheckoutFields] = useState(false)
  const [campaignInProgress, setCampaignInProgress] = useState(false)
  const [smsModalIsOpen, setSmsModalIsOpen] = useState(false)
  const [mailChimpModalIsOpen, setMailChimpModalIsOpen] = useState(false)
  const handleToggleDisplayExpandedCheckoutFields = () => {
    setIsDisplayingExpandedCheckoutFields(!isDisplayingExpandedCheckoutFields)
  }
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const groupCurrency = group?.currency
  const selectAllAttendees = async () => {
    let allAttendeeIds: string[] = []
    if (eventId) {
      allAttendeeIds = await fetchAllEventAttendees({
        eventId: eventId,
        domain: (domain as RoleKey) ?? 'owner',
        selectedTags,
        matchAllTags,
        filterByData,
        filterByOrder,
        selectedTickets,
        selectedTrackingLinks,
        genderFilter,
        matchAllTickets,
        matchAllTrackingLinks,
        currentCheckoutField,
      })
    } else {
      allAttendeeIds = await fetchAllGroupAttendees({
        groupId: groupId ?? '',
        selectedEvents,
        matchAllEvents,
        selectedTags,
        matchAllTags,
        filterByData,
        filterByOrder,
        genderFilter,
      })
    }
    setSelectedAllAttendees(true)
    setSelectedAttendeeIds(allAttendeeIds)
  }
  const deSelectAllAttendees = () => {
    setSelectedAttendeeIds([])
    setSelectedAllAttendees(false)
  }
  const isShowingBottomTab = () => {
    if ((campaignInProgress || selectedAttendeeIds.length > 0) && !smsModalIsOpen && !mailChimpModalIsOpen) return true
    return false
  }
  const shouldShowTicket = () => {
    if (!isMobile && !isRSVPEvent) return true
    return false
  }

  const handleNextPage = () => {
    const nextPage = page + 1
    setPage(nextPage)
  }

  const handlePreviousPage = () => {
    const previousPage = page - 1 < 1 ? 1 : page - 1
    setPage(previousPage)
  }

  const nextButtonDisabled = isFetching || !attendeeRows || !hasMoreAttendees
  const backButtonDisabled = page === 1 || isFetching

  const shouldShowCustomCheckoutFields = () => {
    if (eventHasCustomCheckoutFields && checkoutFieldNamesAndIds && checkoutFieldNamesAndIds.length > 0 && !isHost)
      return true
    return false
  }

  const currentCustomCheckoutFieldName = () => {
    if (
      shouldShowCustomCheckoutFields() &&
      checkoutFieldNamesAndIds &&
      checkoutFieldNamesAndIds[currentCheckoutField] &&
      checkoutFieldNamesAndIds[currentCheckoutField].name
    ) {
      return checkoutFieldNamesAndIds[currentCheckoutField].name
    }
    return ''
  }

  const forceAllowTextBlasts = group?.forceAllowTextBlasts

  const isRSVPEvent = eventId != '' && event?.event?.isRSVPEvent

  const shouldShowSpend = () => {
    if (isHost && !isRSVPEvent) return true
    else if (!isMobile && !isRSVPEvent) return true
    return false
  }

  const onClickSMSBlast = () => {
    trackMixpanelEvent('SMS Blast Click- Curators Attendees Page', {
      eventStatus: event?.event.status ?? '',
      numberOfAttendees: selectedAttendeeIds.length,
      allSelected: selectedAllAttendees,
    })
  }
  return (
    <>
      {isShowingSelectAll && selectedAttendeeIds.length > 0 && (
        <>
          {selectedAllAttendees ? (
            <div className='AttendeesTable-selectAll'>
              <p>{selectedAttendeeIds.length} Attendees Selected&nbsp;</p>
              <a onClick={deSelectAllAttendees}>
                <u>Deselect All Attendees</u>
              </a>
            </div>
          ) : (
            <div className='AttendeesTable-selectAll'>
              {!isLoading && !isEventLoading && <p>{selectedAttendeeIds.length} Attendees Selected</p>}
              {attendeeRows.length === 30 && (
                <>
                  {isLoading || isEventLoading ? (
                    <div className='inputWrapper'>
                      <p>Selecting all attendees</p>
                      <SpinLoader height={15} />
                    </div>
                  ) : (
                    <>
                      <p>&nbsp; Click to &nbsp;</p>
                      <a onClick={selectAllAttendees}>
                        <u>Select All Attendees&nbsp;</u>
                      </a>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
      <div className='Attendees-tableContainer'>
        <div style={{filter: isFetching ? 'blur(3px)' : 'none'}}>
          <table className='AttendeesTable AttendeesTable-firstColSmall'>
            <tbody>
              <tr style={{background: 'none'}}>
                <th>
                  {!isHost && (
                    <AttendeeCheckBox
                      defaultChecked={selectedAttendeeIds.length === attendeeRows.length || selectedAllAttendees}
                      onChecked={checked => {
                        if (checked) {
                          setSelectedAttendeeIds([...attendeeRows.map(row => row.id)])
                          setIsShowingSelectAll(true)
                        }
                        if (!checked) {
                          setSelectedAttendeeIds([])
                          setIsShowingSelectAll(false)
                          setSelectedAllAttendees(false)
                        }
                      }}
                    />
                  )}
                </th>
                <th colSpan={2} style={{paddingLeft: `${isMobile ? '0px' : '60px'}`}}>
                  Name
                </th>
                {shouldShowTicket() && <th>Tickets</th>}
                {shouldShowSpend() && <th>Total Spend</th>}
                {!isHost && <th>Contact</th>}
                {!isMobile && <th>Tags</th>}
                {!isMobile && !isRSVPEvent && <th>Last Purchase</th>}
                {!isMobile && shouldShowCustomCheckoutFields() && (
                  <th colSpan={2}>
                    {!isDisplayingExpandedCheckoutFields && (
                      <div
                        className={`AttendeesTable-checkoutField ${
                          checkoutFieldNamesAndIds && checkoutFieldNamesAndIds?.length > 1 ? 'Clickable' : ''
                        } `}
                        onClick={() => handleToggleDisplayExpandedCheckoutFields()}>
                        {checkoutFieldNamesAndIds && checkoutFieldNamesAndIds?.length > 1 ? (
                          <div className='AttendeesTable-checkoutField-selectField'>
                            <div>{currentCustomCheckoutFieldName()}</div>
                            <div style={{paddingBottom: '5px'}}>&#8964;</div>
                          </div>
                        ) : (
                          currentCustomCheckoutFieldName()
                        )}
                      </div>
                    )}
                    {isDisplayingExpandedCheckoutFields && (
                      <ExpandedCheckoutFieldSelector
                        closeView={() => setIsDisplayingExpandedCheckoutFields(false)}
                        checkoutFieldNamesAndIds={checkoutFieldNamesAndIds}
                      />
                    )}
                  </th>
                )}
              </tr>
              {attendeeRows.map(rowAttr => (
                <AttendeeRow
                  key={rowAttr.id}
                  rowAttrs={rowAttr}
                  isHost={isHost}
                  currency={groupCurrency}
                  shouldShowCustomCheckoutFields={shouldShowCustomCheckoutFields()}
                  isRSVPEvent={isRSVPEvent}
                />
              ))}
            </tbody>
          </table>
        </div>
        {isFetching && (
          <div className='Attendees-tableContainer-loadingDiv'>
            <SpinLoader height={80} />
          </div>
        )}
      </div>
      <PaginationButtons
        page={page}
        backButtonDisabled={backButtonDisabled}
        nextButtonDisabled={nextButtonDisabled}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
      {isShowingBottomTab() && (
        <BlastBottomRow
          campaignInProgress={campaignInProgress}
          smsDisabled={group?.textBlastsDisabled}
          setCampaignInProgress={setCampaignInProgress}
          setSmsModalIsOpen={setSmsModalIsOpen}
          setMailChimpModalIsOpen={setMailChimpModalIsOpen}
          forceAllowTextBlasts={forceAllowTextBlasts}
          onClickSMSBlast={onClickSMSBlast}
          selectedAllAttendees={selectedAllAttendees}
        />
      )}
      {mailChimpModalIsOpen && (
        <MailChimpExportModal isOpen={mailChimpModalIsOpen} onClose={() => setMailChimpModalIsOpen(false)} />
      )}
    </>
  )
}

export default AttendeesTable
