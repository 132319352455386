import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FollowGroupOptions = ReactQueryOptions['social']['account']['followGroup']
export type FollowGroupInput = RouterInputs['social']['account']['followGroup']
export type FollowGroupOutput = RouterOutput['social']['account']['followGroup']

export function useFollowGroup(opts?: FollowGroupOptions) {
  const qc = trpc.useContext()
  return trpc.social.account.followGroup.useMutation({
    ...opts,
    onSettled: (...args) => {
      qc.social.account.listGroupsFollowing.invalidate()
      opts?.onSettled?.(...args)
    },
  })
}
