import {useMixpanel} from 'apis/MixPanelHandler'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {RouterInputs, trpc} from 'lib/trpc'

type UnlikeAttendeeInput = RouterInputs['events']['unlikeAttendee']

export const useUnlikeAttendee = () => {
  const {fetchEventGuestlist} = trpc.useContext().events
  const {userId} = useSessionContext()
  const {showToast} = useToast()

  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  return trpc.events.unlikeAttendee.useMutation({
    onMutate: async (input: UnlikeAttendeeInput) => {
      trackMixpanelEvent('Unlike Click', {
        whichGuest: userId ?? '',
      })
      const fetchEventGuestlistKey = {eventId: input.eventId, viewerId: userId ?? ''}
      fetchEventGuestlist.cancel(fetchEventGuestlistKey)

      const previousGuestlist = fetchEventGuestlist.getData({
        eventId: input.eventId,
        viewerId: userId ?? '',
      })

      fetchEventGuestlist.setData(fetchEventGuestlistKey, oldguestlist => {
        if (!oldguestlist) return oldguestlist
        return {
          guestlist: oldguestlist?.guestlist.map(attendee => {
            if (attendee.attendeeAccountId === input.attendeeId) {
              return {...attendee, likedByViewer: false}
            } else {
              return attendee
            }
          }),
          nextCursor: oldguestlist?.nextCursor,
        }
      })
      return {previousGuestlist}
    },
    onError: (err, input, ctx) => {
      const fetchEventGuestlistKey = {eventId: input.eventId, viewerId: userId ?? ''}
      showToast({title: 'There was an issue unliking attendee', subtitle: err.message, type: 'error'})
      if (ctx) {
        fetchEventGuestlist.setData(fetchEventGuestlistKey, ctx.previousGuestlist)
      }
    },
  })
}
