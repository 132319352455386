import {CartItem} from 'apis/Carts'
import {TicketCount} from 'domains/Cart/CartContextProvider'

interface GenerateCartItemProps {
  ticketsCountWithQuantity?: TicketCount[]
  tables?: string[]
}

export const generateCartItems = (params: GenerateCartItemProps) => {
  const {ticketsCountWithQuantity, tables} = params
  const cartItems: CartItem[] = []
  if (ticketsCountWithQuantity) {
    Object.values(ticketsCountWithQuantity).map(t =>
      cartItems.push({
        resourceId: t.data.id,
        resourceType: 'ticket',
        quantity: t.quantity,
      }),
    )
  }
  if (tables) {
    tables.map(tableId => {
      cartItems.push({
        resourceId: tableId,
        resourceType: 'table',
        quantity: 1,
      })
    })
  }
  return cartItems
}

interface GetCheckoutUrlParams {
  cartId: string
  trackingLink: string | null
  affiliateTrackingLink: string | null
  eventUrl?: string
}

export const getCheckoutUrl = (params: GetCheckoutUrlParams) => {
  const {cartId, trackingLink, affiliateTrackingLink, eventUrl} = params
  if (cartId && trackingLink && affiliateTrackingLink)
    return `/e/${eventUrl}/checkout?cartId=${cartId}&t=${trackingLink}&a=${affiliateTrackingLink}`
  else if (cartId && !trackingLink && affiliateTrackingLink)
    return `/e/${eventUrl}/checkout?cartId=${cartId}&a=${affiliateTrackingLink}`
  else if (cartId && trackingLink && !affiliateTrackingLink)
    return `/e/${eventUrl}/checkout?cartId=${cartId}&t=${trackingLink}`
  else return `/e/${eventUrl}/checkout?cartId=${cartId}`
}
