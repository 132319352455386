import React from 'react'

import classNames from 'classnames'
import {MarkdownEditor} from 'components/form/PoshMarkdownEditor'
import {useEventVisualsContext} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'

import './markdownEditorStyles.scss'
import styles from './styles.module.scss'

interface EventVisualsMarkdownEditorProps {
  value?: string
  onChange: (value: string) => void
  lightmodeOverride?: boolean
  accentColorOverride?: string
  label?: string
  placeholder?: string
}

const TOOLBAR_OPTIONS = ['inline', 'blockType', 'link', 'emoji']

export function EventVisualsMarkdownEditor(props: EventVisualsMarkdownEditorProps) {
  const {lightmodeOverride, accentColorOverride, label} = props
  const {
    palette: {lightmode, accentColor},
  } = useEventVisualsContext()

  const lightmodeValue = lightmodeOverride ?? lightmode
  const accentColorValue = accentColorOverride ?? accentColor

  return (
    <>
      {label && <label className='noMargin'>{label}</label>}
      <div
        style={{color: accentColorValue}}
        className={classNames(styles.EventVisualsMarkdownEditor, {[styles.lightmode]: lightmodeValue})}>
        <MarkdownEditor
          {...props}
          classNames={{
            editorClassName: styles.editor,
            toolbarClassName: styles.toolbar,
            wrapperClassName: styles.wrapper,
          }}
          toolbarOptions={{options: TOOLBAR_OPTIONS}}
        />
      </div>
    </>
  )
}
