import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {Photographer} from './types'

export interface UpdateGalleryParams {
  groupID?: string
  eventID?: string
  imageUris?: string[]
  description?: string | null
  tagOnInstagram?: string[]
  photographers?: Photographer[]
  recap?: string | null
  passwordProtected?: string | null
  enabled?: boolean | null
}
export async function updateGallery(eventId: string, updates: UpdateGalleryParams) {
  const response = await PoshApi.updateGallery(eventId, updates)
  return response.data
}

export function useUpdateGallery() {
  return useMutation((params: {eventId: string; updates: UpdateGalleryParams}) =>
    updateGallery(params.eventId, params.updates),
  )
}
