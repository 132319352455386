import React, {useEffect} from 'react'

import './styles.scss'

declare global {
  interface Window {
    ReactNativeWebView: any
  }
}

const RedirectPage = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUrl = urlParams.get('redirectUrl')

  useEffect(() => {
    if (redirectUrl === '/closeWebView') {
      return window.ReactNativeWebView && window.ReactNativeWebView.postMessage('closeWebView')
    }
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }, [redirectUrl])

  return (
    <div className='RedirectPage'>
      <h3 className='center'>Redirecting...</h3>
      {redirectUrl && (
        <a className='white center' href={redirectUrl}>
          Click to Redirect
        </a>
      )}
    </div>
  )
}

export default RedirectPage
