import React, {useEffect, useState} from 'react'

import {SecondaryFilterProps} from '@posh/types'
import classNames from 'classnames'
import {Checkbox} from 'components/form/checkbox/Checkbox'

import './CheckboxGroup.styles.scss'

export interface BaseSelectProps {
  label: SecondaryFilterProps['name']
  options: SecondaryFilterProps['options']
  inCheckout?: boolean
}

interface SingleSelectProps extends BaseSelectProps {
  multiSelect?: false
  onOptionChecked: (option?: string) => void
  defaultChecked: string
}

interface MultiSelectProps extends BaseSelectProps {
  multiSelect: true
  onOptionsChecked: (options: string[]) => void
  defaultChecked: string[]
}

type SelectProps = SingleSelectProps | MultiSelectProps

const CheckboxGroup = ({label, options, defaultChecked, multiSelect, inCheckout, ...props}: SelectProps) => {
  const [checkedOption, setCheckedOption] = useState<string | undefined>(undefined)
  const [checkedOptions, setCheckedOptions] = useState<string[]>([])

  const onMultiSelectChecked = (value: string) => {
    const {onOptionsChecked} = props as MultiSelectProps
    if (checkedOptions.includes(value)) {
      const newCheckedOptions = checkedOptions.filter(option => option !== value)
      setCheckedOptions(newCheckedOptions)
      onOptionsChecked(newCheckedOptions)
    } else {
      const newCheckedOptions = [...checkedOptions, value]
      setCheckedOptions(newCheckedOptions)
      onOptionsChecked(newCheckedOptions)
    }
  }

  const onSingleSelectChecked = (value: string) => {
    const {onOptionChecked} = props as SingleSelectProps
    if (value === checkedOption) {
      setCheckedOption(undefined)
      onOptionChecked(undefined)
    } else {
      setCheckedOption(value)
      onOptionChecked(value)
    }
  }

  const onChecked = (value: string) => {
    if (multiSelect) {
      onMultiSelectChecked(value)
    } else {
      onSingleSelectChecked(value)
    }
  }

  useEffect(() => {
    if (multiSelect) {
      setCheckedOptions(defaultChecked)
    } else {
      setCheckedOption(defaultChecked)
    }
  }, [])

  return (
    <div>
      <h5>{label}</h5>
      <div className={classNames('CheckboxGroup-options', {inCheckout})}>
        {options.map(option => {
          const {label, value} = option
          const stringifiedValue = value.toString()
          const checked = multiSelect ? checkedOptions.includes(stringifiedValue) : checkedOption === stringifiedValue
          return (
            <Checkbox
              key={stringifiedValue}
              label={label}
              checked={checked}
              onChecked={() => onChecked(stringifiedValue)}
              hideBackground={inCheckout}
            />
          )
        })}
      </div>
    </div>
  )
}

export default CheckboxGroup
