import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {useQueryClient} from '@tanstack/react-query'
import {EventModel} from 'apis/Events/types'
import {useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import {useMixpanel} from 'apis/MixPanelHandler'
import getEventFlyer from 'apis/Util/getEventFlyer'
import BallOfTruth from 'components/BallOfTruth'
import CopyBlock from 'components/CopyBlock'
import HoverIcon from 'components/form/HoverIcon'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import moment from 'moment-timezone'
import {generateTrackingLink} from 'pages/EventManagementPages/Overview'
import {calculateEventStatus} from 'pages/Util/timezoneHelper'

import RequirePermissions, {requirePermissionAndScope} from '../../RequirePermissions'

import './index.scss'

export enum EventRowAction {
  EDIT,
  VIEW,
  DUPLICATE,
  DELETE,
}

interface Props {
  data: EventModel[]
  onRowAction?: (action: EventRowAction, eventId: string) => void
  isHost?: boolean
  activeOnly: boolean
  isFetching?: boolean
}

type EventStatus = 'incomplete' | 'ended' | 'live' | 'draft'

const EventsTable = (props: Props) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {currentUser} = useSessionContext()
  const {groupId, domain} = useGroupMatchParams()
  const search = useLocation().search
  const {isHost, activeOnly, data, isFetching} = props
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const queryClient = useQueryClient()
  const [loaderIndex, setLoaderIndex] = useState<number | null>(null)

  const updateEventStatus = async (status: EventStatus, eventId: string | undefined, index: number) => {
    setLoaderIndex(index)
    await updateEvent({
      eventId: eventId!,
      groupId: groupId!,
      eventData: {
        status: status,
      },
    })
    queryClient.invalidateQueries(['groupEvents'])
  }

  return (
    <div className='EventTable'>
      <ReactTooltip className='poshToolTip' effect='solid' place='top' id='hoverIcon' />
      <div className='EventTable-rows'>
        {data.map((item, index) => {
          const eventId = item._id as string
          const hasAttendees = item.attending.length > 0
          const status = calculateEventStatus(moment.tz(item.endUtc, item.timezone), item.status)
          const isLive = status == 'live'
          const isDraft = status == 'draft'
          if (!activeOnly || (activeOnly && isLive) || isDraft)
            return (
              <PoshImageBackground
                className='EventTable-row'
                key={index}
                style={{backgroundImage: `url('${getEventFlyer(item)}')`}}>
                <div className='EventTable-row-content'>
                  <PoshImage className='EventTable-row-image' src={getEventFlyer(item)} />
                  <div className='EventTable-row-identifiers'>
                    <a
                      className='EventTable-row-identifiers-header'
                      href={`/${domain}/groups/${groupId}/events/${eventId}/overview${search}`}>
                      <h4>{item.name}</h4>
                    </a>
                    <div className='EventTable-row-identifiers-info'>
                      {moment.tz(item.startUtc, item.timezone).format('MMMM Do YYYY, h:mm a')}
                    </div>
                    <div
                      className={`EventPageSectionHeader-details--status ${
                        isDraft ? 'orange' : isLive ? 'green' : ''
                      }`}>
                      <BallOfTruth isLive={isLive} isDraft={isDraft} /> {status[0].toUpperCase() + status.substring(1)}
                    </div>
                  </div>

                  {isHost && (
                    <CopyBlock
                      links={generateTrackingLink({
                        eventHex: item.hexUrl,
                        eventUrl: item.url ?? '',
                        trackingLink: currentUser!.trackingLink!,
                      })}
                    />
                  )}

                  <div className='EventTable-row-actions'>
                    {isLive && !hasAttendees && !isHost && (
                      <div>
                        {isFetching && loaderIndex === index ? (
                          <SpinLoader height={30} />
                        ) : (
                          <HoverIcon
                            className={'status'}
                            source={'https://images.posh.vip/images/drafts-1.svg'}
                            description={'Revert to Draft'}
                            onClick={() => {
                              updateEventStatus('draft', eventId, index)
                              trackMixpanelEvent('Revert Event to Draft-  Curator Event List Table item', {eventId})
                            }}
                          />
                        )}
                      </div>
                    )}
                    {isDraft && !isHost && (
                      <div>
                        {isFetching && loaderIndex === index ? (
                          <SpinLoader height={30} />
                        ) : (
                          <HoverIcon
                            className='statusIcon'
                            source='https://images.posh.vip/images/space-rocket-launch+(1)-1.svg'
                            description={'Go Live'}
                            onClick={() => {
                              updateEventStatus('live', eventId, index)
                              trackMixpanelEvent('Go Live Event-  Curator Event List Table item', {eventId})
                            }}
                          />
                        )}
                      </div>
                    )}
                    <HoverIcon
                      source='https://images.posh.vip/b2/pencil.svg'
                      description={'Edit Event'}
                      onClick={() => {
                        trackMixpanelEvent('Edit Event-  Curator Event List Table item', {eventId: item._id})
                        props.onRowAction && props.onRowAction(EventRowAction.EDIT, item._id)
                      }}
                    />
                    <HoverIcon
                      source='https://images.posh.vip/b2/eye.svg'
                      description={'View Event'}
                      onClick={() => {
                        trackMixpanelEvent('View Event-  Curator Event List Table item', {eventId: item._id})
                        props.onRowAction && props.onRowAction(EventRowAction.VIEW, item._id)
                      }}
                    />
                    {!isHost && (
                      <RequirePermissions
                        requiredPermissions={[
                          {
                            permissionKey: 'duplicate_event',
                            applicableScopes: ['group'],
                          },
                        ]}>
                        <HoverIcon
                          source='https://images.posh.vip/b2/duplicate.svg'
                          description={'Duplicate Event'}
                          onClick={() => {
                            trackMixpanelEvent('Duplicate Event-  Curator Event List Table item', {eventId: item._id})
                            props.onRowAction && props.onRowAction(EventRowAction.DUPLICATE, item._id)
                          }}
                        />
                      </RequirePermissions>
                    )}
                    {!hasAttendees && !isHost && (
                      <RequirePermissions
                        requiredPermissions={[requirePermissionAndScope('delete_event', ['group', 'event'])]}>
                        <HoverIcon
                          source='https://images.posh.vip/b2/delete.svg'
                          description={'Delete Event'}
                          onClick={() => {
                            trackMixpanelEvent('Delete Event-  Curator Event List Table item', {eventId: item._id})
                            props.onRowAction && props.onRowAction(EventRowAction.DELETE, item._id)
                          }}
                        />
                      </RequirePermissions>
                    )}
                  </div>
                </div>
              </PoshImageBackground>
            )
        })}
      </div>
    </div>
  )
}

export default EventsTable
