import React, {useMemo} from 'react'

import {Ticket} from 'apis/Events/types'
import useFetchEvent from 'apis/Events/useFetchEvent'

import {MultiSelectProps, PoshMultiSelect} from '..'

interface TicketTypeMultiSelectProps extends Omit<MultiSelectProps, 'options' | 'placeholder'> {
  ticketOptions: Ticket[]
  selectedTicketIds: string[]
  placeholder?: string
}

export function TicketTypeMultiSelect(props: TicketTypeMultiSelectProps) {
  const {ticketOptions, name = 'Select ticket types', placeholder = 'Search for ticket types...', ...rest} = props

  const selectedTicketIds = useMemo(() => new Set(props.selectedTicketIds), [props.selectedTicketIds])

  const options = useMemo(
    () =>
      ticketOptions.map(ticket => {
        return {
          name: ticket.name,
          id: ticket.id,
          selected: selectedTicketIds.has(ticket.id),
        }
      }),
    [ticketOptions, selectedTicketIds],
  )

  return <PoshMultiSelect options={options} placeholder={placeholder} name={name} {...rest} />
}

interface TicketTypeMultiSelectWithFetchProps
  extends Omit<TicketTypeMultiSelectProps, 'ticketOptions' | 'emptyMessage'> {
  eventId: string
}

function WithFetch(props: TicketTypeMultiSelectWithFetchProps) {
  const {eventId, ...rest} = props

  const {data: eventData, isLoading, isError} = useFetchEvent(eventId)
  const {tickets} = eventData?.event ?? {tickets: []}

  const emptyMessage = isLoading ? 'Loading...' : isError ? 'Error loading tickets' : 'No tickets found'

  return <TicketTypeMultiSelect ticketOptions={tickets} {...rest} emptyMessage={emptyMessage} />
}

TicketTypeMultiSelect.Api = WithFetch
