import React, {useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import useFetchTextBlastRecipientsWithStatus from 'apis/TextBlasts/useFetchTextBlastRecipientsWithStatus'
import classNames from 'classnames'

import {CircleBack, CircleForward} from '../../../components/assets/Icons'
import {trpc} from '../../../lib/trpc'
import formatPhoneNumber from '../formatPhoneNumber.helpers'
import {RecipientsList} from '../Recipient/List'
import {RecipientRow} from '../Recipient/Row'
import StatusBadge from '../Recipient/StatusBadge'
import RecipientsModal from '../RecipientsModal'

import './Recipients.styles.scss'

const RECIPIENTS_PER_PAGE = 9
const Recipients = () => {
  const {groupId, blastId, domain} = useParams()
  const [page, setPage] = useState(0)
  const baseInput = useMemo(
    () => ({
      textBlastId: blastId!,
      groupId: groupId!,
      pageSize: RECIPIENTS_PER_PAGE,
      pageNumber: 0,
    }),
    [blastId, groupId],
  )

  const {data: recipientsData, isLoading} = useFetchTextBlastRecipientsWithStatus({
    ...baseInput,
    pageNumber: page,
  })

  const navigate = useNavigate()
  const onClickRecipient = (recipientId: string) => {
    navigate(`/${domain}/groups/${groupId}/marketing/attendee/${recipientId}`)
  }

  const hasPreviousPage = page > 0
  const hasNextPage = recipientsData?.hasNextPage ?? false
  const queryClient = trpc.useContext()
  const totalRecipients = queryClient.textBlasts.getRecipientsWithStatus.getData(baseInput)?.totalRecipients ?? 0

  const {trackEvent} = useMixpanel()
  useEffect(() => {
    trackEvent('Text Blast- Breakdown Recipients View')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='Recipients'>
      <RecipientsList isLoading={isLoading} numSkeleton={RECIPIENTS_PER_PAGE}>
        {recipientsData?.recipients?.map((recipient, index) => (
          <RecipientRow
            key={index}
            name={recipient.name}
            phoneNumber={formatPhoneNumber(recipient.phone)}
            imageSrc={recipient.avi}
            onClick={() => onClickRecipient(recipient.id)}
            sticker={<StatusBadge status={recipient.status} />}
          />
        ))}
      </RecipientsList>
      <RecipientsModal.Footer>
        <RecipientsModal.FooterGroup>
          <CircleBack
            className={classNames({clickable: hasPreviousPage, disabled_smscampaign: !hasPreviousPage})}
            onClick={hasPreviousPage ? () => setPage(prev => prev - 1) : void 0}
          />
          <CircleForward
            className={classNames({clickable: hasNextPage, disabled_smscampaign: !hasNextPage})}
            onClick={hasNextPage ? () => setPage(prev => prev + 1) : void 0}
          />
        </RecipientsModal.FooterGroup>
        <p className='noMargin'>
          <strong>{totalRecipients}</strong> Recipients
        </p>
      </RecipientsModal.Footer>
    </div>
  )
}

export default Recipients
