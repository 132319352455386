import React, {PropsWithChildren} from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'

import './Stage.styles.scss'

interface StageProps {
  image: string
  greyscale?: boolean
}

export const Stage = (props: PropsWithChildren<StageProps>) => {
  const {children, image, greyscale = true} = props

  return (
    <div className='Stage'>
      <div className='Stage-content'>{children}</div>
      <div className='Stage-imageContainer'>
        <PoshImage src={image} className={`Stage-image ${greyscale ? 'greyscale' : ''}`} />
        <div className='gradient' />
      </div>
    </div>
  )
}
