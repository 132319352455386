import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

async function fetchAdminTopFree(): Promise<any[]> {
  const response = await PoshApi.fetchAdminTopFree()
  return response.data
}

export default function useFetchAdminTopFree() {
  return useQuery<any[]>(['admin-top-free'], () => fetchAdminTopFree(), {
    refetchOnMount: false,
  })
}
