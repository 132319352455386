import React, {useState} from 'react'

import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'

import {CSVUploadFirstStep} from './csvUploadFirstStep'
import {CSVUploadSecondStep} from './csvUploadSecondStep'
import getCSVModalConfig from './modalConfigs'

import './styles.scss'

export type UploadType = 'complimentaryTickets' | 'promoCodes'

interface CSVModalProps extends PoshStyledModalProps {
  uploadType: UploadType
}

const CSVImportModal = (props: CSVModalProps) => {
  const {uploadType} = props
  const config = getCSVModalConfig(uploadType)
  const [csvData, setCsvData] = useState<Record<string, string | number>[]>([])
  if (!config) return null

  return (
    <PoshStyledModal {...props} center>
      {csvData.length > 0 ? (
        <CSVUploadSecondStep config={config} csvData={csvData} uploadType={uploadType} onClose={props.onClose} />
      ) : (
        <CSVUploadFirstStep config={config} setCsvData={setCsvData} />
      )}
    </PoshStyledModal>
  )
}

export default CSVImportModal
