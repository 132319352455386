import React from 'react'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

import './UserListPreview.styles.scss'

const UserListPreviewSkeleton = ({
  baseColor,
  highlightColor,
  numSkeletons = 8,
}: {
  baseColor?: string
  highlightColor?: string
  numSkeletons?: number
}) => {
  return (
    <>
      {Array.from({length: numSkeletons}, (_, index) => (
        <Skeleton
          key={index}
          circle={true}
          containerClassName='UserListPreview-user'
          height={36}
          width={36}
          baseColor={baseColor}
          highlightColor={highlightColor}
        />
      ))}
    </>
  )
}

export default UserListPreviewSkeleton
