import {useToast} from 'components/toasts/ToastProvider'
import {trpc} from 'lib/trpc'

export const useSaveCustomCheckoutFields = () => {
  const {showToast} = useToast()
  const queryClient = trpc.useContext()
  return trpc.customCheckoutFields.saveCustomCheckoutFields.useMutation({
    onSettled: () => {
      queryClient.customCheckoutFields.fetchCustomCheckoutFields.invalidate()
    },
    onError: error => {
      showToast({type: 'error', title: 'There was an error saving your changes.', subtitle: error.message})
    },
    onSuccess: () => {
      showToast({type: 'success', title: 'Your changes have been saved.'})
    },
  })
}
