import React from 'react'

import {Text} from '../../Text/Text'
import {CHART_JS_DEFAULT_COLORS} from '../charts.utils'

import styles from './Legend.module.scss'

interface LegendProps {
  title?: string
  labels: string[]
  colors?: string[]
}

export const Legend = (props: LegendProps) => {
  const {title, labels, colors = CHART_JS_DEFAULT_COLORS} = props

  return (
    <div className={styles.LegendContainer}>
      {title && (
        <Text size='xs' bold>
          {title}
        </Text>
      )}
      <div className={styles.Legend}>
        {labels.map((label, index) => (
          <React.Fragment key={index}>
            <span
              style={{
                backgroundColor: colors[index % colors.length],
              }}
              className={styles.LegendItemBox}
            />
            <Text size='xs' style={{userSelect: 'none'}}>
              {label}
            </Text>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
