import React from 'react'
import {useParams} from 'react-router-dom'

import useFetchBookingRequestById from 'apis/Community/useFetchBookingRequestById'

const CommunityBookingConfirmation = () => {
  const {bookingId} = useParams<{bookingId: string}>()
  const {data: bookingRequest} = useFetchBookingRequestById(bookingId!)

  console.log(bookingRequest, 'yerrrrrr')

  return <h1 style={{color: 'white'}}>BOOKING CONFIRMATION PAGE HOMIE</h1>
}

export default CommunityBookingConfirmation
