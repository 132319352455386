import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

interface RecentOrder {
  avi: string
  name: string
  eventName: string
  total: number
}

interface KPIResponse {
  gmvToday: number
  gmvThisMonth: number
  recentOrders: RecentOrder[]
}

async function fetchKPIs(): Promise<KPIResponse> {
  const response = await PoshApi.fetchKPIs()
  return response.data
}

export default function useFetchKPIs() {
  return useQuery<KPIResponse>(['admin-kpis'], () => fetchKPIs(), {
    refetchOnMount: false,
  })
}
