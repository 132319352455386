import React, {PropsWithChildren} from 'react'

import {EventModel} from 'apis/Events/types'

import SaveDiscardBottomBar from '../SaveDiscardBottomBar'

export interface BaseEventSettingsProps {
  event: EventModel
}

interface EventSettingsProps {
  onDiscard?: () => void
  onSave?: () => void
  isShowingSave: boolean
}

export const EventSettingsComponent = (props: PropsWithChildren<EventSettingsProps>) => {
  const {children, onDiscard, onSave, isShowingSave} = props
  return (
    <>
      <div className='EventSettings-back' onClick={() => history.back()}>
        &larr; Back to Settings
      </div>
      <div className='EventSettings-horizontalInputContainer'>{children}</div>
      {onSave && onDiscard && <SaveDiscardBottomBar onDiscard={onDiscard} onSave={onSave} isOpen={isShowingSave} />}
    </>
  )
}
