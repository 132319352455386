import React, {ReactNode} from 'react'

import classNames from 'classnames'
import {Cancel, Check, Info, Warning} from 'components/assets/MobileIcons'

import {ToastType} from './ToastProvider'
import {IToast} from './ToastProvider'

import './styles.scss'

interface ToastProps {
  toast: IToast
}

const ToastVariantIcon: Record<ToastType, string | ReactNode> = {
  error: <Cancel />,
  success: <Check />,
  warning: <Warning />,
  info: <Info />,
}

export const Toast = (props: ToastProps) => {
  const {toast} = props

  const {type, title, subtitle} = toast

  const classNamesVariantObject = {
    info: type === 'info',
    warning: type === 'warning',
    'error-variant': type === 'error',
    success: type === 'success',
  }

  return (
    <div className='Toast-wrapper'>
      <div className={classNames('Toast-content', 'loaderMask', classNamesVariantObject)} />
      <div className={classNames('Toast-content', classNamesVariantObject)}>
        {ToastVariantIcon[type]}
        <div className={classNames('Toast-content-details', classNamesVariantObject)}>
          <h5>{title}</h5>
          {subtitle && <p>{subtitle}</p>}
        </div>
      </div>
    </div>
  )
}
