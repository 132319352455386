import React, {useState} from 'react'

import {useSignUp} from 'apis/Auth/useSignUp'
import {useUpdateCartWithAccountId} from 'apis/Carts/useUpdateCartWithAccountId'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import CheckoutButton from 'components/form/CheckoutButton'
import {TextFieldVariants} from 'components/form/TextField'
import {useToast} from 'components/toasts/ToastProvider'
import useLiveCartContext, {emailCheckoutFormSchema, tokenCheckoutFormSchema} from 'domains/LiveCart/LiveCartContext'
import TOSCheckForm from 'pages/OwnerPage/SignUp/TOSCheckForm'

import {CartError} from '../CartError'
import CheckoutField from '../CheckoutField/CheckoutField'

export interface SignUpForm {
  first_name?: string
  last_name?: string
  phone_number?: string
  email?: string
  password?: string
  confirm_password?: string
}

export interface CreateAccountFormProps {
  setHasAccount: React.Dispatch<React.SetStateAction<boolean>>
  signupToken?: string
  signupPhoneNumber?: string
}

const CreateAccountForm = (props: CreateAccountFormProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {setHasAccount, signupToken, signupPhoneNumber} = props
  const {cartFormData, cartId, formErrors, setFormErrors, isRSVP} = useLiveCartContext()
  const {showToast} = useToast()
  const {mutate: updateCartWithAccountId} = useUpdateCartWithAccountId()
  const {mutate: signUp, isLoading: isSigningUp} = useSignUp({
    onSuccess: async () => {
      updateCartWithAccountId({cartId})
      setHasAccount(true)
      trackMixpanelEvent('Signup Success-  Signup Checkout')
    },
    onError: err => {
      showToast({
        type: 'error',
        title: 'There was an error creating your account.',
        subtitle: err.message,
      })
      trackMixpanelEvent('Signup Failed-  Signup Checkout', err)
    },
  })
  const [loading, setIsLoading] = useState(false)
  const [accountCreationError, setAccountCreationError] = useState<string | undefined>('')
  const [forms, setForms] = useState({
    smsOptIn: true,
    tos: true,
  })
  const [hasSubmittedOnceWithoutSMSOptIn, setHasSubmittedOnceWithoutSMSOptIn] = useState(false)

  const signUpInfo: SignUpForm = {
    first_name: cartFormData['first_name']?.value,
    last_name: cartFormData['last_name']?.value,
    password: cartFormData['password']?.value,
    confirm_password: cartFormData['confirm_password']?.value ?? '',
    phone_number: cartFormData['phone_number']?.value,
    email: cartFormData['email']?.value,
  }

  const grabInfoValidationErrors = async (signUpInfo: SignUpForm) => {
    let validationErrors: typeof formErrors = {}

    try {
      const validInformation = signupToken
        ? tokenCheckoutFormSchema.validateSync(signUpInfo, {abortEarly: false})
        : emailCheckoutFormSchema.validateSync(signUpInfo, {abortEarly: false})
      if (validInformation) setFormErrors({})
    } catch (error: any) {
      const caughtErrors = error.inner
      validationErrors = caughtErrors.reduce((acc: any, eM: any) => {
        const {path, message} = eM
        return {...acc, [path]: message}
      }, {})
    }

    const isEmpty = Object.values(validationErrors).every(value => value === '')

    if (!isEmpty) {
      trackMixpanelEvent('Signup Failed-  Signup Checkout', validationErrors)
      setFormErrors(validationErrors)
      return false
    } else {
      return true
    }
  }

  const handleSignUp = async () => {
    setIsLoading(true)
    try {
      if (!cartId) throw new Error('No cart id found')

      const isValidInfo = await grabInfoValidationErrors(signUpInfo)

      if (!isValidInfo) return setIsLoading(false)
      if (!forms.tos) throw new Error('Please agree to the terms and conditions')
      if (!forms.smsOptIn && !hasSubmittedOnceWithoutSMSOptIn) {
        setHasSubmittedOnceWithoutSMSOptIn(true)
        setIsLoading(false)
        return showToast({
          type: 'warning',
          title: 'Please opt in to text notifications for important event-related alerts.',
        })
      }

      signUp({
        email: signUpInfo.email ?? '',
        password: signUpInfo.password,
        firstName: signUpInfo.first_name ?? '',
        lastName: signUpInfo.last_name ?? '',
        phone: signUpInfo.phone_number ?? '',
        smsOptIn: forms.smsOptIn,
        verificationToken: signupToken,
      })
      setIsLoading(false)
    } catch (error: any) {
      if (error.response.data.error) {
        trackMixpanelEvent('Signup Failed-  Signup Checkout', {error: error.response.data.error})
        setAccountCreationError(error.response.data.error)
      } else if (error.response) {
        trackMixpanelEvent('Signup Failed-  Signup Checkout', {error: error.response.data})
        setAccountCreationError(error.response.data)
      } else {
        trackMixpanelEvent('Signup Failed-  Signup Checkout', {error: error.message})
        setAccountCreationError(error.message)
      }
    }
    setIsLoading(false)
  }

  return (
    <div className='CheckoutPage-Section'>
      {!isRSVP && <h5>Your Info</h5>}
      <CheckoutField
        field_type={'input'}
        field_key={'first_name'}
        value={cartFormData['first_name']?.value}
        variant={TextFieldVariants.DARK}
        placeholder={'First Name'}
        nativeProps={{}}
        isCustomField={false}
      />
      <CartError error={formErrors.first_name} />
      <CheckoutField
        field_type='input'
        field_key={'last_name'}
        value={cartFormData['last_name']?.value}
        variant={TextFieldVariants.DARK}
        placeholder={'Last Name'}
        nativeProps={{}}
        isCustomField={false}
      />
      <CartError error={formErrors.last_name} />

      <div className='CheckoutPage-Section-Halved'>
        {!signupToken ? (
          <>
            <CheckoutField
              field_type={'phone_number'}
              field_key='phone_number'
              value={cartFormData['phone_number']?.value}
              required={true}
              defaultCountry={'US'}
              placeholder={'Phone Number'}
              className={undefined}
              disabled={false}
            />
            <CartError error={formErrors.phone_number} />
          </>
        ) : (
          <>
            <CheckoutField
              field_type={'input'}
              field_key='email'
              value={cartFormData['email']?.value}
              variant={TextFieldVariants.DARK}
              placeholder={'Email'}
              nativeProps={{}}
              isCustomField={false}
            />
            <CartError error={formErrors.email} />
          </>
        )}
      </div>

      {isRSVP ? (
        <Button
          className='fit center'
          isLoading={loading}
          disabled={loading}
          onClick={() => {
            trackMixpanelEvent('Signup RSVP-  Signup Checkout')
            handleSignUp()
          }}>
          RSVP
        </Button>
      ) : (
        <CheckoutButton
          className={'Gold'}
          disabled={loading || isSigningUp}
          onClick={() => {
            trackMixpanelEvent('Signup Paid-  Signup Checkout')
            handleSignUp()
          }}>
          {loading ? `CREATING ACCOUNT` : `CREATE ACCOUNT`}
        </CheckoutButton>
      )}
      {accountCreationError && <span className='error center text-small'>{accountCreationError}</span>}
      <div className='CheckoutPage-Section fade'>
        <TOSCheckForm setCheckForms={setForms} checkForms={forms} />
      </div>
    </div>
  )
}

export default CreateAccountForm
