import React, {useEffect, useMemo, useState} from 'react'
import Loader from 'react-loader-spinner'

import {IPermissionConstraints} from 'apis/Roles'
import {PermissionResponse} from 'apis/Roles/Permission'
import groupBy from 'lodash/groupBy'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

import useEditAccountPermissions from '../../../../apis/Roles/useEditAccountPermissions'
import useFetchAccountRolePermissions from '../../../../apis/Roles/useFetchAccountRolePermissions'
import useFetchApplicablePermissionsForRoleAndScope from '../../../../apis/Roles/useFetchApplicablePermissionsForRoleAndScope'
import Button from '../../../../components/form/Button'
import PoshSwitch from '../../../../components/form/PoshSwitch'
import useSessionContext from '../../../../domains/Auth/SessionContext'
import useTeamManagementModalContext from '../TeamManagementModalContext'

import './styles.scss'

export default function PermissionsEditor() {
  const {userId} = useSessionContext()
  const {
    selectedRole,
    accountRoleToEdit,
    scope,
    setActivePanel,
    setErrorMessage,
    setSuccessMessage,
    reset,
    closeModal,
    setUpdatedPermissionConstraints: setUpdatedPermissionConstraintsInContext,
  } = useTeamManagementModalContext()

  const [permissions, setPermissions] = useState<PermissionResponse[]>([])
  const [updatedPermissionConstraints, setUpdatedPermissionConstraints] = useState<IPermissionConstraints>(
    accountRoleToEdit?.permissionConstraints ?? {},
  )
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false)

  const {
    refetch: fetchApplicablePermissions,
    data: defaultPermissions,
    isFetching: applicablePermissionsIsFetching,
  } = useFetchApplicablePermissionsForRoleAndScope(selectedRole ?? undefined, scope)
  const {
    refetch: fetchAccountRolePermissions,
    data: accountRolePermissions,
    isFetching: accountRolePermissionsIsFetching,
    refetch: fetchAccountPermissions,
  } = useFetchAccountRolePermissions(accountRoleToEdit?._id)
  const {mutateAsync: updatePermissions, isLoading: isUpdating} = useEditAccountPermissions()
  const isFetching = applicablePermissionsIsFetching || accountRolePermissionsIsFetching

  const onCancel = () => {
    reset()
    setActivePanel('choose_role')
    if (closeModal && accountRoleToEdit) closeModal()
  }

  const onSubmit = () => {
    if (!accountRoleToEdit) {
      setUpdatedPermissionConstraintsInContext(updatedPermissionConstraints)
      setActivePanel('choose_role')
    } else {
      updatePermissions({
        accountRoleId: accountRoleToEdit._id,
        assignedBy: userId!,
        permissionConstraints: updatedPermissionConstraints,
        roleKey: accountRoleToEdit.roleKey,
      }).then(data => {
        if (data.success) {
          setSuccessMessage('Successfully updated permissions')
          fetchAccountPermissions()
        }
        if (data.error) {
          setErrorMessage(data.error)
        }
      })
    }
  }

  const onPermissionToggle = (enabled: boolean, permission: PermissionResponse) => {
    let newPermissionConstraints: IPermissionConstraints = {}
    const {key: permissionKey, enabled: pastEnabled} = permission

    const isReverting = enabled == pastEnabled
    const isCustomConstraint = has(accountRoleToEdit?.permissionConstraints, permissionKey)

    if ((isReverting && isCustomConstraint) || !isReverting) {
      newPermissionConstraints[permissionKey] = {
        enabled: enabled,
      }
      const updatedConstraints = {...updatedPermissionConstraints, ...newPermissionConstraints}
      setUpdatedPermissionConstraints(updatedConstraints)
    } else {
      newPermissionConstraints = omit(updatedPermissionConstraints, [permissionKey])
      setUpdatedPermissionConstraints(newPermissionConstraints)
    }
  }

  useEffect(() => {
    if (accountRoleToEdit?.permissionConstraints)
      setUpdatedPermissionConstraints(accountRoleToEdit?.permissionConstraints)
  }, [accountRoleToEdit])

  useEffect(() => {
    if (accountRolePermissions || defaultPermissions) {
      const permissions = accountRolePermissions ?? defaultPermissions
      setPermissions(permissions!)
    }
  }, [accountRolePermissions, defaultPermissions])

  useEffect(() => {
    if (accountRoleToEdit) fetchAccountRolePermissions()
    else fetchApplicablePermissions()
  }, [selectedRole, accountRoleToEdit, fetchAccountRolePermissions, fetchApplicablePermissions])

  useEffect(() => {
    if (isFetching) return setSaveButtonEnabled(false)
    if (!accountRoleToEdit?.permissionConstraints && !updatedPermissionConstraints) {
      return setSaveButtonEnabled(false)
    }
    if (isEmpty(updatedPermissionConstraints) && isEmpty(accountRoleToEdit?.permissionConstraints)) {
      return setSaveButtonEnabled(false)
    }

    setSaveButtonEnabled(!isEqual(updatedPermissionConstraints, accountRoleToEdit?.permissionConstraints))
  }, [accountRoleToEdit?.permissionConstraints, isFetching, updatedPermissionConstraints])

  const groupedPermissions = useMemo(() => {
    if (!permissions) return {}
    return groupBy(permissions, p => p.module)
  }, [permissions])

  return (
    <>
      <div className='PermissionsEditor-permissions'>
        {isFetching ? (
          <Loader type='TailSpin' color='black' width={20} height={20} />
        ) : (
          <>
            {Object.keys(groupedPermissions).map(module => {
              const modulePermissions = groupedPermissions[module]
              const displayedPermissions = modulePermissions.filter(p => !p.restricted)
              if (displayedPermissions.length === 0) return null
              return (
                <div key={module} className='PermissionsEditor-module'>
                  <h6>{module}</h6>
                  {displayedPermissions.map((permission, index) => (
                    <PoshSwitch
                      key={index}
                      rightTitle={permission.displayName}
                      subtitle={permission.descriptor}
                      switchOptions={{
                        checked: updatedPermissionConstraints[permission.key]?.enabled ?? permission.enabled,
                        onChange: c => onPermissionToggle(c, permission),
                      }}
                    />
                  ))}
                </div>
              )
            })}
          </>
        )}
      </div>
      <div className='AddTeamMemberModal-bottomInputWrapper'>
        <Button className='dark' onClick={onCancel}>
          Cancel
        </Button>
        <Button className='AddTeamMemberModal-button submit' onClick={onSubmit} disabled={!saveButtonEnabled}>
          {isUpdating ? <Loader type='TailSpin' color='black' width={20} height={20} /> : '+ Save'}
        </Button>
      </div>
    </>
  )
}
