const multiSelectStyles = {
  inputField: {
    // Change color of text in search
    color: `#fff`,
  },
  searchBox: {
    // To change search box element look
    background: `none`,
    fontSize: `16px`,
    fontFamily: `helvetica neue, Helvetica, sans-serif`,
    fontWeight: `400`,
    color: `#fff`,
  },
  searchWrapper: {
    border: 'none',
  },
  chips: {
    // To change css chips(Selected options)
    background: `#fc0`,
    color: `black`,
  },
  optionContainer: {
    // To change css for option container
    backgroundColor: `#222222`,
    border: `1px solid #333`,
  },
  option: {
    // To change css for dropdown options
    background: `#222222`,
    transition: '0.2s',
    color: `#ccc`,
  },
  notFound: {
    // To change css for not found options
    backgroundColor: `#222222`,
    color: `#ccc`,
    fontStyle: 'italic',
  },
}

export default multiSelectStyles
