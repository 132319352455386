import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {AuthResponse} from 'apis/Auth'
import {setSessionContext} from 'apis/Auth/setSessionContext'
import {PoshBoatLoader} from 'components/Loaders/PoshBoatLoader'
import useSessionContext from 'domains/Auth/SessionContext'
import {trpc} from 'lib/trpc'
import {PoshRoutes} from 'models/PoshRoutes'

export function MagicLinkPage() {
  // 1. request the identity with the authenticating token
  const query = trpc.auth.identity.useQuery(undefined, {enabled: false, cacheTime: 0})
  const params = useParams<{token: string}>()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (!params.token) throw new Error('MagicLink requires a /:token param')
    // this is how token state is managed in the app, so …
    localStorage.setItem('token', atob(params.token))
    // … as a side effect our fetch will now return the related identity
    query.refetch()
  }, [params.token])

  // 2. validate the response to set the app's login state, or fail
  const sessionContext = useSessionContext()
  const navigate = useNavigate()
  const [mode, setMode] = useState('loading')
  useEffect(() => {
    if (!query.isFetched) return

    if (isAuthResponse(query.data)) {
      setSessionContext(query.data, sessionContext)
      navigate(searchParams.get('r') ?? PoshRoutes.HOME, {replace: true})
    } else {
      localStorage.removeItem('token')
      setMode('error')
    }
  }, [query.isFetched])

  return mode === 'loading' ? (
    <PoshBoatLoader />
  ) : (
    <div style={{display: 'flex', placeItems: 'center', width: '100vw', height: '100vh', padding: '2rem'}}>
      <h1>Something has gone terribly wrong.</h1>
    </div>
  )
}

function isAuthResponse(v?: Record<string, any>): v is AuthResponse {
  return v != null && 'token' in v && 'userId' in v
}
