const SUPPORT_EMAIL = 'support@posh.vip'

/**
 * Generates a mailto link for the support email.
 * @param subject - The subject of the email.
 * @param body - The body of the email.
 * @returns The mailto link.
 */
export const generateSupportEmailHref = (subject: string, body: string) => {
  return `mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
}
