import React from 'react'

import {IDispute} from 'apis/Groups'
import Badge, {BadgeVariant} from 'components/Badge'
import {Card} from 'components/Card'
import moment from 'moment'
import Stripe from 'stripe'

type DisputeStatusBadgeMap = {
  [key in Stripe.Dispute.Status]: JSX.Element
}

const DISPUTE_STATUS_BADGE_MAP: DisputeStatusBadgeMap = {
  charge_refunded: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--green'>
      Charge Refunded
    </Badge>
  ),
  lost: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--red'>
      Lost
    </Badge>
  ),
  needs_response: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--fadedRed'>
      Needs Response
    </Badge>
  ),
  under_review: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--fadedRed'>
      Under Review
    </Badge>
  ),
  warning_closed: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--fadedRed'>
      Warning Closed
    </Badge>
  ),
  warning_needs_response: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--red'>
      Warning Needs Response
    </Badge>
  ),
  warning_under_review: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--fadedRed'>
      Warning Under Review
    </Badge>
  ),
  won: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--greenTwo'>
      Won
    </Badge>
  ),
}

const OrderCardDispute = ({dispute}: {dispute: IDispute}) => {
  const renderStatus = () => {
    return DISPUTE_STATUS_BADGE_MAP[dispute.stripeDisputeStatus]
  }

  return (
    <Card title={'Dispute'} animationIndex={1} className='OrderPage-disputeCard'>
      <p>
        The charge associated with this order was disputed on {moment(dispute.createdAt).format('MMM Do, YYYY')} at{' '}
        {moment(dispute.createdAt).format('h:mm a')}.
      </p>
      <table>
        <tbody>
          <tr>
            <td>Amount</td>
            <td>${dispute.amount.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Fee</td>
            <td>${dispute.fee.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{renderStatus()}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  )
}

export default OrderCardDispute
