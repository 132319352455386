import React from 'react'

import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'

import {AudienceRepublicIntegration} from './audienceRepublicIntegration'
import {MailChimpIntegration} from './mailChimpIntegration'

import './styles.scss'

const Integrations = () => {
  return (
    <>
      <p>Connect other applications with Posh to superpower your organization.</p>
      <div className='IntegrationsCont'>
        <RequirePermissions
          requiredPermissions={[requirePermissionAndScope('export_to_mailchimp', ['event', 'group'])]}>
          <MailChimpIntegration />
        </RequirePermissions>
        <AudienceRepublicIntegration />
      </div>
    </>
  )
}

export default Integrations
