import React, {PropsWithChildren} from 'react'
import {Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom'

import {dateFromObjectId} from '@posh/model-types'
import {EventModel} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import classNames from 'classnames'
import BallOfTruth from 'components/BallOfTruth'
import {DB_BACKED_FEATURES, useIsFeatureEnabled} from 'components/FeatureFlag'
import Button from 'components/form/Button'
import {useSetContextButton} from 'components/Navigation/NavigationContext'
import isDevEnv from 'helpers/isDevEnv'
import {isEventKickbackEligible} from 'helpers/isEventKickbackEligible'
import {useDimensions} from 'hooks/useDimensions'
import moment from 'moment-timezone'
import AttendeesCrmContextProvider from 'pages/GroupPage/Marketing/AttendeesCrmContextProvider'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import {calculateEventStatus} from 'pages/Util/timezoneHelper'

import useFetchEvent from '../../apis/Events/useFetchEvent'
import {
  attendeesUrl,
  attendeesUrlGold,
  brushUrl,
  brushUrlGold,
  chartsgoldUrl,
  chartsUrl,
  Eye,
  eyeUrl,
  galleryActive,
  galleryInActive,
  hostsUrl,
  hostsUrlGold,
  servicesUrl,
  servicesUrlGold,
  settingsUrl,
  settingsUrlGold,
  tableGoldUrl,
  tableUrl,
  ticketsUrl,
  ticketsUrlGold,
} from '../../components/assets/Icons'
import IconButton from '../../components/form/IconButton'
import RequirePermissions, {requirePermissionAndScope} from '../../components/RequirePermissions'
import {PoshPageSectionAttributes} from '../GroupPage/GroupRoutes'
import {useResourcePageParams} from '../PoshAppLayout'
import Approvals from './Approvals'
import Attendees from './Attendees'
import {EventManagementContextProvider} from './EventManagementContext'
import EventPageNavigation from './EventPageNavigation'
import GalleryManagementPage from './GalleryManagementPage'
import KickbackRouter from './KickbackRouter'
import MobileEventPageNavigation from './MobileEventPageNavigation'
import {OverviewWrapper} from './Overview'
import ServicesPage from './ServicesPage'
import Settings from './Settings'
import SwitchToApprovalBtn from './SwitchToApprovalBtn'
import Tables from './Tables'
import TeamMembers from './TeamMembers'
import TicketsRoutes from './Tickets'
import Visuals from './Visuals'

import './styles.scss'
import './EventPageSectionHeader.scss'
import layoutStyles from './layout.module.scss'

export enum EventPageSection {
  OVERVIEW = 'overview',
  VISUALS = 'visuals',
  APPROVALS = 'approvals',
  TICKETS = 'tickets',
  ATTENDEES = 'attendees',
  TEAM_MEMBERS = 'team',
  TABLES = 'tables',
  KICKBACK = 'kickback',
  SETTINGS = 'settings',
  SERVICES = 'services',
  GALLERY = 'gallery',
}

export interface PoshEventPageSectionAttributes extends PoshPageSectionAttributes {
  tooltip: string
  icon?: string
  activeIcon?: string
  backgroundClassName?: string
  showAlert?: boolean
  showEventPageSectionHeader?: boolean
}

interface EventPageWrapperProps {
  section: EventPageSection
  hideNavigation?: boolean
  event: EventModel
  action?: React.ReactNode
  backgroundClassName?: string
  showEventPageSectionHeader?: boolean
}

interface EventPageSectionHeaderProps {
  section: EventPageSection
  eventName: string
  status: string
  timezone: string
  eventStart: moment.Moment
  eventEnd?: moment.Moment
}

const EventPageSectionHeader = (props: EventPageSectionHeaderProps) => {
  const {section, eventName, status, timezone, eventStart, eventEnd} = props
  const eventStatus = calculateEventStatus(eventEnd, status)
  const isLive = eventStatus == 'live'
  const isDraft = eventStatus === 'draft'

  const title = section.charAt(0).toUpperCase() + section.slice(1)
  return (
    <>
      <div className='EventPageSectionHeader'>
        <div className='EventPageSectionHeader-identifiers pshSubPageHeader'>
          {eventName}
          <span className='EventPageSectionHeader-separator'> — </span>
          <span className={'EventPageSectionHeader-identifiers'}>{title}</span>
        </div>
        <div className='EventPageSectionHeader-details'>
          <span className={`EventPageSectionHeader-details--status ${isDraft ? 'orange' : isLive ? 'green' : ''}`}>
            <BallOfTruth isLive={isLive} isDraft={isDraft} /> {eventStatus}
          </span>
          <span className='EventPageSectionHeader-details--countDown'>
            --{' '}
            {isLive
              ? `event starts ${eventStart.fromNow()} (${moment.tz.zone(timezone)?.abbr(360)})`
              : eventStart.fromNow()}
          </span>
        </div>
      </div>
    </>
  )
}

/**
 * This is a layout component for the Event Management pages.
 * @returns
 */
function EventPageLayout() {
  const {isMobile} = useDimensions()
  const {pathname} = useLocation()
  const isOnUpgradedVisualsPage = pathname.includes('visuals')

  const {isFeatureEnabled} = useIsFeatureEnabled(DB_BACKED_FEATURES.WEB_NAVIGATION_REDESIGN)
  const containerClassNames = classNames([layoutStyles.navigation, {[layoutStyles.hidden]: isOnUpgradedVisualsPage}])

  return (
    <div className={layoutStyles.container}>
      {isMobile ? ( // always show mobile navigation
        <div className={containerClassNames}>
          <MobileEventPageNavigation />
        </div>
      ) : !isFeatureEnabled ? ( // otherwise show desktop navigation if feature flag is NOT enabled
        <div className={containerClassNames}>
          <EventPageNavigation />
        </div>
      ) : (
        <></>
      )}
      <Outlet />
    </div>
  )
}

const EventPageWrapper = (props: PropsWithChildren<EventPageWrapperProps>) => {
  const {event, backgroundClassName, showEventPageSectionHeader = true, hideNavigation} = props
  const isKickbackPage = props.section === 'kickback'

  return (
    <div className={`EventManagementPage ${backgroundClassName} ${isKickbackPage && 'kickback'}`}>
      <div
        className={classNames('EventManagementPage-content', {
          [`EventManagementPage-content-${backgroundClassName}`]: !!backgroundClassName,
        })}>
        {showEventPageSectionHeader && (
          <div className='EventManagementPage-content--header'>
            <EventPageSectionHeader
              section={props.section}
              eventName={event.name}
              timezone={event.timezone}
              status={event.status ?? ''}
              eventStart={moment.tz(event.startUtc, event.timezone)}
              eventEnd={moment.tz(event.endUtc, event.timezone)}
            />
            {props.action}
          </div>
        )}
        {props.children}
      </div>
    </div>
  )
}

export const EVENTS_ROUTE_MAP: {[key in EventPageSection]: PoshEventPageSectionAttributes} = {
  [EventPageSection.OVERVIEW]: {
    route: 'overview',
    content: <OverviewWrapper />,
    icon: chartsUrl,
    activeIcon: chartsgoldUrl,
    tooltip: 'Overview',
  },
  [EventPageSection.VISUALS]: {
    route: 'visuals',
    content: <Visuals />,
    icon: brushUrl,
    activeIcon: brushUrlGold,
    requiredPermissions: [requirePermissionAndScope('edit_event_visuals', ['event', 'group'])],
    tooltip: 'Visuals',
    showEventPageSectionHeader: false,
  },
  [EventPageSection.APPROVALS]: {
    route: 'approvals',
    content: <Approvals />,
    icon: 'https://images.posh.vip/b2/pending-black.svg',
    activeIcon: 'https://images.posh.vip/b2/pending-gold.svg',
    requiredPermissions: [requirePermissionAndScope('edit_event_tickets', ['event', 'group'])],
    tooltip: 'Pending Guests',
  },
  [EventPageSection.TICKETS]: {
    route: 'tickets',
    content: <TicketsRoutes />,
    icon: ticketsUrl,
    activeIcon: ticketsUrlGold,
    requiredPermissions: [requirePermissionAndScope('edit_event_tickets', ['event', 'group'])],
    tooltip: 'Tickets',
  },
  [EventPageSection.ATTENDEES]: {
    route: 'attendees',
    content: (
      <AttendeesCrmContextProvider>
        <Attendees />
      </AttendeesCrmContextProvider>
    ),
    icon: attendeesUrl,
    activeIcon: attendeesUrlGold,
    requiredPermissions: [requirePermissionAndScope('view_attendee_list', ['event', 'group'])],
    tooltip: 'Attendees',
  },
  [EventPageSection.TEAM_MEMBERS]: {
    route: 'team',
    content: <TeamMembers />,
    icon: hostsUrl,
    activeIcon: hostsUrlGold,
    tooltip: 'Team & Tracking',
  },
  [EventPageSection.TABLES]: {
    route: 'tables',
    content: <Tables />,
    icon: tableUrl,
    activeIcon: tableGoldUrl,
    requiredPermissions: [requirePermissionAndScope('edit_event_tables', ['event', 'group'])],
    tooltip: 'Tables',
  },
  [EventPageSection.KICKBACK]: {
    route: 'kickback',
    content: <KickbackRouter />,
    icon: 'https://images.posh.vip/b2/kickback-black.png',
    activeIcon: 'https://images.posh.vip/b2/kickback-Recovered-k.png',
    requiredPermissions: [requirePermissionAndScope('enable_kickbacks', ['event', 'group'])],
    tooltip: 'Kickback',
  },
  [EventPageSection.SERVICES]: {
    route: 'services',
    content: <ServicesPage />,
    icon: servicesUrl,
    activeIcon: servicesUrlGold,
    requiredPermissions: [requirePermissionAndScope('edit_event_services', ['event', 'group'])],
    tooltip: 'Services',
  },
  [EventPageSection.SETTINGS]: {
    route: 'settings',
    content: <Settings />,
    icon: settingsUrl,
    activeIcon: settingsUrlGold,
    requiredPermissions: [requirePermissionAndScope('edit_event_settings', ['event', 'group'])],
    tooltip: 'Settings',
  },
  [EventPageSection.GALLERY]: {
    route: 'gallery',
    content: <GalleryManagementPage />,
    icon: galleryInActive,
    activeIcon: galleryActive,
    tooltip: 'Gallery',
    showAlert: true,
  },
}

export const HOST_SECTIONS = [EventPageSection.OVERVIEW, EventPageSection.ATTENDEES, EventPageSection.TEAM_MEMBERS]

const now = moment()

export const EventRoutes = () => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {eventId, domain, groupId} = useResourcePageParams()
  const {data: fetchEventData} = useFetchEvent(eventId!)
  const {search, pathname} = useLocation()
  const isOnUpgradedVisualsPage = pathname.includes('visuals')
  const navigate = useNavigate()

  const navigateToEventPage = () => {
    window.open(`/e/${event.url}` + search, '_blank')
  }

  useSetContextButton({
    content: (
      <>
        <Eye />
        View Event Page
      </>
    ),
    onClick: navigateToEventPage,
  })

  if (!fetchEventData) return null
  const {event} = fetchEventData

  const eventIsOver = moment.tz(event.endUtc, event.timezone).isBefore(moment())

  const eventRoutes = Object.keys(EVENTS_ROUTE_MAP).map(eventSection => {
    const action = () => {
      if (eventSection == EventPageSection.VISUALS) {
        const onClick = () => {
          trackMixpanelEvent('Copy Link- Curator Event Overview Page', {eventStatus: event.status ?? ''})
          navigateToEventPage()
        }
        return (
          <>
            <IconButton className='gold' icon={eyeUrl} onClick={onClick}>
              View Event Page
            </IconButton>
          </>
        )
      } else if (eventSection == EventPageSection.ATTENDEES && event.isRSVPEvent) {
        return <SwitchToApprovalBtn event={event} />
      } else if (eventSection == EventPageSection.OVERVIEW) {
        return (
          <>
            <div className='buttonWrapper'>
              {event.isRSVPEvent && (
                <Button
                  className='light gold'
                  onClick={() => navigate(`/${domain}/groups/${groupId}/events/${eventId}/tickets/create` + search)}>
                  + Add Paid Ticket Type
                </Button>
              )}
            </div>
          </>
        )
      } else {
        return null
      }
    }

    const {route, content, requiredPermissions, backgroundClassName, showEventPageSectionHeader} =
      EVENTS_ROUTE_MAP[eventSection as EventPageSection]

    const venue = event.venue
    if (!venue && eventSection == EventPageSection.TABLES) return null
    if (!eventIsOver && eventSection == EventPageSection.GALLERY) return null
    if (
      !isEventKickbackEligible({isRSVPEvent: event.isRSVPEvent, country: fetchEventData.group.country}) &&
      eventSection == EventPageSection.KICKBACK
    )
      return null
    // TODO: Remove feature flag when Clik goes live
    if (!isDevEnv() && eventSection == EventPageSection.SERVICES) return null

    return (
      <Route
        path={route + '/*'}
        key={route}
        element={
          <EventPageWrapper
            section={eventSection as EventPageSection}
            event={event}
            action={action()}
            hideNavigation={isOnUpgradedVisualsPage}
            backgroundClassName={
              isOnUpgradedVisualsPage
                ? ' hidden'
                : pathname.includes('overview')
                ? 'Analytics'
                : // END TODO
                  backgroundClassName
            }
            showEventPageSectionHeader={showEventPageSectionHeader && !isOnUpgradedVisualsPage}>
            {requiredPermissions ? (
              <RequirePermissions requiredPermissions={requiredPermissions}>{content}</RequirePermissions>
            ) : (
              content
            )}
          </EventPageWrapper>
        }
      />
    )
  })

  const eventData = {
    ...fetchEventData,
    event: {
      ...event,
      isOver: now.isAfter(moment(event.endUtc)),
      createdAt: dateFromObjectId(event._id),
    },
  }

  return (
    <div style={{height: '100dvh', display: 'flex', flexDirection: 'column'}}>
      <PoshHeader desktopOnly />
      <EventManagementContextProvider eventData={eventData}>
        <Routes>
          <Route element={<EventPageLayout />}>
            {eventRoutes}
            <Route path={'*'} element={<PageNotFound />} />
          </Route>
        </Routes>
      </EventManagementContextProvider>
    </div>
  )
}
