import React from 'react'

import {OptimizelyDecideOption, useDecision} from '@optimizely/react-sdk'
import {EOptimizelyTests, ETicketsAvailableMessage} from '@posh/types'

export default function TicketScarcityExperiment({
  ticketsAvailableMessage,
  isLightMode,
  accentColor,
}: {
  ticketsAvailableMessage?: ETicketsAvailableMessage
  isLightMode: boolean
  accentColor: string
}) {
  const decideOptions =
    ticketsAvailableMessage === ETicketsAvailableMessage.MORE_THAN_100
      ? [OptimizelyDecideOption.DISABLE_DECISION_EVENT]
      : []
  const [decision, clientReady] = useDecision(EOptimizelyTests.TICKET_SCARCITY, {decideOptions}, {})
  const key = decision.variationKey
  const colorModeModifier = isLightMode ? 'is-lightMode' : ''

  // Don't render the component if SDK client is not ready yet.
  if (!clientReady) {
    return <></>
  }

  const getScarcityMessage = () => {
    switch (ticketsAvailableMessage) {
      case ETicketsAvailableMessage.LESS_THAN_100_MORE_THAN_50:
        switch (key) {
          case 'variation_1':
            return ''
          case 'variation_2':
          case 'variation_3':
            return 'Limited Tickets!'
          default:
            return ''
        }
      case ETicketsAvailableMessage.LESS_THAN_25:
        switch (key) {
          case 'variation_1':
          case 'variation_2':
            return 'Less Than 50 Tickets Remain!'
          case 'variation_3':
            return 'Less Than 25 Tickets Remain!'
          default:
            return ''
        }
      case ETicketsAvailableMessage.LESS_THAN_50_MORE_THAN_25:
        switch (key) {
          case 'variation_1':
          case 'variation_2':
            return 'Less Than 50 Tickets Remain!'
          case 'variation_3':
            return 'Limited Tickets'
          default:
            return ''
        }
      default:
        return ''
    }
  }
  return (
    <p className={'EventPage-limitedTicketsText ' + colorModeModifier} style={{color: `${accentColor}`, fontSize: 12}}>
      {getScarcityMessage()}
    </p>
  )
}
