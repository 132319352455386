import React from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'

import Button from '../Button'

import './styles.scss'

interface PaginationButtonsProps {
  page: number
  nextButtonDisabled: boolean
  backButtonDisabled: boolean
  handlePreviousPage: () => void
  handleNextPage: () => void
}

const PaginationButtons = (props: PaginationButtonsProps) => {
  const {page, nextButtonDisabled, backButtonDisabled, handlePreviousPage, handleNextPage} = props

  return (
    <>
      <div className='PaginationButtons-bottomArea'>
        <Button disabled={backButtonDisabled} onClick={handlePreviousPage} style={{padding: '10px'}} className='dark'>
          <PoshImage src='https://images.posh.vip/psh-assets/icons/left+(2).png' />
        </Button>
        <div className='PaginationButtons-bottomArea-pageNumber'>{page}</div>
        <Button disabled={nextButtonDisabled} onClick={handleNextPage} style={{padding: '10px'}} className='dark'>
          <PoshImage src='https://images.posh.vip/psh-assets/icons/right.png' />
        </Button>
      </div>
    </>
  )
}

export default PaginationButtons
