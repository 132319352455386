import React, {useLayoutEffect, useState} from 'react'

import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'

const ROOT_IMAGE_URL = 'https://images.posh.vip/login-background/'

function Fallback() {
  return <div className='LoginPage-background' style={{backgroundColor: 'white'}} />
}

export function LoginBackground() {
  const bgImages = [
    'bg-1.jpg',
    'bg-2.jpg',
    'bg-3.jpg',
    'bg-4.jpg',
    'bg-5.jpg',
    // 'bg-6.jpg', too dark
  ]
  const [imageIndex, setImageIndex] = useState<number>(0)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  useLayoutEffect(() => {
    setIsLoaded(false)

    const randomIndex = Math.floor(Math.random() * bgImages.length)
    setImageIndex(randomIndex)
  }, [])

  if (isError) return <Fallback />

  return (
    <PoshImage
      src={`${ROOT_IMAGE_URL}${bgImages[imageIndex]}`}
      alt='login-background'
      className={classNames('LoginPage-background', 'fade-in', {loaded: isLoaded})}
      onLoad={() => setIsLoaded(true)}
      onClick={() => setImageIndex((imageIndex + 1) % bgImages.length)}
      onError={() => setIsError(true)}
    />
  )
}
