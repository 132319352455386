import React from 'react'

import {PoshLogo} from 'components/PoshLogo'

import './styles.scss'

export const PoshBoatLoader = () => {
  return (
    <div className='PoshBoatLoader'>
      <PoshLogo.Animated infinite />
    </div>
  )
}
