import React from 'react'

import {Card} from 'components/Card'

const OrderCardComp = () => {
  return (
    <Card title={'Complimentary Order'} animationIndex={1} className='OrderPage-compCard'>
      <p>This order was created and sent to the attendee at no charge.</p>
    </Card>
  )
}

export default OrderCardComp
