import React from 'react'
import {Control, useController} from 'react-hook-form'

import {Building, LocationPin} from 'components/assets/Icons'
import {ToggleableEye} from 'components/assets/ToggleableEye'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useToast} from 'components/toasts/ToastProvider'

import {useEventVisualsContext} from '../../../context/EventVisualsContext'

const ICON_SIZE = 16

export function EventVisualsCombinedVenueAddressInput(props: {
  control: Control<EventVisualsForm>
  onChangeAddress?: (data: google.maps.places.PlaceResult) => void
}) {
  const {control, onChangeAddress} = props

  // Enforces that the user must select a location from the dropdown
  const [addressIsInError, setAddressIsInError] = React.useState(false)

  const {showToast} = useToast()

  const {
    palette: {lightmode, accentColor, textContrasting},
  } = useEventVisualsContext()

  const {
    field: {onChange: setAddressOnForm, value: addressOnForm},
  } = useController({
    control,
    name: 'venue.address',
  })

  const {
    field: {onChange: setLocationOnForm},
  } = useController({
    control,
    name: 'location.coordinates',
  })
  const {
    field: {onChange: setIsLocationHiddenUntilEventDate, value: isLocationHiddenUntilEventDate},
  } = useController({
    control,
    name: 'isLocationHiddenUntilEventDate',
  })

  const {
    field: {onChange: setCountryOnForm},
  } = useController({
    control,
    name: 'country',
  })

  const updateAddress = (data: google.maps.places.PlaceResult) => {
    setAddressOnForm(data.formatted_address)
    if (data.geometry) setLocationOnForm([data.geometry.location?.lng(), data.geometry.location?.lat()])
    if (data.address_components) {
      const country = data.address_components.find(component => component.types.includes('country'))
      if (country) setCountryOnForm(country.short_name)
    }
    if (onChangeAddress) onChangeAddress(data)
    // when the user selects a location from the dropdown, hide the error message
    setAddressIsInError(false)
  }

  return (
    <>
      <EventVisualsTextInput.Text.Controlled
        control={control}
        name='venue.name'
        placeholder='Venue Name'
        rules={{required: 'Venue name is required'}}
        lightMode={lightmode}
        accentColor={accentColor}
        icon={<Building height={ICON_SIZE} width={ICON_SIZE} />}
      />
      <EventVisualsTextInput.Location.Controlled
        control={control}
        name='venue.address'
        lightMode={lightmode}
        accentColor={accentColor}
        placeholder='Address'
        rules={{required: 'Address is required'}}
        googlePlacesInputOptions={{}}
        formatPlace={data => data.formatted_address ?? 'none'}
        onPlaceSelected={updateAddress}
        icon={<LocationPin height={ICON_SIZE} width={ICON_SIZE} />}
        rightIcon={
          addressOnForm ? (
            <ToggleableEye
              size={ICON_SIZE}
              visible={!isLocationHiddenUntilEventDate}
              color={textContrasting}
              onClick={e => {
                e.stopPropagation()
                const isSettingCurrentlyEnabled = isLocationHiddenUntilEventDate
                setIsLocationHiddenUntilEventDate(!isSettingCurrentlyEnabled)
                if (!isSettingCurrentlyEnabled) {
                  showToast({type: 'info', title: 'The event location will be hidden until the day of the event'})
                }
              }}
            />
          ) : null
        }
        onChange={() => setAddressIsInError(true)} // when the user types in the address input, show the error message
      />
      {addressIsInError && <span style={{color: 'red'}}>Please pick a location from the dropdown</span>}
    </>
  )
}
