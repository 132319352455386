import React, {useEffect, useState} from 'react'

import Select from 'components/form/Select'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

export type SelectorInputCheckoutProps = {
  field_key: string
  value?: string
  field_type: 'dropdown'
  placeholder: string
  name: string
  options: string[]
}

export const SelectorInputCheckout = (props: SelectorInputCheckoutProps) => {
  const {value: initialValue, field_key, name, placeholder, options} = props
  const {updateCheckoutResponses, checkoutResponses} = useLiveCartContext()
  const [value, setValue] = useState<string>(initialValue ?? '')

  useEffect(() => {
    if (!initialValue) return
    if (checkoutResponses) {
      const newCheckoutResponses = checkoutResponses.map(response => {
        if (response.fieldId.toString() === field_key) {
          return {...response, answer: initialValue}
        }
        return response
      })
      updateCheckoutResponses(newCheckoutResponses)
    }
  }, [])

  const onChange = (e: React.ChangeEvent<HTMLSelectElement> | undefined) => {
    if (e) {
      if (checkoutResponses) {
        const newCheckoutResponses = checkoutResponses.map(response => {
          if (response.fieldId.toString() === field_key) {
            return {...response, answer: e.target.value}
          }
          return response
        })
        updateCheckoutResponses(newCheckoutResponses)
      }
      setValue(e.target.value)
    }
  }
  return (
    <Select name={name} value={value} onChange={onChange}>
      <option value={''} disabled>
        {placeholder}
      </option>
      {options.map((value, index) => (
        <option key={index} value={value}>
          {value}
        </option>
      ))}
    </Select>
  )
}
