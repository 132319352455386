import {CountryCode} from '@posh/types'

export const isEventKickbackEligible = ({
  isRSVPEvent,
  country,
}: {
  isRSVPEvent: boolean | undefined
  country: CountryCode | undefined
}) => {
  if (isRSVPEvent === undefined || country === undefined) return false
  return !isRSVPEvent && country === 'US'
}
