import React, {useState} from 'react'

import CheckboxGroup from 'components/form/CheckboxGroup/CheckboxGroup'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

export type CheckboxesInputCheckoutProps = {
  field_key: string
  value?: string[]
  field_type: 'checkboxes'
  placeholder: string
  options: string[]
}

export const CheckboxesInputCheckout = (props: CheckboxesInputCheckoutProps) => {
  const {value: initialValue, field_key, placeholder, options} = props
  const {checkoutResponses, updateCheckoutResponses} = useLiveCartContext()
  const [value, setValue] = useState<string[]>(initialValue ?? [])

  const onChange = (options: string[]) => {
    const newCheckoutResponses = checkoutResponses?.map(response => {
      if (response.fieldId.toString() === field_key) {
        return {...response, answers: options}
      }
      return response
    })
    updateCheckoutResponses(newCheckoutResponses)
    setValue(options)
  }

  return (
    <CheckboxGroup
      label={placeholder}
      options={options.map(o => ({label: o, value: o}))}
      defaultChecked={value}
      onOptionsChecked={onChange}
      multiSelect
      inCheckout
    />
  )
}
