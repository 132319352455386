import React from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'

const SocialLinks = () => {
  return (
    <div className='lp23sociallinks'>
      <a href='https://instagram.com/posh.vip' target='_blank' rel='noreferrer'>
        <PoshImage src='https://images.posh.vip/b2/instagram-lp-white.svg' />
      </a>
      <a href='https://twitter.com/poshvip_' target='_blank' rel='noreferrer'>
        <PoshImage src='https://images.posh.vip/b2/twitter-lp-white.svg' />
      </a>
      <a href='https://www.linkedin.com/company/poshgroupnyc' target='_blank' rel='noreferrer'>
        <PoshImage src='https://images.posh.vip/b2/lpl45.png' />
      </a>
    </div>
  )
}

export default SocialLinks
