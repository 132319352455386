import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {EventFee} from './useGetGroupForFinancials'

export interface UpdateGroupCustomFeeProps {
  fee: string
  groupId: string
  name: string
  amount: number
  type: EventFee['type']
}

async function updateGroupCustomFee(props: UpdateGroupCustomFeeProps) {
  const response = await PoshApi.updateGroupCustomFee(props)
  return response.data
}

export default function useUpdateGroupCustomFee() {
  return useMutation((props: UpdateGroupCustomFeeProps) => updateGroupCustomFee(props))
}
