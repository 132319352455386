import React, {ButtonHTMLAttributes} from 'react'
import {PropsWithChildren} from 'react'

const CopyBlockAction = ({children, onClick}: PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>) => {
  return (
    <button onClick={onClick} className='CopyBlockAction'>
      {children}
    </button>
  )
}

export default CopyBlockAction
