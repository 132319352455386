export interface UploadFileProps {
  file: File
  s3Key: string
  progressCallback: (progress: {loaded: number; total: number}) => void
  errorCallback: (error: string) => void
  completeCallback: () => void
  metadata: {[key: string]: string}
  presignedUrl: string
}

export async function xmlUploadFileToS3(
  presignedUrl: string,
  file: File,
  progressCallback: UploadFileProps['progressCallback'],
) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.upload.addEventListener('progress', event => {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total)
        console.log(`Upload progress: ${percentCompleted}%`)
        progressCallback({loaded: event.loaded, total: event.total})
      }
    })

    xhr.open('PUT', presignedUrl, true)

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      } else {
        console.log({erroResponse: xhr.response})
        reject(new Error('Upload failed'))
      }
    }

    xhr.onerror = () => {
      console.log({erroResponse: xhr.response})
      reject(new Error('Network error'))
    }

    xhr.send(file)
  })
}
