import React, {useState} from 'react'

import {EventModel, IPaymentPlan} from 'apis/Events/types'
import {UpdateEventAttributes_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'

import Input from '../../../../components/form/Input'
import PoshDatePicker from '../../../../components/form/PoshDatePicker'
import EventSetting from '../EventSetting'

interface Props {
  event: EventModel
  updateEventAttributes: UpdateEventAttributes_DEPRECATED
  setUpdateEventAttributes: React.Dispatch<React.SetStateAction<UpdateEventAttributes_DEPRECATED>>
}

const PaymentPlanSettings = (props: Props) => {
  const {event, updateEventAttributes, setUpdateEventAttributes} = props
  const currentPaymentPlan = updateEventAttributes.paymentPlans
    ? updateEventAttributes.paymentPlans[0]
    : event.paymentPlans![0]

  const defaultLastInstallmentDate =
    currentPaymentPlan?.lastInstallmentDate?.toString() ??
    (updateEventAttributes?.paymentPlans
      ? updateEventAttributes?.paymentPlans[0].lastInstallmentDate.toString()
      : undefined)

  const [lastInstallmentDate, setLastInstallmentDate] = useState<string | undefined>(defaultLastInstallmentDate)

  const updatePlanValue = (plan: IPaymentPlan) => {
    setUpdateEventAttributes({
      ...updateEventAttributes,
      paymentPlans: [plan],
    })
  }

  return (
    <>
      <EventSetting
        title='Last Installment Date'
        info='This is the last day payments will be made, this day should be before the event starts'>
        <PoshDatePicker
          noHours={true}
          value={lastInstallmentDate}
          setDates={(localDate, utcDate) => {
            setLastInstallmentDate(localDate)
            const plan: IPaymentPlan = {
              ...currentPaymentPlan,
              lastInstallmentDate: new Date(localDate),
              lastInstallmentDateUtc: new Date(utcDate),
            }
            updatePlanValue(plan)
          }}
          timezone={event.timezone ?? 'America/New_York'}
        />
      </EventSetting>
      <EventSetting
        title='Minimum Order Price'
        info='This is the minimum price for orders when payment plan options are displayed'>
        <Input
          className='small'
          type='number'
          value={currentPaymentPlan?.minimumOrderPrice ?? undefined}
          onChange={e => {
            const price = Number(e.target.value)
            const plan: IPaymentPlan = {
              ...currentPaymentPlan,
              minimumOrderPrice: price,
            }
            updatePlanValue(plan)
          }}
        />
      </EventSetting>
    </>
  )
}

export default PaymentPlanSettings
