import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetEventForVisualEditorOptions = ReactQueryOptions['events']['getEventForVisualEditor']
type GetEventForVisualEditorInput = RouterInputs['events']['getEventForVisualEditor']
export type GetEventForVisualEditorOutput = RouterOutput['events']['getEventForVisualEditor']
export type GuestListPreview = GetEventForVisualEditorOutput['guestlistPreview']

export function useGetEventForVisualEditor(input: GetEventForVisualEditorInput, opts?: GetEventForVisualEditorOptions) {
  return trpc.events.getEventForVisualEditor.useQuery(input, opts)
}
