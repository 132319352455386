import React, {PropsWithChildren, useMemo, useState} from 'react'

import {Ticket} from 'apis/Events/types'

import {CartItem, CartItemType} from '../../apis/Carts'
import {CartContext} from './CartContext'

export interface TicketCount {
  quantity: number
  data: Ticket
}

export interface ICart {
  tickets: TicketsCountState
  tables: string[]
}

export interface TicketsCountState {
  [ticketId: string]: TicketCount
}

export function transformTicketsCountToCartItems(ticketsCount: TicketsCountState): CartItem[] {
  return Object.keys(ticketsCount).map(ticketId => {
    const ticketCount = ticketsCount[ticketId]
    return {
      resourceId: ticketId,
      resourceType: 'ticket' as CartItemType,
      quantity: ticketCount.quantity,
    }
  })
}

export function transformTableIdsToCartItems(tableIds: string[]): CartItem[] {
  return tableIds.map(tableId => ({
    resourceId: tableId,
    resourceType: 'table' as CartItemType,
    quantity: 1,
  }))
}

export default function CartContextProvider(props: PropsWithChildren<Record<never, never>>) {
  const [ticketsCount, setTicketsCount] = useState<TicketsCountState>({})
  const [tables, setTables] = useState<Array<string>>([])
  const [promoCode, setPromoCode] = useState('')
  const [trackingLink, setTrackingLink] = useState('')
  const [paymentPlanIndex, setPaymentPlanIndex] = useState(2)

  const cartItems: CartItem[] = useMemo(() => {
    const ticketCartItems: CartItem[] = transformTicketsCountToCartItems(ticketsCount)

    const tableCartItems: CartItem[] = transformTableIdsToCartItems(tables)
    return [...ticketCartItems, ...tableCartItems]
  }, [ticketsCount, tables])

  const resetCart = () => {
    setTicketsCount({})
    setTables([])
    setPromoCode('')
    setTrackingLink('')
    setPaymentPlanIndex(2)
  }

  const addTable = (table: string) => {
    setTables([...tables, table])
  }

  const addTables = (tablesToAdd: string[]) => {
    setTables([...tables, ...tablesToAdd])
  }

  const removeTable = (table: string) => {
    const newTables = tables.filter(tab => tab !== table)
    setTables(newTables)
  }

  const addTicket = (ticket: Ticket) => {
    let quantity = 0

    if (ticketsCount[ticket.id]) {
      if (ticket.purchaseMin && ticketsCount[ticket.id].quantity == 0) quantity = ticket.purchaseMin
      else if (ticket.purchaseMin && ticket.sellInMultiples)
        quantity = ticketsCount[ticket.id].quantity + ticket.purchaseMin
      else quantity = ticketsCount[ticket.id].quantity + 1
    } else if (ticket.purchaseMin) quantity = ticket.purchaseMin
    else quantity = 1

    setTicketsCount({
      ...ticketsCount,
      [ticket.id]: {
        data: ticket,
        quantity: quantity,
      },
    })
  }

  const removeTicket = (ticket: Ticket) => {
    let quantity = 0

    if (ticketsCount[ticket.id]) {
      if (ticket.purchaseMin == ticketsCount[ticket.id].quantity) quantity = 0
      else if (ticket.purchaseMin && ticket.sellInMultiples)
        quantity = ticketsCount[ticket.id].quantity - ticket.purchaseMin
      else quantity = ticketsCount[ticket.id].quantity - 1
    } else quantity = 1

    setTicketsCount({
      ...ticketsCount,
      [ticket.id]: {
        data: ticket,
        quantity: quantity,
      },
    })
  }

  const handleSetTicketCount = (ticket: Ticket, quantity: number) =>
    setTicketsCount({
      ...ticketsCount,
      [ticket.id]: {
        data: ticket,
        quantity: quantity ? quantity : 0,
      },
    })

  const state = useMemo(() => {
    return {
      ticketsCount,
      promoCode,
      setPromoCode,
      addTicket,
      removeTicket,
      handleSetTicketCount,
      trackingLink,
      setTrackingLink,
      addTable,
      addTables,
      tables,
      removeTable,
      paymentPlanIndex,
      setPaymentPlanIndex,
      cartItems,
      resetCart,
    }
  }, [
    ticketsCount,
    promoCode,
    addTicket,
    removeTicket,
    handleSetTicketCount,
    trackingLink,
    tables,
    removeTable,
    paymentPlanIndex,
    cartItems,
    resetCart,
  ])

  return <CartContext.Provider value={state}>{props.children}</CartContext.Provider>
}
