import useSessionContext from 'domains/Auth/SessionContext'
import {ReactQueryOptions, RouterOutput, trpc} from 'lib/trpc'

import {setSessionContext} from './setSessionContext'

type LoginOptions = ReactQueryOptions['auth']['login']
type LoginWithEmailOutputs = RouterOutput['auth']['login']
export type LoginWithEmailOutput = Extract<LoginWithEmailOutputs, {platform: 'web'}>
export type AuthedAccount = LoginWithEmailOutput['account']

export const useLoginWithEmail = (opts?: LoginOptions) => {
  const sessionContext = useSessionContext()
  return trpc.auth.login.useMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      setSessionContext(data as LoginWithEmailOutput, sessionContext)
      opts?.onSuccess?.(data, variables, context)
    },
  })
}
