import React from 'react'

import classNames from 'classnames'

import styles from './TimePeriodOption.module.scss'

interface TimePeriodOptionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  highlighted?: boolean
}

export const TimePeriodOption = ({children, highlighted, ...htmlProps}: TimePeriodOptionProps) => {
  return (
    <div
      className={classNames(styles.TimePeriodOption, {
        [styles.highlighted]: highlighted,
      })}
      {...htmlProps}>
      {children}
    </div>
  )
}
