import React from 'react'

import useFetchAdminTopFree from 'apis/AdminDashboard/useFetchAdminTopFree'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

interface TopFreeRowProps {
  event: any
}

const TopFreeRow = (props: TopFreeRowProps) => {
  const {event} = props
  return (
    <tr>
      <td>
        <a href={`https://posh.vip/e/${event.url}`} target='_blank' rel='noreferrer'>
          <div className='AdminDashboard-event'>{event.name}</div>
        </a>
      </td>
      <td>{event.start}</td>
      <td>{event.attending.length}</td>
      <td>${event.rev.toFixed(2)}</td>
    </tr>
  )
}

const TopFreeDashboard = () => {
  const {data: data, isFetching: isLoading} = useFetchAdminTopFree()
  return (
    <>
      <BackAndTitle title={'Top Upcoming Free Events'} />
      {isLoading && !data ? (
        <SpinLoader height={40} />
      ) : (
        <table>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Start Date</th>
              <th>Attending</th>
              <th>Revenue</th>
            </tr>
            {data && data.length > 0 && data.map((row, index) => <TopFreeRow key={index} event={row} />)}
          </tbody>
        </table>
      )}
    </>
  )
}

export default TopFreeDashboard
