import {useParams} from 'react-router-dom'

type GroupMatchParams = Record<'groupId' | 'blastId' | 'domain' | 'category' | 'staffGroup', string>

export const useGroupMatchParams = () => {
  return useParams<GroupMatchParams>()
}

export const useGroupBaseUrl = () => {
  const {groupId, domain} = useGroupMatchParams()
  return `/${domain}/groups/${groupId}`
}

export const useGroupSearchParams = () => {
  const {groupId} = useParams()
  if (typeof groupId !== 'string') throw new Error('Invalid groupId in search params')
  return {groupId}
}
