import React, {PropsWithChildren} from 'react'

const SectionHeader = ({text}: {text: string}) => {
  return <h3 className='ApprovalModal-Content-Section-Header grey-light'>{text.toUpperCase()}</h3>
}

const Section = ({name, children}: PropsWithChildren<{name: string}>) => {
  return (
    <div className='ApprovalModal-Content-Section'>
      <SectionHeader text={name} />
      {children}
    </div>
  )
}
export default Section
