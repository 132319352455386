import {UseFormWatch} from 'react-hook-form'

import {UpdateEventInput} from 'apis/Events/useUpdateEvent'
import {CreateEventMixpanelParams} from 'apis/mixpanel-events/createEventMixpanelEvent'
import {UpdatePendingEventInput} from 'apis/PendingEvent/useUpdatePendingEvent'

export type CreateEventForm = Partial<UpdatePendingEventInput> & {_id: string}
export type EventVisualsForm = CreateEventForm | Required<UpdateEventInput>
export type CreateEventTicket = NonNullable<CreateEventForm['tickets']>[0]

export const getEventVisualsAttributes = (watch: UseFormWatch<EventVisualsForm>) => {
  const [flyer, lightmode, accentColor, country, eventTitleFont, parentCategory, subCategory, tickets, contactInfo] =
    watch([
      'flyer',
      'lightmode',
      'accentColor',
      'country',
      'eventTitleFont',
      'parentCategory',
      'subCategory',
      'tickets',
      'contactInfo',
    ])

  const hasFilledNonControlledFields = !!flyer

  return {
    flyer,
    lightmode,
    accentColor,
    country,
    eventTitleFont,
    parentCategory,
    subCategory,
    tickets,
    contactInfo,
    hasFilledNonControlledFields,
  }
}

export const getCreateEventMixpanelParams = (
  pendingEvent: CreateEventForm,
  isAuthed: boolean,
): CreateEventMixpanelParams => {
  const {
    flyer,
    name,
    startUtc,
    endUtc,
    location,
    address,
    parentCategory,
    subCategory,
    contactInfo,
    song,
    shortDescription,
    guestlistEnabled,
    attendanceDisplayDisabled,
    activityEnabled,
    displayOnThirdPartySites,
    tickets,
    venue,
    youtubeLink,
    lineup,
    description,
  } = pendingEvent
  const hasCategory = parentCategory && subCategory

  return {
    flyerExists: !!flyer,
    nameExists: !!name,
    startDateExists: !!startUtc,
    endDateExists: !!endUtc,
    locationExists: !!location,
    addressExists: !!address,
    eventCategory: hasCategory ? parentCategory + ' - ' + subCategory : undefined,
    emailOrPhoneExists: !!contactInfo?.email || !!contactInfo?.phone,
    isAuthed,
    spotifySongExists: !!song?.previewLink,
    shortDescriptionCharacterCount: shortDescription?.length,
    activityEnabled: activityEnabled || false,
    guestlistEnabled: guestlistEnabled || false,
    numberOfGuestsVisible: !attendanceDisplayDisabled,
    marketPlaceEnabled: displayOnThirdPartySites || false,
    numberOfTickets: tickets?.length || 0,
    isFreeEvent: tickets?.every(ticket => ticket.price === 0) || false,
    photoGalleryExists: !!venue?.images?.length,
    youtubeVideoExists: !!youtubeLink,
    lineupExists: !!lineup?.length,
    descriptionExists: !!description,
  }
}
