import React, {PropsWithChildren} from 'react'
import {useNavigate} from 'react-router-dom'

import useCheckPoshAdmin from '../../../apis/AdminDashboard/useCheckPoshAdmin'

// Local Implementation until we refactor teams, works only for admin permissions
function LocalRequireAdminPermissions({
  children,
  permission,
  accountRoleEnabledPermissions,
}: PropsWithChildren<{permission: string; accountRoleEnabledPermissions: string[]}>) {
  if (!accountRoleEnabledPermissions.includes(permission)) {
    return null
  } else {
    return <>{children}</>
  }
}

interface AdminDashboardItem {
  navigationTitle: string
  navigationUrl: string
  requiredPermission: string
}

const ADMIN_DASHBOARD_ITEMS: AdminDashboardItem[] = [
  {navigationTitle: 'Fraud Prevention', navigationUrl: '/admin/fraud/', requiredPermission: 'admin_fraud_tools'},
  {
    navigationTitle: 'Top Upcoming Free Events',
    navigationUrl: '/admin/top-free',
    requiredPermission: 'admin_event_analytics',
  },
  {navigationTitle: 'Recent Text Blasts', navigationUrl: '/admin/textblasts', requiredPermission: 'admin_text_blasts'},
  {navigationTitle: 'Posh Community', navigationUrl: '/admin/community/approve', requiredPermission: 'admin_community'},
  {
    navigationTitle: 'Campaign Links',
    navigationUrl: '/admin/campaign-links',
    requiredPermission: 'admin_campaign_links',
  },
  {
    navigationTitle: 'KPI Dashboard',
    navigationUrl: '/admin/kpi',
    requiredPermission: 'admin_kpi_dashboard',
  },
  {navigationTitle: 'Posh Accounts', navigationUrl: '/admin/accounts', requiredPermission: 'admin_accounts'},
  {navigationTitle: 'Posh Events', navigationUrl: '/admin/events', requiredPermission: 'admin_events'},
  {navigationTitle: 'Posh Orgs', navigationUrl: '/admin/orgs', requiredPermission: 'admin_orgs'},
  {
    navigationTitle: 'Posh Connect File Upload',
    navigationUrl: '/admin/connect-verification',
    requiredPermission: 'admin_identity_upload',
  },
  {
    navigationTitle: 'Posh Refunds',
    navigationUrl: '/admin/refund-requests',
    requiredPermission: 'admin_fraud_tools',
  },
]

const AdminDashboard = () => {
  const navigate = useNavigate()

  const {data: poshAdmin, isFetched: shouldShowAdminDashboard} = useCheckPoshAdmin()
  const poshAdminEnabledPermissions = poshAdmin?.enabledPermissions ?? []

  return (
    <>
      <h2>Posh Admin Dashboard</h2>
      {shouldShowAdminDashboard && (
        <div className='AdminDashboard-menu'>
          {ADMIN_DASHBOARD_ITEMS.map(({navigationTitle, navigationUrl, requiredPermission}, i) => (
            <LocalRequireAdminPermissions
              permission={requiredPermission}
              accountRoleEnabledPermissions={poshAdminEnabledPermissions}
              key={i}>
              <div onClick={() => navigate(navigationUrl)}>{navigationTitle}</div>
            </LocalRequireAdminPermissions>
          ))}
        </div>
      )}
    </>
  )
}

export default AdminDashboard
