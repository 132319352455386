import {browserNavigate} from 'apis/NavigationHelper'
import useSessionContext from 'domains/Auth/SessionContext'

export default function useLogout() {
  const {unauthenticateSession} = useSessionContext()
  const currentPath = window.location.pathname
  const pathsToAvoidRedirect = ['/e/', '/g/', '/p/', '/f/']
  const shouldRedirect = pathsToAvoidRedirect.every(path => !currentPath.startsWith(path))

  return () => {
    unauthenticateSession()
    if (shouldRedirect) {
      // We want to fully reload the page to ensure that the user reaches cloudfront to get any needed redirects (like the landing page)
      browserNavigate('/')
    }
  }
}
