import React, {ReactNode, useState} from 'react'
import {flushSync} from 'react-dom'

import {StagedUserFlowContext} from './StagedUserFlowContext'

import './StagedUserFlow.styles.scss'

export const StagedUserFlow = ({stages}: {stages: ReactNode[]}) => {
  const [stage, setStage] = useState<number>(0)

  const viewTransitionWrapper = (func: () => void) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const browserSupportsViewTransitions = document.startViewTransition
    if (browserSupportsViewTransitions) {
      flushSync(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.startViewTransition(() => {
          func()
        })
      })
    } else {
      func()
    }
  }

  const nextStage = () => {
    if (stage < stages.length - 1) {
      viewTransitionWrapper(() => setStage(prevStage => prevStage + 1))
    }
  }

  const previousStage = () => {
    if (stage > 0) {
      viewTransitionWrapper(() => setStage(prevStage => prevStage - 1))
    }
  }

  const activeStage = (
    <div className='StagedUserFlow' style={{viewTransitionName: 'stage-transition'}}>
      {stages[stage]}
    </div>
  )

  return (
    <StagedUserFlowContext.Provider value={{stage, nextStage, previousStage}}>
      {activeStage}
    </StagedUserFlowContext.Provider>
  )
}
