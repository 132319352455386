import React from 'react'

import useFetchAdminTextBlasts from 'apis/AdminDashboard/useFetchAdminTextBlasts'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

interface TextBlastRowProps {
  blast: any
}

const TextBlastRow = (props: TextBlastRowProps) => {
  const {blast} = props

  return (
    <tr>
      <td>{blast.groupName}</td>
      <td>{blast.sentTime}</td>
      <td>{blast.message}</td>
      <td>{blast.recipients?.length}</td>
      <td>{blast.delivered}</td>
      <td>{(blast.rate * 100).toFixed(0)}%</td>
    </tr>
  )
}

const TextBlastsDashboard = () => {
  const {data: data, isFetching: isLoading} = useFetchAdminTextBlasts()
  return (
    <>
      <BackAndTitle title={'Recent Text Blasts'} />
      {isLoading && !data ? (
        <SpinLoader height={40} />
      ) : (
        <table>
          <tbody>
            <tr>
              <th>Group Name</th>
              <th>Sent Datetime</th>
              <th style={{width: '450px'}}>Message</th>
              <th>Attempted Recipients</th>
              <th>Delivered</th>
              <th>Delivery Rate</th>
            </tr>
            {data && data.length > 0 && data.map((textblast, index) => <TextBlastRow key={index} blast={textblast} />)}
          </tbody>
        </table>
      )}
    </>
  )
}

export default TextBlastsDashboard
