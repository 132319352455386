import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import AllEvents from '../AllEvents'
import Overview from '../Overview'

const OverviewHandler = () => {
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)

  return (
    <>
      <PoshHelmet title={`Overview - ${group?.name}`} />
      <Routes>
        <Route path='' element={<Overview />} />
        <Route path={'all-events'} element={<AllEvents />} />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default OverviewHandler
