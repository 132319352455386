import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {TicketLink} from './types'

export interface CreateTicketLinkResponse {
  created: TicketLink
}
export interface CreateTicketLinkParams {
  parentTicketId: string
  childTicketId: string
  openOrShow: string
  displayCondition: string
  eventId: string
}

async function createTicketLink(data: CreateTicketLinkParams): Promise<CreateTicketLinkResponse> {
  const response = await PoshApi.createTicketLink(data)
  return response.data
}

export function useCreateTicketLink(opts?: UseMutationOptions<CreateTicketLinkResponse, any, CreateTicketLinkParams>) {
  return useMutation((params: CreateTicketLinkParams) => createTicketLink(params), opts)
}
