import React from 'react'

import {Ticket} from 'apis/Events/types'
import Multiselect from 'multiselect-react-dropdown'

import MatchAllInput from './matchAllInput'
import multiSelectStyles from './multi-select-styles'

import './styles.scss'

interface TicketsPurchasedFilters {
  tickets: Ticket[]
  selectedTickets: string[]
  setTickets: React.Dispatch<React.SetStateAction<string[]>>
  matchAllTickets: boolean
  setMatchAllTickets: React.Dispatch<React.SetStateAction<boolean>>
}
interface SelectOption {
  name: string
  id: string
}

const TicketsPurchasedFilters = (props: TicketsPurchasedFilters) => {
  const {tickets, selectedTickets, setTickets, matchAllTickets, setMatchAllTickets} = props

  const preSelectedTickets: SelectOption[] = []
  selectedTickets?.map(selectedTicket => {
    const ticket = tickets.find(ticket => ticket.id == selectedTicket)
    if (ticket) preSelectedTickets.push({name: ticket.name, id: ticket.id})
  })

  const handleUpdate = (value: SelectOption[]) => {
    const ticketIds = value.map(ticket => {
      return ticket.id
    })
    setTickets(ticketIds)
  }
  const options: SelectOption[] = tickets.map(ticket => {
    return {name: ticket.name, id: ticket.id}
  })

  return (
    <div className='DataFiltersSection'>
      {tickets && tickets.length > 0 ? (
        <>
          <div className='FilterModal-topRow'>
            <h6>Filter By Tickets Purchased</h6>
            <MatchAllInput setMatchAll={setMatchAllTickets} matchAll={matchAllTickets} />
          </div>
          <Multiselect
            selectedValues={preSelectedTickets}
            options={options} // Options to display in the dropdown
            onSelect={value => handleUpdate(value)} // Function will trigger on select event
            onRemove={value => handleUpdate(value)} // Function will trigger on remove event
            displayValue='name' // Property name to display in the dropdown options
            style={multiSelectStyles}
            closeOnSelect={false}
            placeholder={'Select Tickets'}
          />
        </>
      ) : (
        <p>Create tickets for your event to start filtering by them!</p>
      )}
    </div>
  )
}

export default TicketsPurchasedFilters
