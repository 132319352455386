import {useMutation} from '@tanstack/react-query'
import {LongLat} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

import {GalleryImage, StaffType} from '.'

export interface CreateStaffMember {
  staffType: StaffType
  location: string
  website?: string
  instagram?: string
  hourlyRate?: number
  yearsOfExperience?: number
  coordinates: LongLat
  galleryImages: GalleryImage[]
  avi: string
  minimumPrice?: number
}

async function createStaffMember(memberData: CreateStaffMember) {
  const response = await PoshApi.createStaffMember(memberData)
  return response.data
}

export default function useCreateStaffMember() {
  return useMutation((params: CreateStaffMember) => createStaffMember(params))
}
