import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export async function deleteEventTable(eventId: string, tableId: string) {
  const response = await PoshApi.removeEventTable(eventId, tableId)
  return response.data
}

export default function useDeleteEventTable() {
  return useMutation((params: {eventId: string; tableId: string}) => deleteEventTable(params.eventId, params.tableId))
}
