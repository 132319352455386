import React, {useState} from 'react'

import {OpenToOtherDatesChoice} from 'apis/Community'
import useCreateBookingRequest, {CreateBookingRequestData} from 'apis/Community/useCreateBookingRequest'
import {useMixpanel} from 'apis/MixPanelHandler'
import PoshDatePicker from 'components/form/PoshDatePicker'
import PoshLocationInput from 'components/form/PoshLocationInput'
import TextField, {TextFieldVariants} from 'components/form/TextField'
import useSessionContext from 'domains/Auth/SessionContext'
import moment from 'moment'
import {getDate} from 'pages/CommunityDashboard/dashboardHelpers'

import {DetailEntity} from '..'

interface EntityBookingRequestProps {
  selectedDetailEntity: DetailEntity
  bookingDetails: CreateBookingRequestData
  setBookingDetails: (bookingDetails: CreateBookingRequestData) => void
  setSuccessfulRequestMade: React.Dispatch<React.SetStateAction<boolean>>
  handleSignUpRedirect: () => void
}

interface ErrorMessage {
  [key: string]: string
}

const EntityBookingRequest = (props: EntityBookingRequestProps) => {
  const {selectedDetailEntity, bookingDetails, setBookingDetails, setSuccessfulRequestMade, handleSignUpRedirect} =
    props
  const {userId, currentUser} = useSessionContext()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {mutateAsync: createBookingRequest} = useCreateBookingRequest()

  const [timezone, setTimezone] = useState<string | undefined>(moment.tz.guess())
  const [location, setLocation] = useState<null | {type: 'Point'; coordinates: [number, number]}>(null)
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({})

  const fieldValidator = (fields: CreateBookingRequestData) => {
    const errorState: ErrorMessage = {}

    if (!fields.location) {
      if (bookingDetails.communityCategory === 'venue') {
        errorState.location = 'Event Details are required'
      } else {
        errorState['location'] = 'Location is required.'
      }
    }

    if (fields.startTime && fields.endTime && fields.startTime >= fields.endTime) {
      errorState['startTime'] = 'Start time must be before end time.'
    }

    if (!fields.message) {
      if (bookingDetails.communityCategory === 'artist') {
        errorState['message'] = 'Extra notes are required.'
      } else {
        errorState['message'] = 'Message is required.'
      }
    }

    if (fields.message.length > 500) {
      errorState['message'] = 'Message cannot exceed 500 characters.'
    }

    if (bookingDetails.communityCategory === 'artist') {
      if (!fields.organizationName) {
        errorState['organizationName'] = 'Organization name is required.'
      }
      if (!fields.otherDates) {
        errorState['otherDates'] = 'Other date availability is required.'
      }
      if (!fields.venueName) {
        errorState['venueName'] = 'Name of venue is required.'
      }
      if (!fields.venueCapacity) {
        errorState['venueCapacity'] = 'Capacity of venue is required.'
      }
      if (!fields.averageTicketPrice) {
        errorState['averageTicketPrice'] = 'Average ticket price is required.'
      }
      if (!fields.approximateBudget) {
        errorState['approximateBudget'] = 'Approximate artist budget is required.'
      }
    }

    const isEmpty = Object.values(errorState).every(value => value === '')

    if (!isEmpty) {
      setErrorMessage(errorState)
      return false
    }
    return true
  }

  const handleBookingRequest = async () => {
    const fieldsAreValid = fieldValidator(bookingDetails)
    try {
      if (fieldsAreValid) {
        setErrorMessage({})
        const response = await createBookingRequest(bookingDetails)
        const accountName = currentUser?.firstName + ' ' + currentUser?.lastName
        const accountEmail = currentUser?.email ?? ''
        const accountId = currentUser?._id ?? ''
        const communityName = selectedDetailEntity.title ?? ''
        const communityCategory = selectedDetailEntity.communityCategory ?? ''
        trackMixpanelEvent('Community Booking Creation', {
          accountName,
          accountEmail,
          accountId,
          communityName,
          communityCategory,
        })
        if (response.message) setSuccessfulRequestMade(true)
      } else {
        return
      }
    } catch (error: any) {
      if (error.response.data.error) {
        setErrorMessage({booking: error.response.data.error})
      } else {
        setErrorMessage({booking: 'Something went wrong. Please try again later.'})
      }
    }
  }

  return (
    <div className='cDetailsModal-contact'>
      <h3 style={{color: 'white'}}>Book {selectedDetailEntity.title}</h3>
      {bookingDetails.communityCategory === 'venue' && (
        <select onChange={e => setBookingDetails({...bookingDetails, location: e.target.value})}>
          <option value={'General Party'}>General Party</option>
          <option value={'Corporate Event'}>Corporate Event</option>
          <option value={'Birthday Party'}>Birthday Party</option>
          <option value={'Band'}>Band</option>
          <option value={'Fundraiser'}>Fundraiser</option>
          <option value={'Greek Life'}>Greek Life</option>
        </select>
      )}
      {bookingDetails.communityCategory !== 'venue' && (
        <PoshLocationInput
          placeholder='Location/Address'
          address={bookingDetails.location ?? ''}
          className='CreateEvent-form-content-innerForm-locationInput'
          onPlaceSelected={place => {
            const {address, location} = place
            setLocation(location)
            setBookingDetails({...bookingDetails, location: address})
          }}
        />
      )}
      {errorMessage.location && <span>{errorMessage.location}</span>}
      <PoshDatePicker
        name={'Start Time'}
        value={bookingDetails.startTime && getDate(bookingDetails.startTime)}
        setDates={(localDate, utcDate: any) => {
          setBookingDetails({...bookingDetails, startTime: utcDate, bookingDate: utcDate})
        }}
        timezone={timezone!}
      />
      {errorMessage.startTime && <span>{errorMessage.startTime}</span>}
      <PoshDatePicker
        name={'End Time'}
        value={bookingDetails.endTime && getDate(bookingDetails.endTime)}
        setDates={(localDate, utcDate: any) => {
          setBookingDetails({...bookingDetails, endTime: utcDate})
        }}
        timezone={timezone!}
      />
      {errorMessage.endTime && <span>{errorMessage.endTime}</span>}
      {bookingDetails.communityCategory === 'artist' && (
        <>
          <select
            onChange={e =>
              setBookingDetails({
                ...bookingDetails,
                otherDates: e.target.value.toLowerCase() as OpenToOtherDatesChoice,
              })
            }
            className='cDetailsModal-otherDates'>
            <option selected disabled>
              Are you open to other dates?
            </option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </select>

          {errorMessage.otherDates && <span>{errorMessage.otherDates}</span>}

          <TextField
            value={bookingDetails.organizationName}
            onChange={e => setBookingDetails({...bookingDetails, organizationName: e.target.value})}
            variant={TextFieldVariants.DARK}
            type='location'
            placeholder='Organization Name'
          />
          {errorMessage.organizationName && <span>{errorMessage.organizationName}</span>}
          <TextField
            value={bookingDetails.venueName}
            onChange={e => setBookingDetails({...bookingDetails, venueName: e.target.value})}
            variant={TextFieldVariants.DARK}
            type='location'
            placeholder='Venue Name'
          />
          {errorMessage.venueName && <span>{errorMessage.venueName}</span>}
          <TextField
            value={bookingDetails.venueCapacity}
            onChange={e => setBookingDetails({...bookingDetails, venueCapacity: e.target.value})}
            variant={TextFieldVariants.DARK}
            type='location'
            placeholder='Venue Capacity'
          />
          {errorMessage.venueCapacity && <span>{errorMessage.venueCapacity}</span>}
          <TextField
            value={bookingDetails.averageTicketPrice}
            onChange={e => setBookingDetails({...bookingDetails, averageTicketPrice: e.target.value})}
            variant={TextFieldVariants.DARK}
            type='location'
            placeholder='Average Ticket Price'
          />
          {errorMessage.averageTicketPrice && <span>{errorMessage.averageTicketPrice}</span>}
          <TextField
            value={bookingDetails.approximateBudget}
            onChange={e => setBookingDetails({...bookingDetails, approximateBudget: e.target.value})}
            variant={TextFieldVariants.DARK}
            type='location'
            placeholder='Approximate Artist Budget'
          />
          {errorMessage.approximateBudget && <span>{errorMessage.approximateBudget}</span>}
        </>
      )}

      <textarea
        value={bookingDetails.message}
        onChange={e => setBookingDetails({...bookingDetails, message: e.target.value})}
        placeholder={
          bookingDetails.communityCategory === 'artist' ? 'Other Notes' : 'Tell us a little about your event...'
        }
        className='TextField-Dark ContactEntityModal-Textarea'
      />

      <div className='cDetailsModal-actionRow'>
        {userId ? (
          <button className='cDetailsModal-actionRow-rtb' onClick={handleBookingRequest}>
            Send Request
          </button>
        ) : (
          <button className='cDetailsModal-actionRow-rtb' onClick={handleSignUpRedirect}>
            Login to Send Request
          </button>
        )}
      </div>
      {errorMessage.booking && <span style={{alignSelf: 'center', marginTop: '10px'}}>{errorMessage.booking}</span>}
    </div>
  )
}

export default EntityBookingRequest
