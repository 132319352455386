// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container__S9KE6{border:2px solid #fff !important}", "",{"version":3,"sources":["webpack://./src/components/Navigation/Primary/components/desktop/NavigationDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,kBACI,gCAAA","sourcesContent":[".Container {\n    border : 2px solid white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "Container__S9KE6"
};
export default ___CSS_LOADER_EXPORT___;
