import React, {PropsWithChildren} from 'react'
import {Link} from 'react-router-dom'

import styles from './styles.module.scss'

export interface Breadcrumb {
  title: string
  href?: string
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[]
  maxBreadcrumbs?: number
}

function Crumb(props: PropsWithChildren<Breadcrumb>) {
  const {href, children} = props
  if (href)
    return (
      <Link className={styles.breadcrumb} to={href}>
        {children}
      </Link>
    )
  return <span className={styles.breadcrumb}>{children}</span>
}

const SEPARATOR = '>'
const OVERFLOW_CHAR = '\u22EF' // midline horizontal ellipsis

export function Breadcrumbs({breadcrumbs, maxBreadcrumbs}: BreadcrumbsProps) {
  const isTruncated = maxBreadcrumbs !== undefined && breadcrumbs.length > maxBreadcrumbs
  if (isTruncated) {
    // make the overflow char the first breadcrumb and take the last maxBreadcrumbs - 1 breadcrumbs
    // the overflow-href is the href of the previous breadcrumb
    breadcrumbs.splice(0, breadcrumbs.length - maxBreadcrumbs + 1, {
      title: OVERFLOW_CHAR,
      href: breadcrumbs[maxBreadcrumbs].href,
    })
  }

  return (
    <div className={styles.Breadcrumbs} data-is-truncated={isTruncated}>
      {breadcrumbs.map((breadcrumb, i) => {
        const isFirst = i === 0
        return (
          <Crumb key={i} {...breadcrumb}>
            {!isFirst && <span className={styles.separator}>{SEPARATOR}</span>}
            <p data-is-link={!!breadcrumb.href}>{breadcrumb.title}</p>
          </Crumb>
        )
      })}
    </div>
  )
}
