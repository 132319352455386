import {formatPhoneNumber as packageFormatPhoneNumber} from 'react-phone-number-input'

const formatPhoneNumber = (phoneNumber?: string) => {
  const formattedPhoneNumberFromPackage = packageFormatPhoneNumber(phoneNumber ?? '')
  if (formattedPhoneNumberFromPackage) return formattedPhoneNumberFromPackage
  if (!phoneNumber || phoneNumber.length !== 10) return phoneNumber ?? ''

  const areaCode = phoneNumber.slice(0, 3)
  const firstDigitsGroup = phoneNumber.slice(3, 6)
  const secondDigitsGroup = phoneNumber.slice(6)
  const formattedPhoneNumber = `(${areaCode}) ${firstDigitsGroup}-${secondDigitsGroup}`
  return formattedPhoneNumber
}

export default formatPhoneNumber
