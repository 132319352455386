import React, {useState} from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import classNames from 'classnames'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import Footer from 'pages/HomePage/Footer'
import {HomePageComponentProps} from 'pages/HomePage/interface'
import HomePageNavigation from 'pages/HomePage/Navigation'

import FAQMap, {QAPair} from './FAQMap'

import './../page.scss'
import './styles.scss'

const FAQQuestion = ({faq}: {faq: QAPair}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {question, answer} = faq
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const onClick = () => {
    trackMixpanelEvent('FAQ Click- Landing Page', {
      buttonText: question,
    })
    setIsExpanded(!isExpanded)
  }

  return (
    <div className='FAQ'>
      <div className='FAQ-top'>
        <div>
          <h4>{question}</h4>
        </div>
        <div className='FAQ-top-expander' onClick={onClick}>
          <div className={classNames('', {expanded: isExpanded})}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                className='expander_line_a'
                d='M12 5V19'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path d='M5 12H19' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </div>
        </div>
      </div>
      <div className={classNames('FAQ-answer', {expanded: isExpanded})}>
        <p>{answer}</p>
      </div>
    </div>
  )
}

export const FAQSection = () => {
  return (
    <div className='FAQSection'>
      <svg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill='#303133'
          d='M37.2,-67.4C46.7,-58.9,51.9,-45.9,60.3,-34C68.7,-22,80.3,-11,79.6,-0.5C78.8,10.1,65.6,20.2,57.4,32.6C49.3,45,46.2,59.8,37.4,66.6C28.7,73.3,14.3,72.1,2.2,68.2C-9.9,64.3,-19.7,57.8,-27,50.1C-34.2,42.5,-38.8,33.8,-49.9,25.2C-61,16.7,-78.6,8.4,-85.2,-3.8C-91.8,-16,-87.5,-32,-77.6,-42.6C-67.7,-53.2,-52.2,-58.5,-38.4,-64.5C-24.5,-70.4,-12.3,-77.1,0.8,-78.5C13.8,-79.9,27.7,-75.9,37.2,-67.4Z'
          transform='translate(100 100)'
        />
      </svg>
      <h2>
        We&apos;re sure you have some <span className='landing-grad'>questions</span>...
      </h2>
      <div className='FAQWrapper'>
        {FAQMap.map((faq, index) => (
          <FAQQuestion key={index} faq={faq} />
        ))}
      </div>
    </div>
  )
}

const FAQPage = (props: HomePageComponentProps) => {
  const {changePage} = props

  return (
    <>
      <PoshHelmet title='Posh FAQ' />
      <body>
        <div className='PublicPage'>
          <div className='PublicPage-inner center'>
            <FAQSection />
          </div>
        </div>
        <Footer />
        <HomePageNavigation changePage={changePage} hasBegunScrolling={false} />
      </body>
    </>
  )
}

export default FAQPage
