import React from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {StaffMember} from 'apis/Community'
import useUpdatePendingCommunity from 'apis/Community/useUpdatePendingCommunity'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {isEmpty, isNull} from 'lodash'

import './styles.scss'

interface PendingStaffProps {
  staff: StaffMember[]
}
interface PendingStaffMemberProps {
  staff: StaffMember
}

const Pendingstaff = (props: PendingStaffMemberProps) => {
  const {staff} = props
  const {mutateAsync: updatePendingCommunity} = useUpdatePendingCommunity()
  const queryClient = useQueryClient()
  const avi = () => {
    if (!isNull(staff.avi) && !isEmpty(staff.avi) && staff.avi) return staff.avi
    else return 'https://images.posh.vip/b2/default-avi.jpg'
  }

  const handleUpdate = async (status: 'approved' | 'denied') => {
    await updatePendingCommunity({id: staff._id, approvalStatus: status, communityType: 'staff'})
    queryClient.invalidateQueries(['pendingCommunity'])
  }

  return (
    <div className='PendingCommunityCard'>
      <PoshImage src={avi()} />
      <label>
        Full Name: {staff.firstName} {staff.lastName}
      </label>
      <p>Email: {staff.email}</p>
      {staff.bio && <p>Bio: {staff.bio}</p>}
      {staff.instagram && <a href={`https://instagram.com/${staff.instagram}`}>Instagram</a>}
      <p>Hourly Rate: {staff.hourlyRate}</p>
      {staff.staffType && <p>Staff Type: {staff.staffType}</p>}
      <button onClick={() => handleUpdate('approved')} className='PendingCommunityCard-approve'>
        APPROVE
      </button>
      <button onClick={() => handleUpdate('denied')} className='PendingCommunityCard-deny'>
        DENY
      </button>
    </div>
  )
}
const Pendingstaffs = (props: PendingStaffProps) => {
  const {staff} = props
  return (
    <>
      <h4>Pending Staff</h4>
      <div className='PendingCommunitySection'>
        {staff.map(staffMember => (
          <Pendingstaff staff={staffMember} key={staffMember._id} />
        ))}
      </div>
    </>
  )
}
export default Pendingstaffs
