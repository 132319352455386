export class BaseNavigationItemAnimations {
  static container(isExpanded: boolean) {
    return {
      gap: isExpanded ? '10px' : 0,
    }
  }

  static body(isExpanded: boolean) {
    return {
      width: isExpanded ? 'auto' : 0,
      opacity: isExpanded ? 1 : 0,
      marginRight: isExpanded ? '8px' : 0,
    }
  }

  static children(isExpanded: boolean, isSelected: boolean) {
    const shouldShowChildren = isSelected && isExpanded
    return {
      width: isExpanded ? 'auto' : 0,
      height: shouldShowChildren ? 'auto' : 0,
      opacity: shouldShowChildren ? 1 : 0,
    }
  }
}
