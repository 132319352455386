import React, {useMemo, useRef, useState} from 'react'

import {DownChevron} from 'components/assets/Icons'
import PoshInfo from 'components/form/PoshInfo'
import useCheckIfClickedOutsideDiv from 'components/helpers/clickedOutsideDiv'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {Text} from '../Shared/Text/Text'

import styles from './OverviewDropdown.module.scss'

const PAID_EVENT_OPTIONS = ['Revenue', 'Tickets', 'Conversion'] as const
const PAID_EVENT_OPTIONS_HOST = ['Revenue', 'Tickets'] as const
const RSVP_OPTIONS = ['Tickets'] as const
export type OverviewDropdownOption = (typeof PAID_EVENT_OPTIONS)[number]

interface OverviewDropdownProps {
  selectedValue: OverviewDropdownOption
  onSelect: (value: OverviewDropdownOption) => void
  isRSVPEvent?: boolean
}

export const OverviewDropdown = ({selectedValue, onSelect, isRSVPEvent}: OverviewDropdownProps) => {
  const [open, setOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const {domain} = useResourcePageParams()
  useCheckIfClickedOutsideDiv(dropdownRef, () => {
    setOpen(false)
  })
  const onClickDropdown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setOpen(open => !open)
  }
  const onSelectValue = (value: OverviewDropdownOption) => {
    onSelect(value)
    setOpen(false)
  }

  const OPTIONS = useMemo(() => {
    if (isRSVPEvent) return RSVP_OPTIONS
    if (domain == 'host') return PAID_EVENT_OPTIONS_HOST
    return PAID_EVENT_OPTIONS
  }, [isRSVPEvent, domain])

  return (
    <div className={styles.OverviewDropdown} ref={dropdownRef}>
      <div className={styles.OverviewDropdown_button} onClick={e => onClickDropdown(e)}>
        <Text size='large'>{isRSVPEvent ? 'RSVPs' : selectedValue}</Text>
        {OPTIONS.length > 1 && (
          <button className={styles.OverviewDropdown_button_arrow}>
            <DownChevron color='black' height={12} />
          </button>
        )}
        {selectedValue === 'Conversion' && <PoshInfo info='Conversion is the percentage of completed carts.' />}
      </div>
      {open && OPTIONS.length > 1 && (
        <div className={styles.OverviewDropdown_options}>
          {OPTIONS.map(option => {
            if (option === selectedValue) return null
            return (
              <div key={option} onClick={() => onSelectValue(option)}>
                <Text size='medium'>{option}</Text>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
