import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CommunityCategory, OpenToOtherDatesChoice} from '.'

export interface CreateBookingRequestData {
  communityId: string
  message: string
  location: string
  bookingDate: Date
  startTime: Date | undefined
  endTime: Date | undefined
  communityCategory: CommunityCategory
  organizationName?: string
  otherDates?: OpenToOtherDatesChoice
  venueName?: string
  venueCapacity?: string
  averageTicketPrice?: string
  approximateBudget?: string
  eventName: string
  eventType?: string
}

async function createBookingRequest(bookingRequestData: CreateBookingRequestData) {
  const response = await PoshApi.createBookingRequest(bookingRequestData)
  return response.data
}

export default function useCreateBookingRequest() {
  return useMutation((params: CreateBookingRequestData) => createBookingRequest(params))
}
