import {useEffect, useMemo, useState} from 'react'

import {GroupAccountRoleUnion} from 'apis/Accounts'
import usePermissionsContext from 'domains/Teams/PermissionsContext'

type SplitOwnerAndNotAttendeeGroups = {
  whereOwner: GroupAccountRoleUnion[]
  whereNotNotOwnerOrAttendee: GroupAccountRoleUnion[]
}

function splitOwnerAndNotAttendeeGroups(
  groupAccountRoleUnions: GroupAccountRoleUnion[],
): SplitOwnerAndNotAttendeeGroups {
  return groupAccountRoleUnions.reduce(
    (acc, group) => {
      if (group.accountRole.roleKey === 'owner') acc.whereOwner.push(group)
      else if (group.accountRole.roleKey !== 'attendee') acc.whereNotNotOwnerOrAttendee.push(group)
      return acc
    },
    {whereOwner: [], whereNotNotOwnerOrAttendee: []} as SplitOwnerAndNotAttendeeGroups,
  )
}

export function useGetCurrentGroup(
  groupAccountRoleUnions: GroupAccountRoleUnion[] | undefined,
  groupId: string | undefined,
) {
  const {setAccountRole} = usePermissionsContext()

  const [currentGroup, setCurrentGroup] = useState<GroupAccountRoleUnion | undefined>(undefined)

  const {whereOwner: groupsWhereOwner, whereNotNotOwnerOrAttendee: groupsWhereNotNotOwnerOrAttendee} = useMemo(
    () => splitOwnerAndNotAttendeeGroups(groupAccountRoleUnions || []),
    [groupAccountRoleUnions],
  )

  // If there is not a group selected pre select a group for them
  useEffect(() => {
    if (!groupAccountRoleUnions) return
    let groupAccountRoleUnion: GroupAccountRoleUnion | undefined
    // if groupId is provided, select that group
    if (groupId) groupAccountRoleUnion = groupAccountRoleUnions.find(a => a.group._id == groupId)
    // if there are groups where the user is an owner, select the first one
    else if (groupsWhereOwner.length) groupAccountRoleUnion = groupsWhereOwner[0]
    // if there are groups where the user is not an attendee, select the first one
    else if (groupsWhereNotNotOwnerOrAttendee.length) groupAccountRoleUnion = groupsWhereNotNotOwnerOrAttendee[0]
    setCurrentGroup(groupAccountRoleUnion)
    // if a group is selected, set the account role to that group
    setAccountRole(groupAccountRoleUnion?.accountRole || null)
  }, [groupAccountRoleUnions, groupId])

  return {currentGroup, setCurrentGroup}
}
