import {useMutation} from '@tanstack/react-query'
import {LongLat} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

import {ArtistType, GalleryImage, Tag} from '.'

export interface UpdateArtist {
  id: string
  artistName?: string
  firstName?: string
  lastName?: string
  artistType?: ArtistType
  baseBookingFee?: number
  spotifyUrl?: string
  location?: string
  coordinates?: LongLat
  bio?: string
  website?: string
  email?: string
  avi?: string
  tags?: Tag[]
  galleryImages?: GalleryImage[]
  instagram?: string
}

async function updateArtist(artistData: UpdateArtist) {
  const response = await PoshApi.updateArtist(artistData)
  return response.data
}

export default function useUpdateArtist() {
  return useMutation((params: UpdateArtist) => updateArtist(params))
}
