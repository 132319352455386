import React from 'react'

import EventPageNavigation from 'pages/EventManagementPages/EventPageNavigation'

import {DesktopTertiaryNavigation} from '../../Desktop'

export function DesktopEventManagementTertiaryNavigation() {
  return (
    <DesktopTertiaryNavigation>
      <EventPageNavigation />
    </DesktopTertiaryNavigation>
  )
}
