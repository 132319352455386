import {useState} from 'react'

import useAddToMailChimpList, {AddToMailChimpListResponse} from 'apis/MailChimp/useAddUsersToMailChimpList'
import {useToast} from 'components/toasts/ToastProvider'
import {chunk} from 'lodash'
import {useResourcePageParams} from 'pages/PoshAppLayout'
import {v4} from 'uuid'

import useAttendeesCrmContext from '../AttendeesCrmContext'

export function useExportAttendeeBatchesToMailchimp() {
  const {groupId, eventId} = useResourcePageParams()
  const [completedData, setCompletedData] = useState<AddToMailChimpListResponse>()
  const {selectedAttendeeIds} = useAttendeesCrmContext()
  const {showToast} = useToast()
  const {mutateAsync: addToMailChimpList, isLoading: isAddingToMailChimpList} = useAddToMailChimpList()
  const handleExportToList = async (listId: string) => {
    try {
      if (!groupId) throw new Error('There was a problem with your request. Please refresh try again.')
      // split attendees up into chunks of 100
      const attendeeChunks = chunk(selectedAttendeeIds, 100)
      const responseData: AddToMailChimpListResponse = {
        created: 0,
        updated: 0,
        errors: 0,
        listLink: '',
      }
      // generate random object id for each chunk of attendees
      const chunkId = v4()
      for (const attendees of attendeeChunks) {
        const data = await addToMailChimpList({listId, groupId, eventId, attendees, chunkId})
        responseData.created = data.created
        responseData.updated = data.updated
        responseData.errors = data.errors
        responseData.listLink = data.listLink
      }
      setCompletedData(responseData)
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }
  return {handleExportToList, isAddingToMailChimpList, completedData}
}
