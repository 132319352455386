import React, {useEffect, useState} from 'react'
import {UseFormReturn} from 'react-hook-form'

import {Checkbox} from 'components/form/checkbox/Checkbox'
import {DateTimeInput} from 'components/PageComponents/EventVisuals/Form/Input/DateInput/DateTime'
import {EventVisualsMarkdownEditor} from 'components/PageComponents/EventVisuals/Form/Input/MarkdownInput'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {TicketSection} from 'components/PageComponents/EventVisuals/Page/Section/ticketSection'
import {CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useDimensions} from 'hooks/useDimensions'

import {CHECK_COLOR, COLOR_PROPS} from './TicketEditorPanel'

import styles from './styles.module.scss'

const ERROR_CLASS_NAME = 'error noMargin text-small center'

interface TicketDetailsPanel {
  form: UseFormReturn<CreateEventTicket>
  currencySymbol: string
}

export const TicketDetails = (props: TicketDetailsPanel) => {
  const {form, currencySymbol} = props
  const {isMobile} = useDimensions()

  const checkboxFlexDirection = isMobile ? 'column' : 'row'

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: {errors},
  } = form

  const description = watch('description')
  const onSaleUtc = watch('onSaleUtc')
  const endSaleUtc = watch('endSaleUtc')
  const validBefore = watch('validity.validBefore')
  const validAfter = watch('validity.validAfter')
  const price = watch('price')

  const [shouldShowSaleDates, setShouldShowSaleDates] = useState(!!onSaleUtc || !!endSaleUtc)
  const [shouldShowValidity, setShouldShowValidity] = useState(!!validBefore || !!validAfter)

  const toggleSaleDates = () => {
    setShouldShowSaleDates(!shouldShowSaleDates)
  }

  const toggleValidity = () => {
    setShouldShowValidity(!shouldShowValidity)
  }

  // Reset date fields if the checkbox is unchecked
  useEffect(() => {
    if (!shouldShowSaleDates) {
      setValue('onSaleUtc', null)
      setValue('endSaleUtc', null)
    }
    if (!shouldShowValidity) {
      setValue('validity.validBefore', null)
      setValue('validity.validAfter', null)
    }
  }, [shouldShowSaleDates, shouldShowValidity])

  useEffect(() => {
    clearErrors()

    if (onSaleUtc && endSaleUtc && onSaleUtc > endSaleUtc) {
      setError('endSaleUtc', {message: 'End date must be after start date'})
    }

    if (validBefore && validAfter && validBefore > validAfter) {
      setError('validity', {message: 'End time must be after start time'})
    }

    if (price && price < 0) {
      setError('price', {message: 'Price must be greater than or equal to 0'})
    }
  }, [onSaleUtc, endSaleUtc, validBefore, validAfter, price])

  return (
    <TicketSection title='Ticket Details'>
      <div className={styles.row}>
        <EventVisualsTextInput.Text.Controlled
          control={control}
          name='name'
          label='Name'
          placeholder='Ticket Name'
          rules={{required: true}}
          autoComplete='none'
          wrapperStyle={{width: '100%'}}
          {...COLOR_PROPS}
        />
        <EventVisualsTextInput.Number.Controlled
          control={control}
          label='Qty'
          name='quantityAvailable'
          placeholder='Unlimited'
          decimalPlaces={0}
          {...COLOR_PROPS}
          style={{maxWidth: '200px'}}
        />
      </div>
      <EventVisualsTextInput.Number.Controlled
        control={control}
        name='price'
        label='Price'
        placeholder='0'
        decimalPlaces={2}
        wrapperStyle={{display: 'flex', gap: '10px', alignItems: 'center'}}
        style={{width: '100%'}}
        prefix={currencySymbol}
        {...COLOR_PROPS}
      />
      <Checkbox
        checked={shouldShowSaleDates}
        onChecked={toggleSaleDates}
        color={CHECK_COLOR}
        style={{flexDirection: checkboxFlexDirection}}
        hideBackground
        label='Sale Period'
        subtitle='When can the customer purchase this ticket?'>
        {shouldShowSaleDates && (
          <div className={styles.row}>
            <DateTimeInput.Controlled control={control} name='onSaleUtc' placeholder='Start Date' {...COLOR_PROPS} />
            <label className={'noMargin'}>to</label>
            <DateTimeInput.Controlled control={control} name='endSaleUtc' placeholder='End Date' {...COLOR_PROPS} />
          </div>
        )}
      </Checkbox>
      {errors.endSaleUtc && <p className={ERROR_CLASS_NAME}>{errors.endSaleUtc.message}</p>}

      <Checkbox
        checked={shouldShowValidity}
        onChecked={toggleValidity}
        color={CHECK_COLOR}
        style={{flexDirection: checkboxFlexDirection}}
        hideBackground
        label='Valid From'
        subtitle='When can the customers use these tickets?'>
        {shouldShowValidity && (
          <div className={styles.row}>
            <DateTimeInput.Controlled
              control={control}
              name='validity.validBefore'
              placeholder='Start time'
              {...COLOR_PROPS}
            />
            <label className={'noMargin'}>to</label>
            <DateTimeInput.Controlled
              control={control}
              name='validity.validAfter'
              placeholder='End Time'
              {...COLOR_PROPS}
            />
          </div>
        )}
      </Checkbox>
      {errors.validity && <p className={ERROR_CLASS_NAME}>{errors.validity.message}</p>}

      <EventVisualsMarkdownEditor
        label='Description'
        onChange={value => setValue('description', value)}
        value={description ?? ''}
        lightmodeOverride={COLOR_PROPS.lightMode}
        accentColorOverride={COLOR_PROPS.accentColor}
      />
    </TicketSection>
  )
}
