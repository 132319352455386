import React, {useState} from 'react'

import {useGetAffiliatePayouts} from 'apis/Kickback/useGetAffiliatePayouts'
import {Card} from 'components/Card'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import BalancesCard from 'pages/GroupPage/Finance/PayoutCards/BalancesCard'
import BankAccount from 'pages/GroupPage/Finance/PayoutCards/BankAccount'
import ViewPayoutsCard from 'pages/GroupPage/Finance/PayoutCards/ViewPayoutsCard'

export const AffiliatePayouts = () => {
  const [startingAfter, setStartingAfter] = useState('')
  const [endingBefore, setEndingBefore] = useState('')
  const {
    data: payouts,
    isSuccess,
    isInitialLoading,
    isFetching,
    isError,
  } = useGetAffiliatePayouts(
    {
      startingAfter,
      endingBefore,
    },
    {keepPreviousData: true},
  )
  const [page, setPage] = useState(1)
  return (
    <>
      {isSuccess ? (
        <div className='AffiliatePayouts'>
          <div className='AffiliatePayouts-left'>
            <BalancesCard
              balances={{
                availableBalance: payouts.formattedAvailableBalance,
                pendingBalance: payouts.formattedPendingBalance,
                instantAvailableBalance: payouts.instantAvailableBalance,
                instantAvailableFormatBalance: payouts.instantAvailableFormatBalance,
              }}
            />
            <Card title={'BANKS & DEBIT CARDS'}>
              {payouts.bankAccounts.map((account, index) => (
                <BankAccount key={index} bankAccount={account} isShowingEditBankAccount={false} />
              ))}
            </Card>
          </div>
          <div className='AffiliatePayouts-right'>
            <ViewPayoutsCard
              page={page}
              setPage={setPage}
              formattedPayouts={payouts.payoutsFormatted}
              isFetching={isFetching}
              setStartingAfter={setStartingAfter}
              setEndingBefore={setEndingBefore}
              isError={isError}
            />
          </div>
        </div>
      ) : isInitialLoading ? (
        <div className='Payouts-tableContainer-loadingDiv'>
          <SpinLoader height={80} />
        </div>
      ) : (
        <div className='Payouts-tableContainer-loadingDiv'>
          <p>There was an issue fetching your payouts</p>
        </div>
      )}
    </>
  )
}
export default AffiliatePayouts
