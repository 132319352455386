import React from 'react'
import {useNavigate} from 'react-router-dom'

import {FetchHostByTrackingLinkResponse} from 'apis/Orders/useFetchHostByTrackingLink'
import {Card} from 'components/Card'
import {PoshImage} from 'components/PoshImage/PoshImage'

interface OrderCardHostProps {
  host: FetchHostByTrackingLinkResponse
  trackingLink: string
}

const OrderCardHost = (props: OrderCardHostProps) => {
  const {host, trackingLink} = props
  const navigate = useNavigate()

  return (
    <Card title={'Tracking Link'} className='OrderPage-customerCard' animationIndex={4}>
      <PoshImage className='OrderPage-avi' src={host.avi} />
      <div className='OrderPage-customerCard-name'>{host.name}</div>
      <div
        className='OrderPage-customerCard-trackingLink'
        onClick={() => navigate(`${location.pathname}/tl-breakdown/${trackingLink}` + location.search)}>
        <PoshImage src='https://images.posh.vip/b2/trackingLink.svg' />
        {trackingLink}
      </div>
    </Card>
  )
}

export default OrderCardHost
