import React from 'react'
import {useNavigate} from 'react-router-dom'

import {getFullName} from '@posh/utils'
import getAvi from 'apis/Util/getAvi'
import Button from 'components/form/Button'
import {useNavigation} from 'components/Navigation/NavigationContext'
import useSessionContext from 'domains/Auth/SessionContext'
import {PoshRoutes} from 'models/PoshRoutes'
import {CurrentUser} from 'pages/OwnerPage/PoshHeader/CurrentUser'
import Notifications from 'pages/OwnerPage/PoshHeader/Notifications'

import {HeaderContextButton, HeaderContextButtonProps, useGetDefaultContextButton} from './components/ContextButton'
import {PageTitle, PageTitleProps} from './components/PageTitle'

import styles from './styles.module.scss'

interface HeaderDesktopProps {
  currentUser:
    | {
        firstName: string
        lastName: string
        avi: string
      }
    | undefined
  onClick: {
    currentUser: () => void
    login: () => void
  }
  pageTitle?: PageTitleProps
  contextButton?: HeaderContextButtonProps
}

export function HeaderDesktop(props: HeaderDesktopProps) {
  const {currentUser, onClick, pageTitle} = props

  const defaultContextButton = useGetDefaultContextButton()
  const contextButton = props.contextButton ?? defaultContextButton

  return (
    <nav className={styles.positioning}>
      <div className={styles.Header}>
        <div className={styles.left}>{pageTitle && <PageTitle {...pageTitle} />}</div>
        <div className={styles.right}>
          <HeaderContextButton {...contextButton} />
          {currentUser ? (
            <>
              <Notifications />
              <div className={styles.currentUser} onClick={onClick.currentUser}>
                <CurrentUser fullName={getFullName(currentUser)} avi={getAvi(currentUser)} />
              </div>
            </>
          ) : (
            <Button className='poshBtn gold' onClick={onClick.login}>
              Log In
            </Button>
          )}
        </div>
      </div>
    </nav>
  )
}

function UsesContext() {
  const {currentUser: ctxUser} = useSessionContext()
  const {pageTitleProps, contextButtonProps} = useNavigation()
  const currentUser = ctxUser
    ? {
        firstName: ctxUser.firstName ?? '',
        lastName: ctxUser.lastName ?? '',
        avi: getAvi(ctxUser),
      }
    : undefined

  const navigate = useNavigate()
  const navigateToProfile = () => navigate('/dashboard/account')
  const navigateToLogin = () => navigate(PoshRoutes.LOGIN)

  return (
    <HeaderDesktop
      currentUser={currentUser}
      onClick={{currentUser: navigateToProfile, login: navigateToLogin}}
      pageTitle={pageTitleProps}
      contextButton={contextButtonProps}
    />
  )
}

HeaderDesktop.UsesContext = UsesContext
