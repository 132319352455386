import Cookies from 'js-cookie'

import {SessionContextState} from '../../domains/Auth/SessionContext'
import {AuthResponse} from './index'
import {LoginWithEmailOutput} from './useLoginWithEmail'

export const setSessionContext = (data: AuthResponse | LoginWithEmailOutput, sessionContext: SessionContextState) => {
  const {setUserId, setToken, setCurrentUser, setAccountRoles, setGroupsWhereAccountIsOwnerMap} = sessionContext

  if (data.token) {
    localStorage.setItem('token', data.token)
    setToken(data.token)
    if (data.expires) {
      localStorage.setItem('token_expires', data.expires.toString())
      Cookies.set('authenticated', 'true', {path: '/', expires: new Date(data.expires)})
    } else {
      Cookies.set('authenticated', 'true', {path: '/'})
    }
  } else if (data.expires) {
    localStorage.setItem('token_expires', data.expires.toString())
  }
  if (data.userId) {
    localStorage.setItem('userId', data.userId)
    setUserId(data.userId)
  }
  if (data.account) {
    localStorage.setItem('currentUser', JSON.stringify(data.account))
    setCurrentUser(data.account)
  }
  if (data.accountRoles) {
    localStorage.setItem('accountRoles', JSON.stringify(data.accountRoles))
    setAccountRoles(data.accountRoles)
  }

  localStorage.setItem('groupsWhereAccountIsOwnerMap', JSON.stringify(data.groupsWhereAccountIsOwnerMap))
  setGroupsWhereAccountIsOwnerMap(data.groupsWhereAccountIsOwnerMap)
}
