import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface RemovePromoCodeParams {
  eventId?: string
  promoCodeId: string
}

async function removePromoCode(params: RemovePromoCodeParams) {
  const response = await PoshApi.removePromoCode(params)
  return response.data
}

export function useRemovePromoCode(opts?: UseMutationOptions<any, any, RemovePromoCodeParams>) {
  return useMutation((params: RemovePromoCodeParams) => removePromoCode(params), opts)
}
