import {AccountNavigationItem} from './Account'
import {CreateEventNavigationItem} from './CreateEvent'
import {HelpNavigationItem} from './Help'
import {LogoNavigationItem} from './Logo'
import {PageNavigationItem} from './Page'

export type PrimaryNavigationItem = 'page' | 'logo' | 'create-event' | 'account' | 'help'
export class NavigationItem {
  static Page = PageNavigationItem
  static Logo = LogoNavigationItem
  static CreateEvent = CreateEventNavigationItem
  static Account = AccountNavigationItem
  static Help = HelpNavigationItem
}
