import React from 'react'

import PoshInfo from 'components/form/PoshInfo'
import {SpinLoader} from 'components/Loaders/SpinLoader'

const AnalyticsCard = ({
  children,
  title,
  loading,
  info,
}: {
  children: React.ReactNode
  title: string
  loading?: boolean
  info?: string
}) => {
  return (
    <div className='EventOverview-charts-analyticsCard'>
      <h4>
        {title}
        {info && <PoshInfo info={info} />}
      </h4>
      <div className='EventOverview-charts-analyticsCard-chartContainer'>
        {loading ? <SpinLoader height={40} /> : children}
      </div>
    </div>
  )
}

export default AnalyticsCard
