import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type CreatePendingClikPhotographerBookingOptions = ReactQueryOptions['services']['createPendingClikPhotographerBooking']
export type CreatePendingClikPhotographerBookingInput = RouterInputs['services']['createPendingClikPhotographerBooking']
export type CreatePendingClikPhotographerBookingOutput =
  RouterOutput['services']['createPendingClikPhotographerBooking']

export function useCreatePendingClikPhotographerBooking(opts?: CreatePendingClikPhotographerBookingOptions) {
  return trpc.services.createPendingClikPhotographerBooking.useMutation(opts)
}
