import {buildYoutubeVideoUrl, CustomEventPageFont} from '@posh/model-types'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

import {GetEventForVisualEditorOutput} from './useGetEventForVisualEditor'

type UpdateEventOptions = ReactQueryOptions['events']['updateEvent']
export type UpdateEventInput = RouterInputs['events']['updateEvent']
export type UpdateEventOutput = RouterOutput['events']['updateEvent']

export function useUpdateEvent(opts?: UpdateEventOptions) {
  return trpc.events.updateEvent.useMutation(opts)
}
/**
 * Transforms the output of `useGetEventForVisualEditor` to the input of `useUpdateEvent`. Used for pre-filling the form with the event data.
 * @param data The `data` property of the output of `useGetEventForVisualEditor`
 * @returns The input for `useUpdateEvent`
 */
export function transformGetEventForVisualEditorOutputToUpdateEventInput(
  data: GetEventForVisualEditorOutput,
): UpdateEventInput {
  return {
    ...data,
    _id: data.eventId,
    location: {
      type: 'Point',
      coordinates: [data.location.lng, data.location.lat],
    },
    eventTitleFont: data.eventTitleFont as CustomEventPageFont,
    song: data.song && {
      ...data.song,
      albumCover: data.song.albumCover ?? '', // TODO do we need this?
    },
    parentCategory: data.eventCategory.parentCategory,
    subCategory: data.eventCategory.subCategory,
    venue: {
      name: data.venueName,
      address: data.venueAddress,
      images: data.gallery,
    },
    youtubeLink: data.youtubeVideoId ? buildYoutubeVideoUrl(data.youtubeVideoId) : null,
    passwordEnabled: data.isPasswordProtected,
  }
}
