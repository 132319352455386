// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventVisualsDesignModal__974u1 .row__RIBq3{display:flex;justify-content:space-between}.EventVisualsDesignModal__974u1 .colorSwatch__vIDno{height:40px;width:40px;border-radius:5px;cursor:pointer}.EventVisualsDesignModal__974u1 .colorSwatch__vIDno[data-is-selected=true]{border:2px solid #fff}", "",{"version":3,"sources":["webpack://./src/components/PageComponents/EventVisuals/Modals/styles.module.scss"],"names":[],"mappings":"AAGE,4CACE,YAAA,CACA,6BAAA,CAIF,oDACE,WAAA,CACA,UAAA,CACA,iBAAA,CACA,cAAA,CAEA,2EACE,qBAAA","sourcesContent":["@import '@global-styles/color_pallete';\n\n.EventVisualsDesignModal {\n  .row {\n    display: flex;\n    justify-content: space-between;\n  }\n\n\n  .colorSwatch {\n    height: 40px;\n    width: 40px;\n    border-radius: 5px;\n    cursor: pointer;\n\n    &[data-is-selected=\"true\"] {\n      border: 2px solid $white;\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EventVisualsDesignModal": "EventVisualsDesignModal__974u1",
	"row": "row__RIBq3",
	"colorSwatch": "colorSwatch__vIDno"
};
export default ___CSS_LOADER_EXPORT___;
