import React, {ChangeEvent, useEffect, useState} from 'react'

import {EventTable} from 'apis/Events/types'
import getAvi from 'apis/Util/getAvi'
import {PoshImage} from 'components/PoshImage/PoshImage'

import {useManualFetchAccount} from '../../../apis/Accounts/useFetchAccount'
import useScanTable from '../../../apis/Admissions/useScanTable'
import useFetchEvent from '../../../apis/Events/useFetchEvent'
import Button from '../../../components/form/Button'
import {Checkbox} from '../../../components/form/checkbox/Checkbox'
import Input from '../../../components/form/Input'
import {useResourcePageParams} from '../../PoshAppLayout'

import './styles.scss'

interface SeatRowProps {
  seatIndex: number
  defaultChecked?: boolean
  onAddSeatIndex: () => void
  onRemoveSeatIndex: () => void
}

function SeatRow({seatIndex, defaultChecked, onAddSeatIndex, onRemoveSeatIndex}: SeatRowProps) {
  const onSeatCheck = () => {
    if (!defaultChecked) {
      onAddSeatIndex()
    } else {
      onRemoveSeatIndex()
    }
  }

  return (
    <div className='SeatRow'>
      <p>Seat #{seatIndex}</p>
      <Checkbox onChecked={onSeatCheck} checked={defaultChecked ?? false} />
    </div>
  )
}

function TableRow({table}: {table: EventTable}) {
  const {eventId} = useResourcePageParams()
  const [collapsed, setCollapsed] = useState(true)
  const {data: account, refetch: fetchAccount} = useManualFetchAccount(table.purchaseData?.accountId)
  const [tableSeatMap, setTableSeatMap] = useState(table.tableSeatMap ?? {})
  const {mutate: scanTable} = useScanTable()

  useEffect(() => {
    fetchAccount()
  }, [fetchAccount])

  const onViewSeats = async () => {
    if (!collapsed) {
      const isApproved = confirm('Are you sure you want to check these seats in?')
      if (isApproved) {
        await scanTable({tableId: table._id!, eventId: eventId!, tableSeatMap})
        setCollapsed(true)
      } else {
        setCollapsed(true)
        setTableSeatMap(table.tableSeatMap ?? {})
      }
    } else {
      setCollapsed(false)
    }
  }

  const onAddSeatIndex = (index: number) => {
    const strI = index.toString()
    setTableSeatMap({...tableSeatMap, [strI]: true})
  }

  const onRemoveSeatIndex = (index: number) => {
    const strI = index.toString()
    setTableSeatMap({...tableSeatMap, [strI]: false})
  }

  const buttonText = collapsed ? 'View Seats' : 'Save & Scan Seats'

  return (
    <div className='TableRow'>
      <div className='TableRow-tableInfo'>
        <div className='TableRow-tableInfo-leftColumn'>
          <PoshImage src={getAvi(account)} />
        </div>
        <div className='TableRow-tableInfo-rightColumn'>
          <p style={{maxWidth: '200px'}}>{table.name}</p>
          <span style={{textTransform: 'capitalize'}}>
            Attendee Name:{' '}
            <strong>
              {account?.firstName} {account?.lastName}
            </strong>
          </span>
        </div>
      </div>
      <div className='TableRow-buttonsContainer'>
        <Button className='TableRow-button' onClick={onViewSeats}>
          {buttonText}
        </Button>
        {!collapsed && (
          <Button className='TableRow-button' onClick={() => setCollapsed(true)}>
            Discard Changes
          </Button>
        )}
      </div>
      {!collapsed && (
        <div className='TableRow-seats'>
          {Array(table.seatingLimit ?? 0)
            .fill(0)
            .map((_v, i) => (
              <SeatRow
                key={i}
                seatIndex={i}
                defaultChecked={tableSeatMap[i.toString()] ?? false}
                onAddSeatIndex={() => onAddSeatIndex(i)}
                onRemoveSeatIndex={() => onRemoveSeatIndex(i)}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default function AdmissionsPage() {
  const {eventId} = useResourcePageParams()
  const {data: event} = useFetchEvent(eventId!)
  const soldTables = event?.event?.tables?.filter(t => !!t.purchaseData)
  const [displayTables, setDisplayTables] = useState<EventTable[]>(soldTables ?? [])

  const [searchString, setSearchString] = useState('')

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setSearchString(value)

    if (value.length == 0) setDisplayTables(soldTables ?? [])

    if (value.length > 2) {
      const searchedTables = displayTables.filter(t => {
        return t.name.toLowerCase().includes(value.toLowerCase())
      })
      setDisplayTables(searchedTables)
    }
  }

  return (
    <div className='AdmissionsPage'>
      <h3 style={{marginTop: '30px'}}>Scan Tables</h3>
      <Input value={searchString} onChange={onSearch} placeholder='Search by Table Name' />
      {displayTables.map(t => (
        <TableRow key={t._id} table={t} />
      ))}
    </div>
  )
}
