import {createContext, useContext} from 'react'

type StagedUserFlowContext = {
  stage: number
  nextStage: () => void
  previousStage: () => void
}

export const StagedUserFlowContext = createContext<StagedUserFlowContext>({} as StagedUserFlowContext)

export const useStagedUserFlow = () => {
  const context = useContext(StagedUserFlowContext)

  if (!context) {
    throw new Error('useStagedUserFlow() cannot be used outside of a StagedUserFlowContextProvider')
  }

  return context
}
