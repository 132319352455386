import React from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {CommunityArtist} from 'apis/Community'
import useUpdatePendingCommunity from 'apis/Community/useUpdatePendingCommunity'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {isEmpty, isNull} from 'lodash'

import './styles.scss'

interface PendingArtistsProps {
  artists: CommunityArtist[]
}
interface PendingArtistProps {
  artist: CommunityArtist
}

const PendingArtist = (props: PendingArtistProps) => {
  const {artist} = props
  const {mutateAsync: updatePendingCommunity} = useUpdatePendingCommunity()
  const queryClient = useQueryClient()
  const avi = () => {
    if (!isNull(artist.avi) && !isEmpty(artist.avi) && artist.avi) return artist.avi
    else return 'https://images.posh.vip/b2/default-avi.jpg'
  }

  const handleUpdate = async (status: 'approved' | 'denied') => {
    await updatePendingCommunity({id: artist._id, approvalStatus: status, communityType: 'artist'})
    queryClient.invalidateQueries(['pendingCommunity'])
  }

  return (
    <div className='PendingCommunityCard'>
      <PoshImage src={avi()} />
      <label>{artist.artistName}</label>
      <p>
        Full Name: {artist.firstName} {artist.lastName}
      </p>
      <p>Email: {artist.email}</p>
      {artist.bio && <p>Bio: {artist.bio}</p>}
      {artist.instagram && <a href={`https://instagram.com/${artist.instagram}`}>Instagram</a>}
      <p>Booking Fee: {artist.baseBookingFee}</p>
      {artist.artistType && <p>Artist Type: {artist.artistType}</p>}
      <button onClick={() => handleUpdate('approved')} className='PendingCommunityCard-approve'>
        APPROVE
      </button>
      <button onClick={() => handleUpdate('denied')} className='PendingCommunityCard-deny'>
        DENY
      </button>
    </div>
  )
}
const PendingArtists = (props: PendingArtistsProps) => {
  const {artists} = props
  return (
    <>
      <h4>Pending Artists</h4>
      <div className='PendingCommunitySection'>
        {artists.map(artist => (
          <PendingArtist artist={artist} key={artist._id} />
        ))}
      </div>
    </>
  )
}
export default PendingArtists
