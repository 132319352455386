import React, {useState} from 'react'

import {CurrencyCode} from '@posh/types'
import {EventPublic, EventTicketAndTicketsAvailable, ITicketGroup} from 'apis/Events/types'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {isEmpty} from 'lodash'

import TicketActionRow from '../TicketActionRow'

interface TicketGroupProps {
  tg: ITicketGroup
  colorModeModifier: string
  eventTickets: EventTicketAndTicketsAvailable[]
  eventIsOver: boolean
  event: EventPublic
  currency?: CurrencyCode
  affiliateTrackingLink: string | null
  trackingLink: string | null
}

export const TicketGroup = (props: TicketGroupProps) => {
  const {tg, colorModeModifier, event, eventIsOver, eventTickets, currency, affiliateTrackingLink, trackingLink} = props
  const {accentColor, lightmode} = event
  const [expandedGroups, setExpandedGroups] = useState<string[]>()
  const isExpanded = expandedGroups?.includes(tg.name)
  const ticketsInGroup = eventTickets.filter(t => tg.ticketTypes.includes(t.ticket.id))

  const handleExpand = () => {
    if (isExpanded) {
      const newArray = expandedGroups?.filter(g => g !== tg.name)
      setExpandedGroups(newArray)
    } else setExpandedGroups([...(expandedGroups ?? []), tg.name])
  }
  return (
    <>
      {!isEmpty(ticketsInGroup) && (
        <div
          className={'EventPage-ticketContainer ' + colorModeModifier + (isExpanded ? ' isExpanded' : '')}
          style={{borderColor: `${accentColor}`, boxShadow: `0 0 30px -5px ${accentColor}`}}>
          <div
            className='EventPage-ticketContainer-groupName'
            style={{background: `${accentColor}`, color: `${lightmode ? '#FAFAFC' : '#0A0B0D'}`}}
            onClick={() => handleExpand()}>
            <span>{tg.name}</span>
            <PoshImage
              className='EventPage-ticketContainer-groupName-arrow'
              src='https://images.posh.vip/b2/right-arrow+(2).svg'
              style={{filter: `${lightmode ? 'none' : 'invert(90)'}`}}
            />
          </div>

          {isExpanded &&
            !eventIsOver &&
            ticketsInGroup.map((t, i) => (
              <TicketActionRow
                key={i}
                ticket={t.ticket}
                index={i}
                trackingLink={trackingLink}
                currency={currency}
                affiliateTrackingLink={affiliateTrackingLink}
                ticketsAvailableMessage={t.ticketsAvailableMessage}
                accentColor={accentColor}
                lightmode={lightmode}
                showFeesInPrice={event.showFeesInPrice}
                paymentPlans={event.paymentPlans}
                displayPaymentPlanPriceOnTickets={event.displayPaymentPlanPriceOnTickets}
                longFormAddToCartButton={event.longFormAddToCartButton}
              />
            ))}
        </div>
      )}
    </>
  )
}
