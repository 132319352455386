import {useMatch, useNavigate} from 'react-router-dom'

import useCheckIsAffiliate from 'apis/Kickback/useCheckIsAffiliate'
import useSessionContext from 'domains/Auth/SessionContext'
import {useNavigateToGroupsPage} from 'hooks/navigation/useNavigateToGroupsDashboard'
import {PoshRoutes} from 'models/PoshRoutes'

function useOrdersNavigation() {
  const route = '/dashboard/orders'
  const isOnOrders = Boolean(useMatch(route))
  const navigate = useNavigate()
  return {
    navigate: () => navigate(route),
    isOnPage: isOnOrders,
  }
}

function useKickbackNavigation() {
  const route = '/dashboard/kickback'
  const navigate = useNavigate()

  // fetch affiliate status. If user is an affiliate, show kickback icon
  const {userId} = useSessionContext()
  const {data: isAnAffiliate, isSuccess} = useCheckIsAffiliate(userId!, {refetchOnMount: false, keepPreviousData: true}) // prevents the icon from flickering on page change
  const shouldShowKickback = isAnAffiliate && isSuccess

  const isInKickback = Boolean(useMatch(route))
  return {
    navigate: () => navigate(route),
    isOnPage: isInKickback,
    shouldShowKickback,
  }
}

function useOrganizationsNavigation() {
  const route = '/dashboard/orgs'
  const isInOrgsDashboard = Boolean(useMatch(route))
  const isInGroupsRoutes = Boolean(useMatch(PoshRoutes.GROUP_ROUTES))
  const navigate = useNavigate()
  const {navigateToGroupPage} = useNavigateToGroupsPage()
  return {
    navigate: () => {
      if (isInGroupsRoutes) navigateToGroupPage({page: 'overview'})
      else navigate(route)
    },
    isOnPage: isInOrgsDashboard || isInGroupsRoutes,
    isInOrgsDashboard,
    isInGroupsRoutes,
  }
}

function useExplorePageNavigation() {
  const route = PoshRoutes.EXPLORE
  const isOnExplore = Boolean(useMatch(route))
  const navigate = useNavigate()
  return {
    navigate: () => navigate(route),
    isOnPage: isOnExplore,
  }
}

export function usePageNavigation() {
  return {
    orders: useOrdersNavigation(),
    kickback: useKickbackNavigation(),
    organizations: useOrganizationsNavigation(),
    explore: useExplorePageNavigation(),
  }
}
