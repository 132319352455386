import React from 'react'

import {Eye, EyeClosed} from './Icons'

export function ToggleableEye({
  size = 24,
  visible,
  ...props
}: Omit<React.SVGProps<SVGSVGElement>, 'height' | 'width'> & {
  visible: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  size?: number
}) {
  return (
    <div style={{cursor: 'pointer'}} onClick={props.onClick}>
      {visible ? <Eye {...props} height={size} width={size} /> : <EyeClosed {...props} height={size} width={size} />}
    </div>
  )
}
