import React, {CSSProperties, ReactNode} from 'react'

const STYLE: CSSProperties = {
  background: 'linear-gradient(180deg, rgba(0, 133, 255, 0.24) 0%, rgba(235, 0, 255, 0) 100%)',
  width: '100%',
  height: '100%',
  paddingTop: '40px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}
const SMSCampaignBackground = ({children, center}: {children: ReactNode; center?: boolean}) => {
  if (center) {
    return <div style={{...STYLE, justifyContent: 'center'}}>{children}</div>
  }
  return <div style={STYLE}>{children}</div>
}

export default SMSCampaignBackground
