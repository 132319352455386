import {zobjectId} from '@posh/types'
import {z} from 'zod'

const CreateEventParamsSchema = z.object({
  groupId: zobjectId.optional(),
  pendingEventId: zobjectId.optional(),
})

export const useCreateEventParams = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const parsedParams = CreateEventParamsSchema.safeParse({
    groupId: queryParams.get('g') ?? undefined,
    pendingEventId: queryParams.get('p') ?? undefined,
  })
  if (!parsedParams.success) throw new Error('Invalid Create Event params')
  return parsedParams.data
}
