import {useEffect, useRef} from 'react'

type KeyTypes = 'Escape' | 'Enter' | 'Tab'

function useComponentButtonListener(key: KeyTypes, onPress: () => void) {
  const componentRef = useRef(null)

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === key) onPress()
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [componentRef, onKeyDown])

  return componentRef
}

export default useComponentButtonListener
