import {useMemo} from 'react'

import {useGetSectionalData} from 'apis/Analytics/useGetSectionalData'
import {ReactQueryOptions, RouterInputs, trpc} from 'lib/trpc'
import moment from 'moment'
import {getTimeSeriesConfiguration} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Overview/getTimeSerisConfiguration'

export type FetchTimeSeriesAnalyticsApiInput = RouterInputs['events']['fetchEventOrdersTimeSeriesAnalytics']
type FetchTimeSeriesAnalyticsApiOptions = ReactQueryOptions['events']['fetchEventOrdersTimeSeriesAnalytics']
type FetchTimeSeriesInterval = FetchTimeSeriesAnalyticsApiInput['timeSeriesConfig']['interval']

// Only use in event settings page :)
type FetchTimeSeriesAnalyticsInput = Omit<FetchTimeSeriesAnalyticsApiInput, 'timeSeriesConfig' | 'timeSeries'> & {
  timeSeries: FetchTimeSeriesAnalyticsApiInput['timeSeries'][number]
} & {
  startDate: Date
  endDate: Date
  isRSVPEvent?: boolean
}

const getGraphLabelFormat = ({
  timeInterval,
  isInDifferentYear,
}: {
  timeInterval: FetchTimeSeriesInterval
  isInDifferentYear: boolean
}) => {
  if (timeInterval === 'hour') {
    return 'hh:mm a'
  } else if (timeInterval === 'day') {
    return 'MMM D'
  } else if (timeInterval === 'week') {
    return `[Week of] MMM D ${isInDifferentYear ? "[']YY" : ''}`
  }

  return 'not currently supported'
}

const now = moment()

export const useFetchTimeSeriesAnalytics = (
  input: FetchTimeSeriesAnalyticsInput,
  apiOptions?: FetchTimeSeriesAnalyticsApiOptions,
) => {
  const {timeSeries, startDate, endDate, isRSVPEvent = false, ...apiInput} = input

  const timeSeriesConfig = useMemo(() => {
    return getTimeSeriesConfiguration({startDate, endDate})
  }, [endDate, startDate])

  const {
    data: serverData,
    isLoading: isGraphDataLoading,
    isError: isGraphDataError,
    isSuccess: isGraphDataSuccess,
    error: graphDataError,
  } = trpc.events.fetchEventOrdersTimeSeriesAnalytics.useQuery(
    {
      ...apiInput,
      timeSeries: isRSVPEvent ? ['tickets'] : [timeSeries],
      timeSeriesConfig,
    },
    apiOptions,
  )

  const graphData = serverData?.[0]?.data.map(dataPoint => {
    const momentDataPoint = moment(dataPoint.time)
    const isInDifferentYear = !momentDataPoint.isSame(now, 'year')
    const label = momentDataPoint.format(
      getGraphLabelFormat({timeInterval: timeSeriesConfig.interval, isInDifferentYear}),
    )
    return {
      label,
      value: dataPoint.value,
    }
  })

  const {
    data: crossSectionData,
    isLoading: isCrossSectionLoading,
    isError: isCrossSectionError,
    error: crossSectionError,
    isSuccess: isCrossSectionSuccess,
  } = useGetSectionalData({
    eventId: input.eventId,
    groupId: input.groupId,
    dataPoints: isRSVPEvent ? ['totalRSVP'] : ['totalGmv', 'totalTicketsSold'],
    timeSeriesConfig,
  })

  return {
    crossSectionData,
    isCrossSectionLoading,
    isCrossSectionSuccess,
    isCrossSectionError,
    crossSectionError,
    graphDataError,
    graphData,
    isGraphDataLoading,
    isGraphDataSuccess,
    isGraphDataError,
  }
}
