import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {IPermissionConstraints} from '.'
import {RoleKey} from './Role'

export interface EditPermissionResponse {
  success?: boolean
  error?: string
}

export interface EditPermissionProps {
  roleKey: RoleKey
  accountRoleId: string
  assignedBy: string
  permissionConstraints: IPermissionConstraints
}

async function editAccountPermissions(params: EditPermissionProps): Promise<EditPermissionResponse> {
  const response = await PoshApi.editAccountPermissions(
    params.roleKey,
    params.accountRoleId,
    params.assignedBy,
    params.permissionConstraints,
  )
  return response.data
}

export default function useEditAccountPermissions() {
  return useMutation<EditPermissionResponse, unknown, EditPermissionProps>(params => editAccountPermissions(params))
}
