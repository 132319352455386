import React from 'react'
import {Navigate, Outlet, Route, RouteProps, useLocation, useNavigate} from 'react-router-dom'

import {EventModel} from 'apis/Events/types'
import {includes} from 'lodash'

import {AccountModel} from '../../apis/Accounts'
import {AccountDomain} from '../../apis/Roles/AccountDomain'
import useSessionContext from '../../domains/Auth/SessionContext'
import {useResourcePageParams} from '../../pages/PoshAppLayout'

const isValidAccountGroupRole = (domain: AccountDomain, account: AccountModel, groupId: string) => {
  const groupRoleMap = account.groups.find(g => g.groupID == groupId)
  if (!groupRoleMap) return false

  return groupRoleMap.role == domain
}

const isValidAccountEventRole = (domain: AccountDomain, account: AccountModel, event: EventModel) => {
  if (domain == AccountDomain.HOST) {
    const eventTrackingLinks = event.trackingLinks.map(t => t.value)
    return includes(eventTrackingLinks, account.trackingLink)
  }

  return isValidAccountGroupRole(domain, account, event.groupID)
}

export const AuthorizedGroupRoute = (props: RouteProps) => {
  const {domain, groupId} = useResourcePageParams()
  const {currentUser} = useSessionContext()
  const navigate = useNavigate()

  const search = useLocation().search
  const wvAuth = new URLSearchParams(search).get('wvAuth')

  if ((!currentUser || !groupId) && !wvAuth) navigate(`/dashboard`)
  return <Route {...props} />
}

export const NewAuthorizedGroupRoute = () => {
  const {domain, groupId} = useResourcePageParams()
  const {currentUser} = useSessionContext()

  const search = useLocation().search
  const wvAuth = new URLSearchParams(search).get('wvAuth')

  if ((!currentUser || !groupId) && !wvAuth) return <Navigate to='/dashboard' />
  return <Outlet />
}

interface AuthorizedEventRouteProps extends RouteProps {
  event: EventModel
}

export const AuthorizedEventRoute = (props: AuthorizedEventRouteProps) => {
  const {domain} = useResourcePageParams()
  const {currentUser} = useSessionContext()

  const search = useLocation().search
  const wvAuth = new URLSearchParams(search).get('wvAuth')

  if (!currentUser && !wvAuth) return <Navigate to={'/dashboard'} />

  return <Route {...props} />
}

export const NewAuthorizedEventRoute = () => {
  const {domain} = useResourcePageParams()
  const {currentUser} = useSessionContext()

  const search = useLocation().search
  const wvAuth = new URLSearchParams(search).get('wvAuth')

  if (!currentUser && !wvAuth) return <Navigate to={'/dashboard'} />

  return <Outlet />
}
