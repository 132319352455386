import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

export function useUpdateOwnAccount() {
  return trpc.accounts.updateOwnAccount.useMutation<Response | undefined>()
}

type UpdatePasswordOptions = ReactQueryOptions['accounts']['updatePassword']
export type UpdatePasswordInput = RouterInputs['accounts']['updatePassword']
export type UpdatePasswordOutput = RouterOutput['accounts']['updatePassword']

export function useUpdatePassword(opts?: UpdatePasswordOptions) {
  return trpc.accounts.updatePassword.useMutation(opts)
}
