import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {OrderAndName, OrderModel, OrderPageModel} from '.'

export async function fetchOrder(orderId: string): Promise<OrderModel> {
  const response = await PoshApi.fetchOrder(orderId)
  const data = response.data
  return data.order
}

export async function fetchOrderPageData(orderId: string): Promise<OrderPageModel> {
  const response = await PoshApi.fetchOrderPageData(orderId)
  const data: OrderPageModel = response.data
  return data
}

export async function fetchOrderAndName(orderId: string): Promise<OrderAndName> {
  const response = await PoshApi.fetchOrderAndName(orderId)
  const data = response.data
  return data.orderAndName
}

export function useFetchOrder(orderId?: string) {
  return useQuery(['order', orderId], () => fetchOrder(orderId ?? ''), {
    enabled: !!orderId,
  })
}
export function useFetchOrderPageData(orderId?: string, opts?: UseQueryOptions<OrderPageModel>) {
  return useQuery<OrderPageModel>(['order', orderId], () => fetchOrderPageData(orderId ?? ''), {
    ...opts,
    enabled: !!orderId,
    retry: false,
  })
}
export function useFetchOrderAndName(orderId?: string) {
  return useQuery(['orderAndName', orderId], () => fetchOrderAndName(orderId ?? ''), {
    enabled: !!orderId,
  })
}
