import React, {useState} from 'react'

import {CommunityCategory} from 'apis/Community'
import Footer from 'components/DashboardFooter'

import CommunityConfirmationPage from '../CommunityConfirmationPage'
import CommunityForm from './CommunityForm'
import OnboardingProgressBar from './OnboardingProgressBar'

import './styles.scss'

const CommunityOnboarding = () => {
  const [completedSteps, setCompletedSteps] = useState<number>(0)
  const [communityCategory, setCommunityCategory] = useState<CommunityCategory | undefined>(undefined)
  const totalSteps = communityCategory === 'staff' ? 6 : 7
  const [showConfirmationPage, setShowConfirmationPage] = useState(false)

  return showConfirmationPage ? (
    <CommunityConfirmationPage communityCategory={communityCategory} />
  ) : (
    <>
      <div className='OnboardingFormCont'>
        <OnboardingProgressBar totalSteps={totalSteps} completedSteps={completedSteps} />
        <CommunityForm
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
          setCommunityCategory={setCommunityCategory}
          setShowConfirmationPage={setShowConfirmationPage}
        />
      </div>
      <Footer />
    </>
  )
}

export default CommunityOnboarding
