/* eslint-disable */
import {useCookies} from 'react-cookie'
import isDevEnv from 'helpers/isDevEnv'
import mixpanel from 'mixpanel-browser'

import {useParams, useSearchParams} from 'react-router-dom'
import {KickbackOffer} from './Kickback/KickbackOffer'
import {useQueryParams} from 'hooks/QueryParams/useQueryParams'
import {
  CreateEventMixpanelEvents,
  DEPRECATEDCreateEventMixpanelEvents,
} from './mixpanel-events/createEventMixpanelEvent'
import {TEventPlaylistMixpanelEvents} from './mixpanel-events/eventPlaylistMixpanelEvents'
import {KickbackOfferType} from 'pages/EventManagementPages/Kickstart/types'

const MIXPANEL_PROD = '47aa822b8957d1c9d02e17d63d5a6764'
const MIXPANEL_DEV = '19f4a355a01a89aa72bcc9d5a14ab727'

export type GuestlistNotificationClickWhere = 'profile_pic' | 'profile_name' | 'event_name' | 'event_flyer'
type PreviewProfileModalClick = 'purchase_tickets' | 'rsvp_event' | 'instagram' | 'view_profile'

type IOverload = {
  (eventPage: 'Unlike Click', mixpanelEventParams: {whichGuest: string}): void
  (
    eventPage: 'Community Booking Creation',
    mixpanelEventParams: {
      accountName: string
      accountEmail: string
      accountId: string
      communityName: string
      communityCategory: string
    },
  ): void
  (
    eventPage: 'Community Booking Request Canceled',
    mixpanelEventParams: {
      accountName: string
      accountEmail: string
      accountId: string
      communityName: string
      communityCategory: string
    },
  ): void
  (
    eventPage: 'Community Booking Request Accepted',
    mixpanelEventParams: {
      accountName: string
      accountEmail: string
      accountId: string
      communityName: string
      communityCategory: string
    },
  ): void
  (
    eventPage: 'Community Tab Clicked',
    mixpanelEventParams: {communityCategory: string; accountName?: string; accountEmail?: string; accountId?: string},
  ): void
  (
    eventPage: 'Community Profile Clicked',
    mixpanelEventParams: {
      communityCategory: string
      communityName: string
      accountEmail?: string
      accountId?: string
      accountName?: string
    },
  ): void
  (
    eventPage: 'Community Account Created',
    mixpanelEventParams: {communityCategory: string; communityName: string},
  ): void
  (
    eventPage: 'Guestlist Like Notification Click',
    mixpanelEventParams: {
      where: GuestlistNotificationClickWhere
      whoClickedId: string
      whoLikedUrl: string
      eventUrl: string
    },
  ): void
  (
    eventName: 'Guestlist Profile Click',
    mixpanelEventParams: {whichGuest: string; clickerWasAttendingEvent: boolean},
  ): void
  (
    eventName: 'Like Click',
    mixpanelEventParams: {
      sucessfullyLikedUser: boolean
      whichGuest: string
      within: 'guestlist' | 'profile_preview_modal'
    },
  ): void
  (
    eventName: 'Add Profile Pic Modal Click',
    mixpanelEventParams: {where: 'choose_image' | 'cancel' | 'confirm_and_upload_image'},
  ): void
  (
    eventName: 'Profile Preview Modal Click',
    mixpanelEventParams: {whichProfile: string; where: PreviewProfileModalClick; wasInPaywall: boolean},
  ): void
  (eventName: 'Event Link Copied', mixpanelEventParams: {link: string}): void
  (eventName: 'Create New Event-  Curator side bar'): void
  (eventName: 'Create New Event -  Curator overview above recent orders'): void
  (eventName: 'Revert Event to Draft-  Curator Event List Table item', mixpanelEventParams: {eventId: string}): void
  (eventName: 'Go Live Event-  Curator Event List Table item', mixpanelEventParams: {eventId: string}): void
  (eventName: 'Edit Event-  Curator Event List Table item', mixpanelEventParams: {eventId: string}): void
  (eventName: 'View Event-  Curator Event List Table item', mixpanelEventParams: {eventId: string}): void
  (eventName: 'Duplicate Event-  Curator Event List Table item', mixpanelEventParams: {eventId: string}): void
  (eventName: 'Delete Event-  Curator Event List Table item', mixpanelEventParams: {eventId: string}): void
  (
    eventName: 'Create Kickback Offer-  Curator Event Kickback Page',
    mixpanelEventParams: Partial<Omit<KickbackOffer, '_id'>>,
  ): void
  (eventName: 'Kickback Add Affiliate-  Curator Event Kickback Page Home'): void
  (eventName: 'Kickback Create Offer-  Curator Event Kickback Page Home Header Buttons', mixpanelEventParams: {kickbackType: KickbackOfferType}): void
  (eventName: 'Kickback Settings-  Curator Event Kickback Page Home'): void
  (
    eventName: 'Kickback Add Affiliate Posh User Update Button-  Curator Event Kickback Page Add Modal',
    mixpanelEventParams: {addedAccountId: string},
  ): void

  (eventName: 'Kickback Receipt CTA Change Edit-  Curator Event Kickback Page Settings'): void
  (eventName: 'Kickback Receipt CTA Text Edit-  Curator Event Kickback Page Settings'): void
  (eventName: 'Toggle Kickback Activation-  Curator Kickback Offer Edit Page'): void

  (
    eventName: 'Checkout Clicked-  Event Page',
    mixpanelEventParams: {cartTotal: number; currency?: string; cartItems: number},
  ): void
  (eventName: 'Checkout Promo Code Submit-  Checkout Page'): void
  (eventName: 'Checkout Select Existing Payment Card-  Checkout Page'): void
  (eventName: 'Checkout Clicked-  Checkout Page', mixpanelEventParams: {source: string}): void
  (eventName: 'Add Ticket Button-  Event Page'): void

  (eventName: 'Join Posh Sign Up-  Sign Up Join Posh Page'): void
  (eventName: 'I want to create an event button-  Sign up routing page'): void
  (eventName: 'Throw Your Own Event Button-  User profile page'): void
  (eventName: 'Upload Brand Logo Success-  Create Organization Page'): void
  (eventName: 'Continue Button-  Create Organization Page'): void
  (eventName: 'Add new card button-  Checkout Page'): void
  (eventName: 'Kickback Icon Button-  Event Page'): void

  (eventName: 'Create Kickback Attendee-  Event Page'): void
  (eventName: 'Kickback Icon-  Ticket Receipt Page'): void
  (eventName: 'Link Copy-  Attendee Kickback Offer'): void
  (eventName: 'Outlook Calendar -  Add to calendar modal'): void
  (eventName: 'Google Calendar-  Add to calendar modal'): void
  (eventName: 'Apple Calendar -  Add to calendar modal'): void
  (eventName: 'View Event Page-  Receipt Page'): void
  (
    eventName: 'Event Click-  Explore Page',
    mixpanelEventParams: {eventUrl: string; when?: string; where?: string; sort?: string},
  ): void
  (eventName: 'Get Tickets Button-  Explore Page', mixpanelEventParams: {eventUrl: string}): void
  (eventName: 'Unlock Hidden Tickets-  Event Page'): void
  (eventName: 'View Artist-  Event Page', mixpanelEventParams: {artistName: string; artistLink: string}): void
  (eventName: 'Launch an Event Bottom-  Home Page'): void
  (eventName: 'Explore Events Bottom-  Home Page'): void
  (eventName: 'Checkout Failed Error-  Checkout Page', mixpanelEventParams: {message: string}): void
  (eventName: 'Checkout Success-  Checkout Page'): void

  (eventName: 'Text Blast- Send Click', mixpanelEventParams: Record<string, any>): void
  (eventName: 'Text Blast- Cancel Click', mixpanelEventParams: Record<string, any>): void
  (eventName: 'Text Blast- View & Edit Recipients', mixpanelEventParams: Record<string, any>): void
  (eventName: 'Text Blast- Remove Recipient Click'): void
  (eventName: 'Text Blast- Continue to Campaign Click', mixpanelEventParams: Record<string, any>): void
  (eventName: 'Text Blast- Breakdown Recipients View'): void
  (eventName: 'Text Blast- Breakdown Details View', mixpanelEventParams: {textBlastWasSentAt: string}): void
  (eventName: 'Text Blast- SMS Campaign on Group Overview Click'): void
  (eventName: 'Text Blast- SMS Blasts View More Click'): void

  (eventName: 'Checkout Cart Icon Clicked-  Checkout Page'): void
  (eventName: 'Phone/Email Checkout Toggle-  Checkout Signin', mixpanelEventParams: {selectedMethod: string}): void
  (eventName: 'Phone Auth Clicked-  Phone Auth Form'): void
  (eventName: 'Phone Auth Success-  Phone Auth Form'): void
  (eventName: 'Phone Auth Fail-  Phone Auth Form', mixpanelEventParams: {error: string}): void

  (eventName: 'Email Auth Clicked-  Email Auth Form'): void
  (eventName: 'Email Auth Success-  Email Auth Form'): void
  (eventName: 'Email Auth Fail-  Email Auth Form'): void
  (eventName: 'Create New Account-  Email Auth Form'): void
  (eventName: 'Forgot Password-  Email Auth Form'): void
  (eventName: 'Signup RSVP-  Signup Checkout'): void
  (eventName: 'Signup Paid-  Signup Checkout'): void
  (eventName: 'Signup Success-  Signup Checkout'): void
  (eventName: 'Signup Failed-  Signup Checkout', mixpanelEventParams: any): void
  (eventName: 'Verify Phone Number Login Click-  Checkout'): void
  (eventName: 'Verify Phone Number Success-  Checkout'): void
  (eventName: 'Verify Phone Number Fail-  Checkout', mixpanelEventParams: {error: string}): void
  (eventName: 'Add to Calendar-  Ticket Receipt Page'): void
  (eventName: 'Show Receipt-  Ticket Receipt Page'): void
  (eventName: 'Zoom QR-  Ticket Receipt Page'): void
  (eventName: 'View and chat with guests-  Ticket Receipt Page'): void
  (eventName: 'Open kickback modal-  Ticket Receipt Page'): void
  (eventName: 'Add to Wallet-  Ticket Receipt Page Add to Wallet'): void
  (
    eventName: 'Select Category- Create Event Details Page',
    mixpanelEventParams: {parentCategory: string; subCategory: string},
  ): void
  (
    eventPage: 'Create Event Continue-  Stage 1 details page',
    mixpanelEventParams: {parentCategory?: string; subCategory?: string},
  ): void

  (eventName: 'Email Toggle-  Main login'): void
  (eventName: 'Phone Toggle-  Main login'): void
  (eventName: 'Dont Have An Account Signup-  Main Login'): void
  (eventName: 'Forgot Password-  Main Login'): void
  (eventName: 'Login Button Clicked-  Main Login'): void
  (eventName: 'Header Navigation Click-  Group Page', mixpanelEventParams: {page: string}): void
  (eventName: 'Header Analytics time frame Change Week-  Group Page'): void
  (eventName: 'Header Analytics time frame Change Month-  Group Page'): void
  (eventName: 'Header Analytics time frame Change All Time-  Group Page'): void
  (eventName: 'Header Analytics Tickets Chart Click-  Group Page'): void
  (eventName: 'Header Analytics Revenue Chart Click-  Group Page'): void
  (eventName: 'Order List View Order Click-  Order List Element', mixpanelEventParams: {expanded: boolean}): void
  (eventName: 'Header Bell Icon Click-  Group Page'): void
  (eventName: 'Organization Dashboard Click-  Curator Sidebar'): void
  (eventName: 'My Account Click-  Curator Sidebar'): void
  (eventName: 'Kickback Click-  Curator Sidebar'): void
  (eventName: 'Admin Dashboard Click-  Curator Sidebar'): void
  (eventName: 'My Orders Click-  Curator Sidebar'): void
  (eventName: 'Logout Click-  Curator Sidebar'): void
  (eventName: 'Org Dropdown Click-  Curator Sidebar'): void
  (eventName: 'Org Dropdown Organization Clicked-  Curator Sidebar'): void
  (eventName: 'Open QR Icon-  Orders Page'): void
  (eventName: 'Information Icon-  Orders Page'): void
  (eventName: 'Wallet Icon-  Orders Page'): void
  (eventName: 'Calendar Icon-  Orders Page'): void
  (eventName: 'Create Org Error-  Create Organization Page', mixpanelEventParams: {error: string}): void
  (
    eventName: 'Checkout Clicked-  Event Page',
    mixpanelEventParams: {
      cartTotal: number
      currency?: string
      cartItems: number
      guestlistEnabled: boolean
      guestlistNumber: number
      spotifySong: boolean
      lineup: number
      lightOrDarkMode: string
      purchaseOrRSVPAndPurchase: string
      lowestTicketPrice: number
      kickbackEnabled: boolean
      youtubeVideo: boolean
    },
  ): void
  (
    eventName: 'RSVP Button-  Event Page',
    mixpanelEventParams: {
      guestlistEnabled: boolean
      guestlistNumber: number
      spotifySong: boolean
      lineup: number
      lightOrDarkMode: string
      youtubeVideo: boolean
    },
  ): void
  (eventName: 'Instagram Profile Click- Attendee Guestlist Modal', mixpanelEventParams: {numberOfBadges: number}): void
  (
    eventName: 'Instagram Profile Click- Attendee/Curator Profile Page',
    mixpanelEventParams: {numberOfBadges: number},
  ): void
  (
    eventName: 'Profile Preview Modal Click',
    mixpanelEventParams: {
      whichProfile: string
      where: PreviewProfileModalClick
      wasInPaywall: boolean
      numberOfBadges: number
    },
  ): void
  (eventName: 'Event Click- Attendee/Curator Profile Page'): void
  (
    eventName: 'Event Click- Attendee/Curator Profile Page',
    mixpanelEventParams: {
      nextEvent: boolean
      upcomingEvent: boolean
      previousEvent: boolean
    },
  ): void
  (
    eventName: 'Like Click',
    mixpanelEventParams: {
      sucessfullyLikedUser: boolean
      whichGuest: string
      within: 'guestlist' | 'profile_preview_modal'
      numberOfBadges?: number
    },
  ): void
  (eventName: 'Guestlist Purchase/Rsvp Button Action-  Guestlist Click Modal'): void
  (
    eventName: 'Get Tickets Button-  Explore Page',
    mixpanelEventParams: {
      eventUrl: string
      numberOfEventsInFeaturedSection: string
      positionInFeaturedSection: string
      isGuestlistShown: string
      numberOfGuestsShown: string
    },
  ): void
  (
    eventName: 'Event Click-  Explore Page',
    mixpanelEventParams: {eventUrl: string; when?: string; where?: string; sort?: string; rankingInExplore: string},
  ): void
  (eventName: 'FAQ Click- Landing Page', mixpanelEventParams: {buttonText: string}): void
  (eventName: 'Profile Name Click- Notifications Tab', mixpanelEventParams: {notificationType: string}): void
  (eventName: 'Event Click- Notifications Tab', mixpanelEventParams: {notificationType: string}): void
  (eventName: 'Shortlink Click- Curator Event Overview Page', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'Copy Link- Curator Event Overview Page', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'Revert to Draft- Curator Event Overview Page'): void
  (eventName: 'View Event Page Click- Curator Event Visuals Page', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'Add Ticket Type- Curator Event Ticketing', mixpanelEventParams: {eventStatus: string}): void
  (
    eventName: 'Create Ticket Type- Add Ticket Type Page',
    mixpanelEventParams: {
      quantityAvailable: number | null
      minPurchaseQuantity: number | null
      maxPurchaseQuantity: number | null
      passwordProtected: boolean
      requireApproval: boolean
      hideTier: boolean
      hidePrice: boolean
      sellInMultiplies: boolean
      disabled: boolean
    },
  ): void
  (eventName: 'Create Ticket Group- Curator Event Ticketing', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'Create Ticket Group- Ticket Group Page', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'Delete Ticket Group- Ticket Group Page', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'CSV Upload- Curator Event Ticketing', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'Send Tickets- Curator Event Ticketing', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'Send to Non-Posh User- Complimentary Tickets Modal', mixpanelEventParams: {eventStatus: string}): void
  (
    eventName: 'Send Tickets- Complimentary Tickets Modal',
    mixpanelEventParams: {numberOfTickets: number; eventStatus: string},
  ): void
  (
    eventName: 'Continue Click- Complimentary Tickets Non-Posh User Modal',
    mixpanelEventParams: {eventStatus: string},
  ): void
  (eventName: 'X Click- Complimentary Tickets Non-Posh User Modal', mixpanelEventParams: {eventStatus: string}): void
  (
    eventName: 'SMS Blast Click- Curators Attendees Page',
    mixpanelEventParams: {numberOfAttendees: number; allSelected: boolean; eventStatus: string},
  ): void
  (
    eventName: 'Apply Filters- Curators Attendees Filter Modal',
    mixpanelEventParams: {sortBy: string; maleOrFemale: string | null; eventStatus: string},
  ): void
  (eventName: 'Create New Tag- Curators Attendees Page Tag Modal', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'Add Team Member - Curator Event Team Page', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'X Click- Event Team Non-Posh User Modal', mixpanelEventParams: {eventStatus: string}): void
  (eventName: 'Continue Click- Event Team Non-Posh User Modal', mixpanelEventParams: {eventStatus: string}): void
  (
    eventName: 'Add Role- Event Team Non-Posh User Modal',
    mixpanelEventParams: {teamMemberRole: string; eventStatus: string},
  ): void
  (eventName: 'Overview Sidebar Click- Event Edit Page'): void
  (eventName: 'Visuals Sidebar Click- Event Edit Page'): void
  (eventName: 'Tickets Sidebar Click- Event Edit Page'): void
  (eventName: 'Attendees Sidebar Click- Event Edit Page'): void
  (eventName: 'Team Sidebar Click- Event Edit Page'): void
  (eventName: 'Kickback Sidebar Click- Event Edit Page'): void
  (eventName: 'Settings Sidebar Click- Event Edit Page'): void
  (eventName: 'View Event Page Sidebar Click- Event Edit Page'): void
  (eventName: 'Explore Nav Click- Mobile Web Navigation Bar'): void
  (eventName: 'Group Overview Click- Mobile Web Navigation Bar'): void
  (eventName: 'My Orders Click- Mobile Web Navigation Bar'): void
  (eventName: 'Kickback Dashboard Click- Mobile Web Navigation Bar'): void
  (eventName: 'Profile Settings Click- Mobile Web Navigation Bar'): void
  (
    eventName: 'Save Changes Click- Curator Event Visuals Page',
    mixpanelEventParams: {
      eventStatus: string
      flyerChanged: boolean
      accentColorChanged: boolean
      lightDarkModeChanged: boolean
      youtubeVideoAddedChanged: boolean
      eventSongAddedChanged: boolean
      venuePicturesAddedChanged: boolean
    },
  ): void
  (
    eventName: 'Send Click- Curators Attendees SMS Campaign Page',
    mixpanelEventParams: {
      insertFieldUsed: boolean
      eventLinkAttached: boolean
    },
  ): void
  (eventName: 'Signup Failure- Sign Up Join Posh Page', mixpanelEventParams: {reasonForFailure: string}): void
  (eventName: 'Signup Success- Sign Up Join Posh Page'): void
  (eventName: 'Already Have An Account? Login- Sign Up Join Posh Page'): void
  (eventName: 'Receipt Page View- Attendee Receipt Page', mixpanelEventParams: {kickbackEnabled: boolean}): void
  (eventName: 'Upload Image- Add Profile Pic Receipt Modal'): void
  (eventName: 'No Thanks- Add Profile Pic Receipt Modal'): void
  (eventName: 'Create Your Link- Receipt Page Kickback Modal'): void
  (eventName: 'Navigate to Kickback Dashboard- Receipt Page Kickback Modal'): void
  (
    eventName: 'Finish Connecting Card Button-  User Kickbacks Config Page',
    mixpanelEventParams: {buttonText: string},
  ): void
  (
    eventName: 'Expand Kickback Link Row-  Kickback Active Link Page',
    mixpanelEventParams: {cardConnected: boolean},
  ): void
  (eventName: 'Kickback Link Copy- Kickback Dashboard Page'): void
  (eventName: 'Go Live- After Create Event Ready To Go Live Page'): void
  (eventName: 'Save Draft-  After Create Event Ready To Go Live Page'): void
  (eventName: 'Copy Link-  After Create Event Ready To Go Live Page'): void
  (eventName: 'QR Code Click-  After Create Event Ready To Go Live Page'): void
  (eventName: 'View Event-  After Create Event Ready To Go Live Page'): void
  (eventName: 'Configure Settings-  After Create Event Ready To Go Live Page'): void
  (eventName: 'Customize Visuals-  After Create Event Ready To Go Live Page'): void
  (eventName: 'Invite Team Members-  After Create Event Ready To Go Live Page'): void
  (eventName: 'App Download Button- Hero Landing Page'): void

  (eventName: 'Email Click- Create Event Enter Email/Phone Modal'): void
  (eventName: 'Phone Click- Create Event Enter Email/Phone Modal'): void
  (eventName: 'Create Event Enter Phone Continue- Enter Your Phone Modal'): void
  (eventName: 'Create Event Phone Verification Continue Button- Phone Verification Modal'): void
  (
    eventName: 'Signup Failure- Almost there last step to launch event and for new posh users, email',
    mixpanelEventParams: {errorMessage: string},
  ): void
  (eventName: 'Signup Success- Almost there last step to launch event and for new posh users, email'): void
  (
    eventName: 'Signup Failure- Almost there last step to launch event and for new posh users, phone',
    mixpanelEventParams: {errorMessage: string},
  ): void
  (eventName: 'Signup Success- Almost there last step to launch event and for new posh users, phone'): void
  (eventName: 'Copy Link Button- New RSVP Event Page'): void
  (eventName: 'Edit Event Button- New RSVP Event Page'): void
  (eventName: 'Error Page View - Lil Terrio'): void
  (eventName: 'New SMS Campaign- Group Marketing Page'): void
  (eventName: 'View SMS Campaign- Group Marketing Page'): void
  (
    eventName: 'SMS Blast Click- Group Marketing Page',
    mixpanelEventParams: {allSelected: boolean; numAttendees: number},
  ): void
  (eventName: 'Add Team Member- Group Team Page'): void
  (eventName: 'Exact Match Team Add- Group Team Page', mixpanelEventParams: {email?: string; phone?: string}): void
  (eventName: 'Continue Click- Group Non-Posh User Modal'): void
  (eventName: 'Add Click- Group Team Select Role Modal', mixpanelEventParams: {role: string}): void
  (eventName: 'X Click- Group Non-Posh User Modal'): void
  (eventName: 'Transfer Balance- Curator Finance Page', mixpanelEventParams: {availableBalance: string}): void
  (eventName: '2-3 Biz Days- Initiate Payout Finance Page', mixpanelEventParams: {transferAmount: number}): void
  (eventName: 'Instant Payout- Initiate Payout Finance Page', mixpanelEventParams: {transferAmount: number}): void
  (eventName: 'Check Disputes- Curator Finance Page'): void
  (eventName: 'View Order Info- Curator Dispute Page'): void
  (eventName: 'Email Disputer- Curator Dispute Page'): void
  (eventName: 'View Profile Click- My Account'): void
  (eventName: 'Account Settings- My Account'): void
  (eventName: 'Logout Click- My Account'): void
  (eventName: "I'm Sure Delete Event- Curator Event Delete Modal"): void
  (eventName: 'Event Page View- Event Page'): void
  (eventName: 'Login Error-  Main Login', mixpanelEventParams: {error: string}): void
  (eventName: `Event Visuals Upgrade Deprecation Takeover- ${'New' | 'Legacy'} editor- ${string}`): void // TODO EVENT_VISUALS_UPGRADE_DEPRECATION Remove this
  (eventName: `Event Visuals Intro Modal- ${'New' | 'Legacy'} editor`): void // TODO EVENT_VISUALS_UPGRADE_DEPRECATION Remove this
} & DEPRECATEDCreateEventMixpanelEvents &
  CreateEventMixpanelEvents &
  TEventPlaylistMixpanelEvents

export const useMixpanel = () => {
  const params = useParams()
  const {queryParams} = useQueryParams()
  const isDev = isDevEnv()
  const [cookies] = useCookies(['campaignId', 'gclid'])

  const trackEvent: IOverload = (eventName: string, mixpanelEventParams?: Record<string, any>): void => {
    try {
      const token = isDev ? MIXPANEL_DEV : MIXPANEL_PROD
      mixpanel.init(token)
      const userId = localStorage.getItem('userId')
      userId && mixpanel.identify(userId)
      const userStorage = localStorage.getItem('currentUser')
      const id = cookies.campaignId
      const gclidCookie = cookies.gclid

      const campaignId = id ? decodeURIComponent(id).replace(/j:/g, '').replace(/"/g, '') : undefined
      const gclid = gclidCookie ? decodeURIComponent(gclidCookie).replace(/j:/g, '').replace(/"/g, '') : undefined
      const categoryParams = {
        parentCategory: queryParams.get('parentCategory'),
        subCategory: queryParams.get('subCategory'),
      }

      if (userId && userStorage) {
        const user = JSON.parse(userStorage)
        mixpanel.people.set({
          $first_name: user.firstName,
          $last_name: user.lastName,
          $email: user.email,
          userId,
        })
        if (gclid || campaignId) {
          mixpanel.people.union({
            campaignId,
            gclid,
          })
        }
      }

      mixpanel.track(eventName, {
        ...mixpanelEventParams,
        campaignId,
        gclid,
        userId,
        ...params,
        ...(categoryParams.parentCategory ? categoryParams : {}),
      })
    } catch (error) {
      console.error(error)
      return
    }
  }
  return {
    trackEvent,
  }
}
