import {useMutation, useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export async function getAccountPaymentLink(accountId: string): Promise<string> {
  const response = await PoshApi.fetchAccountPaymentLink(accountId)
  const data = response.data
  return data.url
}

export function useFetchPortalLink(userId: string | null | undefined, isPaymentPortal?: boolean) {
  return useQuery(['paymentLink', userId], () => getAccountPaymentLink(userId ?? '1'), {
    enabled: !!isPaymentPortal && !!userId,
  })
}

export function useFetchPortalLinkWithMutation() {
  return useMutation((params: {userId: string}) => getAccountPaymentLink(params.userId))
}
