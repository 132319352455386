import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {Group} from '.'

export async function fetchGroup(groupId: string): Promise<Group> {
  const response = await PoshApi.fetchGroup(groupId)
  const data = response.data
  return data.group
}

export function useFetchGroup(groupId?: string) {
  return useQuery(['group', groupId], () => fetchGroup(groupId ?? ''), {
    enabled: !!groupId,
  })
}

export function useConditionFetchGroup(groupId?: string) {
  return useQuery(['group', groupId], () => {
    if (!groupId) return null
    return fetchGroup(groupId)
  })
}
