import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface VerifyNewLoginMethodParams {
  userId: string
  newLoginMethod: string
  loginMethod: 'Email' | 'Phone'
}

async function sendVerificationCode(params: VerifyNewLoginMethodParams): Promise<string> {
  const response = await PoshApi.sendVerificationCode(params)
  return response.data.message
}

export default function useSendVerificationCode() {
  return useMutation((params: VerifyNewLoginMethodParams) => sendVerificationCode(params))
}

export interface CheckVerificationCodeAndUpdateAccountParams {
  userId: string
  newLoginMethod: string
  loginMethod: 'Email' | 'Phone'
  token: string
  password?: string
}

async function checkVerificationCodeAndUpdateAccount(
  params: CheckVerificationCodeAndUpdateAccountParams,
): Promise<string> {
  const response = await PoshApi.checkVerificationCodeAndUpdateAccount(params)
  return response.data.message
}

export function useCheckVerificationCodeAndUpdateAccount() {
  return useMutation((params: CheckVerificationCodeAndUpdateAccountParams) =>
    checkVerificationCodeAndUpdateAccount(params),
  )
}
