import React, {ChangeEvent, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {useQueryClient} from '@tanstack/react-query'
import {Coordinate, CreateEventTableParams, EventTable} from 'apis/Events/types'
import useFetchEvent, {useFetchEventByUrl} from 'apis/Events/useFetchEvent'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import useCartContext from 'domains/Cart/CartContext'
import {includes, isNull} from 'lodash'
import isUndefined from 'lodash/isUndefined'

import useCreateEventTable from '../../../../apis/Events/useCreateEventTable'
import {audienceUrl, deleteUrl, moneyBagUrl} from '../../../../components/assets/Icons'
import Button from '../../../../components/form/Button'
import {useResourcePageParams} from '../../../PoshAppLayout'
import {getCurrencySymbol} from '../../../Util/getCurrencySymbol'

import './styles.scss'

const MoneyBagIcon = () => <PoshImage className='TableItemModal-icon' src={moneyBagUrl} />
const PersonsIcon = () => <PoshImage className='TableItemModal-icon' src={audienceUrl} />
const CloseIcon = () => <PoshImage className='TableItemModal-icon small clickable' src={deleteUrl} />

interface TableItemModalProps {
  tables?: EventTable[]
  onClose: () => void
  isPurchasing?: boolean
  tablePosition: Coordinate
  onPrimaryActionClicked: (eventTables: EventTable[]) => void
  onDisableTable?: (eventTable: EventTable) => void
  onMarkTableSold?: (eventTable: EventTable) => void
  selectedFilterKey?: string
}

interface CreateEventTableFormParams {
  tablePosition: Coordinate
  onClose: () => void
  selectedFilterKey?: string
}

const CreateTableForm = (props: CreateEventTableFormParams) => {
  const {tablePosition, onClose, selectedFilterKey} = props
  const {eventId} = useResourcePageParams()
  const [createEventParams, setCreateEventParams] = useState<CreateEventTableParams>({})
  const {showToast} = useToast()
  const {mutateAsync: createEventTable, isLoading} = useCreateEventTable()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (
      selectedFilterKey &&
      !isUndefined(selectedFilterKey) &&
      !isNull(selectedFilterKey) &&
      selectedFilterKey !== 'Both'
    )
      setCreateEventParams({...createEventParams, filterKey: selectedFilterKey})
  }, [])

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setCreateEventParams({...createEventParams, name: value})
  }
  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setCreateEventParams({...createEventParams, description: value})
  }
  const handleSeatingLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    const number = Number(value)
    setCreateEventParams({...createEventParams, seatingLimit: number})
  }
  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    const number = Number(value)
    setCreateEventParams({...createEventParams, price: number})
  }

  const onCreateTable = async () => {
    const {name, price, description} = createEventParams
    if (name?.trim().length == 0 || isUndefined(price) || description?.trim().length == 0) {
      showToast({type: 'error', title: 'Name, Price, Description are required fields'})
    } else {
      await createEventTable({
        eventId: eventId!,
        tables: [
          {
            ...createEventParams,
            mapPosition: tablePosition,
          },
        ],
      })
      await queryClient.invalidateQueries(['event', eventId])
    }
  }

  return (
    <div className='TableItemModal'>
      <a className='TableItemModal-closeIcon' onClick={onClose}>
        <CloseIcon />
      </a>
      <div className='TableItemModal-inputField'>
        <label>Name</label>
        <input onChange={handleNameChange} className='TableItemModal-input' />
      </div>
      <div className='TableItemModal-inputField'>
        <label>Description</label>
        <input onChange={handleDescriptionChange} className='TableItemModal-input' />
      </div>
      <div className='TableItemModal-inputField'>
        <label>Seating Limit</label>
        <input onChange={handleSeatingLimitChange} placeholder={'0'} className='TableItemModal-input' type='number' />
      </div>
      <div className='TableItemModal-inputField'>
        <label>Price</label>
        <input onChange={handlePriceChange} placeholder={'0'} className='TableItemModal-input' type='number' />
      </div>
      <div className='TableItemModal-inputField'>
        {isLoading ? (
          <SpinLoader />
        ) : (
          <Button className='TableItemModal-button' onClick={onCreateTable}>
            Create Table
          </Button>
        )}
      </div>
    </div>
  )
}

export const TableItemModal = (props: TableItemModalProps) => {
  const {
    tables,
    onClose,
    isPurchasing,
    tablePosition,
    onPrimaryActionClicked,
    onDisableTable,
    onMarkTableSold,
    selectedFilterKey,
  } = props
  const {eventUrl: eventUrlParams} = useParams<{eventUrl: string}>()
  const {eventId, domain, groupId} = useResourcePageParams()
  const {data: eventData} = useFetchEvent(eventId!)
  const {data: eventDataPublic, isFetching: eventDataIsFetching} = useFetchEventByUrl(eventUrlParams)
  const currency = eventDataPublic?.group?.currency
  const navigate = useNavigate()
  const [isShowingFullDescription, setIsShowingFullDescription] = useState(false)
  const search = useLocation().search
  const firstTable = tables?.[0]
  const shouldShowPurchaseEdit = (): boolean => {
    if (!tables || !firstTable) return false
    else if (firstTable.purchaseData?.accountId) return false
    else if (eventDataPublic?.event.disableTablePurchaseButton && isPurchasing) return false
    else return true
  }

  const renderTableName = () => {
    if (tables && tables.length > 0) return tables.map(t => t.name).join(' and ')
    else if (firstTable) return firstTable.name
  }

  const renderTablePrice = () => {
    if (tables && tables.length > 0) return tables.map(t => t.price).reduce((a, b) => a + b, 0)
    else if (firstTable) return firstTable.price ?? 0
  }

  const renderTableDescription = () => {
    if (firstTable) return firstTable.description
  }

  const renderTableSeatingLimit = () => {
    if (firstTable) return firstTable.seatingLimit
  }

  const tableName = renderTableName()
  const tablePrice = renderTablePrice()?.toFixed(2)
  const tableDescription = renderTableDescription()
  const tableSeatingLimit = renderTableSeatingLimit()

  if (tables && firstTable) {
    const oneTable = tables.length === 1
    const purchaseData = firstTable.purchaseData
    return (
      <div className='TableItemModal'>
        <div className='TableItemModal-headerRow'>
          <p className='TableItemModal-name noMargin'>{tableName}</p>
          <a onClick={onClose}>
            <CloseIcon />
          </a>
        </div>
        <div className='TableItemModal-priceContainer'>
          <MoneyBagIcon />
          <p className='noMargin'>
            {getCurrencySymbol(currency)}
            {tablePrice}
          </p>
        </div>
        {tableSeatingLimit && (
          <div className='TableItemModal-seatingLimit'>
            <PersonsIcon />
            {tableSeatingLimit} person limit
          </div>
        )}
        {tableDescription && (
          <>
            {tableDescription?.length > 185 ? (
              <>
                {isShowingFullDescription ? (
                  <div className='TableItemModal-descriptionWrap'>
                    <p className='TableItemModal-description noMargin'>{tableDescription}</p>
                    <a
                      className='TableItemModal-showMore'
                      style={{marginTop: '0px'}}
                      onClick={() => {
                        setIsShowingFullDescription(false)
                      }}>
                      Show Less
                    </a>
                  </div>
                ) : (
                  <div className='TableItemModal-descriptionWrap'>
                    <p className='TableItemModal-shortenedDescription'>{tableDescription}</p>
                    <a
                      className='TableItemModal-showMore'
                      onClick={() => {
                        setIsShowingFullDescription(true)
                      }}>
                      Show More
                    </a>
                  </div>
                )}
              </>
            ) : (
              <div className='TableItemModal-descriptionWrap'>
                <p className='TableItemModal-description noMargin'>{tableDescription}</p>
              </div>
            )}
          </>
        )}
        {shouldShowPurchaseEdit() && (
          <Button onClick={() => onPrimaryActionClicked(tables)} className='TableItemModal-button'>
            {isPurchasing ? 'Add To Cart' : 'View / Edit Table'}
          </Button>
        )}
        {!isPurchasing && oneTable && !purchaseData?.accountId && (
          <Button
            onClick={() => (onDisableTable ? onDisableTable(firstTable) : null)}
            className={`TableItemModal-button ${firstTable.disabled ? '' : 'danger'}`}>
            {firstTable.disabled ? 'Enable Table' : 'Disable Table'}
          </Button>
        )}
        {!isPurchasing && oneTable && !purchaseData?.accountId && (
          <Button
            disabled={!!purchaseData}
            onClick={() => (onMarkTableSold ? onMarkTableSold(firstTable) : null)}
            className={`TableItemModal-button ${firstTable.sold ? '' : 'sold'}`}>
            {firstTable.sold ? 'Mark as Available' : 'Mark as Sold'}
          </Button>
        )}
        {!isPurchasing && oneTable && purchaseData?.accountId && (
          <Button
            onClick={() =>
              navigate(`/${domain}/groups/${groupId}/marketing/attendee/${purchaseData?.accountId}` + search)
            }
            className='TableItemModal-button'>
            View Purchase Data
          </Button>
        )}
      </div>
    )
  } else {
    return <CreateTableForm tablePosition={tablePosition} onClose={onClose} selectedFilterKey={selectedFilterKey} />
  }
}

interface TableItemProps {
  tables: EventTable[]
  onSelectTables: (eventTable: EventTable[]) => void
  disabled?: boolean
  isDeleting?: boolean
}

const TableItem = (props: TableItemProps) => {
  const {tables, onSelectTables, disabled, isDeleting} = props
  const onTrigger = () => {
    onSelectTables(tables)
  }
  const {cartItems} = useCartContext()
  const generateClassName = () => {
    if (tables.filter(t => t.disabled).length > 0) return 'disabled'
    else if (tables.filter(t => t.purchaseData?.accountId || t.sold).length > 0) return 'booked'
    else if (cartItems && cartItems.length > 0) {
      const cartItemIds = cartItems.map(item => item.resourceId)
      const tableItemIds = tables.map(item => item._id)
      const intersection = cartItemIds.filter(id => includes(tableItemIds, id))
      return intersection.length > 0 ? 'selectable' : 'available'
    } else return 'available'
  }

  const accessibilityClassName = disabled ? 'static' : 'clickable'

  return (
    <div className={`TableItem-container`}>
      <button
        className={`TableItem ${generateClassName()} ${accessibilityClassName} ${isDeleting ? 'is-deleting' : ''}`}
        onClick={onTrigger}
        disabled={disabled}
      />
    </div>
  )
}

export default TableItem
