import React, {useState} from 'react'

import {getGroupAvi} from '@posh/model-types'
import classNames from 'classnames'
import useCheckIfClickedOutsideDiv from 'components/helpers/clickedOutsideDiv'
import {PoshImage} from 'components/PoshImage/PoshImage'
import VerifiedGroupBadge from 'components/VerifiedGroupBadge'

import styles from './styles.module.scss'

type Group = {name: string; aviLocation: string | undefined; verified?: boolean}
export interface BaseNavigationOrgSelectProps {
  selectedIndex: number
  groups: Group[]
  onSelectGroup: (index: number) => void
  onPressCreateNewOrganization: () => void
  onExpand?: () => void
  className?: string
}

function CreateNewGroupButton(props: {onPressCreateNewOrganization: () => void}) {
  const {onPressCreateNewOrganization} = props
  return (
    <div className={styles.ListItem} onClick={onPressCreateNewOrganization}>
      <span style={{color: '#c0c1c3'}}>+ New Organization</span>
    </div>
  )
}

function GroupListItem(props: {group: Group}) {
  const {group} = props
  return (
    <div className={styles.GroupName}>
      <PoshImage className={styles.GroupAvi} src={getGroupAvi(group)} />
      <span>{group.name}</span>
      {group.verified && <VerifiedGroupBadge size={15} organizerView />}
    </div>
  )
}

function GroupsList(props: {
  groups?: Group[]
  onSelectGroup: (index: number) => void
  onPressCreateNewOrganization: () => void
}) {
  const {groups = [], onSelectGroup, onPressCreateNewOrganization} = props

  return (
    <>
      {groups.map((group, index) => (
        <div
          key={index}
          className={classNames([styles.ListItem, styles.inMenu])}
          onClick={e => {
            e.stopPropagation()
            onSelectGroup(index)
          }}>
          <GroupListItem group={group} />
        </div>
      ))}
      <CreateNewGroupButton onPressCreateNewOrganization={onPressCreateNewOrganization} />
    </>
  )
}

/**
 * NOTE: In most cases you should not use the component directly and make a wrapping component instead.
 */
export function BaseNavigationOrgSelect(props: BaseNavigationOrgSelectProps) {
  const {selectedIndex, onSelectGroup, onPressCreateNewOrganization, onExpand, groups} = props
  const [isExpanded, setIsExpanded] = useState(false)

  const clickOutsideRef = React.useRef<HTMLDivElement>(null)

  useCheckIfClickedOutsideDiv(clickOutsideRef, () => setIsExpanded(false))

  const selectedGroup = groups[selectedIndex]

  return (
    <div
      className={classNames([props.className, styles.Container])}
      data-is-expanded={isExpanded}
      ref={clickOutsideRef}>
      {isExpanded ? (
        <GroupsList
          groups={groups}
          onSelectGroup={i => {
            setIsExpanded(false)
            onSelectGroup(i)
          }}
          onPressCreateNewOrganization={onPressCreateNewOrganization}
        />
      ) : (
        <div
          className={styles.ListItem}
          onClick={e => {
            e.stopPropagation()
            setIsExpanded(true)
            onExpand?.()
          }}>
          {selectedGroup ? (
            <GroupListItem group={selectedGroup} />
          ) : (
            <CreateNewGroupButton onPressCreateNewOrganization={onPressCreateNewOrganization} />
          )}
          <PoshImage className={styles.Arrow} src='https://images.posh.vip/b2/downward-arrow-grey.svg' />
        </div>
      )}
    </div>
  )
}
