import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'
import {PoshImageBackground} from 'components/PoshImage/PoshImage'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

import styles from './styles.module.scss'

interface EventVisualsBackgroundImageProps {
  flyer?: string
}

export const EventVisualsBackgroundImage = (props: PropsWithChildren<EventVisualsBackgroundImageProps>) => {
  const {flyer, children} = props
  const {
    flyerPlaceholder,
    palette: {lightmode},
  } = useEventVisualsContext()

  return (
    <div
      className={classNames(styles.VisualEditorBackgroundWrapper, {
        [styles.lightmode]: lightmode,
      })}>
      <PoshImageBackground
        className={styles.VisualEditorBackgroundImage}
        style={{backgroundImage: `url(${flyer ?? flyerPlaceholder})`}}
      />
      <div className={styles.contents}>{children}</div>
    </div>
  )
}
