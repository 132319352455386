import React, {useState} from 'react'

import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'

interface GalleryCarouselProps {
  images: string[]
}

const GalleryCarousel = (props: GalleryCarouselProps) => {
  const {images} = props
  const [range, setRange] = useState([0, 1])

  const isInRange = (index: number) => {
    return index >= range[0] && index <= range[1]
  }

  const shouldShowPrev = () => {
    return range[0] > 0 && images.length > 1
  }

  const shouldShowNext = () => {
    return range[1] < images.length - 1 && images.length > 1
  }

  return (
    <div className='GalleryCarousel'>
      {shouldShowPrev() && (
        <Button
          onClick={() => setRange([range[0] - 1, range[1] - 1])}
          className={'GalleryCarousel-arrow left evenPadding semiTransparent'}>
          <PoshImage src='https://images.posh.vip/psh-assets/icons/left+(2).png' />
        </Button>
      )}
      <div className='GalleryCarousel-images'>
        {images.map((image, index) => (
          <>{isInRange(index) && <PoshImage key={image} src={image} className='GalleryCarousel-image' />}</>
        ))}
      </div>
      {shouldShowNext() && (
        <Button
          onClick={() => setRange([range[0] + 1, range[1] + 1])}
          className={'GalleryCarousel-arrow right evenPadding semiTransparent'}>
          <PoshImage src='https://images.posh.vip/psh-assets/icons/right.png' />
        </Button>
      )}
    </div>
  )
}

export default GalleryCarousel
