import React, {PropsWithChildren, useState} from 'react'

import classNames from 'classnames'
import {motion} from 'framer-motion'

import {NavigationItem} from '../components/shared/NavigationItem'

import styles from './styles.module.scss'

export function PrimaryNavigationDesktop({children}: PropsWithChildren) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <motion.div layout='position' className={styles.SidebarStatic}>
      <div className={styles.Inner} onClick={() => setIsExpanded(v => !v)}>
        <div className={styles.PageNavigation}>
          <NavigationItem.Logo.Desktop isExpanded={isExpanded} onClick={() => setIsExpanded(v => !v)} />
          <NavigationItem.Page.Desktop.Organizations isExpanded={isExpanded} />
          <NavigationItem.Page.Desktop.Kickback isExpanded={isExpanded} />
          <NavigationItem.Page.Desktop.Explore isExpanded={isExpanded} />
          <NavigationItem.Page.Desktop.Orders isExpanded={isExpanded} />
        </div>
        <div className={classNames([styles.PageNavigation, styles.BottomButtons])}>
          <NavigationItem.Help.Desktop isExpanded={isExpanded} />
          <NavigationItem.CreateEvent.Desktop isExpanded={isExpanded} />
        </div>
      </div>
      {children && <div className={styles.Children}>{children}</div>}
    </motion.div>
  )
}
