import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {RouterInputs, trpc} from 'lib/trpc'

type LikeAttendeeInput = RouterInputs['events']['likeAttendee']

export const useLikeAttendee = () => {
  const {showToast} = useToast()
  const {fetchEventGuestlist} = trpc.useContext().events
  const {userId} = useSessionContext()
  return trpc.events.likeAttendee.useMutation({
    onMutate: async (input: LikeAttendeeInput) => {
      fetchEventGuestlist.cancel({eventId: input.eventId, viewerId: userId ?? ''})

      const previousGuestlist = fetchEventGuestlist.getData({
        eventId: input.eventId,
        viewerId: userId ?? '',
      })

      fetchEventGuestlist.setData({eventId: input.eventId, viewerId: userId ?? ''}, oldguestlist => {
        if (!oldguestlist) return oldguestlist
        return {
          guestlist: oldguestlist?.guestlist?.map(attendee => {
            if (attendee.attendeeAccountId === input.attendeeId) {
              return {...attendee, likedByViewer: true}
            } else {
              return attendee
            }
          }),
          nextCursor: oldguestlist?.nextCursor,
        }
      })
      return {previousGuestlist}
    },
    onError: (err, input, ctx) => {
      showToast({title: 'There was an issue liking attendee', subtitle: err.message, type: 'error'})
      if (ctx) {
        fetchEventGuestlist.setData({eventId: input.eventId, viewerId: userId ?? ''}, ctx.previousGuestlist)
      }
    },
  })
}
