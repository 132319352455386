import {useNavigate} from 'react-router-dom'

import {AccountRoleResponse} from 'apis/Roles'
import usePermissionsContext from 'domains/Teams/PermissionsContext'
import {useResourcePageParams} from 'pages/PoshAppLayout'

export type GroupOverviewPage =
  | 'overview'
  | 'overview/all-events'
  | 'marketing'
  | 'team'
  | 'community'
  | 'finance'
  | 'profile'
  | 'settings/general'
  | 'settings/integrations'
  | 'settings/webhooks'

type NavigateToGroupPageParams = {
  roleKey?: string
  groupId?: string
  page: GroupOverviewPage
}

export function useNavigateToGroupsPage() {
  const {setAccountRole} = usePermissionsContext()
  const pathParams = useResourcePageParams()
  const navigate = useNavigate()

  /* Gets the params for the path. If not provided, uses those in the path */
  const getParams = (params: NavigateToGroupPageParams) => {
    let roleKey = params.roleKey
    let groupId = params.groupId

    if (!roleKey) roleKey = pathParams.domain
    if (!groupId) groupId = pathParams.groupId

    if (!roleKey || !groupId) throw new Error('Missing domain or groupId when navigating to group page')
    return {roleKey, groupId, page: params.page}
  }

  /* Returns the path for navigating to the group */
  const getGroupPagePath = (params: NavigateToGroupPageParams) => {
    const {roleKey, groupId, page} = getParams(params)
    return `/${roleKey}/groups/${groupId}/${page}`
  }

  /* Navigates to the group page */
  const navigateToGroupPage = (params: NavigateToGroupPageParams) => navigate(getGroupPagePath(params))

  const navigateToGroupsDashboardAndSetRole = (params: {groupId: string; accountRole: AccountRoleResponse}) => {
    const {groupId, accountRole} = params
    setAccountRole(accountRole)
    navigateToGroupPage({
      groupId,
      roleKey: accountRole.roleKey,
      page: 'overview',
    })
  }

  return {navigateToGroupsDashboardAndSetRole, navigateToGroupPage, getGroupPagePath}
}
