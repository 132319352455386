import React from 'react'
import Loader from 'react-loader-spinner'

import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import {useFetchCampaignDetailedAnalytics} from 'apis/AdminDashboard/campaigns/useFetchCampaignDetailedAnalytics'

const DetailedModal = ({
  detailedSummaryClassProperty,
  campaignId,
}: {
  detailedSummaryClassProperty: string
  campaignId: string
}) => {
  const {data, isLoading} = useFetchCampaignDetailedAnalytics(campaignId, detailedSummaryClassProperty)

  const columns =
    data?.tableItems && data.tableItems[0]
      ? Object.keys(data?.tableItems[0].data[0] ?? {}).map((key, id) => {
          return {
            id: id.toString(),
            Header: key,
            accessorKey: key,
          }
        })
      : []

  const info = data?.tableItems && data.tableItems[0] ? data.tableItems[0].data : []

  const table = useReactTable({
    data: info,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className='AdminDashboard-analytics-detailedModal'>
      {isLoading && <Loader type={'TailSpin'} />}
      <div className='AdminDashboard-analytics-largeText'>{data?.tableItems?.[0].title}</div>
      <div className='p-2'>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default DetailedModal
