import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

export type FetchEventGuestlistOutput = RouterOutput['events']['fetchEventGuestlist']
type FetchEventGueslistInput = RouterInputs['events']['fetchEventGuestlist']
type Options = ReactQueryOptions['events']['fetchEventGuestlist']

export type GuestList = FetchEventGuestlistOutput['guestlist']

export const useFetchEventGuestlist = (
  {eventId, viewerId}: Partial<FetchEventGueslistInput>,
  {enabled, ...options}: Options,
) => {
  return trpc.events.fetchEventGuestlist.useQuery(
    {eventId: eventId!, viewerId},
    {
      enabled: !!eventId && enabled,
      ...options,
    },
  )
}
