import React, {useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {useQueryClient} from '@tanstack/react-query'
import {PromoCode, PromoCodeType} from 'apis/Events/types'
import useFetchEvent from 'apis/Events/useFetchEvent'
import {useUpdatePromoCode} from 'apis/Events/useUpdatePromoCode'
import useFetchOrders from 'apis/Orders/useFetchOrders'
import Input from 'components/form/Input'
import PoshSwitch from 'components/form/PoshSwitch'
import Select from 'components/form/Select'
import {useToast} from 'components/toasts/ToastProvider'
import {useDimensions} from 'hooks/useDimensions'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {useFetchEventTickets} from '../../../../apis/Events/useFetchEventTickets'
import SaveDiscardBottomBar from '../../SaveDiscardBottomBar'

import './index.scss'

type PromoCodeOption = '$' | '%'

const EditPromoCode = () => {
  const {isMobile} = useDimensions()
  const navigate = useNavigate()
  const {code} = useParams<{code: string}>()
  const {showToast} = useToast()
  const {eventId, groupId, domain} = useResourcePageParams()
  const {data: eventResponse} = useFetchEvent(eventId!)
  const {data: tickets} = useFetchEventTickets(eventId!)
  const {data: ordersAndNames} = useFetchOrders(code ?? '', eventId)
  const defaultPromoCode = useMemo(
    () => eventResponse?.event.promoCodes.find(promoCode => promoCode.code == code),
    [eventResponse], // eslint-disable-line react-hooks/exhaustive-deps
  )
  const [promoCode, setPromoCode] = useState(defaultPromoCode)
  const [showSave, setShowSave] = useState(false)
  const [limitedToTicketId, setLimitedToTicketId] = useState(promoCode?.limitedToTicket ?? '')
  const search = useLocation().search
  const discountableTickets = tickets?.filter(ticket => ticket.ticket.price !== 0)
  const queryClient = useQueryClient()

  const {mutateAsync: updatePromoCode} = useUpdatePromoCode({
    onSuccess: data => {
      showToast({type: 'success', title: `Updated promo code: ${data.updated.code}`})
      queryClient.invalidateQueries(['eventPromoCodes'])
      navigate(-1)
    },
    onError: err => {
      showToast({type: 'error', title: `Something went wrong when updating promo code: ${err.response.data.error}`})
    },
  })

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const getPromoCodeCopy = (): PromoCode => {
    return JSON.parse(JSON.stringify(promoCode))
  }

  const handleTypeChange = (x: '%' | '$') => {
    let newType: PromoCodeType
    if (x == '$') newType = 'flat'
    else newType = 'percent'
    const newPromoCode = getPromoCodeCopy()
    newPromoCode.type = newType
    setPromoCode(newPromoCode)
  }

  const handleLimitedToChange = (ticketId: string) => {
    const newPromoCode = getPromoCodeCopy()
    newPromoCode.limitedToTicket = ticketId ?? ''
    setPromoCode(newPromoCode)
  }

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    const newDiscount = parseFloat(val)
    const newPromoCode = getPromoCodeCopy()
    newPromoCode.value = newDiscount ?? 0
    setPromoCode(newPromoCode)
  }

  const handleLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    const newLimit = parseInt(val)
    const newPromoCode = getPromoCodeCopy()
    newPromoCode.limit = newLimit
    setPromoCode(newPromoCode)
  }

  const handleEnabledToggle = (checked: boolean) => {
    const newPromoCode = getPromoCodeCopy()
    newPromoCode.disabled = !checked
    setPromoCode(newPromoCode)
  }

  const handleDiscard = () => {
    setPromoCode(defaultPromoCode)
    setShowSave(false)
  }

  const handleSave = async () => {
    if (!promoCode) return
    const promoCodesCopy: PromoCode[] = eventResponse?.event.promoCodes || []
    const index = promoCodesCopy.findIndex(x => x.code == code)
    promoCodesCopy[index] = promoCode

    // NEW (AND IMPROVED!) UPDATE PROMO CODE ROUTE
    await updatePromoCode({
      eventId: eventId ?? '',
      promoCodeId: promoCode._id,
      updates: {...promoCode},
    })
    setShowSave(false)
  }

  useEffect(() => {
    if (promoCode != defaultPromoCode && !showSave) {
      setShowSave(true)
    }
  }, [promoCode]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='EditPromoCode'>
      <div className='EditPromoCode-editArea'>
        <h3>{code}</h3>
        <div className='formWrapper'>
          <div className='inputWrapper'>
            <label>Discount</label>
            <Select
              defaultValue={promoCode?.type == 'flat' ? '$' : '%'}
              onChange={x => handleTypeChange(x.target.value as PromoCodeOption)}>
              <option value={'%'}>%</option>
              <option value={'$'}>$</option>
            </Select>
            <Input
              type='number'
              style={{width: '110px'}}
              onChange={e => handleDiscountChange(e)}
              value={promoCode?.value}
            />
          </div>
          {discountableTickets && discountableTickets.length && (
            <div className='inputWrapper'>
              <label>Limited To</label>
              <Select
                defaultValue={limitedToTicketId ?? 'None'}
                style={{minWidth: '210px'}}
                onChange={x => handleLimitedToChange(x.target.value)}>
                {discountableTickets!.map(ticket => (
                  <option key={ticket.ticket.id} value={ticket.ticket.id}>
                    {ticket.ticket.name}
                  </option>
                ))}
                <option value={''}>None</option>
              </Select>
            </div>
          )}
          <div className='inputWrapper'>
            <label>Use Limit</label>
            <Input
              type='number'
              style={{width: '110px'}}
              onChange={e => handleLimitChange(e)}
              value={promoCode?.limit}
            />
          </div>
          <div className='inputWrapper'>
            <label style={{marginRight: '10px'}}>Enabled</label>
            <PoshSwitch
              switchOptions={{checked: !promoCode?.disabled, onChange: checked => handleEnabledToggle(checked)}}
            />
          </div>
        </div>
      </div>
      <div className='EditPromoCode-ordersArea'>
        <div className='EditPromoCode-ordersArea-title'>Orders (used {ordersAndNames?.length ?? 0} times)</div>
        {ordersAndNames && ordersAndNames.length > 0 && (
          <table className='EditPromoCode-ordersArea-table'>
            <tr>
              <th>Name</th>
              <th>Tickets</th>
              {!isMobile && <th>Total Discount</th>}
            </tr>
            {ordersAndNames?.map((order, index) => (
              <tr key={index}>
                <td>{order.name}</td>
                {!isMobile && (
                  <td>
                    {order.tickets.map((ticket, index) => (
                      <span key={index}>
                        {ticket.name + (order.tickets.length > 0 && index != order.tickets.length - 1 ? ', ' : '')}
                      </span>
                    ))}
                  </td>
                )}
                <td>{formatter.format(order.discount || 0)}</td>
                <td>
                  <a
                    onClick={() =>
                      navigate(`/${domain}/groups/${groupId}/marketing/attendee/${order.accountID}` + search)
                    }>
                    View Orders
                  </a>
                </td>
              </tr>
            ))}
          </table>
        )}
      </div>
      <div className='EditPromoCode-ordersArea' />
      <SaveDiscardBottomBar onDiscard={handleDiscard} onSave={handleSave} isOpen={showSave} />
    </div>
  )
}

export default EditPromoCode
