import {useMutation} from '@tanstack/react-query'
import {EventFee} from 'apis/Groups/useGetGroupForFinancials'
import PoshApi from 'apis/PoshApi'

export interface UpdateEventCustomFeeProps {
  fee: string
  eventId: string
  name: string
  amount: number
  type: EventFee['type']
  limitedToId: string[]
}

async function updateEventCustomFee(props: UpdateEventCustomFeeProps) {
  const response = await PoshApi.updateEventCustomFee(props)
  return response.data
}

export default function useUpdateEventCustomFee() {
  return useMutation((props: UpdateEventCustomFeeProps) => updateEventCustomFee(props))
}
