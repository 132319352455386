import {FieldValues, UseFormSetValue, UseFormWatch} from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'

// add a type for the key to persist the form to storage for each form (to avoid conflicts)
export type PersistToStorageKey = 'create-event-playlist-form'

export const usePersistFormState = <T extends FieldValues>(
  watch: UseFormWatch<T>,
  setValue: UseFormSetValue<T>,
  persistToStorage?: {
    key: PersistToStorageKey
    storage: Storage
  },
) => {
  const formPersistConfig = persistToStorage
    ? {
        watch,
        setValue,
        storage: persistToStorage.storage,
      }
    : {
        // We need to provide these functions to useFormPersist even if we're not persisting to storage
        watch: () => {},
        setValue: () => {},
        dirty: false,
      }

  const formPersistKey = persistToStorage ? persistToStorage.key : ''

  const {clear} = useFormPersist(formPersistKey, formPersistConfig)

  return {
    clear,
  }
}
