import {useCensorMessageContent} from 'apis/PendingTextBlasts/useCensorMessageContent'
import useConfirmPendingTextBlast from 'apis/PendingTextBlasts/useConfirmPendingTextBlast'
import useDeletePendingTextBlast from 'apis/PendingTextBlasts/useDeletePendingTextBlast'
import {UseFetchPendingTextBlastOutput} from 'apis/PendingTextBlasts/useFetchPendingTextBlast'
import {useSetEventForPendingTextBlast} from 'apis/PendingTextBlasts/useSetEventForPendingTextBlast'
import useSetMessageForPendingTextBlast from 'apis/PendingTextBlasts/useSetMessageForPendingTextBlast'

import {ITRPCMutations} from './types'

export const useTRPCMutations = ({
  setChosenEvent,
  setMessage,
  setIsCensored,
  navigate,
  showToast,
  queryClient,
}: ITRPCMutations) => {
  const {mutate: setEventForPendingTextBlast} = useSetEventForPendingTextBlast({
    onSuccess: ({eventLink}) => {
      if (eventLink) {
        setChosenEvent(e => ({id: e!.id!, url: eventLink as `https://posh.vip/f/${string}?t=${string}`}))
      }
    },
  })

  const {mutate: setMessageForPendingTextBlast} = useSetMessageForPendingTextBlast()

  const {mutate: confirmPendingTextBlast} = useConfirmPendingTextBlast({
    onSuccess: data => {
      navigate(`../../smscampaign/${data.pendingBlastId}/status`)
    },
    onMutate: params => {
      // Optimistically update the results of the `useFetchPendingTextBlast` query to have the new status
      queryClient.pendingTextBlasts.fetchPendingTextBlast.setData(
        {
          pendingTextBlastId: params.pendingTextBlastId,
          groupId: params.groupId,
        },
        oldData => {
          const old = oldData as UseFetchPendingTextBlastOutput
          return {
            ...old,
            status: 'sent',
          }
        },
      )
    },
    onError: error => {
      showToast({
        type: 'error',
        title: error.message,
      })
    },
    onSettled: () => {
      queryClient.pendingTextBlasts.fetchPendingTextBlast.invalidate()
    },
  })

  const {mutate: deletePendingTextBlast} = useDeletePendingTextBlast()

  const {mutate: censorMessageContent} = useCensorMessageContent({
    onSuccess: ({censoredMessage, isCensored}) => {
      setMessage(censoredMessage)
      setIsCensored(isCensored)
    },
  })

  return {
    setEventForPendingTextBlast,
    setMessageForPendingTextBlast,
    confirmPendingTextBlast,
    deletePendingTextBlast,
    censorMessageContent,
  }
}
