import React from 'react'
import {Helmet} from 'react-helmet'

type PoshHelmetProps = {
  title: string
}

export const PoshHelmet = (props: PoshHelmetProps) => {
  const title = props.title
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property='og:title' content={title} />
        <meta property='og:description' content={title} />
        <meta property='og:image' content={title} />
        <meta property='og:url' content={`https://posh.vip`} />
        <meta property='twitter:title' content={title} />
        <meta property='twitter:description' content={title} />
        <meta property='twitter:image' content={title} />
        <meta property='twitter:card' content='summary_large_image' />
      </Helmet>
    </>
  )
}
