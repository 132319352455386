import React from 'react'
import {useZendesk} from 'react-use-zendesk'

import {AccountCapabilityStatus} from '@posh/model-types'
import Button from 'components/form/Button'

export const AccountStatusActionButton = ({
  sellTicketsStatus,
  payoutsStatus,
  onClickAddBankAccount,
  onClickSubmitInfo,
  isLoading,
}: {
  sellTicketsStatus: AccountCapabilityStatus
  payoutsStatus: AccountCapabilityStatus
  onClickAddBankAccount: () => void
  onClickSubmitInfo: () => void
  isLoading: boolean
}) => {
  // Ticket sales take precedence over payouts over instant payouts
  // but any stripe needs yo info state takes prio
  const zendesk = useZendesk()
  const statuses = [sellTicketsStatus, payoutsStatus]
  if (statuses.some(s => s === 'info required now')) {
    return (
      <Button onClick={onClickSubmitInfo} isLoading={isLoading}>
        Submit Required Info
      </Button>
    )
  }

  if (statuses.some(s => s === 'info needed soon')) {
    return (
      <Button onClick={onClickSubmitInfo} isLoading={isLoading}>
        Submit Info
      </Button>
    )
  }

  if (statuses.some(s => s === 'add bank account')) {
    return (
      <Button onClick={onClickAddBankAccount} isLoading={isLoading}>
        Add Bank Account
      </Button>
    )
  }

  if (statuses.some(s => s === 'pending info verification' || s === 'pending POSH verification' || s === 'disabled')) {
    return (
      <Button onClick={() => zendesk.open()} isLoading={isLoading}>
        Contact Support
      </Button>
    )
  }

  return null
}
