import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface AccountSearchResponse {
  accountId: string
  fullName: string
  avi: string
  exactMatch: boolean
}

const searchAccounts = async (query: string): Promise<AccountSearchResponse[]> => {
  const response = await PoshApi.searchAccountsByQuery(query)
  return response.data.accounts
}

export function useSearchAccounts() {
  return useMutation<AccountSearchResponse[], unknown, string>(query => searchAccounts(query))
}
