import React, {useState} from 'react'

import {BookingRequestWithBookerInfo} from 'apis/Community'
import useUpdateBookingRequest from 'apis/Community/useUpdateBookingRequest'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {PoshImage} from 'components/PoshImage/PoshImage'

import {EntityDetails, getDate, getTime} from '../dashboardHelpers'

interface BookingModalProps extends PoshStyledModalProps {
  bookingRequest: BookingRequestWithBookerInfo | null
  setIsNewRequestModalOpen: (isOpen: boolean) => void
  entityDetails: EntityDetails
}

const BookingModal = (props: BookingModalProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {bookingRequest, setIsNewRequestModalOpen, entityDetails} = props
  const {mutateAsync: updateBookingRequest} = useUpdateBookingRequest()
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [requestAccepted, setRequestAccepted] = useState<boolean>(bookingRequest?.status === 'accepted' ?? false)
  if (!bookingRequest) return null
  const {
    bookingDate,
    startTime,
    endTime,
    location,
    booker,
    opened,
    message,
    status,
    _id,
    organizationName,
    otherDates,
    venueName,
    venueCapacity,
    averageTicketPrice,
    approximateBudget,
  } = bookingRequest

  const handleAcceptBookingRequest = async () => {
    try {
      setButtonsDisabled(true)
      const response = await updateBookingRequest({id: _id, status: 'accepted', opened: true})
      if (response.success) setRequestAccepted(true)
      const accountId = bookingRequest.bookerId
      const accountName = booker.firstName + ' ' + booker.lastName
      const accountEmail = booker.email ?? ''
      const communityCategory = entityDetails.type ?? ''
      const communityName = entityDetails.title ?? ''
      trackMixpanelEvent('Community Booking Request Accepted', {
        accountName,
        accountEmail,
        accountId,
        communityName,
        communityCategory,
      })
    } catch (error) {
      console.error(error)
      setErrorMessage(error.response.data.message)
    }
  }

  const handleDenyBookingRequest = async () => {
    setButtonsDisabled(true)
    try {
      const response = await updateBookingRequest({id: _id, status: 'denied', opened: true})
      if (response.success) setRequestAccepted(true)
      const accountId = bookingRequest.bookerId
      const accountName = booker.firstName + ' ' + booker.lastName
      const accountEmail = booker.email ?? ''
      const communityCategory = entityDetails.type ?? ''
      const communityName = entityDetails.title ?? ''
      trackMixpanelEvent('Community Booking Request Canceled', {
        accountName,
        accountEmail,
        accountId,
        communityName,
        communityCategory,
      })
    } catch (error) {
      console.log(error)
      setErrorMessage(error.response.data.message)
    }
  }

  return requestAccepted ? (
    <PoshStyledModal {...props}>
      <div className='ComSupDashModal'>
        <div className='ComSupDashModal-m'>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h1>Booking Request</h1>
            <PoshImage
              className='ComSupDashModal-x'
              src='https://images.posh.vip/b2/x.svg'
              onClick={() => props.onClose()}
            />
          </div>
          <hr />
          <p>{`Your booking request made from ${booker.firstName} ${booker.lastName} has been accepted! ${booker.firstName} has been notified of your acceptance but you can remain in contact with them by email or phone.`}</p>
          <hr />
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', gap: '10px'}}>
              <PoshImage
                className='ComSupDashModal-details-booker-email'
                src='https://images.posh.vip/b2/mail.png'
                onClick={() => window.open(`mailto:${booker.email}?subject=Posh+Booking+Request`)}
              />
              <p>{`${booker.email}`}</p>
            </div>
            <div style={{display: 'flex', gap: '10px'}}>
              <PoshImage
                className='ComSupDashModal-details-booker-phone'
                src='https://images.posh.vip/b2/phone-call.png'
                onClick={() => window.open(`tel:+1${booker.phone}`)}
              />
              <p>{`${booker.phone}`}</p>
              <div className='slamWrapper'>
                <span className='slam'>ACCEPTED!</span>
              </div>
            </div>
            <div style={{display: 'flex', gap: '10px'}}>
              <PoshImage
                className='ComSupDashModal-details-booker-phone'
                src='https://images.posh.vip/b2/calendar.png'
              />
              <p>{`${getDate(bookingDate)}`}</p>
            </div>
            <div style={{display: 'flex', gap: '10px'}}>
              <PoshImage className='ComSupDashModal-details-booker-phone' src='https://images.posh.vip/b2/clock.png' />
              <p>{`${getTime(startTime)} - ${getTime(endTime)}`}</p>
            </div>
            {organizationName && (
              <>
                <hr />
                <h2>Other Information</h2>
                <br />
                <p>
                  <b>Organization name:</b>
                  <i>{` ${organizationName}`}</i>
                </p>
                <p>
                  <b>Venue name:</b>
                  <i>{` ${venueName}`}</i>
                </p>
                <p>
                  <b>Venue capacity:</b>
                  <i>{` ${venueCapacity}`}</i>
                </p>
                <p>
                  <b>Open to other dates:</b>
                  <i>{` ${otherDates}`}</i>
                </p>
                <p>
                  <b>Approximate Artist Budget:</b>
                  <i>{` ${approximateBudget}`}</i>
                </p>
                <p>
                  <b>Average Ticket Price:</b>
                  <i>{` ${averageTicketPrice}`}</i>
                </p>
              </>
            )}

            <hr />
            <div className='ComSupDashModal-details-booker' style={{marginBottom: '10px'}}>
              <PoshImage src={booker.avi ? booker.avi : 'https://images.posh.vip/b2/default-avi.jpg'} />
            </div>
            <div className='ComSupDashModal-from'>{`From ${booker.firstName}:`}</div>
            <div className='ComSupDashModal-quote'>{message}</div>
          </div>
        </div>
      </div>
    </PoshStyledModal>
  ) : (
    <PoshStyledModal {...props}>
      <div className='ComSupDashModal'>
        <div className='ComSupDashModal-m'>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h1>Booking Request</h1>
            <PoshImage
              className='ComSupDashModal-x'
              src='https://images.posh.vip/b2/x.svg'
              onClick={() => props.onClose()}
            />
          </div>
          <hr />
          <div className='ComSupDashModal-details'>
            <div>
              <div style={{color: '#fc0'}}>{getDate(bookingDate)}</div>
              <div>{`${getTime(startTime)} - ${getTime(endTime)}`}</div>
              <div>{location}</div>
            </div>
            <div className='ComSupDashModal-details-booker'>
              <div>
                <b>{`${booker?.firstName} ${booker?.lastName}`}</b>
                {booker.joined && <div>{`Joined ${getDate(booker.joined)}`}</div>}
                <div className='ComSupDashModal-details-booker-icons'>
                  {booker.email && (
                    <PoshImage
                      className='ComSupDashModal-details-booker-email'
                      src='https://images.posh.vip/b2/mail.png'
                      onClick={() => window.open(`mailto:${booker.email}?subject=Posh+Booking+Request`)}
                    />
                  )}
                  {booker.phone && (
                    <PoshImage
                      className='ComSupDashModal-details-booker-phone'
                      src='https://images.posh.vip/b2/phone-call.png'
                      onClick={() => window.open(`tel:+1${booker.phone}`)}
                    />
                  )}
                </div>
              </div>
              <PoshImage src={booker.avi ? booker.avi : 'https://images.posh.vip/b2/default-avi.jpg'} />
            </div>
          </div>
          <hr />
          <div className='ComSupDashModal-from'>{`From ${booker.firstName}:`}</div>
          <div className='ComSupDashModal-quote'>{message}</div>
          {organizationName && (
            <>
              <hr />
              <h2>Other Information</h2>
              <br />
              <p>
                <b>Organization name:</b>
                <i>{` ${organizationName}`}</i>
              </p>
              <p>
                <b>Venue name:</b>
                <i>{` ${venueName}`}</i>
              </p>
              <p>
                <b>Venue capacity:</b>
                <i>{` ${venueCapacity}`}</i>
              </p>
              <p>
                <b>Open to other dates:</b>
                <i>{` ${otherDates}`}</i>
              </p>
              <p>
                <b>Approximate Artist Budget:</b>
                <i>{` ${approximateBudget}`}</i>
              </p>
              <p>
                <b>Average Ticket Price:</b>
                <i>{` ${averageTicketPrice}`}</i>
              </p>
            </>
          )}
          {status === 'pending' && <hr />}
          {status === 'pending' && (
            <div className='ComSupDashModal-actions'>
              <Button className='Button--dark' onClick={() => handleDenyBookingRequest()} disabled={buttonsDisabled}>
                Deny Booking
              </Button>
              <button className='ComDashBtn' onClick={() => handleAcceptBookingRequest()} disabled={buttonsDisabled}>
                Accept Booking Request
              </button>
            </div>
          )}
          {errorMessage && <div className='ComSupDashModal-error'>{errorMessage}</div>}
        </div>
      </div>
    </PoshStyledModal>
  )
}

export default BookingModal
