// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AnalyticsSection__yMeSB{display:flex;flex-direction:column;gap:15px}.AnalyticsHeader__MxmjA{display:flex;flex-direction:row;justify-content:space-between;align-items:center}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/OverviewUpdate/Analytics/Shared/AnalyticsSection/AnalyticsSection.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,wBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":[".AnalyticsSection {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.AnalyticsHeader {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AnalyticsSection": "AnalyticsSection__yMeSB",
	"AnalyticsHeader": "AnalyticsHeader__MxmjA"
};
export default ___CSS_LOADER_EXPORT___;
