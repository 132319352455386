import {AccountRoleResponse} from 'apis/Roles'

export const handleAuthNavigation = ({
  encodedRedirectUrl,
  accountRoles,
  setAccountRole,
}: {
  encodedRedirectUrl?: string | null
  accountRoles?: AccountRoleResponse[]
  setAccountRole: React.Dispatch<React.SetStateAction<AccountRoleResponse | null>>
}) => {
  if (encodedRedirectUrl) {
    const decodedRedirectUrl = decodeURIComponent(encodedRedirectUrl)
    return decodedRedirectUrl
  }
  const relevantAccountRoles = accountRoles ? accountRoles.filter(g => g.roleKey != 'attendee') : []
  const hasRelevantAccountRoles = relevantAccountRoles.length > 0
  const ownerAccountRoles = relevantAccountRoles.filter(g => g.roleKey == 'owner')
  const poshAdminAccountRoles = relevantAccountRoles.filter(g => g.roleKey == 'poshadmin')
  if (!hasRelevantAccountRoles) return '/dashboard/orders'
  if (poshAdminAccountRoles.length > 0) {
    const accountRole = poshAdminAccountRoles[0]
    setAccountRole(accountRole)
    return '/dashboard/orgs'
  } else if (ownerAccountRoles.length > 1) {
    const accountRole = ownerAccountRoles[0]
    setAccountRole(accountRole)
    return '/dashboard/orgs'
  } else {
    let highestRole = relevantAccountRoles[0]
    for (const role of relevantAccountRoles) {
      if (role.roleKey == 'owner') {
        highestRole = role
        break
      } else if (role.roleKey == 'admin' && highestRole.roleKey == 'host') {
        highestRole = role
      }
    }
    const entity = highestRole.scope === 'event' ? highestRole.parentEntity : highestRole.entity
    return `/${highestRole.roleKey}/groups/${entity}/overview`
  }
}
