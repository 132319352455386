import React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {useNavigate} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {CurrencyCode} from '@posh/types'
import {useQueryClient} from '@tanstack/react-query'
import {EventTicketAndTicketSold} from 'apis/Events/types'
import {useCreateTicketType} from 'apis/Events/useCreateTicketType'
import axios from 'axios'
import {lock, menuUrl, Pencil} from 'components/assets/Icons'
import {CopyRectangles} from 'components/assets/MobileIcons'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import {useDimensions} from 'hooks/useDimensions'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {useResourcePageParams} from 'pages/PoshAppLayout'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'
import {isTicketOnSale} from 'pages/Util/timezoneHelper'

import {LinkSvg} from './svgs'

import './TicketRow.scss'

const BUTTON_ICON_SIZE = 12

interface TicketRowProps {
  ticket: EventTicketAndTicketSold
  index: number
  currency?: CurrencyCode
  isEditingDisabled?: boolean
  timezone?: string
  linkedToText?: string
  setCurrentlyEditing: (curr: string) => void
}

export const TicketRow = (props: TicketRowProps) => {
  const {ticket, index, currency, isEditingDisabled, timezone, setCurrentlyEditing, linkedToText} = props
  const {isMobile} = useDimensions()
  const isOnSale = isTicketOnSale(ticket.ticket, timezone ?? 'America/New_York')
  const navigate = useNavigate()

  const onSaleText = () => {
    const t = ticket.ticket
    if (isEmpty(t.onSaleUtc)) {
      return 'No date'
    } else {
      return moment.tz(t.onSaleUtc, timezone ?? 'America/New_York').format('MMM D h:mm a')
    }
  }

  const endSaleText = () => {
    const t = ticket.ticket
    if (isEmpty(t.endSaleUtc)) {
      return 'No date'
    } else {
      return moment.tz(t.endSaleUtc, timezone ?? 'America/New_York').format('MMM D h:mm a')
    }
  }

  const onEdit = () => {
    if (isEditingDisabled) return
    navigate(`${ticket.ticket.id}`)
  }

  const {eventId} = useResourcePageParams()
  const queryClient = useQueryClient()
  const {mutate: addTicketType, isLoading: isCreating} = useCreateTicketType({
    onSuccess: async ({ticketId: createdTicketId}) => {
      await queryClient.refetchQueries(['event', eventId])
      await queryClient.refetchQueries(['eventTickets', eventId])
      navigate(`${createdTicketId}`)
      showToast({type: 'success', title: 'Ticket duplicated successfully.'})
    },
    onError: error => {
      if (axios.isAxiosError(error)) {
        return showToast({type: 'error', title: error.response?.data.error})
      }
      if (error instanceof Error) {
        return showToast({type: 'error', title: error.message})
      }
    },
  })

  const {showToast} = useToast()

  const onDuplicate = async () => {
    if (isCreating) return
    if (!eventId) throw new Error('There was an error duplicating the ticket.')
    addTicketType({
      eventId,
      ticket: {...ticket.ticket, name: 'Duplicate of ' + ticket.ticket.name},
    })
  }

  return (
    <>
      <Draggable draggableId={ticket.ticket.id} index={index}>
        {provider => (
          <tr ref={provider.innerRef} {...provider.draggableProps} {...provider.dragHandleProps}>
            <td>
              <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                <PoshImage style={{width: '20px', height: '20px', cursor: 'pointer'}} src={menuUrl} />
                {ticket.ticket.name}
                {ticket.ticket.password && <PoshImage className='TicketTable-lockIcon' src={lock} />}
              </div>
            </td>
            {!isMobile && (
              <td style={{color: isOnSale === 1 ? 'lime' : isOnSale === 2 ? '00ccff' : '#a0a0ac'}}>
                {isOnSale === 1 ? 'On Sale' : isOnSale === 2 ? 'Scheduled' : isOnSale === 3 ? 'Hidden' : 'Closed'}
              </td>
            )}
            {!isMobile && (
              <td>
                {ticket.ticket.price > 0 ? (
                  <>
                    {getCurrencySymbol(currency)}
                    {`${ticket.ticket.price % 1 === 0 ? ticket.ticket.price : ticket.ticket.price.toFixed(2)}`}
                  </>
                ) : (
                  <>FREE</>
                )}
              </td>
            )}
            <td>
              {ticket.ticket.quantityAvailable === 9999999 ? (
                <>{ticket.ticketsSold} / No Limit</>
              ) : (
                <>
                  {ticket.ticketsSold} / {ticket.ticket.quantityAvailable}
                </>
              )}
            </td>
            {!isMobile && <td>{onSaleText()}</td>}
            {!isMobile && <td>{endSaleText()}</td>}
            <td className='TicketRow-actions'>
              <ReactTooltip className='poshToolTip' effect='solid' place='top' id='ticket-action-buttons' />
              {!isMobile && (
                <div
                  onClick={() => setCurrentlyEditing(ticket.ticket.id)}
                  className='TicketRow-button'
                  data-tip='Create a link between this ticket and other tickets'
                  data-for='ticket-action-buttons'>
                  {linkedToText && (
                    <ReactTooltip
                      className={`poshToolTip ${linkedToText ? 'active' : ''}`}
                      effect='solid'
                      place='top'
                      id={`link-${linkedToText}`}
                    />
                  )}
                  <LinkSvg
                    dataTip={`Linked to ${linkedToText}`}
                    dataFor={`link-${linkedToText}`}
                    accentColor={linkedToText ? '#00CCFF' : '#FFFFFF'}
                  />
                </div>
              )}

              <div
                onClick={onEdit}
                className='TicketRow-button'
                data-tip='Edit ticket settings'
                data-for='ticket-action-buttons'>
                <Pencil />
              </div>
              <div
                onClick={onDuplicate}
                className='TicketRow-button'
                style={{alignItems: 'center'}}
                data-tip={isCreating ? '' : 'Duplicate ticket and its settings'}
                data-for='ticket-action-buttons'>
                {isCreating ? (
                  <SpinLoader height={BUTTON_ICON_SIZE} />
                ) : (
                  <CopyRectangles color='white' width={BUTTON_ICON_SIZE} height={BUTTON_ICON_SIZE} />
                )}
              </div>
            </td>
          </tr>
        )}
      </Draggable>
    </>
  )
}
