// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton__iqga0{transition:.2s;cursor:pointer}.IconButton__iqga0:hover{filter:opacity(0.7)}.IconButton__iqga0.disabled__J23Df{cursor:unset;filter:opacity(0.5)}.CustomSectionItem__0Kz-S{display:grid;gap:25px;padding:30px;cursor:pointer}.CustomSectionItem__0Kz-S:hover{transform:scale(1.02)}.CustomSectionItem__0Kz-S.button__8-tlI{padding:10px 20px;border-radius:999px;display:flex;width:fit-content;align-items:center;gap:10px}.CustomSectionItem__0Kz-S.button__8-tlI:hover{transform:none}", "",{"version":3,"sources":["webpack://./src/components/PageComponents/EventVisuals/Button/styles.module.scss"],"names":[],"mappings":"AAAA,mBACE,cAAA,CACA,cAAA,CACA,yBACE,mBAAA,CAGF,mCACE,YAAA,CACA,mBAAA,CAIJ,0BACE,YAAA,CACA,QAAA,CACA,YAAA,CACA,cAAA,CACA,gCACE,qBAAA,CAEF,wCACE,iBAAA,CACA,mBAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,QAAA,CACA,8CACE,cAAA","sourcesContent":[".IconButton {\n  transition: 0.2s;\n  cursor: pointer;\n  &:hover {\n    filter: opacity(0.7);\n  }\n  \n  &.disabled {\n    cursor: unset;\n    filter: opacity(0.5);\n  }\n}\n\n.CustomSectionItem {\n  display: grid;\n  gap: 25px;\n  padding: 30px;\n  cursor: pointer;\n  &:hover {\n    transform: scale(1.02);\n  }\n  &.button {\n    padding: 10px 20px;\n    border-radius: 999px;\n    display: flex;\n    width: fit-content;\n    align-items: center;\n    gap: 10px;\n    &:hover {\n      transform: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconButton": "IconButton__iqga0",
	"disabled": "disabled__J23Df",
	"CustomSectionItem": "CustomSectionItem__0Kz-S",
	"button": "button__8-tlI"
};
export default ___CSS_LOADER_EXPORT___;
