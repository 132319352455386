import {useQuery} from '@tanstack/react-query'

import PoshApi from '../PoshApi'

async function getHostedByPoshContent(): Promise<any> {
  const response = await PoshApi.getHostedByPoshContent()
  return response.data
}

export default function useGetHostedByPoshContent() {
  return useQuery(['hostedByPoshContent'], () => getHostedByPoshContent())
}
