import React, {useState} from 'react'
import ReactPlayer from 'react-player'

import useGetHostedByPoshContent from 'apis/Util/useGetHostedByPoshContent'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'
import moment from 'moment'

import {NewsletterBlockInput} from '../Footer/NewsletterBlock'

import './styles.scss'

const MediaLabel = ({label}: {label: string}) => <div className='MediaLabel'>{label}</div>

const DurationLabel = ({duration}: {duration: string}) => <div className='DurationLabel'>{duration}</div>

const Video = ({data}: {data: any}) => {
  const [thumbnailShowing, setThumbnailShowing] = useState(true)

  return (
    <div className='HostedSection-videoWrapper'>
      {data ? (
        <>
          <ReactPlayer
            onPlay={() => setThumbnailShowing(false)}
            onPause={() => setThumbnailShowing(true)}
            url={data.video['youtube-link']}
            frameBorder={0}></ReactPlayer>
          {thumbnailShowing && (
            <>
              <PoshImage src={data.video['cover-photo'].url} />
              <div className='HostedSection-videoWrapper-overlay'>
                <div className='MediaLabelCont'>
                  <MediaLabel label='Podcast' />
                  <DurationLabel duration={moment(data.video['published-on']).format('MMM DD')} />
                </div>
                <h5>{data.video.name}</h5>
                <PoshImage
                  className='HostedSection-videoWrapper-overlay-playButton'
                  src='https://images.posh.vip/b3/hosted-by-posh-lp-section/Play.svg'
                />
                <PoshImage
                  src='https://images.posh.vip/b3/hosted-by-posh-lp-section/HostedByPosh.png'
                  className='HostedSection-videoWrapper-overlay-logo'
                />
              </div>
            </>
          )}
        </>
      ) : (
        <SpinLoader height={30} />
      )}
    </div>
  )
}

const Blogs = ({data}: {data: any}) => {
  const navigate = (slug: string) => {
    window.open(`https://hosted.posh.vip/blog-posts/${slug}`, '_blank')
  }

  return (
    <>
      <PoshImageBackground
        className='HostedSection-blogWrapper'
        style={{backgroundImage: data ? `url('${data.blogs[0]['header-and-background-image'].url}')` : ''}}
        onClick={() => navigate(data ? data.blogs[0].slug : '')}>
        <div className='HostedSection-blogWrapper-overlay'>
          {data ? (
            <>
              <div className='MediaLabelCont'>
                <MediaLabel label='Blog' />
              </div>
              <h5>{data.blogs[0].name}</h5>
            </>
          ) : (
            <SpinLoader height={30} />
          )}
        </div>
      </PoshImageBackground>
      <PoshImageBackground
        className='HostedSection-blogWrapper'
        style={{backgroundImage: data ? `url('${data.blogs[1]['header-and-background-image'].url}')` : ''}}
        onClick={() => navigate(data ? data.blogs[1].slug : '')}>
        <div className='HostedSection-blogWrapper-overlay'>
          {data ? (
            <>
              <div className='MediaLabelCont'>
                <MediaLabel label='Blog' />
              </div>
              <h5>{data.blogs[1].name}</h5>
            </>
          ) : (
            <SpinLoader height={30} />
          )}
        </div>
      </PoshImageBackground>
    </>
  )
}

const Newsletter = () => {
  return (
    <div className='HostedNewsletterWrapper'>
      <PoshImage src='https://images.posh.vip/b3/hosted-by-posh-lp-section/HostedNewsletter.svg' />
      <p>
        Discover wallet-friendly tips, remarkable transformation stories from the world&apos;s best event hosts, and the
        inside scoop on scoring amazing venues. Delivered to your inbox every Thursday.
      </p>
      <NewsletterBlockInput />
    </div>
  )
}

const HostedSection = () => {
  const {data} = useGetHostedByPoshContent()

  return (
    <div className='LandingPage-section HostedSection'>
      <div className='LandingPage-section-inner'>
        <h2>Learn From The Best</h2>
        <a href='https://hosted.posh.vip' target='_blank' rel='noreferrer'>
          <Button>Visit Our Magazine</Button>
        </a>

        <div className='HostedSectionWrapper'>
          <div className='HostedSectionWrapper-left'>
            <Video data={data} />
            <Newsletter />
          </div>
          <div className='HostedSectionWrapper-right'>
            <Blogs data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HostedSection
