import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'

import useFetchEvent from '../../../apis/Events/useFetchEvent'
import {
  UpdateEventAttributes_DEPRECATED,
  useUpdateEvent_DEPRECATED,
} from '../../../apis/Events/useUpdateEventDEPRECATED'
import {PoshHelmet} from '../../../components/PoshHelmet/PoshHelmet'
import RequirePermissions, {requirePermissionAndScope} from '../../../components/RequirePermissions'
import {useResourcePageParams} from '../../PoshAppLayout'
import TeamsSection from './TeamsSection'
import {TicketScannersSection} from './TicketScannersSection'
import TrackingLinksSection from './TrackingLinksSection'

export default function TeamMembers() {
  const queryClient = useQueryClient()
  const {eventId, groupId} = useResourcePageParams()

  const {data: eventResponse, refetch: fetchEvent} = useFetchEvent(eventId!)
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()

  const [eventTrackingLinks, setEventTrackingLinks] = useState(eventResponse?.event.trackingLinks)

  const [currPromoCodes, setCurrPromoCodes] = useState(eventResponse?.event.promoCodes)

  useEffect(() => {
    setCurrPromoCodes(eventResponse?.event.promoCodes)
    setEventTrackingLinks(eventResponse?.event.trackingLinks)
  }, [eventResponse])

  const changesWereMade = useMemo(() => {
    if (!eventResponse) return false
    if (eventResponse.event.promoCodes != currPromoCodes) return true
    return eventResponse.event.trackingLinks != eventTrackingLinks
  }, [currPromoCodes, eventResponse, eventTrackingLinks])

  const processEventUpdates = useCallback(async () => {
    if (changesWereMade) {
      if (!eventResponse) {
        return null
      }
      const updateEventParams: UpdateEventAttributes_DEPRECATED = {
        trackingLinks: eventTrackingLinks,
        promoCodes: currPromoCodes,
      }
      await updateEvent({eventId: eventId!, groupId: groupId!, eventData: updateEventParams})
      await fetchEvent()
      await queryClient.invalidateQueries(['eventPromoCodes'])
    }
  }, [changesWereMade, currPromoCodes, eventId, eventResponse, fetchEvent, groupId, updateEvent])

  useEffect(() => {
    processEventUpdates()
  }, [currPromoCodes, eventTrackingLinks]) // eslint-disable-line react-hooks/exhaustive-deps

  const hostsRef = useRef(null)

  return (
    <>
      <PoshHelmet title={`Team Members - ${eventResponse?.event.name}`} />
      <div ref={hostsRef} className='TeamMembers'>
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_team_members', ['event', 'group'])]}>
          <TeamsSection />
        </RequirePermissions>
        <RequirePermissions
          requiredPermissions={[requirePermissionAndScope('edit_event_tracking_links', ['event', 'group'])]}>
          <TrackingLinksSection eventResponse={eventResponse} />
        </RequirePermissions>
        <RequirePermissions
          requiredPermissions={[requirePermissionAndScope('view_ticket_scan_pins', ['event', 'group'])]}>
          <TicketScannersSection eventId={eventId} />
        </RequirePermissions>
      </div>
    </>
  )
}
