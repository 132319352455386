import React from 'react'

import Button from 'components/form/Button'

import {CrossSection} from '../CrossSection/CrossSection'
import {AnalyticsSection} from '../Shared/AnalyticsSection/AnalyticsSection'
import {Text} from '../Shared/Text/Text'

import styles from './Attendees.module.scss'

export function AttendeesLoadingState({onNavigateToAttendeesPage}: {onNavigateToAttendeesPage: () => void}) {
  return (
    <AnalyticsSection>
      <AnalyticsSection.Header>
        <AnalyticsSection.Title>Attendee</AnalyticsSection.Title>
        <Button onClick={onNavigateToAttendeesPage}>View Attendees</Button>
      </AnalyticsSection.Header>
      <CrossSection.Section className={styles.AttendeeSection}>
        {[...Array(2)].map((_, index) => (
          <CrossSection key={index}>
            <CrossSection.Row>
              <Text.Loading width={150} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='xl' width={100} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='small' />
            </CrossSection.Row>
            <CrossSection.Row>
              <div className={styles.AttendeeSectionLoadingGraphContainer}>
                <div className={styles.AttendeeSectionLoadingGraph}></div>
              </div>
            </CrossSection.Row>
          </CrossSection>
        ))}
      </CrossSection.Section>
    </AnalyticsSection>
  )
}
