import React from 'react'

import {InfoI} from 'components/assets/Icons'

import {BaseNavigationItem} from '../BaseNavigationItem'
import {DesktopBaseNavigationItemProps} from '../BaseNavigationItem/desktop'

import styles from '../BaseNavigationItem/styles.module.scss'

const HELP_DESK_URL = 'https://support.posh.vip'

export function DesktopHelpNavigationItem(
  props: Omit<DesktopBaseNavigationItemProps, 'body' | 'icon' | 'isSelected' | 'onClick'>,
) {
  return (
    <BaseNavigationItem.Desktop
      body={<span>Help</span>}
      {...props}
      icon={<InfoI />}
      isSelected={false} // the Create Event button does not have a selected state
      className={styles.Help}
      onClick={() => window.open(HELP_DESK_URL, '_blank')}
    />
  )
}
