import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {UpdateTicketAttributes} from './types'

export interface UpdateTicketParams {
  eventId: string
  ticket: UpdateTicketAttributes
}

export async function updateTicket(params: UpdateTicketParams): Promise<string> {
  const response = await PoshApi.updateTicket(params)
  return response.data.message
}

export function useUpdateTicket() {
  return useMutation((params: UpdateTicketParams) => updateTicket(params))
}
