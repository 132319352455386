import React from 'react'
import {useNavigate} from 'react-router-dom'

import loginRedirect from 'components/helpers/loginRedirect'
import signupRedirect from 'components/helpers/signupRedirect'
import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'

const CommunityPathModal = ({setPathModalOpen}: {setPathModalOpen: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const closeModal = () => {
    setPathModalOpen(false)
  }

  const navigate = useNavigate()
  const redirectLink = '/community'

  return (
    <div className='CommunityModal'>
      <div className='PathModal'>
        <h3>I want to:</h3>
        <div className='PathModal-options'>
          <div onClick={() => signupRedirect(navigate, redirectLink, true)}>
            <PoshImage src='https://images.posh.vip/b2/add-friend.svg' style={{filter: 'invert(1)'}} />
            Create An Account
          </div>
          <div onClick={() => loginRedirect(navigate, redirectLink)}>
            <PoshImage src='https://images.posh.vip/b2/login.svg' style={{filter: 'invert(1)'}} />
            Login to POSH
          </div>
        </div>
        <PoshImage
          src='https://images.posh.vip/b2/cancel.svg'
          className='cDetailsModal-x'
          onClick={() => closeModal()}
        />
      </div>
    </div>
  )
}

export default CommunityPathModal
