import React from 'react'
import {useForm} from 'react-hook-form'

import {MAX_TICKET_QUANTITY} from '@posh/model-types'
import Button from 'components/form/Button'
import {CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {Panel, PanelProps} from 'components/Panel/Panel'
import {useToast} from 'components/toasts/ToastProvider'

import {TicketDetails} from './TicketDetails'
import {TicketOptions} from './TicketOptions'
import {TicketPrivacySettings} from './TicketPrivacySettings'

import styles from '../styles.module.scss'

export interface OpenTicketEditorInterface {
  openTicketEditor: (initialTicket?: CreateEventTicket, index?: number) => void
  closeTicketEditor: () => void
}

export interface TicketEditorPanel extends Omit<PanelProps, 'title'> {
  initialTicket?: CreateEventTicket
  ticketEditorIndex?: number
  onComplete: (ticket: CreateEventTicket, index?: number) => void
  currencySymbol: string
}

const parseInitialTicket = (initialTicket?: CreateEventTicket) => {
  // Hide the quantity available if it's the max
  if (!initialTicket) return undefined
  const quantityAvailable =
    initialTicket?.quantityAvailable === MAX_TICKET_QUANTITY ? undefined : initialTicket?.quantityAvailable
  return {
    ...initialTicket,
    quantityAvailable,
  }
}

export const CHECK_COLOR = 'white'
const LIGHT_MODE = false
const ACCENT_COLOR = 'white'
const PANEL_BACKGROUND = 'rgb(0 0 0 / 50%)'

export const COLOR_PROPS = {
  lightMode: LIGHT_MODE,
  accentColor: ACCENT_COLOR,
}

export const TicketEditorPanel = (props: TicketEditorPanel) => {
  const {initialTicket, ticketEditorIndex, onComplete, onClose, currencySymbol} = props
  const title = ticketEditorIndex !== undefined ? 'Edit Ticket' : 'Add Ticket'
  const buttonText = ticketEditorIndex !== undefined ? 'Save Changes' : 'Create Ticket'

  const {showToast} = useToast()

  const form = useForm<CreateEventTicket>({
    defaultValues: parseInitialTicket(initialTicket),
  })

  const {
    handleSubmit,
    formState: {isValid, errors},
  } = form

  const hasErrors = Object.keys(errors).length > 0

  const onSubmit = handleSubmit(data => {
    if (!data)
      return showToast({type: 'error', title: 'There was an issue creating your ticket. Please refresh and try again.'})
    onComplete(data, ticketEditorIndex)
  })

  return (
    <Panel {...props} title={title} headerStyle={{border: 'unset'}} backgroundStyle={{background: PANEL_BACKGROUND}}>
      <TicketDetails form={form} currencySymbol={currencySymbol} />
      <TicketOptions form={form} />
      <TicketPrivacySettings form={form} />
      <div className={styles.flexEndRow}>
        <div className={styles.buttonWrapper}>
          <Button className='dark' onClick={onClose}>
            Cancel
          </Button>
          <Button className='gold' disabled={!isValid || hasErrors} onClick={onSubmit}>
            {buttonText}
          </Button>
        </div>
      </div>
    </Panel>
  )
}
