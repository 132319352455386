import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {EventFee} from './useGetGroupForFinancials'

export interface CreateGroupCustomFeeProps {
  name: string
  amount: number
  type: EventFee['type']
  groupId: string
}

async function createGroupCustomFee(props: CreateGroupCustomFeeProps) {
  const response = await PoshApi.createGroupCustomFee(props)
  return response.data
}

export default function useCreateGroupCustomFee() {
  return useMutation((props: CreateGroupCustomFeeProps) => createGroupCustomFee(props))
}
