import React from 'react'

import classNames from 'classnames'
import {PlusInCircle, TrashIcon} from 'components/assets/Icons'
import {useEventVisualsContext} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'

import styles from './styles.module.scss'

interface EventVisualsIconButtonProps {
  onPress: () => void
  iconType: 'trash' | 'plus'
  iconSize?: number
  disabled?: boolean
}

const DEFAULT_ICON_SIZE = 24

const getIcon = (iconType: string, color: string, size: number) => {
  switch (iconType) {
    case 'trash':
      return <TrashIcon fill={color} height={size} width={size} />
    case 'plus':
      return <PlusInCircle fill={color} height={size} width={size} />
    default:
      return null
  }
}

/**
 * IconButton component for EventVisuals it is styled using the palette and has no background or text (plain SVG)
 */
export const EventVisualsIconButton = (props: EventVisualsIconButtonProps) => {
  const {onPress, iconSize = DEFAULT_ICON_SIZE, iconType, disabled = false} = props
  const {
    palette: {textContrasting},
  } = useEventVisualsContext()
  return (
    <div
      onClick={() => !disabled && onPress()}
      className={classNames(styles.IconButton, {[styles.disabled]: disabled})}>
      {getIcon(iconType, textContrasting, iconSize)}
    </div>
  )
}
