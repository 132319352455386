import React, {PropsWithChildren} from 'react'

import {motion} from 'framer-motion'

import styles from './styles.module.scss'

export function DesktopTertiaryNavigation({children}: PropsWithChildren) {
  return (
    <motion.div
      className={styles.TertiaryNavigation}
      initial={{translateX: -50, opacity: 0}}
      animate={{translateX: 0, opacity: 1}}>
      {children}
    </motion.div>
  )
}
