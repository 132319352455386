import React from 'react'

import {parseYoutubeVideoId} from '@posh/model-types'

interface YoutubeIFrameProps {
  url: string
  accentColor: string
  style?: React.CSSProperties
}
const IFRAME_SIZE = '100%'
/**
 * YoutubeIFrame component for EventPage it is styled using the accentColor
 */
export const YoutubeIFrame = (props: YoutubeIFrameProps) => {
  const {url, accentColor, style} = props
  const videoId = (() => {
    try {
      return parseYoutubeVideoId(url)
    } catch {
      return null
    }
  })()
  if (!videoId) return null
  return (
    <div className='EventPage-videoSection' style={style}>
      <iframe
        width={IFRAME_SIZE}
        height={IFRAME_SIZE}
        style={{boxShadow: `0 0 30px -5px ${accentColor}`}}
        src={`https://www.youtube.com/embed/${videoId}`}
      />
    </div>
  )
}
