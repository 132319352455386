import React from 'react'

import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'

import useFetchEvent from '../../../apis/Events/useFetchEvent'
import {useResourcePageParams} from '../../PoshAppLayout'
import ApprovalSection from '../Tickets/ApprovalSection'
import ApprovalCrmContextProvider from '../Tickets/ApprovalSection/ApprovalContextProvider/ApprovalCrmContextProvider'

import '../../EventManagementPages/Tickets/EditPromoCode/index.scss'

const Approvals = () => {
  const {eventId} = useResourcePageParams()
  const {data: event} = useFetchEvent(eventId!)

  return (
    <>
      <PoshHelmet title={`Approvals - ${event?.event?.name ?? ''}`} />
      <div
        className='eventSection'
        style={{display: 'flex', flexDirection: 'column', height: '100%', padding: '20px 0 0 0', margin: '20px 0 0'}}>
        <h3>Orders Pending Approval</h3>
        <ApprovalCrmContextProvider>
          <ApprovalSection />
        </ApprovalCrmContextProvider>
      </div>
    </>
  )
}

export default Approvals
