import React, {useRef, useState} from 'react'

import {useLoginWithEmail} from 'apis/Auth/useLoginWithEmail'
import CloudflareTurnstile, {TurnstileRef} from 'components/CloudflareTurnstile'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {useToast} from 'components/toasts/ToastProvider'
import {ActionButtons} from 'pages/NewCheckoutPage/LoginForm/ActionButtons'

import {CreateEventFormDataModalStepProps} from '../util/createEventFormData'

export const CreateEventEmailLoginForm = (props: CreateEventFormDataModalStepProps) => {
  const {
    createEventFormData: {
      userData: {email},
    },
    setCreateEventFormData,
  } = props
  const {showToast} = useToast()
  const [password, setPassword] = useState<string | undefined>('')
  const {mutate: login, isLoading, isError} = useLoginWithEmail()
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(undefined)
  const captchaRef = useRef<TurnstileRef>(null)

  const handleLogin = async () => {
    if (!password) {
      showToast({type: 'error', title: 'Please input a password'})
      return
    }
    try {
      login({email, password, cfTurnstileResponse: captchaToken})
    } catch (error) {
      showToast({type: 'error', title: 'There was an error logging in'})
      resetCaptcha()
    }
  }

  const handleCreateNewAccount = () => {
    setCreateEventFormData(u => ({...u, hasAccount: false}))
  }

  const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      await handleLogin()
    }
  }

  const resetCaptcha = () => {
    setCaptchaToken(undefined)
    captchaRef.current?.reset()
  }

  const handleCaptchaToken = (token: string) => {
    setCaptchaToken(token)
  }

  return (
    <div className='CreateEventLogin'>
      <h4 className='noMargin'>Log in to your Posh account</h4>
      <p className={'center'}>{email}</p>
      <Input
        value={password}
        type={'password'}
        className='fullWidth'
        required={true}
        onChange={e => setPassword(e.target.value)}
        placeholder={'Password'}
        onKeyDown={e => onKeyDown(e)}
      />
      <CloudflareTurnstile ref={captchaRef} successCallback={handleCaptchaToken} />
      <Button
        className='createEventGrad'
        disabled={isLoading || password?.length === 0 || !captchaToken}
        onClick={handleLogin}>
        Log in
      </Button>
      {isError && <span style={{textAlign: 'center', color: 'red'}}>Invalid Email or Password</span>}
      {isError && <ActionButtons handleCreateNewAccount={handleCreateNewAccount} />}
    </div>
  )
}
