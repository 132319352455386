import React from 'react'

import MDEditor from '@uiw/react-md-editor'
import Button from 'components/form/Button'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'

interface ConfirmationModalProps {
  showConfirmationModal: boolean
  setShowConfirmationModal: (show: boolean) => void
  createReleaseOnClick: () => void
  release: {
    version: string
    updatesMarkdown: string
  }
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {showConfirmationModal, setShowConfirmationModal, createReleaseOnClick, release} = props
  return (
    <PoshStyledModal width={900} isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
      <h3 className='center'>Confirm correct info and no typos</h3>
      <p>
        Version: <b>{release.version}</b>
      </p>
      <MDEditor.Markdown
        className='ReleaseDashboard-markdown-preview'
        source={release.updatesMarkdown}></MDEditor.Markdown>
      <Button style={{margin: 'auto'}} className='gold' onClick={createReleaseOnClick}>
        Create
      </Button>
    </PoshStyledModal>
  )
}

export default ConfirmationModal
