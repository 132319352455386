import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface AuthenticateMailChimpUserParams {
  code: string
  state: string
}

async function authenticateMailChimpUser(params: AuthenticateMailChimpUserParams): Promise<string> {
  const response = await PoshApi.authenticateMailChimpUser(params)
  return response.data.link
}

export function useAuthenticateMailChimpUser(params: AuthenticateMailChimpUserParams) {
  return useQuery(['authenticateMailChimpUser', params], () => authenticateMailChimpUser(params), {
    enabled: !!params.code && !!params.state,
    retry: false,
  })
}
