import * as Yup from 'yup'

export const ticketValidator = Yup.object().shape({
  name: Yup.string().required('Ticket name is required').max(100, 'Name must be less than 100 characters'),
  price: Yup.number(),
  quantityAvailable: Yup.number(),
  onSale: Yup.string().nullable(),
  onSaleUtc: Yup.string().nullable(),
  endSale: Yup.string().nullable(),
  endSaleUtc: Yup.string().nullable(),
  purchaseMin: Yup.number().min(1, 'Purchase minimum must be greater than 0'),
  purchaseLimit: Yup.number().min(1, 'Purchase limit must be greater than 0').nullable(),
  description: Yup.string().nullable(),
  password: Yup.string().nullable(),
  isHidden: Yup.boolean(),
  approvalRequired: Yup.boolean(),
  sellInMultiples: Yup.boolean(),
  closed: Yup.boolean(),
  disabled: Yup.boolean(),
})
