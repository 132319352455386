import {useSearchParams} from 'react-router-dom'

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const setQueryParams = (key: string, value: string | string[]) => {
    if (Array.isArray(value)) {
      searchParams.delete(key)
      value.forEach(v => searchParams.append(key, v))
    } else {
      if (!value) {
        searchParams.delete(key)
      } else {
        searchParams.set(key, value)
      }
    }
    setSearchParams(searchParams)
  }

  return {queryParams: searchParams, setQueryParams}
}
