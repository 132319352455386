import React from 'react'

import useFetchMailChimpAuthLink from 'apis/MailChimp/useFetchMailChimpAuthLink'
import {useIsMailChimpUserAuthenticated} from 'apis/MailChimp/useIsMailChimpUserAuthenticated'
import {check} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import {useGroupMatchParams} from 'domains/Groups/helpers'

export const MailChimpIntegration = () => {
  const {groupId, domain} = useGroupMatchParams()
  const {mutateAsync: fetchMailChimpAuthLink, isLoading} = useFetchMailChimpAuthLink()
  const {data: mailChimpAuthStatus, isFetching} = useIsMailChimpUserAuthenticated(groupId || '')
  const {showToast} = useToast()
  const isAuthed = mailChimpAuthStatus?.isAuthenticated ?? false
  const buttonTitle = isAuthed ? 'Connected' : isLoading ? 'Connecting...' : 'Connect'
  const buttonDisabled = isLoading || isFetching
  const image = isAuthed ? check : 'https://images.posh.vip/b2/settings-page-icons/right-arrow-white.svg'

  const handleMailChimpRedirect = async () => {
    try {
      if (isAuthed) throw new Error('Already Authenticated with MailChimp')
      if (!groupId) throw new Error('No group id')
      const link = await fetchMailChimpAuthLink({groupId, scope: domain})
      window.location.href = link
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  return (
    <div className='IntegrationsCont-integration'>
      <PoshImage
        src='https://images.posh.vip/b2/settings-page-icons/mailchimp-logo.jpeg'
        className='IntegrationsCont-integration-logo'
      />
      <h5>MailChimp</h5>
      <p>Email blast your attendees</p>
      <Button
        disabled={buttonDisabled}
        onClick={handleMailChimpRedirect}
        className={`${isAuthed ? 'mailChimp' : 'dark light'} fullWidth`}>
        {buttonTitle}
        {!isLoading && <PoshImage src={image} />}
      </Button>
    </div>
  )
}
