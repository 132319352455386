// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageContainer__6PMPr{display:flex;justify-content:center;align-items:center;flex-direction:column}.PageContent__fT4oi{display:flex;flex-direction:column;gap:35px;width:700px}.EventPlaylistCard__nO6xb{display:flex;flex-direction:row;gap:20px}.EventPlaylistCard__nO6xb img{width:150px;height:150px;object-fit:cover;border-radius:20px}.EventPlaylistCard__nO6xb .EventPlaylistInfo__gRH-F{display:flex;flex-direction:column;flex-grow:1;height:100%}", "",{"version":3,"sources":["webpack://./src/pages/ManageEventPlaylists/ManageEventPlaylistsPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CAGF,oBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,WAAA,CAGF,0BACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,8BACE,WAAA,CACA,YAAA,CACA,gBAAA,CACA,kBAAA,CAGF,oDACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,WAAA","sourcesContent":[".PageContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.PageContent {\n  display: flex;\n  flex-direction: column;\n  gap: 35px;\n  width: 700px;\n}\n\n.EventPlaylistCard {\n  display: flex;\n  flex-direction: row;\n  gap: 20px;\n}\n\n.EventPlaylistCard img {\n  width: 150px;\n  height: 150px;\n  object-fit: cover;\n  border-radius: 20px;\n}\n\n.EventPlaylistCard .EventPlaylistInfo {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageContainer": "PageContainer__6PMPr",
	"PageContent": "PageContent__fT4oi",
	"EventPlaylistCard": "EventPlaylistCard__nO6xb",
	"EventPlaylistInfo": "EventPlaylistInfo__gRH-F"
};
export default ___CSS_LOADER_EXPORT___;
