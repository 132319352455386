import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import {TimePicker, TimePickerProps} from '@material-ui/pickers'

import {
  ControlledEventVisualsDateInputProps,
  EventVisualsDateInputProps,
  EventVisualsDateInputWrapper,
  getSharedPickerProps,
} from './EventVisualsDateInputWrapper'

export function TimeInput(props: EventVisualsDateInputProps<TimePickerProps>) {
  const pickerProps = getSharedPickerProps(props)
  const format = props.format ?? 'h:mm a'

  return (
    <EventVisualsDateInputWrapper {...props}>
      <TimePicker {...pickerProps} format={format} />
    </EventVisualsDateInputWrapper>
  )
}

const ControlledTime = <T extends FieldValues, K extends Path<T>>(
  props: ControlledEventVisualsDateInputProps<T, K, TimePickerProps>,
) => {
  const {name, control, rules} = props
  const isRequired = typeof rules?.required === 'object' ? rules.required.value : !!rules?.required

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {value, onChange}}) => (
        <TimeInput {...props} value={value} onChange={onChange} required={isRequired} />
      )}
    />
  )
}

TimeInput.Controlled = ControlledTime
