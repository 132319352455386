import React, {useEffect, useState} from 'react'

import {FetchEventParentCommentsOutput, useFetchEventParentComments} from 'apis/comments/useFetchEventParentComments'
import {EventPublic} from 'apis/Events/types'
import {useAddEventComment} from 'apis/Events/useAddEventComment'
import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import {trpc} from 'lib/trpc'
import {ProfilePreviewModalState} from 'pages/EventPage'

import {shouldBlurActivity} from '../helpers/activityHelpers'
import CommentComponent from './CommentComponent'
import {fakeComments} from './fakeComments'

import './styles.scss'

interface ActivitySectionProps {
  event: EventPublic
  isAdmin: boolean
  isOwner: boolean
  setProfilePreviewModal: React.Dispatch<React.SetStateAction<ProfilePreviewModalState>>
}

const ActivitySection = (props: ActivitySectionProps) => {
  const {event, isAdmin, isOwner, setProfilePreviewModal} = props
  const [limit, setLimit] = useState(10)
  const {data: eventCommentsData} = useFetchEventParentComments({
    eventId: event.id ?? '',
    limit,
  })
  const {isAttendee} = event
  const hasMoreComments = eventCommentsData?.hasMoreComments
  const {mutate: createEventComment} = useAddEventComment({
    onSuccess: () => {
      setComment('')
      setErrorMsg('')
      queryClient.comments.fetchEventParentComments.invalidate()
    },
    onError: error => {
      setErrorMsg(error.message ?? 'You cannot leave a comment on an event you have not purchased tickets for.')
    },
  })
  const {currentUser} = useSessionContext()
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState<FetchEventParentCommentsOutput['comments']>([])
  const [errorMsg, setErrorMsg] = useState('')
  const [shouldShowEmojiPicker, setShouldShowEmojiPicker] = useState('')
  const shouldBlurCommentsSection = shouldBlurActivity({
    isAdmin,
    isAttendee,
    isOwner,
    currentUser,
  })

  const queryClient = trpc.useContext()

  useEffect(() => {
    if (eventCommentsData && eventCommentsData.comments) {
      setComments(eventCommentsData.comments)
    }
  }, [eventCommentsData])

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      createEventComment({eventId: event.id!, value: comment})
    }
  }

  return (
    <div className={classNames('ActivitySection', {blur: shouldBlurCommentsSection})}>
      <div className={`ActivitySection-commentInput ${event.lightmode ? 'lightmode' : ''}`}>
        <input
          type='text'
          placeholder='Leave a comment for your fellow attendees...'
          value={comment}
          onChange={e => setComment(e.target.value)}
          onKeyDown={e => onKeyDown(e)}></input>
        <div
          style={{background: event.accentColor}}
          onClick={() => createEventComment({eventId: event.id!, value: comment})}>
          <PoshImage src='https://images.posh.vip/b2/speech-bubble.svg' />
        </div>
      </div>
      {errorMsg && <div className='activityComment-text-error'>{errorMsg}</div>}
      <div className='ActivitySection-commentContainer'>
        {!shouldBlurCommentsSection ? (
          <>
            {comments.map(c => (
              <>
                <CommentComponent
                  key={c._id}
                  comment={c}
                  accentColor={event.accentColor}
                  isLightMode={event.lightmode ?? false}
                  eventId={event.id!}
                  groupId={event.groupID}
                  parentCommentId={c._id}
                  replyToCommentId={c._id}
                  isReply={false}
                  shouldShowEmojiPicker={shouldShowEmojiPicker}
                  setShouldShowEmojiPicker={setShouldShowEmojiPicker}
                  setProfilePreviewModal={setProfilePreviewModal}
                />
              </>
            ))}
            {hasMoreComments ? (
              <div className='ActivitySection-commentLoadMore' onClick={() => setLimit(limit + 10)}>
                Load More
              </div>
            ) : (
              eventCommentsData &&
              !hasMoreComments &&
              comments.length > 10 && (
                <div className='ActivitySection-commentLoadMore' onClick={() => setLimit(10)}>
                  View Less
                </div>
              )
            )}
          </>
        ) : (
          <>
            {fakeComments.map(c => (
              <CommentComponent
                key={c._id}
                comment={c}
                accentColor={event.accentColor}
                isLightMode={event.lightmode ?? false}
                eventId={event.id!}
                groupId={event.groupID}
                isReply={false}
                shouldShowEmojiPicker={shouldShowEmojiPicker}
                setShouldShowEmojiPicker={setShouldShowEmojiPicker}
                setProfilePreviewModal={setProfilePreviewModal}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default ActivitySection
