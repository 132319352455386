import React from 'react'

import classNames from 'classnames'
import {getColorEvenlySpaced} from 'helpers/colors'

import {TableRowLabel} from '../TableRowLabel/TableRowLabel'

import styles from './TableCell.module.scss'

interface TableCellProps {
  text: string
  bold?: boolean
}

export const TableCell = (props: TableCellProps) => {
  const {text, bold} = props

  return (
    <div
      className={classNames(styles.TableCell, {
        [styles.bold]: bold,
      })}>
      {text}
    </div>
  )
}

TableCell.Text = TableCell

interface TableCellMultiOptionProps<T> {
  allOptions: T[]
  selectedOptions: T[]
  getText: (option: T) => string
}

const TableCellMultiOption = <T,>(props: TableCellMultiOptionProps<T>) => {
  const {allOptions, selectedOptions, getText} = props

  return (
    <div className={styles.TableCellMultiOption}>
      {selectedOptions.map(option => {
        const optionIndex = allOptions.indexOf(option)

        if (optionIndex === -1) return null

        const color = getColorEvenlySpaced(optionIndex, allOptions.length, 35)

        return <TableRowLabel key={optionIndex} text={getText(option)} color={color} />
      })}
    </div>
  )
}

TableCell.MultiOption = TableCellMultiOption
