import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {OrdersForTicket} from '.'

export interface FetchOrdersForTicketParams {
  ticketId: string
  eventId: string
}

export async function fetchOrdersForTicket(params: FetchOrdersForTicketParams): Promise<OrdersForTicket[]> {
  const response = await PoshApi.fetchOrdersForTicket(params)
  return response.data.ordersAndNames
}

export default function useFetchOrderForTickets(params: FetchOrdersForTicketParams) {
  return useQuery(['orderForTickets', params.ticketId], () => fetchOrdersForTicket(params), {
    enabled: !!params.eventId && !!params.ticketId,
  })
}
