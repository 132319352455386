import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface SetNotificationAsReadResponse {
  message: string
}

export interface SetNotificationAsReadParams {
  notificationId: string
}

async function setNotificationAsRead(data: SetNotificationAsReadParams): Promise<SetNotificationAsReadResponse> {
  const response = await PoshApi.setNotificationAsRead(data)
  return response.data
}

export function useSetNotificationAsRead(
  opts?: UseMutationOptions<SetNotificationAsReadResponse, any, SetNotificationAsReadParams>,
) {
  return useMutation((params: SetNotificationAsReadParams) => setNotificationAsRead(params), opts)
}
