import React from 'react'

import Button from 'components/form/Button'

import {CrossSection} from '../CrossSection/CrossSection'
import {AnalyticsSection} from '../Shared/AnalyticsSection/AnalyticsSection'
import {Text} from '../Shared/Text/Text'

import styles from './Attendees.module.scss'

export function AttendeesErrorState({onNavigateToAttendeesPage}: {onNavigateToAttendeesPage: () => void}) {
  return (
    <AnalyticsSection>
      <AnalyticsSection.Header>
        <AnalyticsSection.Title>Attendee</AnalyticsSection.Title>
        <Button onClick={onNavigateToAttendeesPage}>View Attendees</Button>
      </AnalyticsSection.Header>
      {/* We want the error to expand all the way across the section so we unset the grid*/}
      <CrossSection.Section className={styles.AttendeeSection} style={{gridTemplateColumns: 'unset'}}>
        <div className={styles.AttendeeSectionErrorContainer}>
          <Text size='large'>Error</Text>
          <Text>Something went wrong trying to retrieve attendee data.</Text>
        </div>
      </CrossSection.Section>
    </AnalyticsSection>
  )
}
