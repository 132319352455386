import React, {ChangeEvent} from 'react'

import {useGetGroup} from 'apis/Groups/useGetGroup'
import Input from 'components/form/Input'
import {LocationInput, UseLocationInputRefParams} from 'components/form/PoshLocationInput'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useGroupSearchParams} from 'domains/Groups/helpers'

import {EditGroupProfile} from './EditGroupProfile'

import './index.scss'

type IInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export interface ISettingInputProp extends IInputProps {
  title: string
  subTitle?: string
  placeHolder: string
  className?: string
  onChangeFunc: (value: ChangeEvent<HTMLTextAreaElement>) => void
  errorMessage?: string
}

interface ISettingLocationProp extends IInputProps {
  title: string
  placeHolder: string
  className?: string
  setLocationAndCoordinates: (value: string, type: 'Point', coordinates: [number, number]) => void
}

export const SettingInput = (props: ISettingInputProp) => (
  <tr>
    <td>
      <label>{props.title}</label>
      {props.subTitle && <p className='subTitle'>{props.subTitle}</p>}
    </td>
    <td>
      {props.className == 'Large' ? (
        <>
          <textarea
            className='poshTextArea'
            placeholder={props.placeHolder}
            onChange={e => props.onChangeFunc(e)}
            value={props.value}
          />
        </>
      ) : (
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /*@ts-ignore*/
        <Input type='text' placeholder={props.placeHolder} {...props} onChange={e => props.onChangeFunc(e)} />
      )}
      {props.errorMessage && <p style={{color: 'red', fontSize: 12}}>{props.errorMessage}</p>}
    </td>
  </tr>
)

export const SettingLocationInput = (props: ISettingLocationProp) => {
  const options: UseLocationInputRefParams = {
    onPlaceSelected: place => {
      const latitude = place.geometry?.location?.lat()
      const longitude = place.geometry?.location?.lng()
      if (latitude && longitude && place.formatted_address)
        props.setLocationAndCoordinates(place.formatted_address, 'Point', [longitude, latitude])
    },
    options: {
      types: ['geocode', 'establishment'],
    },
  }

  return (
    <tr>
      <td>
        <label>{props.title}</label>
      </td>
      <td>
        <LocationInput
          placeholder={props.placeHolder}
          className='poshInput'
          style={{width: '100%'}}
          defaultValue={props.value}
          googlePlacesInputOptions={options}
        />
      </td>
    </tr>
  )
}

const Profile = () => {
  const {groupId} = useGroupSearchParams()
  const {data: groupData, isLoading, isError, dataUpdatedAt} = useGetGroup({groupId})

  if (isLoading) {
    return (
      <div className='Settings-loader'>
        <SpinLoader height={100} />
      </div>
    )
  }

  if (isError) {
    return (
      <div>
        <h1>Something went wrong while getting your group profile settings</h1>
      </div>
    )
  }

  return <EditGroupProfile key={`edit-group-profile-${dataUpdatedAt}`} group={groupData.group} groupId={groupId} />
}

export default Profile
