import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {
  Covid19Settings,
  EventLineUp,
  EventSong,
  EventTable,
  EventVenue,
  IPaymentPlan,
  ITicketGroup,
  LongLat,
  PromoCode,
  TagOption,
  TrackingLink,
  UpdateTicketAttributes,
} from './types'

export interface UpdateEventAttributes_DEPRECATED {
  _id?: string
  name?: string
  description?: string
  termsOfService?: string
  shortDescription?: string
  groupID?: string
  start?: string
  end?: string
  startUtc?: string
  endUtc?: string
  url?: string
  status?: string
  tickets?: UpdateTicketAttributes[]
  flyer?: string | null
  smallFlyer?: string | null
  venue?: EventVenue
  promoCodes?: PromoCode[]
  attending?: string[]
  accentColor?: string
  trackingLinks?: TrackingLink[]
  noTrackVisits?: number
  lineup?: EventLineUp[]
  fakeGuestlistCount?: number
  guestlistEnabled?: boolean
  offerGratuity?: boolean
  noBranding?: boolean
  lightmode?: boolean
  activityEnabled?: boolean
  fbPixelId?: string
  ttPixelId?: string
  gtmContainerId?: string
  geniusMonkeyId?: string
  category?: string
  youtubeLink?: string
  paymentPlans?: IPaymentPlan[]
  tables?: EventTable[]
  passwordProtected?: boolean
  password?: string
  confirmationMessage?: string
  customCheckoutSubmitText?: string
  galleryID?: string
  displayOnThirdPartySites?: boolean
  location?: LongLat
  attendeeNoPasswordCheckout?: boolean
  showFeesInPrice?: boolean
  longFormAddToCartButton?: boolean
  tableDescription?: string
  displayGroupName?: string
  displayGroupImage?: string
  customConfirmationMessage?: string
  minimumFollowerCount?: number
  eventTitleFont?: string
  performanceCategory?: string
  timezone?: string
  tags?: TagOption[]
  covid19Settings?: Covid19Settings
  song?: EventSong
  displayEndTime?: boolean
  multiDayDisplay?: boolean
  attendanceDisplayDisabled?: boolean
  ticketGroups?: ITicketGroup[]
  displayKickbackCTA?: boolean
  kickbackCTAText?: string
  legacyRSVPFlow?: boolean
}

export async function updateEvent_DEPRECATED(
  eventId: string,
  groupId: string,
  eventData: UpdateEventAttributes_DEPRECATED,
) {
  const response = await PoshApi.updateEvent(eventId, groupId, eventData)
  return response.data as {message: string; warning: string}
}

export function useUpdateEvent_DEPRECATED() {
  return useMutation((params: {eventId: string; groupId: string; eventData: UpdateEventAttributes_DEPRECATED}) =>
    updateEvent_DEPRECATED(params.eventId, params.groupId, params.eventData),
  )
}
