import React from 'react'

import styles from './Error.module.scss'

interface ErrorProps {
  error: string
}

export const Error = (props: ErrorProps) => {
  const {error} = props
  return <span className={styles.error}>{error}</span>
}
