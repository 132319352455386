import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'
import PhoneInput, {DefaultInputComponentProps} from 'react-phone-number-input'

import classNames from 'classnames'
import {ControlledFormProps} from 'components/DynamicForm/Inputs/shared/types'

import {EventVisualsTextInputWrapper, EventVisualsTextInputWrapperProps} from './EventVisualsTextInputWrapper'

import styles from './styles.module.scss'

type EventVisualsTextInputProps = Omit<DefaultInputComponentProps, 'className' | 'name'> &
  EventVisualsTextInputWrapperProps
const DEFAULT_FONT_SIZE = 16

export function EventVisualsBasePhoneInput(props: EventVisualsTextInputProps) {
  const {lightMode, accentColor, fontFamily, ...rest} = props
  const lightModeClassName = lightMode ? styles.light : styles.dark
  const inputClassName = classNames(styles.input, lightModeClassName)
  const fontSize = props.size ?? DEFAULT_FONT_SIZE

  return (
    <PhoneInput
      {...rest}
      value={props.value?.toString()}
      onChange={value => props.onChange && props.onChange(value)}
      className={inputClassName}
      numberInputProps={{
        style: {fontSize, color: accentColor, fontFamily},
      }}
      style={{width: '100%'}}
      placeholder={props.placeholder + (props.required ? '*' : '')}
    />
  )
}

export function PlainPhoneInput({
  icon,
  ...props
}: EventVisualsTextInputProps & {
  icon?: React.ReactNode
}) {
  const {required, value} = props
  const isOutlined = required === true && !value
  return (
    <EventVisualsTextInputWrapper.Row {...props} isOutlined={isOutlined}>
      {icon}
      <EventVisualsBasePhoneInput {...props} />
    </EventVisualsTextInputWrapper.Row>
  )
}

type ControlledEventVisualsTextInputProps<
  T extends FieldValues,
  K extends Path<T>,
> = EventVisualsTextInputWrapperProps & ControlledFormProps<T, K> & EventVisualsTextInputProps

function ControlledPlainPhoneInput<T extends FieldValues, K extends Path<T>>({
  name,
  control,
  rules,
  ...props
}: ControlledEventVisualsTextInputProps<T, K>) {
  const isRequired = typeof rules?.required === 'object' ? rules.required.value : !!rules?.required

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState}) => <PlainPhoneInput {...props} {...field} {...fieldState} required={isRequired} />}
    />
  )
}

PlainPhoneInput.Controlled = ControlledPlainPhoneInput
