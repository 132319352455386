import React, {useState} from 'react'
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input'

import {orgTypeList} from '@posh/types'
import {UpdateGroupAttributes} from 'apis/Groups'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useFetchGroupEvents} from 'apis/Groups/useFetchGroupEvents'
import useUpdateGroup from 'apis/Groups/useUpdateGroup'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import {useToast} from 'components/toasts/ToastProvider'
import DeleteGroupModal from 'pages/GroupPage/Profile/DeleteGroupModal'
import {useResourcePageParams} from 'pages/PoshAppLayout'

const General = () => {
  const {groupId} = useResourcePageParams()
  const {data: group, refetch, isFetching} = useFetchGroup(groupId ?? '')
  const {mutateAsync: updateGroup, isError} = useUpdateGroup()
  const {showToast} = useToast()
  const {data: events} = useFetchGroupEvents(groupId ?? '')
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false)
  const [settings, setSettings] = useState<UpdateGroupAttributes>({})

  const handleSave = async () => {
    if (settings && settings.contactPhone && !isPossiblePhoneNumber(settings.contactPhone)) {
      showToast({type: 'error', title: 'Phone number is not valid'})
      return
    }
    try {
      await updateGroup({
        groupId: groupId!,
        updates: settings,
      })
      setSettings({})
      refetch()
      showToast({type: 'success', title: 'Group settings updated'})
    } catch (error: any) {
      if (error.response.data.error) showToast({type: 'error', title: error.response.data.error as string})
    }
  }

  const handleDiscard = async () => {
    setSettings({})
    refetch()
  }

  return (
    <>
      <p>Configure general settings for your organization.</p>
      <table>
        <tbody>
          <tr>
            <td>
              <label>Organization Type</label>
            </td>
            <td>
              <Select onChange={e => setSettings({...settings, organizationType: e.target.value})}>
                <option selected={!!settings.organizationType}>Select organization type</option>
                {orgTypeList.map(organization => (
                  <option
                    key={organization.code}
                    selected={organization.code === (settings.organizationType ?? group?.organizationType)}
                    value={organization.code}>
                    {organization.name}
                  </option>
                ))}
              </Select>
            </td>
          </tr>
          {events?.length === 0 && (
            <tr>
              <td></td>
              <td>
                <Button className='fullWidth dark light' onClick={() => setShowDeleteGroupModal(true)}>
                  Delete Organization
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <hr />
      <p>Your contact information will be displayed to attendees.</p>
      <table>
        <tbody>
          <tr>
            <td>
              <label>Contact Email</label>
            </td>
            <td>
              <Input
                placeholder='jamal@gmail.com'
                value={settings?.contactEmail ?? group?.contactEmail}
                onChange={e => setSettings({...settings, contactEmail: e.target.value})}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Contact Phone</label>
            </td>
            <td>
              <PhoneInput
                defaultCountry={group?.country ?? 'US'}
                style={{width: '100%'}}
                placeholder={'Phone'}
                onChange={e => setSettings({...settings, contactPhone: e})}
                className='AccountSettings-phoneInput'
                value={settings?.contactPhone ?? group?.contactPhone}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className='buttonWrapper'>
        <Button className='light dark' onClick={() => handleDiscard()}>
          Discard
        </Button>
        <Button className='light gold' onClick={() => handleSave()}>
          Save Changes
        </Button>
      </div>
      {showDeleteGroupModal && (
        <DeleteGroupModal
          isOpen={showDeleteGroupModal}
          onClose={() => setShowDeleteGroupModal(false)}
          groupId={groupId ?? ''}
          groupName={group?.name}
        />
      )}
    </>
  )
}

export default General
