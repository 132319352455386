import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

async function fetchAllEventTrackingLinks(eventId: string, groupId: string): Promise<string[]> {
  const response = await PoshApi.fetchAllEventTrackingLinks(eventId, groupId)
  return response.data
}

export function useFetchAllEventTrackingLinks(eventId: string, groupId: string) {
  return useQuery(['allEventTrackingLinks', eventId], () => {
    return fetchAllEventTrackingLinks(eventId, groupId)
  })
}
