import React, {ChangeEvent} from 'react'

import {EventTable} from 'apis/Events/types'
import {EditEventTableParams} from 'apis/Events/useUpdateEventTable'

import {useFetchGroup} from '../../../../apis/Groups/useFetchGroup'
import Input from '../../../../components/form/Input'
import {useResourcePageParams} from '../../../PoshAppLayout'
import {getCurrencySymbol} from '../../../Util/getCurrencySymbol'

import './styles.scss'

export const FAKE_EVENT_TABLE: EventTable = {
  _id: '1',
  name: 'This is ze table',
  price: 3000,
  description: 'Sit here if you are rich',
  mapPosition: [1, 2],
}

interface Props {
  tables: EventTable[]
  tableParams: EditEventTableParams[]
  setTableParams: (editTableParams: EditEventTableParams[]) => void
}

export default function EditTable({tableParams, setTableParams}: Props) {
  const {groupId, domain} = useResourcePageParams()
  const {data: group} = useFetchGroup(groupId!)
  const currency = group?.currency
  const tableToEdit = tableParams[0]
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newTableParams = [...tableParams]
    newTableParams[0].name = e.target.value
    setTableParams(newTableParams)
  }

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newTableParams = [...tableParams]
    newTableParams[0].description = e.target.value
    setTableParams(newTableParams)
  }

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    let val = parseInt(value)

    if (isNaN(val)) {
      const newTableParams = [...tableParams]
      newTableParams[0].price = undefined
      setTableParams(newTableParams)
    } else {
      if (val < 1) {
        val = 1
      }

      if (val) {
        const newTableParams = [...tableParams]
        newTableParams[0].price = val
        setTableParams(newTableParams)
      }
    }
  }

  const handleSeatingLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    if (value == '') return
    if (value == '0') {
      const newTableParams = [...tableParams]
      newTableParams[0].seatingLimit = 0
      setTableParams(newTableParams)
    } else {
      const newTableParams = [...tableParams]
      newTableParams[0].seatingLimit = parseInt(value)
      setTableParams(newTableParams)
    }
  }

  return (
    <div className='EditTable'>
      <div className='EditTable-inputContainer'>
        <label>Name</label>
        <Input
          className='EditTable-nameInput Input-fullWidth'
          placeholder='Table Name'
          type='text'
          value={tableToEdit?.name}
          onChange={handleNameChange}
        />
      </div>
      <div className='EditTable-inputContainer'>
        <label>Description</label>
        <textarea
          className='EditTable-descriptionInput'
          placeholder='Table Description'
          value={tableToEdit?.description}
          onChange={e => {
            handleDescriptionChange(e)
          }}
        />
      </div>
      <div className='EditTable-inputContainer'>
        <label>Price {getCurrencySymbol(currency)}</label>
        <Input
          className='Input-fullWidth'
          type='number'
          placeholder='Price'
          value={tableToEdit?.price}
          onChange={handlePriceChange}
        />
      </div>
      <div className='EditTable-inputContainer'>
        <label>Seating limit</label>
        <Input
          className='Input-fullWidth'
          type='number'
          placeholder='Price'
          value={tableToEdit?.seatingLimit}
          onChange={handleSeatingLimitChange}
        />
      </div>
    </div>
  )
}
