import React from 'react'

import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

const FraudTicketTypes = () => {
  return (
    <>
      <BackAndTitle title={'Recent Ticket Types over $200'} />
    </>
  )
}

export default FraudTicketTypes
