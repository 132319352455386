import {useState} from 'react'

export function useCopyLink() {
  const [justCopied, setJustCopied] = useState(false)

  const copyLink = (link: string) => {
    navigator.clipboard.writeText(link)
    if (setJustCopied) {
      setJustCopied(true)
      setTimeout(() => {
        setJustCopied(false)
      }, 1000)
    }
  }

  return {justCopied, copyLink}
}
