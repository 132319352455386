import React from 'react'

import {DEFAULT_ACCOUNT_AVI} from '@posh/model-types'
import {CurrencyCode} from '@posh/types'
import {IPendingApproval} from 'apis/Orders/useGetPendingApprovals'
import useUpdatePendingApprovals from 'apis/Orders/useUpdatePendingApprovals'
import {infoButton} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import {getFullName} from 'helpers/User/getFullName'
import {useDimensions} from 'hooks/useDimensions'
import {includes} from 'lodash'
import moment from 'moment'
import mongoose from 'mongoose'
import ApprovalButtons, {LoadingDeactivatedApprovalButton} from 'pages/EventManagementPages/Tickets/ApprovalButtons'
import {AttendeeCheckBox} from 'pages/GroupPage/Marketing/AttendeeCheckBox'
import AttendeeRowSocials from 'pages/GroupPage/Marketing/AttendeeRowSocials'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {ApprovalStatusPresentTenseMap, ApproveOrDeny} from '../..'
import useApprovalCrmContext from '../../ApprovalContextProvider/ApprovalCrmContext'
import useApprovalButtons from '../../useApprovalButtons.hooks'
import sumOn from './sumOn.helpers'

interface ApprovalRowProps {
  rowAttrs: IPendingApproval
  isHost: boolean
  currency?: CurrencyCode
  shouldShowTicket: () => boolean
}

const ApprovalRow = (props: ApprovalRowProps) => {
  const {rowAttrs, isHost, shouldShowTicket} = props
  const {eventId} = useResourcePageParams()
  const {isMobile} = useDimensions()
  const {selectedApprovalIds, setSelectedApprovalIds, setShowApprovalProfile} = useApprovalCrmContext()
  const {expandedType, handleExpandButton, handleCancelConfirmation} = useApprovalButtons()
  const {showToast} = useToast()
  const showOrderDate = (approvalId: string) => {
    if (moment().isAfter(moment(new mongoose.Types.ObjectId(approvalId).getTimestamp()))) {
      const date = moment(new mongoose.Types.ObjectId(approvalId).getTimestamp()).format('MMM DD')
      return date
    }
    const date = moment(new mongoose.Types.ObjectId(approvalId).getTimestamp()).format('h:mma')
    return date
  }

  const {mutate: updatePendingApproval, isLoading} = useUpdatePendingApprovals({
    onSuccess: (_, variables) => {
      showToast({
        type: 'success',
        title: `Successfully ${variables.updates[0].status} order!`,
      })
    },
    onError: (error, variables) => {
      showToast({
        type: 'error',
        title: `There was an error ${ApprovalStatusPresentTenseMap[variables.updates[0].status]} this order!`,
        subtitle: error.message,
      })
    },
  })

  const handleUpdatePendingApproval = (status: ApproveOrDeny) => {
    if (!rowAttrs) return
    const confirmedStatus = status === 'approve' ? 'approved' : 'denied'
    updatePendingApproval({updates: [{status: confirmedStatus, id: rowAttrs._id}], eventId: eventId!})
  }

  return (
    <>
      <tr>
        <td>
          {!isHost && (
            <AttendeeCheckBox
              defaultChecked={includes(selectedApprovalIds, rowAttrs._id)}
              onChecked={checked => {
                if (checked) setSelectedApprovalIds([...selectedApprovalIds, rowAttrs._id])
                if (!checked) setSelectedApprovalIds([...selectedApprovalIds.filter(id => id !== rowAttrs._id)])
              }}
            />
          )}
        </td>

        <td colSpan={2} className='AttendeesTable-img-name'>
          {!isMobile && <PoshImage src={rowAttrs.account.avi ?? DEFAULT_ACCOUNT_AVI}></PoshImage>}
          {getFullName(rowAttrs.account) || <i>No Name</i>}
        </td>
        {!isMobile && <td colSpan={2}>{rowAttrs.account.email}</td>}
        {shouldShowTicket() && <td>{sumOn(rowAttrs.cart.tickets, 'num')}</td>}
        <>
          {!isMobile && !isHost && (
            <td>
              <AttendeeRowSocials
                instagramUrl={rowAttrs.account.profile?.instagram}
                phoneNumber={rowAttrs.account.phone}
              />
            </td>
          )}
          {!isHost && !isMobile && <td colSpan={1}>{rowAttrs.cart.trackingLink}</td>}
          <>
            {!isMobile && <td>{showOrderDate(rowAttrs._id)}</td>}
            <td className='AttendeesTable-viewOrdersRow'>
              <div className='ApprovalCrm-actionRow'>
                {isLoading ? (
                  <LoadingDeactivatedApprovalButton isCrmRow={true} />
                ) : (
                  <ApprovalButtons
                    isCrmRow={true}
                    expandedType={expandedType}
                    handleExpandButton={handleExpandButton}
                    handleCancelConfirmation={handleCancelConfirmation}
                    handleUpdateApprovalStatus={handleUpdatePendingApproval}
                  />
                )}
                <a
                  className='AttendeesTable-viewOrders'
                  onClick={() => {
                    handleCancelConfirmation()
                    setShowApprovalProfile(rowAttrs)
                  }}>
                  <PoshImage src={infoButton} />
                </a>
              </div>
            </td>
          </>
        </>
      </tr>
    </>
  )
}

export default ApprovalRow
