import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {EventModel} from 'apis/Events/types'
import {useFetchGroupEvents} from 'apis/Groups/useFetchGroupEvents'
import EventsTable, {EventRowAction} from 'components/data/EventsTable'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {orderBy} from 'lodash'
import moment from 'moment'

import DeleteEventModal from '../Overview/DeleteEventModal'
import DuplicateEventModal from '../Overview/DuplicateEventsModal'
import BackAndTitle from '../PageComponents/BackAndTitle'

import './styles.scss'

const AllEvents = () => {
  const {groupId, domain} = useGroupMatchParams()
  const {data: events, isFetching} = useFetchGroupEvents(groupId ?? '')
  const navigate = useNavigate()
  const [actionEvent, setActionEvent] = useState<EventModel | undefined>(undefined)
  const [isShowingDuplicateEventModal, setIsShowingDuplicateEventModal] = useState(false)
  const [isShowingDeleteEventModal, setIsShowingDeleteEventModal] = useState(false)
  const search = useLocation().search

  const handleRowAction = (action: EventRowAction, eventId: string) => {
    if (action == EventRowAction.EDIT) {
      navigate(`/${domain}/groups/${groupId}/events/${eventId}/overview` + search)
    }

    if (action == EventRowAction.DELETE) {
      setActionEvent(events!.find(event => event._id == eventId)!)
      setIsShowingDeleteEventModal(true)
    }

    if (action == EventRowAction.DUPLICATE) {
      setActionEvent(events!.find(event => event._id == eventId)!)
      setIsShowingDuplicateEventModal(true)
    }

    if (action == EventRowAction.VIEW) {
      const event = events!.find(event => event._id == eventId)!
      navigate(`/e/${event.url}` + search)
    }
  }

  const sortedEvents = orderBy(
    events,
    ({start}) => {
      return moment(start).valueOf()
    },
    ['desc'],
  )

  return (
    <div className='GroupPage AllEvents'>
      <div className='GroupPage-content'>
        <div className='GroupPage-content-inner'>
          <BackAndTitle title={'All Events'} />
          {!isFetching && sortedEvents && (
            <EventsTable
              data={sortedEvents ?? []}
              onRowAction={handleRowAction}
              isHost={domain === 'host'}
              activeOnly={false}
            />
          )}
          {isFetching && (
            <div className='AllEvents-loading'>
              <SpinLoader />
            </div>
          )}
          <DeleteEventModal
            event={actionEvent}
            isOpen={isShowingDeleteEventModal}
            onClose={() => setIsShowingDeleteEventModal(false)}
          />
          {actionEvent && isShowingDuplicateEventModal && (
            <DuplicateEventModal
              event={actionEvent}
              isOpen={isShowingDuplicateEventModal}
              onClose={() => setIsShowingDuplicateEventModal(false)}
              width={830}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AllEvents
