import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'
import {PropsWithClassName} from 'helpers/props'

import styles from './Card.module.scss'

interface BaseCardProps {
  style?: React.CSSProperties
}

type CardProps = PropsWithClassName<PropsWithChildren<BaseCardProps>>

/**
 * A card component that wraps its children in a card-like border
 * @param props
 * @param props.className A class name to override styles of the card
 */
export const Card = (props: CardProps) => {
  const {className, children, style} = props

  return (
    <div className={classNames(styles.Card, className)} style={style}>
      {children}
    </div>
  )
}
