import React from 'react'

import {AttendeeRowAttributes, FilterBy, FilterData, Gender} from 'apis/Groups'

export type AttendeesCrmContextState = {
  page: number
  setPage: (page: number) => void
  filterByData: FilterData
  setFilterByData: (data: FilterData) => void
  genderFilter: Gender
  setGenderFilter: (data: Gender) => void
  filterByOrder: FilterBy
  setFilterByOrder: (data: FilterBy) => void
  selectedAttendeeIds: string[]
  setSelectedAttendeeIds: (ids: string[]) => void
  selectedAllAttendees: boolean
  setSelectedAllAttendees: (isActive: boolean) => void
  isEventScoped: boolean
  setIsEventScoped: (isActive: boolean) => void
  filteredAttendeeIds: string[]
  setFilteredAttendeeIds: (ids: string[]) => void
  selectedTags: string[]
  setSelectedTags: (ids: string[]) => void
  selectedTickets: string[]
  setSelectedTickets: (ids: string[]) => void
  selectedEvents: string[]
  setSelectedEvents: (ids: string[]) => void
  selectedTrackingLinks: string[]
  setSelectedTrackingLinks: (ids: string[]) => void
  isFilterActive: boolean
  setIsFilterActive: (isActive: boolean) => void
  currentCheckoutField: number
  setCurrentCheckoutField: (isActive: number) => void
  matchAllEvents: boolean
  setMatchAllEvents: (isActive: boolean) => void
  matchAllTags: boolean
  setMatchAllTags: (isActive: boolean) => void
  matchAllTrackingLinks: boolean
  setMatchAllTrackingLinks: (isActive: boolean) => void
  matchAllTickets: boolean
  setMatchAllTickets: (isActive: boolean) => void
  showAttendeeProfile: AttendeeRowAttributes | null
  setShowAttendeeProfile: React.Dispatch<React.SetStateAction<AttendeeRowAttributes | null>>
}

export const AttendeesCrmContext = React.createContext<AttendeesCrmContextState | null>(null)

export default function useAttendeesCrmContext() {
  const context = React.useContext(AttendeesCrmContext)
  if (!context) throw 'AttendeesCrmContext must be provided'
  return context
}
