import React, {ReactNode} from 'react'

import {RecipientRowSkeleton} from './Row'

import './List.styles.scss'

type RecipientsListProps = {
  isLoading: boolean
  children?: ReactNode[]
  numSkeleton: number
}

export const RecipientsList = ({isLoading, children, numSkeleton}: RecipientsListProps) => {
  let render

  const noChildren = !children || children.length === 0
  if (noChildren && !isLoading) {
    render = <p className='center'>No recipients found! Try searching for something else.</p>
  } else if (noChildren && isLoading) {
    render = new Array(numSkeleton).fill(undefined).map((_, index) => <RecipientRowSkeleton key={index} />)
  }

  return <div className='RecipientList'>{render ?? children}</div>
}
