import React from 'react'

import {FileOrderResponse, OrderResponse} from '@posh/model-types'

import CheckoutFieldResponse from './CheckoutFieldResponse'
import getResponseValue from './getResponseValue.helper'

const CheckoutFieldTextResponse = (response: Exclude<OrderResponse, FileOrderResponse>) => {
  return (
    <CheckoutFieldResponse>
      <CheckoutFieldResponse.Label>{response.prompt}</CheckoutFieldResponse.Label>
      <CheckoutFieldResponse.Value>{getResponseValue(response)}</CheckoutFieldResponse.Value>
    </CheckoutFieldResponse>
  )
}

export default CheckoutFieldTextResponse
