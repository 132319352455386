import {MAX_TICKET_QUANTITY} from '@posh/model-types'
import {Ticket} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'

export function useTrackCreateTicketMixpanelEvent() {
  const {trackEvent} = useMixpanel()

  const trackCreateTicketMixpanelEvent = (ticket: Partial<Ticket>) => {
    trackEvent('Create Ticket Type- Add Ticket Type Page', {
      quantityAvailable: ticket.quantityAvailable || MAX_TICKET_QUANTITY,
      minPurchaseQuantity: ticket.purchaseMin ?? null,
      maxPurchaseQuantity: ticket.purchaseLimit ?? null,
      passwordProtected: !!ticket.password,
      requireApproval: !!ticket.approvalRequired,
      hideTier: !!ticket.isHidden,
      hidePrice: !!ticket.priceHidden,
      sellInMultiplies: !!ticket.sellInMultiples,
      disabled: !!ticket.disabled,
    })
  }

  return {trackCreateTicketMixpanelEvent}
}
