import {prependHttpsProtocolIfMissing} from '@posh/model-types'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type UpdateGroupCustomLinksOptions = ReactQueryOptions['groups']['updateGroupCustomLinks']
export type UpdateGroupCustomLinksInput = RouterInputs['groups']['updateGroupCustomLinks']
export type UpdateGroupCustomLinksOutput = RouterOutput['groups']['updateGroupCustomLinks']

export function useUpdateGroupCustomLinks(opts?: UpdateGroupCustomLinksOptions) {
  const qc = trpc.useContext()
  const {mutate, ...rest} = trpc.groups.updateGroupCustomLinks.useMutation({
    ...opts,
    onMutate: data => {
      opts?.onMutate?.(data)
      if (!data) return
      qc.groups.getGroup.setData({groupId: data.groupId}, old => {
        if (!old) return old
        const {update} = data
        const newLinks = (() => {
          switch (update.action) {
            case 'create':
              return [...old.group.links, {url: update.url, title: update.title}]
            case 'delete':
              return old.group.links.filter((_, i) => i !== update.index)
            case 'update':
              return old.group.links.map((link, i) => {
                if (i === update.index) return {...link, ...update}
                return link
              })
          }
        })()
        return {
          group: {
            ...old.group,
            links: newLinks,
          },
        }
      })
    },
    onSettled: data => {
      if (!data) return
      qc.groups.getGroup.invalidate({groupId: data.groupId})
    },
  })
  return {
    mutate: (input: UpdateGroupCustomLinksInput) => {
      if ('url' in input.update && input.update.url) {
        input.update.url = prependHttpsProtocolIfMissing(input.update.url)
      }
      mutate(input)
    },
    ...rest,
  }
}
