import React from 'react'

import useAccountExistsByEmail from 'apis/Accounts/useAccountExistsByEmail'
import {useMixpanel} from 'apis/MixPanelHandler'
import useSessionContext from 'domains/Auth/SessionContext'
import useLiveCartContext, {emailCheckoutFormSchema} from 'domains/LiveCart/LiveCartContext'
import useGetCurrentPathWithParams from 'helpers/useGetCurrentPathWithParams'
import {LoginFlow} from 'pages/LoginPage'
import {CartError} from 'pages/NewCheckoutPage/CartError'

import {EmailLoginFormFirstStep} from './firstStep'
import {EmailLoginFormSecondStep} from './secondStep'

import '../../styles.scss'

export interface EmailLoginFormProps {
  hasAccount: boolean
  setHasAccount: React.Dispatch<React.SetStateAction<boolean>>
  setShowSignUp: React.Dispatch<React.SetStateAction<boolean>>
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setLoginFlow: React.Dispatch<React.SetStateAction<LoginFlow>>
  setStartedWithEmail: React.Dispatch<React.SetStateAction<boolean>>
}

export const EmailLoginForm = ({
  hasAccount,
  setHasAccount,
  setShowSignUp,
  setEmail,
  email,
  setLoginFlow,
  setStartedWithEmail,
}: EmailLoginFormProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {isRSVP, validateAllRequiredCheckoutFields, setFormErrors, formErrors} = useLiveCartContext()
  const {userId} = useSessionContext()
  const {refetch: validateAccountEmail, data, isFetching: isValidatingEmail} = useAccountExistsByEmail(email)
  const {encodedPath} = useGetCurrentPathWithParams()

  const {exists: emailExists, isUnconfirmed} = data ?? {}

  const shouldShowSecondStep = emailExists === true && !userId && hasAccount && !!email && !isUnconfirmed

  const handleEmailCheckClick = async () => {
    try {
      trackMixpanelEvent('Phone Auth Clicked-  Phone Auth Form')
      setFormErrors(errors => ({...errors, email: undefined}))
      if (isRSVP && !validateAllRequiredCheckoutFields()) return
      const validEmail = emailCheckoutFormSchema.validateSyncAt('email', {email: email}, {abortEarly: false})
      const {exists: emailExists, isUnconfirmed, accountId} = (await validateAccountEmail()).data!
      if (isUnconfirmed && validEmail && emailExists && accountId) {
        window.location.href = `/confirm-account/${accountId}?r=${encodedPath}`
      } else if (validEmail && emailExists === true) {
        setHasAccount(true)
        setShowSignUp(false)
      } else if (validEmail && emailExists === false) {
        setHasAccount(false)
        setShowSignUp(false)
        //Only allow signup by phone, if account doesn't exist for email push user to signup by phone
        setLoginFlow('phone')
        setStartedWithEmail(true)
      }
      trackMixpanelEvent('Phone Auth Success-  Phone Auth Form')
    } catch (error: any) {
      const validationError = error.inner[0]
      const {path, message} = validationError
      trackMixpanelEvent('Phone Auth Fail-  Phone Auth Form', {error: message})
      setFormErrors({[path]: message})
    }
  }

  const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      if (!emailExists || userId || !hasAccount) {
        handleEmailCheckClick()
      }
    }
  }

  return (
    <>
      {shouldShowSecondStep ? (
        <EmailLoginFormSecondStep
          email={email}
          setHasAccount={setHasAccount}
          setShowSignUp={setShowSignUp}
          setStartedWithEmail={setStartedWithEmail}
          setLoginFlow={setLoginFlow}
          setEmail={setEmail}
        />
      ) : (
        <EmailLoginFormFirstStep
          setEmail={setEmail}
          email={email}
          handleEmailCheckClick={handleEmailCheckClick}
          isValidatingEmail={isValidatingEmail}
          onKeyDown={onKeyDown}
        />
      )}
      <CartError error={formErrors.email} center />
    </>
  )
}
