import {useParams} from 'react-router-dom'

type EventMatchParams = Record<'eventId' | 'domain', string>

export const useEventMatchParams = () => {
  return useParams<EventMatchParams>()
}

export const useEventBaseUrl = () => {
  const {eventId, domain} = useEventMatchParams()
  return `/${domain}/events/${eventId}`
}
