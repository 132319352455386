import {Control, useController} from 'react-hook-form'

import {useGetGroup} from 'apis/Groups/useGetGroup'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'

export function useGetNeedsPaymentsEnabled(groupId: string, control: Control<EventVisualsForm>) {
  const {data: group, isLoading: isLoading} = useGetGroup({groupId: groupId!}, {enabled: !!groupId})
  const groupCanAcceptPayments = group?.group.canAcceptPayments

  const {
    field: {value: tickets},
  } = useController({control: control, name: 'tickets'})
  const isPaid = (tickets ?? []).some(ticket => ticket.price && ticket.price > 0)

  const requiresPaymentsEnabled = isPaid && !groupCanAcceptPayments

  return {requiresPaymentsEnabled, isLoading}
}
