import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {TicketLink} from './types'

export interface UpdateTicketLinkParams {
  parentTicketId: string
  childTicketId: string
  openOrShow: string
  displayCondition: string
  eventId: string
}
export interface UpdateTicketLinkResponse {
  updated: TicketLink
}

async function updateTicketLink(data: UpdateTicketLinkParams): Promise<UpdateTicketLinkResponse> {
  const response = await PoshApi.updateTicketLink(data)
  return response.data
}

export function useUpdateTicketLink(opts?: UseMutationOptions<UpdateTicketLinkResponse, any, UpdateTicketLinkParams>) {
  return useMutation((params: UpdateTicketLinkParams) => updateTicketLink(params), opts)
}
