import React from 'react'
import NumberFormat from 'react-number-format'

import {Bank, Bolt} from 'components/assets/Icons'
import Badge from 'components/Badge'
import Button from 'components/form/Button'

import './styles.scss'

const payouts = [
  {
    id: 1,
    arrival_date: 'May 1, 2023',
    amount: 7650.4,
    status: 'INSTANT',
  },
  {
    id: 2,
    arrival_date: 'April 7, 2023',
    amount: 8506,
    status: 'PAID',
  },
  {
    id: 3,
    arrival_date: 'March 1, 2023',
    amount: 1000,
    status: 'PAID',
  },
  {
    id: 4,
    arrival_date: 'February 1, 2023',
    amount: 1000,
    status: 'INSTANT',
  },
  {
    id: 5,
    arrival_date: 'January 1, 2023',
    amount: 1000,
    status: 'INSTANT',
  },
  {
    id: 6,
    arrival_date: 'December 1, 2022',
    amount: 1000,
    status: 'PAID',
  },
]

function PayoutTypeSelector() {
  return (
    <>
      <a className={'PayoutTypeSelector-option'}>
        <Bank className='PayoutTypeSelector-icon' />
        <p className='PayoutTypeSelector-heading'>2-3 Biz Days</p>
      </a>
      <a className={'PayoutTypeSelector-option instantSelected'}>
        <Bolt className='PayoutTypeSelector-icon' />
        <p className='PayoutTypeSelector-heading'>Instant</p>
      </a>
    </>
  )
}

const TransferBalanceModalLandingPage = () => {
  const SelectPayout = () => {
    const limit = 898288
    return (
      <>
        {limit && (
          <p style={{padding: '10px'}} className='center text-small'>
            $20,500.00 available for transfer
          </p>
        )}
        <div className='TransferBalanceModal-payoutTypesWrapper'>
          <PayoutTypeSelector />
        </div>

        <div className='TransferBalanceModal-buttonRow'>
          <Button className={'Button--yellow TransferBalanceModal-tranferButton instant'}>
            Instant Payout $7,370.95
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className='SectionElementFrameB'>
      <div className='PayoutsTableLandingPage'>
        <h5>PAYOUTS</h5>
        <table>
          <thead>
            <tr>
              <th>Arrival Date</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {payouts.map(payout => (
              <tr key={payout.id}>
                <td>{payout.arrival_date}</td>
                <td>${payout.amount.toFixed(2)}</td>
                <td>
                  <Badge className={payout.status == 'INSTANT' ? 'Badge--blueGradient' : 'Badge--green'}>
                    {payout.status}
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='TransferBalanceModalWrapper'>
        <div className='TransferBalanceModal'>
          <h3 className='center noMargin'>Initiate Payout</h3>
          <div className='TransferBalanceModal-currencyInputDiv'>
            <p className='TransferBalanceModal-currencyPrefix'>$</p>
            <NumberFormat
              className={'TransferBalanceModal-currencyInput'}
              placeholder={`0`}
              style={{width: '170px'}}
              value={7370.95}
              thousandSeparator=','
              allowNegative={false}
              onValueChange={() => null}
            />
          </div>
          <SelectPayout />
        </div>
      </div>
    </div>
  )
}

export default TransferBalanceModalLandingPage
