import React from 'react'

import {EventPublic} from 'apis/Events/types'
import {EventCard} from 'pages/EventCard'

const UpcomingEvents = ({events}: {events: EventPublic[]}) => {
  events.shift()

  return (
    <>
      <hr />
      <p className='PProfile-subsectionTitle'>Upcoming Events</p>
      <div className='PProfile-upcomingEventsWrapper'>
        {events.map((event, i) => (
          <EventCard
            key={i}
            backgroundImage={event?.flyer}
            url={event?.url}
            timezone={event?.timezone}
            startUtc={event?.startUtc}
            name={event?.name}
            venueName={event?.venue?.name}
          />
        ))}
      </div>
    </>
  )
}

export default UpcomingEvents
