import {EventModel} from 'apis/Events/types'

export const isPaidEvent = (event: EventModel) => {
  if (!event.isRSVPEvent) {
    return true
  }

  if (event.tickets.some(ticket => ticket.price > 0)) {
    return true
  }

  return false
}
