import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {FetchGroupAttendeesParams} from '.'

export type FetchAllAttendeesParams = Omit<FetchGroupAttendeesParams, 'page'>

export async function fetchGroupAttendees(params: FetchAllAttendeesParams): Promise<string[]> {
  const response = await PoshApi.fetchAllGroupAttendees(params)
  const data = response.data
  return data.attendees as string[]
}

export function useFetchAllGroupAttendees() {
  return useMutation<string[], unknown, FetchAllAttendeesParams>(params => fetchGroupAttendees(params), {
    retry: false,
  })
}
