import React, {useEffect, useState} from 'react'

import {Checkmark, CopyRectangles, Share, Swap} from 'components/assets/MobileIcons'
import {useToast} from 'components/toasts/ToastProvider'
import {useDimensions} from 'hooks/useDimensions'

import {useCopyLink} from '../../hooks/useCopyLink'
import CopyBlockAction from './CopyBlockAction'

import './CopyBlock.styles.scss'

/**
 *
 * @param links can be a string or an array of strings, an array supports the swap functionality
 * @returns
 */
const CopyBlock = ({
  links,
  onCopy,
  onSwapLink,
}: {
  links: string | string[]
  onCopy?: (copiedText: string) => void
  onSwapLink?: () => void
}) => {
  const isLinksArray = Array.isArray(links)
  const moreThanOneLink = isLinksArray && links.length > 1
  const [currentLinkIndex, setCurrentLinkIndex] = useState(0)
  const currentLink = isLinksArray ? links[currentLinkIndex] : links
  const {copyLink, justCopied} = useCopyLink()
  const {showToast} = useToast()
  const {isMobile} = useDimensions()
  const canUseMobileShare = navigator.canShare && navigator.canShare() && isMobile

  useEffect(() => {
    if (justCopied) {
      showToast({type: 'success', title: 'Copied to clipboard!', subtitle: currentLink})
    }
  }, [justCopied, showToast])

  const swapCurrentLink: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    setCurrentLinkIndex((currentLinkIndex + 1) % links.length)
    onSwapLink && onSwapLink()
  }
  const copyCurrentLink = () => {
    copyLink(currentLink)
    onCopy?.(currentLink)
  }
  const shareCurrentLink = async () => {
    try {
      await navigator.share({url: currentLink})
    } catch (e) {
      console.error(e.message)
      showToast({type: 'error', title: 'There was an issue sharing your link. Please try copying it instead.'})
    }
  }

  return (
    <div className='CopyBlock' onClick={copyCurrentLink}>
      <p>{currentLink}</p>
      <div className='CopyBlock-actions'>
        {moreThanOneLink && (
          <CopyBlockAction onClick={swapCurrentLink}>
            <Swap />
          </CopyBlockAction>
        )}
        <CopyBlockAction onClick={copyCurrentLink}>
          {justCopied ? <Checkmark /> : <CopyRectangles color='white' width={12} height={12} />}
        </CopyBlockAction>
        {canUseMobileShare && (
          <CopyBlockAction onClick={shareCurrentLink}>
            <Share />
          </CopyBlockAction>
        )}
      </div>
    </div>
  )
}

export default CopyBlock
