import React, {forwardRef} from 'react'

import {TCheckoutSummaryCustomField} from 'apis/Checkout/useFetchCheckoutSummary'
import {TextFieldVariants} from 'components/form/TextField'
import {isEmpty} from 'lodash'

import useLiveCartContext from '../../../domains/LiveCart/LiveCartContext'
import {CartError} from '../CartError'
import CheckoutField from '../CheckoutField/CheckoutField'

const getCheckoutFieldValue = (field: TCheckoutSummaryCustomField) => {
  if (field.type === 'file_upload') return field.url
  if (field.type === 'dropdown') return field.answer
  if (field.type === 'input') return field.answer
  return field.answers
}

const CustomCheckoutFields = forwardRef<HTMLDivElement>(({}, ref) => {
  const {checkoutResponses, isRSVP, customFieldErrors} = useLiveCartContext()
  const className = isRSVP ? 'RSVPModal-Section' : 'CheckoutPage-Section'
  return (
    checkoutResponses &&
    !isEmpty(checkoutResponses) && (
      <div className={className + ' fade'} ref={ref}>
        {checkoutResponses.map(checkoutField => {
          const value = getCheckoutFieldValue(checkoutField)
          const options =
            checkoutField.type === 'dropdown' || checkoutField.type === 'checkboxes' ? checkoutField.options : undefined
          const isRequired = checkoutField.required ?? false
          return (
            <>
              <div className='CheckoutPage-Section-customFieldLabel' style={isRSVP ? {fontWeight: 'bold'} : {}}>
                {checkoutField.prompt}
                {isRequired && <span id='asterisk'>*</span>}
              </div>
              <CheckoutField
                field_type={checkoutField.type}
                field_key={checkoutField.fieldId.toString()}
                value={value as string}
                variant={TextFieldVariants.DARK}
                nativeProps={{}}
                isCustomField={true}
                options={options}
              />
              <CartError error={customFieldErrors[checkoutField.fieldId.toString()]} center />
            </>
          )
        })}
      </div>
    )
  )
})

CustomCheckoutFields.displayName = 'CustomCheckoutFields'

export default CustomCheckoutFields
