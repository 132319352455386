import {useCallback, useRef} from 'react'

export const useIntersectionObserverRef = <T extends HTMLElement>({
  callback,
  options,
}: {
  callback: IntersectionObserverCallback
  options?: IntersectionObserverInit
}) => {
  // store a reference to our intersesction observer so that we only
  // ever have one at a time!
  //
  // Otherwise we could instantiate a new observer on every render
  // and have orphaned intersection observers?
  const intersectionObserver = useRef<IntersectionObserver | null>(null)
  const observedElementRef = useCallback(
    (element: T | null) => {
      if (intersectionObserver.current) {
        intersectionObserver.current?.disconnect()
      }

      intersectionObserver.current = new IntersectionObserver(callback, options)

      if (element) {
        intersectionObserver.current?.observe(element)
      }
    },
    [callback, options],
  )

  return observedElementRef
}
