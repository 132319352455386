export enum FileStatus {
  ADDED = 'added',
  QUEUED = 'queued',
  UPLOADING = 'uploading',
  ERROR = 'error',
  UPLOADED = 'uploaded',
}

export interface MultiFileUploadFile {
  id: string
  file?: File
  status: FileStatus
  progress: number
  s3Uri?: string
}
export interface UseMultiFileUploadManagerState {
  files: MultiFileUploadFile[]
}

export enum MultiFileUploadManagerActionTypes {
  ADD_FILES = 'ADD_FILES',
  QUEUE_FILES = 'QUEUE_FILES',
  CLEAR_FILES = 'CLEAR_FILES',
  SET_STATUS = 'SET_STATUS',
  SET_STATUS_UPLOADING = 'SET_STATUS_UPLOADING',
  SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS',
  REMOVE_UPLOAD = 'REMOVE_UPLOAD',
}

export type UploadManagerAction =
  | {
      type: MultiFileUploadManagerActionTypes.ADD_FILES
      files: File[]
      status: FileStatus
      getFileErrorMessage: (file: File) => string
      onExceedMaxFiles: () => void
    }
  | {
      type: MultiFileUploadManagerActionTypes.CLEAR_FILES
    }
  | {
      type: MultiFileUploadManagerActionTypes.QUEUE_FILES
    }
  | {
      type: MultiFileUploadManagerActionTypes.SET_STATUS
      id: string
      status: FileStatus
      // For updating status to uploaded
      s3Uri?: string
    }
  | {
      type: MultiFileUploadManagerActionTypes.SET_STATUS_UPLOADING
      id: string
    }
  | {
      type: MultiFileUploadManagerActionTypes.SET_UPLOAD_PROGRESS
      id: string
      progress: number
    }
  | {
      type: MultiFileUploadManagerActionTypes.REMOVE_UPLOAD
      id: string
    }
