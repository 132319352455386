import {CurrencyCode} from '@posh/types'

export const getCurrencySymbol = (currency: CurrencyCode | undefined) => {
  if (currency == 'GBP') {
    return '£'
  } else if (currency == 'EUR') {
    return '€'
  } else if (currency == 'CAD') {
    return '$'
  } else if (currency == 'PEN') {
    return 'S/.'
  } else if (currency == 'USD') {
    return '$'
  } else if (currency == 'MXN') {
    return 'MEX$'
  } else if (currency == 'DKK') {
    return 'kr'
  } else if (currency == 'AUD') {
    return '$'
  } else if (currency == 'CHF') {
    return 'CHF'
  } else if (currency == 'GTQ') {
    return 'Q'
  } else if (currency == 'HUF') {
    return 'Ft'
  } else if (currency == 'AED') {
    return 'د.إ'
  }
  return '$'
}
