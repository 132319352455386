import React from 'react'

import {PoshLogo} from 'components/PoshLogo'

import './styles.scss'

const DashboardFooter = () => {
  return (
    <div className='DashboardFooter'>
      <div>
        <PoshLogo.Link />
      </div>
      <div className='DashboardFooter-links'>
        <a href='mailto:support@posh.vip' target='_blank' rel='noreferrer'>
          Support
        </a>
        <a href='/tos' target='_blank'>
          Terms of Service
        </a>
        <a href='/privacy' target='_blank'>
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

export default DashboardFooter
