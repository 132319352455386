import useSessionContext from 'domains/Auth/SessionContext'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type ListAccountFollowingOptions = ReactQueryOptions['social']['account']['listAccountFollowing']
export type ListAccountFollowingsInput = RouterInputs['social']['account']['listAccountFollowing']
export type ListAccountFollowingOutput = RouterOutput['social']['account']['listAccountFollowing']

export function useListAccountsFollowing(input: ListAccountFollowingsInput, opts?: ListAccountFollowingOptions) {
  return trpc.social.account.listAccountFollowing.useQuery(input, opts)
}

export function useIsCurrentUserFollowingAccount(accountId?: string, opts?: ListAccountFollowingOptions) {
  const {currentUser} = useSessionContext()
  const {data, isLoading} = useListAccountsFollowing(undefined, {
    ...opts,
    enabled: !!currentUser,
  })

  if (!data || !accountId) {
    return {isCurrentUserFollowingAccount: false, isLoading}
  }

  const isCurrentUserFollowingAccount = data.following.some(account => account.to === accountId)

  return {isCurrentUserFollowingAccount, isLoading}
}
