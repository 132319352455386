import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CommunityArtist, StaffMember, Venue} from '.'

export interface PendingCommunityResponse {
  pendingVenues?: Venue[]
  pendingStaff?: StaffMember[]
  pendingArtists?: CommunityArtist[]
}

async function fetchPendingCommunity(): Promise<PendingCommunityResponse> {
  const response = await PoshApi.fetchPendingCommunity()
  return response.data
}

export default function useFetchPendingCommunity() {
  return useQuery<PendingCommunityResponse>(['pendingCommunity'], () => fetchPendingCommunity(), {
    refetchOnMount: false,
  })
}
