import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {GalleryModel} from './types'

export async function createGallery(eventId: string, gallery: GalleryModel) {
  const response = await PoshApi.createGallery(eventId, gallery)
  return response.data
}

export function useCreateGallery() {
  return useMutation((params: {eventId: string; gallery: GalleryModel}) =>
    createGallery(params.eventId, params.gallery),
  )
}
