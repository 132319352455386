import {FetchTimeSeriesAnalyticsApiInput} from 'apis/Events/useFetchTimeSeriesAnalytics'
import moment from 'moment'

import {TOverviewTimePeriod} from './TimePeriodSelector'

type GetTimeSeriesConfigurationParams = {
  startDate: Date
  endDate: Date
}

type Interval = FetchTimeSeriesAnalyticsApiInput['timeSeriesConfig']['interval']

export const getDateRange = (timePeriod: Exclude<TOverviewTimePeriod, 'custom' | 'event-lifetime'>) => {
  let startDateMoment
  let endDateMoment
  if (timePeriod === 'ytd') {
    startDateMoment = moment().startOf('year')
    endDateMoment = moment().startOf('day')
  } else if (timePeriod === 'month') {
    startDateMoment = moment().startOf('month')
    endDateMoment = moment().startOf('day')
  } else {
    startDateMoment = moment().startOf(timePeriod)
    endDateMoment = moment().endOf(timePeriod)
  }
  return {
    startDate: moment(startDateMoment),
    endDate: moment(endDateMoment),
  }
}

export const getTimeSeriesConfiguration = (
  params: GetTimeSeriesConfigurationParams,
): FetchTimeSeriesAnalyticsApiInput['timeSeriesConfig'] => {
  const startMoment = moment(params.startDate).startOf('day')
  const endMoment = moment(params.endDate).startOf('day').add(1, 'day')

  let interval: Interval = 'hour'
  if (endMoment.diff(startMoment, 'days') <= 2) {
    interval = 'hour'
  } else if (endMoment.diff(startMoment, 'days') <= 60) {
    interval = 'day'
  } else {
    interval = 'week'
  }

  const range = {
    startDate: startMoment.utc().toDate(),
    endDate: endMoment.utc().toDate(),
  }

  return {
    range,
    interval,
  }
}
