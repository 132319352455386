import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {OrderAndName} from '.'

export async function fetchOrdersAndNames(promoCode: string, eventId?: string): Promise<OrderAndName[]> {
  const response = await PoshApi.fetchOrders(promoCode, eventId)
  return response.data.ordersAndNames
}

export default function useFetchOrders(promoCode: string, eventId?: string) {
  return useQuery(['orders', promoCode], () => fetchOrdersAndNames(promoCode, eventId))
}
