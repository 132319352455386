import React from 'react'

import {CurrencyCode} from '@posh/types'
import moment from 'moment'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

interface Installment {
  amount: number
  date: string
}

interface PaymentPlanOptionProps {
  installments: Installment[]
  cartTotal?: number
  onSelectPaymentPlanOption: () => void
  isSelected?: boolean
  index: number
  currency?: CurrencyCode
}

const PaymentPlanOption = (props: PaymentPlanOptionProps) => {
  const {installments, onSelectPaymentPlanOption, isSelected, cartTotal, index} = props
  // TODO: clean some of this up with data from new trpc checkout summary
  const secondInstallmentWithoutFees = installments[1]
  const paymentPlanTotal = installments.reduce((a, b) => a + b.amount, 0)
  const poshFee = paymentPlanTotal - cartTotal!
  const currencySymbol = getCurrencySymbol(props.currency)
  const showFirstInstallmentPrice = installments[0].amount < installments[1].amount

  return (
    <div onClick={onSelectPaymentPlanOption} className={`PaymentPlans-Option ${isSelected && 'selected'}`}>
      {!isSelected && <div className='PaymentPlans-Option-Option'>Option #{index + 2}</div>}
      <p className='PaymentPlans-Option-Total noMargin'>
        {currencySymbol}
        {(cartTotal! + poshFee).toFixed(2)}
      </p>
      <div className='PaymentPlans-Option-InstallmentContainer'>
        <p className='PaymentPlans-Option-InstallmentPrice text-xl noMargin'>
          {showFirstInstallmentPrice
            ? `${currencySymbol}${installments[0].amount.toFixed(2)}`
            : `${currencySymbol}${secondInstallmentWithoutFees.amount.toFixed(2)}`}
        </p>
        <div className='PaymentPlans-Option-InfoText'>
          {showFirstInstallmentPrice ? 'first installment' : 'per installment'}
        </div>
      </div>
      <div className='PaymentPlans-Option-InstallmentRows'>
        {installments.map((installment, i) => (
          <div key={i} className='PaymentPlans-Option-InstallmentRow'>
            <p className='noMargin'>
              <strong>
                {currencySymbol}
                {installment.amount.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}
              </strong>{' '}
              Installment #{i + 1} - {i == 0 ? 'Today' : moment(installment.date).format('MMM Do')}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PaymentPlanOption
