import React from 'react'
import {Helmet} from 'react-helmet'

const CommunityHelmet = () => {
  return (
    <Helmet>
      <title>Staff &amp; Venues For Your Next Event | Posh Community</title>
      <meta
        name='description'
        content='Book venues, musicians, DJs, photographers, security, bartenders, videographers, and more.'
      />
      <meta property='og:title' content='Staff &amp; Venues For Your Next Event | Posh Community' />
      <meta
        property='og:description'
        content='Book venues, musicians, DJs, photographers, security, bartenders, videographers, and more.'
      />
      <meta property='og:image' content='https://images.posh.vip/b2/stock-party.jpeg' />
      <meta property='og:url' content='https://posh.vip/community' />
      <meta property='og:type' content='website' />
      <meta property='twitter:title' content='Staff &amp; Venues For Your Next Event | Posh Community' />
      <meta
        property='twitter:description'
        content='Book venues, musicians, DJs, photographers, security, bartenders, videographers, and more.'
      />
      <meta property='twitter:image' itemProp='image' content='https://images.posh.vip/b2/stock-party.jpeg' />
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content='https://posh.vip/community' />
      <link rel='canonical' href='https://posh.vip/community' />
    </Helmet>
  )
}

export default CommunityHelmet
