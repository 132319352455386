import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface CancelOrderParams {
  orderId: string
  isRelisting: boolean
}

export async function cancelFreeOrder(params: CancelOrderParams): Promise<string> {
  const response = await PoshApi.cancelFreeOrder(params)
  return response.data.message
}

export default function useCancelOrder() {
  return useMutation((params: CancelOrderParams) => cancelFreeOrder(params), {
    retry: false,
  })
}
