import React from 'react'

import styles from './InputLabel.module.scss'

interface InputLabelProps {
  label: string
  subtitle?: string
}

export const InputLabel = (props: InputLabelProps) => {
  const {label, subtitle} = props

  return (
    <div>
      <div className={styles.Label}>{label}</div>
      {subtitle && <div className={styles.Subtitle}>{subtitle}</div>}
    </div>
  )
}
