import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetEventPlaylistOptions = ReactQueryOptions['events']['playlists']['getEventPlaylist']
type GetEventPlaylistInput = RouterInputs['events']['playlists']['getEventPlaylist']
export type GetEventPlaylistOutput = RouterOutput['events']['playlists']['getEventPlaylist']
export type TEventPlaylist = GetEventPlaylistOutput['playlist']

export function useGetEventPlaylist(input: GetEventPlaylistInput, opts?: GetEventPlaylistOptions) {
  return trpc.events.playlists.getEventPlaylist.useQuery(input, opts)
}
