import {setSessionContext} from 'apis/Auth/setSessionContext'
import useSessionContext from 'domains/Auth/SessionContext'
import {RouterInputs, RouterOutput, trpc} from 'lib/trpc'

export type ConfirmAndAuthenticateAccountParams = RouterInputs['auth']['confirmAndAuthenticateAccount']
export type ConfirmAndAuthenticateAccountOutput = RouterOutput['auth']['confirmAndAuthenticateAccount']

export function useConfirmAndAuthenticateAccount() {
  const sessionContext = useSessionContext()
  return trpc.auth.confirmAndAuthenticateAccount.useMutation({
    onSuccess: data => {
      const {userId, token, account, expires, accountRoles, groupsWhereAccountIsOwnerMap} = data
      setSessionContext(
        {
          userId,
          token,
          account,
          expires,
          accountRoles,
          groupsWhereAccountIsOwnerMap,
        },
        sessionContext,
      )
    },
  })
}
