import React from 'react'

import {useFetchEventsForChooseEventLink} from 'apis/Events/useFetchEventsForChooseEventLink'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {PoshImage} from 'components/PoshImage/PoshImage'
import moment from 'moment-timezone'
import {useResourcePageParams} from 'pages/PoshAppLayout'

const EventsModal = ({
  isOpen,
  onClose,
  onChooseEvent,
}: {
  isOpen: boolean
  onClose: () => void
  onChooseEvent: (eventId: string, hexUrl?: string) => void
}) => {
  const {groupId, eventId} = useResourcePageParams()
  const {data: events} = useFetchEventsForChooseEventLink({groupId: groupId!, eventId})

  const onEventClick = (eventId: string, hexUrl?: string) => {
    onClose()
    onChooseEvent(eventId, hexUrl)
  }

  return (
    <PoshStyledModal isOpen={isOpen} onClose={onClose} contentClassName='EventsModal'>
      <h3 className='center noMargin'>Attach Event Link</h3>
      <p>Select an event to attach to this campaign.</p>
      <div className='EventsModal-options'>
        {events?.map(event => (
          <div
            key={event._id}
            className='EventsModal-eventOption clickable'
            onClick={() => onEventClick(event._id, event.hexUrl)}>
            <PoshImage className='EventsModal-eventOption-flyer' src={event.flyer} />
            <div>
              <h4 className='noMargin'>{event.name}</h4>
              <p className='noMargin'>{moment(event.startUtc).tz(event.timezone).format('MMMM D, YYYY - h:mmA')}</p>
            </div>
          </div>
        ))}
      </div>
    </PoshStyledModal>
  )
}

export default EventsModal
