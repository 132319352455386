import React, {useMemo} from 'react'
import {BrowserRouter} from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function importAll(r) {
  r.keys().forEach(r)
}

// Automatically import all files ending in *.ttf from the fonts directory
importAll(require.context('../src/assets/webfonts/', true, /\.ttf$/))

import '../../../node_modules/react-grid-layout/css/styles.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import {OptimizelyProvider} from '@optimizely/react-sdk'
import {DEV_STRIPE_PUBLISHABLE_KEY, STRIPE_PUBLISHABLE_KEY} from '@posh/types'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {optimizelyClient} from 'apis/OptimizelyHandler'
import {NavigationContextProvider} from 'components/Navigation/NavigationContext'
import {ToastProvider} from 'components/toasts/ToastProvider'
import {TrpcQueryClientProvider} from 'contexts/TrpcQueryClientProvider'
import CartContextProvider from 'domains/Cart/CartContextProvider'
import isDevEnv from 'helpers/isDevEnv'
import {v4 as uuidv4} from 'uuid'

import SessionContextProvider from './domains/Auth/SessionContextProvider'
import {AvailableFeaturesProvider} from './domains/AvailableFeatures/AvailableFeaturesProvider'
import {PermissionsModalContextProvider} from './domains/Teams/PermissionsContext'
import {ZendeskProvider} from './domains/Zendesk/ZendeskProvider'
import Layout from './pages/Layout'

import './assets/stylesheets/styles.scss'

const isDev = isDevEnv()
const keyToUse = isDev ? DEV_STRIPE_PUBLISHABLE_KEY : STRIPE_PUBLISHABLE_KEY

const App = () => {
  const stripePromise = loadStripe(keyToUse)
  const isOptEditor = new URLSearchParams(window.location.search).has('optimizely_editor')

  const useInititateOptimizely = () => {
    const id = useMemo(() => uuidv4(), [])
    if (isOptEditor) {
      return {optimizelyId: undefined, id}
    }
    const optId = window['optimizely']?.get('visitor_id') ?? undefined
    return {optimizelyId: optId, id}
  }

  const {optimizelyId, id} = useInititateOptimizely()

  const PoshOptimizelyProvider = isOptEditor ? React.Fragment : OptimizelyProvider

  return (
    <BrowserRouter>
      <ToastProvider>
        <SessionContextProvider>
          <TrpcQueryClientProvider>
            <PoshOptimizelyProvider optimizely={optimizelyClient} user={{id: optimizelyId?.randomId ?? id}}>
              <PermissionsModalContextProvider>
                <AvailableFeaturesProvider>
                  <CartContextProvider>
                    <Elements stripe={stripePromise}>
                      <NavigationContextProvider>
                        <ZendeskProvider>
                          <Layout />
                        </ZendeskProvider>
                      </NavigationContextProvider>
                    </Elements>
                  </CartContextProvider>
                </AvailableFeaturesProvider>
              </PermissionsModalContextProvider>
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </PoshOptimizelyProvider>
          </TrpcQueryClientProvider>
        </SessionContextProvider>
      </ToastProvider>
    </BrowserRouter>
  )
}

export default App
