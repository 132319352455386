/**
 * This file contains the default attributes for the create event page.
 * It is used alongside optimizely experiments to test the impact of different attributes on user behavior.
 */

const POSSIBLE_TITLES_AND_DESCRIPTIONS = [
  {
    name: 'The Get-Together',
    description: `You bring your friends, we'll bring the party. Music and drinks all night.`,
  },
  {
    name: 'Weekend Unwind',
    description: `Shake off the week with rooftops, music, and Espressos Martinis.`,
  },
  {
    name: 'A Night to Remember',
    description: `Join us for a night of music, dance, and laughter.`,
  },
]

const POSSIBLE_FLYERS = [
  'https://images.posh.vip/create-event-default-flyers/052824_Default_Flyer_1.webp',
  'https://images.posh.vip/create-event-default-flyers/052824_Default_Flyer_2.webp',
]

const getRandomEventNameAndDescription = () => {
  return POSSIBLE_TITLES_AND_DESCRIPTIONS[Math.floor(Math.random() * POSSIBLE_TITLES_AND_DESCRIPTIONS.length)]
}

const getRandomFlyer = () => {
  return POSSIBLE_FLYERS[Math.floor(Math.random() * POSSIBLE_FLYERS.length)]
}

const getStartDate = () => {
  // get the upcoming friday at 8pm
  const date = new Date()
  const day = date.getDay()
  const diff = 5 - day
  date.setDate(date.getDate() + diff)
  date.setHours(20)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

const getStartAndEndDate = () => {
  // get the upcoming friday at 8pm
  const startDate = getStartDate()
  // get the next day at 4am
  const endDate = getStartDate()
  endDate.setDate(endDate.getDate() + 1)
  endDate.setHours(4)
  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  }
}

export {getRandomEventNameAndDescription, getRandomFlyer, getStartAndEndDate}
