import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchEventsOptions = ReactQueryOptions['poshAdmin']['poshAdminEventsRouter']['fetchEvents']
type FetchEventsInput = RouterInputs['poshAdmin']['poshAdminEventsRouter']['fetchEvents']
export type FetchEventsAdminOutput = RouterOutput['poshAdmin']['poshAdminEventsRouter']['fetchEvents']
export type FetchEventsAdminOutputItem = FetchEventsAdminOutput['items']['0']

export function useFetchEvents(input: FetchEventsInput) {
  return trpc.poshAdmin.poshAdminEventsRouter.fetchEvents.useInfiniteQuery(input)
}
