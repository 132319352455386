import React from 'react'

export interface Statistic {
  label: string
  value: number
}

interface StatisticsProps {
  statistics?: Statistic[]
  type: string
}

const Statistics = ({statistics, type}: StatisticsProps) => {
  if (!statistics) return <></>
  const validStatistics = statistics.filter(statistic => statistic.value > 0)
  return (
    <div className='PProfile-statistics'>
      {validStatistics.map((statistic, index) => {
        let value: string | number = statistic.value
        const isLastStatistic = index === validStatistics.length - 1
        if (value > 1000000) {
          value = value / 1000000
          value = Math.round(value * 10) / 10
          value = Number(value)
          value = Number(value.toFixed(1))
          value = value.toString() + 'M'
        } else if (value > 10000) {
          value = value / 1000
          value = Math.round(value * 10) / 10
          value = Number(value)
          value = Number(value.toFixed(1))
          value = value.toString() + 'K'
        } else {
          value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return (
          <>
            <div className={`PProfile-statistics-statistic ${type}`}>
              <h5>{value}</h5>
              <p>{statistic.label}</p>
            </div>
            {!isLastStatistic && <div className='PProfile-statistics-separator' />}
          </>
        )
      })}
    </div>
  )
}

export default Statistics
