import React, {createContext, PropsWithChildren, useContext} from 'react'

import {FetchEventResponse} from 'apis/Events/useFetchEvent'

type EventManagementContextValue = Omit<FetchEventResponse, 'event'> & {
  event: FetchEventResponse['event'] & {
    isOver: boolean
    createdAt: Date
  }
}

const EventManagementContext = createContext<EventManagementContextValue | undefined>(undefined)

export const useEventManagementContext = () => {
  const context = useContext(EventManagementContext)
  if (!context) {
    throw new Error('useEventManagementContext must be used within a EventManagementContextProvider')
  }
  return context
}

export const EventManagementContextProvider = ({
  children,
  eventData,
}: PropsWithChildren<{eventData: EventManagementContextValue}>) => {
  return <EventManagementContext.Provider value={eventData}>{children}</EventManagementContext.Provider>
}
