import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'

import './styles.scss'

interface TicketsContainerProps {
  lightmode: boolean
  accentColor: string
  style?: React.CSSProperties
}

/**
 * Container for tickets on the event page that has a border and shadow
 */
export const TicketsContainer = (props: PropsWithChildren<TicketsContainerProps>) => {
  const {lightmode, accentColor, style, children} = props
  const colorModeModifier = lightmode ? 'is-lightMode' : 'is-darkMode'
  return (
    <div
      className={classNames('EventPage-ticketContainer', colorModeModifier)}
      style={{borderColor: accentColor, boxShadow: `0 0 30px -5px ${accentColor}`, ...style}}>
      {children}
    </div>
  )
}
