type FreelancerOption = {
  prompt: string
  description: string
  button: string
  image: string
  link: string
}

const FreelancerOptions: FreelancerOption[] = [
  {
    prompt: 'Looking for a venue?',
    description: "We'll help you find one!",
    button: 'Book A Venue',
    image: 'https://images.posh.vip/b2/freelancericons/Venue.png',
    link: 'https://posh.vip/cpn/freelancer_venue',
  },
  {
    prompt: 'Looking for a DJ?',
    description: "Let's get one in the mix!",
    button: 'Book A DJ',
    image: 'https://images.posh.vip/b2/freelancericons/DJ.png',
    link: 'https://posh.vip/cpn/freelancer_dj',
  },
  {
    prompt: 'Need a photographer?',
    description: 'We got shooters.',
    button: 'Book A Photographer',
    image: 'https://images.posh.vip/b2/freelancericons/Photographer.png',
    link: 'https://posh.vip/cpn/freelancer_photographer',
  },
  {
    prompt: 'Security an issue?',
    description: "Let's get you some muscle!",
    button: 'Book A Security Guard',
    image: 'https://images.posh.vip/b2/freelancericons/Security.png',
    link: 'https://posh.vip/cpn/freelancer_doorperson',
  },
  {
    prompt: 'Would cocktails be nice?',
    description: 'We know a guy...',
    button: 'Book A Bartender',
    image: 'https://images.posh.vip/b2/freelancericons/Bartender.png',
    link: 'https://posh.vip/cpn/freelancer_bartender',
  },
]

export default FreelancerOptions
