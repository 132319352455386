import React from 'react'

import {YoutubeVideo} from 'apis/Util/useGetYoutubeVideos'
import {PoshImage} from 'components/PoshImage/PoshImage'

import styles from './styles.module.scss'

interface YoutubeSearchInputProps {
  video: YoutubeVideo
  textColor: string
}

export const YoutubeVideoListItem = (props: YoutubeSearchInputProps) => {
  const {video, textColor} = props

  return (
    <div className={styles.YoutubeVideo}>
      <PoshImage src={video.thumbnail} alt={video.title} />
      <h5 className='noMargin' style={{color: textColor}}>
        {video.title}
      </h5>
    </div>
  )
}

const SelectedYoutubeVideo = (props: YoutubeSearchInputProps) => {
  const {video, textColor} = props
  return (
    <div className={styles.YoutubeVideo} style={{cursor: 'default'}}>
      <p className='noMargin' style={{color: textColor}}>
        {video.link}
      </p>
    </div>
  )
}

YoutubeVideoListItem.Selected = SelectedYoutubeVideo
