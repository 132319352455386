import React from 'react'

import moment from 'moment'

import {Text} from '../Shared/Text/Text'
import {OverviewTimePeriodSelector, TOverviewTimePeriod} from './TimePeriodSelector'

import styles from './Header.module.scss'

const now = moment()

export type DateRange = {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
}

type OverviewHeaderProps = {
  selectedTimePeriod: TOverviewTimePeriod
  onChangeTimePeriod: (timePeriod: TOverviewTimePeriod) => void
  dateRange: DateRange
  onSetCustomDateRange: (arg: DateRange) => void
}

export const OverviewHeader = ({
  selectedTimePeriod,
  onChangeTimePeriod,
  dateRange,
  onSetCustomDateRange,
}: OverviewHeaderProps) => {
  const overviewDescriptor = (selectedTimePeriod: TOverviewTimePeriod) => {
    switch (selectedTimePeriod) {
      case 'day':
        return 'Today'
      case 'week':
        return 'This Week'
      case 'month':
        return now.format('MMMM')
      case 'ytd':
        return 'YTD'
      case 'event-lifetime':
        return 'All Time'
      case 'custom':
        return 'Custom'
    }
  }

  return (
    <div className={styles.OverviewTimeSeriesSectionHeader}>
      <Text size='large'>
        <Text size='large' bold>
          Overview{' '}
        </Text>
        {overviewDescriptor(selectedTimePeriod)}
      </Text>
      <OverviewTimePeriodSelector
        selectedTimePeriod={selectedTimePeriod}
        onClick={onChangeTimePeriod}
        customDates={dateRange}
        setCustomDates={onSetCustomDateRange}
      />
    </div>
  )
}
