import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchKickBackUsageOptions = ReactQueryOptions['kickbacks']['getKickbackUsage']
type FetchKickBackUsageInput = RouterInputs['kickbacks']['getKickbackUsage']
export type FetchKickBackUsageResponse = RouterOutput['kickbacks']['getKickbackUsage']
export type KickbackUsageResponse = FetchKickBackUsageResponse[0]

export default function useFetchKickBackUsage(input: FetchKickBackUsageInput, options?: FetchKickBackUsageOptions) {
  return trpc.kickbacks.getKickbackUsage.useQuery(input, options)
}
