import React, {useState} from 'react'
import Loader from 'react-loader-spinner'
import {useLocation, useNavigate} from 'react-router-dom'

import {CountryCode, countryList, getCurrencyFromCountry} from '@posh/types'
import {facebookPixelTriggerTrack, POSH_PIXEL_ID} from 'apis/FbPixelHelpers'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import {PoshLogo} from 'components/PoshLogo'
import {useToast} from 'components/toasts/ToastProvider'

import {useFetchAccount} from '../../../apis/Accounts/useFetchAccount'
import {useCreateGroup} from '../../../apis/Groups/useCreateGroup'
import {uploadImage} from '../../../apis/Util/useUploadImage'
import SingleImagePicker, {PictureState} from '../../../components/form/ImagePicker'
import useSessionContext from '../../../domains/Auth/SessionContext'

import './../onboarding.scss'

const CreateGroupPage = () => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const navigate = useNavigate()
  const query = new URLSearchParams(useLocation().search)
  const {showToast} = useToast()
  const {userId, accountRoles} = useSessionContext()
  const {mutateAsync: createGroup} = useCreateGroup()
  const {refetch: updateCurrentUser} = useFetchAccount(userId!)
  const [groupName, setGroupName] = useState('')
  const [headerGraphic, setHeaderGraphic] = useState<PictureState>({file: null, url: null})
  const [country, setCountry] = useState<CountryCode>('US')
  const [isLoading, setIsLoading] = useState(false)
  const trackingLink = query.get('t') ?? undefined
  const didReceiveHeaderGraphic = (picture: PictureState) => {
    trackMixpanelEvent('Upload Brand Logo Success-  Create Organization Page')
    setHeaderGraphic(picture)
  }
  const hasMultipleGroups = accountRoles?.find(role => role.roleKey === 'owner')

  const trackGroupCreationPixel = () => {
    const data = {
      content_name: `Created a Group on POSH, Group Name: ${groupName}`,
      currency: getCurrencyFromCountry(country as CountryCode),
    }
    facebookPixelTriggerTrack('CompleteCreateOrganization', data, POSH_PIXEL_ID)
  }

  const onCreateGroup = async () => {
    trackMixpanelEvent('Continue Button-  Create Organization Page')
    setIsLoading(true)
    let logoUrl: string | undefined | null
    try {
      if (!groupName) throw new Error('Please Enter a Group Name')
      else if (groupName.length < 5) throw new Error('Please enter a group name larger then 5 characters')
      else if (!headerGraphic.file) throw new Error('Please Enter a Group Logo')
      if (headerGraphic.file) logoUrl = await uploadImage(headerGraphic.file)
      else logoUrl = headerGraphic.url
      await createGroup({
        groupName,
        logoBlob: logoUrl ?? '',
        currency: getCurrencyFromCountry(country as CountryCode),
        country: country as CountryCode,
        referredBy: trackingLink,
      })
      trackGroupCreationPixel()
      await updateCurrentUser()
      setIsLoading(false)
      navigate('/dashboard/orgs')
    } catch (e: any) {
      showToast({type: 'error', title: e.message})
      trackMixpanelEvent('Create Org Error-  Create Organization Page', {error: e.message})
      setIsLoading(false)
    }
  }

  return (
    <div className='OnboardPage'>
      <div className='OnboardPage-side'>
        <div className='OnboardPage-side-art'>
          <PoshLogo.Link />
        </div>
      </div>
      <div className='OnboardPage-content'>
        {hasMultipleGroups && (
          <div className='OnboardPage-content-login'>
            Already have an organization? <a href='/dashboard/orgs'>My Dashboard</a>
          </div>
        )}
        <h2>Create Organization</h2>
        <p className='OnboardPage-q'>Where will you be selling tickets?</p>
        <Select
          onChange={e => setCountry(e.target.value as CountryCode)}
          value={country || 'default'}
          defaultValue={'default'}>
          <option selected disabled value='default'>
            Select country...
          </option>
          {countryList.map(country => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </Select>
        <p className='OnboardPage-q'>What is your organization called?</p>
        <Input
          type='text'
          className='fullWidth'
          placeholder='Brand Name'
          onChange={e => setGroupName(e.target.value)}
          required
        />
        <p className='OnboardPage-q'>Add your brand&apos;s logo. (Square)</p>
        <SingleImagePicker
          title={''}
          value={headerGraphic}
          didReceiveFile={didReceiveHeaderGraphic}
          block={true}
          showTitle={false}
          mobileAutoHeight={true}
        />
        <Button className='Button' onClick={onCreateGroup} disabled={isLoading}>
          {isLoading ? <Loader type='TailSpin' color='#0a0b0d' height={30} width={30} /> : 'Continue'}
        </Button>
      </div>
    </div>
  )
}

export default CreateGroupPage
