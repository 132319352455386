import React from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import {EventStatus} from 'pages/GroupPage/EventLaunch'

import './styles.scss'

interface EventStatusProps {
  status: EventStatus
  setStatus: (value: React.SetStateAction<EventStatus>) => void
}

export const EventStatusSlider = (props: EventStatusProps) => {
  const {status, setStatus} = props

  return (
    <div className='EventStatusSlider'>
      <div className={`EventStatusSlider-Live ${status === 'live' ? 'Active' : ''}`} onClick={() => setStatus('live')}>
        {status === 'live' ? 'EVENT IS LIVE' : 'GO LIVE'}
      </div>
      <div
        className={`EventStatusSlider-Draft ${status === 'draft' ? 'Active' : ''}`}
        onClick={() => setStatus('draft')}>
        {status === 'draft' ? 'SAVED AS DRAFT' : 'SAVE DRAFT'}
      </div>
    </div>
  )
}

EventStatusSlider.displayName = 'EventStatusSlider'

export const EventStatusButton = (props: EventStatusProps) => {
  const {status, setStatus} = props
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  return (
    <div className='EventStatusButton'>
      {status === 'live' && (
        <div
          className={`EventStatusSlider${status === 'live' ? '-Draft' : '-Live'}`}
          onClick={() => {
            trackMixpanelEvent('Revert to Draft- Curator Event Overview Page')
            setStatus('draft')
          }}>
          {status === 'live' ? 'REVERT TO DRAFT' : 'GO LIVE'}
        </div>
      )}
      {status === 'draft' && (
        <div
          className={`EventStatusSlider${status === 'draft' ? '-Live' : '-Draft'}`}
          onClick={() => {
            setStatus('live')
          }}>
          {status === 'draft' ? 'GO LIVE' : 'REVERT TO DRAFT'}
        </div>
      )}
    </div>
  )
}
