import React, {ComponentProps, forwardRef} from 'react'

import {buildCloudflareImageUrl} from '@posh/model-types'

type PoshImageProps = ComponentProps<'img'>

export const PoshImage = forwardRef<HTMLImageElement, PoshImageProps>((props: PoshImageProps, ref) => {
  const imageProps = processPoshImageProps(props)
  return <img ref={ref} {...imageProps} />
})

PoshImage.displayName = 'PoshImage'

const processPoshImageProps = (props: PoshImageProps) => {
  if (props.src && (props.src.includes('https://') || props.src.includes('http://'))) {
    return {
      ...props,
      src: getWebCloudflareImageUrl(props.src),
    }
  }
  return props
}

type PoshImageBackgroundProps = ComponentProps<'div'>

export const PoshImageBackground = forwardRef<HTMLDivElement, PoshImageBackgroundProps>(
  (props: PoshImageBackgroundProps, ref) => {
    const imageProps = processPoshImageBackgroundProps(props)
    return <div ref={ref} {...imageProps} />
  },
)

PoshImageBackground.displayName = 'PoshImageBackground'

const extractUrlFromBackgroundImage = (backgroundImage: string) => {
  const match = backgroundImage.match(/url\(['"]?(.*?)['"]?\)/)
  return match ? match[1] : null
}

const processPoshImageBackgroundProps = (props: PoshImageBackgroundProps) => {
  if (
    props.style &&
    props.style.backgroundImage &&
    typeof props.style.backgroundImage === 'string' &&
    (props.style.backgroundImage.includes('https://') || props.style.backgroundImage.includes('http://'))
  ) {
    const extractedUrl = extractUrlFromBackgroundImage(props.style.backgroundImage)

    if (!extractedUrl) {
      return props
    }

    const newBackgroundImage = `url(${getWebCloudflareImageUrl(extractedUrl)})`

    return {
      ...props,
      style: {
        ...props.style,
        backgroundImage: newBackgroundImage,
      },
    }
  }
  return props
}

export const getWebCloudflareImageUrl = (src: string) => {
  return buildCloudflareImageUrl(src, {
    quality: 85,
    fit: 'scale-down',
    // We want to use the original image format if it's an SVG, otherwise we'll use WEBP
    format: src.includes('.svg') ? 'svg' : 'webp',
    // For web, we will use the recommended width of 1920 by Cloudflare (since we aren't using responsive images)
    width: 1920,
  })
}
