import * as palettes from './palettes';
import * as af from './affordances';
export const colors = Object.keys(palettes).reduce((acc, key) => {
    // @ts-ignore
    acc[key] = palettes[key].tokens;
    return acc;
}, {});
export const affordances = Object.keys(af).reduce((acc, key) => {
    // @ts-ignore
    acc[key] = af[key].tokens;
    return acc;
}, {});
