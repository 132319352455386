import React from 'react'
import {Fade} from 'react-slideshow-image'

import 'react-slideshow-image/dist/styles.css'

import {EventVenue} from 'apis/Events/types'
import {Caret, LocationPin} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'

import EventSection from '../EventSection'

import './styles.scss'

interface VenueImagesProps {
  venue: EventVenue
  accentColor: string
  lightMode: boolean
}

const ICON_SIZE = 20

export const VenueImages = (props: VenueImagesProps) => {
  const {venue, lightMode, accentColor} = props
  const {images} = venue
  const sectionHeader = venue.name ?? 'Venue Images'
  const hasMoreThanOneImage = images && images.length > 1

  return (
    <div className='VenueImages'>
      <EventSection accentColor={accentColor} header={sectionHeader} className='noMinHeight'>
        <p style={{color: lightMode ? '#0a0b0d' : '#fff'}}>{venue.address}</p>
        <a
          href={`https://google.com/maps/search/${venue.address}`}
          target='_blank'
          className={`VenueImages-mapBtn ${lightMode ? 'lightMode' : ''}`}
          style={{background: accentColor}}
          rel='noreferrer'>
          <LocationPin style={{fill: lightMode ? '#fff' : '#0a0b0d'}} />
          Open In Maps
        </a>

        {images && images.length > 0 && (
          <div className='VenueImages-venueImagesContainer'>
            <Fade
              autoplay={hasMoreThanOneImage}
              arrows={hasMoreThanOneImage}
              nextArrow={<Caret direction='right' color={accentColor} width={ICON_SIZE} height={ICON_SIZE} />}
              prevArrow={<Caret direction='left' color={accentColor} width={ICON_SIZE} height={ICON_SIZE} />}>
              {images.map((image, index) => (
                <PoshImage className='VenueImages-slide' key={index} alt={`Venue Image Slide ${index}`} src={image} />
              ))}
            </Fade>
          </div>
        )}
      </EventSection>
    </div>
  )
}
