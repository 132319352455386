import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchEventParentCommentsOptions = ReactQueryOptions['comments']['fetchEventParentComments']
type FetchEventParentCommentsInput = RouterInputs['comments']['fetchEventParentComments']
export type FetchEventParentCommentsOutput = RouterOutput['comments']['fetchEventParentComments']
export type EventParentComment = FetchEventParentCommentsOutput['comments'][0]

export function useFetchEventParentComments(
  input: FetchEventParentCommentsInput,
  opts?: FetchEventParentCommentsOptions,
) {
  return trpc.comments.fetchEventParentComments.useQuery(input, opts)
}
