import React from 'react'

const LinkSvg = ({accentColor, dataTip, dataFor}: {accentColor: string; dataTip?: string; dataFor?: string}) => {
  return (
    <svg
      data-for={dataFor}
      data-tip={dataTip}
      width='15'
      height='15'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.15022 11.7515L6.54914 14.3526L6.54908 14.3527L6.54902 14.3528C5.4734 15.4284 3.72312 15.4285 2.64737 14.3528C2.12623 13.8316 1.8393 13.1388 1.8393 12.4019C1.8393 11.665 2.12623 10.9723 2.64719 10.4512L2.64737 10.451L5.24845 7.84983C5.60754 7.49068 5.60754 6.90836 5.24839 6.54926C4.8893 6.19017 4.30697 6.19017 3.94782 6.54926L1.34674 9.15041L1.34619 9.15102C0.478096 10.0194 0 11.1739 0 12.4019C0 13.6301 0.47828 14.7848 1.3468 15.6533C2.24327 16.5497 3.42074 16.998 4.59826 16.998C5.77578 16.998 6.9533 16.5497 7.84965 15.6533L7.84972 15.6532L10.4508 13.0521C10.8099 12.693 10.8099 12.1106 10.4507 11.7515C10.0917 11.3924 9.50944 11.3924 9.15022 11.7515ZM17 4.59838C17 3.37015 16.5217 2.21544 15.6531 1.34692C13.8602 -0.445911 10.9431 -0.44585 9.15029 1.34692L9.15004 1.34723L6.54902 3.94818C6.18987 4.30728 6.18987 4.88966 6.54902 5.24875C6.63432 5.33429 6.73569 5.40213 6.84729 5.44836C6.9589 5.49459 7.07854 5.51831 7.19934 5.51815C7.43465 5.51815 7.67008 5.42833 7.84959 5.24875L10.4506 2.6478L10.4509 2.64749C11.5265 1.57187 13.2768 1.5718 14.3526 2.64749C14.8736 3.16863 15.1607 3.86149 15.1607 4.59838C15.1607 5.3352 14.8738 6.02794 14.3528 6.54908L14.3526 6.54926L11.7515 9.15041C11.3924 9.5095 11.3924 10.0918 11.7515 10.451C11.8368 10.5365 11.9382 10.6043 12.0498 10.6506C12.1614 10.6968 12.281 10.7205 12.4018 10.7204C12.6372 10.7204 12.8725 10.6306 13.0521 10.451L15.6532 7.84983L15.6538 7.84922C16.5218 6.98083 17 5.8263 17 4.59838Z'
        fill={accentColor}
      />
      <path
        d='M5.24858 11.7514C5.33388 11.8369 5.43524 11.9047 5.54683 11.9509C5.65842 11.9972 5.77805 12.0209 5.89884 12.0208C6.13421 12.0208 6.36958 11.9309 6.54915 11.7514L11.7514 6.54908C12.1106 6.18998 12.1106 5.60766 11.7514 5.24851C11.3923 4.88941 10.81 4.88941 10.4509 5.24851L5.24858 10.4507C4.88943 10.8099 4.88943 11.3923 5.24858 11.7514Z'
        fill={accentColor}
      />
    </svg>
  )
}

export {LinkSvg}
