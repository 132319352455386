import React from 'react'

import Button from 'components/form/Button'

import './styles.scss'

interface Props {
  onDiscard: () => void
  onSave: () => void
  isOpen: boolean
  disabled?: boolean
}

export default function SaveDiscardBottomBar(props: Props) {
  const {disabled} = props
  if (!props.isOpen) return null

  return (
    <div className='SaveDiscardBottomBar'>
      <p>Would you like to save your changes?</p>
      <div className='SaveDiscardBottomBar-buttonRow'>
        <Button onClick={props.onDiscard} className='dark light'>
          <span>DISCARD</span>
        </Button>
        <Button disabled={disabled} className='gold light' onClick={props.onSave}>
          SAVE CHANGES
        </Button>
      </div>
    </div>
  )
}
