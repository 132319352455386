import React, {useState} from 'react'
import ReactJson from 'react-json-view'
import Loader from 'react-loader-spinner'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import {useFetchConnectAccountStatus} from '../../../apis/AdminDashboard/connectAccounts/useFetchAccountStatus'

import './styles.scss'

const ConnectAccounts = () => {
  const [connectId, setConnectId] = useState<undefined | string>()
  const {data: connectData, isFetching, refetch, error} = useFetchConnectAccountStatus(connectId)
  return (
    <>
      <BackAndTitle title={'Connect Account Status Search + File Upload'} />
      <div className='ConnectAccount-mainContainer'>
        <div className='ConnectAccount-leftContainer'>
          <div className='ConnectAccount-leftActionContainer'>
            <Input
              className='ConnectAccount-leftConnectInput'
              value={connectId}
              onChange={e => setConnectId(e.target.value)}
              placeholder={`Connect Account Id`}
            />
            <Button
              onClick={() => {
                if (connectId) {
                  refetch()
                }
              }}>
              Search Connect Account
            </Button>
          </div>
          {isFetching && (
            <div className='Loader'>
              <Loader type='Rings' color='white' height={100} width={100} />
            </div>
          )}
          {error && <div className='ConnectAccount-errorText'>{error.message}</div>}
          {connectData && (
            <ReactJson
              src={connectData}
              displayDataTypes={false}
              displayObjectSize={false}
              theme={'shapeshifter:inverted'}
            />
          )}
        </div>
        <div className='ConnectAccount-rightContainer'></div>
      </div>
    </>
  )
}
export default ConnectAccounts
