import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface GenerateAffiliationProps {
  userId: string
  eventId: string
}

const generateAffiliation = async (params: GenerateAffiliationProps): Promise<string> => {
  const response = await PoshApi.generateAffiliation(params)
  return response.data
}

export function useGenerateAffiliation() {
  return useMutation<string, unknown, GenerateAffiliationProps>(params => generateAffiliation(params))
}
