import React, {createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react'

import {AccountRoleResponse, IPermissionConstraints, TeamMemberResponse} from 'apis/Roles'
import {RoleKey, ScopeKey} from 'apis/Roles/Role'

import {EditTeamManagementModalPanel} from '.'
import {AddTeamManagementPanel} from './AddTeamMemberModal'

function useNetworkResponseState() {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const reset = () => {
    setIsLoading(false)
    setErrorMessage('')
    setSuccessMessage('')
  }

  return {
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
    reset,
  }
}

function useAddTeamMemberState() {
  const [updatedPermissionConstaints, setUpdatedPermissionConstraints] = useState<IPermissionConstraints | null>(null)
  const [selectedRole, setSelectedRole] = useState<RoleKey | null>(null)

  const reset = () => {
    setUpdatedPermissionConstraints(null)
    setSelectedRole(null)
  }

  return {
    updatedPermissionConstaints,
    setUpdatedPermissionConstraints,
    selectedRole,
    setSelectedRole,
    reset,
  }
}

function useEditPermissionsState() {
  const [accountRoleToEdit, setAccountRoleToEdit] = useState<AccountRoleResponse | null>(null)
  const [updatedPermissionConstaints, setUpdatedPermissionConstraints] = useState<IPermissionConstraints>({})

  const reset = () => {
    setAccountRoleToEdit(null)
    setUpdatedPermissionConstraints({})
  }

  return {
    accountRoleToEdit,
    setAccountRoleToEdit,
    updatedPermissionConstaints,
    setUpdatedPermissionConstraints,
    reset,
  }
}

function useDeleteTeamMemberState() {
  const [teamMemberToDelete, setTeamMemberToDelete] = useState<TeamMemberResponse | null>(null)
  const reset = () => setTeamMemberToDelete(null)
  return {
    reset,
    teamMemberToDelete,
    setTeamMemberToDelete,
  }
}

type TeamManagementModalPanel = EditTeamManagementModalPanel | AddTeamManagementPanel
export interface TeamManagementModalOptions {
  initialPanel?: TeamManagementModalPanel
  fixedPanel?: TeamManagementModalPanel
  teamMemberToEdit?: TeamMemberResponse
}

function useProviderValue(entityId: string, scope: ScopeKey, options: TeamManagementModalOptions = {}) {
  const networkResponseState = useNetworkResponseState()
  const addTeamMemberState = useAddTeamMemberState()
  const editPermissionsState = useEditPermissionsState()
  const deletePermissionsState = useDeleteTeamMemberState()

  const [entityTeamMembers, setEntityTeamMembers] = useState<TeamMemberResponse[]>([])
  const [activePanel, setActivePanel] = useState<TeamManagementModalPanel>(options.initialPanel ?? 'choose_role')
  const [closeModal, setCloseModal] = useState<(() => void) | null>(null)

  const reset = useCallback(() => {
    setActivePanel(options.initialPanel ?? 'choose_role')
    deletePermissionsState.reset()
    networkResponseState.reset()
    addTeamMemberState.reset()
    editPermissionsState.reset()
  }, [addTeamMemberState, deletePermissionsState, editPermissionsState, networkResponseState, options.initialPanel])

  useEffect(() => {
    if (!options.fixedPanel) {
      setActivePanel(options.initialPanel ?? 'choose_role')
    }
  }, [options])

  useEffect(() => {
    const {teamMemberToEdit, fixedPanel} = options
    if (teamMemberToEdit && fixedPanel) {
      switch (fixedPanel) {
        case 'edit_permissions':
          editPermissionsState.setAccountRoleToEdit(teamMemberToEdit.accountRole)
          setActivePanel(fixedPanel)
          break
        case 'delete_team_member':
          deletePermissionsState.setTeamMemberToDelete(teamMemberToEdit)
          setActivePanel(fixedPanel)
          break
        default:
          return
      }
    }
  }, [deletePermissionsState, editPermissionsState, options])

  return useMemo(
    () => ({
      ...addTeamMemberState,
      ...networkResponseState,
      ...editPermissionsState,
      ...deletePermissionsState,
      closeModal,
      setCloseModal,
      activePanel,
      setActivePanel,
      entityTeamMembers,
      setEntityTeamMembers,
      entityId,
      scope,
      reset,
    }),
    [
      activePanel,
      addTeamMemberState,
      closeModal,
      deletePermissionsState,
      editPermissionsState,
      entityId,
      entityTeamMembers,
      networkResponseState,
      reset,
      scope,
    ],
  )
}

export type TeamManagementModalContext = ReturnType<typeof useProviderValue>

const context = createContext<TeamManagementModalContext | null>(null)
context.displayName = 'TeamManagementModalContext'

export default function useTeamManagementModalContext() {
  const addTeamModalContext = React.useContext(context)
  if (!addTeamModalContext) throw 'TeamManagementModalContext must be provided'
  return addTeamModalContext
}

interface Props {
  entityId: string
  scope: ScopeKey
  options?: TeamManagementModalOptions
}

export const TeamManagementModalContextProvider = ({children, entityId, scope, options}: PropsWithChildren<Props>) => {
  const value = useProviderValue(entityId, scope, options)
  return <context.Provider value={value}>{children}</context.Provider>
}
