// must be imported ahead of application (and therefore ES imports)
require('../providers/monitoring').mount({
  env: process.env.APP_ENV ?? '__APP_ENV_MISSING__',
})

import React from 'react'
import {createRoot} from 'react-dom/client'

import App from '../App'

const root = document.getElementById('root')

if (root) {
  createRoot(root).render(<App />)
}

// HMR interface
if (module.hot) {
  module.hot.accept('../App', () => {
    const NextApp = require('../App').default
    if (root) {
      createRoot(root).render(<NextApp />)
    }
  })
}
