import {useEffect, useState} from 'react'

const useHasImageDownloaded = (src?: string) => {
  const [imageHasDownloaded, setImageHasDownloaded] = useState(false)

  useEffect(() => {
    if (!src) return
    const image = new Image()
    image.src = src
    image.onload = () => setImageHasDownloaded(true)
  }, [src])

  return {imageHasDownloaded}
}

export default useHasImageDownloaded
