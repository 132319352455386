import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'

import styles from './styles.module.scss'

export type BlurTint = 'light' | 'dark'

type BlurVariants = 'noBorder' | 'scaleOnHover'

const DEFAULT_BLUR_RADIUS = 5
const DEFAULT_BRIGHTNESS = 2

export interface GlassViewProps extends React.HTMLAttributes<HTMLDivElement> {
  blurRadius?: number
  brightness?: number
  blurTint?: BlurTint
  className?: string
  variants?: BlurVariants[]
}

export const GlassView = (props: PropsWithChildren<GlassViewProps>) => {
  const {
    blurTint = 'dark',
    blurRadius = DEFAULT_BLUR_RADIUS,
    brightness = DEFAULT_BRIGHTNESS,
    children,
    className,
    variants,
    ...rest
  } = props
  const styleVariants = variants?.map(variant => styles[variant])

  const filter = `blur(${blurRadius}px) brightness(${brightness})`

  return (
    <div
      className={classNames(styles.GlassView, styles[blurTint], styleVariants, className)}
      style={{
        WebkitBackdropFilter: filter,
        backdropFilter: filter,
        ...rest.style,
      }}
      {...rest}>
      {children}
    </div>
  )
}
