import React, {useEffect, useState} from 'react'
import Loader from 'react-loader-spinner'
import {useNavigate} from 'react-router-dom'

import {ArtistType, CommunityCategory, GalleryImage, StaffType} from 'apis/Community'
import useCreateArtist from 'apis/Community/useCreateArtist'
import useCreateStaffMember from 'apis/Community/useCreateStaffMember'
import useCreateVenue from 'apis/Community/useCreateVenue'
import useFetchCommunityEntity from 'apis/Community/useFetchCommunityEntity'
import {LongLat} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {uploadImage} from 'apis/Util/useUploadImage'
import GalleryEditor from 'components/form/GalleryEditor'
import {PictureState} from 'components/form/ImagePicker'
import PoshLocationInput from 'components/form/PoshLocationInput'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import {isEmpty, isNull, isUndefined} from 'lodash'

import AviSelector from '../AviSelector'
import CommunityOnboardingSteps, {Input, OnboardingStep} from '../steps'

interface ErrorState {
  [key: string]: string
}

const CommunityForm = ({
  setCompletedSteps,
  completedSteps,
  setCommunityCategory,
  setShowConfirmationPage,
}: {
  setCompletedSteps: React.Dispatch<React.SetStateAction<number>>
  completedSteps: number
  setCommunityCategory: React.Dispatch<React.SetStateAction<CommunityCategory | undefined>>
  setShowConfirmationPage: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const currentCompletedSteps = completedSteps
  const startingKey = 'profileType'
  const communityOnboardingSteps: OnboardingStep[] = CommunityOnboardingSteps
  const [activeStep, setActiveStep] = useState<OnboardingStep | undefined>(
    communityOnboardingSteps.find(step => step.key == startingKey),
  )
  const {currentUser} = useSessionContext()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {mutateAsync: createArtist} = useCreateArtist()
  const {mutateAsync: createVenue} = useCreateVenue()
  const {mutateAsync: createStaffMember} = useCreateStaffMember()
  const {data: communityEntity, isFetching: isFetchingCommunityEntity} = useFetchCommunityEntity()
  const [coordinates, setCoordinates] = useState<LongLat>({type: 'Point', coordinates: [0, 0]})
  const [inputValues, setInputValues] = useState<{[key: string]: string}>({})
  const [images, setImages] = useState<PictureState[]>([])
  const [error, setError] = useState<ErrorState>({})
  const navigate = useNavigate()
  const hasAddedImage = images && images[0] && images[0].url

  const isValidField = (value: string | undefined, input: Input | undefined) => {
    setError({})
    const errorObject: ErrorState = {}
    const key = input?.key as string
    if (input && input.inputType !== 'gallery' && input.isRequired && !value && input.errorMessage) {
      errorObject[key] = input.errorMessage
      setError(errorObject)
    }
    if (
      input &&
      input.inputType === 'gallery' &&
      input.isRequired &&
      input.errorMessage &&
      input.minImages &&
      images.length < input.minImages
    ) {
      errorObject[key] = input.errorMessage
      setError(errorObject)
    }
    if (input?.inputType === 'location' && value && input.locationErrorMessage && /\d/.test(value)) {
      errorObject[key] = input.locationErrorMessage
      setError(errorObject)
    }

    if (Object.keys(errorObject).length > 0) return false
    return true
  }

  const saveAndAdvanceStep = ({value}: {value?: string}, input: Input | undefined) => {
    if (!isValidField(value, input)) return

    if (inputValues.communityCategory) {
      const communityCategoryInput = inputValues.communityCategory as CommunityCategory
      setCommunityCategory(communityCategoryInput)
    }
    const nextStepKey =
      activeStep?.nextStepKey ??
      activeStep?.inputs[activeStep?.inputs.length - 1].options?.find(o => o.value == value)?.nextStepKey
    const nextStep = communityOnboardingSteps.find(step => step.key == nextStepKey)
    setCompletedSteps(currentCompletedSteps + 1)
    setActiveStep(nextStep ?? undefined)
  }

  const previousStep = () => {
    setCompletedSteps(currentCompletedSteps - 1)
    setActiveStep(communityOnboardingSteps.find(step => activeStep?.prevStepKey == step.key))
  }

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    {value}: {value?: string},
    input: Input | undefined,
    isNumberInput?: boolean,
  ): void => {
    if (isNumberInput && event.key !== 'Enter') ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault()
    else if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      saveAndAdvanceStep({value}, input)
    }
  }

  const getSavableGallery = async () => {
    if (isEmpty(images)) return []
    const galleryImagePromises = images.map(async image => {
      if (image.file) return await uploadImage(image.file)
      else return image.url
    })

    const galleryFiles = await Promise.all(galleryImagePromises)
    const savableGalleryImages = galleryFiles.map(image => {
      if (!isNull(image) && !isUndefined(image)) {
        return {url: image}
      }
    }) as GalleryImage[]
    return savableGalleryImages
  }

  const createCommunityEntityHelper = async () => {
    try {
      let entity
      const communityCategory = inputValues.communityCategory as CommunityCategory
      const galleryImages = await getSavableGallery()

      switch (communityCategory) {
        case 'artist':
          entity = await createArtist({
            artistName: inputValues.artistName,
            artistType: inputValues.artistType as ArtistType,
            location: inputValues.location,
            coordinates: coordinates,
            instagram: inputValues.instagram ?? undefined,
            spotifyUrl: inputValues.spotifyUrl,
            website: inputValues.website,
            baseBookingFee: inputValues.baseBookingFee ? Number(inputValues.baseBookingFee) : 0,
            avi: inputValues.avi,
            galleryImages,
          })
          break

        case 'venue':
          entity = await createVenue({
            name: inputValues.name,
            address: inputValues.address,
            coordinates: coordinates,
            maxCapacity: Number(inputValues.maxCapacity),
            instagram: inputValues.instagram ?? undefined,
            website: inputValues.website,
            baseBuyout: inputValues.baseBuyout ? Number(inputValues.baseBuyout) : 0,
            ageLimit: inputValues.ageLimit ? Number(inputValues.ageLimit) : 21,
            galleryImages,
          })
          break

        case 'staff':
          entity = await createStaffMember({
            staffType: inputValues.staffType as StaffType,
            location: inputValues.location,
            coordinates: coordinates,
            website: inputValues.website,
            hourlyRate: inputValues.hourlyRate ? Number(inputValues.hourlyRate) : 0,
            avi: inputValues.avi,
            galleryImages,
            instagram: inputValues.instagram ?? undefined,
            minimumPrice: inputValues.minimumPrice ? Number(inputValues.minimumPrice) : 0,
          })
          break
        default:
          break
      }
      if (entity.message) {
        setShowConfirmationPage(true)
        switch (communityCategory) {
          case 'artist':
            trackMixpanelEvent('Community Account Created', {
              communityCategory,
              communityName: inputValues.artistName,
            })
            break
          case 'venue':
            trackMixpanelEvent('Community Account Created', {
              communityCategory,
              communityName: inputValues.name,
            })
            break
          case 'staff':
            const name = currentUser?.firstName + ' ' + currentUser?.lastName
            trackMixpanelEvent('Community Account Created', {communityCategory, communityName: name})
            break
          default:
            break
        }
      }
    } catch (error) {
      setError({creation: error.response.data.error})
      setTimeout(() => {
        navigate('/dashboard/community')
        setError({})
      }, 5000)
    }
  }

  useEffect(() => {
    if (!activeStep) {
      createCommunityEntityHelper()
    }
  }, [activeStep])

  useEffect(() => {
    if (communityEntity?.type && !isFetchingCommunityEntity) {
      navigate('/dashboard/community')
    }
  }, [communityEntity])

  return (
    <>
      {completedSteps != 0 && (
        <div className='CommunityPreviousStepBtn' onClick={() => previousStep()}>
          <PoshImage src='https://images.posh.vip/b2/next.svg' />
        </div>
      )}
      <div className='OnboardingFormCont-input'>
        <div className='OnboardingFormCont-input-q'>{activeStep?.question}</div>
        {error.creation && (
          <div className='Reroute'>
            <div className='Reroute-message'>{error.creation}</div>
            <div className='Reroute-message'>Rerouting to Profile Dashboard.....</div>
            <Loader type='Rings' color='#ffcb00' height={40} width={40} />
          </div>
        )}
        {activeStep?.inputs.map(input => (
          <>
            {input?.inputType === 'select' && (
              <div
                className={`CommunityInput CommunityInput--Select ${
                  input.options && input.options.length > 3 ? 'CommunityInput--Select--Medium' : ''
                }`}>
                {input.options?.map(option => (
                  <span
                    key={option.value}
                    onClick={() => {
                      saveAndAdvanceStep({value: option.value}, input)
                      const key = option.key as string
                      setInputValues({...inputValues, [key]: option.value})
                    }}>
                    {option.label}
                  </span>
                ))}
              </div>
            )}
            {input?.inputType === 'location' && input.locationType && (
              <div className='CommunityInput CommunityInput--Text'>
                <PoshLocationInput
                  placeholder={input?.placeholder}
                  className='CreateEvent-form-content-innerForm-locationInput'
                  address={inputValues[input.key as string] ?? ''}
                  onPlaceSelected={place => {
                    const {address, location} = place
                    const key = input?.key as string
                    setInputValues({...inputValues, [key]: address})
                    setCoordinates(location)
                  }}
                  options={input.locationType}
                />
                {error[input.key as string] && <span className='errorMessage'>{error[input.key as string]}</span>}
                {activeStep.inputs.length == 1 && (
                  <div className='CommunityNextStepBtn'>
                    <PoshImage
                      src='https://images.posh.vip/b2/next.svg'
                      onClick={() => saveAndAdvanceStep({value: inputValues[input.key as string]}, input)}
                    />
                  </div>
                )}
              </div>
            )}
            {input?.inputType === 'text' && (
              <div className='CommunityInput CommunityInput--Text'>
                <input
                  key={input.key}
                  type='text'
                  autoFocus
                  value={inputValues[input.key as string] ?? ''}
                  placeholder={input?.placeholder}
                  onChange={event => {
                    const key = input?.key as string
                    setInputValues({...inputValues, [key]: event.target.value})
                  }}
                  onKeyDown={e => onKeyDown(e, {value: inputValues[input.key as string]}, input)}></input>
                {error[input.key as string] && <span className='errorMessage'>{error[input.key as string]}</span>}
                {activeStep.inputs.length == 1 && (
                  <div className='CommunityNextStepBtn'>
                    <PoshImage
                      src='https://images.posh.vip/b2/next.svg'
                      onClick={() => saveAndAdvanceStep({value: inputValues[input.key as string]}, input)}
                    />
                  </div>
                )}
              </div>
            )}
            {input?.inputType === 'avi' && (
              <AviSelector
                activeStep={activeStep.inputs[0]}
                saveAndAdvanceStep={saveAndAdvanceStep}
                inputValues={inputValues}
                setInputValues={setInputValues}
                communityCategory={inputValues.communityCategory as CommunityCategory}
              />
            )}
            {error[input.key as string] && input?.inputType === 'avi' && (
              <span className='errorMessage'>{error[input.key as string]}</span>
            )}
            {input?.inputType === 'gallery' && (
              <div className='CommunityInput CommunityInput--Avi'>
                <GalleryEditor images={images} setImages={setImages} />
                {activeStep.inputs.length == 1 && (
                  <div className='CommunityNextStepBtn gallery'>
                    <PoshImage
                      src='https://images.posh.vip/b2/next.svg'
                      onClick={() => {
                        if (hasAddedImage) {
                          const value = images[0].url!
                          saveAndAdvanceStep({value}, input)
                        } else {
                          saveAndAdvanceStep({value: ''}, input)
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {error[input.key as string] && input?.inputType === 'gallery' && (
              <span className='errorMessage'>{error[input.key as string]}</span>
            )}
            {(input?.inputType === 'number' || input?.inputType === 'price') && (
              <div className='CommunityInput CommunityInput--Text'>
                <div className='CommunityInput CommunityInput--PriceWrapper'>
                  {input?.inputType === 'price' && <span>$</span>}
                  <input
                    type='number'
                    autoFocus
                    onKeyDown={e => onKeyDown(e, {value: inputValues[input.key as string]}, input, true)}
                    value={inputValues[input.key as string] ?? ''}
                    placeholder={input?.placeholder}
                    onChange={event => {
                      const key = input?.key as string
                      setInputValues({...inputValues, [key]: event.target.value})
                    }}
                  />
                  {input?.perHour && inputValues[input.key as string] && <span>/hr</span>}
                </div>
                {error[input.key as string] && <span className='errorMessage'>{error[input.key as string]}</span>}
                {activeStep.inputs.length == 1 && (
                  <div className='CommunityNextStepBtn'>
                    <PoshImage
                      src='https://images.posh.vip/b2/next.svg'
                      onClick={() => saveAndAdvanceStep({value: inputValues[input.key as string]}, input)}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        ))}
        {activeStep?.inputs && activeStep?.inputs.length > 1 && (
          <div className='CommunityInput CommunityInput--ButtonWrapper'>
            <div className='CommunityNextStepBtn big'>
              <PoshImage
                src='https://images.posh.vip/b2/next.svg'
                onClick={() => saveAndAdvanceStep({value: inputValues[activeStep.inputs[0].key as string]}, undefined)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CommunityForm
