import React from 'react'

import {Card, CardProps} from 'components/Card'

import styles from './FinanceCard.module.scss'

export const FinanceCard = ({children, ...props}: {children: React.ReactNode} & Omit<CardProps, 'className'>) => {
  return (
    <Card {...props} className={styles.FinanceCard}>
      {children}
    </Card>
  )
}
