import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import classNames from 'classnames'
import {NumberInput, NumberInputProps} from 'components/DynamicForm/Inputs/NumberInput/NumberInput'
import {ControlledFormProps} from 'components/DynamicForm/Inputs/shared/types'
import {getEventTitleFont} from 'components/Text/EventTitleFontStyleProvider'

import {EventVisualsTextInputWrapper, EventVisualsTextInputWrapperProps} from './EventVisualsTextInputWrapper'

import styles from './styles.module.scss'

type EventVisualsNumberInputProps = Omit<NumberInputProps, 'className' | 'name'> & EventVisualsTextInputWrapperProps
const DEFAULT_FONT_SIZE = 16

export function EventVisualsBaseNumberInput(props: EventVisualsNumberInputProps) {
  const {lightMode, accentColor, fontFamily, decimalPlaces, ...rest} = props
  const lightModeClassName = lightMode ? styles.light : styles.dark
  const inputClassName = classNames(styles.input, lightModeClassName)
  const fontSize = props.size ?? DEFAULT_FONT_SIZE

  return (
    <NumberInput
      {...rest}
      value={props.value}
      className={inputClassName}
      style={{fontSize, color: accentColor, fontFamily: getEventTitleFont(fontFamily)}}
      placeholder={props.placeholder + (props.required ? '*' : '')}
      containerClassName={styles.numberInputOverride}
      decimalPlaces={decimalPlaces ?? 0}
    />
  )
}

export function PlainNumberInput({
  icon,
  style,
  ...props
}: EventVisualsNumberInputProps & {
  icon?: React.ReactNode
}) {
  const {required, value} = props
  const isOutlined = required === true && !value
  return (
    <EventVisualsTextInputWrapper.Row {...props} style={style} isOutlined={isOutlined}>
      {icon}
      <EventVisualsBaseNumberInput {...props} />
    </EventVisualsTextInputWrapper.Row>
  )
}

type ControlledEventVisualsNumberInputProps<
  T extends FieldValues,
  K extends Path<T>,
> = EventVisualsTextInputWrapperProps &
  ControlledFormProps<T, K> &
  Omit<EventVisualsNumberInputProps, 'value' | 'onChange'>

function ControlledPlainNumberInput<T extends FieldValues, K extends Path<T>>({
  name,
  control,
  rules,
  ...props
}: ControlledEventVisualsNumberInputProps<T, K>) {
  const isRequired = typeof rules?.required === 'object' ? rules.required.value : !!rules?.required

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState}) => (
        <PlainNumberInput
          {...props}
          {...field}
          {...fieldState}
          required={isRequired}
          error={fieldState.error?.message} // Fix: Pass the error message instead of the FieldError object
          value={field.value as number}
          onChange={value => field.onChange(value)}
        />
      )}
    />
  )
}

PlainNumberInput.Controlled = ControlledPlainNumberInput
