import React from 'react'

import {OrderResponse} from '@posh/model-types'
import {Card} from 'components/Card'
import isMultipleEventResponses from 'components/CheckoutFieldResponses/isMultipleEventResponses.helper'
import ResponsesMapper from 'components/CheckoutFieldResponses/ResponsesMapper'

import './CheckoutFieldResponses.styles.scss'

const CheckoutFieldResponses = ({
  responses,
}: {
  responses?:
    | Array<OrderResponse & {eventId: string}>
    | {eventName: string; eventResponses?: OrderResponse[]; eventId: string}[]
}) => {
  if (!responses || responses.length === 0) return null

  if (isMultipleEventResponses(responses)) {
    return (
      <Card title={'Checkout Field Responses'} animationIndex={1} className='CheckoutFieldResponses-perEvent'>
        {responses.map((response, index) => {
          if (!response.eventResponses || response.eventResponses.length === 0) return null
          const {eventId} = response
          return (
            <div key={index}>
              <h5>{response.eventName}</h5>
              {ResponsesMapper(response.eventResponses, eventId)}
            </div>
          )
        })}
      </Card>
    )
  }

  return (
    <Card title={'Checkout Field Responses'} animationIndex={1} className='CheckoutFieldResponses'>
      {ResponsesMapper(responses, responses[0].eventId)}
    </Card>
  )
}

export default CheckoutFieldResponses
