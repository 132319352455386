import React from 'react'

import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {trpc} from 'lib/trpc'

import {AppleSvg} from '../SVGs/svgs'

import './styles.scss'

interface AddToWalletProps {
  orderId: string
  image?: string
  accentColor?: string
  mixpanelCallback?: () => void
}
export const AddToWallet = (props: AddToWalletProps) => {
  const orderId = props.orderId
  const image = props.image
  const accentColor = props.accentColor
  const {mutateAsync: getAppleWalletPass, isLoading} = trpc.accounts.getAppleWalletPass.useMutation()

  const downloadPass = async () => {
    props.mixpanelCallback && props.mixpanelCallback()
    const url = await getAppleWalletPass({orderId})
    if (!url) return
    window.location.assign(url)
  }

  return (
    <>
      {image ? (
        <>
          {isLoading ? (
            <div style={{marginTop: '10px !important'}} className='AppleWalletButton-loader'>
              <SpinLoader height={30} />
            </div>
          ) : (
            <PoshImage onClick={downloadPass} src={image} />
          )}
        </>
      ) : (
        <>
          {isLoading ? (
            <div className='AppleWalletButton-altloader'>
              <SpinLoader height={30} />
            </div>
          ) : (
            <div style={{border: `1px solid ${accentColor ?? '#fff'}`}} onClick={downloadPass}>
              <AppleSvg accentColor={accentColor ?? '#fff'} />
            </div>
          )}
        </>
      )}
    </>
  )
}
