import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import classNames from 'classnames'
import {TableIcon} from 'components/TableUI/TableIcons/TableIcon'

import {InputContainer} from '../shared/InputContainer/InputContainer'
import {ControlledFormProps} from '../shared/types'

import styles from './Select.module.scss'

export interface SelectProps<T>
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'value' | 'onChange' | 'className'> {
  value: T
  onChange: (value: T) => void
  options: Array<{label: string; value: T}>
  error?: string
  className?: string
}

const ICON_SIZE = 16

export const Select = <T,>(props: SelectProps<T>) => {
  const {value, onChange, options, error, className, ...rest} = props

  const index = options.findIndex(option => option.value === value)

  if (index === -1) {
    console.error('Select value not found in options')
  }

  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()

    const index = parseInt(event.target.value)
    const value = options[index]?.value
    onChange(value)
  }

  const isValueUndefined = value === undefined

  return (
    <InputContainer error={error}>
      <TableIcon
        className={styles.SelectIcon}
        name='triangle-down'
        width={ICON_SIZE}
        height={ICON_SIZE}
        color={'#909193'}
      />
      <select
        {...rest}
        multiple={false}
        className={classNames(styles.Select, {[styles.SelectValueUndefined]: isValueUndefined}, className)}
        value={value ? index : ''}
        onChange={e => onChangeSelect(e)}>
        {options.map((option, i) => {
          const isOptionUndefined = option.value === undefined
          return (
            <option key={i} value={isOptionUndefined ? '' : i}>
              {option.label}
            </option>
          )
        })}
      </select>
    </InputContainer>
  )
}

interface ControlledSelectProps<T extends FieldValues, K extends Path<T>>
  extends Omit<SelectProps<T[K]>, 'value' | 'onChange' | 'name'>,
    ControlledFormProps<T, K> {}

const ControlledSelect = <T extends FieldValues, K extends Path<T>>(props: ControlledSelectProps<T, K>) => {
  const {control, name, rules, ...selectProps} = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState, formState: _formState}) => {
        return <Select {...field} error={fieldState.error?.message} {...selectProps} />
      }}
    />
  )
}

Select.Controlled = ControlledSelect
