const MULTI_SELECT_BACKGROUND_COLOR = '#222222'

const multiSelectStyles = {
  inputField: {
    // Change color of text in search
    color: `#fff`,
    width: '100%',
  },
  searchBox: {
    // To change search box element look
    border: `1px solid #7a7b7d`,
    background: `none`,
    padding: '10px 16px',
    borderRadius: `999px`,
    fontSize: `16px`,
    fontFamily: `helvetica neue, Helvetica, sans-serif`,
    fontWeight: `400`,
    color: `#fff`,
  },
  chips: {
    // To change css chips(Selected options)
    background: `#fc0`,
    color: `black`,
  },
  optionContainer: {
    // To change css for option container
    border: `1px solid #333`,
    background: MULTI_SELECT_BACKGROUND_COLOR,
  },
  option: {
    // To change css for dropdown options
    background: MULTI_SELECT_BACKGROUND_COLOR,
    transition: '0.2s',
    color: `#ccc`,
  },
}

export default multiSelectStyles
