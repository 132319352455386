import React, {useEffect, useRef, useState} from 'react'

import {useFetchGroupAttendeesPaged} from 'apis/Groups/useFetchGroupAttendees'
import {filterUrl, tagWhiteUrl} from 'components/assets/Icons'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {isEmpty} from 'lodash'

import {ActionBarButton} from '../ActionBarButton'
import AttendeePanes from '../AttendeePanes'
import useAttendeesCrmContext from '../AttendeesCrmContext'
import {AttendeesSearchBar} from '../AttendeesSearchBar'
import AttendeesTable from '../AttendeesTable'
import AttendeesCrmActionsModal from '../Filter'
import {FilterAlert} from '../Filter/filterAlert'
import {TagsModal} from '../TagsModal'

const AttendeesListView = () => {
  const {groupId} = useGroupMatchParams()
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false)
  const [tagModalIsOpen, setTagModalIsOpen] = useState(false)
  const {
    page,
    setPage,
    isFilterActive,
    selectedTags,
    selectedEvents,
    filterByData,
    filterByOrder,
    matchAllEvents,
    matchAllTags,
    genderFilter,
  } = useAttendeesCrmContext()

  const [searchQuery, setSearchQuery] = useState('')
  const {
    data: groupAttendeesData,
    refetch,
    isError,
    isFetching,
  } = useFetchGroupAttendeesPaged({
    groupId: groupId ?? '',
    page,
    selectedEvents,
    matchAllEvents,
    selectedTags,
    matchAllTags,
    filterByData,
    filterByOrder,
    searchQuery,
    genderFilter,
  })
  const attendees = groupAttendeesData?.attendees

  useEffect(() => {
    setPage(1)
    refetch()
  }, [
    isFilterActive && selectedTags,
    isFilterActive && selectedEvents,
    isFilterActive && filterByData,
    isFilterActive && filterByOrder,
  ])

  const crmStart = useRef<null | HTMLDivElement>(null)
  const startNewCampaign = () => {
    crmStart.current?.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div className='Attendees'>
      <div className='Attendees-content'>
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('send_blast', ['group'])]}>
          <AttendeePanes startNewCampaign={startNewCampaign} />
        </RequirePermissions>
        <hr />
        <div className='Attendees-headerWrapper'>
          <h2 ref={crmStart}>Attendees</h2>
          <AttendeesSearchBar setSearchQuery={setSearchQuery} />
        </div>

        <div className='Attendees-actionBar'>
          {!isEmpty(attendees) && (
            <RequirePermissions requiredPermissions={[requirePermissionAndScope('filter_attendee_list', ['group'])]}>
              <ActionBarButton
                img={filterUrl}
                title={'FILTER'}
                onClick={() => setFilterModalIsOpen(true)}
                info={'Filter your attendees by tag, total spend, date added, or events attended!'}
              />
            </RequirePermissions>
          )}
          <RequirePermissions requiredPermissions={[requirePermissionAndScope('tag_attendees', ['group'])]}>
            <ActionBarButton
              img={tagWhiteUrl}
              title={'TAG'}
              onClick={() => setTagModalIsOpen(true)}
              info={'Tag your attendees for better filtering.'}
            />
          </RequirePermissions>
        </div>
        <FilterAlert onOpenModal={() => setFilterModalIsOpen(true)} />
        <AttendeesTable
          attendeeRows={attendees ?? []}
          hasMoreAttendees={groupAttendeesData?.hasMore ?? false}
          isFetching={isFetching}
        />
        {attendees && attendees.length === 0 && !isFetching && (isFilterActive || page == 1) && (
          <div className='Attendees-emptyState'>
            Once you begin selling tickets, attendee information will appear here!
          </div>
        )}
        {isError && (
          <div className='Attendees-emptyState'>There was an error fetching your attendees, please try again.</div>
        )}
        <AttendeesCrmActionsModal isOpen={filterModalIsOpen} onClose={() => setFilterModalIsOpen(false)} />
        <TagsModal isOpen={tagModalIsOpen} onClose={() => setTagModalIsOpen(false)} />
      </div>
    </div>
  )
}

export default AttendeesListView
