import React, {PropsWithChildren} from 'react'

import {GuestList} from 'apis/Events/useFetchEventGuestlist'
import {stockGuest0, stockGuest1, stockGuest2, stockGuest3, stockGuest4} from 'components/assets/Icons'
import Button from 'components/form/Button'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'

interface GuestlistViewMoreModalProps extends PoshStyledModalProps {
  isViewingInRSVPEvent: boolean
  guestlistProfiles: GuestList
}

const GuestlistPreview = ({children}: PropsWithChildren<unknown>) => {
  return <div className='GuestlistPreview'>{children}</div>
}

const STOCK_PROFILES = [
  {
    attendeeAccountId: '0',
    attendeeAvi: stockGuest0,
  },
  {
    attendeeAccountId: '1',
    attendeeAvi: stockGuest1,
  },
  {
    attendeeAccountId: '2',
    attendeeAvi: stockGuest2,
  },
  {
    attendeeAccountId: '3',
    attendeeAvi: stockGuest3,
  },
  {
    attendeeAccountId: '4',
    attendeeAvi: stockGuest4,
  },
]

export const GuestlistViewMoreModal = ({
  isViewingInRSVPEvent,
  guestlistProfiles,
  ...props
}: GuestlistViewMoreModalProps) => {
  const promptImperative = isViewingInRSVPEvent ? 'RSVP' : 'Purchase Tickets'

  const onClickImperativeButton = () => {
    const ticketsSection = document.getElementsByClassName(
      isViewingInRSVPEvent ? 'EventPage-rsvpBtn' : 'EventPage-ticketSection',
    )[0]
    ticketsSection.scrollIntoView({behavior: 'smooth'})
    props.onClose()
  }

  const definedProfiles = guestlistProfiles.map((profile, index) => {
    if (profile?.hasCustomAvi) return profile
    else return STOCK_PROFILES[index]
  })

  return (
    <PoshStyledModal {...props}>
      <div className='GuestlistProfileModal'>
        <GuestlistPreview>
          {definedProfiles.map(profile => (
            <PoshImage key={profile.attendeeAccountId.toString()} src={profile.attendeeAvi} />
          ))}
        </GuestlistPreview>
        <h4>{`${promptImperative} to view more guests!`}</h4>
        <Button onClick={onClickImperativeButton} className='dark round'>
          {promptImperative}
        </Button>
      </div>
    </PoshStyledModal>
  )
}
