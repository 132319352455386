import {Component} from 'react'

import {DateInput} from './Date'
import {DateTimeInput} from './DateTime'
import {TimeInput} from './Time'

export class EventVisualsDateTimeInput extends Component {
  static Date = DateInput
  static Time = TimeInput
  static DateTime = DateTimeInput
}
