// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheckboxGroupContainer__aL9Co{display:flex;flex-direction:row;gap:10px;flex-wrap:wrap}", "",{"version":3,"sources":["webpack://./src/components/DynamicForm/Inputs/CheckboxGroup/CheckboxGroup.module.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA","sourcesContent":[".CheckboxGroupContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CheckboxGroupContainer": "CheckboxGroupContainer__aL9Co"
};
export default ___CSS_LOADER_EXPORT___;
