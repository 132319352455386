import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'
import {GlassMorphicButton} from 'components/form/Button'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'

import {WithToolTip} from '../../../../form/PoshInfo'
import {EventVisualsCircleButton} from '../../Button/CircleButton'

import styles from './styles.module.scss'

interface EventVisualsFlyerProps {
  image?: string
  isUploadingImage: boolean
  flyerPlaceholder: string
  lightMode: boolean
  accentColor: string
  openImagePicker: () => void
  openDesignModal: () => void
}

export const EventVisualsFlyer = (props: PropsWithChildren<EventVisualsFlyerProps>) => {
  const {
    image,
    isUploadingImage,
    openDesignModal,
    flyerPlaceholder,
    openImagePicker,
    lightMode,
    accentColor,
    children,
  } = props

  return (
    <div className={styles.EventVisualsFlyer}>
      <div className={styles.wrapper}>
        {image ? (
          <PoshImage
            className={classNames(styles.flyer, {[`${styles.isLoading}`]: isUploadingImage})}
            src={image}
            alt='event flyer'
          />
        ) : (
          <PoshImageBackground
            onClick={openImagePicker}
            className={styles.flyerPlaceholder}
            style={{backgroundImage: `url(${flyerPlaceholder})`}}>
            <h3 className='noMargin akira'>DESIGN YOUR EVENT PAGE</h3>
            <GlassMorphicButton onClick={openImagePicker} disabled={isUploadingImage}>
              Upload poster*
            </GlassMorphicButton>
          </PoshImageBackground>
        )}
        <div className={styles.childrenWrapper}>{children}</div>
        <div className={styles.buttonWrapper}>
          {image && (
            <>
              <WithToolTip info={'Customize your event font, accent color, and light/dark mode.'}>
                <EventVisualsCircleButton
                  disabled={isUploadingImage}
                  iconType='equalizer'
                  lightMode={lightMode}
                  accentColor={accentColor}
                  onClick={openDesignModal}
                />
              </WithToolTip>
              <WithToolTip
                info={`Choose an image from your gallery or search for GIFs/images online for your event's flyer.`}>
                <EventVisualsCircleButton
                  disabled={isUploadingImage}
                  iconType='gallery'
                  lightMode={lightMode}
                  accentColor={accentColor}
                  onClick={openImagePicker}
                />
              </WithToolTip>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
