import React from 'react'

import {Gender} from 'apis/Groups'
import Select from 'components/form/Select'
import {isNull} from 'lodash'

interface GenderFilterProps {
  genderFilter: Gender
  setGenderFilter: React.Dispatch<React.SetStateAction<Gender>>
}

const GenderFilters = (props: GenderFilterProps) => {
  const {genderFilter, setGenderFilter} = props
  return (
    <div className='DataFiltersSection'>
      <div className='FilterModal-topRow'>
        <h6>Filter By Detected Gender</h6>
      </div>
      <div className='DataFiltersSection-selectWrapper'>
        <Select className='DataFiltersSection-genderSelect' onChange={e => setGenderFilter(e.target.value as Gender)}>
          <option disabled selected={isNull(genderFilter)}>
            Select a gender
          </option>
          <option value='male' selected={genderFilter === 'male'}>
            Male
          </option>
          <option value='female' selected={genderFilter === 'female'}>
            Female
          </option>
        </Select>
      </div>
    </div>
  )
}

export default GenderFilters
