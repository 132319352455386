import React from 'react'

import {IPendingApproval} from 'apis/Orders/useGetPendingApprovals'

export type ApprovalCrmContextState = {
  selectedApprovalIds: string[]
  setSelectedApprovalIds: (ids: string[]) => void
  approvalProfile: IPendingApproval | null
  setShowApprovalProfile: React.Dispatch<React.SetStateAction<IPendingApproval | null>>
}

export const ApprovalCrmContext = React.createContext<ApprovalCrmContextState | null>(null)

export default function useApprovalCrmContext() {
  const context = React.useContext(ApprovalCrmContext)
  if (!context) throw 'ApprovalCrmContext must be provided'
  return context
}
