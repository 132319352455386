import React, {forwardRef, useEffect, useImperativeHandle} from 'react'

export interface TurnstileRef {
  getResponse: (widgetId?: string) => string
  reset: (widgetId?: string) => void
  isExpired: (widgetId?: string) => boolean
  ready(callback: () => void): void
  render(id: string, options: {sitekey: string; callback: (token: string) => void}): void
}

declare global {
  interface Window {
    handleCfTurnstile: (token: string) => void
    turnstile: TurnstileRef
  }
}

export interface CaptchaInterfacingProps {
  captchaToken?: string
  resetCaptcha?: () => void
}

//If the environment variable is not set, use the testing value
const CLOUDFLARE_SITE_KEY = process.env.CLOUDFLARE_SITE_KEY || '3x00000000000000000000FF'

interface CloudflareTurnstileProps {
  successCallback?: (token: string) => void
}

const CloudflareTurnstile = forwardRef<TurnstileRef, CloudflareTurnstileProps>(
  ({successCallback}: CloudflareTurnstileProps, ref) => {
    useImperativeHandle(ref, () => ({
      reset: widgetId => window.turnstile.reset(widgetId),
      getResponse: widgetId => window.turnstile.getResponse(widgetId),
      isExpired: widgetId => window.turnstile.isExpired(widgetId),
      ready: callback => window.turnstile.ready(callback),
      render: (id, options) => window.turnstile.render(id, options),
    }))
    useEffect(() => {
      if (successCallback) window.handleCfTurnstile = successCallback
    }, [successCallback])

    const renderTurnstile = () => {
      if (!window.turnstile || !window.turnstile.render) {
        return false
      }
      window.turnstile.render('#cf-turnstile', {
        sitekey: CLOUDFLARE_SITE_KEY,
        callback: token => {
          if (successCallback) successCallback(token)
        },
      })
      return true
    }

    useEffect(() => {
      const intervalId = setInterval(() => {
        if (renderTurnstile()) {
          clearInterval(intervalId)
        }
      }, 1000)

      return () => clearInterval(intervalId)
    }, [])

    return (
      <div
        id='cf-turnstile'
        className='cf-turnstile'
        style={{width: '100%'}}
        data-sitekey={CLOUDFLARE_SITE_KEY}
        {...(successCallback ? {'data-callback': 'handleCfTurnstile'} : {})}
        data-size='flexible'
        data-appearance='interaction-only'></div>
    )
  },
)
CloudflareTurnstile.displayName = 'CloudflareTurnstile'

export default CloudflareTurnstile
