import React from 'react'
import {useMatch} from 'react-router-dom'

import {PoshRoutes} from 'models/PoshRoutes'

import {EventManagementTertiaryNavigation} from './Variants/EventManagement'

/**
 * Gets the proper Desktop Tertiary Navigation based on the current route.
 * Currently only supports Event Management. (No other Tertiary Navigation exists)
 */
function useGetDesktopTertiaryNavigation() {
  const isInEventManagement = Boolean(useMatch(PoshRoutes.EVENT_ROUTES))
  if (isInEventManagement) return EventManagementTertiaryNavigation.Desktop
  return null
}

function DesktopTertiaryNavigation() {
  const DesktopTertiaryNavigation = useGetDesktopTertiaryNavigation()
  if (DesktopTertiaryNavigation) return <DesktopTertiaryNavigation />
  return <></>
}

function MobileTertiaryNavigation() {
  // not implemented
  return <></>
}

export class TertiaryNavigation {
  static Desktop = DesktopTertiaryNavigation
  static Mobile = MobileTertiaryNavigation
}
