import React from 'react'

import {EventPublic} from 'apis/Events/types'
import Button from 'components/form/Button'
import PoshLock from 'components/PoshLock'

import './styles.scss'

interface RestrictionBoxProps {
  colorModeModifier: string
  event: EventPublic
  buttonAction: () => void | Promise<void>
  text: string
  buttonText?: string | null
  inSection?: boolean
  isAwaitingApproval?: boolean
}

const EventPageRestrictionBox = (props: RestrictionBoxProps) => {
  const {colorModeModifier, event, buttonAction, text, buttonText, inSection, isAwaitingApproval} = props

  return (
    <div className={`RestrictionBox ${inSection ? 'inSection' : ''} ` + colorModeModifier}>
      <h4 style={{color: event.lightmode ? event.accentColor : 'white'}}>
        <PoshLock fill={event.lightmode ? event.accentColor : 'white'} />
        Restricted Access
      </h4>
      <p className='noMargin' style={{color: event.lightmode ? event.accentColor : 'white'}}>
        {text}
      </p>
      {buttonText && !isAwaitingApproval && (
        <Button onClick={buttonAction} style={{background: event.accentColor}}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}

export default EventPageRestrictionBox
