import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface CreateMailChimpListParams {
  listName: string
  groupId: string
}
export interface CreateMailChimpListResponse {
  listId: string
}

async function createMailChimpList(params: CreateMailChimpListParams): Promise<CreateMailChimpListResponse> {
  const response = await PoshApi.createMailChimpList(params)
  return response.data
}

export default function useCreateMailChimpList() {
  return useMutation<CreateMailChimpListResponse, unknown, CreateMailChimpListParams>(params =>
    createMailChimpList(params),
  )
}
