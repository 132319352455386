import React, {ButtonHTMLAttributes, forwardRef} from 'react'

import './styles.scss'

type Props = ButtonHTMLAttributes<HTMLButtonElement>

const CheckoutButton = forwardRef<HTMLButtonElement, Props>(({className, title, children, ...props}, ref) => (
  <button title={title} className={'CheckoutButton ' + (className || '')} ref={ref} {...props}>
    {children || title}
  </button>
))

CheckoutButton.displayName = 'CheckoutButton'

export default CheckoutButton
