import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {OrderModel} from '.'

async function fetchComplimentaryOrder(eventId: string): Promise<OrderModel[]> {
  const response = await PoshApi.fetchComplimentaryOrders(eventId)
  const data: OrderModel[] = response.data.complimentaryOrders
  return data
}

export function useFetchComplimentaryOrders(eventId: string) {
  return useQuery(['complimentaryOrders', eventId], () => fetchComplimentaryOrder(eventId))
}
