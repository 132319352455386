import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface MailChimpList {
  id: string
  name: string
  dateCreated: string
  contacts: string
}

async function fetchMailChimpLists(groupId: string): Promise<MailChimpList[]> {
  const response = await PoshApi.fetchMailChimpLists(groupId)
  return response.data.lists
}

export function useFetchMailChimpLists(groupId: string) {
  return useQuery<MailChimpList[], any>(['mailChimpLists', groupId], async () => await fetchMailChimpLists(groupId), {
    enabled: !!groupId,
  })
}
