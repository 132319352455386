import React from 'react'

import {ExportKeyObject} from 'apis/Events/types'

import './styles.scss'

interface CheckBoxListProps {
  options: ExportKeyObject[]
  updateOptions: (options: ExportKeyObject[]) => void
}
export const CheckBoxList = (props: CheckBoxListProps) => {
  const {options, updateOptions} = props

  const handleUpdate = (target: HTMLFormElement) => {
    const selectedKeys: string[] = []
    const dLen = target.length
    for (let i = 0; i < dLen; i++) {
      if ((target[i] as HTMLFormElement).checked) selectedKeys.push((target[i] as HTMLFormElement).value)
    }
    updateOptions(
      options.map(el => {
        if (selectedKeys.includes(el.key)) {
          return {...el, selected: true}
        } else {
          return {...el, selected: false}
        }
      }),
    )
  }
  return (
    <form onChange={e => handleUpdate(e.currentTarget)}>
      <div className='CheckBoxList'>
        {options.map((el, index: number) => (
          <label key={`${el.key}-${index}`} className='CheckBoxList-Option'>
            <input type='checkbox' id={`${el.key}-${index}`} checked={el.selected} value={el.key} />
            <span aria-hidden='true' className='CheckBoxList-Option-Checkbox'>
              <svg viewBox='-2 -3 18 18'>
                <path d='M1 4.5L5 10L14 1' />
              </svg>
            </span>
            {el.name}
          </label>
        ))}
      </div>
    </form>
  )
}
