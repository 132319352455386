import {useEffect, useState} from 'react'

import {Coordinates} from '@posh/types'
import {useToast} from 'components/toasts/ToastProvider'

export const useGetUserCoordinates = (dep: string) => {
  const {showToast} = useToast()
  const [userCoordinates, setUserCoordinates] = useState<Coordinates | undefined>(undefined)

  useEffect(() => {
    if (dep === 'Near Me') {
      if (!('geolocation' in navigator)) {
        showToast({
          type: 'error',
          title: 'You have not shared your location with POSH. Please share to get events near you.',
        })
      } else {
        navigator.geolocation.getCurrentPosition(position => {
          setUserCoordinates([position.coords.longitude, position.coords.latitude])
        })
      }
    }
  }, [dep])

  return {
    coordinates: userCoordinates,
    setCoordinates: setUserCoordinates,
  }
}
