import React, {useState} from 'react'

import {FetchAffiliatesResponse} from 'apis/Kickback/useFetchAffiliates'
import {KickbackOffer} from 'apis/Kickback/useFetchKickBackOffers'
import {useUpdateKickBackOfferLimit} from 'apis/Kickback/useUpdateKickbackOfferLimit'
import Button from 'components/form/Button'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import Multiselect from 'multiselect-react-dropdown'
import multiSelectStyles from 'pages/GroupPage/Marketing/Filter/multi-select-styles'

import './styles.scss'

interface KickbackOfferLimitModalProps extends PoshStyledModalProps {
  offer: KickbackOffer
  refetchKickbackOffers: () => void
  existingAffiliates: FetchAffiliatesResponse
}

interface SelectOption {
  name: string
  id: string
}

const KickbackOfferLimitModal = (props: KickbackOfferLimitModalProps) => {
  const {offer, refetchKickbackOffers, existingAffiliates} = props
  const {mutateAsync: updateKickbackOfferLimit, isLoading} = useUpdateKickBackOfferLimit()
  const affiliates = offer.limitedTo
  const selectedAffiliates = existingAffiliates.filter(affiliate => affiliates?.includes(affiliate.affiliateId))
  const [preSelectedAffiliates, setPreSelectedAffiliates] = useState<SelectOption[]>(
    selectedAffiliates.map(affiliate => ({
      name: affiliate.firstName + ' ' + affiliate.lastName,
      id: affiliate.affiliateId,
    })),
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const selectedAffiliateIds = preSelectedAffiliates.map(affiliate => affiliate.id)

  const isButtonDisabled =
    (affiliates?.every(affiliate => selectedAffiliateIds.includes(affiliate)) &&
      affiliates.length === selectedAffiliateIds.length) ||
    isLoading

  const allAffiliateOptions = existingAffiliates.map(affiliate => ({
    name: affiliate.firstName + ' ' + affiliate.lastName,
    id: affiliate.affiliateId,
  }))

  const handleUpdate = (value: SelectOption[]) => {
    setPreSelectedAffiliates(value)
  }

  const handleUpdateKickback = async () => {
    try {
      await updateKickbackOfferLimit({id: offer._id, limitedTo: selectedAffiliateIds})
      refetchKickbackOffers()
      setSuccessMessage('Successfully updated kickback offer')
    } catch (err) {
      setErrorMessage(err.response.data.error)
    }
  }

  return (
    <PoshStyledModal {...props}>
      <div className='KickbackOfferLimitModal'>
        <h3>Limit Kickback Offer</h3>
        <p className='noMargin'>Choose which specific affiliates you want this offer limited to</p>
        <Multiselect
          selectedValues={preSelectedAffiliates}
          options={allAffiliateOptions}
          onSelect={value => handleUpdate(value)}
          onRemove={value => handleUpdate(value)}
          displayValue='name'
          style={multiSelectStyles}
          closeOnSelect={true}
          placeholder={'Select Affiliates'}
        />
        <Button onClick={handleUpdateKickback} disabled={isButtonDisabled}>
          Update Kickback Offer
        </Button>
        {errorMessage && <p className='error noMargin'>{errorMessage}</p>}
        {successMessage && <p className='success noMargin'>{successMessage}</p>}
      </div>
    </PoshStyledModal>
  )
}

export default KickbackOfferLimitModal
