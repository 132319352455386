import {useEffect} from 'react'

import useSessionContext from 'domains/Auth/SessionContext'

export const useUnauthenticateIfExpiredToken = () => {
  const {userId, unauthenticateSession} = useSessionContext()
  useEffect(() => {
    if (userId) {
      const tokenExpires = parseInt(localStorage.getItem('token_expires')!)
      const tokenExpiresDate = new Date(tokenExpires)
      const currentDate = new Date()
      if (tokenExpiresDate < currentDate) {
        unauthenticateSession()
      }
    }
  }, [userId])
}
