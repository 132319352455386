import {useEffect, useState} from 'react'

export const useResize = (myRef: React.RefObject<HTMLHeadingElement>) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const getDimensions = () => ({
      width: (myRef && myRef.current?.offsetWidth) || 0,
      height: (myRef && myRef.current?.offsetHeight) || 0,
    })

    const handleResize = () => {
      const {width, height} = getDimensions()
      setWidth(width)
      setHeight(height)
    }

    if (myRef.current) {
      const {width, height} = getDimensions()
      setWidth(width)
      setHeight(height)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  return {width, height}
}
