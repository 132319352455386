import {useEffect, useState} from 'react'
import {useMediaQuery} from 'react-responsive'
function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window
  return {
    width,
    height,
  }
}

export const useDimensions = () => {
  const mobileWidth = 576
  const tabletWidth = 768
  const desktopWidth = 992

  const isMobile = useMediaQuery({query: `(max-width: ${mobileWidth}px)`})
  const isTablet = useMediaQuery({query: `(max-width: ${tabletWidth}px)`})
  const isDesktop = useMediaQuery({query: `(max-width: ${desktopWidth}px)`})
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {isMobile, isTablet, isDesktop, mobileWidth, tabletWidth, desktopWidth, ...windowDimensions}
}
