import React, {useEffect, useState} from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'
import {useDimensions} from 'hooks/useDimensions'
import {LoginFlow} from 'pages/LoginPage'
import {LoginMethodPicker} from 'pages/LoginPage/LoginMethodPicker'

import {CreateEventEmailEntryForm} from './CreateEventEmailEntryForm'
import {CreateEventEmailLoginForm} from './CreateEventEmailVerificationForm'
import CreateEventNewAccountForm from './CreateEventNewAccountForm'
import {CreateEventPhoneEntryForm} from './CreateEventPhoneEntryForm'
import {CreateEventPhoneVerificationForm} from './CreateEventPhoneVerificationForm'
import {CreateEventFormData} from './util/createEventFormData'

export interface CreateEventLoginProps {
  defaultEmail?: string
  defaultPhone?: string
}

function getInitialLoginFlow(isMobile: boolean, defaultEmail: string, defaultPhone: string): LoginFlow {
  if (isMobile || !defaultPhone) return 'phone'
  return 'email'
}

export const CreateEventLogin = (props: CreateEventLoginProps) => {
  const {defaultEmail = '', defaultPhone = ''} = props
  const {isMobile} = useDimensions()
  const initialFlow = getInitialLoginFlow(isMobile, defaultEmail, defaultPhone)
  const [createEventFormData, setCreateEventFormData] = useState<CreateEventFormData>({
    hasAccount: false,
    loginStep: {type: initialFlow},
    loginFlow: initialFlow,
    showSignUp: false,
    userData: {email: defaultEmail, phone: defaultPhone},
  })

  useEffect(() => {
    if (!createEventFormData.userData.email) {
      setCreateEventFormData(u => ({...u, hasAccount: false}))
    }
  }, [createEventFormData.userData.email, createEventFormData.userData.phone])

  const switchLoginFlow = (method: LoginFlow) => {
    setCreateEventFormData(u => ({
      ...u,
      hasAccount: false,
      showSignUp: false,
      loginFlow: method,
      loginStep: {type: method},
      userData: {email: defaultEmail, phone: defaultPhone},
    }))
  }

  const handleResetLogin = () => {
    const initialLoginFlow = getInitialLoginFlow(isMobile, defaultEmail, defaultPhone)
    switchLoginFlow(initialLoginFlow)
  }

  const handleGoBack = () => {
    if (createEventFormData.loginStep.previous) {
      setCreateEventFormData(u => ({...u, loginStep: u.loginStep.previous!}))
    } else {
      handleResetLogin()
    }
  }

  const getCurrentForm = () => {
    switch (createEventFormData.loginStep.type) {
      case 'email':
        return (
          <CreateEventEmailEntryForm
            createEventFormData={createEventFormData}
            setCreateEventFormData={setCreateEventFormData}
          />
        )
      case 'phone':
        return (
          <CreateEventPhoneEntryForm
            createEventFormData={createEventFormData}
            setCreateEventFormData={setCreateEventFormData}
          />
        )
      case 'loginWithEmail':
        return (
          <CreateEventEmailLoginForm
            createEventFormData={createEventFormData}
            setCreateEventFormData={setCreateEventFormData}
          />
        )
      case 'verifySMS':
        return (
          <CreateEventPhoneVerificationForm
            createEventFormData={createEventFormData}
            setCreateEventFormData={setCreateEventFormData}
          />
        )
      case 'createAccount':
        return (
          <CreateEventNewAccountForm
            createEventFormData={createEventFormData}
            setCreateEventFormData={setCreateEventFormData}
          />
        )
    }
  }

  return (
    <div className='CreateEventLogin'>
      {createEventFormData.loginStep.previous && (
        <PoshImage
          className='CreateEventLogin-backButton'
          src='https://images.posh.vip/b2/left-arrow-in-circular-button-black-symbol.svg'
          onClick={handleGoBack}
        />
      )}
      {!createEventFormData.hasAccount && (
        <LoginMethodPicker noMargin switchLoginFlow={switchLoginFlow} loginFlow={createEventFormData.loginFlow} />
      )}
      {getCurrentForm()}
    </div>
  )
}
