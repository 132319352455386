import React, {useContext, useState} from 'react'
import {createContext} from 'react'

import {StagedUserFlow} from '../StagedUserFlow'
import {StageFour, StageOne, StageThree, StageTwo} from './PhotographyServiceFlowStages'

type PhotographyServiceBooking = {
  groupId: string
  eventId: string
  time: Date
  lengthOfStay: number
  pending: boolean
}

type PhotographyServiceFlowProps = Omit<PhotographyServiceBooking, 'lengthOfStay'>

export const PhotographyServiceFlow = (props: PhotographyServiceFlowProps) => {
  const [value, setValue] = useState<PhotographyServiceBooking>({
    ...props,
    lengthOfStay: 2,
  })

  return (
    <PhotographyServiceContext.Provider value={{value, setValue}}>
      <StagedUserFlow
        stages={[<StageOne key={0} />, <StageTwo key={1} />, <StageThree key={2} />, <StageFour key={3} />]}
      />
    </PhotographyServiceContext.Provider>
  )
}

type PhotographyServiceContext = {
  value: PhotographyServiceBooking
  setValue: React.Dispatch<React.SetStateAction<PhotographyServiceBooking>>
}

const PhotographyServiceContext = createContext<PhotographyServiceContext>({} as PhotographyServiceContext)

export const usePhotographyService = () => {
  const context = useContext(PhotographyServiceContext)

  if (!context) {
    throw new Error('usePhotographyService() must be used within a PhotographyServiceContextProvider.')
  }

  return context
}
