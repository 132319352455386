import React, {useState} from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'

interface EntityPictureGalleryProps {
  gallery: string[]
}

const EntityPictureGallery = (props: EntityPictureGalleryProps) => {
  const {gallery} = props
  const [galleryPicture, setGalleryPicture] = useState<string>(gallery[0])

  const handleNextPhoto = () => {
    const nextIndex = gallery.indexOf(galleryPicture) + 1
    if (nextIndex < gallery.length) {
      setGalleryPicture(gallery[nextIndex])
    } else {
      setGalleryPicture(gallery[0])
    }
  }

  return (
    <>
      <PoshImage src={galleryPicture} className='cDetailsModal-cover' />
      <div className='cDetailsModal-nextButton'>
        <PoshImage src='https://images.posh.vip/b2/next.png' onClick={() => handleNextPhoto()} />
      </div>
    </>
  )
}

export default EntityPictureGallery
