import React from 'react'

export interface CSVModalCofig {
  title: string
  headers: string[]
  confirmText: string
  template: string
  description: JSX.Element
}

const getCSVModalConfig = (uploadType: string) => {
  switch (uploadType) {
    case 'complimentaryTickets': {
      const config: CSVModalCofig = {
        title: 'Complimentary Tickets',
        headers: ['First Name', 'Last Name', 'Email', 'Ticket Quantity'],
        confirmText: 'Send Tickets',
        template: 'https://images.posh.vip/b2/POSH+Complimentary+Tickets+Import+Template.csv',
        description: (
          <>
            <p>Download the template below, add your data to it, then upload.</p>
            <p>
              <b>Imports are limited to 200 attendees at a time and five imports per day.</b>
            </p>
            <p>
              <b>Attendees must consent to being sent tickets prior to upload.</b>
            </p>
            <p>
              Upon uploading, you will select which ticket type you’d like to send out. If you need to send multiple
              ticket types, use multiple uploads.
            </p>
          </>
        ),
      }
      return config
    }
    case 'promoCodes': {
      const config: CSVModalCofig = {
        title: 'Promo Codes',
        headers: ['Promo Code', 'Type', 'Value', 'Use Limit'],
        confirmText: 'Activate Promo Codes',
        template: 'https://images.posh.vip/b2/POSH+Promo+Code+Import+Template.csv',
        description: (
          <>
            <p>Download the template below, add your data to it, then upload.</p>
            <p>
              <b>Imports are limited to 200 promo codes at a time and five imports per day.</b>
            </p>
            <p>Upon uploading, you can select a ticket type to limit the codes to.</p>
          </>
        ),
      }
      return config
    }
    default:
      return undefined
  }
}

export default getCSVModalConfig
