import React from 'react'

import classNames from 'classnames'
import {capitalize} from 'lodash'

import './StatusBadge.styles.scss'

// eslint-disable-next-line @typescript-eslint/ban-types
const StatusBadge = ({status}: {status: string}) => {
  return <span className={classNames('StatusBadge', `StatusBadge_${status}`)}>{capitalize(status)}</span>
}

export default StatusBadge
