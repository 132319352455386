import React, {ReactElement, useEffect, useMemo} from 'react'

import {useQueryClient} from '@tanstack/react-query'

import useFetchTeam from '../../../apis/Roles/useFetchTeam'
import PoshStyledModal, {PoshStyledModalProps} from '../../../components/modals/Modal/PoshStyledModal'
import DeleteTeamMember from '../DeleteTeamMember'
import PermissionsEditor from './PermissionsEditor'
import useTeamManagementModalContext from './TeamManagementModalContext'

import './styles.scss'

export type EditTeamManagementModalPanel = 'edit_permissions' | 'delete_team_member'

const EDIT_MODAL_PANEL_COMPONENT_MAP: {[key in EditTeamManagementModalPanel]: ReactElement} = {
  edit_permissions: <PermissionsEditor />,
  delete_team_member: <DeleteTeamMember />,
}

export default function TeamManagementModal(props: PoshStyledModalProps) {
  const {
    activePanel,
    errorMessage,
    successMessage,
    setIsLoading,
    setEntityTeamMembers,
    reset,
    entityId,
    scope,
    setCloseModal,
  } = useTeamManagementModalContext()
  const {data: team, isFetching: teamIsFetching} = useFetchTeam(entityId, scope)
  const queryClient = useQueryClient()
  const activePanelIsNotChooseRole = activePanel !== 'choose_role'

  const onClose = () => {
    reset()
    queryClient.invalidateQueries(['team'])
    props.onClose()
  }

  useEffect(() => {
    setIsLoading(teamIsFetching)
    if (team) setEntityTeamMembers(team)
  }, [setEntityTeamMembers, setIsLoading, team, teamIsFetching])

  useEffect(() => {
    setCloseModal(() => props.onClose)
  }, [props.onClose, setCloseModal])

  const title = useMemo(() => {
    switch (activePanel) {
      case 'edit_permissions':
        return 'Edit Permissions'
      case 'delete_team_member':
        return 'Remove Team Member'
      default:
        return 'Select a Role'
    }
  }, [activePanel])

  return (
    <PoshStyledModal width={800} {...props} noborder onClose={onClose}>
      <div className='AddTeamMemberModal'>
        <h4>{title}</h4>
        {activePanelIsNotChooseRole && <>{EDIT_MODAL_PANEL_COMPONENT_MAP[activePanel]}</>}
        {successMessage && <p>{successMessage}</p>}
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    </PoshStyledModal>
  )
}
