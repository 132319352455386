import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CommunityCategory, CommunityEntityResponse} from '.'

async function fetchCommunityEntityByUrl(
  communityCategory: CommunityCategory,
  communityUrl: string,
): Promise<CommunityEntityResponse> {
  const response = await PoshApi.fetchCommunityEntityByUrl(communityCategory, communityUrl)
  return response.data
}

export default function useFetchCommunityEntityByUrl(communityCategory: CommunityCategory, communityUrl: string) {
  return useQuery(
    ['fetchCommunityEntityByUrl', communityUrl],
    () => fetchCommunityEntityByUrl(communityCategory, communityUrl),
    {
      enabled: !!communityUrl,
    },
  )
}
