import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import useSessionContext, {AccountRoleGroupIdMap} from '../../domains/Auth/SessionContext'
import {AccountGroupRoleMap, GroupAccountRoleUnion} from '.'

export interface GroupsResponse {
  groups: AccountGroupRoleMap[]
  accountRoleGroupUnions: GroupAccountRoleUnion[]
}

export async function getAccountGroups(accountId: string): Promise<GroupAccountRoleUnion[]> {
  const response = await PoshApi.fetchAccountGroups(accountId)
  const data: GroupsResponse = response.data
  return data.accountRoleGroupUnions
}

export function useFetchAccountGroups() {
  const {userId, setAccountRoleGroupIdMap} = useSessionContext()
  return useQuery(['accountGroups', userId], () => getAccountGroups(userId!), {
    onSuccess: data => {
      const obj: AccountRoleGroupIdMap = {}
      data?.forEach(groupRoleUnion => {
        obj[groupRoleUnion.group._id] = groupRoleUnion.accountRole
      })
      localStorage.setItem('accountRoleGroupIdMap', JSON.stringify(obj))
      setAccountRoleGroupIdMap(obj)
    },
    enabled: !!userId,
  })
}
