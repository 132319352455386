import React from 'react'

import InlineBadge from 'pages/SMSCampaign/InlineBadge'
import {RenderElementProps} from 'slate-react'

const Element = (props: RenderElementProps) => {
  const {attributes, children, element} = props
  switch (element.type) {
    case 'badge':
      return <InlineBadge {...props} />
    case 'paragraph':
      return (
        <p className='noMargin' style={{fontSize: '15px', lineHeight: 'normal'}} {...attributes}>
          {children}
        </p>
      )
    default:
      return null
  }
}

export default Element
