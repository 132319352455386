import React from 'react'
import Loader from 'react-loader-spinner'
import {formatPhoneNumber} from 'react-phone-number-input'
import {useLocation, useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import PhoneNumberBadge from 'components/Badge/PhoneNumberBadge'
import Button from 'components/form/Button'
import moment from 'moment'

import useFetchTextBlastsForGroup, {TextBlastForGroup} from '../../../apis/Groups/useFetchTextBlastsForGroup'
import {useGroupMatchParams} from '../../../domains/Groups/helpers'
import {useResourcePageParams} from '../../../pages/PoshAppLayout'
import MessageView from '../../../pages/SMSCampaign/Breakdown/MessageView'
import UserListPreview from '../../UserListPreview/UserListPreview'

import './index.scss'

interface AggregationTextBlastGroup extends TextBlastForGroup {
  totalRecipients: number
}

interface BlastsProps {
  textBlast: AggregationTextBlastGroup
}

const BlastsTableRow = (props: BlastsProps) => {
  const {textBlast} = props
  const {trackEvent} = useMixpanel()
  const {groupId} = useGroupMatchParams()
  const {domain} = useResourcePageParams()

  const navigate = useNavigate()
  const {number, eventName, dateCreated, totalRecipients, fromNumber, previewRecipients, message} = textBlast

  return (
    <>
      <div
        className='BlastsTable-blast'
        onClick={() => {
          trackEvent('Text Blast- SMS Campaign on Group Overview Click')
          navigate(`/${domain}/groups/${groupId}/marketing/smscampaign/${textBlast._id}/details`)
        }}>
        <h5>
          Campaign #{number}
          {eventName && ` - ${eventName}`} - {moment(dateCreated).format('MMM D hh:mmA')}
        </h5>
        <MessageView className='BlastsTable-blast__message' message={message ?? ''} attachedEventLink={!!eventName} />
        <hr />
        <div className='BlastInfo'>
          <div>
            <UserListPreview>
              {previewRecipients.map((r, index) => (
                <UserListPreview.User key={index} imageSrc={r.avi} />
              ))}
            </UserListPreview>
            <div>
              <i>{totalRecipients} Recipients</i>
            </div>
          </div>
          <PhoneNumberBadge className='BlastInfo__phoneNumber'>
            {fromNumber?.length === 5 ? fromNumber : formatPhoneNumber(fromNumber ?? '')}
          </PhoneNumberBadge>
        </div>
      </div>
    </>
  )
}

const BlastsTable = ({limit, fullHistory}: {limit: number; fullHistory?: boolean}) => {
  const {groupId} = useGroupMatchParams()
  const {trackEvent} = useMixpanel()
  const {data: textBlasts, isFetching} = useFetchTextBlastsForGroup({groupId: groupId!, limit})
  const {domain} = useResourcePageParams()
  const search = useLocation().search
  const navigate = useNavigate()

  if (textBlasts && textBlasts.length > 0 && !isFetching) {
    return (
      <>
        <div className='BlastsTable'>
          {textBlasts?.map((tb, index) => (
            <BlastsTableRow key={index} textBlast={tb} />
          ))}
        </div>
        {fullHistory ? (
          <>
            <Button className='light dark fullWidth' disabled={true}>
              Contact Support to Get Full Campaign History
            </Button>
          </>
        ) : (
          <>
            <Button
              className='light dark fullWidth'
              onClick={() => {
                trackEvent('Text Blast- SMS Blasts View More Click')
                navigate(`/${domain}/groups/${groupId}/marketing/view-sms`)
              }}>
              View More Campaigns
            </Button>
          </>
        )}
      </>
    )
  } else {
    return (
      <>
        {isFetching ? (
          <>
            <p>Loading...</p>
            <Loader type='TailSpin' width={40} height={40} />
          </>
        ) : (
          <>
            <p className='Empty'>You haven&apos;t utilized marketing campaigns yet.</p>
            <button
              className='Button Overview-moreButton'
              onClick={() => {
                navigate(`/${domain}/groups/${groupId}/marketing` + search)
              }}>
              + Begin Campaign
            </button>
          </>
        )}
      </>
    )
  }
}

export default BlastsTable
