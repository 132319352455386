import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {Medium} from '.'

export interface CreateCampaignLinkParams {
  name: string
  source: string
  medium: Medium
  destination_url: string
  short_url: string
  category: string
}

const createCampaignLink = async (params: CreateCampaignLinkParams) => {
  const response = await PoshApi.createCampaignLink(params)
  return response.data
}

export function useCreateCampaignLink() {
  return useMutation<unknown, unknown, CreateCampaignLinkParams>(params => createCampaignLink(params))
}
