import React from 'react'
import {DropzoneOptions, useDropzone} from 'react-dropzone'

import {ImageTypeToFileSizeLimitMb} from '@posh/model-types'
import classNames from 'classnames'
import {useUploadImage, UseUploadImageParams} from 'hooks/uploadImage/useUploadImage'
import {useUploadMultipleImages, UseUploadMultipleImagesParams} from 'hooks/uploadImage/useUploadMultipleImages'

interface FileDropzoneProps extends DropzoneOptions {
  children?: React.ReactNode | ((openPicker?: () => void) => React.ReactNode)
  className?: string
  style?: React.CSSProperties
}

export function FileDropzone(props: FileDropzoneProps) {
  const {children, className, style, ...dropzoneOptions} = props

  const isFunctionChildren = typeof children === 'function'

  const {getRootProps, getInputProps, open} = useDropzone(dropzoneOptions)

  const inputProps = getInputProps()
  const rootProps = getRootProps()

  return (
    <div {...rootProps} className={classNames(className, rootProps.className)} style={style}>
      <input {...inputProps} />
      {isFunctionChildren ? children?.(open) : children}
    </div>
  )
}

type BaseFileDropzoneProps = Omit<FileDropzoneProps, 'accept' | 'onDropAccepted'>

type WithUploadSingleImageProps = Omit<BaseFileDropzoneProps, 'multiple'> & UseUploadImageParams

/**
 * A file dropzone that uploads a single image to S3. If multiple files are dropped, only the first one will be uploaded.
 */
function WithUploadSingleImage(props: WithUploadSingleImageProps) {
  const {uploadImage, acceptedFileTypes} = useUploadImage(props)

  const onChooseFile = (files: File[]) => uploadImage(files[0])

  return <FileDropzone {...props} onDropAccepted={onChooseFile} accept={acceptedFileTypes} multiple={false} />
}

type WithUploadMultipleImagesProps = Omit<BaseFileDropzoneProps, 'multiple'> & UseUploadMultipleImagesParams

/**
 * A file dropzone that uploads one or multiple images to S3.
 */
function WithUploadMultipleImages(props: WithUploadMultipleImagesProps) {
  const {uploadMultipleImages, acceptedFileTypes} = useUploadMultipleImages(props)

  const onChooseFile = (files: File[]) => uploadMultipleImages(files)

  return <FileDropzone {...props} onDropAccepted={onChooseFile} accept={acceptedFileTypes} multiple />
}

type WithImageUploadProps = Omit<BaseFileDropzoneProps, 'multiple' | 'maxSize'> &
  (({multiple?: false} & UseUploadImageParams) | ({multiple: true} & UseUploadMultipleImagesParams))

function WithImageUpload(props: WithImageUploadProps) {
  const sizeLimitBytes = ImageTypeToFileSizeLimitMb[props.imageType] * 1024 * 1024

  if (props.multiple) {
    return <WithUploadMultipleImages {...props} maxSize={sizeLimitBytes} />
  }
  return <WithUploadSingleImage {...props} maxSize={sizeLimitBytes} />
}

FileDropzone.WithImageUpload = WithImageUpload
