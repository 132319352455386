import React, {useState} from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import {SpotifySong, useGetSpotifySongs} from 'apis/Util/useGetSpotifySongs'
import {SpotifyLogoGreen} from 'components/assets/Icons'
import {ControlledFormProps} from 'components/DynamicForm/Inputs/shared/types'
import useDebounce from 'hooks/useDebounce'

import {DropdownSearchInput} from '../DropdownSearchInput'
import {Song} from './Song'

export interface EventVisualsSpotifySearchInputProps {
  song: SpotifySong | null
  onChooseSong: (song: SpotifySong | null) => void
  accentColor: string
  lightMode: boolean
}

const SPOTIFY_LOGO_SIZE = 18
const SPOTIFY_FETCH_DEBOUNCE_TIME = 500

export function EventVisualsSpotifySearchInput(props: EventVisualsSpotifySearchInputProps) {
  const {accentColor, lightMode, onChooseSong, song} = props
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, SPOTIFY_FETCH_DEBOUNCE_TIME)

  const {data: getSongsResult, isFetching: isQueryingSongs} = useGetSpotifySongs(
    {search: debouncedSearch},
    {enabled: !!debouncedSearch, keepPreviousData: !!search},
  )

  const deselectSong = () => {
    onChooseSong(null)
  }
  return (
    <DropdownSearchInput
      accentColor={accentColor}
      lightMode={lightMode}
      item={song}
      search={search}
      setSearch={setSearch}
      isFetching={isQueryingSongs}
      icon={<SpotifyLogoGreen height={SPOTIFY_LOGO_SIZE} width={SPOTIFY_LOGO_SIZE} />}
      placeholder={'Search for a song'}
      searchedItems={getSongsResult?.songs}
      renderItem={item => <Song song={item} lightMode={lightMode} />}
      renderSelectedItem={item => <Song.Selected song={item} lightMode={lightMode} />}
      onSelectItem={onChooseSong}
      onDeselectItem={deselectSong}
    />
  )
}

function Controlled<T extends FieldValues, K extends Path<T>>(
  props: Omit<EventVisualsSpotifySearchInputProps, 'onChooseSong' | 'song'> & ControlledFormProps<T, K>,
) {
  const {name, control, rules, ...rest} = props
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field}) => (
        <EventVisualsSpotifySearchInput {...rest} song={field.value} onChooseSong={field.onChange} />
      )}
    />
  )
}

EventVisualsSpotifySearchInput.Controlled = Controlled
