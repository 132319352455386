import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetPendingApprovalsOptions = ReactQueryOptions['order']['getPendingApprovals']
type GetPendingApprovalsInput = RouterInputs['order']['getPendingApprovals']
export type GetPendingApprovalsOutput = RouterOutput['order']['getPendingApprovals']
export type IPendingApproval = GetPendingApprovalsOutput['pendingOrders'][number]

function useGetPendingApprovals(input: GetPendingApprovalsInput, opts?: GetPendingApprovalsOptions) {
  const query = trpc.order.getPendingApprovals.useQuery(input, opts)
  const pendingOrders = query.data?.pendingOrders ?? []
  return {
    ...query,
    data: {pendingOrders},
  }
}

export default useGetPendingApprovals
