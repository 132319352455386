import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type UpdatePendingApprovalOptions = ReactQueryOptions['order']['updatePendingApprovals']
export type UpdatePendingApprovalInput = RouterInputs['order']['updatePendingApprovals']
export type UpdatePendingApprovalOutput = RouterOutput['order']['updatePendingApprovals']

const useUpdatePendingApprovals = (options?: UpdatePendingApprovalOptions) => {
  const trpcClient = trpc.useContext()
  return trpc.order.updatePendingApprovals.useMutation({
    ...options,
    onSettled: (...args) => {
      options?.onSettled && options?.onSettled(...args)
      trpcClient.order.getPendingApprovals.invalidate()
    },
  })
}

export default useUpdatePendingApprovals
