import {DEFAULT_ACCOUNT_AVI} from '@posh/model-types'
import {AccountModel, AccountModelPublic} from 'apis/Accounts'
import {isEmpty, isNull, isUndefined} from 'lodash'

export default function getAvi(account?: AccountModel | AccountModelPublic | Pick<AccountModel, 'avi' | 'instagram'>) {
  if (account && !isNull(account.avi) && !isUndefined(account.avi) && !isEmpty(account.avi)) return account.avi
  else if (
    account &&
    account.instagram &&
    !isNull(account.instagram.avi) &&
    !isUndefined(account.instagram.avi) &&
    !isEmpty(account.instagram.avi)
  )
    return account.instagram.avi
  else return DEFAULT_ACCOUNT_AVI
}
