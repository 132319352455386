import React, {useMemo} from 'react'

import ChooseRoleForm from '../ChooseRoleForm'
import PermissionsEditor from '../PermissionsEditor'
import useTeamManagementModalContext from '../TeamManagementModalContext'

import '../styles.scss'

export type AddTeamManagementPanel = 'edit_permissions' | 'choose_role'

interface AddTeamMemberModalProps {
  userBeingAdded: string
  onClickAddTeamMember?: (role: string) => void
}

export default function AddTeamMemberModal(props: AddTeamMemberModalProps) {
  const {userBeingAdded, onClickAddTeamMember} = props
  const {activePanel} = useTeamManagementModalContext()

  const title = useMemo(() => {
    switch (activePanel) {
      case 'edit_permissions':
        return 'Edit Permissions'
      case 'delete_team_member':
        return 'Remove Team Member'
      default:
        return 'Select a Role'
    }
  }, [activePanel])

  return (
    <div className='AddTeamMemberModal'>
      <h4>{title}</h4>
      {activePanel === 'choose_role' ? (
        <ChooseRoleForm accountId={userBeingAdded} onClickAddTeamMember={onClickAddTeamMember} />
      ) : activePanel === 'edit_permissions' ? (
        <PermissionsEditor />
      ) : null}
    </div>
  )
}
