import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetGroupPayoutsOptions = ReactQueryOptions['groups']['getGroupPayouts']
type GetGroupPayoutsInput = RouterInputs['groups']['getGroupPayouts']
export type GetGroupPayoutsResponse = RouterOutput['groups']['getGroupPayouts']
export type StripeBankAccount = GetGroupPayoutsResponse['bankAccounts'][0]
export type GroupFormattedPayouts = GetGroupPayoutsResponse['payoutsFormatted']

export function useGetGroupPayouts(input: GetGroupPayoutsInput, options?: GetGroupPayoutsOptions) {
  return trpc.groups.getGroupPayouts.useQuery(input, options)
}

export function invalidateGroupPayouts() {
  const trpcClient = trpc.useContext()
  trpcClient.groups.getGroupPayouts.invalidate()
}
