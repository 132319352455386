import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface ResetPasswordParams {
  email: string
  token: string
  password: string
}

export async function resetPassword(params: ResetPasswordParams): Promise<boolean> {
  const response = await PoshApi.passwordReset(params)
  return response.data.success
}

export function useResetPassword() {
  return useMutation<boolean, unknown, ResetPasswordParams>(params => resetPassword(params))
}
