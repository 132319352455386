import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface DeleteEventCommentProps {
  eventId: string
  commentId: string
}

async function deleteEventComment(props: DeleteEventCommentProps) {
  const response = await PoshApi.deleteEventComment(props)
  return response.data
}

export default function useDeleteEventComment() {
  return useMutation((props: DeleteEventCommentProps) => deleteEventComment(props))
}
