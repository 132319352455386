import React, {useState} from 'react'

import {IGroupLink} from 'apis/Groups'
import {useUpdateGroupCustomLinks} from 'apis/Groups/useUpdateGroupCustomLinks'
import Button from 'components/form/Button'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'
import {AddButton} from 'pages/EventManagementPages/TeamMembers/EditorHelpers'

import AddNewLink from '../AddNewLink'
import LinkRow from '../LinkRow'

import './styles.scss'

interface CustomLinksProps {
  groupLinks: IGroupLink[]
  groupId: string
}

const CustomLinks = (props: CustomLinksProps) => {
  const {groupLinks, groupId} = props
  const [isAddingLink, setIsAddingLink] = useState(false)
  const {showToast} = useToast()

  const {mutateAsync: updateGroupCustomLinks} = useUpdateGroupCustomLinks({
    onError: error => {
      showToast({type: 'error', title: 'Something went wrong updating your custom links', subtitle: error.message})
    },
  })

  const addCustomLink = async (customLink: IGroupLink) => {
    await updateGroupCustomLinks({groupId, update: {action: 'create', ...customLink}})
    setIsAddingLink(false)
  }

  const removeCustomLink = (index: number) => updateGroupCustomLinks({groupId, update: {action: 'delete', index}})

  const updateCustomLink = (index: number, customLink: IGroupLink) =>
    updateGroupCustomLinks({groupId, update: {action: 'update', index, ...customLink}})

  return (
    <>
      <div className='CustomLinks'>
        {groupLinks.map((link, index) => (
          <LinkRow
            key={link.url}
            link={link}
            onUpdate={updatedCustomLink => updateCustomLink(index, updatedCustomLink)}
            onDelete={() => removeCustomLink(index)}
          />
        ))}
        {isAddingLink && (
          <AddNewLink onAdd={customLink => addCustomLink(customLink)} onCancel={() => setIsAddingLink(false)} />
        )}
      </div>
      {!isAddingLink && (
        <>
          {isEmpty(groupLinks) ? (
            <Button onClick={() => setIsAddingLink(true)} className='AddLinkBtn'>
              + Add Links To Your Profile
            </Button>
          ) : (
            <AddButton onClick={() => setIsAddingLink(true)} />
          )}
        </>
      )}
    </>
  )
}
export default CustomLinks
