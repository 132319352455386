import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {useFetchEventByUrl} from 'apis/Events/useFetchEvent'
import {useFetchShouldDisplayProfilePictureModal} from 'apis/Events/useFetchShouldDisplayProfilePictureModal'
import {POSH_PIXEL_ID, trackPurchaseSubtotal, trackPurchaseSuccess} from 'apis/FbPixelHelpers'
import useFetchEventKickbackAmount from 'apis/Kickback/useFetchEventKickbackAmount'
import {useMixpanel} from 'apis/MixPanelHandler'
import {OrderReceiptResponse} from 'apis/Orders'
import {useFetchOrderReceipt} from 'apis/Orders/useFetchOrderReceipt'
import {useFetchOrderReceiptByCartId} from 'apis/Orders/useFetchOrderReceiptByCartId'
import getEventFlyer from 'apis/Util/getEventFlyer'
import {KickbackIcon} from 'components/assets/KickbackIcon'
import {FeatureFlag} from 'components/FeatureFlag'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import AddSocialsModal from 'components/modals/Modal/AddSocialsModal'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'
import useCartContext from 'domains/Cart/CartContext'
import AddToCalendarModal from 'pages/EventPage/AddToCalendarModal'
import EditProfilePictureModal from 'pages/EventPage/EditProfilePictureModal'
import PoshMobileNav from 'pages/PoshMobileNav'

import useSessionContext from '../../domains/Auth/SessionContext'
import {useQueryParams} from '../../hooks/QueryParams/useQueryParams'
import {AddToWallet} from './AddToWallet'
import {KickbackCard} from './EnableKickbackCard'
import KickbackModal from './KickbackElements'
import OrderSummary from './OrderSummary'
import {CalendarSvg, PersonSvg, QRCodeSvg, ZoomSvg} from './SVGs/svgs'
import Ticket from './Ticket'

import './styles.scss'

interface ReceiptPageProps {
  compOrder: boolean
}

interface ReceiptContentProps {
  receipt: OrderReceiptResponse
  isPostCheckout?: boolean
}

interface ReceiptBodyProps {
  setReceiptShowing: React.Dispatch<React.SetStateAction<boolean>>
  setIsKickbackModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  accentColor: string
  eventUrl: string
  receipt: OrderReceiptResponse
}

const ReceiptBody = (props: ReceiptBodyProps) => {
  const {setReceiptShowing, setIsKickbackModalOpen, accentColor, eventUrl, receipt} = props
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {resetCart} = useCartContext()
  const handleOnClickGuestlist = () => {
    trackMixpanelEvent('View Event Page-  Receipt Page')
    resetCart()
    navigate(`/e/${eventUrl}`)
  }

  const orderId = receipt.order._id
  return (
    <>
      <div className='ReceiptPage-body' style={{borderTop: `2px solid ${accentColor}`}}>
        <PoshImage
          src='https://images.posh.vip/b2/cancel.svg'
          className='ReceiptPage-x'
          onClick={() => setReceiptShowing(false)}
        />
        <KickbackCard eventId={receipt.event.id} onClick={() => setIsKickbackModalOpen(true)} />
        {receipt?.event.confirmationMessage && <h2>{receipt?.event.confirmationMessage}</h2>}
        <p style={{textAlign: 'center'}}>We sent a copy of this receipt to {receipt?.order.accountEmail}</p>
        <h3 className='ReceiptPage-orderNumber'>Order #{receipt?.order.number}</h3>

        {receipt && orderId && <OrderSummary order={receipt.order} orderId={orderId} />}

        <Button className='Button--dark' onClick={handleOnClickGuestlist}>
          View Event Page
        </Button>
      </div>
    </>
  )
}

const ReceiptContent = (props: ReceiptContentProps) => {
  const {receipt} = props
  const orderId = receipt?.order?._id.toString()
  const {currentUser, userId} = useSessionContext()
  const [zoomOnQr, setZoomOnQr] = useState(false)
  const [receiptShowing, setReceiptShowing] = useState(false)
  const [addCalendarModalOpen, setAddCalendarModalOpen] = useState(false)
  const [isKickbackModalOpen, setIsKickbackModalOpen] = useState(false)
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {data: kickbackAmount, isFetching: isFetchingKickbackAmount} = useFetchEventKickbackAmount(
    {
      eventId: receipt?.event.id ?? '',
    },
    {
      enabled: !!receipt?.event.id,
    },
  )
  const [shouldShowAddProfilePicture, setShouldShowAddProfilePicture] = useState(false)
  const navigate = useNavigate()
  const colorModeModifier = receipt?.event.lightmode ? 'is-lightMode' : 'is-darkMode'
  const {resetCart} = useCartContext()
  const {data: shouldDisplayProfilePictureModal} = useFetchShouldDisplayProfilePictureModal({
    eventId: receipt?.event.id,
    userId: userId,
  })

  const kickbackEnabled = !!kickbackAmount && !isFetchingKickbackAmount

  const handleChatWithGuestsRedirect = () => {
    resetCart()
    if (shouldDisplayProfilePictureModal && !currentUser?.avi) {
      setShouldShowAddProfilePicture(true)
      return
    } else navigate(`/e/${receipt.event.url}#activity`)
  }

  useEffect(() => {
    if (receipt && !isFetchingKickbackAmount) {
      trackMixpanelEvent('Receipt Page View- Attendee Receipt Page', {kickbackEnabled})
    }
  }, [kickbackEnabled, isFetchingKickbackAmount, receipt])

  useEffect(() => {
    // only want to run these trackings if we got to receipt from a checkout flow
    if (!props.isPostCheckout) return

    try {
      const pixelData = {
        eventName: receipt?.event.name,
        currency: receipt?.order.currency ?? 'USD',
        ticketCount: receipt?.order.tickets.length,
      }

      // a pixel id set on the event is one used by the organizer directly
      if (receipt.event.fbPixelId) {
        trackPurchaseSuccess({pixelId: receipt?.event.fbPixelId, value: receipt?.order.subtotal, ...pixelData})
      }

      trackPurchaseSuccess({
        pixelId: POSH_PIXEL_ID,
        value: receipt.order.feeBreakdown?.find(f => f.name === 'Processing Fee')?.totalFee ?? 0,
        ...pixelData,
      })
      trackPurchaseSubtotal({pixelId: POSH_PIXEL_ID, value: receipt?.order.subtotal, ...pixelData})

      // Push Data to google tag manager datalayer
      if (window?.dataLayer) {
        window?.dataLayer?.push({
          event: 'purchase',
          orderId: receipt?.order?._id.toString(),
          eventId: receipt?.event?.id,
          eventName: receipt?.event?.name,
          currency: receipt?.order?.currency ?? 'USD',
          subtotal: receipt?.order?.subtotal,
          ticketCount: receipt?.order?.tickets?.length,
          tickets: receipt?.order?.tickets,
          purchaserEmail: receipt?.order?.accountEmail,
          purchaserName: receipt?.order?.accountName,
          purchaserId: receipt?.order?.accountID,
        })
      }
    } catch (error) {
      console.error('Error tracking purchase success: ', error)
    }
  }, [])

  const getBackgroudColor = () => {
    return colorModeModifier == 'is-darkMode'
      ? `linear-gradient(-45deg, #0a0b0d, ${receipt?.event.accentColor}23, #161719, #0a0b0d, ${receipt?.event.accentColor}3e, #0a0b0d, #262729, ${receipt?.event.accentColor}0e, #0a0b0d) no-repeat center center / 500% 500%`
      : `linear-gradient(-45deg, #fafbfd, ${receipt?.event.accentColor}23, #f6f7f9, #fafbfd, ${receipt?.event.accentColor}5e, #fafbfd, #f6f7f9, ${receipt?.event.accentColor}0e, #fafbfd) no-repeat center center / 500% 500%`
  }

  const onClickUploadImage = () => {
    trackMixpanelEvent('Upload Image- Add Profile Pic Receipt Modal')
  }

  const onClickNoThanks = () => {
    trackMixpanelEvent('No Thanks- Add Profile Pic Receipt Modal')
  }

  const onClickCreateYourLink = () => {
    trackMixpanelEvent('Create Your Link- Receipt Page Kickback Modal')
  }

  const onClickNavigateToKickbackDashboard = () => {
    trackMixpanelEvent('Navigate to Kickback Dashboard- Receipt Page Kickback Modal')
  }

  return (
    <>
      <div className='ReceiptPage'>
        <div
          className='TicketCont'
          style={{
            background: getBackgroudColor(),
          }}>
          <div className='TicketCont-in' style={{color: receipt?.event.accentColor}}>
            <span style={{WebkitTextStroke: `2px ${receipt?.event.accentColor}`}}>
              {receipt?.event.isRSVPEvent ? 'YOU' : `TICKET${receipt?.order.tickets.length > 1 ? 'S' : ''}`}
            </span>
            <br />
            {receipt?.event.isRSVPEvent ? `RSVP'D` : 'SECURED'}
            {kickbackEnabled && (
              <Button
                onClick={() => {
                  trackMixpanelEvent('Kickback Icon-  Ticket Receipt Page')
                  setIsKickbackModalOpen(true)
                }}
                className='KickbackButtonNewCTA'
                style={{
                  border: `1px ${receipt?.event.accentColor} solid`,
                  color: receipt?.event.accentColor,
                  backgroundColor: receipt?.event.lightmode ? 'white' : 'black',
                }}>
                <KickbackIcon size={30} primaryColor={receipt.event.accentColor} secondaryColor='black' />
                {kickbackAmount}
              </Button>
            )}
          </div>
          <div className='TicketCont-holder-animWrap'>
            <div className={`TicketCont-holder ${zoomOnQr ? 'zoomed' : ''}`}>
              {currentUser && orderId && (
                <Ticket
                  accentColor={receipt.event.accentColor}
                  flyer={getEventFlyer(receipt.event)}
                  name={`${currentUser.firstName} ${currentUser?.lastName}`}
                  orderNumber={receipt.order.number}
                  orderId={orderId}
                  zoomOnQr={zoomOnQr}
                  eventName={receipt.event.name}
                  lightMode={receipt.event.lightmode ?? false}
                />
              )}
            </div>
          </div>
        </div>

        <div className={`ReceiptPage-actions ${receipt.event.lightmode ? 'lightmode' : 'darkmode'}`}>
          <a
            className='ReceiptPage-actions-appStore'
            href='https://apps.apple.com/us/app/posh-live-experiences/id1556928106?itsct=apps_box_badge&amp;itscg=30200'>
            <PoshImage
              src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/${
                receipt.event.lightmode ? 'white' : 'black'
              }/en-us?size=250x83&amp;releaseDate=1617148800`}
              alt='Download on the App Store'
            />
          </a>
          <div className='ReceiptPage-actions-top'>
            <div
              style={{border: `1px solid ${receipt.event.accentColor}`}}
              onClick={() => {
                trackMixpanelEvent('Show Receipt-  Ticket Receipt Page')
                setReceiptShowing(!receiptShowing)
              }}>
              <QRCodeSvg accentColor={receipt.event.accentColor} />
            </div>
            <div
              style={{border: `1px solid ${receipt.event.accentColor}`}}
              onClick={() => {
                trackMixpanelEvent('Zoom QR-  Ticket Receipt Page')
                setZoomOnQr(!zoomOnQr)
              }}>
              <ZoomSvg accentColor={receipt.event.accentColor} />
            </div>
            <AddToWallet
              orderId={orderId ?? ''}
              accentColor={receipt.event.accentColor}
              mixpanelCallback={() => {
                trackMixpanelEvent('View and chat with guests-  Ticket Receipt Page')
              }}
            />
            <div
              style={{border: `1px solid ${receipt.event.accentColor}`}}
              onClick={() => {
                trackMixpanelEvent('Add to Calendar-  Ticket Receipt Page')
                setAddCalendarModalOpen(true)
              }}>
              <CalendarSvg accentColor={receipt.event.accentColor} />
            </div>
          </div>
          {(receipt.event.activityEnabled ?? true) && (
            <button
              onClick={() => {
                trackMixpanelEvent('View and chat with guests-  Ticket Receipt Page')
                handleChatWithGuestsRedirect()
              }}
              style={{border: `1px solid ${receipt.event.accentColor}`, color: receipt.event.accentColor}}
              className={`ReceiptPage-actions-chatIconLight ${receipt.event.lightmode ? 'lightmode' : 'darkmode'}`}>
              <PersonSvg accentColor={receipt.event.accentColor} />
              VIEW &amp; CHAT WITH GUESTS!
            </button>
          )}
        </div>
        {receiptShowing && (
          <ReceiptBody
            receipt={receipt}
            accentColor={receipt.event.accentColor}
            eventUrl={receipt.event.url}
            setIsKickbackModalOpen={setIsKickbackModalOpen}
            setReceiptShowing={setReceiptShowing}
          />
        )}
      </div>

      {receipt.event.id && kickbackAmount && (
        <KickbackModal
          isOpen={isKickbackModalOpen}
          onClose={() => {
            trackMixpanelEvent('Open kickback modal-  Ticket Receipt Page')
            setIsKickbackModalOpen(false)
          }}
          eventId={receipt?.event.id}
          shouldShowButton={true}
          kickbackAmount={kickbackAmount}
          onClickCreateYourLink={onClickCreateYourLink}
          onClickNavigateToKickbackDashboard={onClickNavigateToKickbackDashboard}
          isAttendee={true}
        />
      )}
      {receipt.event && receipt.event.geniusMonkeyId && (
        <PoshImage
          src={`https://pm.geniusmonkey.com/gm.png?id=226596511&quantity=${receipt.order.tickets.length}&total_price=${receipt.order.total}&order_id=${orderId}`}
          style={{display: 'none'}}
        />
      )}
      <EditProfilePictureModal
        redirectTo={`/e/${receipt.event.url}#activity`}
        isOpen={shouldShowAddProfilePicture}
        onClose={() => setShouldShowAddProfilePicture(false)}
        onClickUploadImage={onClickUploadImage}
        onClickNoThanks={onClickNoThanks}
      />
      <AddToCalendarModal
        event={receipt.event}
        addCalendarModalOpen={addCalendarModalOpen}
        setAddCalendarModalOpen={setAddCalendarModalOpen}
      />
      <FeatureFlag.DBBacked featureName='spark'>
        <AddSocialsModal eventId={receipt.event.id} />
      </FeatureFlag.DBBacked>

      <table className='order-info-for-fb' style={{display: 'none'}}>
        <tr>
          <td>Order #</td>
          <td id='ordernumber'>{receipt?.order.number}</td>
        </tr>
        <tr>
          <td>Subtotal</td>
          <td id='subtotal'>{receipt?.order.subtotal}</td>
        </tr>
        <tr>
          <td>Discount</td>
          <td id='discount'>{receipt?.order.discount ?? 0}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td id='total'>{receipt?.order.total}</td>
        </tr>
      </table>
      <table className='tickets-info-for-fb' style={{display: 'none'}}>
        {receipt?.order.tickets.map((ticket, index) => (
          <tr key={ticket.name}>
            <td id={`ticket-${index + 1}`} className='ticket-type-name'>
              {ticket.name}
            </td>
            <td className='ticket-type-price'>{ticket.price}</td>
          </tr>
        ))}
      </table>
    </>
  )
}

const OrderReceiptPage = () => {
  const {cartId} = useParams()
  const {queryParams} = useQueryParams()
  const {data: receipt} = useFetchOrderReceiptByCartId(cartId ?? '')
  const navigate = useNavigate()

  useEffect(() => {
    if (receipt && queryParams.get('checkout')) {
      navigate({
        pathname: `/receipt/${cartId}`,
        search:
          '?' +
          new URLSearchParams({
            order: receipt.order.number?.toString() ?? '',
            subtotal: receipt.order.subtotal.toString() ?? '',
            total: receipt.order.total.toString() ?? '',
            eventId: receipt.event.id.toString(),
          }).toString(),
      })
    }
  }, [receipt, queryParams])

  return (
    <>
      {receipt ? (
        <>
          <ReceiptContent receipt={receipt} isPostCheckout={!!queryParams.get('checkout')} />
          <PoshMobileNav />
        </>
      ) : (
        <div className='ReceiptPage'>
          <div className='ReceiptPage-loading'>
            <SpinLoader height={100} />
          </div>
        </div>
      )}
    </>
  )
}

const CompReceiptPage = () => {
  const {orderId} = useParams()
  const {data: receipt} = useFetchOrderReceipt(orderId ?? '')
  return (
    <>
      {receipt ? (
        <ReceiptContent receipt={receipt} />
      ) : (
        <div className='ReceiptPage'>
          <div className='ReceiptPage-loading'>
            <SpinLoader height={100} />
          </div>
        </div>
      )}
    </>
  )
}

export default function ReceiptPage(props: ReceiptPageProps) {
  const {compOrder} = props
  return <>{compOrder ? <CompReceiptPage /> : <OrderReceiptPage />}</>
}

export function DisabledReceiptPage() {
  const navigate = useNavigate()
  const {eventUrl} = useParams()
  const {data: event, isFetching} = useFetchEventByUrl(eventUrl)
  const {resetCart} = useCartContext()
  const {currentUser} = useSessionContext()

  const handleOnClickBackToEventPage = () => {
    resetCart()
    navigate(`/e/${eventUrl}`)
  }

  const ReceiptContent = () => (
    <>
      <div>
        <h4>
          {currentUser?.firstName}, you&apos;ve requested to attend {event?.event.name}!
        </h4>
        <p>We&apos;ll notify you if you&apos;re approved.</p>
        <Button
          className='Button--dark'
          onClick={handleOnClickBackToEventPage}
          style={{width: '200px', margin: '20px auto 0'}}>
          Back to Event Page
        </Button>
      </div>
    </>
  )

  return (
    <>
      <div className='ReceiptPage'>
        {isFetching ? (
          <div className='ReceiptPage-loading'>
            Loading...
            <SpinLoader height={30} />
          </div>
        ) : (
          <>
            <div className='ReceiptPage-card'>
              <ReceiptContent />
            </div>
            <div className='ReceiptPage-flyer'>
              <PoshImageBackground
                className='ReceiptPage-flyer-inner'
                style={{backgroundImage: `url(${getEventFlyer(event?.event)})`}}></PoshImageBackground>
              <PoshImage src={getEventFlyer(event?.event)} />
            </div>
          </>
        )}
      </div>
    </>
  )
}
