import React, {useEffect, useState} from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import {ControlledFormProps} from '../shared/types'
import {TextInput, TextInputProps} from '../TextInput/TextInput'

export interface NumberInputProps extends Omit<TextInputProps, 'value' | 'onChange' | 'step' | 'type' | 'ref'> {
  // strangely, adding `type='number'` here allows you to enter letters...
  value: number | undefined
  onChange: (value: number) => void
  decimalPlaces?: number
}

export const NumberInput = (props: NumberInputProps) => {
  const {value, onChange, decimalPlaces = 2, ...rest} = props

  const [textValue, setTextValue] = useState<string>(value ? value.toFixed(decimalPlaces) : '')

  useEffect(() => {
    if (textValue === value?.toFixed(decimalPlaces)) return
    setTextValue(value ? value.toString() : '')
  }, [value])

  const onChangeText = (newValue: string) => {
    if (newValue === '' || /^-?\d*\.?\d*$/.test(newValue)) {
      const numDecimalPlaces = newValue.split('.')[1]?.length ?? 0
      if (numDecimalPlaces > decimalPlaces) {
        return
      }
      setTextValue(newValue)
      const numericValue = parseFloat(newValue)
      if (!isNaN(numericValue)) {
        onChange(parseFloat(numericValue.toFixed(decimalPlaces)))
      } else if (newValue === '') {
        onChange(0)
      }
    }
  }

  const onBlur = () => {
    if (value && decimalPlaces > 0 && textValue === value.toString()) {
      setTextValue(value.toFixed(decimalPlaces))
    }
  }

  return (
    <TextInput
      {...rest}
      inputMode='numeric'
      pattern='^-?[0-9]\d*(\.\d+)?$'
      value={textValue}
      onChange={onChangeText}
      onBlur={onBlur}
    />
  )
}

interface ControlledNumberInputProps<T extends FieldValues, K extends Path<T>>
  extends Omit<NumberInputProps, 'value' | 'onChange' | 'name'>,
    ControlledFormProps<T, K> {}

const ControlledNumberInput = <T extends FieldValues, K extends Path<T>>(props: ControlledNumberInputProps<T, K>) => {
  const {control, name, rules, ...numberInputProps} = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState}) => {
        return <NumberInput {...field} error={fieldState.error?.message} {...numberInputProps} />
      }}
    />
  )
}

NumberInput.Controlled = ControlledNumberInput
