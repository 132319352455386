import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {CustomCheckoutFieldFileResponse} from 'apis/Checkout/useFetchCheckoutSummary'
import {useDeleteCustomCheckoutFieldFile} from 'apis/CustomCheckoutFields/useDeleteCustomCheckoutFieldFile'
import {useFetchCustomCheckoutFieldFile} from 'apis/CustomCheckoutFields/useFetchCustomCheckoutFieldFile'
import {useUploadCustomCheckoutFieldFile} from 'apis/CustomCheckoutFields/useUploadCustomCheckoutFieldFile'
import FileUpload from 'components/FileUpload'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

export type FileUploadInputCheckoutProps = {
  field_key: string
  value?: string
  field_type: 'file_upload'
}

type FileBase64 = {encoding?: string; name: string}

export const FileUploadInputCheckout = (props: FileUploadInputCheckoutProps) => {
  const [defaultFileBase64, setDefaultFileBase64] = useState<FileBase64 | undefined>(undefined)
  const {checkoutResponses, updateCheckoutResponses, cartId} = useLiveCartContext()
  const updateFileCheckoutResponse = (fileKey: string) => {
    let keyOfUpdatedFile = fileKey
    const newCheckoutResponses = checkoutResponses?.map(checkoutField => {
      const isFileUploadType = checkoutField.type === 'file_upload'
      const isSameFieldId = checkoutField.fieldId.toString() === props.field_key
      if (!isFileUploadType || !isSameFieldId) {
        return checkoutField
      }

      const isDeletingFile = fileKey === ''
      if (isDeletingFile) keyOfUpdatedFile = checkoutField.url
      const updatedCheckoutResponse: CustomCheckoutFieldFileResponse = {
        ...checkoutField,
        url: fileKey,
      }
      return updatedCheckoutResponse
    })
    updateCheckoutResponses(newCheckoutResponses)
    setDefaultFileBase64(undefined)
    return keyOfUpdatedFile
  }

  const {eventUrl, hexUrl} = useParams()
  const {mutate: fetchCheckoutFieldFile} = useFetchCustomCheckoutFieldFile({
    onSuccess: fileBase64 => setDefaultFileBase64({encoding: fileBase64.base64File, name: fileBase64.fileName}),
  })
  const {mutate: uploadFile, isLoading: isUploadingFile} = useUploadCustomCheckoutFieldFile({
    onSuccess: fileKey => updateFileCheckoutResponse(fileKey),
  })
  const {mutate: removeFile} = useDeleteCustomCheckoutFieldFile()

  useEffect(() => {
    if (!props.value) return
    fetchCheckoutFieldFile({key: props.value})
  }, [])

  const onFileUploaded = (fileName: string, base64File: string) => {
    if (!cartId) return
    if (!eventUrl && !hexUrl) return
    uploadFile({cartId, base64File, fileName, eventUrl: (eventUrl || hexUrl) as string})
  }

  const onFileRemoved = () => {
    const fileKey = updateFileCheckoutResponse('')
    removeFile({key: fileKey})
  }
  return (
    <FileUpload
      onFileUploaded={onFileUploaded}
      onFileRemoved={onFileRemoved}
      defaultFileBase64={defaultFileBase64?.encoding}
      defaultFileName={defaultFileBase64?.name}
      disableRemoveButton={isUploadingFile}
    />
  )
}
