import React from 'react'
import {FieldValues, Path, useController} from 'react-hook-form'

import {EventParentCategory, EventParentCategorySubcategories} from '@posh/model-types'
import {WithCategorySelectDropdown} from 'components/Dropdown/CategorizationDropdown'
import {ControlledFormProps} from 'components/DynamicForm/Inputs/shared/types'

import {EventVisualsTextInputWrapper} from '../../Input/TextInput/EventVisualsTextInputWrapper'

interface EventVisualsCategorySelectProps<T extends EventParentCategory> {
  parentCategory?: EventParentCategory
  subCategory?: EventParentCategorySubcategories<T>[number]
  onSelect: (params: {
    parentCategory: EventParentCategory
    subCategory: EventParentCategorySubcategories<T>[number]
  }) => void
  accentColor: string
  lightMode: boolean
  placeholder?: string
}

function formatter<T extends EventParentCategory>(
  parentCategory?: T,
  subCategory?: EventParentCategorySubcategories<T>[number],
) {
  if (!parentCategory || !subCategory) return ''
  return `${parentCategory} - ${subCategory}`
}

export function EventVisualsCategorySelect<T extends EventParentCategory>(props: EventVisualsCategorySelectProps<T>) {
  const {
    parentCategory,
    subCategory,
    onSelect,
    accentColor,
    lightMode,
    placeholder = 'Choose a Category (optional)',
  } = props
  const textColor = parentCategory && subCategory ? accentColor : '#909193'
  const text = formatter(parentCategory, subCategory) || placeholder

  return (
    <WithCategorySelectDropdown
      menuButton={
        <div style={{cursor: 'pointer'}}>
          <EventVisualsTextInputWrapper.Row accentColor={accentColor} lightMode={lightMode}>
            <input className='noMargin' style={{color: textColor, fontSize: 16, cursor: 'pointer'}} value={text} />
          </EventVisualsTextInputWrapper.Row>
        </div>
      }
      onChooseCategory={onSelect}
    />
  )
}

interface ControlledProps<T extends EventParentCategory, U extends FieldValues, V extends Path<U>>
  extends Omit<EventVisualsCategorySelectProps<T>, 'parentCategory' | 'subCategory' | 'onSelect'>,
    Omit<ControlledFormProps<U, V>, 'name'> {
  parentCategoryFormName: Path<U>
  subCategoryFormName: Path<U>
}

function Controlled<T extends EventParentCategory, U extends FieldValues, V extends Path<U>>(
  props: ControlledProps<T, U, V>,
) {
  const {parentCategoryFormName, subCategoryFormName, control} = props
  const {
    field: {value: parentCategory, onChange: setParentCategory},
  } = useController({control, name: parentCategoryFormName})
  const {
    field: {value: subCategory, onChange: setSubCategory},
  } = useController({control, name: subCategoryFormName})

  const onSelect: EventVisualsCategorySelectProps<T>['onSelect'] = ({parentCategory, subCategory}) => {
    setParentCategory(parentCategory)
    setSubCategory(subCategory)
  }

  return (
    <EventVisualsCategorySelect
      {...props}
      onSelect={onSelect}
      parentCategory={parentCategory}
      subCategory={subCategory}
    />
  )
}

EventVisualsCategorySelect.Controlled = Controlled
