import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {BookingRequestWithBookerInfo, CommunityCategory} from '.'

export interface FetchBookingRequestsParams {
  communityId?: string
  communityCategory?: CommunityCategory
}
export interface BookingRequestResponse {
  pending: BookingRequestWithBookerInfo[]
  accepted: BookingRequestWithBookerInfo[]
  denied: BookingRequestWithBookerInfo[]
  past: BookingRequestWithBookerInfo[]
  total: number
  totalAccepted: number
}

async function fetchBookingRequests(params: FetchBookingRequestsParams): Promise<BookingRequestResponse> {
  const response = await PoshApi.fetchBookingRequests(params)
  return response.data
}

export default function useFetchBookingRequests(params: FetchBookingRequestsParams) {
  return useQuery(['fetchBookingRequests', params], () => fetchBookingRequests(params), {
    enabled: !!params.communityId,
    refetchOnMount: false,
  })
}
