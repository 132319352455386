import React from 'react'

import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'

import competitorMap from './competitorMap'

import './styles.scss'

const CompareSection = () => {
  return (
    <div className='LandingPage-section-inner compareSection center'>
      <h2>How We Compare</h2>

      <div className='CsCompetitors'>
        {competitorMap.map(competitor => (
          <div
            key={competitor.name}
            className={classNames('CsCompetitors-comp', {
              posh: competitor.name === 'POSH',
            })}>
            <div className='CsCompetitors-comp-logo'>
              <PoshImage src={competitor.logo} alt={competitor.name} />
            </div>
            {competitor.features.map(feature => (
              <div className='CsCompetitors-comp-feature' key={feature.name}>
                <PoshImage
                  src={
                    feature.hasFeature
                      ? 'https://images.posh.vip/b2/yes-feature.svg'
                      : 'https://images.posh.vip/b2/no-feature.svg'
                  }
                />
                <p style={{color: feature.hasFeature ? '#A8FFA6' : '#fff'}}>{feature.name}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CompareSection
