import React from 'react'
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd'

import {CurrencyCode} from '@posh/types'
import {EventTicketAndTicketSold} from 'apis/Events/types'
import {useDimensions} from 'hooks/useDimensions'

import TicketList from './ticketsList'

interface SortableTicketListProps {
  tickets: EventTicketAndTicketSold[]
  setTickets: (tickets: EventTicketAndTicketSold[]) => void
  currency?: CurrencyCode
  isEditingDisabled?: boolean
  timezone?: string
}

export default function SortableTicketList(props: SortableTicketListProps) {
  const {tickets, setTickets, currency, isEditingDisabled, timezone} = props

  const reorder = (startIndex: number, endIndex: number) => {
    const result = tickets
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    const reorderedTickets = reorder(result.source.index, result.destination.index)
    setTickets(reorderedTickets)
  }
  const {isMobile} = useDimensions()

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='list'>
          {provided => (
            <table ref={provided.innerRef} {...provided.droppableProps} className='TicketTable'>
              <tbody>
                <tr>
                  <th style={{width: '100px'}}>Name</th>
                  {!isMobile && <th>Status</th>}
                  {!isMobile && <th>Price</th>}
                  <th>Sold</th>
                  {!isMobile && <th>Open Sale</th>}
                  {!isMobile && <th>End Sale</th>}
                  <th />
                </tr>
                <TicketList
                  tickets={tickets}
                  currency={currency}
                  isEditingDisabled={isEditingDisabled}
                  timezone={timezone}
                />
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}
