import React from 'react'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'

import {CRUDTableMap, CRUDTableMapObj} from '.'

interface CRUDCreateInputProps {
  obj: CRUDTableMapObj
  updateDocument: (key: string, value: any) => void
}

const CRUDCreateInput = (props: CRUDCreateInputProps) => {
  const {obj, updateDocument} = props

  if (obj.type === 'string' || obj.type === 'url') {
    return <Input placeholder={obj.label} onChange={e => updateDocument(obj.key, e.target.value)} />
  }

  if (obj.type === 'select') {
    return (
      <Select onChange={e => updateDocument(obj.key, e.target.value)}>
        <option selected={true} disabled={true} hidden={true}>{`Select ${obj.label}...`}</option>
        {obj.options?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Select>
    )
  }

  return <></>
}

interface CRUDCreateModalProps extends PoshStyledModalProps {
  refetchDataFunction: any
  entityName: string
  map: CRUDTableMap
  createFunction: any
  isCreateFunctionLoading?: boolean
}

const CRUDCreateModal = (props: CRUDCreateModalProps) => {
  const {refetchDataFunction, entityName, map, isOpen, onClose, createFunction, isCreateFunctionLoading} = props

  const [newDocument, setNewDocument] = React.useState({})
  const updateDocument = (key: string, value: any) => {
    setNewDocument({...newDocument, [key]: value})
  }

  const onCloseModal = () => {
    setNewDocument({})
    onClose()
  }

  const handleCreate = async () => {
    await createFunction({item: newDocument})
    refetchDataFunction()
    onCloseModal()
  }

  return (
    <PoshStyledModal onClose={onCloseModal} isOpen={isOpen} center className='CRUDCreateModal'>
      <h3>Create {entityName}</h3>
      <div className='CRUDCreateModal-form'>
        {map.map(
          (obj, index) => obj.editable && <CRUDCreateInput key={index} obj={obj} updateDocument={updateDocument} />,
        )}
        <Button onClick={() => handleCreate()}>+ Create {entityName}</Button>
      </div>
    </PoshStyledModal>
  )
}

export default CRUDCreateModal
