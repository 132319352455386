import React, {useEffect} from 'react'
import {DefaultValues, FieldValues, useForm, UseFormReturn} from 'react-hook-form'

import {zodResolver} from '@hookform/resolvers/zod'
import Button from 'components/form/Button'
import {ZodSchema} from 'zod'

import {PersistToStorageKey, usePersistFormState} from '../internals/usePersistFormState'

import styles from './FormBase.module.scss'

/**
 * FormBase is a component that wraps a form and provides a common layout for forms.
 * WARNING: Please don't include any personal information/data when using the persistToStorage feature.
 *
 * @param children A function that receives the form object and returns the form elements.
 * @param defaultValues The default values for the form.
 * @param schema The schema for the form.
 * @param onSubmit The function to call when the form is submitted.
 * @param onCancel The function to call when the form is canceled.
 * @param isSubmitting Whether the form is submitting.
 * @param submitText The text to display on the submit button.
 * @param warnUnsavedChanges Whether to warn the user if they try to close the tab or refresh the tab with unsaved changes.
 * @param persistToStorage An object containing the key and storage to persist the form to.
 */
export interface FormBaseProps<T extends FieldValues> {
  children: (form: UseFormReturn<T>) => JSX.Element
  defaultValues?: DefaultValues<T>
  schema: ZodSchema<T>
  onSubmit: (data: T) => void
  onCancel: () => void
  isSubmitting?: boolean
  submitText?: string
  warnUnsavedChanges?: boolean
  persistToStorage?: {
    key: PersistToStorageKey
    storage: Storage
  }
}

export const FormBase = <T extends FieldValues>(props: FormBaseProps<T>) => {
  const {
    children,
    defaultValues,
    schema,
    onSubmit,
    onCancel,
    isSubmitting = false,
    submitText = 'Submit',
    warnUnsavedChanges = true,
    persistToStorage,
  } = props

  const form = useForm<T>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: 'onTouched',
  })

  const {
    formState: {isDirty, isSubmitSuccessful, defaultValues: formDefaultValues},
    reset,
    handleSubmit,
    watch,
    setValue,
  } = form

  const {clear} = usePersistFormState(watch, setValue, persistToStorage)

  const onClickCancel = () => {
    clear()
    reset()
    onCancel()
  }

  // This effect will warn the user if they try to close the tab or try to refresh the tab.
  // It doesn't work when using the back button or any navigation that doesn't involve closing or refreshing the tab.
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty && warnUnsavedChanges) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [warnUnsavedChanges, isDirty])

  useEffect(() => {
    if (formDefaultValues !== defaultValues) {
      reset(defaultValues, {keepValues: true, keepDirty: false, keepDefaultValues: false})
    }
  }, [defaultValues, isSubmitSuccessful, reset])

  return (
    <form
      onSubmit={handleSubmit(data => {
        onSubmit(data)
        reset()
        clear()
      })}
      className={styles.Form}>
      {children(form)}
      <div className={styles.FormSubmitRow}>
        <Button className='dark' onClick={onClickCancel}>
          Cancel
        </Button>
        <Button type='submit' className='gold' disabled={!isDirty || isSubmitting} isLoading={isSubmitting}>
          {submitText}
        </Button>
      </div>
    </form>
  )
}
