import React, {PropsWithChildren} from 'react'

import {datadogRum} from '@datadog/browser-rum'

import {useAvailableFeatures} from '../../domains/AvailableFeatures/AvailableFeaturesContext'

interface BaseFeatureFlagWithBorder {
  showChildrenIf: boolean
  showBorder?: true
  text: string
}

interface BaseFeatureFlagWithoutBorder {
  showChildrenIf: boolean
  showBorder: false
  text?: never
}

export type BaseFeatureFlagProps = PropsWithChildren<BaseFeatureFlagWithBorder | BaseFeatureFlagWithoutBorder>

export const DB_BACKED_FEATURES = {
  CREATE_EVENT_UPDATED_EDITOR: 'create_event_updated_editor',
  WEB_EVENT_PLAYLIST: 'web_event_playlists',
  WEB_TICKET_VALIDITY_PERIOD: 'web_ticket_validity_period',
  SPARK: 'spark',
  WEB_NAVIGATION_REDESIGN: 'web_navigation_redesign-MANUALLY_DISABLED',
  MANAGE_EVENT_PLAYLISTS: 'manage_event_playlists',
} as const

type FeatureName = (typeof DB_BACKED_FEATURES)[keyof typeof DB_BACKED_FEATURES]
type DBBackedFeatureFlagProps = PropsWithChildren<{featureName: FeatureName}>

function BaseFeatureFlag({children, showChildrenIf, showBorder = true, ...props}: BaseFeatureFlagProps) {
  if (showChildrenIf) {
    if (showBorder) {
      return (
        <div style={{borderWidth: 1, borderColor: 'pink'}}>
          <p>{props.text}</p>
          {children}
        </div>
      )
    }
    return <>{children}</>
  }
  return <></>
}

/**
 * Gets if a feature is enabled
 * @param featureName the name of the feature flag to check for
 * @returns if the feature flag is enabled
 */
export function useIsFeatureEnabled(featureName: FeatureName) {
  const {availableFeatures, isLoading} = useAvailableFeatures()
  const isFeatureEnabled = availableFeatures?.includes(featureName) ?? false
  datadogRum.addFeatureFlagEvaluation(featureName, isFeatureEnabled)
  return {isFeatureEnabled, isLoading}
}

/**
 *
 * @param featureName the name of the feature flag to check for
 * @returns
 * A component that will only render its children if the app has the feature flag enabled (using the `availableFeatures` flag from `useFeatureFlags`)
 * the feature flag is stored in the database and can be enabled/disabled with more granular control by admins
 */
function DBBackedFeatureFlag(props: DBBackedFeatureFlagProps) {
  const {children, featureName} = props
  const {isFeatureEnabled: isFeatureEnabled} = useIsFeatureEnabled(featureName)
  return (
    <BaseFeatureFlag showChildrenIf={isFeatureEnabled} showBorder={false}>
      {children}
    </BaseFeatureFlag>
  )
}

export function FeatureFlag(props: BaseFeatureFlagProps) {
  return <BaseFeatureFlag {...props} />
}

FeatureFlag.DBBacked = DBBackedFeatureFlag
