import React, {useEffect, useRef} from 'react'
import {Control, UseFormWatch} from 'react-hook-form'

import {CustomSectionItem} from 'components/PageComponents/EventVisuals/Button/CustomSectionItem'
import {EventVisualsSection as EvenVisualSectionWrapper} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {isEmpty, isNil} from 'lodash'

import {EventVisualsSection, useEventVisualsContext} from '../../context/EventVisualsContext'
import {EventVisuals} from '../Layout'
import {EventVisualsLineupSection} from '../Lineup'
import {EventVisualsOverviewDescription} from '../OverviewDescription'
import {EventVisualsVenueImages} from '../VenueImages'
import {EventVisualsYoutubeSection} from '../YoutubeSection'

import styles from './styles.module.scss'

const SmoothBehavior = {behavior: 'smooth'} as const

interface EventVisualsCustomSectionsProps {
  control: Control<EventVisualsForm>
  watch: UseFormWatch<EventVisualsForm>
  showDescription?: boolean
}

export const EventVisualsCustomSections = (props: EventVisualsCustomSectionsProps) => {
  const {control, watch, showDescription = true} = props

  const overviewRef = useRef<HTMLDivElement>(null)
  const youtubeRef = useRef<HTMLDivElement>(null)
  const lineupRef = useRef<HTMLDivElement>(null)
  const galleryRef = useRef<HTMLDivElement>(null)

  const {currentlyAddingSection, setCurrentlyAddingSection} = useEventVisualsContext()

  useEffect(() => {
    switch (currentlyAddingSection) {
      case 'text':
        return overviewRef.current?.scrollIntoView(SmoothBehavior)
      case 'youtube':
        return youtubeRef.current?.scrollIntoView(SmoothBehavior)
      case 'lineup':
        return lineupRef.current?.scrollIntoView(SmoothBehavior)
      case 'gallery':
        return galleryRef.current?.scrollIntoView(SmoothBehavior)
    }
  }, [currentlyAddingSection])

  const youtubeLink = watch('youtubeLink')
  const description = watch('description')
  const lineup = watch('lineup')
  const gallery = watch('venue.images')

  const availableSectionsToAdd: EventVisualsSection[] = (() => {
    const sections: EventVisualsSection[] = []
    if (isNil(youtubeLink) && currentlyAddingSection !== 'youtube') sections.push('youtube')
    if (isNil(description) && currentlyAddingSection !== 'text' && showDescription) sections.push('text')
    if (!lineup || (isEmpty(lineup) && currentlyAddingSection !== 'lineup')) sections.push('lineup')
    if (!gallery || (isEmpty(gallery) && currentlyAddingSection !== 'gallery')) sections.push('gallery')
    return sections
  })()

  const hasCustomSections = availableSectionsToAdd.length !== 4

  return (
    <>
      {hasCustomSections && (
        <div className={styles.CustomSections}>
          {showDescription && (
            <div ref={overviewRef}>
              <EventVisualsOverviewDescription control={control} />
            </div>
          )}
          <div ref={youtubeRef}>
            <EventVisualsYoutubeSection control={control} />
          </div>
          <div ref={lineupRef}>
            <EventVisualsLineupSection control={control} />
          </div>
          <div ref={galleryRef}>
            <EventVisualsVenueImages control={control} />
          </div>
        </div>
      )}
      <EventVisuals.Row>
        <EvenVisualSectionWrapper className={styles.CustomSectionsList}>
          {availableSectionsToAdd.map(section => (
            <CustomSectionItem key={section} section={section} onSelectSection={setCurrentlyAddingSection} />
          ))}
        </EvenVisualSectionWrapper>
      </EventVisuals.Row>
    </>
  )
}
