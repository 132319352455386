import React from 'react'

import {PlusSolid} from 'components/assets/Icons'

import {BaseNavigationItem} from '../BaseNavigationItem'
import {DesktopBaseNavigationItemProps} from '../BaseNavigationItem/desktop'
import {useCreateEventPrimaryNavigation} from './navigate'

import styles from '../BaseNavigationItem/styles.module.scss'

export function DesktopCreateEventNavigationItem(
  props: Omit<DesktopBaseNavigationItemProps, 'body' | 'icon' | 'isSelected' | 'onClick'>,
) {
  const {navigate} = useCreateEventPrimaryNavigation()
  return (
    <BaseNavigationItem.Desktop
      body={<span>Create Event</span>}
      {...props}
      icon={<PlusSolid />}
      isSelected={false} // the Create Event button does not have a selected state
      className={styles.CreateEvent}
      onClick={navigate}
    />
  )
}
