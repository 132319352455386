import React from 'react'

const EventSection = ({
  children,
  header,
  accentColor,
  className,
}: {
  children: any
  header?: string
  accentColor: string
  className?: string
}) => {
  return (
    <>
      <div className={`EventPage-section ${className}`}>
        <div className='EventPage-section-inner'>
          {header && (
            <div className='EventPage-sectionTitle'>
              <div
                className='EventPage-sectionTitle-Polygon'
                style={{background: `linear-gradient(90deg, ${accentColor} 0%, ${accentColor}00 100%)`}}
              />
              <span className='EventPage-sectionTitle-Text' style={{color: `${accentColor}`}}>
                {header}
              </span>
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  )
}

export default EventSection
