import React from 'react'

import {CheckoutFieldNameAndId} from 'apis/Events/types'

import useAttendeesCrmContext from '../../../GroupPage/Marketing/AttendeesCrmContext'

import './styles.scss'

interface ExpandedCheckoutFieldSelectorProps {
  checkoutFieldNamesAndIds?: CheckoutFieldNameAndId[]
  closeView: () => void
}
export const ExpandedCheckoutFieldSelector = (props: ExpandedCheckoutFieldSelectorProps) => {
  const {checkoutFieldNamesAndIds, closeView} = props
  const {setCurrentCheckoutField, currentCheckoutField} = useAttendeesCrmContext()
  return (
    <div className='ExpandedCheckoutFieldSelector'>
      {checkoutFieldNamesAndIds &&
        checkoutFieldNamesAndIds.map(({name, id}, index) => (
          <>
            <div
              onClick={() => {
                setCurrentCheckoutField(index)
                closeView()
              }}
              className='ExpandedCheckoutFieldSelector-option'
              key={id}>
              {name}
            </div>
            <div className='ExpandedCheckoutFieldSelector-divider' />
          </>
        ))}
    </div>
  )
}
