import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {ITicketGroup} from './types'

export interface UpdateTicketGroupsParams {
  eventId: string
  ticketGroups: ITicketGroup[]
}

const updateTicketGroups = async (params: UpdateTicketGroupsParams) => {
  const response = await PoshApi.updateTicketGroups(params)
  return response.data
}

export function useUpdateTicketGroups(opts?: UseMutationOptions<any, any, UpdateTicketGroupsParams>) {
  return useMutation((params: UpdateTicketGroupsParams) => updateTicketGroups(params), opts)
}
