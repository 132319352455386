import {ISMSModal} from './types'
export const useSMSModal = ({setIsRecipientsModalOpen, setIsEventsModalOpen}: ISMSModal) => {
  const onCloseRecipientsModal = () => {
    setIsRecipientsModalOpen(false)
  }

  const onCloseEventsModal = () => {
    setIsEventsModalOpen(false)
  }

  return {
    onCloseRecipientsModal,
    onCloseEventsModal,
  }
}
