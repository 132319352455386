import React, {PropsWithChildren} from 'react'
import {FieldValues, Path} from 'react-hook-form'

import MomentUtils from '@date-io/moment'
import {MuiThemeProvider} from '@material-ui/core'
import {DatePickerProps, DateTimePickerProps, MuiPickersUtilsProvider, TimePickerProps} from '@material-ui/pickers'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import {ControlledFormProps} from 'components/DynamicForm/Inputs/shared/types'
import {customTheme as PoshDatePickerTheme} from 'components/form/PoshDatePicker'
import moment from 'moment'

import {EventVisualsTextInputWrapper} from '../TextInput/EventVisualsTextInputWrapper'

import styles from './styles.module.scss'

type PickerProps = DatePickerProps | TimePickerProps | DateTimePickerProps

export type EventVisualsDateInputProps<T extends PickerProps> = Omit<T, 'onChange'> & {
  accentColor: string
  lightMode: boolean
  placeholder: string
  onChange: (d: Date | null) => void
  rightIcon?: React.ReactNode
  leftIcon?: React.ReactNode
  isOutlined?: boolean
  timezone?: string
}

export type ControlledEventVisualsDateInputProps<
  T extends FieldValues,
  K extends Path<T>,
  U extends PickerProps,
> = ControlledFormProps<T, K> & Omit<EventVisualsDateInputProps<U>, 'onChange' | 'value'>

export function EventVisualsDateInputWrapper<T extends PickerProps>({
  children,
  ...props
}: PropsWithChildren<EventVisualsDateInputProps<T>>) {
  const {rightIcon, leftIcon, lightMode, accentColor, isOutlined} = props
  const lightModeClassname = lightMode ? styles.light : styles.dark

  return (
    <MuiThemeProvider theme={PoshDatePickerTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <EventVisualsTextInputWrapper.Row
          accentColor={accentColor}
          lightMode={lightMode}
          isOutlined={isOutlined}
          className={lightModeClassname}>
          {leftIcon}
          <div className={styles.inputContainer}>{children}</div>
          <div className={styles.iconContainer}>{rightIcon}</div>
        </EventVisualsTextInputWrapper.Row>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

export function getSharedPickerProps<T extends PickerProps>(props: EventVisualsDateInputProps<T>) {
  const {value: propsValue, lightMode, accentColor, ...datePickerProps} = props
  const value = propsValue ? moment(propsValue) : null // null makes the input empty

  const lightModeColor = lightMode ? 'black' : 'white'
  const color = value ? accentColor : lightModeColor
  const placeholder = props.placeholder + (props.required ? '*' : '')

  const onChange = (d: MaterialUiPickersDate) => {
    props.onChange(d ? d.toDate() : null)
  }

  return {
    value,
    InputProps: {
      disableUnderline: true,
      style: {color},
    },
    ...datePickerProps,
    placeholder,
    onChange,
  }
}
