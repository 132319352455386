import React from 'react'

import classNames from 'classnames'

import styles from './styles.module.scss'

export interface MobileBaseNavigationItemProps {
  icon: React.ReactNode
  isSelected: boolean
  onClick: () => void
  className?: string
}

export function MobileBaseNavigationItem(props: MobileBaseNavigationItemProps) {
  const {icon, isSelected, onClick} = props
  return (
    <div
      onClick={onClick}
      className={classNames([styles.Container, styles.mobile, props.className])}
      data-is-selected={isSelected}>
      {icon}
    </div>
  )
}
