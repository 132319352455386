import React from 'react'

import {SelectProps} from 'components/form/Select'

import Select from '../../../../../../../components/form/Select'

interface TicketLinkSelectProps extends SelectProps {
  options: [key: string, value: any][]
}

export const TicketLinkSelect = (props: TicketLinkSelectProps) => {
  return (
    <Select {...props}>
      {props.options.map(([key, value]) => {
        return (
          <option key={key} value={key}>
            {value}
          </option>
        )
      })}
    </Select>
  )
}
