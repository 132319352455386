import React from 'react'

import {NavigationOrgSelect} from 'pages/OwnerPage/PoshHeader/SideBar/components/OrgSelect'
import {WithDataAccountGroupUnionOrgSelectProps} from 'pages/OwnerPage/PoshHeader/SideBar/components/OrgSelect/AccountGroupUnionOrgSelect'

import styles from './styles.module.scss'

export function NavigationDropdown(props: Omit<WithDataAccountGroupUnionOrgSelectProps, 'className'>) {
  return <NavigationOrgSelect.AccountGroupUnion.WithData className={styles.Container} {...props} />
}
