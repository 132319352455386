import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {OrderReceiptResponse} from '.'

export async function fetchOrderReceipt(orderId: string): Promise<OrderReceiptResponse> {
  const response = await PoshApi.fetchOrderReceipt(orderId)
  return response.data
}

export function useFetchOrderReceipt(orderId: string) {
  return useQuery(['orderReceipt', orderId], () => fetchOrderReceipt(orderId))
}
