import React, {forwardRef} from 'react'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {HIDDEN_LOCATION_COPY} from '@posh/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'
import moment from 'moment'

import './styles.scss'

const EventCardDate = ({start, timezone}: {start: Date | undefined; timezone: string | undefined}) => {
  const now = moment.utc()
  if (moment(start).isSame(now, 'day')) {
    return <div className='EventCard-dotw'>Today</div>
  }
  if (moment(start).isBefore(moment().add(6, 'day')) && moment(start).isAfter(now, 'day')) {
    return <div className='EventCard-dotw'> {moment.tz(start, timezone ?? 'America/New_York').format('ddd')} </div>
  }
  return (
    <>
      <div className='EventCard-month'>{moment.tz(start, timezone ?? 'America/New_York').format('MMM')}</div>
      <div className='EventCard-day'>{moment.tz(start, timezone ?? 'America/New_York').format('DD')}</div>
    </>
  )
}

interface EventCardProps {
  backgroundImage: string
  ranking?: number
  url?: string
  useTrackingLink?: boolean
  timezone?: string
  startUtc: Date
  name?: string
  groupAvi?: string
  venueName?: string
}

const SKELETON_BASE_COLOR = 'rgb(68 68 68)'
const SKELETON_HIGHLIGHT_COLOR = 'rgb(80,80,80)'

export const EventCardSkeleton = () => {
  return (
    <SkeletonTheme baseColor={SKELETON_BASE_COLOR} highlightColor={SKELETON_HIGHLIGHT_COLOR} duration={0.8}>
      <Skeleton className='EventCard' style={{backgroundColor: SKELETON_BASE_COLOR}} />
    </SkeletonTheme>
  )
}

export const EventCard = forwardRef<HTMLDivElement, EventCardProps>((props, ref) => {
  const {backgroundImage, url, useTrackingLink, timezone, startUtc, name, groupAvi, venueName} = props
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const [searchParams] = useSearchParams()

  const handleClick = () => {
    if (url) {
      trackMixpanelEvent('Event Click-  Explore Page', {
        eventUrl: url,
        when: searchParams.get('when') ?? 'This Week',
        where: searchParams.get('where') ?? 'New York City',
        sort: searchParams.get('sort') ?? 'Trending',
        rankingInExplore: String(props.ranking),
      })
      const navigateUrl = `/e/${url}${useTrackingLink ? '?t=posh' : ''}`
      navigate(navigateUrl)
    }
  }

  return (
    <PoshImageBackground
      ref={ref}
      className='EventCard'
      style={{backgroundImage: `url(${backgroundImage})`}}
      onClick={handleClick}>
      <div className='EventCard-filter' />
      <div className='EventCard-info'>
        <div className='EventCard-date'>
          <EventCardDate start={startUtc} timezone={timezone} />
        </div>
        <div>
          <div className='EventCard-name'>{name ?? ''}</div>
          {/* Venue name is mandatory when creating an event, for now if it is missing its because
          it's hidden by back end logic such as approval awaiting or until event date'*/}
          <div className='EventCard-location'>{venueName || HIDDEN_LOCATION_COPY}</div>
        </div>
      </div>
      {groupAvi && <PoshImage className='EventCard-organizer' src={groupAvi} />}
    </PoshImageBackground>
  )
})

EventCard.displayName = 'EventCard'
