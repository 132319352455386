const PATHS_TO_AVOID_ZENDESK = ['/e/', '/g/', '/p/', '/f/', '/services/photographer', '/create']

declare global {
  interface Window {
    ReactNativeWebView: any
  }
}

const shouldShowZendeskWidget = (pathname: string, isAuthed: boolean) => {
  const includesAvoidPath = PATHS_TO_AVOID_ZENDESK.some(avoidPath => pathname.includes(avoidPath))
  return isAuthed && !includesAvoidPath && !window.ReactNativeWebView
}

export {shouldShowZendeskWidget}
