import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {EventPublic} from 'apis/Events/types'
import Button from 'components/form/Button'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {CalendarSvg, ReceiptSvg, ShareSvg} from 'pages/ReceiptPage/SVGs/svgs'

import AddToCalendarModal from '../AddToCalendarModal'
import EventSection from '../EventSection'

import './styles.scss'

const AttendeeActions = ({
  event,
  colorModeModifier,
  accentColor,
  attendeeCartId,
}: {
  event: EventPublic
  colorModeModifier: string
  accentColor: string
  attendeeCartId: string | undefined
}) => {
  const contrastColor = colorModeModifier == 'is-darkMode' ? '#0a0b0d' : '#fff'

  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [addCalendarModalOpen, setAddCalendarModalOpen] = useState(false)

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: event.name,
          text: event.description,
          url: window.location.href,
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error))
    } else {
      console.log('Share not supported')
      setShareModalOpen(true)
    }
  }

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href)
  }

  const navigate = useNavigate()
  const goToReceipt = () => {
    navigate(`/receipt/${attendeeCartId}`)
  }

  return (
    <>
      <EventSection accentColor={accentColor} className='noMinHeight smallPadding'>
        <div className='AttendeeEPActions'>
          <Button
            style={{
              color: contrastColor,
              background: accentColor,
            }}
            onClick={() => share()}>
            <ShareSvg accentColor={contrastColor} />
            Share Event
          </Button>
          <Button
            style={{
              color: contrastColor,
              background: accentColor,
            }}
            onClick={() => setAddCalendarModalOpen(true)}>
            <CalendarSvg accentColor={contrastColor} />
            Add to Calendar
          </Button>
          {attendeeCartId && (
            <Button
              style={{
                color: contrastColor,
                background: accentColor,
              }}
              onClick={() => goToReceipt()}>
              <ReceiptSvg accentColor={contrastColor} />
              View Receipt &amp; QR
            </Button>
          )}
        </div>
      </EventSection>
      <PoshStyledModal isOpen={shareModalOpen} onClose={() => setShareModalOpen(false)} center noborder>
        <h3>Share Event</h3>
        <p>
          Anyone with the link will be able to view &amp; {event.isRSVPEvent ? 'RSVP' : 'purchase tickets'} to this
          event.
        </p>
        <div className='buttonWrapper fullWidth center'>
          <Button onClick={() => copyLink()}>Copy Link</Button>
        </div>
      </PoshStyledModal>
      <AddToCalendarModal
        event={event}
        addCalendarModalOpen={addCalendarModalOpen}
        setAddCalendarModalOpen={setAddCalendarModalOpen}
      />
    </>
  )
}

export default AttendeeActions
