import {useQuery} from '@tanstack/react-query'

import PoshApi from '../PoshApi'
import {ExportKeyObject} from './types'

export async function fetchEventExportKeys(eventId: string): Promise<ExportKeyObject[]> {
  const response = await PoshApi.fetchEventExportKeys(eventId)
  return response.data
}

export function useFetchEventExportKeys(eventId: string) {
  return useQuery(['eventKeys'], () => fetchEventExportKeys(eventId))
}
