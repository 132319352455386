import React from 'react'

import {CurrencyCode} from '@posh/types'
import {Pencil, TrashIcon} from 'components/assets/Icons'
import {CopyRectangles} from 'components/assets/MobileIcons'
import {CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'

import styles from './styles.module.scss'

interface AdmissionSectionTicketProps {
  ticket: CreateEventTicket
  currencyCode: CurrencyCode
  onDelete?: (ticket: CreateEventTicket) => void
  onDuplicate: (ticket: CreateEventTicket) => void
  onEdit: (ticket: CreateEventTicket) => void
}

const ICON_SIZE = 15
const ICON_COLOR = 'black'

const IconWrapper = (props: {children: React.ReactNode; onClick: () => void}) => {
  return (
    <div onClick={props.onClick} className={styles.iconWrapper}>
      {props.children}
    </div>
  )
}

export const AdmissionSectionTicket = (props: AdmissionSectionTicketProps) => {
  const {ticket, currencyCode, onDelete, onDuplicate, onEdit} = props
  const {name, price} = ticket

  const formattedPrice = price
    ? Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
      }).format(price)
    : 'Free'

  return (
    <div className={styles.AdmissionSectionTicket}>
      <div className={styles.sectionWrapper}>
        <h6 className='noMargin'>{name}</h6>
        <p className='noMargin'>{formattedPrice}</p>
      </div>
      <div className={styles.sectionWrapper}>
        <IconWrapper onClick={() => onDuplicate(ticket)}>
          <CopyRectangles color={ICON_COLOR} width={ICON_SIZE} height={ICON_SIZE} />
        </IconWrapper>
        {onDelete && (
          <IconWrapper onClick={() => onDelete(ticket)}>
            <TrashIcon color={ICON_COLOR} width={ICON_SIZE} height={ICON_SIZE} />
          </IconWrapper>
        )}
        <IconWrapper onClick={() => onEdit(ticket)}>
          <Pencil color={ICON_COLOR} width={ICON_SIZE} height={ICON_SIZE} />
        </IconWrapper>
      </div>
    </div>
  )
}
