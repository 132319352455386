export interface IReview {
  title: string
  quote: string
  organizer: string
  organization: string
  avi: string
}

const reviewMap: IReview[] = [
  {
    title: 'Posh is next to none.',
    quote:
      "We were looking for a platform that aligned with our brand and our vision moving forward. Posh being both versatile and avant-garde, it's the exact platform we needed to stand out from the rest. Having members of their team only a message away has been hugely helpful in utilizing all Posh's tools on offer. This has been a game changer in allowing us to completely tailor our event page to our audience. The team, the tools, and the data analytics, combined with the site design; Posh is next to none. We are grateful for all their help, and looking forward to even larger-scale events with them in the future. Cheers!",
    organizer: 'Harry Miller',
    organization: "Don't Follow Miami",
    avi: 'https://images.posh.vip/b3/testimonial-avis/harry.jpeg',
  },
  {
    title: 'Hands down better than other platforms',
    quote:
      'Posh has been a great platform because it’s very intuitive and easy to use, while still giving me the flexibility, detail and analytics I need',
    organizer: 'Will Trepp',
    organization: 'Sycamore Hospitality',
    avi: 'https://images.posh.vip/b3/testimonial-avis/trepps.jpeg',
  },
  {
    title: "I'm not sure we would be able to do this without",
    quote:
      "Posh has allowed us to smoothly sell and check tickets for upcoming events. We can track our sales, easily edit event pages, and track event attendance. One of the best features has been the text alerts which allows for quick, necessary communication with attendees if there are any logistical changes with the event. With Posh we have been able to expand our event capacity to 400 ticketholders and have brought in $40,000 in revenue. I'm not sure we would be able to do this without a platform that's specifically designed for nightlife event planning and small businesses.",
    organizer: 'Isabelle  Kenagy',
    organization: 'Indigo Social Club',
    avi: 'https://images.posh.vip/b3/testimonial-avis/isabelle.jpeg',
  },
  {
    title: 'No Brainer!',
    quote:
      'Posh immediately caught my eye as the most user friendly, sexiest, and innovative ticketing platform. What had truly exceeded my expectations has been the personalized support and dedication from not only the Posh team, but from Avante and Eli themselves. There isn’t a single problem, idea, or question that they don’t personally get involved with. Text blasts, personalized table charts, and custom payment plans are just a few of the many things I absolutely love about Posh. We Belong Here cannot wait to grow with Posh.',
    organizer: 'Charles Hochfelder',
    organization: 'We Belong Here',
    avi: 'https://images.posh.vip/b3/testimonial-avis/charles.jpeg',
  },
  {
    title: 'Ensures optimal performance',
    quote:
      "We used posh for several of our invite-only events. We needed a ticketing platform that had customizable branding, a password protected page and enhanced marketing options for a high customer service experience. It's a great event focused ticketing platform with a team that is responsive and ensures optimal performance before, during and after an event.",
    organizer: 'Olga Litvinenko',
    organization: 'Casper Network',
    avi: 'https://images.posh.vip/b3/testimonial-avis/olga.jpg',
  },
  {
    title: 'It has everything any event planner would need.',
    quote:
      "I'm a college student using my backyard space to host concerts. Before I started using Posh, I would collect tickets via Venmo day-of at the door. This is stressful during the concert, because I have no idea how many people are about to roll up. A couple weeks ago, I decided to make an account with Posh not only for better estimates on the turn-out, but also for metrics and communications. I can now use data to drive my decision-making at no cost to myself. I save time by sending an SMS blast with a convenient link to past attendees. Throwing these concerts is so much easier; now I can sit back, relax and enjoy the music.",
    organizer: 'Sophia Gross',
    organization: 'USC Student',
    avi: 'https://images.posh.vip/b3/testimonial-avis/sophia.png',
  },
  {
    title: 'It’s better than EventBrite!',
    quote:
      'We used Posh to sell tickets for our annual Alumni Golf Tournament. The SMS blasts were very helpful in giving alumni a heads up about the event, and giving them important reminders. The contact info we obtained from Posh will also be helpful in the future when we want to reach out. All in all Posh helped us a ton with outreach!',
    organizer: 'Aidan Davis',
    organization: 'Sigma Alpha Epsilon',
    avi: 'https://images.posh.vip/b3/testimonial-avis/aidan.jpeg',
  },
  {
    title: 'My sales are going up everyday!',
    quote: 'This webside is amazing, I love it and my sales are going up everyday',
    organizer: 'Antonio',
    organization: 'Maurisset',
    avi: 'https://images.posh.vip/b3/testimonial-avis/antonio.png',
  },
  {
    title: 'Easy to use, at every step of the way.',
    quote:
      'Posh helped us reach our guests much easier with their sms marketing platform and allowed us to fund our events more easily with daily payout. Their customer service is really their standout feature. Any issues or questions that have arisen are always dealt with quickly and kindly. My favorite aspect of working with Posh is that they’re always working to improve that platform for both organizers and guests.',
    organizer: 'Juan Ortiz Bencosme',
    organization: 'Reggaeton Rave',
    avi: 'https://images.posh.vip/b3/testimonial-avis/juan.jpeg',
  },
  {
    title: 'Do whats best for your brand!',
    quote:
      "My experience with Posh has been great so far. Since using the platform, I've been able to grow my brand and sell out major events. The integrated marketing and social features are some of my favorite tools I utilize and feel that they've given me an edge in selling out my events. Since the transition from Eventbrite to Posh, I am most happy with the amazing customer service and how timely they are to address any questions or hiccups I may have.",
    organizer: 'Mikey Laviano',
    organization: "Club Rudy's",
    avi: 'https://images.posh.vip/b3/testimonial-avis/mikey.jpg',
  },
  {
    title: 'Cultivates a community feel!',
    quote:
      "Posh has been instrumental in the growth of High Tide Music Festival. We decided on Posh to find a sleek and easy to use platform and they delivered. There is no better platform to communicate with attendees and sell tickets. Between Posh's sleek interface and unbeatable customer service we will always use Posh. Posh feels like it was built for promoters by promoters.",
    organizer: 'Edward Holt',
    organization: 'High Tide Festival',
    avi: 'https://images.posh.vip/b3/testimonial-avis/edward.jpeg',
  },
]

export default reviewMap
