import React, {PropsWithChildren, useCallback} from 'react'
import {DragDropContext, Droppable, OnDragEndResponder} from 'react-beautiful-dnd'
import {Control, useController} from 'react-hook-form'

import {ClientCustomCheckoutField, CustomFieldType} from '@posh/model-types'
import CustomCheckoutFieldTemplate from 'components/CustomCheckoutFieldsCreator/CustomCheckoutFieldTemplate'
import {nanoid} from 'nanoid'

import AddFieldTypes from './AddFieldTypes'

import './CustomCheckoutFieldsCreator.styles.scss'

const CheckoutFieldsTemplates = (props: PropsWithChildren<any>) => {
  return (
    <Droppable droppableId={'checkout-fields'}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
          {props.children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

const CustomCheckoutFieldsCreator = ({
  control,
  eventId,
}: {
  eventId: string
  control: Control<{fields: ClientCustomCheckoutField[]}>
}) => {
  const {field} = useController({
    name: 'fields',
    control,
  })

  const checkoutFields = field.value

  const onDelete = (index: number) => {
    const newCustomCheckoutFields = checkoutFields.reduce((acc, ccf, i) => {
      if (i === index) {
        if (ccf.isNew) return acc // if it's new, just remove it
        return [...acc, {...ccf, delete: true}] // if it's not new, mark it for deletion
      }
      return [...acc, ccf]
    }, [] as ClientCustomCheckoutField[])

    field.onChange(newCustomCheckoutFields)
  }

  const onChangeProperty = useCallback(
    <T extends keyof ClientCustomCheckoutField>(index: number, property: T, newValue: ClientCustomCheckoutField[T]) => {
      const newCustomCheckoutFields = [...checkoutFields]
      newCustomCheckoutFields[index][property] = newValue
      field.onChange(newCustomCheckoutFields)
    },
    [checkoutFields, field],
  )
  const onChangeRequired = (required: boolean, index: number) => onChangeProperty(index, 'required', required)
  const onChangePrompt = (prompt: string, index: number) => onChangeProperty(index, 'prompt', prompt)
  const onChangeOptions = (options: string[], index: number) => onChangeProperty(index, 'options', options)
  const onLimitToTickets = (ticketIds: string[], index: number) =>
    onChangeProperty(index, 'limitedToTickets', ticketIds)

  const onAddFieldTemplate = (checkoutField: CustomFieldType) => {
    const newFieldTemplate: ClientCustomCheckoutField = {
      type: checkoutField,
      id: nanoid(),
      prompt: '',
      required: false,
      isNew: true,
    }
    if (checkoutField === 'dropdown' || checkoutField === 'checkboxes') {
      newFieldTemplate.options = ['', '']
    }
    field.onChange([...checkoutFields, newFieldTemplate])
  }

  const onDragEnd: OnDragEndResponder = result => {
    const {destination, source} = result
    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    const newCustomCheckoutFields = [...checkoutFields]
    const [removed] = newCustomCheckoutFields.splice(source.index, 1)
    newCustomCheckoutFields.splice(destination.index, 0, removed)
    field.onChange(newCustomCheckoutFields)
  }
  return (
    <div className='CustomCheckoutFieldsCreator'>
      <DragDropContext onDragEnd={onDragEnd}>
        <CheckoutFieldsTemplates>
          {checkoutFields.map((checkoutField, index) => {
            if (checkoutField.delete) return null
            else
              return (
                <CustomCheckoutFieldTemplate
                  eventId={eventId}
                  key={checkoutField.id}
                  onDelete={() => onDelete(index)}
                  onChangeRequired={required => onChangeRequired(required, index)}
                  onChangePrompt={prompt => onChangePrompt(prompt, index)}
                  onChangeOptions={options => onChangeOptions(options, index)}
                  index={index}
                  onChangeLimitedToTickets={ticketIds => onLimitToTickets(ticketIds, index)}
                  checkoutField={checkoutField}
                  limitedToTickets={checkoutField.limitedToTickets}
                />
              )
          })}
        </CheckoutFieldsTemplates>
      </DragDropContext>
      <AddFieldTypes onPressAddFieldType={onAddFieldTemplate} />
    </div>
  )
}

export default CustomCheckoutFieldsCreator
