import React from 'react'

import useFetchEventKickbackAmount from '../../../apis/Kickback/useFetchEventKickbackAmount'

interface KickbackCardProps {
  onClick: () => void
  eventId: string
}

export const KickbackCard = (props: KickbackCardProps) => {
  const {onClick, eventId} = props
  const {data: kickbackAmount, isFetching: isFetchingKickbackAmount} = useFetchEventKickbackAmount({eventId})
  return (
    <>
      {kickbackAmount && !isFetchingKickbackAmount && (
        <div className='KickbackOffer'>
          <div>
            <br />
            {kickbackAmount}
            <button className='KickbackBtn' onClick={() => onClick()}>
              Get paid &rarr;
            </button>
          </div>
        </div>
      )}
    </>
  )
}
