import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type UnfollowAccountOptions = ReactQueryOptions['social']['account']['unfollowAccount']
export type UnfollowAccountInput = RouterInputs['social']['account']['unfollowAccount']
export type UnfollowAccountOutput = RouterOutput['social']['account']['unfollowAccount']

export function useUnfollowAccount(opts?: UnfollowAccountOptions) {
  const qc = trpc.useContext()
  return trpc.social.account.unfollowAccount.useMutation({
    ...opts,
    onSettled: (...args) => {
      qc.social.account.listAccountFollowing.refetch()
      opts?.onSettled?.(...args)
    },
  })
}
