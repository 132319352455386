import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchKickBackOffersOptions = ReactQueryOptions['kickbacks']['getKickbackOffers']
type FetchKickBackOffersInput = RouterInputs['kickbacks']['getKickbackOffers']
export type FetchKickBackOffersResponse = RouterOutput['kickbacks']['getKickbackOffers']
export type KickbackOfferResponse = FetchKickBackOffersResponse[0]
export type KickbackOffer = KickbackOfferResponse

export default function useFetchKickBackOffers(input: FetchKickBackOffersInput, options?: FetchKickBackOffersOptions) {
  return trpc.kickbacks.getKickbackOffers.useQuery(input, options)
}
