import React, {useEffect, useState} from 'react'

import {CurrencyCode} from '@posh/types'
import {useQueryClient} from '@tanstack/react-query'
import useCreateEventCustomFee from 'apis/Events/useCreateEventCustomFee'
import useFetchEvent from 'apis/Events/useFetchEvent'
import useCreateGroupCustomFee from 'apis/Groups/useCreateGroupCustomFee'
import {EventFee} from 'apis/Groups/useGetGroupForFinancials'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {PoshMultiSelect} from 'components/form/MultiSelect'
import PoshSwitch from 'components/form/PoshSwitch'
import Select from 'components/form/Select'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {isEmpty} from 'lodash'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import './styles.scss'

interface AddCustomFeesModal extends PoshStyledModalProps {
  scope: 'group' | 'event'
  currency: CurrencyCode
  eventId?: string
}

export interface TicketTierFees {
  name: string
  selected: boolean
  id: string
}

export const AddCustomFeesModal = (props: AddCustomFeesModal) => {
  const {onClose, scope, currency, eventId} = props
  const {data, isFetched} = useFetchEvent(eventId!)
  const {groupId} = useGroupMatchParams()
  const {mutateAsync: createGroupCustomFee, isLoading: isLoadingGroupFee} = useCreateGroupCustomFee()
  const {mutateAsync: createEventCustomFee, isLoading: isLoadingEventFee} = useCreateEventCustomFee()
  const [name, setName] = useState('')
  const [amount, setAmount] = useState(0)
  const [type, setType] = useState<EventFee['type']>('percent')
  const [errorMessage, setErrorMessage] = useState('')
  const [ticketTypes, setTicketTypes] = useState<TicketTierFees[]>([])
  const [isTicketTypeChecked, setIsTicketTypeChecked] = useState(false)
  const isLoading = isLoadingGroupFee || isLoadingEventFee
  const queryClient = useQueryClient()
  const details =
    scope === 'group'
      ? 'This fee will be active for all events under this group.'
      : 'This fee are only applicable to this event.'

  const resetModal = () => {
    setTicketTypes(ticketTypes.map(el => ({...el, selected: false})))
    setName('')
    setAmount(0)
    setType('percent')
  }

  const handleAddFee = async () => {
    setErrorMessage('')
    try {
      if (!name || !amount) throw new Error('Name and amount are required')
      if (scope === 'group') await createGroupCustomFee({name, amount, type, groupId: groupId!})
      else if (scope === 'event' && eventId) {
        const limitedToId: string[] = []
        for (const ticket of ticketTypes) {
          if (ticket.selected) limitedToId.push(ticket.id)
        }
        await createEventCustomFee({name, amount, type, eventId, limitedToId})
      }
      queryClient.invalidateQueries([scope])
      resetModal()
      onClose()
    } catch (error: any) {
      const message = error.response?.data?.error || error.message
      if (message !== 'Name and amount are required') resetModal()
      setErrorMessage(message)
    }
  }
  const eventTickets = data?.event?.tickets
  useEffect(() => {
    if (!isEmpty(eventTickets) && isEmpty(ticketTypes)) {
      setTicketTypes(
        eventTickets!.map(el => {
          return {
            selected: false,
            name: el.name,
            id: el.id,
          }
        }),
      )
    }
  }, [eventTickets, ticketTypes])

  return (
    <PoshStyledModal {...props}>
      <div className='AddCustomFeesModal'>
        <h3 className='noMargin'>Add Custom Fees</h3>
        <p className='noMargin'>{details}</p>
        {errorMessage && <p className='noMargin error'>{errorMessage}</p>}
        <Input type='text' placeholder='Name of Fee' value={name} onChange={e => setName(e.target.value)} />
        <div className='AddCustomFeesModal-priceWrapper'>
          <Select
            value={type}
            onChange={e => setType(e.target.value as 'percent' | 'flat')}
            className='AddCustomFeesModal-input'>
            <option value='percent'>%</option>
            <option value='flat'>{getCurrencySymbol(currency)}</option>
          </Select>
          <Input
            type='number'
            placeholder='Amount'
            value={amount}
            onChange={e => setAmount(Number(e.target.value))}
            className='poshInput'
          />
        </div>
        {scope === 'event' && (
          <PoshSwitch
            title='Limit to ticket type(s)'
            switchOptions={{
              checked: isTicketTypeChecked,
              onChange: () => setIsTicketTypeChecked(!isTicketTypeChecked),
            }}
          />
        )}
        {isTicketTypeChecked &&
          (isFetched ? (
            <div className='AddCustomFeesModal-ticket-types'>
              <PoshMultiSelect
                options={ticketTypes}
                name='Assign Fee To Ticket Types'
                emptyMessage='No ticket types'
                placeholder='Select ticket types'
                updateOptions={setTicketTypes}
              />
            </div>
          ) : (
            <SpinLoader height={80} />
          ))}
        <div className='buttonWrapper center'>
          <Button className='dark fullWidth' onClick={onClose}>
            CANCEL
          </Button>
          <Button className='gold fullWidth' onClick={handleAddFee} disabled={isLoading}>
            {isLoading ? 'CREATING FEE' : 'CREATE FEE'}
          </Button>
        </div>
      </div>
    </PoshStyledModal>
  )
}
