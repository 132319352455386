import {useCallback} from 'react'

import {useElements, useStripe} from '@stripe/react-stripe-js'
import {PaymentIntentResult} from '@stripe/stripe-js'
import {useUpdateCartWithPaymentIntent} from 'apis/Carts/useUpdateCartWithPaymentIntent'

type TConfirmPaymentBaseParams = {
  cartId: string
  onSuccess?: () => void
}

type TConfirmPaymentExistingParams = TConfirmPaymentBaseParams & {
  paymentMethodId: string
}

const throwCheckoutErrorMessage = (message: string) => {
  throw new Error(`${message} No charge has been made to your card.`)
}

/**
 * The function returned throws an error "Stripe has not loaded" if stripe
 * is not loaded yet.
 *
 */
const useStripeConfirmPayment = <TPayment extends 'existing' | 'new'>({
  payment,
  redirectUrl,
}: {
  payment: TPayment
  redirectUrl: string
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const {mutateAsync: updateCartWithPaymentIntent} = useUpdateCartWithPaymentIntent()
  const returnUrl = window.location.origin + redirectUrl

  const confirmPayment = useCallback(
    async (args: TPayment extends 'existing' ? TConfirmPaymentExistingParams : TConfirmPaymentBaseParams) => {
      const {cartId, onSuccess} = args

      if (!stripe || !elements) {
        throw new Error('Stripe has not loaded')
      }

      let confirmPaymentResponse: PaymentIntentResult
      if (payment === 'new') {
        const elementsSubmissionResponse = await elements.submit()
        if (elementsSubmissionResponse.error) {
          return elementsSubmissionResponse
        }

        const {paymentIntentClientSecret} = await updateCartWithPaymentIntent({cartId}).catch(error =>
          throwCheckoutErrorMessage(error.message),
        )

        confirmPaymentResponse = await stripe.confirmPayment({
          elements,
          clientSecret: paymentIntentClientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: returnUrl,
          },
        })
      } else {
        // payment === 'existing'
        const {paymentIntentClientSecret} = await updateCartWithPaymentIntent({cartId}).catch(error =>
          throwCheckoutErrorMessage(error.message),
        )

        const existingPaymentMethodParams = args as TConfirmPaymentExistingParams
        confirmPaymentResponse = await stripe.confirmPayment({
          clientSecret: paymentIntentClientSecret,
          redirect: 'if_required',
          confirmParams: {
            payment_method: existingPaymentMethodParams.paymentMethodId,
            return_url: returnUrl,
          },
        })
      }

      if (!confirmPaymentResponse.error) onSuccess?.()
      return confirmPaymentResponse
    },
    [updateCartWithPaymentIntent, elements, payment, returnUrl, stripe],
  )
  return {
    confirmPayment,
  }
}

export default useStripeConfirmPayment
