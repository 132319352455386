import React, {useEffect, useState} from 'react'
import NumberFormat from 'react-number-format'

import {getInstantTransferAmounts, INSTANT_TRANSFER_MIN_AMOUNT, POSH_FEE_FOR_INSTANT_TRANSFER} from '@posh/model-types'
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {PayoutType} from 'apis/Groups'
import useAddDebitCard from 'apis/Groups/useAddDebitCard'
import {TGetGroupForFinancialsOutput} from 'apis/Groups/useGetGroupForFinancials'
import {useTransferBalance} from 'apis/Groups/useTransferBalance'
import {useMixpanel} from 'apis/MixPanelHandler'
import classNames from 'classnames'
import {Bank, Bolt} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {isUndefined} from 'lodash'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import './styles.scss'

const POSH_FEE_INSTANT_TRANSFER_PERCENT = POSH_FEE_FOR_INSTANT_TRANSFER * 100

interface Balances {
  availableBalance: string
  instantBalance: string
  availableBalanceFormatted: string
  instantAvailableBalanceFormatted: string
}

interface TransferBalanceProps extends PoshStyledModalProps {
  group: TGetGroupForFinancialsOutput
  hasInstantPayout: boolean
  balances: Balances
}

interface PayoutTypeSelectorProps {
  setPayoutType: React.Dispatch<React.SetStateAction<PayoutType>>
  payoutType: PayoutType | undefined
  shouldShowInstant: boolean
}

function PayoutTypeSelector(props: PayoutTypeSelectorProps) {
  const {payoutType, setPayoutType, shouldShowInstant} = props

  return (
    <>
      <a
        onClick={() => setPayoutType('standard')}
        className={
          payoutType === 'standard' ? 'PayoutTypeSelector-option normalSelected' : 'PayoutTypeSelector-option'
        }>
        <Bank className='PayoutTypeSelector-icon' />
        <p className='PayoutTypeSelector-heading'>2-3 Biz Days</p>
        <p className='PayoutTypeSelector-feeText subtitle'>No Fee</p>
      </a>
      <a
        onClick={() => {
          if (shouldShowInstant === false) return
          setPayoutType('instant')
        }}
        className={classNames('PayoutTypeSelector-option', {
          instantSelected: payoutType === 'instant',
          disabled: shouldShowInstant === false,
        })}>
        <Bolt className='PayoutTypeSelector-icon' />
        <p className='PayoutTypeSelector-heading'>INSTANT</p>
        <p className='PayoutTypeSelector-feeText subtitle'>{POSH_FEE_INSTANT_TRANSFER_PERCENT}% Fee</p>
      </a>
    </>
  )
}

const EMPTY_INPUT_WIDTH = 65
const MONETARY_DIGIT_WIDTH = 17

export default function TransferBalanceModal(props: TransferBalanceProps) {
  const {group, balances, hasInstantPayout} = props
  const {trackEvent} = useMixpanel()
  const {availableBalance, instantBalance, availableBalanceFormatted, instantAvailableBalanceFormatted} = balances
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [payoutAmount, setPayoutAmount] = useState('')
  const [formatedPayoutAmount, setFormatedPayoutAmount] = useState('')
  const [payoutType, setPayoutType] = useState<PayoutType>('standard')
  const [deductedPayoutAmount, setDeductedPayoutAmount] = useState('')
  const [isAddingDebitCard, setIsAddingDebitCard] = useState(false)
  const {mutateAsync: transferBalance, error, isLoading} = useTransferBalance()
  const {mutateAsync: addDebitCard, error: debitCardError, isLoading: isAddingCard} = useAddDebitCard()
  const currency = getCurrencySymbol(group.currency)
  const elements = useElements()
  const stripe = useStripe()
  const gmv = group.gmv ?? 0
  const shouldShowInstant = gmv > 10000 || (!isUndefined(group.verified) && group.verified === true)
  useEffect(() => {
    const payoutNumber = parseFloat(payoutAmount)
    if (isNaN(payoutNumber)) return
    const {transferAmount} = getInstantTransferAmounts(payoutNumber)
    setDeductedPayoutAmount(transferAmount.string)
  }, [payoutAmount])

  useEffect(() => {
    if (error) setErrorMessage(error.message)
    if (debitCardError) setErrorMessage(debitCardError.response.data.error.toString().split('. ', 1)[0])
  }, [error, debitCardError])

  const handleInitiatePayout = async () => {
    setErrorMessage('')
    const payout = parseFloat(payoutAmount)
    if (payoutType === 'instant') {
      trackEvent('2-3 Biz Days- Initiate Payout Finance Page', {transferAmount: payout})
    } else {
      trackEvent('Instant Payout- Initiate Payout Finance Page', {transferAmount: payout})
    }
    const groupId = group._id
    if (!payoutType) {
      setErrorMessage('Please choose a payout type')
      return
    } else if (payoutType === 'instant' && hasInstantPayout === false && !isAddingDebitCard) {
      setIsAddingDebitCard(true)
      return
    } else {
      await transferBalance({groupId, amount: payout, type: payoutType})
      setSuccessMessage('Successfully initiated payout!')
    }
  }

  const shouldDisplayButton = () => {
    if (!payoutAmount || !payoutType || !isValidPayoutAmount()) return false
    return true
  }

  const isValidPayoutAmount = () => {
    const balance = payoutType === 'instant' ? instantBalance : availableBalance
    const parsedPayoutAmount = parseFloat(payoutAmount)
    if (isNaN(parsedPayoutAmount)) return false
    if (payoutType === 'instant' && parsedPayoutAmount < INSTANT_TRANSFER_MIN_AMOUNT) return false

    if (balance && parsedPayoutAmount <= parseFloat(balance)) return true
    else return false
  }

  const handleAddCard = async () => {
    setErrorMessage('')
    const currency = group.currency?.toLowerCase() ?? 'us'
    const cardElement = elements!.getElement(CardElement)!
    const result = await stripe?.createToken(cardElement, {currency})
    if (result?.error) {
      setErrorMessage(result.error.message ?? 'There was a problem adding your card as a payment method')
      return
    }
    if (result && result.token) {
      const groupId = group._id
      const token = result.token.id
      await addDebitCard({groupId, token})
      await handleInitiatePayout()
    }
  }

  const AddCreditCard = () => {
    return (
      <>
        <a style={{margin: 'auto'}} className={'PayoutTypeSelector-option instantSelected'}>
          <Bolt className='PayoutTypeSelector-icon' />
          <p className='PayoutTypeSelector-heading'>INSTANT</p>
          <p className='PayoutTypeSelector-feeText'>{POSH_FEE_INSTANT_TRANSFER_PERCENT}% Fee</p>
        </a>
        <p className='center'>You need to add a debit card to send instant payouts.</p>
      </>
    )
  }

  const SelectPayout = () => {
    const limit = payoutType === 'instant' ? instantAvailableBalanceFormatted : availableBalanceFormatted
    return (
      <>
        {limit && (
          <p style={{margin: '10px 0'}} className='center text-small subtitle'>
            Transfer up to {limit}
          </p>
        )}
        {payoutType === 'instant' && (
          <p style={{margin: '10px 0'}} className='center text-small subtitle'>
            ({currency}
            {INSTANT_TRANSFER_MIN_AMOUNT}.00 minimum)
          </p>
        )}
        <div className='TransferBalanceModal-payoutTypesWrapper'>
          <PayoutTypeSelector
            setPayoutType={setPayoutType}
            payoutType={payoutType}
            shouldShowInstant={shouldShowInstant}
          />
        </div>
        {!shouldShowInstant && (
          <div className='subtitle-container'>
            <p className='subtitle'>
              You must have at least $10,000 in ticket sales and an instant eligible payout method to use instant
              payouts.
            </p>
          </div>
        )}

        {isLoading || isAddingCard ? (
          <div className='TransferBalanceModal-loader'>
            <SpinLoader />
          </div>
        ) : (
          <div className='TransferBalanceModal-buttonRow'>
            {shouldDisplayButton() && (
              <Button
                onClick={() => handleInitiatePayout()}
                className={
                  payoutType === 'instant'
                    ? 'Button--yellow TransferBalanceModal-tranferButton instant'
                    : 'Button--yellow TransferBalanceModal-tranferButton'
                }>
                {payoutType === 'instant'
                  ? `Instant Payout ${currency}${deductedPayoutAmount} (${POSH_FEE_INSTANT_TRANSFER_PERCENT}% fee)`
                  : `Payout ${currency}${formatedPayoutAmount} (2-3 Day Arrival)`}
              </Button>
            )}
          </div>
        )}
      </>
    )
  }

  return (
    <PoshStyledModal {...props}>
      <div className='TransferBalanceModal'>
        {errorMessage && <p className='TransferBalanceModal-error'>{errorMessage}</p>}
        {successMessage && <p className='TransferBalanceModal-success'>{successMessage}</p>}
        {isAddingDebitCard ? (
          <div className='AddCreditCard'>
            <AddCreditCard />
            <CardElement
              options={{
                style: {base: {fontSize: '16px', color: '#fff'}, invalid: {color: 'red'}},
              }}
              className='AddCreditCard-stripeCardInput'
            />
            {isLoading || isAddingCard ? (
              <div className='TransferBalanceModal-loader'>
                <SpinLoader height={40} />
              </div>
            ) : (
              <Button
                onClick={() => handleAddCard()}
                style={{width: '100%'}}
                className='Button--yellow TransferBalanceModal-tranferButton instant'>{`Add Card and Instant Payout ${currency}${deductedPayoutAmount} (${POSH_FEE_INSTANT_TRANSFER_PERCENT}% fee)`}</Button>
            )}
          </div>
        ) : (
          <>
            <h3 className='center noMargin'>Initiate Payout</h3>
            <div className='TransferBalanceModal-currencyInputDiv'>
              <p className='TransferBalanceModal-currencyPrefix'>{currency}</p>
              <NumberFormat
                className={
                  isValidPayoutAmount()
                    ? 'TransferBalanceModal-currencyInput'
                    : 'TransferBalanceModal-currencyInput red'
                }
                placeholder={`0`}
                style={{width: `${EMPTY_INPUT_WIDTH + formatedPayoutAmount.length * MONETARY_DIGIT_WIDTH}`}}
                value={formatedPayoutAmount}
                thousandSeparator=','
                allowNegative={false}
                onValueChange={values => {
                  setFormatedPayoutAmount(values.formattedValue)
                  setPayoutAmount(values.value)
                }}
              />
            </div>
            <SelectPayout />
          </>
        )}
      </div>
    </PoshStyledModal>
  )
}
