import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

type AccountExistsResponse = {exists: boolean; isUnconfirmed?: boolean; accountId?: string}
async function accountExistsByEmail(email: string): Promise<AccountExistsResponse> {
  const response = await PoshApi.accountExistsByEmail(email)
  return response.data
}

export default function useAccountExistsByEmail(email?: string) {
  return useQuery(
    ['validAccountEmail', email],
    () => {
      if (!email) return
      return accountExistsByEmail(email!)
    },
    {
      enabled: false,
    },
  )
}
