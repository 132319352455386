import React, {PropsWithChildren} from 'react'
import {Collapse} from 'react-collapse'

import classNames from 'classnames'

import {Caret} from '../../../../assets/Icons'

import styles from './styles.module.scss'

interface TicketSectionProps {
  title: string
  expandable?: boolean
  style?: React.CSSProperties
}

const ICON_SIZE = 15
export const TicketSection = (props: PropsWithChildren<TicketSectionProps>) => {
  const {title, expandable = false, style, children} = props
  const [expanded, setExpanded] = React.useState(!expandable)

  const caretDirection = expanded ? 'down' : 'right'
  const toggleExpanded = () => {
    if (expandable) setExpanded(!expanded)
  }

  return (
    <div className={styles.TicketSection}>
      <div className={classNames(styles.header, {[styles.expandable]: expandable})} onClick={toggleExpanded}>
        <p className='noMargin' style={{color: 'grey'}}>
          {title}
        </p>
        {expandable && <Caret direction={caretDirection} color='grey' width={ICON_SIZE} height={ICON_SIZE} />}
      </div>
      <Collapse isOpened={expanded}>
        <div style={{...style, gap: '10px', display: 'flex', flexDirection: 'column'}}>{children}</div>
      </Collapse>
    </div>
  )
}
