import React, {useEffect, useRef} from 'react'
import {useInViewport} from 'react-in-viewport'
import {Link} from 'react-router-dom'

import {NotificationType} from '@posh/types'
import {FetchNotificationsOutput} from 'apis/Notifications/useFetchNotifications'
import moment from 'moment'

import {Action} from '../../../../../apis/Notifications'
import {useSetNotificationActionTaken} from '../../../../../apis/Notifications/useSetNotificationActionTaken'
import {useSetNotificationAsRead} from '../../../../../apis/Notifications/useSetNotificationAsRead'
import {NotificationComponent} from '../NotificationTypes/NotificationComponent'

type NotificationActionProps = {
  notificationId: string
  action: Action
  actionTaken: boolean
  closeNotificationTray: () => void
}

const NotificationAction = (props: NotificationActionProps) => {
  const {notificationId, action, actionTaken, closeNotificationTray} = props
  const {mutateAsync: setNotificationActionTaken} = useSetNotificationActionTaken()
  const onActionClicked = () => {
    if (!actionTaken) {
      setNotificationActionTaken({
        notificationId: notificationId,
      })
    }
    if (!action.isExternal) {
      closeNotificationTray()
    }
  }

  return (
    <div className='action'>
      {action.isExternal ? (
        <a href={action.link} onClick={onActionClicked}>
          {action.text}
        </a>
      ) : (
        <Link to={action.link} onClick={onActionClicked}>
          {action.text}
        </Link>
      )}
    </div>
  )
}

type NotificationProps = {
  notification: FetchNotificationsOutput[0]
  closeNotificationTray: () => void
}

const Notification = ({notification, closeNotificationTray}: NotificationProps) => {
  const {mutateAsync: setNotificationAsRead} = useSetNotificationAsRead()

  const notificationRef = useRef(null)
  const {inViewport, enterCount} = useInViewport(notificationRef, {threshold: 0.75})

  useEffect(() => {
    if (inViewport && enterCount === 1 && !notification.readAt) {
      setNotificationAsRead({notificationId: notification._id})
    }
  }, [inViewport])

  // Deprecated notification types
  if (
    notification.type === NotificationType.DEPRECATED_NEW_DISPUTE ||
    notification.type === NotificationType.DEPRECATED_NEW_RELEASE ||
    notification.type === NotificationType.DEPRECATED_ORDER_NOTIFICATION
  ) {
    return (
      <div className={`Notification ${notification.readAt ? 'is-read' : ''}`} ref={notificationRef}>
        <div className='Notification-content Notification-content-deprecated'>
          {notification.content}
          {notification.action && (
            <NotificationAction
              notificationId={notification._id}
              action={notification.action}
              actionTaken={!!notification.actionAt}
              closeNotificationTray={closeNotificationTray}
            />
          )}
        </div>
        <div className='Notification-time'>{moment(notification.createdAt).fromNow()}</div>
      </div>
    )
  }

  // New notification types
  return (
    <div className={`Notification ${notification.readAt ? 'is-read' : ''}`} ref={notificationRef}>
      <NotificationComponent notification={notification} />
    </div>
  )
}

export default Notification
