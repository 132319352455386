import {DefaultValues, Resolver, SubmitErrorHandler, useForm} from 'react-hook-form'

import {Ticket} from 'apis/Events/types'
import {useToast} from 'components/toasts/ToastProvider'

import {ticketValidator} from '../ticketValidator'
import {BaseTicketForm} from './schema'
import {transformFormValuesToApiInputType} from './transformFormValuesToApiInputType'

/**
 * Shorthand form for creating or updating a ticket with the TicketSettings component. Provides a form object and a modified handleSubmit function
 * that handles form validation and error handling.
 */
export function useTicketSettingsForm<T extends BaseTicketForm>(params: {
  defaultValues?: DefaultValues<T>
  resolver: Resolver<T>
}) {
  const {defaultValues, resolver} = params
  const {showToast} = useToast()

  const form = useForm<T>({
    resolver,
    mode: 'onChange',
    defaultValues,
  })

  const handleSubmit = (onValid: (ticket: Partial<Ticket>) => void, onInvalid: SubmitErrorHandler<T>) => {
    return form.handleSubmit(data => {
      const ticket = transformFormValuesToApiInputType(data)
      ticketValidator
        .validate(ticket)
        .then(() => {
          onValid(ticket)
        })
        .catch(error => {
          if (error.response) showToast({type: 'error', title: error.response.data.error})
          else showToast({type: 'error', title: error.message})
        })
    }, onInvalid)
  }

  return {...form, handleSubmit}
}
