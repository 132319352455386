import React from 'react'

import {CustomFieldType} from '@posh/model-types'
import AddFieldTypeButton from 'components/CustomCheckoutFieldsCreator/AddFieldTypeButton'

const AddFieldTypes = ({onPressAddFieldType}: {onPressAddFieldType?: (type: CustomFieldType) => void}) => {
  const onPressAddFieldTypeButton = (type: CustomFieldType) => {
    onPressAddFieldType && onPressAddFieldType(type)
  }

  return (
    <div className='AddFieldTypes'>
      <AddFieldTypeButton variant='input' onPress={onPressAddFieldTypeButton} />
      <AddFieldTypeButton variant='checkboxes' onPress={onPressAddFieldTypeButton} />
      <AddFieldTypeButton variant='dropdown' onPress={onPressAddFieldTypeButton} />
      <AddFieldTypeButton variant='file_upload' onPress={onPressAddFieldTypeButton} />
    </div>
  )
}

export default AddFieldTypes
