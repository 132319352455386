import React, {useEffect, useState} from 'react'

import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'

interface EventCardEvent {
  organizer: string
  name: string
  action: string
  icon: string
}

const ChangingEventCard = () => {
  const eventMap: EventCardEvent[] = [
    {
      organizer: 'Hawaiian Pineapple Co.',
      name: 'Holiday Party',
      action: 'Purchase Tickets',
      icon: 'https://images.posh.vip/b2/pineapple.svg',
    },
    {
      organizer: 'Party People',
      name: 'Undergound Rave',
      action: 'Purchase Tickets',
      icon: 'https://images.posh.vip/b2/party.svg',
    },
    {
      organizer: 'Companyon',
      name: 'Annual VC Mixer',
      action: 'RSVP',
      icon: 'https://images.posh.vip/b2/champagne.svg',
    },
  ]

  const [eventAndHue, setEventAndHue] = useState<{event: EventCardEvent; hue: number}>({event: eventMap[0], hue: 0})

  const totalTime = 9000
  const interval = totalTime / eventMap.length
  let i = 0

  useEffect(() => {
    setInterval(() => {
      i++
      const index = i % eventMap.length
      setEventAndHue({event: eventMap[index], hue: (i * 360) / eventMap.length})
    }, interval)
  }, [])

  return (
    <div className='ChangingEventCard' style={{filter: `hue-rotate(${eventAndHue.hue}deg)`}}>
      <div className='ChangingEventCard-inner'>
        <PoshImage src={eventAndHue.event.icon} />
      </div>
      <h5>{eventAndHue.event.organizer}</h5>
      <h3>{eventAndHue.event.name}</h3>
      <Button className='outline'>{eventAndHue.event.action}</Button>
    </div>
  )
}

export default ChangingEventCard
