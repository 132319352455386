import React, {useState} from 'react'

import Button from 'components/form/Button'

import PoshStyledModal, {PoshStyledModalProps} from '../PoshStyledModal'

import './styles.scss'

interface ConfirmationModalProps extends PoshStyledModalProps {
  title: string
  bodyText?: string
  onClick: () => void
  confirmationMessage?: string
  confirmButtonText: string
  cancelButtonText?: string
  errorMessage?: string
  successMessage?: boolean
}

/**
 *
 * This confirmation modal may be staying open for longer than it should in other places its used outside of payouts...
 */
const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    isOpen,
    title,
    bodyText,
    onClick,
    confirmationMessage,
    confirmButtonText,
    errorMessage,
    onClose,
    cancelButtonText = 'Cancel',
    ...rest
  } = props
  const successMessage = props.successMessage ?? true
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleOnClick = () => {
    setSuccess(false)
    try {
      onClick()
      successMessage && setSuccess(true)
    } catch (err) {
      setError(true)
    }
  }

  const handleClose = () => {
    setSuccess(false)
    setError(false)
    onClose()
  }

  return (
    <PoshStyledModal isOpen={isOpen} onClose={handleClose} {...rest}>
      <div className='ConfirmationModal'>
        {error && <p className='ConfirmationModal-errorMessage'>{errorMessage}</p>}
        {success && confirmationMessage && (
          <p className='ConfirmationModal-confirmationMessage'>{confirmationMessage}</p>
        )}
        <h4>{title}</h4>
        {bodyText && <p>{bodyText}</p>}
        <div className='ConfirmationModal-buttonRow'>
          {success ? (
            <>
              <Button className='dark' onClick={handleClose}>
                Exit
              </Button>
            </>
          ) : (
            <>
              <Button className='dark' onClick={handleClose}>
                {cancelButtonText}
              </Button>
              <Button className='gold' onClick={() => handleOnClick()}>
                {confirmButtonText}
              </Button>
            </>
          )}
        </div>
      </div>
    </PoshStyledModal>
  )
}

export default ConfirmationModal
