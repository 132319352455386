import React, {useState} from 'react'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'
import {isMobile} from 'react-device-detect'
import {useNavigate} from 'react-router-dom'

import {useQueryClient} from '@tanstack/react-query'
import {ITicketGroup} from 'apis/Events/types'
import {useUpdateTicketGroups} from 'apis/Events/useUpdateTicketGroups'
import {menuUrl, moreInfo} from 'components/assets/Icons'
import Button from 'components/form/Button'
import PoshInfo from 'components/form/PoshInfo'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useDimensions} from 'hooks/useDimensions'
import {useResourcePageParams} from 'pages/PoshAppLayout'

const reorderTicketGroup = (list: ITicketGroup[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

interface TicketGroupTableProps {
  ticketGroups?: ITicketGroup[]
  refetch: () => void
  onClickCreateTicketGroup?: () => void
}

const TicketGroupTable = (props: TicketGroupTableProps) => {
  const {ticketGroups, refetch, onClickCreateTicketGroup} = props
  const {eventId, domain, groupId} = useResourcePageParams()
  const queryClient = useQueryClient()
  const {mutateAsync: updateTicketGroups} = useUpdateTicketGroups()
  const navigate = useNavigate()
  const {isMobile} = useDimensions()
  const [orderedTicketGroups, setOrderedTicketGroups] = useState(ticketGroups ?? [])

  const onRerenderTicketGroups = async (ticketGroups: ITicketGroup[]) => {
    setOrderedTicketGroups(ticketGroups)
    await updateTicketGroups({eventId: eventId!, ticketGroups: ticketGroups})
    queryClient.refetchQueries(['event'])
    refetch()
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return
    }
    if (result.destination.index === result.source.index) {
      return
    }
    const reorderedTicketGroup = reorderTicketGroup(ticketGroups!, result.source.index, result.destination.index)
    onRerenderTicketGroups(reorderedTicketGroup)
  }

  return (
    <>
      <div className='eventSection'>
        <div className='eventSection-header'>
          <h3>
            Ticket Groups <PoshInfo image={'https://images.posh.vip/TicketGroupExample.png'} />
          </h3>

          <Button
            className='dark light'
            onClick={() => {
              onClickCreateTicketGroup && onClickCreateTicketGroup()
              navigate(`/${domain}/groups/${groupId}/events/${eventId}/tickets/create-ticket-group`)
            }}>
            <span>+ Create Ticket Group</span>
          </Button>
        </div>
        {ticketGroups && ticketGroups.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='list'>
              {provided => (
                <table ref={provided.innerRef} {...provided.droppableProps} className='TicketTable'>
                  <tbody>
                    <tr>
                      <th style={{width: '100px'}}>Name</th>
                      {!isMobile && <th style={{textAlign: 'center'}}>Total Ticket Types</th>}
                      <th />
                    </tr>
                    <TicketGroupList ticketGroups={orderedTicketGroups} />
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <p className='emptySection'>
            Group your tickets by tier, status, availability, etc. This can be useful when you have a large number of
            ticket types.
          </p>
        )}
      </div>
    </>
  )
}

export default TicketGroupTable

interface TicketGroupListProps {
  ticketGroups: ITicketGroup[]
}

const TicketGroupList = (props: TicketGroupListProps) => {
  const {ticketGroups} = props
  return (
    <>
      {ticketGroups.map((group: ITicketGroup, index: number) => (
        <TicketGroupRow key={group._id} ticketGroup={group} index={index} />
      ))}
    </>
  )
}

interface TicketGroupRowProps {
  key?: string
  ticketGroup: ITicketGroup
  index: number
}

const TicketGroupRow = (props: TicketGroupRowProps) => {
  const {ticketGroup, index} = props
  const navigate = useNavigate()
  const {eventId, domain, groupId} = useResourcePageParams()
  const onEdit = (ticketGroup: ITicketGroup) => {
    navigate(`/${domain}/groups/${groupId}/events/${eventId}/tickets/${ticketGroup._id}/edit-ticket-group`)
  }
  if (!ticketGroup._id) return null
  return (
    <>
      <Draggable draggableId={ticketGroup._id} index={index}>
        {provider => (
          <tr ref={provider.innerRef} {...provider.draggableProps} {...provider.dragHandleProps}>
            <td>
              <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%'}}>
                <PoshImage style={{width: '20px', height: '20px', cursor: 'pointer'}} src={menuUrl} />
                {ticketGroup.name}
              </div>
            </td>
            {!isMobile && <td style={{textAlign: 'center'}}>{ticketGroup.ticketTypes.length}</td>}
            <td style={{textAlign: 'right'}} onClick={() => onEdit(ticketGroup)}>
              <PoshImage className='TicketTable-moreInfo' style={{width: '20px', marginRight: '20px'}} src={moreInfo} />{' '}
            </td>
          </tr>
        )}
      </Draggable>
    </>
  )
}
