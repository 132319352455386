import {useMutation} from '@tanstack/react-query'
import {AccountProfile} from 'apis/Accounts'
import PoshApi from 'apis/PoshApi'
import {useToast} from 'components/toasts/ToastProvider'

async function updatePoshProfile(update: AccountProfile): Promise<string> {
  const response = await PoshApi.updatePoshProfile(update)
  const {data} = response
  return data.message
}

export default function useUpdatePoshProfile() {
  const {showToast} = useToast()
  return useMutation((params: {update: AccountProfile}) => updatePoshProfile(params.update), {
    onError: (error: any) => {
      if (error.response) {
        showToast({type: 'error', title: error.response.data.error ?? 'Error updating posh profile'})
      }
    },
  })
}
