import React, {useEffect, useMemo, useRef, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {useMixpanel} from 'apis/MixPanelHandler'
import useCheckIfClickedOutsideDiv from 'components/helpers/clickedOutsideDiv'
import {PoshImage} from 'components/PoshImage/PoshImage'

import {useFetchNotifications} from '../../../../apis/Notifications/useFetchNotifications'
import Bell from '../../../../assets/images/icons/bell.svg'
import NotificationTray from './NotificationTray'

import './styles.scss'

const formatter = Intl.NumberFormat('en-US', {notation: 'compact'})

const Notifications = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [alertBellBoundingRect, setAlertBellBoundingRect] = useState<DOMRect | null>(null)
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {isLoading, isError, isSuccess, data: notifications} = useFetchNotifications()

  const queryClient = useQueryClient()

  const closeNotificationTray = () => {
    setIsOpen(false)
    queryClient.invalidateQueries(['notifications'])
  }

  const unreadNotifications: number = useMemo(
    () => notifications?.filter(notif => !notif.readAt).length ?? 0,
    [notifications],
  )

  const notificationTrayRef = useRef(null)
  useCheckIfClickedOutsideDiv(notificationTrayRef, closeNotificationTray)

  const alertBellLocationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (alertBellLocationRef.current) {
      const alertBellPosition = alertBellLocationRef.current.getBoundingClientRect()
      setAlertBellBoundingRect(alertBellPosition)
    }
  }, [alertBellLocationRef.current, isOpen])

  return (
    <>
      <div
        className='AlertBell'
        ref={alertBellLocationRef}
        onClick={() => {
          trackMixpanelEvent('Header Bell Icon Click-  Group Page')
          setIsOpen(true)
        }}>
        <PoshImage src={Bell} />
        {unreadNotifications > 0 && <div className='AlertBell-badge'>{formatter.format(unreadNotifications)}</div>}
      </div>
      <NotificationTray
        isOpen={isOpen}
        popperRef={notificationTrayRef}
        closeNotificationTray={closeNotificationTray}
        notifications={notifications ?? []}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        popperBoundingRect={alertBellBoundingRect}
      />
    </>
  )
}

export default Notifications
