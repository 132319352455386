import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface AvailableNumber {
  friendlyName: string
  phoneNumber: string
  locality: string
  region: string
}

async function getAvailableNumbers(areaCode: string): Promise<AvailableNumber[]> {
  const response = await PoshApi.getAvailableNumbers(areaCode)
  return response.data
}

export default function useGetAvailableNumbers() {
  return useMutation<AvailableNumber[], unknown, string>(areaCode => getAvailableNumbers(areaCode))
}
