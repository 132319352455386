import React, {useEffect, useState} from 'react'

import {useFetchMailChimpLists} from 'apis/MailChimp/useFetchMailChimpLists'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import useAttendeesCrmContext from '../AttendeesCrmContext'
import ContactsSelected from '../ContactsSelected'
import {ListCreator} from './listCreator'
import {ListSelector} from './listSelector'

import './styles.scss'

export const MailChimpExportModal = (props: PoshStyledModalProps) => {
  const {onClose} = props
  const {groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const {setSelectedAttendeeIds} = useAttendeesCrmContext()
  const {data: lists, isFetching, error} = useFetchMailChimpLists(groupId!)
  const [isShowingListCreator, setIsShowingListCreator] = useState(false)

  useEffect(() => {
    if (error && error.response) showToast({type: 'error', title: error.response.data.error})
  }, [error])

  const resetModal = () => {
    setSelectedAttendeeIds([])
    setIsShowingListCreator(false)
    onClose()
  }

  return (
    <PoshStyledModal {...props} onClose={resetModal}>
      <div className='MailChimpExportModal'>
        <ContactsSelected />
        {!isFetching && lists && !error && (
          <>
            {lists.length > 0 && !isShowingListCreator ? (
              <ListSelector lists={lists} createNewList={() => setIsShowingListCreator(true)} />
            ) : (
              <ListCreator />
            )}
          </>
        )}
      </div>
    </PoshStyledModal>
  )
}
