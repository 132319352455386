import React, {useState} from 'react'

import {FetchOrgsAdminOutputItem, useFetchOrgs} from 'apis/AdminDashboard/orgs/useFetchOrgs'
import {useUpdateOrgPoshAdmin} from 'apis/AdminDashboard/orgs/useUpdateOrgPoshAdmin'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useToast} from 'components/toasts/ToastProvider'
import {flatten} from 'lodash'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import CRUDTable, {CRUDTableDataMap, CRUDTableMap} from '../CRUDTable'

const OrgTableMap: CRUDTableMap = [
  {
    label: 'Id',
    key: 'id',
    type: 'string',
    editable: false,
  },
  {
    label: 'Group Name',
    key: 'groupName',
    type: 'string',
    editable: true,
  },
  {
    label: 'URL',
    key: 'url',
    type: 'string',
    editable: true,
  },
  {
    label: 'Created At',
    key: 'createdAt',
    type: 'string',
    editable: false,
  },
  {
    label: 'Is Deleted',
    key: 'isDeleted',
    type: 'string',
    editable: false,
  },
  {
    label: 'Currency',
    key: 'currency',
    type: 'string',
    editable: false,
  },
  {
    label: 'GMV',
    key: 'gmv',
    type: 'string',
    editable: false,
  },
  {
    label: 'Country',
    key: 'country',
    type: 'string',
    editable: true,
  },
  {
    label: 'Contact Phone',
    key: 'contactPhone',
    type: 'string',
    editable: true,
  },
  {
    label: 'Contact Email',
    key: 'contactEmail',
    type: 'string',
    editable: true,
  },
  {
    label: 'Stripe Id',
    key: 'stripeId',
    type: 'string',
    editable: true,
  },
  {
    label: 'Verified',
    key: 'verified',
    type: 'multiSelect',
    options: ['true', 'false'],
    editable: true,
  },
  {
    label: 'Text Blasts Disabled',
    key: 'textBlastsDisabled',
    type: 'multiSelect',
    options: ['true', 'false'],
    editable: true,
  },
  {
    label: 'Force Allow Text Blasts',
    key: 'forceAllowTextBlasts',
    type: 'multiSelect',
    options: ['true', 'false'],
    editable: true,
  },
  {
    label: 'Payouts Disabled',
    key: 'payoutsDisabled',
    type: 'multiSelect',
    options: ['true', 'false'],
    editable: true,
  },
  {
    label: 'Custom Posh Fee',
    key: 'customPoshFee',
    type: 'string',
    editable: false,
  },
]

export interface OrgData extends CRUDTableDataMap {
  key: 'org'
  data: FetchOrgsAdminOutputItem[]
}

const PoshOrgs = () => {
  const {showToast} = useToast()
  const [searchText, setSearchText] = useState('')

  const {data, fetchNextPage, refetch, isLoading: loading} = useFetchOrgs({limit: 100, searchParam: searchText})

  const {mutate: updateOrg} = useUpdateOrgPoshAdmin({
    onSuccess() {
      refetch()
    },
    onError: error => showToast({type: 'error', title: 'Error updating org: ' + error.message}),
  })

  const allOrgs = flatten(data?.pages.map(i => i.items))

  const fetchMoreUsers = () => {
    fetchNextPage({pageParam: data?.pages[data?.pages.length - 1].nextCursor ?? undefined})
  }

  const onUpdate = async ({id, item}: {id: string; item?: FetchOrgsAdminOutputItem}) => {
    if (!item) return
    updateOrg({
      groupId: item.id,
      url: item.url,
      groupName: item.groupName,
      country: item.country ?? undefined,
      contactEmail: item.contactEmail ?? undefined,
      contactPhone: item.contactPhone ?? undefined,
      stripeId: item.stripeId ?? undefined,
      verified: item.verified ?? undefined,
      textBlastsDisabled: item.textBlastsDisabled ?? undefined,
      forceAllowTextBlasts: item.forceAllowTextBlasts ?? undefined,
      payoutsDisabled: item.payoutsDisabled ?? undefined,
    })
  }

  const copyOrdId = async ({id}: {id: string}) => {
    const org = allOrgs?.find(c => c.id === id)
    if (org) {
      navigator.clipboard.writeText(org.id)
      showToast({type: 'success', title: `Copied org id to clipboard`})
    }
  }

  const CRUDPayload: OrgData = {
    key: 'org',
    data: allOrgs as FetchOrgsAdminOutputItem[],
    refetchDataFunction: refetch,
    entityName: 'Posh Account',
    updateFunction: onUpdate,
    copyFunction: copyOrdId,
  }

  return (
    <>
      <BackAndTitle title={'Posh Orgs'} />
      {loading && <SpinLoader height={40} />}
      <Input
        style={{margin: 'auto', display: 'block', width: '400px'}}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        placeholder='Search'
      />
      <CRUDTable payload={CRUDPayload} map={OrgTableMap} />
      <Button
        style={{margin: 'auto', textAlign: 'center'}}
        onClick={() => {
          fetchMoreUsers()
        }}>
        Load More Users
      </Button>
    </>
  )
}

export default PoshOrgs
