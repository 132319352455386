import React from 'react'
import {useParams} from 'react-router-dom'

import {useGetAccountPublicProfile} from 'apis/Accounts/useGetAccountPublicProfile'
import {useFetchPastEvents} from 'apis/PoshProfile/useFetchPastEvents'
import {useFetchUpcomingEvent} from 'apis/PoshProfile/useFetchUpcomingEvent'
import {useIsCurrentUserFollowingAccount} from 'apis/Social/accounts/useListAccountsFollowing'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import PoshLoaderTransition from 'pages/PoshLoaderTransition'
import PoshMobileNav from 'pages/PoshMobileNav'

import Profile from '..'

const UserProfile = () => {
  const {username} = useParams<{username: string}>()
  const {data: account, isFetching} = useGetAccountPublicProfile({username}, {enabled: !!username})
  const {data: upcomingEvent} = useFetchUpcomingEvent(username!)
  const {data: pastEvents} = useFetchPastEvents(username!)
  const {isCurrentUserFollowingAccount} = useIsCurrentUserFollowingAccount(account?.accountPublicProfile.accountId)

  if (!account) {
    if (!isFetching) return <PageNotFound />
    return <PoshLoaderTransition pageIsChanging={true} fadeOutTransition={true} />
  }
  const {accountPublicProfile} = account
  const statistics = !!accountPublicProfile
    ? [
        {label: 'events attended', value: accountPublicProfile.eventsAttended ?? 0},
        {label: 'guestlist clicks', value: accountPublicProfile.guestlistClicks ?? 0},
      ]
    : []

  return (
    <>
      <PoshHeader desktopOnly />
      <div className='PProfileWrapper'>
        <Profile
          _id={accountPublicProfile.accountId}
          isFetching={isFetching}
          type='account'
          avi={accountPublicProfile.avi}
          title={`${accountPublicProfile.firstName} ${accountPublicProfile.lastName}`}
          subtitle={`@${accountPublicProfile.username}`}
          statistics={statistics}
          instagram={accountPublicProfile.profile?.instagram}
          twitter={accountPublicProfile.profile?.twitter}
          upcomingEvent={upcomingEvent}
          pastEvents={pastEvents}
          verified={accountPublicProfile.profile?.poshVerified}
          firstName={accountPublicProfile.firstName}
          accentColor={'#000000'}
          isFollowedByViewer={isCurrentUserFollowingAccount}
          url={accountPublicProfile.username}
        />
      </div>
      <PoshMobileNav />
    </>
  )
}

export default UserProfile
