import React, {useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import {DB_BACKED_FEATURES, useIsFeatureEnabled} from 'components/FeatureFlag'
import {useDimensions} from 'hooks/useDimensions'

import {useFetchGroup} from '../../../apis/Groups/useFetchGroup'
import RequirePermissions from '../../../components/RequirePermissions'
import {useGroupBaseUrl} from '../../../domains/Groups/helpers'
import {useResourcePageParams} from '../../PoshAppLayout'
import {GroupPageSection, GROUPS_ROUTE_MAP} from '../GroupRoutes'

import './styles.scss'

const GroupPageNavigation = () => {
  const baseUrl = useGroupBaseUrl()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {pathname} = useLocation()
  const {OVERVIEW, MARKETING, TEAM, COMMUNITY, FINANCE, PROFILE, SETTINGS} = GroupPageSection
  const {groupId} = useResourcePageParams()
  const {data: group} = useFetchGroup(groupId!)
  const navigationSections = group?.communityEnabled
    ? [OVERVIEW, MARKETING, TEAM, COMMUNITY, FINANCE, PROFILE, SETTINGS]
    : [OVERVIEW, MARKETING, TEAM, FINANCE, PROFILE, SETTINGS]
  const [isExpanded, setIsExpanded] = useState(false)

  const {isMobile} = useDimensions()
  const shouldShowMenu = () => {
    if (isMobile && isExpanded) return true
    if (!isMobile) return true
    return false
  }

  const {isFeatureEnabled} = useIsFeatureEnabled(DB_BACKED_FEATURES.WEB_NAVIGATION_REDESIGN)
  if (isFeatureEnabled) return <></>

  return (
    <>
      <div className='GroupPageNavigation'>
        <div className='GroupPageNavigation-nav'>
          {shouldShowMenu() &&
            navigationSections.map((groupSection, index) => {
              const isSelected = pathname.includes(groupSection)
              const attrs = GROUPS_ROUTE_MAP[groupSection]
              const cleanedGroupSection = groupSection.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{}\[\]\\\/]/gi, '')
              const Link = () => {
                if (attrs.requiredPermissions) {
                  return (
                    <RequirePermissions
                      requiredPermissions={attrs.requiredPermissions}
                      allowPartialMatch={attrs.allowPartialMatch}>
                      <div key={groupSection} className='GroupPageNavigation-navLinkWrapper'>
                        <NavLink
                          onClick={() => {
                            trackMixpanelEvent('Header Navigation Click-  Group Page', {page: groupSection})
                          }}
                          className={navData =>
                            navData.isActive ? 'GroupPageNavigation-navLink is-active' : 'GroupPageNavigation-navLink'
                          }
                          key={groupSection}
                          to={`${baseUrl}/${groupSection}${groupSection === 'settings' ? '/general' : ''}`}>
                          {cleanedGroupSection}
                        </NavLink>
                        <div className={`GroupPageNavigation-navLinkBorder ${isSelected ? 'selected' : ''}`} />
                      </div>
                    </RequirePermissions>
                  )
                } else {
                  return (
                    <div key={groupSection} className='GroupPageNavigation-navLinkWrapper'>
                      <NavLink
                        onClick={() => {
                          trackMixpanelEvent('Header Navigation Click-  Group Page', {page: groupSection})
                        }}
                        className={navData =>
                          navData.isActive ? 'GroupPageNavigation-navLink is-active' : 'GroupPageNavigation-navLink'
                        }
                        key={groupSection}
                        to={`${baseUrl}/${groupSection}`}>
                        {groupSection}
                      </NavLink>
                      <div className={`GroupPageNavigation-navLinkBorder ${isSelected ? 'selected' : ''}`} />
                    </div>
                  )
                }
              }

              return <Link key={index} />
            })}
          {isMobile && (
            <a
              className='GroupPageNavigation-menuTrigger'
              onClick={() => {
                setIsExpanded(!isExpanded)
              }}>
              {isExpanded ? 'x' : 'MENU'}
            </a>
          )}
        </div>
      </div>
    </>
  )
}

export default GroupPageNavigation
