import React, {useState} from 'react'
import {SketchPicker} from 'react-color'

import {Input} from '@material-ui/core'
import {useQueryClient} from '@tanstack/react-query'
import {Tag} from 'apis/Groups'
import useFetchGroupTagsAndEvents from 'apis/Groups/useFetchGroupTagsAndEvents'
import PoshApi from 'apis/PoshApi'
import {CreateTagParams, useAddAccountsToTag} from 'apis/Tags/useAddAccountsToTag'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import useAttendeesCrmContext from '../AttendeesCrmContext'
import ContactsSelected from '../ContactsSelected'
import {TagContainer} from '../TagContainer'

export const TagsModal = ({
  isOpen,
  onClose,
  onClickCreateTag,
}: {
  isOpen: boolean
  onClose: () => void
  onClickCreateTag?: () => void
}) => {
  const {groupId} = useResourcePageParams()
  const {selectedAttendeeIds, setSelectedAttendeeIds} = useAttendeesCrmContext()
  const {data: tagsAndEvents, isFetching: isLoading, refetch} = useFetchGroupTagsAndEvents(groupId!)
  const {mutateAsync: addTag} = useAddAccountsToTag()
  const queryClient = useQueryClient()
  const [createTagOpen, setCreateTagOpen] = useState(false)
  const [tagValue, setTagValue] = useState('')
  const [color, setColor] = useState('#623D3D')
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [newTagConfirmationMessage, setNewTagConfirmationMessage] = useState('')
  const [isLoadingCreateTag, setIsLoading] = useState(false)
  const onTagSelected = (tag: Tag) => {
    setConfirmationMessage('')
    addTag({
      tagId: tag._id,
      accountIds: selectedAttendeeIds,
      groupId: groupId!,
    }).then(() => {
      setSelectedAttendeeIds([])
      queryClient.invalidateQueries(['groupAttendees', groupId])
      queryClient.invalidateQueries(['eventAttendees'])
    })
    setConfirmationMessage('Tag Added Successfully')
  }
  const resetModal = () => {
    setConfirmationMessage('')
    onClose()
  }
  const createTagPress = async () => {
    onClickCreateTag?.()
    setIsLoading(true)
    setNewTagConfirmationMessage('')
    const tag: CreateTagParams = {
      groupID: groupId!,
      background: color,
      name: tagValue,
    }
    await PoshApi.createTag(tag)
    await refetch()
    setIsLoading(false)
    setNewTagConfirmationMessage('New Tag Created')
    setCreateTagOpen(false)
  }

  return (
    <PoshStyledModal isOpen={isOpen} onClose={resetModal}>
      {!createTagOpen ? (
        <>
          {isLoading ? (
            <div className='TagsModal'>
              <SpinLoader />
            </div>
          ) : (
            <div className='TagsModal'>
              {confirmationMessage ? (
                <>
                  <p style={{color: '#00fc00', textAlign: 'center'}}>{confirmationMessage}</p>
                  <Button className='Button--rounded' onClick={resetModal}>
                    Close
                  </Button>
                </>
              ) : (
                <>
                  <ContactsSelected />
                  {newTagConfirmationMessage && (
                    <p style={{color: '#00fc00', textAlign: 'center'}}>{newTagConfirmationMessage}</p>
                  )}
                  <p>Click on an existing tag to tag your selected attendees</p>
                  <div className='TagsModal-tagWrap'>
                    {tagsAndEvents?.tags.map(tag => (
                      <TagContainer key={tag._id} tag={tag} onClick={() => onTagSelected(tag)} />
                    ))}
                  </div>
                  <p>Don&apos;t see the tag you&apos;re looking for?</p>
                  <Button style={{marginTop: '0px'}} className='Button--rounded' onClick={() => setCreateTagOpen(true)}>
                    Create A New Tag
                  </Button>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <div className='TagsModal'>
          <Input
            type='text'
            placeholder='Insert Tag Name'
            className='TagsModal-input'
            value={tagValue}
            onChange={e => setTagValue(e.target.value)}
            disableUnderline={true}
          />
          <SketchPicker color={color} onChangeComplete={color => setColor(color.hex)} />
          <div className='TagsModal-back' onClick={() => setCreateTagOpen(false)}>
            Back
          </div>
          {isLoadingCreateTag ? (
            <SpinLoader />
          ) : (
            <Button className='Button--rounded' onClick={createTagPress}>
              Create New Tag
            </Button>
          )}
        </div>
      )}
    </PoshStyledModal>
  )
}
