// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LegendContainer__matoG{display:flex;flex-direction:column;gap:5px}.Legend__lhhzx{width:120px;display:grid;grid-template-columns:1fr 4fr;gap:5px;height:fit-content;overflow-y:scroll;max-height:250px}.Legend__lhhzx::-webkit-scrollbar{background-color:rgba(0,0,0,0)}.LegendItem__ldjGZ{display:flex;align-items:center;gap:5px}.LegendItemBox__9r\\+4M{display:inline-block;width:10px;height:10px;margin-right:5px;margin-top:3px}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/OverviewUpdate/Analytics/Shared/Charts/Legend/Legend.module.scss"],"names":[],"mappings":"AAAA,wBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,eACE,WAAA,CACA,YAAA,CACA,6BAAA,CACA,OAAA,CACA,kBAAA,CAEA,iBAAA,CACA,gBAAA,CAEA,kCACE,8BAAA,CAIJ,mBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,uBACE,oBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,cAAA","sourcesContent":[".LegendContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.Legend {\n  width: 120px;\n  display: grid;\n  grid-template-columns: 1fr 4fr;\n  gap: 5px;\n  height: fit-content;\n\n  overflow-y: scroll;\n  max-height: 250px;\n\n  &::-webkit-scrollbar {\n    background-color: transparent;\n  }\n}\n\n.LegendItem {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n\n.LegendItemBox {\n  display: inline-block;\n  width: 10px;\n  height: 10px;\n  margin-right: 5px;\n  margin-top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LegendContainer": "LegendContainer__matoG",
	"Legend": "Legend__lhhzx",
	"LegendItem": "LegendItem__ldjGZ",
	"LegendItemBox": "LegendItemBox__9r+4M"
};
export default ___CSS_LOADER_EXPORT___;
