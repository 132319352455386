import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import useFetchEvent from '../../../apis/Events/useFetchEvent'
import {useResourcePageParams} from '../../PoshAppLayout'
import CreateTicketGroup from '../TeamMembers/CreateTicketGroup'
import EditTicketGroup from '../TeamMembers/EditTicketGroup'
import CreateTicket from './CreateTicket'
import EditPromoCode from './EditPromoCode'
import EditTicket from './EditTicket'
import TicketsSection from './TicketsSection'

import './styles.scss'
import './EditPromoCode/index.scss'

const TicketsRoutes = () => {
  const {eventId} = useResourcePageParams()
  const {data: eventData} = useFetchEvent(eventId!)
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  if (!eventData) return <></>
  const {group, event} = eventData

  const onCreateTicketGroupClick = () => {
    trackMixpanelEvent('Create Ticket Group- Ticket Group Page', {eventStatus: event.status ?? ''})
  }

  const onDeleteTicketGroup = () => {
    trackMixpanelEvent('Delete Ticket Group- Ticket Group Page', {eventStatus: event.status ?? ''})
  }

  return (
    <>
      <PoshHelmet title={`Tickets - ${event.name}`} />
      <div className='Tickets'>
        <Routes>
          <Route path='' element={<TicketsSection />} />
          <Route
            path={`:ticketId`}
            element={<EditTicket tickets={event.tickets} currency={group.currency} timezone={event.timezone} />}
          />
          <Route path={`create`} element={<CreateTicket currency={group.currency} timezone={event.timezone} />} />
          <Route
            path='create-ticket-group'
            element={
              <CreateTicketGroup
                ticketGroups={event.ticketGroups}
                tickets={event.tickets}
                onCreateTicketGroupClick={onCreateTicketGroupClick}
              />
            }
          />
          <Route
            path=':ticketGroupId/edit-ticket-group'
            element={
              <EditTicketGroup
                ticketGroups={event.ticketGroups}
                tickets={event.tickets}
                onDeleteTicketGroup={onDeleteTicketGroup}
              />
            }
          />
          <Route path={'*'} element={<PageNotFound />} />
          <Route path={`edit-promo-code/:code`} element={<EditPromoCode />} />
        </Routes>
      </div>
    </>
  )
}

export default TicketsRoutes
