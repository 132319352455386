import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {useCreateTrackingLink} from 'apis/Events/useCreateTrackingLink'
import {useDeleteEventTrackingLink} from 'apis/Events/useDeleteEventTrackingLink'
import {TTrackingLinkWithHost} from 'apis/Events/useGetTrackingLinksWithHost'
import {Form, FormInputRow} from 'components/DynamicForm/Form/Form'
import {TextInput} from 'components/DynamicForm/Inputs/TextInput/TextInput'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'
import {TableIcon} from 'components/TableUI/TableIcons/TableIcon'
import {useToast} from 'components/toasts/ToastProvider'
import {useCopyLink} from 'hooks/useCopyLink'
import {trpc} from 'lib/trpc'
import {z} from 'zod'

import {generateTrackingLink} from '../Overview'

interface TrackingLinkTableProps {
  trackingLinks: TTrackingLinkWithHost[]
  refresh: () => void
  dataUpdatedAt: number
  eventId: string
  hexUrl: string
}

export const TrackingLinkTable = (props: TrackingLinkTableProps) => {
  const {trackingLinks, refresh, dataUpdatedAt, eventId, hexUrl} = props
  const [trackingLinkToDelete, setTrackingLinkToDelete] = useState<
    | {
        id: string
        value: string
      }
    | undefined
  >(undefined)

  const navigate = useNavigate()

  const columns: ColumnConfig<TTrackingLinkWithHost>[] = [
    {
      key: 'value',
      header: 'Link',
      render: link => {
        if (link === 'posh') {
          return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5}}>
              <TableCell.Text text={link} bold />
              <ReactTooltip className='poshToolTip' effect='solid' place='top' id='posh-tracking-link' />
              <div data-tip={'This link tracks traffic from Posh and our partners.'} data-for='posh-tracking-link'>
                <TableIcon name='info' />
              </div>
            </div>
          )
        }
        return <TableCell text={link} bold />
      },
    },
    {
      key: 'clicks',
      header: 'Clicks',
      formatter: value => value.toString(),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'ticketsSold',
      header: 'Tickets Sold',
      formatter: value => value.toString(),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'conversionRate',
      header: 'Conversion Rate',
      formatter: value => `${value}%`,
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'revenue',
      header: 'Revenue Generated',
      formatter: value => `$${value.toFixed(2)}`,
      sortConfig: {
        defaultSort: true,
      },
    },
  ]

  const {copyLink} = useCopyLink()
  const {showToast} = useToast()

  const copyTrackingLink = (link: string) => {
    const fullTrackingLink = generateTrackingLink({eventHex: hexUrl, trackingLink: link})
    copyLink(fullTrackingLink)
    showToast({type: 'success', title: `Copied tracking link to clipboard: ${fullTrackingLink}`})
  }

  const closeTrackingLinkDeleteModal = () => {
    setTrackingLinkToDelete(undefined)
  }

  const {mutate: deleteTrackingLink} = useDeleteEventTrackingLink({
    onError: error =>
      showToast({type: 'error', title: 'There was an error removing the tracking link', subtitle: error.message}),
    onSuccess: () => {
      showToast({type: 'success', title: 'Tracking link removed'})
      refresh()
    },
  })

  return (
    <>
      <CRUDTable
        columns={columns}
        actionButtons={[
          {
            icon: 'trash',
            onClick: t => setTrackingLinkToDelete({id: t.id, value: t.value}),
            displayIf: t => t.value !== 'posh' && t.conversionRate === 0,
          },
          {
            icon: 'copy-squares',
            onClick: ({value}) => copyTrackingLink(value),
          },
        ]}
        data={trackingLinks}
        searchableColumn='value'
        refresh={refresh}
        lastUpdated={dataUpdatedAt}
        resourceName='Tracking Link'
        itemsPerPage={10}
        onClickRow={({value: link}) => navigate(`${location.pathname}/tl-breakdown/${link}`)}
        createForm={({onClose}) => (
          <TrackingLinkCreateForm trackingLinks={trackingLinks} eventId={eventId} hexUrl={hexUrl} onClose={onClose} />
        )}
      />
      {!!trackingLinkToDelete && (
        <ConfirmationModal
          title={`Are you sure you want to delete the tracking link: ${trackingLinkToDelete.value}?`}
          confirmButtonText={'Yes, delete it'}
          isOpen={!!trackingLinkToDelete}
          onClose={closeTrackingLinkDeleteModal}
          bodyText='This action cannot be undone.'
          onClick={() => {
            deleteTrackingLink({eventId, trackingLinkId: trackingLinkToDelete.id})
            closeTrackingLinkDeleteModal()
          }}
        />
      )}
    </>
  )
}

interface TrackingLinkCreateFormProps {
  trackingLinks: TTrackingLinkWithHost[]
  eventId: string
  hexUrl: string
  onClose: () => void
}

const TrackingLinkCreateForm = (props: TrackingLinkCreateFormProps) => {
  const {trackingLinks, eventId, hexUrl, onClose} = props

  const {showToast} = useToast()
  const queryClient = trpc.useContext()

  const {mutate: createTrackingLink, isLoading: isCreatingTrackingLink} = useCreateTrackingLink({
    onSuccess: (_, input) => {
      showToast({type: 'success', title: `Created tracking link: ${input.value}`})
      queryClient.events.trackingLinks.getTrackingLinksWithHost.invalidate({eventId})
      onClose()
    },
    onError: (error, input) => {
      showToast({
        type: 'error',
        title: `There was an error creating the tracking link: ${input.value}`,
        subtitle: error.message,
      })
    },
  })

  const schema = z.object({
    link: z
      .string()
      .min(1)
      .max(100)
      .regex(new RegExp(/^[a-z0-9-]+$/), {
        message: 'Only lowercase letters, numbers, and hyphens without whitespace are allowed',
      })
      .transform(value => value.replace(/\s+/g, '-'))
      .refine(val => !trackingLinks.some(tL => tL.value === val), {message: 'Tracking link already exists'}),
  })

  return (
    <Form.Custom
      schema={schema}
      onSubmit={data => {
        createTrackingLink({eventId, value: data.link})
      }}
      isSubmitting={isCreatingTrackingLink}
      onCancel={onClose}>
      {form => (
        <>
          <FormInputRow label='Tracking Link' subLabel='Tracking links allow you to segment traffic and sales.'>
            <TextInput.Controlled control={form.control} name='link' placeholder='e.g. link-2' />
          </FormInputRow>
          <FormInputRow label='Link Preview'>
            <p className='noMargin secondary'>{`${generateTrackingLink({
              eventHex: hexUrl,
              trackingLink: form.watch('link') ?? '',
            })}`}</p>
          </FormInputRow>
        </>
      )}
    </Form.Custom>
  )
}
