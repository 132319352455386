import React from 'react'
import Loader from 'react-loader-spinner'

import {AddToMailChimpListResponse} from 'apis/MailChimp/useAddUsersToMailChimpList'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'

interface MailChimpLoaderProps {
  isCreatingNewList: boolean
  isAddingToMailChimpList: boolean
  completedData?: AddToMailChimpListResponse
}

interface MailChimpStatProps {
  stat: number
  label: string
}

const MailChimpStat = (props: MailChimpStatProps) => {
  const {stat, label} = props
  const color =
    label === 'Created' ? 'rgba(5, 255, 0, 1)' : label === 'Updated' ? 'rgba(0, 209, 255, 1)' : 'rgba(255, 0, 0, 1)'
  const backgroundColor =
    label === 'Created'
      ? 'rgba(5, 255, 0, 0.3)'
      : label === 'Updated'
      ? 'rgba(0, 209, 255, 0.3)'
      : 'rgba(255, 0, 0, 0.3)'
  return (
    <div className='MailChimpStat'>
      <div style={{backgroundColor}} className='MailChimpStat-icon'>
        <h4 style={{color}} className='noMargin'>
          {stat}
        </h4>
      </div>
      <p style={{color}} className='noMargin'>
        {label}
      </p>
    </div>
  )
}

export const MailChimpLoader = (props: MailChimpLoaderProps) => {
  const {isCreatingNewList, isAddingToMailChimpList, completedData} = props
  const title = isCreatingNewList
    ? 'Creating list...'
    : isAddingToMailChimpList
    ? 'Transferring attendees...'
    : 'Good to go!'
  const description = isCreatingNewList
    ? 'Creating your new list in Mailchimp...'
    : isAddingToMailChimpList
    ? 'Hang tight! We’re transferring the selected attendees over to your Mailchimp list.'
    : 'Your attendees have been successfully transferred to your Mailchimp list.'

  return (
    <>
      <h3 className='center noMargin'>{title}</h3>
      <p className='center'>{description}</p>
      {completedData ? (
        <>
          <div className='MailChimpStats'>
            <MailChimpStat stat={completedData.created} label='Created' />
            <MailChimpStat stat={completedData.updated} label='Updated' />
            <MailChimpStat stat={completedData.errors} label='Errors' />
          </div>
          <div className='buttonWrapper center'>
            <Button
              onClick={() => {
                window.location.href = completedData.listLink
              }}
              className='mailChimpGradient'>
              Open List
              <PoshImage src='https://images.posh.vip/psh-assets/icons/mailchimp-logo.svg' />
            </Button>
          </div>
        </>
      ) : (
        <div className='inputWrapper center'>
          <Loader type='Rings' height={60} width={60} color='#fc0' />
        </div>
      )}
    </>
  )
}
