import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CartDataFieldType} from '.'

export interface CustomCheckoutFields {
  _id?: string
  name: string
  type: CartDataFieldType
  required: boolean
  field_key: string
  eventId: string
  groupId: string
  metaData?: any
}

async function fetchCustomCheckoutFields(eventId: string): Promise<CustomCheckoutFields[] | undefined> {
  const response = await PoshApi.fetchCustomCheckoutFields(eventId)
  return response.data
}

export default function useFetchCustomCheckoutFields(eventId: string) {
  return useQuery(['customCheckoutFields', eventId], () => {
    return fetchCustomCheckoutFields(eventId)
  })
}
