import React from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'
import {PoshLogo} from 'components/PoshLogo'

interface SideArtProps {
  isCommunityRoute: boolean
}

export const SideArt = (props: SideArtProps) => {
  const {isCommunityRoute} = props
  return (
    <div className={isCommunityRoute ? 'OnboardPage-sideCommunity' : 'OnboardPage-side'}>
      {isCommunityRoute ? (
        <>
          <a className='OnboardPage-side-communityLogo' href='https://posh.vip/community'>
            <PoshImage src='https://images.posh.vip/b2/posh-community.png' />
          </a>
          <PoshImage
            className='OnboardPage-side-communityArt'
            src='https://images.posh.vip/high-res-community-comic.jpg'
          />
        </>
      ) : (
        <div className='OnboardPage-side-art'>
          <PoshLogo.Link />
        </div>
      )}
    </div>
  )
}
