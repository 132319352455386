import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetAvailableFeaturesOptions = ReactQueryOptions['utils']['getAvailableFeatures']
type GetAvailableFeaturesInput = RouterInputs['utils']['getAvailableFeatures']
type GetAvailableFeaturesOutput = RouterOutput['utils']['getAvailableFeatures']
export type AvailableFeatures = GetAvailableFeaturesOutput['availableFeatures']

export function useGetAvailableFeatures(input: GetAvailableFeaturesInput, opts?: GetAvailableFeaturesOptions) {
  return trpc.utils.getAvailableFeatures.useQuery(input, opts)
}
