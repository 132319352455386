import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface MailChimpAuthStatus {
  isAuthenticated: boolean
  canExportUsers: boolean
}

async function isMailChimpUserAuthenticated(groupId: string): Promise<MailChimpAuthStatus> {
  const response = await PoshApi.isMailChimpUserAuthenticated(groupId)
  return response.data
}

export function useIsMailChimpUserAuthenticated(groupId: string) {
  return useQuery(['isMailChimpAuthed', groupId], () => isMailChimpUserAuthenticated(groupId), {
    enabled: !!groupId,
  })
}
