import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import {DateTimePicker, DateTimePickerProps} from '@material-ui/pickers'
import moment from 'moment-timezone'

import {
  ControlledEventVisualsDateInputProps,
  EventVisualsDateInputProps,
  EventVisualsDateInputWrapper,
  getSharedPickerProps,
} from './EventVisualsDateInputWrapper'

export function DateTimeInput(props: EventVisualsDateInputProps<DateTimePickerProps>) {
  const pickerProps = getSharedPickerProps(props)
  const valueIsInDifferentYear = pickerProps.value?.year() !== new Date().getFullYear()
  const format = props.format ?? (valueIsInDifferentYear ? 'MM/DD/YYYY h:mm a' : 'MM/DD h:mm a')

  const isOutlined = props.required === true && !props.value

  const localDate = (() => {
    if (!props.value) return null
    if (props.timezone) return moment.tz(props.value, props.timezone)
    return moment(props.value)
  })()

  return (
    <EventVisualsDateInputWrapper {...props} isOutlined={isOutlined}>
      <DateTimePicker
        {...pickerProps}
        format={format}
        value={localDate}
        key={props.timezone} // re-render when timezone changes so the MUI picker is updated
      />
    </EventVisualsDateInputWrapper>
  )
}

function ControlledDateTime<T extends FieldValues, K extends Path<T>>(
  props: ControlledEventVisualsDateInputProps<T, K, DateTimePickerProps>,
) {
  const {name, control, rules} = props
  const isRequired = typeof rules?.required === 'object' ? rules.required.value : !!rules?.required

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {value, onChange}}) => (
        <DateTimeInput {...props} value={value} onChange={onChange} required={isRequired} />
      )}
    />
  )
}

DateTimeInput.Controlled = ControlledDateTime
