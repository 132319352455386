import React from 'react'

const Fingerprint = ({accentColor}: {accentColor: string}) => {
  return (
    <svg viewBox='0 0 512 512' preserveAspectRatio='none' fill={accentColor}>
      <g>
        <g>
          <path
            d='M367.105,22.293C334.636,7.509,297.259,0,256.022,0c-41.536,0-78.912,7.509-111.125,22.315
                    c-5.333,2.453-7.68,8.789-5.227,14.144c2.453,5.355,8.789,7.68,14.144,5.227c29.397-13.504,63.787-20.352,102.208-20.352
                    c38.165,0,72.555,6.848,102.251,20.373c1.429,0.64,2.944,0.96,4.416,0.96c4.053,0,7.936-2.304,9.707-6.251
                    C374.828,31.061,372.46,24.725,367.105,22.293z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M412.972,109.845c-43.243-43.947-97.536-67.179-156.992-67.179c-59.221,0-113.472,23.232-156.864,67.157
                    c-4.139,4.203-4.096,10.965,0.085,15.083c2.091,2.069,4.8,3.093,7.488,3.093c2.752,0,5.504-1.067,7.595-3.157
                    C153.601,85.035,202.582,64,255.98,64c53.589,0,102.635,21.013,141.781,60.8c4.117,4.181,10.88,4.245,15.083,0.128
                    C417.046,120.811,417.11,114.027,412.972,109.845z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M255.98,85.333c-15.232,0-30.336,1.877-44.928,5.611c-5.717,1.451-9.152,7.253-7.701,12.949
                    c1.451,5.739,7.232,9.173,12.992,7.701c12.843-3.264,26.197-4.928,39.637-4.928c88.235,0,160,71.765,160,160
                    c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667C437.313,166.677,355.969,85.333,255.98,85.333z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M179.521,113.643c-2.987-5.099-9.536-6.784-14.592-3.819c-13.653,8-26.304,17.835-37.547,29.227
                    c-33.216,33.579-51.925,78.827-52.693,127.445c-0.107,5.909,4.608,10.731,10.496,10.837c0.064,0,0.128,0,0.171,0
                    c5.824,0,10.581-4.672,10.667-10.496c0.683-43.072,17.195-83.136,46.528-112.768c9.963-10.091,21.12-18.773,33.152-25.813
                    C180.78,125.269,182.486,118.72,179.521,113.643z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M360.513,175.616C334.124,145.344,296.022,128,255.98,128c-37.205,0-72.107,14.592-98.261,41.067
                    c-26.048,26.347-40.384,61.931-40.384,100.8c0.064,22.933-7.104,50.453-19.947,76.907l-5.995,10.667
                    c-2.88,5.141-1.067,11.648,4.075,14.528c1.664,0.917,3.456,1.365,5.227,1.365c3.733,0,7.339-1.963,9.301-5.44l6.293-11.221
                    c14.72-30.336,22.464-60.373,22.379-87.381c0-32.64,12.16-62.912,34.219-85.227c22.144-22.4,51.648-34.731,83.093-34.731
                    c33.856,0,66.112,14.677,88.448,40.32c3.904,4.437,10.624,4.864,15.061,1.024C363.926,186.795,364.374,180.075,360.513,175.616z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M414.465,389.653l-12.011-39.381c-6.763-30.848-7.339-60.331-7.701-77.931l-0.128-5.675
                    c0-15.808-2.667-31.36-7.915-46.165c-1.941-5.547-8.043-8.448-13.611-6.507c-5.547,1.984-8.448,8.064-6.485,13.632
                    c4.437,12.523,6.677,25.664,6.677,39.317l0.128,5.803c0.363,18.411,0.981,49.195,8.405,82.923l12.203,40.192
                    c1.408,4.587,5.653,7.552,10.219,7.552c1.024,0,2.069-0.149,3.115-0.448C412.993,401.259,416.172,395.307,414.465,389.653z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M383.34,438.891l-9.899-31.744c-17.579-54.912-20.864-116.736-21.461-140.48c0-52.928-43.072-96-96-96
                    c-52.928,0-95.979,43.733-95.979,97.856c0.021,11.221-2.048,70.528-47.083,135.488c-3.371,4.843-2.155,11.499,2.688,14.848
                    c4.8,3.307,11.477,2.133,14.848-2.688c47.467-68.459,50.944-130.389,50.88-148.011c0-41.984,33.472-76.16,74.624-76.16
                    c41.173,0,74.667,33.515,74.667,74.944c0.768,30.976,4.715,91.221,22.464,146.624l9.856,31.659
                    c1.429,4.587,5.653,7.509,10.197,7.509c1.067,0,2.112-0.149,3.179-0.491C381.953,450.496,385.089,444.523,383.34,438.891z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M309.313,266.624c0-29.419-23.915-53.333-53.333-53.333c-29.419,0-53.333,23.936-53.333,53.739
                    c0.128,3.541,2.475,87.339-65.259,175.509c-3.584,4.672-2.709,11.371,1.963,14.955c4.693,3.584,11.371,2.709,14.955-1.963
                    c72.384-94.208,69.781-185.451,69.653-188.885c0-17.643,14.357-32,32-32c17.643,0,32.021,14.357,32.021,32.256
                    c0.277,11.947,0.363,119.723-82.923,227.925c-3.584,4.672-2.731,11.349,1.941,14.955c1.963,1.493,4.245,2.219,6.507,2.219
                    c3.2,0,6.379-1.429,8.469-4.203C309.868,393.621,309.633,279.04,309.313,266.624z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M266.625,266.197c-0.256-5.909-5.056-9.984-11.115-10.176c-5.888,0.256-10.453,5.248-10.197,11.115
                    c0,0.149,0.597,15.744-3.371,40.469c-0.939,5.803,3.029,11.285,8.832,12.224c0.576,0.085,1.152,0.128,1.707,0.128
                    c5.163,0,9.685-3.733,10.539-8.96C267.371,283.904,266.646,266.901,266.625,266.197z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M245.676,342.08c-5.632-1.6-11.584,1.557-13.269,7.211c-13.355,45.056-35.52,87.189-65.899,125.184
                    c-3.669,4.608-2.923,11.307,1.685,14.997c1.963,1.557,4.331,2.325,6.656,2.325c3.136,0,6.229-1.387,8.32-3.989
                    c32.107-40.192,55.552-84.757,69.696-132.48C254.55,349.675,251.329,343.744,245.676,342.08z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M349.036,469.931l-12.843-40.896c-5.12-15.296-9.899-31.509-9.92-31.552c-1.28-4.331-5.12-7.381-9.621-7.637
                    c-4.715-0.512-8.683,2.347-10.432,6.485c-0.235,0.555-24,56.405-51.243,99.285c-3.157,4.971-1.685,11.584,3.285,14.72
                    c1.771,1.131,3.755,1.664,5.717,1.664c3.52,0,6.976-1.749,8.981-4.907c16.512-25.984,31.467-55.808,41.237-76.587
                    c0.576,1.707,1.131,3.435,1.707,5.12l12.779,40.683c1.749,5.611,7.723,8.789,13.376,6.997
                    C347.67,481.536,350.806,475.541,349.036,469.931z'
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

const QRCodeSvg = ({accentColor}: {accentColor: string}) => {
  return (
    <svg
      id='Layer_1'
      enableBackground='new 0 0 512 512'
      height='512'
      viewBox='0 0 512 512'
      width='512'
      xmlns='http://www.w3.org/2000/svg'
      fill={accentColor}>
      <g>
        <path d='m496 144c-8.837 0-16-7.164-16-16v-82c0-7.72-6.28-14-14-14h-82c-8.837 0-16-7.164-16-16s7.163-16 16-16h82c25.364 0 46 20.636 46 46v82c0 8.836-7.163 16-16 16z' />
        <path d='m16 144c-8.837 0-16-7.164-16-16v-82c0-25.364 20.636-46 46-46h82c8.837 0 16 7.164 16 16s-7.163 16-16 16h-82c-7.72 0-14 6.28-14 14v82c0 8.836-7.163 16-16 16z' />
        <path d='m128 512h-82c-25.364 0-46-20.636-46-46v-82c0-8.836 7.163-16 16-16s16 7.164 16 16v82c0 7.72 6.28 14 14 14h82c8.837 0 16 7.164 16 16s-7.163 16-16 16z' />
        <path d='m466 512h-82c-8.837 0-16-7.164-16-16s7.163-16 16-16h82c7.72 0 14-6.28 14-14v-82c0-8.836 7.163-16 16-16s16 7.164 16 16v82c0 25.364-20.636 46-46 46z' />
        <path d='m194 240h-84c-25.364 0-46-20.636-46-46v-84c0-25.364 20.636-46 46-46h84c25.364 0 46 20.636 46 46v84c0 25.364-20.636 46-46 46zm-84-144c-7.72 0-14 6.28-14 14v84c0 7.72 6.28 14 14 14h84c7.72 0 14-6.28 14-14v-84c0-7.72-6.28-14-14-14z' />
        <path d='m194 448h-84c-25.364 0-46-20.636-46-46v-84c0-25.364 20.636-46 46-46h84c25.364 0 46 20.636 46 46v84c0 25.364-20.636 46-46 46zm-84-144c-7.72 0-14 6.28-14 14v84c0 7.72 6.28 14 14 14h84c7.72 0 14-6.28 14-14v-84c0-7.72-6.28-14-14-14z' />
        <path d='m402 240h-84c-25.364 0-46-20.636-46-46v-84c0-25.364 20.636-46 46-46h84c25.364 0 46 20.636 46 46v84c0 25.364-20.636 46-46 46zm-84-144c-7.72 0-14 6.28-14 14v84c0 7.72 6.28 14 14 14h84c7.72 0 14-6.28 14-14v-84c0-7.72-6.28-14-14-14z' />
        <path d='m422 352h-38v-54c0-14.336-11.663-26-26-26h-60c-14.337 0-26 11.664-26 26v60c0 14.336 11.663 26 26 26h54v38c0 14.336 11.663 26 26 26h44c14.337 0 26-11.664 26-26v-44c0-14.336-11.663-26-26-26zm-118-48h48v48h-48zm112 112h-32v-32h32z' />
        <path d='m160 176h-16c-8.837 0-16-7.164-16-16v-16c0-8.836 7.163-16 16-16h16c8.837 0 16 7.164 16 16v16c0 8.836-7.163 16-16 16z' />
        <path d='m368 176h-16c-8.837 0-16-7.164-16-16v-16c0-8.836 7.163-16 16-16h16c8.837 0 16 7.164 16 16v16c0 8.836-7.163 16-16 16z' />
        <path d='m160 384h-16c-8.837 0-16-7.164-16-16v-16c0-8.836 7.163-16 16-16h16c8.837 0 16 7.164 16 16v16c0 8.836-7.163 16-16 16z' />
      </g>
    </svg>
  )
}

const ZoomSvg = ({accentColor}: {accentColor: string}) => {
  return (
    <svg
      id='Layer_1'
      enableBackground='new 0 0 512 512'
      height='512'
      viewBox='0 0 512 512'
      width='512'
      xmlns='http://www.w3.org/2000/svg'
      fill={accentColor}>
      <g>
        <g>
          <path
            d='M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667
          S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6
          c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z
          M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z'
          />
        </g>
      </g>
    </svg>
  )
}

const ReceiptSvg = ({accentColor}: {accentColor: string}) => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      fill={accentColor}>
      <g>
        <g>
          <rect x='86.588' y='472.785' width='331.294' height='37.647' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M311.843,169.726H195.765c-10.397,0-18.824,8.427-18.824,18.824s8.427,18.824,18.824,18.824h116.078
                c10.397,0,18.824-8.427,18.824-18.824S322.24,169.726,311.843,169.726z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M399.686,257.569H195.765c-10.397,0-18.824,8.427-18.824,18.824s8.427,18.824,18.824,18.824h203.922
                c10.397,0,18.823-8.427,18.823-18.824S410.083,257.569,399.686,257.569z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M500.907,3.86c-6.758-3.037-14.657-1.838-20.204,3.062l-52.712,46.632L375.216,6.363c-7.147-6.394-17.951-6.394-25.098,0
                l-52.706,47.134L244.7,6.363c-7.147-6.394-17.945-6.394-25.092,0l-52.775,47.191L114.121,6.922
                c-5.54-4.894-13.446-6.093-20.204-3.062c-6.751,3.043-11.093,9.757-11.093,17.161v335.059h-64C8.427,356.079,0,364.506,0,374.903
                v45.804c0,49.474,40.251,89.725,89.725,89.725v-37.647c-28.718,0-52.078-23.366-52.078-52.078v-26.98h294.902v26.98
                c0,49.474,40.251,89.726,89.725,89.726c49.474,0,89.725-40.251,89.725-89.725V21.02C512,13.616,507.658,6.903,500.907,3.86z
                M474.353,420.707c0,28.712-23.366,52.078-52.078,52.078s-52.078-23.366-52.078-52.078v-45.804
                c0-10.397-8.427-18.824-18.824-18.824H120.471V62.802l33.964,30.049c7.153,6.325,17.907,6.293,25.016-0.069l52.706-47.134
                l52.706,47.128c7.147,6.394,17.951,6.394,25.098,0l52.712-47.134l52.706,47.134c7.115,6.362,17.87,6.387,25.016,0.069
                l33.958-30.042V420.707z'
          />
        </g>
      </g>
    </svg>
  )
}

const AppleSvg = ({accentColor}: {accentColor: string}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      id='Capa_1'
      x='0px'
      y='0px'
      viewBox='0 0 512.22 512.22'
      fill={accentColor}
      width='512'
      height='512'>
      <path d='M451.73,352.45c-16.01-9.61-49.31-29.62-49.31-81.67c0-43.93,29.4-66.1,45.19-78.01l0.33-0.25  c6.34-4.78,7.85-13.68,3.45-20.29c-35.28-52.97-95.87-54.5-107.65-54.28h-0.07c-8.08,0.01-15.79,0.94-23.1,2.41  c2.7-2.16,5.32-4.46,7.84-6.9c25.24-24.42,39.11-60.45,38.04-98.88C366.22,6.47,359.58,0,351.46,0c-27.3,0-58.34,16.31-81.01,42.57  c-22.84,26.46-33.51,58.16-29.26,86.96c0.35,2.39,1.27,4.57,2.59,6.44c-4-1.34-8.23-2.9-12.41-4.44  c-15.88-5.85-33.89-12.48-50.75-12.48c-0.34,0-34.62,0.25-68.85,21.51c-29.94,18.61-65.64,57.34-65.64,136.23  c0,41.03,16.03,98.47,40.84,146.35c17.14,33.08,52.71,88.6,98.39,88.86h0.49c33.31,0,60.91-19.67,79.68-19.67  c32.06,0,43.47,19.89,78.14,19.89c31.08,0,61.2-23.61,89.53-70.16c10.4-17.1,18.49-34.01,23.44-45.19  c9.45-21.3,9.45-24.14,9.45-26.22C466.09,361.08,458.79,356.69,451.73,352.45z M293.16,62.17c12.31-14.26,27.82-24.84,42.51-29.51  c-2.81,23.29-12.65,44.28-28.12,59.24c-10.84,10.49-23.85,17.31-37.06,19.66C271.89,95.08,279.77,77.68,293.16,62.17z   M406.12,428.82c-21.41,34.44-43.59,53.4-62.45,53.4c-25.95,0-38.08-19.89-78.14-19.89c-27.03,0-52.82,19.81-80,19.67  c-21.2-0.12-48.76-27.96-71.92-72.66c-22.77-43.93-37.48-95.96-37.48-132.55c0-51.8,17.03-88.87,50.59-110.19  c26.13-16.59,52.86-17.52,53.99-17.55c23.42,0.06,55.93,20.79,79.62,20.79c21.33,0,49.89-21.89,83.45-21.89h0.55  c8.14-0.16,46.57,0.75,73.57,30.05c-19.08,16.07-45.48,44.97-45.48,92.78c0,64.94,41.07,93.45,60.47,105.34  C428.06,387.82,418.85,408.35,406.12,428.82z' />
    </svg>
  )
}

const PersonSvg = ({accentColor}: {accentColor: string}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      width='512'
      height='512'
      x='0'
      y='0'
      viewBox='0 0 512 512.002'
      fill={accentColor}>
      <g>
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='m210.351562 246.632812c33.882813 0 63.222657-12.152343 87.195313-36.128906 23.972656-23.972656 36.125-53.304687 36.125-87.191406 0-33.875-12.152344-63.210938-36.128906-87.191406-23.976563-23.96875-53.3125-36.121094-87.191407-36.121094-33.886718 0-63.21875 12.152344-87.191406 36.125s-36.128906 53.308594-36.128906 87.1875c0 33.886719 12.15625 63.222656 36.132812 87.195312 23.976563 23.96875 53.3125 36.125 87.1875 36.125zm0 0'
          data-original='#000000'
        />
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='m426.128906 393.703125c-.691406-9.976563-2.089844-20.859375-4.148437-32.351563-2.078125-11.578124-4.753907-22.523437-7.957031-32.527343-3.308594-10.339844-7.808594-20.550781-13.371094-30.335938-5.773438-10.15625-12.554688-19-20.164063-26.277343-7.957031-7.613282-17.699219-13.734376-28.964843-18.199219-11.226563-4.441407-23.667969-6.691407-36.976563-6.691407-5.226563 0-10.28125 2.144532-20.042969 8.5-6.007812 3.917969-13.035156 8.449219-20.878906 13.460938-6.707031 4.273438-15.792969 8.277344-27.015625 11.902344-10.949219 3.542968-22.066406 5.339844-33.039063 5.339844-10.972656 0-22.085937-1.796876-33.046874-5.339844-11.210938-3.621094-20.296876-7.625-26.996094-11.898438-7.769532-4.964844-14.800782-9.496094-20.898438-13.46875-9.75-6.355468-14.808594-8.5-20.035156-8.5-13.3125 0-25.75 2.253906-36.972656 6.699219-11.257813 4.457031-21.003906 10.578125-28.96875 18.199219-7.605469 7.28125-14.390625 16.121094-20.15625 26.273437-5.558594 9.785157-10.058594 19.992188-13.371094 30.339844-3.199219 10.003906-5.875 20.945313-7.953125 32.523437-2.058594 11.476563-3.457031 22.363282-4.148437 32.363282-.679688 9.796875-1.023438 19.964844-1.023438 30.234375 0 26.726562 8.496094 48.363281 25.25 64.320312 16.546875 15.746094 38.441406 23.734375 65.066406 23.734375h246.53125c26.625 0 48.511719-7.984375 65.0625-23.734375 16.757813-15.945312 25.253906-37.585937 25.253906-64.324219-.003906-10.316406-.351562-20.492187-1.035156-30.242187zm0 0'
          data-original='#000000'
        />
      </g>
    </svg>
  )
}

const CalendarSvg = ({accentColor}: {accentColor: string}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      id='Capa_1'
      x='0px'
      y='0px'
      width='484.951px'
      height='484.951px'
      viewBox='0 0 484.951 484.951'
      fill={accentColor}>
      <g>
        <g id='_x31_5_38_'>
          <g>
            <path
              d='M118.505,115.332h6.938c10.544,0,19.091-8.55,19.091-19.091V43.558V23.852c0-10.541-8.547-19.09-19.091-19.09h-6.938
				c-10.543,0-19.09,8.549-19.09,19.09v19.707v52.682C99.415,106.782,107.962,115.332,118.505,115.332z'
            />
            <path
              d='M363.373,114.859h6.938c10.543,0,19.09-8.549,19.09-19.091v-57.21v-15.18c0-10.54-8.547-19.089-19.09-19.089h-6.938
				c-10.545,0-19.092,8.549-19.092,19.089v15.18v57.209C344.283,106.31,352.83,114.859,363.373,114.859z'
            />
            <path
              d='M453.916,43.558H404.92v57.209c0,19.084-15.525,29.61-34.607,29.61h-6.938c-19.084,0-34.609-15.526-34.609-34.61V43.558
				H160.053V96.24c0,19.084-15.525,34.61-34.609,34.61h-6.938c-19.083,0-34.608-15.526-34.608-34.61V43.558H31.036
				C13.923,43.558,0,57.481,0,74.595v375.03c0,17.114,13.923,31.037,31.036,31.037h422.88c17.113,0,31.035-13.923,31.035-31.037
				V74.595C484.953,57.482,471.029,43.558,453.916,43.558z M453.916,449.625H31.037l-0.001-283.213h422.886l0.016,283.212
				C453.936,449.624,453.93,449.625,453.916,449.625z'
            />
            <path
              d='M258.498,261.41h55.727c2.209,0,4-1.791,4-4v-48.255c0-2.209-1.791-4-4-4h-55.727c-2.209,0-4,1.791-4,4v48.255
				C254.498,259.619,256.289,261.41,258.498,261.41z'
            />
            <path
              d='M349.443,261.41h55.727c2.209,0,4-1.791,4-4v-48.255c0-2.209-1.791-4-4-4h-55.727c-2.209,0-4,1.791-4,4v48.255
				C345.443,259.619,347.234,261.41,349.443,261.41z'
            />
            <path
              d='M76.611,340.41h55.726c2.209,0,4-1.791,4-4v-48.256c0-2.209-1.791-4-4-4H76.611c-2.209,0-4,1.791-4,4v48.256
				C72.611,338.619,74.402,340.41,76.611,340.41z'
            />
            <path
              d='M167.555,340.41h55.726c2.209,0,4-1.791,4-4v-48.256c0-2.209-1.791-4-4-4h-55.726c-2.209,0-4,1.791-4,4v48.256
				C163.555,338.619,165.346,340.41,167.555,340.41z'
            />
            <path
              d='M258.499,340.41h55.726c2.209,0,4-1.791,4-4v-48.256c0-2.209-1.791-4-4-4h-55.726c-2.209,0-4,1.791-4,4v48.256
				C254.499,338.619,256.29,340.41,258.499,340.41z'
            />
            <path
              d='M349.443,340.41h55.727c2.209,0,4-1.791,4-4v-48.256c0-2.209-1.791-4-4-4h-55.727c-2.209,0-4,1.791-4,4v48.256
				C345.443,338.619,347.234,340.41,349.443,340.41z'
            />
            <path
              d='M132.337,363.154H76.612c-2.209,0-4,1.791-4,4v48.256c0,2.209,1.791,4,4,4h55.726c2.209,0,4-1.791,4-4v-48.256
				C136.337,364.945,134.546,363.154,132.337,363.154z'
            />
            <path
              d='M223.282,363.154h-55.726c-2.209,0-4,1.791-4,4v48.256c0,2.209,1.791,4,4,4h55.726c2.209,0,4-1.791,4-4v-48.256
				C227.282,364.945,225.491,363.154,223.282,363.154z'
            />
            <path
              d='M314.227,363.154H258.5c-2.209,0-4,1.791-4,4v48.256c0,2.209,1.791,4,4,4h55.727c2.209,0,4-1.791,4-4v-48.256
				C318.227,364.945,316.436,363.154,314.227,363.154z'
            />
            <path
              d='M405.17,363.154h-55.727c-2.209,0-4,1.791-4,4v48.256c0,2.209,1.791,4,4,4h55.727c2.209,0,4-1.791,4-4v-48.256
				C409.17,364.945,407.379,363.154,405.17,363.154z'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const ShareSvg = ({accentColor}: {accentColor: string}) => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      fill={accentColor}>
      <g>
        <g>
          <path
            d='M406,332c-29.641,0-55.761,14.581-72.167,36.755L191.99,296.124c2.355-8.027,4.01-16.346,4.01-25.124
			c0-11.906-2.441-23.225-6.658-33.636l148.445-89.328C354.307,167.424,378.589,180,406,180c49.629,0,90-40.371,90-90
			c0-49.629-40.371-90-90-90c-49.629,0-90,40.371-90,90c0,11.437,2.355,22.286,6.262,32.358l-148.887,89.59
			C156.869,193.136,132.937,181,106,181c-49.629,0-90,40.371-90,90c0,49.629,40.371,90,90,90c30.13,0,56.691-15.009,73.035-37.806
			l141.376,72.395C317.807,403.995,316,412.75,316,422c0,49.629,40.371,90,90,90c49.629,0,90-40.371,90-90
			C496,372.371,455.629,332,406,332z'
          />
        </g>
      </g>
    </svg>
  )
}

export {Fingerprint, QRCodeSvg, ReceiptSvg, AppleSvg, PersonSvg, CalendarSvg, ShareSvg, ZoomSvg}
