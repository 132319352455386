import React, {useMemo, useState} from 'react'
import {Control, useController} from 'react-hook-form'

import {EmailWithCircleArrow, PhoneWithCircleArrow} from 'components/assets/Icons'
import {WithToolTip} from 'components/form/PoshInfo'
import {EventVisualsCheckbox} from 'components/PageComponents/EventVisuals/Form/Input/Checkbox/EventVisualsCheckbox'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useDimensions} from 'hooks/useDimensions'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

interface EventVisualsAccountInfoSectionSectionProps {
  control: Control<EventVisualsForm>
  defaultCountry?: string
}

const size = 20

const switchContactInfoButton = (showEmail: boolean, iconColor: string, setShowEmail: (val: boolean) => void) => {
  const tooltipInfo = showEmail ? 'Switch to phone number' : 'Switch to email'
  const iconButton = showEmail ? (
    <PhoneWithCircleArrow
      style={{cursor: 'pointer'}}
      onClick={() => setShowEmail(false)}
      color={iconColor}
      height={size}
      width={size}
    />
  ) : (
    <EmailWithCircleArrow
      style={{cursor: 'pointer'}}
      onClick={() => setShowEmail(true)}
      color={iconColor}
      height={size}
      width={size}
    />
  )

  return <WithToolTip info={tooltipInfo}>{iconButton}</WithToolTip>
}

function useFormValues(control: Control<EventVisualsForm>) {
  const {
    field: {value: smsOptIn, onChange: setSmsOptIn},
  } = useController({
    control,
    name: 'contactInfo.smsOptIn',
  })
  return {setSmsOptIn, smsOptIn}
}

/**
 * Account Information section for the event visuals page (Create Event page)
 * only renders if the user is not logged in
 */
export const EventVisualsAccountInfoSection = (props: EventVisualsAccountInfoSectionSectionProps) => {
  const {control, defaultCountry = 'US'} = props
  const {
    palette: {accentColor, lightmode: lightMode, textContrasting: iconColor},
  } = useEventVisualsContext()
  const {isMobile} = useDimensions()
  const [showEmail, setShowEmail] = useState(!isMobile)

  const switchIcon = useMemo(() => switchContactInfoButton(showEmail, iconColor, setShowEmail), [showEmail, iconColor])

  const {smsOptIn, setSmsOptIn} = useFormValues(control)

  return (
    <EventVisualsSection headerText={'Email or Phone Number'} rightIcon={switchIcon}>
      {showEmail ? (
        <EventVisualsTextInput.Text.Controlled
          control={control}
          name='contactInfo.email'
          placeholder='Email'
          autoComplete='none'
          autoCorrect='off'
          accentColor={accentColor}
          lightMode={lightMode}
          rules={{required: 'Email is required', minLength: 5}}
        />
      ) : (
        <>
          <EventVisualsTextInput.Phone.Controlled
            control={control}
            name='contactInfo.phone'
            placeholder='Phone Number'
            defaultCountry={defaultCountry.toUpperCase()}
            accentColor={accentColor}
            lightMode={lightMode}
            rules={{required: 'Phone number is required', minLength: 9}}
          />
          <EventVisualsCheckbox
            label='I agree to receive text notifications about Posh events. Message and data rates may apply. Message frequency varies. Text HELP for help. Text STOP to unsubscribe.'
            onChecked={setSmsOptIn}
            checked={smsOptIn ?? false}
          />
        </>
      )}
    </EventVisualsSection>
  )
}
