import React from 'react'
import {Navigate, useParams} from 'react-router-dom'

import useFetchCheckoutSummary from 'apis/Checkout/useFetchCheckoutSummary'
import {useFetchEventByUrl} from 'apis/Events/useFetchEvent'
import {LiveCartContextProvider} from 'domains/LiveCart/LiveCartContext'

import {NewCheckoutPage} from '..'

const CheckoutPageWrapper = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const cartId = urlParams.get('cartId')
  const {eventUrl} = useParams<{eventUrl: string}>()

  // const navigate = useNavigate()

  // TODO: figure out this cart data completed check
  if (!cartId || !eventUrl) {
    return <Navigate to={'/'} />
  }

  return <CheckoutPage cartId={cartId} eventUrl={eventUrl} />
}

const CheckoutPage = ({cartId, eventUrl}: {cartId: string; eventUrl: string}) => {
  const {
    data: checkoutSummary,
    isLoading: isCheckoutSummaryLoading,
    isError: isCheckoutSummaryError,
    error: checkoutSummaryError,
  } = useFetchCheckoutSummary({cartId})
  const {data: eventData} = useFetchEventByUrl(eventUrl)

  if (!eventData) {
    return null
  }

  if (isCheckoutSummaryLoading) {
    return <NewCheckoutPage.Loading eventData={eventData} />
  }

  if (isCheckoutSummaryError) {
    return <NewCheckoutPage.Error eventData={eventData} cartId={cartId} errorMessage={checkoutSummaryError.message} />
  }

  return (
    <LiveCartContextProvider cartId={cartId} checkoutSummary={checkoutSummary} isRSVP={false}>
      <NewCheckoutPage eventData={eventData} />
    </LiveCartContextProvider>
  )
}

export default CheckoutPageWrapper
