import React, {useEffect, useState} from 'react'
import {UseFormReturn} from 'react-hook-form'

import useFetchEvent from 'apis/Events/useFetchEvent'
import {NumberInput} from 'components/DynamicForm/Inputs/NumberInput/NumberInput'
import {TextInput} from 'components/DynamicForm/Inputs/TextInput/TextInput'
import {Checkbox} from 'components/form/checkbox/Checkbox'
import PoshDatePicker from 'components/form/PoshDatePicker'
import PoshMarkdownEditor from 'components/form/PoshMarkdownEditor'
import moment from 'moment'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {BaseTicketForm} from './ticketSettingsForm/schema'

interface TicketSettingsProps {
  form: Pick<UseFormReturn<BaseTicketForm>, 'control' | 'setValue' | 'getValues'>
  isEditing: boolean
  timezone: string
  currencySymbol: string
}

export const TicketSettings = (props: TicketSettingsProps) => {
  const {isEditing, timezone, currencySymbol} = props
  const {control, setValue, getValues} = props.form

  const [isShowingPasswordField, setIsShowingPasswordField] = useState(getValues('password') !== undefined)

  useEffect(() => {
    if (!isShowingPasswordField) {
      setValue('password', undefined, {shouldDirty: true})
    }
  }, [isShowingPasswordField])

  const {eventId} = useResourcePageParams()
  const {data} = useFetchEvent(eventId!)
  const {event} = data ?? {}
  const eventTimeFormat = 'MMM Do, h:mm a'
  const eventStart = moment(event?.startUtc).tz(timezone)
  const eventEnd = moment(event?.endUtc).tz(timezone)
  const eventTimeFrame = eventStart.format(eventTimeFormat) + ' - ' + eventEnd.format(eventTimeFormat)

  return (
    <>
      <label className='noMargin'>Ticket name*</label>
      <TextInput.Controlled className='large' control={control} name='name' autoComplete='none' />
      <div className='TicketEditor-inputs'>
        <div className='grid'>
          <label className='noMargin'>Price</label>
          <NumberInput.Controlled
            placeholder='Free'
            control={control}
            name='price'
            prefix={currencySymbol}
            decimalPlaces={2}
          />
          <label className='noMargin'>Quantity Available</label>
          <NumberInput.Controlled
            placeholder='Unlimited'
            control={control}
            name='quantityAvailable'
            decimalPlaces={0}
          />
          <label className='noMargin'>Start sale</label>
          <PoshDatePicker
            value={getValues('onSaleUtc')?.toString()}
            setDates={(localDate, utcDate) => {
              setValue('onSaleUtc', utcDate, {shouldDirty: true})
              setValue('onSale', localDate, {shouldDirty: true})
            }}
            name='Start Sale'
            timezone={timezone}
          />
          <label className='noMargin'>End sale</label>
          <PoshDatePicker
            value={getValues('endSaleUtc')?.toString()}
            setDates={(localDate, utcDate) => {
              setValue('endSaleUtc', utcDate, {shouldDirty: true})
              setValue('endSale', localDate, {shouldDirty: true})
            }}
            name='End Sale'
            timezone={timezone}
          />
          <label className='noMargin'>Min. Purchase Quantity</label>
          <NumberInput.Controlled placeholder='1' control={control} name='purchaseMin' decimalPlaces={0} />
          <label className='noMargin'>Max. Purchase Quantity</label>
          <NumberInput.Controlled placeholder='Unlimited' control={control} name='purchaseLimit' decimalPlaces={0} />
          <label className='noMargin'>Valid From</label>
          <PoshDatePicker
            value={getValues('validity.validAfter')?.toString()}
            setDates={(_, utcDate) => setValue('validity.validAfter', utcDate, {shouldDirty: true})}
            name='Valid From'
            timezone={timezone}
            minDate={eventStart.toDate()}
            maxDate={eventEnd.toDate()}
            info={`The date and time at which tickets become valid for scanning. This date and time must be within the event's time frame. (${eventTimeFrame})`}
          />
          <label className='noMargin'>Valid To</label>
          <PoshDatePicker
            value={getValues('validity.validBefore')?.toString()}
            setDates={(_, utcDate) => setValue('validity.validBefore', utcDate, {shouldDirty: true})}
            name='Valid To'
            timezone={timezone}
            minDate={eventStart.toDate()}
            maxDate={eventEnd.toDate()}
            info={`The date and time at which tickets become invalid for scanning. This date and time must be within the event's time frame. (${eventTimeFrame})`}
          />
        </div>
        <PoshMarkdownEditor
          value={getValues('description')}
          placeholder='Ticket Description'
          onChange={description => setValue('description', description, {shouldDirty: true})}
          toolBarSize='small'
        />
        {isShowingPasswordField && (
          <div className='grid'>
            <label className='noMargin'>Password</label>
            <TextInput.Controlled control={control} name='password' placeholder='Password' />
          </div>
        )}
      </div>
      <div className='TicketEditor-checks'>
        <Checkbox
          checked={isShowingPasswordField}
          onChecked={setIsShowingPasswordField}
          label='Password Protected'
          info='A password will be required to view this ticket tier on the event page.'
        />
        <Checkbox.Controlled
          control={control}
          name='approvalRequired'
          label='Require Approval'
          info='Users with this ticket in their cart wont receive a QR code until you approve their order. Paid approvals expire in 7 days.'
        />
        <Checkbox.Controlled
          control={control}
          name='isHidden'
          label='Hide Tier'
          info='This ticket tier will be hidden from the event page.'
        />
        <Checkbox.Controlled
          control={control}
          name='priceHidden'
          label='Hide Price'
          info='The price of this ticket tier will be hidden on the event page.'
        />
        <Checkbox.Controlled
          control={control}
          name='sellInMultiples'
          label='Sell in Multiples'
          info='Users can only purchase this ticket tier in multiples of the minimum purchase quantity.'
        />
        <Checkbox.Controlled
          control={control}
          name='disabled'
          label='Disabled'
          info='Only the ticket tier name will be displayed on the event page and the tier will not be available for purchase.'
        />
        {isEditing && (
          <Checkbox.Controlled
            control={control}
            name='closed'
            label='Mark as Sold Out'
            info='This ticket tier will no longer be available for purchase. You can reopen it at any time.'
          />
        )}
      </div>
    </>
  )
}
