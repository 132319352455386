import React from 'react'
import {Control, useController} from 'react-hook-form'

import {YoutubeSearchInput} from 'components/PageComponents/EventVisuals/Form/Input/YoutubeSearchInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsHeaderButtons} from 'components/PageComponents/EventVisuals/Page/Section/headerButtons'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {isNil} from 'lodash'
import {YoutubeIFrame} from 'pages/EventPage/Youtube'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

export function EventVisualsYoutubeSection({control}: {control: Control<EventVisualsForm>}) {
  const {
    field: {onChange: setYoutubeLink, value: youtubeLink},
  } = useController({
    control,
    name: 'youtubeLink',
  })

  const removeYoutubeSection = () => {
    setCurrentlyAddingSection(undefined)
    setYoutubeLink(null)
  }

  const {
    currentlyAddingSection,
    setCurrentlyAddingSection,
    palette: {accentColor, lightmode, textContrasting},
  } = useEventVisualsContext()

  if (isNil(youtubeLink) && currentlyAddingSection !== 'youtube') return null

  return (
    <EventVisualsSection
      headerText='YouTube Video'
      underlined
      rightIcon={
        <EventVisualsHeaderButtons
          deleteButtonProps={{
            onPress: removeYoutubeSection,
            disabled: false,
            confirmationModalTitle: 'Are you sure you want to remove your youtube video?',
            confirmationModalSubtitle: 'This action cannot be undone',
            confirmationButtonText: 'Delete Youtube Video',
            cancelButtonText: 'Cancel',
            deleteWithoutModalOnSectionEmpty: true,
            isSectionEmpty: !youtubeLink,
          }}
        />
      }>
      <YoutubeSearchInput
        accentColor={accentColor}
        lightMode={lightmode}
        textColor={textContrasting}
        youtubeLink={youtubeLink || null}
        setYoutubeLink={setYoutubeLink}
      />
      <YoutubeIFrame url={youtubeLink ?? ''} accentColor={accentColor} style={{padding: 0}} />
    </EventVisualsSection>
  )
}
