// brought to you by: https://stackoverflow.com/questions/65241412/restrict-generic-key-type-based-on-lookup-type-in-typescript
/** This helper type takes a type T and maps it's keys
     to the values of the key itself if it's of AllowedType
     and to never if it's not of the AllowedType

     For example,
     type Ticket = {
       type: string,
       quantity: number,
       id: number,
       username: string,
       createdAt: Date
     }
     type TicketWithOnlyAllowedKeys = TypeWithOnlyAllowedTypeKeys<Ticket, number>
                                    = {
                                        type: never,
                                        quantity: 'quantity',
                                        id: 'id',
                                        username: never,
                                        createdAt: never,
                                      }

*/
type TypeWithOnlyAllowedTypeKeys<T, AllowedType> = {
  [TKey in keyof T]: T[TKey] extends AllowedType ? TKey : never
}

/**
 * This uses the other type, TypeWithOnlyAllowedTypeKeys, and uses all its
 * values to create a union type
 *
 * For example,
 * (refer back to example on TypeWithOnlyAllowedTypeKeys)
 * type AllowedTicketKeys = KeysOfType<Ticket, number>
 *                        = TypeWithOnlyAllowedTypeKeys<Ticket, number>[keyof T]
 *                        = TicketWithOnlyAllowedKeys[keyof T]
 *                        = 'quantity' | 'id' | never
 */
type KeysOfType<T, AllowedType> = TypeWithOnlyAllowedTypeKeys<T, AllowedType>[keyof T]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sumOn = <T extends Record<string, any>>(items: T[], field: KeysOfType<T, number>) => {
  return items.reduce((total, item) => {
    return item[field] + total
  }, 0)
}

export default sumOn
