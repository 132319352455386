import React, {useEffect, useState} from 'react'

import {Webhook, WebhookType} from 'apis/Groups'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useToast} from 'components/toasts/ToastProvider'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import WebhooksMap from './Map'
import WebhookSection from './Section'
import {useUpdateGroupWebhooks} from './useUpdateGroupWebhooks'

import './styles.scss'

const Webhooks = () => {
  const {showToast} = useToast()
  const {groupId} = useResourcePageParams()
  const {data: group, refetch: refetchGroup, isFetching} = useFetchGroup(groupId ?? '')
  const {mutateAsync: updateGroupWebhooks, isLoading: isUpdatingWebhooks} = useUpdateGroupWebhooks({
    onSuccess: res => {
      resetPage()
      showToast({type: 'success', title: res.message})
    },
    onError: error => {
      showToast({type: 'error', title: 'Something went wrong while updating your webhooks', subtitle: error.message})
    },
  })

  const [webhooks, setWebhooks] = useState<Webhook[]>([])
  const [webhookRoute, setWebhookRoute] = useState<string | undefined>(undefined)

  const updateWebhookStatus = (type: WebhookType, enabled: boolean) => {
    const webhook = webhooks.find(webhook => webhook.type === type)
    if (webhook) setWebhooks(webhooks.map(webhook => (webhook.type === type ? {...webhook, enabled} : webhook)))
    else setWebhooks([...webhooks, {type, enabled}])
  }

  const handleSave = async () => {
    await updateGroupWebhooks({
      groupId: groupId!,
      webhooks,
      webhookRoute,
    })
  }

  const resetPage = async () => {
    setWebhooks([])
    refetchGroup()
  }

  const saveButtonDisabled =
    ((webhooks.length === 0 || group?.enabledWebhooks === webhooks) && webhookRoute === group?.webhookRoute) ||
    isFetching ||
    isUpdatingWebhooks

  useEffect(() => {
    if (group?.enabledWebhooks) setWebhooks(group.enabledWebhooks)
    if (group?.webhookRoute) setWebhookRoute(group.webhookRoute)
  }, [group])

  if (!group) return <SpinLoader />

  return (
    <>
      <p>
        Webhooks are automated messages sent from Posh when something happens, allowing you to receive real-time updates
        about your events.
      </p>
      <p>
        Your webhook secret key is <code>{group.webhookSecret}</code> and will appear in a header in each request named{' '}
        <code>Posh-Secret</code>. Contact support to regenerate your secret key.
      </p>
      <hr />
      <div style={{maxWidth: '400px'}}>
        <Input
          style={{width: '100%', minWidth: '250px'}}
          label='Webhook Endpoint'
          placeholder='https://yourcustomsite.com/endpoint'
          value={webhookRoute ?? group.webhookRoute}
          onChange={e => setWebhookRoute(e.target.value)}
        />
      </div>
      <hr />
      {WebhooksMap.map(webhook => (
        <WebhookSection
          key={webhook.type}
          webhook={webhook}
          webhooks={webhooks}
          updateWebhookStatus={updateWebhookStatus}
        />
      ))}
      <div className='buttonWrapper'>
        <Button className='light dark' onClick={resetPage}>
          Discard
        </Button>
        <Button
          className='light gold'
          disabled={saveButtonDisabled}
          isLoading={isUpdatingWebhooks}
          onClick={handleSave}>
          Save Changes
        </Button>
      </div>
    </>
  )
}

export default Webhooks
