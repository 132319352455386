import {useUpdatePendingEvent as useUpdatePendingEventApi} from 'apis/PendingEvent/useUpdatePendingEvent'
import {useToast} from 'components/toasts/ToastProvider'

import {useCreateEventParams} from '../useCreateEventParams'

export function useUpdatePendingEventForCreateEvent() {
  const {pendingEventId, groupId} = useCreateEventParams()
  const {showToast} = useToast()

  const {mutate: updatePendingEvent, isLoading: isUpdatingPendingEvent} = useUpdatePendingEventApi({
    onError: error => {
      showToast({title: 'Error updating event', subtitle: error.message, type: 'error'})
    },
  })

  const updateWithNewSchema = (
    data: Omit<Parameters<typeof updatePendingEvent>[0], 'useCreateEventThreeSchema' | '_id'>,
  ) => {
    if (!pendingEventId) throw new Error('pendingEventId is required to update pending event')
    updatePendingEvent({
      ...data,
      _id: pendingEventId,
      groupId: data.groupId ?? groupId,
    })
  }

  return {updatePendingEvent: updateWithNewSchema, isUpdatingPendingEvent}
}
