import React from 'react'
import Loader from 'react-loader-spinner'

import useGetPendingApprovals from 'apis/Orders/useGetPendingApprovals'
import {UpdatePendingApprovalInput} from 'apis/Orders/useUpdatePendingApprovals'
import {isEmpty} from 'lodash'

import ApprovalModal from '../ApprovalModal'
import useApprovalCrmContext from './ApprovalContextProvider/ApprovalCrmContext'
import ApprovalCRM from './ApprovalCRM'
import BulkApprovalActions from './ApprovalCRM/BulkApprovalActions'
import useApprovalSectionParams from './useApprovalSectionParams.hooks'

import './styles.scss'

export type ApprovalStatus = UpdatePendingApprovalInput['updates'][number]['status']
export const ApprovalStatusPresentTenseMap = {
  approved: 'approving',
  denied: 'denying',
} as const
export const ApprovalStatusActiveMap = {
  approved: 'approve',
  denied: 'deny',
} as const
export type ApproveOrDeny = (typeof ApprovalStatusActiveMap)[keyof typeof ApprovalStatusPresentTenseMap]

const ApprovalSection = () => {
  const {approvalProfile, setShowApprovalProfile, selectedApprovalIds} = useApprovalCrmContext()
  const {eventId} = useApprovalSectionParams()
  const {data: pendingApprovalsResponse, isFetching} = useGetPendingApprovals({eventId})
  return (
    <>
      <div className='ApprovalSection'>
        {isFetching ? (
          <Loader width={30} height={30} color='#fc0' type='TailSpin' />
        ) : (
          <>
            {pendingApprovalsResponse && !isEmpty(pendingApprovalsResponse.pendingOrders) ? (
              <>
                <ApprovalCRM newApprovalsResponse={pendingApprovalsResponse} />
                {approvalProfile && (
                  <ApprovalModal
                    onClose={() => {
                      setShowApprovalProfile(null)
                    }}
                  />
                )}
              </>
            ) : (
              <p className='italic'>There are no orders awaiting your approval</p>
            )}
          </>
        )}
      </div>
      {selectedApprovalIds && selectedApprovalIds.length > 0 && <BulkApprovalActions />}
    </>
  )
}

export default ApprovalSection
