import {useQuery} from '@tanstack/react-query'
import {EventModel} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

import {GroupEventsResponse} from '.'

export async function fetchGroupEvents(groupId: string): Promise<EventModel[]> {
  const response = await PoshApi.fetchGroupEvents(groupId)
  const data: GroupEventsResponse = response.data
  return data.events
}

export function useFetchGroupEvents(groupId?: string) {
  return useQuery(['groupEvents', groupId], () => fetchGroupEvents(groupId ?? ''), {
    enabled: !!groupId,
  })
}
