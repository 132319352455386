import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface CheckReturn {
  requestRefundBtnDisplay: string
  previousRequest: boolean
}

export async function CheckForRefundRequest(eventId: string): Promise<CheckReturn> {
  const response = await PoshApi.checkForRefundRequest(eventId)
  return response.data
}

export function useCheckForRefundRequest(eventId: string) {
  return useQuery(['checkForRefundRequest'], () => CheckForRefundRequest(eventId))
}
