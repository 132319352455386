import { mapValues } from '../tools/map-values';
export const definePalette = (palette, props) => Object.assign({}, props, {
    // not a formal w3c type
    type: 'palette',
    value: palette,
    tokens: mapValues(palette, (value) => {
        return typeof value === 'object'
            ? { type: 'color', ...value }
            : { type: 'color', value };
    }),
});
