import React from 'react'

import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'

import {ContactInformationProps} from '../types'

export const ContactInformationModal = (props: ContactInformationProps) => {
  const {owners, isOpen, onClose} = props
  return (
    <PoshStyledModal isOpen={isOpen} onClose={onClose}>
      <div className='FullRefundDashboard-Modal'>
        <h1 className='center'>Contact Organizer</h1>
        {owners &&
          owners.map(owner => (
            <>
              <p className='center'>
                {owner.firstName} {owner.lastName}
              </p>
              <p className='center'>{owner.email}</p>
              <p className='center'>{owner.phone}</p>
            </>
          ))}
      </div>
    </PoshStyledModal>
  )
}
