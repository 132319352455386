import React, {useEffect} from 'react'
import CountUp from 'react-countup'
import {Link} from 'react-router-dom'

import {TEventPlaylist} from 'apis/Events/playlists/useGetEventPlaylist'
import {useTrackEventPlaylistView} from 'apis/Events/playlists/useTrackEventPlaylistView'
import {useMixpanel} from 'apis/MixPanelHandler'
import UserListPreview from 'components/UserListPreview'
import VerifiedGroupBadge from 'components/VerifiedGroupBadge'
import {motion} from 'framer-motion'

import {EventPlaylistList} from './EventPlaylistList/EventPlaylistList'

import styles from './EventPlaylist.module.scss'

export type EventPlaylistProps = TEventPlaylist & {
  slug: string
  locationInput: React.ReactNode
}

export const EventPlaylist = (props: EventPlaylistProps) => {
  const {
    title,
    events,
    description,
    curator,
    slug,
    totalAttendeePreview,
    totalAttending,
    backgroundImagePalette,
    locationInput,
  } = props

  const {trackEvent} = useMixpanel()

  const curatorDetails = (() => {
    if (curator.type === 'account') {
      return {
        name: curator.accountName,
        avi: curator.accountAvi,
        verified: curator.accountVerified,
        link: `/p/${curator.accountUsername}`,
      }
    } else {
      return {
        name: curator.groupName,
        avi: curator.groupAvi,
        verified: curator.groupVerified,
        link: `/g/${curator.groupUrl}`,
      }
    }
  })()

  // Track the view of the playlist (in our DB)
  useTrackEventPlaylistView({playlistSlug: slug}, {staleTime: Infinity, retry: false})

  // Track the view of the playlist (in Mixpanel)
  useEffect(() => {
    trackEvent('Playlist View - Playlists', {slug})
  }, [])

  return (
    <div className={styles.EventPlaylist}>
      <div className={styles.EventPlaylistDetails}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h1>{title}</h1>
          {locationInput}
        </div>
        <div className={styles.EventPlaylistSubheader}>
          <p>{events.length} events</p>
          <div style={{height: 30, width: 1, backgroundColor: 'white'}}></div>
          <UserListPreview>
            <Link to={curatorDetails.link}>
              <UserListPreview.User imageSrc={curatorDetails.avi} />
            </Link>
          </UserListPreview>

          <p className={styles.EventPlaylistCuratedBy}>
            Curated by{' '}
            <Link style={{color: 'white'}} to={curatorDetails.link}>
              {curatorDetails.name}
            </Link>
            {curatorDetails.verified && <VerifiedGroupBadge id={curatorDetails.name} size={15} />}
          </p>
        </div>
        <p className={styles.EventPlaylistDescription}>{description}</p>
        <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{duration: 0.5, ease: 'easeIn'}}
          className={styles.EventPlaylistAttendingPreview}>
          <UserListPreview>
            {totalAttendeePreview.map(({avi}, index) => (
              <UserListPreview.User key={index} imageSrc={avi} />
            ))}
          </UserListPreview>
          <p>
            <CountUp useEasing end={totalAttending} formattingFn={new Intl.NumberFormat().format} duration={2} />+
            attending
          </p>
        </motion.div>
      </div>
      <EventPlaylistList events={events} slug={props.slug} backgroundImagePalette={backgroundImagePalette} />
    </div>
  )
}
