import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface TestWebhookParams {
  groupId: string
  webhook: string
}

export async function testWebhook(params: TestWebhookParams): Promise<string> {
  const response = await PoshApi.testWebhook(params)
  const data = response.data
  return data.message as string
}

export function useTestWebhook() {
  return useMutation<string, unknown, TestWebhookParams>(params => testWebhook(params), {
    retry: false,
  })
}
