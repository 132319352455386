// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageContainer__mElf-{display:flex;justify-content:center}.PageContent__FjbSC{padding:50;display:flex;justify-content:center;align-items:center;flex-direction:column;max-width:1500px}", "",{"version":3,"sources":["webpack://./src/pages/ManageEventPlaylists/CreateEventPlaylistPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CAGF,oBACE,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,gBAAA","sourcesContent":[".PageContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.PageContent {\n  padding: 50;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  max-width: 1500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageContainer": "PageContainer__mElf-",
	"PageContent": "PageContent__FjbSC"
};
export default ___CSS_LOADER_EXPORT___;
