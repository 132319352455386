import React, {ReactNode} from 'react'

import './ColorLegend.styles.scss'

const ColorLegend = ({color, children}: {color: string; children: ReactNode}) => {
  return (
    <div className='ColorLegend'>
      <div className='ColorLegend_Square' style={{backgroundColor: color}} />
      <p className='noMargin'>{children}</p>
    </div>
  )
}

export default ColorLegend
