// This is the pure table component. It does not include any header (other than the column headers)
// but does include pagination and a footer.

import React from 'react'

import {flexRender, Table as TTable} from '@tanstack/react-table'
import classNames from 'classnames'
import moment from 'moment'

import {TableButton} from '../TableButton/TableButton'
import {TableIcon} from '../TableIcons/TableIcon'

import styles from './Table.module.scss'

const TABLE_ROW_HEIGHT = 40
const TABLE_HEADER_HEIGHT = 35
const BORDER_HEIGHT = 1

const FULL_COL_SPAN = 100
const ICON_SIZE = 14

export interface TableProps<T> {
  table: TTable<T>
  data: T[]
  onClickRow?: (row: T) => void
  itemsPerPage?: number
  refresh: () => void
  lastUpdated: number
  CTAComponent?: React.FC
  EmptyComponent?: React.FC
  resourceName: string
}

export const Table = <T,>(props: TableProps<T>) => {
  const {
    table,
    data,
    onClickRow,
    itemsPerPage = 10,
    refresh,
    lastUpdated,
    CTAComponent,
    EmptyComponent,
    resourceName,
  } = props

  const hasNoData = !data.length

  const hasNextPage = table.getCanNextPage()
  const hasPreviousPage = table.getCanPreviousPage()

  const tableHeaderGroups = table.getHeaderGroups()

  const emptyTable = !table.getRowModel().rows?.length

  const numberOfResults = table.getPrePaginationRowModel().rows?.length

  const tableHeight = itemsPerPage * (TABLE_ROW_HEIGHT + BORDER_HEIGHT) + TABLE_HEADER_HEIGHT + BORDER_HEIGHT

  return (
    <div className={styles.TableContainer}>
      <div className={styles.TableWrapper} style={{minHeight: tableHeight}}>
        <table>
          <thead style={{height: TABLE_HEADER_HEIGHT}}>
            {tableHeaderGroups.map(headerGroup => (
              <>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                ))}
              </>
            ))}
          </thead>
          <tbody>
            {!emptyTable ? (
              table.getRowModel().rows.map(row => (
                <tr
                  tabIndex={0}
                  className={classNames({[styles.TableClickableRow]: !!onClickRow})}
                  style={{height: TABLE_ROW_HEIGHT}}
                  onClick={onClickRow ? () => onClickRow(row.original) : undefined}
                  onKeyDown={onClickRow ? e => e.key === 'Enter' && onClickRow(row.original) : undefined}
                  key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={FULL_COL_SPAN}>
                  {hasNoData ? (
                    <div className={styles.TableEmptyWrapper}>
                      {CTAComponent ? <CTAComponent /> : `No ${resourceName.toLowerCase()}s.`}
                    </div>
                  ) : (
                    <div className={styles.TableEmptyWrapper}>{EmptyComponent ? <EmptyComponent /> : `No results`}</div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={styles.TableFooter}>
        <div>
          <p className='noMargin'>
            <span style={{fontWeight: 900}}>{numberOfResults}</span> result(s)
          </p>
        </div>
        <div className={styles.TableUpdatedAt}>
          <p className='noMargin grey-light italic'>Last Updated {moment(lastUpdated).format('h:mma')}</p>
          <TableIcon name='refresh' width={ICON_SIZE} height={ICON_SIZE} cursor={'pointer'} onClick={() => refresh()} />
        </div>
        <div className={styles.TableNextPreviousButtons}>
          <TableButton text='Previous' onClick={() => table.previousPage()} disabled={!hasPreviousPage} />
          <TableButton text='Next' onClick={() => table.nextPage()} disabled={!hasNextPage} />
        </div>
      </div>
    </div>
  )
}
