import React, {useMemo, useState} from 'react'

import {AttendeeRowAttributes, FilterBy, FilterData, Gender} from 'apis/Groups'

import {AttendeesCrmContext} from './AttendeesCrmContext'

const AttendeesCrmContextProvider = (props: {children: React.ReactNode}) => {
  const [page, setPage] = useState(1)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [filteredIds, setFilteredIds] = useState<string[]>([])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [selectedEvents, setSelectedEvents] = useState<string[]>([])
  const [selectedTrackingLinks, setSelectedTrackingLinks] = useState<string[]>([])
  const [selectedTickets, setSelectedTickets] = useState<string[]>([])
  const [filterByData, setFilterByData] = useState<FilterData>('attendees')
  const [filterByOrder, setFilterByOrder] = useState<FilterBy>('newest to oldest')
  const [genderFilter, setGenderFilter] = useState<Gender>(null)
  const [selectedAllAttendees, setSelectedAllAttendees] = useState(false)
  const [isEventScoped, setIsEventScoped] = useState(false)
  const [matchAllEvents, setMatchAllEvents] = useState(false)
  const [matchAllTags, setMatchAllTags] = useState(false)
  const [matchAllTrackingLinks, setMatchAllTrackingLinks] = useState(false)
  const [matchAllTickets, setMatchAllTickets] = useState(false)
  const [currentCheckoutField, setCurrentCheckoutField] = useState(0)
  const [showAttendeeProfile, setShowAttendeeProfile] = useState<AttendeeRowAttributes | null>(null)
  const [isFilterActive, setIsFilterActive] = useState(false)

  const contextState = useMemo(() => {
    return {
      page,
      setPage,
      filterByData,
      setFilterByData,
      genderFilter,
      setGenderFilter,
      filterByOrder,
      setFilterByOrder,
      selectedAttendeeIds: selectedIds,
      setSelectedAttendeeIds: setSelectedIds,
      filteredAttendeeIds: filteredIds,
      setFilteredAttendeeIds: setFilteredIds,
      selectedEvents,
      setSelectedEvents,
      selectedTags,
      setSelectedTags,
      selectedTrackingLinks,
      setSelectedTrackingLinks,
      isFilterActive,
      setIsFilterActive,
      selectedAllAttendees,
      setSelectedAllAttendees,
      isEventScoped,
      setIsEventScoped,
      showAttendeeProfile,
      setShowAttendeeProfile,
      matchAllEvents,
      setMatchAllEvents,
      matchAllTags,
      setMatchAllTags,
      matchAllTrackingLinks,
      setMatchAllTrackingLinks,
      currentCheckoutField,
      setCurrentCheckoutField,
      matchAllTickets,
      setMatchAllTickets,
      selectedTickets,
      setSelectedTickets,
    }
  }, [
    page,
    setPage,
    filterByData,
    setFilterByData,
    genderFilter,
    filterByOrder,
    setFilterByOrder,
    selectedIds,
    filteredIds,
    selectedEvents,
    selectedTags,
    selectedTrackingLinks,
    isFilterActive,
    selectedAllAttendees,
    isEventScoped,
    showAttendeeProfile,
    matchAllEvents,
    matchAllTags,
    matchAllTrackingLinks,
    currentCheckoutField,
    setCurrentCheckoutField,
    matchAllTickets,
    selectedTickets,
  ])

  return <AttendeesCrmContext.Provider value={contextState}>{props.children}</AttendeesCrmContext.Provider>
}

export default AttendeesCrmContextProvider
