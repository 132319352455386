import React, {createContext, PropsWithChildren, useCallback, useState} from 'react'

import {isValidPhoneNumber} from '@posh/utils'
import {CartData} from 'apis/Carts'
import useUpdateCart from 'apis/Carts/useUpdateCart'
import {
  TCheckoutSummaryCustomField,
  TCheckoutSummaryCustomFields,
  TFetchCheckoutSummaryOutput,
} from 'apis/Checkout/useFetchCheckoutSummary'
import {isEmpty} from 'lodash'
import * as yup from 'yup'

export const emailCheckoutFormSchema = yup.object({
  first_name: yup.string().min(2).required('First Name is required.'),
  last_name: yup.string().min(2).required('Last Name is required.'),
  phone_number: yup
    .string()
    .test('is-valid-phone-number', 'Please input a valid phone number.', testForValidMobileNumber)
    .required('Phone Number is required.'),
  email: yup.string().trim().email('Please input valid Email Address').required('Email is required.'),
  password: yup
    .string()
    .min(8, 'Password is too short - should be at least 8 characters.')
    .required('Password is required.')
    .nullable(),
  confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match.'),
})

export const tokenCheckoutFormSchema = yup.object({
  first_name: yup.string().min(2).required('First Name is required.'),
  last_name: yup.string().min(2).required('Last Name is required.'),
  phone_number: yup
    .string()
    .test('is-valid-phone-number', 'Please input a valid phone number.', testForValidMobileNumber)
    .required('Phone Number is required.'),
  email: yup.string().trim().email('Please input valid Email Address').required('Email is required.'),
})

function testForValidMobileNumber(phoneNumber = 'NONE') {
  return isValidPhoneNumber(phoneNumber)
}

interface CartProps {
  checkoutSummary: TFetchCheckoutSummaryOutput
  isRSVP: boolean
  cartId: string
}

const isCheckoutResponseCompleted = (response: TCheckoutSummaryCustomField) => {
  switch (response.type) {
    case 'file_upload':
      return !!response.url
    case 'checkboxes':
      return !isEmpty(response.answers)
    case 'input':
    case 'dropdown':
      return !!response.answer
    default:
      return false
  }
}

// Only things that affect the price of the cart are DB backed, and checkout responses.
// This includes promo code, and payment plan selection.
// We do not DB back account creation data, or billing info data.
function useProviderValue({checkoutSummary, isRSVP, cartId}: CartProps) {
  const [checkoutResponses, setCheckoutResponses] = useState<TCheckoutSummaryCustomFields | undefined>(
    () => checkoutSummary.checkoutFieldsAndResponses,
  )
  const cartHasCustomCheckoutFields = checkoutResponses !== undefined
  const [customFieldErrors, setCustomFieldErrors] = useState<Record<string, string | undefined>>({})
  const [formErrors, setFormErrors] = useState<Record<string, string | undefined>>({})
  const [stripeError, setStripeError] = useState<string | undefined>(undefined)

  const updateStripeError = useCallback(
    (error: string | undefined) => {
      setStripeError(error)
    },
    [setStripeError],
  )

  const validateAllRequiredCheckoutFields = useCallback(
    /**
     * This function will validate all required custom checkout fields have been answered.
     * For those that have not, the customFieldErrors object will be updated and store
     * errors for each fieldId.
     *
     * @returns true if all required fields have been answered or there are no required fields, false otherwise
     */
    () => {
      setCustomFieldErrors({})
      const newCustomFieldsErrors: Record<string, string | undefined> = {}
      if (!cartHasCustomCheckoutFields) return true
      checkoutResponses.forEach(response => {
        if (!response.required) return
        if (!isCheckoutResponseCompleted(response)) {
          newCustomFieldsErrors[response.fieldId.toString()] = `${response.prompt} is required.`
        }
      })
      setCustomFieldErrors(newCustomFieldsErrors)
      return isEmpty(newCustomFieldsErrors)
    },
    [cartHasCustomCheckoutFields, checkoutResponses],
  )

  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState<number | undefined>(
    () => checkoutSummary.paymentPlansInfo?.selectedPaymentPlan,
  )
  const [cartFormData, setCartFormData] = useState<CartData>({})
  const updateCartFormField = useCallback(
    (key: string, value: string) => {
      const nonValueDataForField = cartFormData[key]
      setCartFormData({
        ...cartFormData,
        [key]: {
          ...nonValueDataForField,
          value,
        },
      })
    },
    [cartFormData],
  )

  const {mutateAsync: updateCart} = useUpdateCart()
  const choosePaymentPlan = useCallback(
    (planNumber: 0 | 1 | undefined) => {
      setSelectedPaymentPlan(planNumber)
      const parsedPaymentPlan: '0' | '1' | 'unselected' =
        planNumber === undefined ? 'unselected' : (planNumber.toString() as '0' | '1')
      updateCart({
        cartId,
        selectedPaymentPlan: parsedPaymentPlan,
      })
    },
    [cartId, updateCart],
  )
  const applyPromoCode = useCallback(
    async (promoCode: string) => {
      await updateCart({
        cartId,
        promoCodeValue: promoCode,
      })
    },
    [cartId, updateCart],
  )
  const updateCheckoutResponses = useCallback(
    (newCheckoutResponses: TCheckoutSummaryCustomFields) => {
      setCheckoutResponses(newCheckoutResponses)
      updateCart({
        cartId,
        checkoutResponses: newCheckoutResponses,
      })
    },
    [cartId, updateCart],
  )

  return {
    checkoutSummary,
    setFormErrors,
    setCustomFieldErrors,
    checkoutResponses,
    updateCartFormField,
    updateCheckoutResponses,
    formErrors,
    customFieldErrors,
    stripeError,
    updateStripeError,
    validateAllRequiredCheckoutFields,
    selectedPaymentPlan,
    choosePaymentPlan,
    applyPromoCode,
    cartFormData,
    cartId,
    isRSVP,
    cartHasCustomCheckoutFields,
  }
}

export type LiveCartContext = ReturnType<typeof useProviderValue>

const context = createContext<LiveCartContext | null>(null)
context.displayName = 'PermissionsContext'

export default function useLiveCartContext() {
  const liveCartContext = React.useContext(context)
  if (!liveCartContext) throw 'LiveCartContext must be provided'
  return liveCartContext
}

export const LiveCartContextProvider = (props: PropsWithChildren<CartProps>) => {
  const {checkoutSummary, isRSVP, children, cartId} = props
  const value = useProviderValue({checkoutSummary, isRSVP, cartId})
  return <context.Provider value={value}>{children}</context.Provider>
}
