import * as Sentry from '@sentry/react'

import type {MonitoringProps} from './mount'
import type {MonitoringUser} from './setUser'

export function mount(props: MonitoringProps) {
  Sentry.init({
    dsn: 'https://7957d234b4b24ea1849bcbda3f525ab3@o1153546.ingest.sentry.io/4505116094758912',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: props.env,
  })
}

export function setUser(props?: MonitoringUser) {
  if (props) {
    Sentry.setUser({...props, ip_address: '{{auto}}'})
  } else {
    Sentry.setUser(null)
  }
}
