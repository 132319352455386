import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

async function verifyEmail(accountId: string, token: string) {
  const response = await PoshApi.verifyEmail(accountId, token)
  const data = response.data
  return data
}

export default function useVerifyEmail() {
  return useMutation((params: {accountId: string; token: string}) => verifyEmail(params.accountId, params.token))
}
