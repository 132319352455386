import React, {useState} from 'react'

import {Ticket} from 'apis/Events/types'
import {FilterBy, FilterData, Gender} from 'apis/Groups'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import useFetchGroupTagsAndEvents from '../../../../apis/Groups/useFetchGroupTagsAndEvents'
import PoshStyledModal, {PoshStyledModalProps} from '../../../../components/modals/Modal/PoshStyledModal'
import {useGroupMatchParams} from '../../../../domains/Groups/helpers'
import useAttendeesCrmContext from '../AttendeesCrmContext'
import DataFilters from './dataFilters'
import EventFilters from './eventFilters'
import GenderFilters from './genderFilters'
import TagFilters from './tagfilters'
import TicketsPurchasedFilters from './ticketsPurchasedFilters'
import TrackingLinkFilters from './trackingLinkFilters'

import './styles.scss'

const ErrorMessage = () => <p>Something went wrong :(</p>

const Loading = () => (
  <div style={{width: '100%', display: 'flex', justifyContent: 'center', margin: 'auto', alignContent: 'center'}}>
    <SpinLoader />
  </div>
)

interface AttendeesCrmActionsModalProps extends PoshStyledModalProps {
  tickets?: Ticket[]
  onApplyFilters?: () => void
}

const AttendeesCrmActionsModal = (props: AttendeesCrmActionsModalProps) => {
  const {groupId} = useGroupMatchParams()
  const {tickets, onClose, isOpen, onApplyFilters} = props
  const {data: tagsAndEvents, isFetching, isError} = useFetchGroupTagsAndEvents(groupId)
  const {
    isEventScoped,
    setIsFilterActive,
    setFilterByOrder,
    setFilterByData,
    setSelectedEvents,
    setSelectedTags,
    setSelectedTrackingLinks,
    setSelectedTickets,
    selectedTags,
    selectedEvents,
    selectedTrackingLinks,
    selectedTickets,
    matchAllTags,
    matchAllEvents,
    matchAllTrackingLinks,
    matchAllTickets,
    setMatchAllTickets,
    setMatchAllEvents,
    setMatchAllTags,
    setMatchAllTrackingLinks,
    genderFilter,
    setGenderFilter,
  } = useAttendeesCrmContext()

  const Filters = () => {
    const {domain} = useResourcePageParams()
    const [data, setData] = useState<FilterData>('')
    const [filterBy, setFilterBy] = useState<FilterBy>('')
    const [selectedTagIds, setTags] = useState<string[]>(selectedTags)
    const [selectedEventIds, setEvents] = useState<string[]>(selectedEvents)
    const [selectedTicketIds, setTicketIds] = useState<string[]>(selectedTickets)
    const [isMatchingAllEvents, setIsMatchingAllEvents] = useState<boolean>(matchAllEvents ?? false)
    const [isMatchingAllTags, setIsMatchingAllTags] = useState<boolean>(matchAllTags ?? false)
    const [isMatchingAllTickets, setIsMatchingAllTickets] = useState<boolean>(matchAllTickets ?? false)
    const [currentGenderFilter, setCurrentGenderFilter] = useState<Gender>(genderFilter)
    const [isMatchingAllTrackingLinks, setIsMatchingAllTrackingLinks] = useState<boolean>(
      matchAllTrackingLinks ?? false,
    )
    const [selectedTrackingLinkNames, setTrackingLinks] = useState<string[]>(selectedTrackingLinks)
    const isHost = domain == 'host'

    const onFilter = () => {
      onApplyFilters?.()
      if (data) setFilterByData(data)
      if (filterBy) setFilterByOrder(filterBy)
      if (!isEventScoped && selectedEventIds) setSelectedEvents(selectedEventIds)
      if (isEventScoped && selectedTrackingLinkNames) setSelectedTrackingLinks(selectedTrackingLinkNames)
      if (isEventScoped && selectedTicketIds) setSelectedTickets(selectedTicketIds)
      if (selectedTagIds) setSelectedTags(selectedTagIds)
      if (currentGenderFilter) setGenderFilter(currentGenderFilter)
      setMatchAllEvents(isMatchingAllEvents)
      setMatchAllTags(isMatchingAllTags)
      setMatchAllTickets(isMatchingAllTickets)
      setMatchAllTrackingLinks(isMatchingAllTrackingLinks)
      setIsFilterActive(true)
      onClose()
    }

    return (
      <div className='Filters'>
        <DataFilters data={data} setData={setData} filterBy={filterBy} setFilterBy={setFilterBy} />
        <GenderFilters setGenderFilter={setCurrentGenderFilter} genderFilter={currentGenderFilter} />
        {!isHost && (
          <TagFilters
            tags={tagsAndEvents?.tags ?? []}
            selectedTags={selectedTagIds}
            setTags={setTags}
            setMatchAllTags={setIsMatchingAllTags}
            matchAllTags={isMatchingAllTags}
          />
        )}
        {!isHost && tickets && isEventScoped && (
          <TicketsPurchasedFilters
            tickets={tickets}
            selectedTickets={selectedTicketIds}
            setTickets={setTicketIds}
            matchAllTickets={isMatchingAllTickets}
            setMatchAllTickets={setIsMatchingAllTickets}
          />
        )}
        {!isHost && isEventScoped && (
          <TrackingLinkFilters
            selectedTrackingLinks={selectedTrackingLinkNames}
            setTrackingLinks={setTrackingLinks}
            matchAllTrackingLinks={isMatchingAllTrackingLinks}
            setMatchAllTrackingLinks={setIsMatchingAllTrackingLinks}
          />
        )}
        {!isEventScoped && (
          <EventFilters
            events={tagsAndEvents?.events ?? []}
            selectedEvents={selectedEventIds}
            setEvents={setEvents}
            setMatchAllEvents={setIsMatchingAllEvents}
            matchAllEvents={isMatchingAllEvents}
          />
        )}
        <Button style={{margin: 'auto'}} className='Button--rounded' onClick={() => onFilter()}>
          Apply Filters
        </Button>
      </div>
    )
  }

  return (
    <PoshStyledModal isOpen={isOpen} onClose={() => onClose()}>
      {isFetching && <Loading />}
      {isError && <ErrorMessage />}
      {!isFetching && !isError && <Filters />}
    </PoshStyledModal>
  )
}

export default AttendeesCrmActionsModal
