import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
import {AccountRoleResponse} from 'apis/Roles'

export interface CheckPoshAdminResponse {
  authorized: boolean
  accountRole: AccountRoleResponse
  enabledPermissions: string[]
}

async function fetchCheckIfPoshAdmin(): Promise<CheckPoshAdminResponse> {
  const response = await PoshApi.checkPoshAdmin()
  return response.data
}

export default function useCheckPoshAdmin() {
  return useQuery<CheckPoshAdminResponse>(['check-posh-admin'], () => fetchCheckIfPoshAdmin(), {})
}
