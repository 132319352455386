import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'

import {TCrossSectionType, TDataPoint, useGetSectionalData} from 'apis/Analytics/useGetSectionalData'
import Button from 'components/form/Button'
import {WithToolTip} from 'components/form/PoshInfo'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {CrossSection} from '../CrossSection/CrossSection'
import {AnalyticsSection} from '../Shared/AnalyticsSection/AnalyticsSection'
import {Text} from '../Shared/Text/Text'
import {parseNumberValue} from '../Shared/utils'

import styles from './Tickets.module.scss'
interface TicketsSectionProps {
  eventId: string
  groupId: string
  isRSVPEvent?: boolean
  isLoading?: boolean
}

const RSVP_TICKET_DATA_POINTS: TDataPoint[] = ['scannedTickets', 'ticketSourceBreakdownRSVP']
const TICKET_DATA_POINTS: TDataPoint[] = ['scannedTickets', 'totalTicketTypes', 'ticketSourceBreakdown']

const HOST_TICKET_DATA_POINTS: TDataPoint[] = ['scannedTickets', 'totalTicketTypes', 'attendeeEventRetention']

export const TicketsSection = (props: TicketsSectionProps) => {
  const {eventId, groupId, isRSVPEvent} = props
  const {domain} = useResourcePageParams()
  const navigate = useNavigate()

  const DATA_POINTS = useMemo(() => {
    if (isRSVPEvent) return RSVP_TICKET_DATA_POINTS
    if (domain === 'host') return HOST_TICKET_DATA_POINTS
    return TICKET_DATA_POINTS
  }, [isRSVPEvent, domain])

  const navigateToTicketsPage = () => {
    navigate(`/${domain}/groups/${groupId}/events/${eventId}/tickets`)
  }

  const {data, isLoading, isError, isSuccess} = useGetSectionalData(
    {
      eventId,
      groupId,
      dataPoints: DATA_POINTS,
    },
    {enabled: props.isLoading !== true},
  )

  return (
    <AnalyticsSection>
      <AnalyticsSection.Header>
        <AnalyticsSection.Title>{isRSVPEvent ? 'RSVPs' : 'Tickets'}</AnalyticsSection.Title>
        <Button onClick={navigateToTicketsPage}>View {isRSVPEvent ? 'RSVPs' : 'Tickets'}</Button>
      </AnalyticsSection.Header>
      {(isLoading || props.isLoading) && (
        <CrossSection.Section className={styles.TicketsCrossSectionContainer}>
          {[...Array(DATA_POINTS.length)].map((_, index) => (
            <CrossSection key={index}>
              <CrossSection.Row>
                <Text.Loading width={150} />
              </CrossSection.Row>
              <CrossSection.Row>
                <Text.Loading size='xl' width={100} />
              </CrossSection.Row>
              <CrossSection.Row>
                <Text.Loading size='small' />
              </CrossSection.Row>
              <CrossSection.Row>
                <div
                  style={{display: 'flex', width: '100%', height: 250, justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{width: 200, height: 200, borderRadius: '50%', backgroundColor: 'lightgrey'}}></div>
                </div>
              </CrossSection.Row>
            </CrossSection>
          ))}
        </CrossSection.Section>
      )}

      {isError && (
        <CrossSection.Section>
          <CrossSection>
            <CrossSection.Row>
              <Text size='xl' bold>
                Error
              </Text>
            </CrossSection.Row>
            <CrossSection.Row>
              <Text>Something went wrong. Contact support@posh.vip</Text>
            </CrossSection.Row>
          </CrossSection>
        </CrossSection.Section>
      )}

      {isSuccess && (
        <CrossSection.Section className={styles.TicketsCrossSectionContainer}>
          {data.map((crossSection, index) => (
            <CrossSection key={crossSection.title}>
              <CrossSection.Row>
                <Text>{crossSection.title}</Text>
                <WithToolTip
                  info={getTooltipText(crossSection.type, isRSVPEvent)}
                  align={index === data.length - 1 ? 'left' : undefined}>
                  <CrossSection.Icon name='info' />
                </WithToolTip>
              </CrossSection.Row>
              <CrossSection.Row>
                <Text size='xl' bold>
                  {parseNumberValue(crossSection.mainValue)}
                </Text>
              </CrossSection.Row>
              <CrossSection.Row>
                {crossSection.label && <CrossSection.Subtitle muted>{crossSection.label}</CrossSection.Subtitle>}
              </CrossSection.Row>
              {crossSection.chart && (
                <CrossSection.Row style={{marginTop: 5}}>
                  <CrossSection.Chart chart={crossSection.chart} id={`${crossSection.title}-chart`} />
                </CrossSection.Row>
              )}
            </CrossSection>
          ))}
        </CrossSection.Section>
      )}
    </AnalyticsSection>
  )
}

export const getTooltipText = (type: TCrossSectionType, isRSVPEvent?: boolean) => {
  switch (type) {
    case 'scannedTickets':
      return `A breakdown of how many ${isRSVPEvent ? 'RSVPs' : 'tickets'} have been scanned or used.`
    case 'totalTicketTypes':
      return 'A breakdown of each ticket type that was sold.'
    case 'ticketSourceBreakdown':
      return 'A breakdown of where tickets were sold from.'
    case 'ticketSourceBreakdownRSVP':
      return 'A breakdown of where RSVPs were made from.'
    default:
      return ''
  }
}
