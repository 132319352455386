import React from 'react'
import {Route, Routes} from 'react-router-dom'

import PageNotFound from 'pages/PageNotFound/PageNotFound'

import RequirePermissions, {requirePermissionAndScope} from '../../../components/RequirePermissions'
import OrderPage from './OrderPage'
import {OrdersPage} from './OrdersPage'

import './styles.scss'

const OrderRoutes = () => {
  return (
    <Routes>
      <Route
        path=''
        element={
          <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_orders_list', ['group'])]}>
            <OrdersPage />
          </RequirePermissions>
        }
      />
      <Route
        path={`:orderId`}
        element={
          <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_order', ['group', 'event'])]}>
            <OrderPage />
          </RequirePermissions>
        }
      />
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  )
}

export default OrderRoutes
