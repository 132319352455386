import React from 'react'
import ReactSwitch, {ReactSwitchProps} from 'react-switch'

import classNames from 'classnames'

import './index.scss'

export interface PoshSwitchProps {
  title?: string
  rightTitle?: string
  className?: string
  switchOptions: ReactSwitchProps
  disabled?: boolean
  onColor?: string
  height?: number
  width?: number
  subtitle?: string
  textColor?: string
}
const PoshSwitch = (props: PoshSwitchProps) => {
  const {
    disabled,
    className,
    title,
    switchOptions,
    rightTitle,
    onColor = '#00ccff',
    height = 24,
    width = 40,
    subtitle,
    textColor = 'white',
  } = props
  const finalClassName = classNames(['PoshSwitch', className])

  return (
    <label className={finalClassName}>
      {title && <span>{title}</span>}
      <ReactSwitch
        onColor={onColor}
        height={height}
        width={width}
        checkedIcon={false}
        uncheckedIcon={false}
        disabled={disabled}
        {...switchOptions}
      />
      <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
        {rightTitle && (
          <label className='noMargin' style={{color: textColor}}>
            {rightTitle}
          </label>
        )}
        {subtitle && (
          <p className={`noMargin text-xs`} style={{width: 'fit-content', color: textColor, textAlign: 'start'}}>
            {subtitle}
          </p>
        )}
      </div>
    </label>
  )
}

export default PoshSwitch
