import React, {forwardRef} from 'react'

import './styles.scss'

export enum TextFieldVariants {
  LIGHT = 'light',
  DARK = 'dark',
  DARK_OUTLINED = 'darkOutlined',
  WITH_ICON = 'withIcon',
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: string
}

const getVariant = (variant?: string) => {
  switch (variant) {
    case TextFieldVariants.LIGHT:
      return 'TextField-light'
    case TextFieldVariants.DARK:
      return 'TextField-dark'
    case TextFieldVariants.DARK_OUTLINED:
      return 'TextField-darkOutlined'
    case TextFieldVariants.WITH_ICON:
      return 'TextField-withIcon'
    default:
      return 'TextField-dark'
  }
}

const TextField = forwardRef<HTMLInputElement, Props>(({className, ...props}, ref) => (
  <input
    ref={ref}
    className={'poshInput ' + getVariant(props.variant) + (` ${className}` || '')}
    type={props.type || 'text'}
    {...props}
  />
))
TextField.displayName = 'TextField'

export default TextField
