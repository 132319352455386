import {ICrossSection} from 'apis/Analytics/useGetSectionalData'

export const parseNumberValue = (value: ICrossSection['mainValue']): string => {
  if (!value) return ''
  switch (value.type) {
    case 'currency':
      const formattedValue = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency,
      }).format(value.value)
      return formattedValue
    case 'number':
      return value.value.toString()
    case 'percentage':
      return `${value.value}%`
    default:
      return ''
  }
}
