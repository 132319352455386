import React from 'react'
import {Route, Routes} from 'react-router-dom'

import ProtectedRoute from 'components/routes/ProtectedRoute'
import CommunityOnboarding from 'pages/CommunityPublic/CommunityOnboarding'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import Community from '..'
import CommunityBookingConfirmation from '../BookingConfirmation'
import CommunityBooking from '../BookingPage'
import CommunityFooter from '../Footer'
import {PublicCommunityLanding} from '../PublicCommunityLanding'

export const CommunityRoutes = () => {
  return (
    <Routes>
      <Route path='' element={<PublicCommunityLanding />} />
      <Route
        path='explore'
        element={
          <>
            <Community />
            <CommunityFooter />
          </>
        }
      />
      <Route path='explore' element={<Community />} />
      {/* <Route path='subscribe' element={<CommunitySubscription />} /> */}
      <Route element={<ProtectedRoute isCommunityRoute={true} />}>
        <Route path='signup' element={<CommunityOnboarding />} />
        <Route path='book' element={<CommunityBooking />} />
        <Route path='confirmation/:bookingId' element={<CommunityBookingConfirmation />} />
        <Route path={'*'} element={<PageNotFound />} />
      </Route>
    </Routes>
  )
}
