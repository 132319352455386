import {AccountRoleResponse} from 'apis/Roles'
import useSessionContext, {SessionContextState} from 'domains/Auth/SessionContext'
import {ReactQueryOptions, RouterOutput, trpc} from 'lib/trpc'

type CreateGroupOptions = ReactQueryOptions['groups']['createNewGroup']
export type CreateGroupResponse = RouterOutput['groups']['createNewGroup']

export function useCreateGroup(opts?: CreateGroupOptions) {
  const sessionContext = useSessionContext()
  return trpc.groups.createNewGroup.useMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      const {accountRoles} = data
      setAccountRoleContext(accountRoles, sessionContext)
      opts?.onSuccess?.(data, variables, context)
    },
  })
}

export const setAccountRoleContext = (accountRoles: AccountRoleResponse[], sessionContext: SessionContextState) => {
  const {setAccountRoles} = sessionContext
  localStorage.setItem('accountRoles', JSON.stringify(accountRoles))
  setAccountRoles(accountRoles)
}
