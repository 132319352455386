import {useToast} from 'components/toasts/ToastProvider'
import {trpc} from 'lib/trpc'

export const useSwitchGroupPayoutsInterval = () => {
  const queryClient = trpc.useContext()
  const {showToast} = useToast()
  return trpc.groups.updatePayoutsInterval.useMutation({
    onSuccess: (_, input) => {
      showToast({
        type: 'success',
        title: 'Your payout schedule has been updated!',
      })
      // The delay of processing the webhook may be too long to see the changes
      // reflected for the user quick enough so we use optimistic updates instead
      // of just relying on the cache invalidation in onSettled
      queryClient.groups.getGroupForFinancials.setData({groupId: input.groupId}, old => {
        if (!old) return old
        return {
          ...old,
          manualPayouts: input.interval === 'manual' ? true : false,
        }
      })
    },
    onError: error => {
      showToast({
        type: 'error',
        title: 'Something went wrong while updating your payout schedule.',
        subtitle: error.message,
      })
    },
    onSettled: () => queryClient.groups.getGroupForFinancials.invalidate(),
  })
}
