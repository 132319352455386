import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetYoutubeVideosOptions = ReactQueryOptions['utils']['getYoutubeVideos']
type GetYoutubeVideosInput = RouterInputs['utils']['getYoutubeVideos']
export type GetYoutubeVideosOutput = RouterOutput['utils']['getYoutubeVideos']

export type YoutubeVideo = GetYoutubeVideosOutput['videos'][number]

export function useGetYoutubeVideos(input: GetYoutubeVideosInput, opts?: GetYoutubeVideosOptions) {
  return trpc.utils.getYoutubeVideos.useQuery(input, opts)
}
