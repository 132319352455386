import {RouterInputs, RouterOutput, trpc} from '../../lib/trpc'

type Input = RouterInputs['groups']['getTextBlasts']
export type TextBlastForGroup = RouterOutput['groups']['getTextBlasts'][number]

const useFetchTextBlastsForGroup = (input: Input) => {
  return trpc.groups.getTextBlasts.useQuery(input)
}

export default useFetchTextBlastsForGroup
