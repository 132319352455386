import React from 'react'
import {useParams} from 'react-router-dom'

import {DEV_STRIPE_PUBLISHABLE_KEY, STRIPE_PUBLISHABLE_KEY} from '@posh/types'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js'
import {TFetchCheckoutSummaryOutput} from 'apis/Checkout/useFetchCheckoutSummary'
import {useFetchEventByUrl} from 'apis/Events/useFetchEvent'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import isDevEnv from 'helpers/isDevEnv'

import PaymentElementWrapper from '../PaymentElementWrapper'

const isDev = isDevEnv()
const keyToUse = isDev ? DEV_STRIPE_PUBLISHABLE_KEY : STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(keyToUse)

interface ElementsWrapperProps {
  fetchingSummary: boolean
  summary: TFetchCheckoutSummaryOutput | undefined
  hasAgreedToCuratorTos?: boolean
  checkoutDisabled: boolean
}

const DEFAULT_CURRENCY = 'usd'
const ElementsWrapper = (props: ElementsWrapperProps) => {
  const {fetchingSummary, summary, hasAgreedToCuratorTos, checkoutDisabled} = props
  const {eventUrl} = useParams<{eventUrl: string}>()
  const {data: eventsResp} = useFetchEventByUrl(eventUrl)

  const options: StripeElementsOptions = {
    mode: 'payment',
    currency: summary?.cartInfo.currency?.toLocaleLowerCase() ?? DEFAULT_CURRENCY,
    amount: summary?.cartInfo.stripeTotal ?? 0,
    paymentMethodTypes: ['card'],
    setupFutureUsage: 'off_session',
    onBehalfOf: eventsResp?.group.connectId,
    appearance: {
      theme: 'night',

      variables: {
        colorPrimary: '#FFCC00',
        colorBackground: '#222325',
        borderRadius: '50px',
      },
    },
  }

  return !fetchingSummary ? (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Elements stripe={stripePromise} options={options}>
      <PaymentElementWrapper
        summary={summary}
        eventsResp={eventsResp}
        hasAgreedToCuratorTos={hasAgreedToCuratorTos}
        checkoutDisabled={checkoutDisabled}
      />
    </Elements>
  ) : (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px'}}>
      <SpinLoader height={50} />
    </div>
  )
}

export default ElementsWrapper
