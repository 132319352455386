import React from 'react'

import './index.scss'

export type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

const Select = (props: SelectProps) => {
  return (
    <div className='wrapperForCustomArrow'>
      <select {...props} className={'poshSelect ' + (props.className || '')}>
        {props.children}
      </select>
    </div>
  )
}

export default Select
