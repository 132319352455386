import React from 'react'
import {Route, Routes} from 'react-router-dom'

import PageNotFound from 'pages/PageNotFound/PageNotFound'

import RequestPasswordReset from './RequestPasswordReset'
import ResetPasswordSubmit from './ResetPasswordSubmit'

const ResetPasswordPage = () => {
  return (
    <div className='OwnerPage'>
      <Routes>
        <Route path={'new'} element={<RequestPasswordReset />} />
        <Route path={''} element={<ResetPasswordSubmit />} />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </div>
  )
}

export default ResetPasswordPage
