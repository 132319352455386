import React from 'react'

import {motion} from 'framer-motion'

const GAP = 1
const RECTANGLE_BORDER_RADIUS = 5

function getWidthPct({numberOfSteps}: {numberOfSteps: number}) {
  return (100 - GAP * (numberOfSteps - 1)) / numberOfSteps // Calculate the width of each rectangle
}

function StepRectangle({i, numberOfSteps, fill}: {i: number; numberOfSteps: number; fill: string}) {
  const width = getWidthPct({numberOfSteps})
  return <rect x={`${i * (width + GAP)}%`} width={`${width}%`} height='100%' rx={RECTANGLE_BORDER_RADIUS} fill={fill} />
}

const GRADIENT_ID = 'gradient'
function Gradient() {
  return (
    <linearGradient id={GRADIENT_ID} x1='0' y1='0' x2='100%' y2='0'>
      <stop offset='0%' stopColor='#FFCC00' />
      <stop offset='50%' stopColor='#FF006B' />
      <stop offset='100%' stopColor='#9747FF' />
    </linearGradient>
  )
}

const MASK_ID = 'mask'
function Mask({numberOfSteps}: {numberOfSteps: number}) {
  return (
    <mask id={MASK_ID}>
      {Array.from({length: numberOfSteps}, (_, i) => (
        <StepRectangle key={i} i={i} numberOfSteps={numberOfSteps} fill='white' />
      ))}
    </mask>
  )
}

function AnimatedProgressBar({progress}: {progress: `${number}%`}) {
  return (
    <motion.rect
      height='100%'
      rx={RECTANGLE_BORDER_RADIUS}
      fill={`url(#${GRADIENT_ID})`}
      mask={`url(#${MASK_ID})`}
      animate={{width: progress}}
      transition={{duration: 0.5, ease: 'backOut', stiffness: 50, damping: 20}}
    />
  )
}

export interface CreateEventProgressBarProps {
  progress: `${number}%`
  numberOfSteps: number
}

export function CreateEventProgressBar({progress, numberOfSteps}: CreateEventProgressBarProps) {
  return (
    <svg height='100%' width='100%' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <Gradient />
        <Mask numberOfSteps={numberOfSteps} />
      </defs>
      {Array.from({length: numberOfSteps}, (_, i) => (
        <StepRectangle key={i} i={i} numberOfSteps={numberOfSteps} fill='#262729' />
      ))}
      <AnimatedProgressBar progress={progress} />
    </svg>
  )
}
