import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

const invalidateAccount = async (accountId: string): Promise<string> => {
  const response = await PoshApi.invalidateAccount(accountId)
  const message: string = response.data.message
  return message
}

export function useInvalidateAccount() {
  return useMutation((params: {accountId: string}) => invalidateAccount(params.accountId))
}
