import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {
  CommunityArtistsResponse,
  CommunityCategory,
  MarketFilters,
  SortBy,
  StaffMembersResponse,
  VenuesResponse,
} from '.'

export interface CommunityByCategoryParams {
  selectedCategory: CommunityCategory
  market: MarketFilters
  sortBy: SortBy
  selectedChecks?: string[]
  searchQuery?: string
  maxRange?: number
  minRange?: number
}

type TVenue = 'venue'
type Staff = 'staff'
type Artist = 'artist'

export type CategoryResopnseType<T> = T extends TVenue
  ? VenuesResponse
  : T extends Staff
  ? StaffMembersResponse
  : T extends Artist
  ? CommunityArtistsResponse
  : never

async function fetchCommunityByCategory<T>(params: CommunityByCategoryParams): Promise<CategoryResopnseType<T>> {
  const response = await PoshApi.fetchCommunityByCategory(params)
  return response.data
}

export function useFetchCommunityByCategory(params: CommunityByCategoryParams) {
  return useQuery<VenuesResponse | StaffMembersResponse | CommunityArtistsResponse>(
    ['community', params],
    () => {
      if (params.selectedCategory === 'venue') return fetchCommunityByCategory<'venue'>(params)
      else if (params.selectedCategory === 'staff') return fetchCommunityByCategory<'staff'>(params)
      else return fetchCommunityByCategory<'artist'>(params)
    },
    {
      refetchOnMount: false,
    },
  )
}
