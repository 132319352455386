/**
 * @deprecated: for being easily expanded into their use case
 *              or showing no detectable usage
 */
const deprecated = {
    // no usage
    primaryHalfOpacity: { type: 'color', value: '#FFCC0080' },
    // see mapPoint
    primaryQuarterOpacity: { type: 'color', value: '#FFCC0040' },
    // single point of use on explore/search/select.primary.selected
    primaryTenthOpacity: { type: 'color', value: '#FFCC001A' },
    // single point of use on explore/search/select.secondary.selected
    secondaryTenthOpacity: { type: 'color', value: '#00CCFF1A' },
    // used for ToolTip backgroundColor and calendar "todayTextColor"
    primaryDark: { type: 'color', value: '#E0B400' },
};
export const legacyColors = {
    //
    // Literal semantic map from deprecations
    //
    mapPoint: {
        ...deprecated.primaryQuarterOpacity,
        description: 'Used for our map point in mobile/components/map/MapPoint.tsx:80',
    },
    'explore/search/select.primary.selected.backgroundColor': {
        ...deprecated.primaryTenthOpacity,
        description: 'selectedPrimary on app/(index)/(tabs)/(exploreTab)/explore/components/select/ExplorePageSelect.tsx',
    },
    'explore/search/select.secondary.selected.backgroundColor': {
        ...deprecated.primaryTenthOpacity,
        description: 'selectedSecondary on app/(index)/(tabs)/(exploreTab)/explore/components/select/ExplorePageSelect.tsx',
    },
    'components/misc/tooltip.backgroundColor': {
        ...deprecated.primaryDark,
        description: 'backgroundColor of components/misc/ToolTip.tsx',
    },
    'apps/mobile/components/calendar.todayTextColor': {
        ...deprecated.primaryDark,
        description: 'todayTextColor of apps/mobile/components/calendar theme',
    },
    //
    // Existing
    //
    // primary: tokens.affordance.primary.backgroundColor
    primary: { value: '#FFCC00', type: 'color' },
    // See deprecated
    // primaryHalfOpacity: { value: '#FFCC0080', type: 'color' },
    // primaryQuarterOpacity: { value: '#FFCC0040', type: 'color' },
    // primaryTenthOpacity: { value: '#FFCC001A', type: 'color' },
    // primaryDark: { value: '#E0B400', type: 'color' },
    secondary: { value: '#00CCFF', type: 'color' },
    // See deprecated
    // secondaryTenthOpacity: { value: '#00CCFF1A', type: 'color' },
    secondaryDark: { value: '#244745', type: 'color' },
    tertiary: { value: '#FF005C', type: 'color' },
    tertiaryDark: { value: '#C50047', type: 'color' },
    communityGreen: { value: '#51D300', type: 'color' },
    success: { value: '#05FF00', type: 'color' },
    successDark: { value: '#013220', type: 'color' },
    error: { value: '#D85151', type: 'color' },
    warning: { value: '#FFB800', type: 'color' },
    lightPurple: { value: '#DBAEFF', type: 'color' },
    lightBlue: { value: '#AEC0FF', type: 'color' },
    pink: { value: '#FF4B8C', type: 'color' },
    verified: { value: '#FFCC00', type: 'color' },
    // Indicating dangerous actions (delete, etc)
    danger: { value: '#FF5555', type: 'color' },
    // Status Circles
    green: { value: '#54D851', type: 'color' },
    blue: { value: '#51C8D8', type: 'color' },
    red: { value: '#D85151', type: 'color' },
    orange: { value: '#D1951F', type: 'color' },
    // Backgrounds
    backgroundBlack: { value: '#0a0b0d', type: 'color' },
    backgroundDarker: { value: '#101113', type: 'color' },
    backgroundDark: { value: '#151618', type: 'color' },
    backgroundMediumDark: { value: '#191a1c', type: 'color' },
    backgroundMedium: { value: '#222325', type: 'color' },
    backgroundLight: { value: '#262729', type: 'color' },
    backgroundPurple: { value: '#AB40FF', type: 'color' },
    backgroundSkyBlue: { value: '#04A9D4', type: 'color' },
    // Greys & Whites
    white: { value: '#ffffff', type: 'color' },
    offWhite: { value: '#f0f1f3', type: 'color' },
    greyLight: { value: '#c0c1c3', type: 'color' },
    greyMediumLight: { value: '#909193', type: 'color' },
    greyHalfOpacity: { value: '#7a7b7d80', type: 'color' },
    grey: { value: '#7a7b7d', type: 'color' },
    black: { value: '#000000', type: 'color' },
    // Borders
    borderDark: { value: '#303133', type: 'color' },
    borderDarker: { value: '#1a1b1d', type: 'color' },
    // opacities
    opacityZero: { value: '#00000000', type: 'color' },
    opacityQuarter: { value: '#00000040', type: 'color' },
    opacityHalf: { value: '#00000080', type: 'color' },
    opacityThreeQuarter: { value: '#000000bf', type: 'color' },
    opacityFull: { value: '#000000ff', type: 'color' },
    // Badge colors
    badgeBlue: { value: '#3FDCFF', type: 'color' },
    badgeOrange: { value: '#FFB800', type: 'color' },
    badgeGreen: { value: '#4EFF3F', type: 'color' },
    // Gender Pie Chart Colors
    menColor: { value: '#428EFF', type: 'color' },
    womenColor: { value: '#DB00FF', type: 'color' },
    // Misc Colors
    pumpkinOrange: { value: '#f99428', type: 'color' },
    contextMenuBackground: { value: '#1a1a1c', type: 'color' },
    mailChimpPrimary: { value: '#FFE01B', type: 'color' },
    facebookBlue: { value: '#1877F2', type: 'color' },
    snapchatYellow: { value: '#FFFC00', type: 'color' },
};
export const legacyGradients = {
    backGroundGradient: {
        type: 'object',
        value: ['rgba(25, 26, 28, 1)', 'rgba(10, 11, 13, 1)'],
    },
    instagramGradient: {
        type: 'object',
        value: ['#FEDA75', '#FA7E1E', '#D62976', '#962FBF', '#4F5BD5'],
    },
};
