import {useCallback, useMemo, useState} from 'react'

import {useToast} from 'components/toasts/ToastProvider'
import {camelCase} from 'lodash'
import {BaseEditor, createEditor, Descendant, Editor, Node, Text, Transforms} from 'slate'
import {ReactEditor, withReact} from 'slate-react'

type ParagraphElement = {type: 'paragraph'; children: Descendant[]}
type BadgeElement = {type: 'badge'; children: Descendant[]}
type CustomElement = ParagraphElement | BadgeElement
declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: CustomElement
  }
}

const withInlines = (editor: Editor) => {
  const {isInline, isElementReadOnly, isSelectable} = editor

  editor.isInline = element => element.type === 'badge' || isInline(element)

  editor.isElementReadOnly = element => element.type === 'badge' || isElementReadOnly(element)

  editor.isSelectable = element => element.type !== 'badge' || isSelectable(element)

  return editor
}

export const ATTENDEE_FIELDS = ['FIRST NAME', 'LAST NAME']
// prettier-ignore
type AttendeeFields = (typeof ATTENDEE_FIELDS)[number]

export const useEditor = () => {
  const [editor] = useState(() => withInlines(withReact(createEditor())))
  const {showToast} = useToast()
  const [parsedMessage, setParsedMessage] = useState<string | undefined>(undefined)

  const insertBadge = useCallback(
    (text: AttendeeFields) => {
      if (editor.selection) {
        Transforms.insertNodes(editor, {
          type: 'badge',
          children: [{text}],
        })

        showToast({title: `Inserted ${text} field`, type: 'success'})
      } else {
        showToast({title: 'Please select a location to insert the field', type: 'error'})
      }
    },
    [editor, showToast],
  )

  const serialize = useCallback((value: Node): string => {
    if (Text.isText(value)) {
      return value.text
    }

    if (Editor.isEditor(value)) {
      return value.children.map(n => serialize(n)).join('')
    }

    const children = value.children.map(n => serialize(n)).join('')
    switch (value.type) {
      case 'paragraph':
        return `${children}\n`
      case 'badge':
        return `{{${camelCase(children)}}}`
      default:
        return ''
    }
  }, [])

  const onChangeMessage = useCallback(() => {
    setParsedMessage(serialize(editor))
  }, [editor, serialize])

  const adjustedMessageLength = useMemo(() => {
    const bracketRegex = /{{[^}]+}}/g
    // Replace bracketed words with 5 x's so they all generically add 5 to the length
    const modifiedString = parsedMessage?.replace(bracketRegex, '') ?? ''
    return modifiedString.length
  }, [parsedMessage])

  return {
    editor,
    insertBadge,
    onChangeMessage,
    parsedMessage,
    adjustedMessageLength,
  }
}
