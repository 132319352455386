import React, {useState} from 'react'

import {useSubscribeToHosted} from 'apis/Util/useSubscribeToHosted'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'

import './styles.scss'

export const NewsletterBlockInput = () => {
  const [email, setEmail] = useState('')
  const {showToast} = useToast()
  const [subscribed, setSubscribed] = useState(false)

  const {mutate: subscribeToHosted} = useSubscribeToHosted({
    onSuccess: () => {
      setSubscribed(true)
    },
    onError: () => {
      showToast({type: 'error', title: 'There was an issue subscribing to Hosted By POSH'})
    },
  })

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      subscribeToHosted({email})
    }
  }

  return (
    <div className='NewsletterBlock-input'>
      {subscribed ? (
        <div className='NewsletterBlock-input-subscribed'>You subscribed to Hosted By POSH!</div>
      ) : (
        <>
          <input
            placeholder='Enter your email'
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <PoshImage
            src='https://images.posh.vip/b3/icons/paper-plane.svg'
            className='NewsletterBlock-input-send'
            onClick={() => subscribeToHosted({email})}
          />
        </>
      )}
    </div>
  )
}

export const NewsletterBlock = () => {
  return (
    <div className='NewsletterBlock'>
      <div className='NewsletterBlock-prompt'>Subscribe to &quot;HOSTED&quot; &mdash; our weekly newsletter</div>
      <NewsletterBlockInput />
    </div>
  )
}
