import React, {useState} from 'react'

import {AppRouter} from '@posh/server'
import {CurrencyCode} from '@posh/types'
import {Currency} from '@posh/utils'
import {TRPCClientErrorLike} from '@trpc/client'
import {useFetchTimeSeriesAnalytics} from 'apis/Events/useFetchTimeSeriesAnalytics'
import LineGraph from 'components/charts/LineGraph'
import {generateSupportEmailHref} from 'helpers/generateSupportEmailHref'
import useDebounce from 'hooks/useDebounce'
import moment from 'moment'
import {useEventManagementContext} from 'pages/EventManagementPages/EventManagementContext'

import {CrossSection} from '../CrossSection/CrossSection'
import {Card} from '../Shared/Card/Card'
import {Text} from '../Shared/Text/Text'
import {parseNumberValue} from '../Shared/utils'
import {getDateRange} from './getTimeSerisConfiguration'
import {DateRange, OverviewHeader} from './Header'
import {OverviewDropdown, OverviewDropdownOption} from './OverviewDropdown'
import {TOverviewTimePeriod} from './TimePeriodSelector'

import styles from './TimeSeriesSection.module.scss'

const DEFAULT_TIME_PERIOD: TOverviewTimePeriod = 'week'

const generateAnalyticsSupportEmail = ({
  dataType,
  groupId,
  eventId,
  selectedTimePeriod,
  dateRange,
  selectedOption,
  error,
}: {
  dataType: 'crossSection' | 'graph'
  groupId: string
  eventId: string
  selectedTimePeriod: TOverviewTimePeriod
  dateRange: DateRange
  selectedOption: OverviewDropdownOption
  error: TRPCClientErrorLike<AppRouter> | null
}) => {
  return generateSupportEmailHref(
    `Analytics Time Series ${dataType === 'crossSection' ? 'Cross Section' : 'Graph'} - Error`,
    `Information for Support: \n\n Group ID: ${groupId} \n Event ID: ${eventId} \n Time Period: ${selectedTimePeriod} \n Date Range: ${
      dateRange.startDate
    } - ${dateRange.endDate} \n Selected Option: ${selectedOption} \n Error: ${JSON.stringify(
      error,
    )} \n\n Please provide any additional context or details here.`,
  )
}

export const getFormatter = (
  option: OverviewDropdownOption,
  currencyCode: CurrencyCode,
  which: 'axis' | 'tooltip',
  isRSVPEvent?: boolean,
) => {
  switch (option) {
    case 'Revenue':
      return (value: number | string) => {
        if (typeof value === 'string') {
          return Currency.format(parseFloat(value), currencyCode)
        }
        return Currency.format(value, currencyCode)
      }
    case 'Tickets':
      return (value: number | string) => {
        if (isRSVPEvent) {
          return `${value}${which == 'tooltip' ? ' RSVPs' : ''}`
        }
        return `${value}${which == 'tooltip' ? ' tickets' : ''}`
      }
    case 'Conversion':
      return (value: number | string) => {
        return `${value}%`
      }
  }
}
const now = moment()
export const TimeSeriesSection = ({
  eventId,
  groupId,
  groupCurrency,
  isLoading: propsIsLoading,
}: {
  eventId: string
  groupId: string
  groupCurrency: CurrencyCode
  isLoading?: boolean
}) => {
  const {event} = useEventManagementContext()
  const {isRSVPEvent} = event

  const eventLifetimeDateRange = {
    startDate: moment(event.createdAt),
    endDate: event.isOver ? moment(event.endUtc) : now,
  }

  const [selectedOption, setSelectedOption] = useState<OverviewDropdownOption>(isRSVPEvent ? 'Tickets' : 'Revenue')
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<TOverviewTimePeriod>(
    event.isOver ? 'event-lifetime' : DEFAULT_TIME_PERIOD,
  )
  const onChangeTimePeriod = (timePeriod: TOverviewTimePeriod) => {
    setSelectedTimePeriod(timePeriod)
    if (timePeriod === 'event-lifetime') {
      return setDateRange(eventLifetimeDateRange)
    }
    if (timePeriod !== 'custom') {
      return setDateRange(getDateRange(timePeriod))
    }
  }

  const GraphStyle = {
    width: '99%',
    height: '297px',
  }

  const [dateRange, setDateRange] = useState<DateRange>(() =>
    event.isOver ? eventLifetimeDateRange : getDateRange(DEFAULT_TIME_PERIOD),
  )
  const onSetCustomDates = ({startDate, endDate}: DateRange) => {
    if (!endDate) {
      setDateRange({startDate, endDate})
    } else {
      setDateRange({startDate, endDate: endDate.endOf('day')})
    }
  }
  const shouldDebounceValue = !!dateRange.startDate && !!dateRange.endDate
  const debouncedDates = useDebounce(dateRange, 200, shouldDebounceValue)

  const {
    graphData,
    isGraphDataLoading,
    isGraphDataError,
    isGraphDataSuccess,
    crossSectionData,
    isCrossSectionLoading,
    isCrossSectionError,
    crossSectionError,
    graphDataError,
    isCrossSectionSuccess,
  } = useFetchTimeSeriesAnalytics(
    {
      eventId,
      groupId,
      timeSeries: selectedOption.toLowerCase() as Lowercase<OverviewDropdownOption>,
      startDate: debouncedDates.startDate!.toDate(),
      endDate: debouncedDates.endDate!.toDate(),
      isRSVPEvent,
    },
    {
      enabled: propsIsLoading !== true,
    },
  )

  return (
    <div className={styles.OverviewTimeSeriesSection}>
      <OverviewHeader
        selectedTimePeriod={selectedTimePeriod}
        onChangeTimePeriod={onChangeTimePeriod}
        dateRange={dateRange}
        onSetCustomDateRange={onSetCustomDates}
      />
      <CrossSection.Section style={{flexGrow: 0}}>
        {(isCrossSectionLoading || propsIsLoading) &&
          [...Array(2)].map((_, index) => (
            <CrossSection key={index}>
              <CrossSection.Row>
                <Text.Loading width={150} />
              </CrossSection.Row>
              <CrossSection.Row>
                <Text.Loading size='xl' width={100} />
              </CrossSection.Row>
              <CrossSection.Row>
                <Text.Loading size='small' />
              </CrossSection.Row>
            </CrossSection>
          ))}

        {isCrossSectionError && (
          <CrossSection>
            <CrossSection.Row>
              <Text size='xl' bold>
                Error
              </Text>
            </CrossSection.Row>
            <CrossSection.Row>
              <Text>
                Something went wrong fetching analytics data. Contact{' '}
                <a
                  href={generateAnalyticsSupportEmail({
                    dataType: 'crossSection',
                    groupId,
                    eventId,
                    selectedTimePeriod,
                    dateRange,
                    selectedOption,
                    error: crossSectionError,
                  })}>
                  support
                </a>{' '}
                if this error persists.
              </Text>
            </CrossSection.Row>
          </CrossSection>
        )}

        {isCrossSectionSuccess && crossSectionData && (
          <>
            {crossSectionData.map(crossSection => (
              <CrossSection key={crossSection.title}>
                <CrossSection.Row>
                  <Text>{crossSection.title}</Text>
                </CrossSection.Row>
                <CrossSection.Row>
                  <Text size='xl' bold>
                    {parseNumberValue(crossSection.mainValue)}
                  </Text>
                </CrossSection.Row>
              </CrossSection>
            ))}
          </>
        )}
      </CrossSection.Section>
      {/* Graph */}
      <Card style={{gap: '25px', display: 'flex', flexDirection: 'column', flex: 1}}>
        <OverviewDropdown selectedValue={selectedOption} onSelect={setSelectedOption} isRSVPEvent={isRSVPEvent} />
        {(isGraphDataLoading || propsIsLoading) && <LineGraph.Loading style={GraphStyle} />}

        {isGraphDataError && (
          <div
            style={{
              ...GraphStyle,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 10,
            }}>
            <Text size='large' bold>
              Error
            </Text>
            <Text>
              Something went wrong fetching analytics data. Contact{' '}
              <a
                href={generateAnalyticsSupportEmail({
                  dataType: 'graph',
                  groupId,
                  eventId,
                  selectedTimePeriod,
                  dateRange,
                  selectedOption,
                  error: graphDataError,
                })}>
                support
              </a>{' '}
              if this error persists.
            </Text>
          </div>
        )}

        {isGraphDataSuccess && graphData && (
          <div style={{width: '99%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <LineGraph
              labels={graphData.map(dataPoint => dataPoint.label)}
              data={graphData.map(dataPoint => dataPoint.value)}
              tooltipValueFormatter={getFormatter(selectedOption, groupCurrency, 'tooltip', isRSVPEvent)}
              yAxisLabelFormatter={getFormatter(selectedOption, groupCurrency, 'axis', isRSVPEvent)}
              displayPrompt={false}
              canvasStyle={GraphStyle}
              responsive={true}
              hideRefresh
            />
          </div>
        )}
      </Card>
    </div>
  )
}
