import React, {HTMLProps, PropsWithChildren} from 'react'

import classNames from 'classnames'

import {FilledPhone} from '../assets/Icons'

import './styles.scss'

const PhoneNumberBadge = ({className, children}: PropsWithChildren<HTMLProps<HTMLSpanElement>>) => {
  return (
    <span className={classNames('PhoneNumberBadge', className)}>
      <FilledPhone width='16' height='16' fill='white' />
      {children}
    </span>
  )
}

export default PhoneNumberBadge
