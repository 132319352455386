import {MutationOptions, useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
import useSessionContext from 'domains/Auth/SessionContext'

import {AuthResponse, CheckPhoneCodeParams} from '.'
import {setSessionContext} from './setSessionContext'

export async function checkLoginCode(params: CheckPhoneCodeParams): Promise<AuthResponse> {
  const response = await PoshApi.checkLoginCode(params)
  return response.data
}

export function useCheckLoginCode(
  userIdentifier: string,
  options?: MutationOptions<AuthResponse, unknown, CheckPhoneCodeParams>,
) {
  const sessionContext = useSessionContext()

  return useMutation<AuthResponse, unknown, CheckPhoneCodeParams>(
    ['accountData', userIdentifier],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({setSessionOnSuccess, ...params}) => checkLoginCode(params),
    {
      onSuccess: (data, variables, context) => {
        variables.setSessionOnSuccess = variables.setSessionOnSuccess ?? true
        if (variables.setSessionOnSuccess) {
          setSessionContext(data, sessionContext)
        }
        options?.onSuccess?.(data, variables, context)
      },
    },
  )
}
