import React, {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

import {GroupOverviewPage, useNavigateToGroupsPage} from 'hooks/navigation/useNavigateToGroupsDashboard'

import {usePageNavigation} from '../../shared/NavigationItem/Page/pageNavigation'
import {NavigationDropdown} from '../NavigationDropdown'
import {NavigationSecondaryList} from '../NavigationSecondaryList'

import styles from './styles.module.scss'

const OrgSecondary: Array<{title: string; groupSubpage: GroupOverviewPage}> = [
  {title: 'Dashboard', groupSubpage: 'overview'},
  {title: 'Events', groupSubpage: 'overview/all-events'},
  {title: 'Finances', groupSubpage: 'finance'},
  {title: 'Marketing', groupSubpage: 'marketing'},
  {title: 'Profile', groupSubpage: 'profile'},
  {title: 'Settings', groupSubpage: 'settings/general'},
]

function getGroupSecondaryItems(
  navigateFunction: (page: GroupOverviewPage) => void,
): Array<{title: string; onClick: () => void}> {
  return OrgSecondary.map(({title, groupSubpage}) => ({
    title,
    onClick: () => navigateFunction(groupSubpage),
  }))
}

export function OrganizationsSecondary() {
  const {isInGroupsRoutes} = usePageNavigation().organizations
  const {navigateToGroupsDashboardAndSetRole, navigateToGroupPage} = useNavigateToGroupsPage()
  const location = useLocation()

  const secondaryNavigationListItems = getGroupSecondaryItems(page => navigateToGroupPage({page}))

  const selectedIndex = useMemo(() => {
    return OrgSecondary.findIndex(({groupSubpage}) => location.pathname.endsWith(groupSubpage))
  }, [location.pathname])

  if (!isInGroupsRoutes) return null

  return (
    <div className={styles.OrganizationSecondary}>
      <NavigationDropdown
        onSelectGroupAccountRoleUnion={agu => {
          navigateToGroupsDashboardAndSetRole({groupId: agu.group._id, accountRole: agu.accountRole})
        }}
      />
      <NavigationSecondaryList items={secondaryNavigationListItems} selectedIndex={selectedIndex} />
    </div>
  )
}
