import React, {PropsWithChildren} from 'react'

import {AnimatePresence, motion, MotionProps} from 'framer-motion'

import styles from './styles.module.scss'

export interface PageOverlayProps {
  isOpen: boolean
  style?: React.CSSProperties
  initialAnimation?: MotionProps['initial']
  animateTo?: MotionProps['animate']
  exitAnimation?: MotionProps['exit']
}

/**
 * This is a full page overlay that can be used to block user interaction with the page.
 * it fades in and out when the `isOpen` prop is toggled.
 * @param isOpen - Whether the overlay is visible or not
 * @param style - The style of the overlay
 * @param initialAnimation - The initial animation of the overlay defaults to {opacity: 0}
 * @param animateTo - The animation to apply when the overlay is visible defaults to {opacity: 1} (fades in)
 * @param exitAnimation - The animation to apply when the overlay is hidden defaults to {opacity: 0} (fades out)
 */
export const PageOverlay = (props: PropsWithChildren<PageOverlayProps>) => {
  const {
    isOpen,
    style,
    animateTo = {opacity: 1},
    exitAnimation = {opacity: 0},
    initialAnimation = {opacity: 0},
    children,
  } = props

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={styles.PageOverlay}
          style={style}
          exit={exitAnimation}
          initial={initialAnimation}
          animate={animateTo}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
