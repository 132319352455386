import React, {useState} from 'react'

import {getBankIdentifiers} from '@posh/types'
import {useStripe} from '@stripe/react-stripe-js'
import {useAddPaymentMethod} from 'apis/Groups/useAddPaymentMethod'
import {TGetGroupForFinancialsOutput} from 'apis/Groups/useGetGroupForFinancials'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {trpc} from 'lib/trpc'

import './styles.scss'

interface Props extends PoshStyledModalProps {
  group: TGetGroupForFinancialsOutput
  refetchPayouts: () => void
}

export default function AddBankModal(props: Props) {
  const stripe = useStripe()
  const queryClient = trpc.useContext()
  const {group, refetchPayouts, onClose} = props
  const {showToast} = useToast()
  const [accountHolderName, setAccountHolderName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')

  const {mutate: addBankAccount, isLoading} = useAddPaymentMethod({
    onSuccess: async () => {
      showToast({type: 'success', title: 'Bank Account Added'})
      refetchPayouts()
      queryClient.groups.getGroupForFinancials.invalidate()
      onClose()
    },
    onError: error => {
      showToast({type: 'error', title: 'Failed to add a bank account', subtitle: error.message})
    },
  })

  const country = group.country
  const bankModalDetails = getBankIdentifiers(country)
  if (!stripe) return null
  const onAdd = async () => {
    if (!country || !group.currency)
      return showToast({
        type: 'error',
        title: 'Failed to add a bank account',
        subtitle: 'Missing country or currency for group',
      })
    const {token, error} = await stripe.createToken('bank_account', {
      country: country,
      currency: group.currency,
      account_holder_name: accountHolderName,
      account_holder_type: 'Individual',
      account_number: accountNumber,
      routing_number: routingNumber,
    })
    if (error) return showToast({type: 'error', title: 'Failed to add a bank account', subtitle: error.message})
    if (token) addBankAccount({token: token.id, groupId: group._id})
  }

  const buttonIsDisabled = !accountHolderName || !accountNumber || isLoading
  const buttonText = isLoading ? 'Adding Bank Account' : 'Add Bank Account'

  return (
    <PoshStyledModal {...props}>
      <div className='formWrapper straight'>
        <h3>Add Bank Account</h3>
        <Input placeholder='Account Holder Name' onChange={e => setAccountHolderName(e.target.value)} />
        {bankModalDetails[1] && (
          <Input placeholder={bankModalDetails[1]} onChange={e => setRoutingNumber(e.target.value)} />
        )}
        {bankModalDetails[0] && (
          <Input placeholder={bankModalDetails[0]} onChange={e => setAccountNumber(e.target.value)} />
        )}
        <Button className='Button--gray' onClick={onAdd} disabled={buttonIsDisabled} isLoading={isLoading}>
          {buttonText}
        </Button>
      </div>
    </PoshStyledModal>
  )
}
