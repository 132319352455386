import React, {forwardRef, InputHTMLAttributes} from 'react'

import classNames from 'classnames'

import PoshInfo from '../PoshInfo'

import './index.scss'

type Props = InputHTMLAttributes<HTMLInputElement>

interface AdditionalProps extends Props {
  currencySymbol?: string
  label?: string
  info?: string
  icon?: JSX.Element
}

export const sanitizeNumber = (number: number) => {
  if (isNaN(number)) return undefined
  if (number < 0) return 0
  return number
}

const handleOnChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  isNumberInput: boolean,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
) => {
  if (!isNumberInput && onChange) onChange(e)
  else {
    if (e.target.value.includes('-')) e.target.value = '0'
    onChange && onChange(e)
  }
}

const Input = forwardRef<HTMLInputElement, AdditionalProps>(({className, ...props}, ref) => {
  const {currencySymbol, label, info, type, onChange, icon} = props
  const inputClassName = `poshInput ${className || ''} ${currencySymbol ? 'currencySymbol' : ''} ${icon ? 'icon' : ''}`
  const shouldUseWrapper = !!currencySymbol || !!label || !!info || !!icon
  const inputWrapperClassName = !!label ? `inputLabelWrapper` : `inputWrapper`
  const preventScroll: React.FocusEventHandler<HTMLInputElement> = e => {
    e.target.addEventListener(
      'wheel',
      function (e) {
        e.preventDefault()
      },
      {passive: false},
    )
  }

  return (
    <>
      {shouldUseWrapper ? (
        <div className={classNames(inputWrapperClassName, className)}>
          {label && (
            <div className={inputWrapperClassName + '-labelContatiner'}>
              {label && <label className='noMargin'>{label}</label>}
              {info && <PoshInfo info={info} />}
            </div>
          )}
          {currencySymbol && <span className={inputWrapperClassName + '-currencySymbol'}>{currencySymbol}</span>}
          {icon && <span className={inputWrapperClassName + '-icon'}>{icon}</span>}
          <input
            ref={ref}
            className={inputClassName}
            {...props}
            type={type}
            onChange={e => handleOnChange(e, type === 'number', onChange)}
            onFocus={preventScroll}
          />
        </div>
      ) : (
        <input
          ref={ref}
          className={inputClassName}
          {...props}
          onChange={e => handleOnChange(e, type === 'number', onChange)}
        />
      )}
    </>
  )
})

Input.displayName = 'Input'

export default Input
