import React from 'react'
import {Navigate, Outlet, useLocation} from 'react-router-dom'

import useSessionContext from '../../domains/Auth/SessionContext'

interface ProtectedRouteProps {
  isCommunityRoute?: boolean
}

const PrivateRoute = (props: ProtectedRouteProps) => {
  const {isCommunityRoute} = props
  const sessionContext = useSessionContext()
  const {userId} = sessionContext
  const currentUrl = useLocation()
  const encodedUrl = encodeURIComponent(currentUrl.pathname + currentUrl.search)

  if (userId) {
    return <Outlet />
  } else if (isCommunityRoute) {
    const encodedRedirect = encodeURIComponent('/dashboard/community')
    return <Navigate to={`/login?r=${encodedRedirect}`} />
  } else {
    return <Navigate to={`/login?r=${encodedUrl}`} />
  }
}

export default PrivateRoute
