import {useEffect} from 'react'

import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'

const AUTOSAVE_DELAY = 2000

export function useAutosave(pendingEvent: EventVisualsForm, save: () => void, isValid: boolean) {
  const dirtyString = JSON.stringify(pendingEvent)

  useEffect(() => {
    if (!isValid) return
    const timeout = setTimeout(save, AUTOSAVE_DELAY)
    return () => clearTimeout(timeout)
  }, [dirtyString])
}
