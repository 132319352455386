import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CreateEventTableParams} from './types'

export async function createEventTable(eventId: string, tableParamsCollection: CreateEventTableParams[]) {
  const response = await PoshApi.createEventTable(eventId, tableParamsCollection)
  return response.data
}

export default function useCreateEventTable() {
  return useMutation((params: {eventId: string; tables: CreateEventTableParams[]}) =>
    createEventTable(params.eventId, params.tables),
  )
}
