import React from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'

import './index.scss'

interface HoverIconProps {
  description: string
  source: string
  onClick: () => void
  className?: string
}

const HoverIcon = (props: HoverIconProps) => {
  const {description, source, onClick, className} = props
  return (
    <>
      <div data-tip={description} data-for='hoverIcon' className='HoverIcon'>
        <PoshImage className={`HoverIcon ${className}`} src={source} onClick={onClick} />
      </div>
    </>
  )
}

export default HoverIcon
