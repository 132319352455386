import React, {PropsWithChildren} from 'react'
import {useLocation} from 'react-router-dom'
import {ZendeskProvider as UseZendeskProvider} from 'react-use-zendesk'

import useSessionContext from '../Auth/SessionContext'
import {shouldShowZendeskWidget} from './zendeskHelpers'

const ZENDESK_KEY = '2d1061d3-eaa1-4a2f-912d-411438c7da8c'

export const ZendeskProvider = (props: PropsWithChildren) => {
  const {children} = props
  const {currentUser} = useSessionContext()
  const location = useLocation()
  const pathName = location.pathname
  const shouldRenderZendeskWidget = shouldShowZendeskWidget(pathName, !!currentUser)

  // Need to do this to avoid the zendesk widget from rendering upon the first load. This package is pretty jenky and sometimes renders even though we explicitly tell it not to. This is a workaround.
  const zendeskKey = shouldRenderZendeskWidget ? ZENDESK_KEY : ''
  return <UseZendeskProvider apiKey={zendeskKey}>{children}</UseZendeskProvider>
}
