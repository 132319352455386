import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
import {useGroupMatchParams} from 'domains/Groups/helpers'

import useSessionContext from '../../domains/Auth/SessionContext'
import {OrderAndEvent} from '.'

export async function fetchAccountOrdersAndEvents(accountId: string, groupID?: string): Promise<OrderAndEvent[]> {
  const response = await PoshApi.fetchAccountOrdersAndEvents(accountId, groupID)
  const data = response.data
  return data.ordersAndEvents
}

export function useFetchOrdersAndEvents(accountId?: string) {
  const {userId} = useSessionContext()
  const {groupId} = useGroupMatchParams()
  if (!userId) throw 'User must be signed in'
  const selectedId = accountId ? accountId : userId
  const selectedGroupId = groupId ? groupId : '0'
  return useQuery(['accountOrdersAndEvents', selectedId], () =>
    fetchAccountOrdersAndEvents(selectedId, selectedGroupId),
  )
}
