import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetSectionalDataOptions = ReactQueryOptions['groups']['getSectionalData']
type GetSectionalDataInput = RouterInputs['groups']['getSectionalData']
export type TDataPoint = GetSectionalDataInput['dataPoints'][number]
export type GetSectionalDataOutput = RouterOutput['groups']['getSectionalData']
export type ICrossSection = GetSectionalDataOutput[number]
export type TCrossSectionType = ICrossSection['type']

export function useGetSectionalData(input: GetSectionalDataInput, opts?: GetSectionalDataOptions) {
  return trpc.groups.getSectionalData.useQuery(input, opts)
}
