import React from 'react'

import PoshMobileNav from 'pages/PoshMobileNav'

import {EventMarketplace} from '.'

const EventMarketplaceWebWrapper = () => {
  return (
    <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
      <div style={{flex: 1}}>
        <EventMarketplace />
      </div>
      <PoshMobileNav />
    </div>
  )
}

export default EventMarketplaceWebWrapper
