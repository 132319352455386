import React from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'
import {isEmpty} from 'lodash'
import moment from 'moment'

import {FinishedTableProps, PoshAdminNameProps} from '../../types'
import {buildEventUrl, formatDollars} from '../utils'

export const FinishedTable = (props: FinishedTableProps) => {
  const {action, refunds} = props
  return (
    <>
      <h1>Recently {action}</h1>
      {isEmpty(refunds) ? (
        <p>No Recently {action} Refunds</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Organizer</th>
              <th>Event Name (Link)</th>
              <th>Event GMV</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {refunds.map(refund => (
              <tr key={refund._id.toString()}>
                <td>{refund.group.name}</td>
                <td>
                  <a className='white' href={buildEventUrl(refund.event.url)}>
                    {refund.event.name}
                  </a>
                </td>
                <td>${formatDollars(refund.event.gmv)}</td>
                <td className='FullRefundDashboard-Responder'>
                  <i>{action} by</i>&nbsp;
                  {formatPoshAdminName({
                    firstName: refund.poshAdmin.firstName,
                    lastName: refund.poshAdmin.lastName,
                    username: refund.poshAdmin.username,
                    avi: refund.poshAdmin.avi,
                  })}
                  &nbsp;
                  <i>on {createDateString(refund.respondedAt)}</i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  )
}

const formatPoshAdminName = (props: PoshAdminNameProps) => {
  const {firstName, lastName, username, avi} = props
  return (
    <a className='white' href={`/p/${username}`}>
      <span>
        <PoshImage src={generateAvi(avi)} />
        <p className='noPadding'>
          {firstName} {lastName}
        </p>
      </span>
    </a>
  )
}

const createDateString = (date: Date) => {
  return moment(date).format('MMMM Do YYYY [at] h:mm a')
}

const generateAvi = (avi?: string) => {
  return avi ?? 'https://images.posh.vip/b2/default-avi.jpg'
}
