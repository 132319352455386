import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetSpotifySongsOptions = ReactQueryOptions['utils']['getSpotifySongs']
type GetSpotifySongsInput = RouterInputs['utils']['getSpotifySongs']
export type GetSpotifySongsOutput = RouterOutput['utils']['getSpotifySongs']

export type SpotifySong = GetSpotifySongsOutput['songs'][number]

export function useGetSpotifySongs(input: GetSpotifySongsInput, opts?: GetSpotifySongsOptions) {
  return trpc.utils.getSpotifySongs.useQuery(input, opts)
}
