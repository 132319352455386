// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormInputRow__3eppC{display:flex;flex-direction:column;gap:12px;word-wrap:break-word}", "",{"version":3,"sources":["webpack://./src/components/DynamicForm/Form/Form.module.scss"],"names":[],"mappings":"AAAA,qBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,oBAAA","sourcesContent":[".FormInputRow {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormInputRow": "FormInputRow__3eppC"
};
export default ___CSS_LOADER_EXPORT___;
