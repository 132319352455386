// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cursorHover__ox2vq,.inputContainer__kprCJ input,.iconContainer__Y\\+Ryj{cursor:pointer;transition:.2s}.cursorHover__ox2vq:hover,.inputContainer__kprCJ input:hover,.iconContainer__Y\\+Ryj:hover{filter:opacity(0.7)}.iconContainer__Y\\+Ryj{display:flex;flex-direction:row;gap:10px;align-items:center}.inputContainer__kprCJ{width:100%;align-items:center}.inputContainer__kprCJ>*{max-width:100%}", "",{"version":3,"sources":["webpack://./src/components/PageComponents/EventVisuals/Form/Input/DateInput/styles.module.scss"],"names":[],"mappings":"AAAA,wEACE,cAAA,CACA,cAAA,CACA,0FACE,mBAAA,CAKJ,uBAEE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,kBAAA,CAGF,uBACE,UAAA,CAIA,kBAAA,CAHA,yBACE,cAAA","sourcesContent":[".cursorHover {\n  cursor: pointer;\n  transition: 0.2s;\n  &:hover {\n    filter: opacity(0.7);\n  }\n}\n\n\n.iconContainer {\n  @extend .cursorHover;\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: center;\n}\n\n.inputContainer {\n  width: 100%;\n  > * {\n    max-width: 100%;\n  }\n  align-items: center;\n\n  input {\n    @extend .cursorHover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursorHover": "cursorHover__ox2vq",
	"inputContainer": "inputContainer__kprCJ",
	"iconContainer": "iconContainer__Y+Ryj"
};
export default ___CSS_LOADER_EXPORT___;
