import {ImpersonationOperator as SessionOperator, Session} from '@posh/express-helpers'

import * as datadog from './datadog'
import * as sentry from './sentry'

export type MonitoringUser = {
  id?: string
  email?: string
  operator?: SessionOperator
  session?: Session
}

export function setUser(props?: MonitoringUser) {
  if (props?.id || props?.email) {
    datadog.setUser(props)
    sentry.setUser(props)
  } else {
    datadog.setUser(undefined)
    sentry.setUser(undefined)
  }
}
