import React from 'react'
import {Control, useController} from 'react-hook-form'

import {EventVisualsMarkdownEditor} from 'components/PageComponents/EventVisuals/Form/Input/MarkdownInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsHeaderButtons} from 'components/PageComponents/EventVisuals/Page/Section/headerButtons'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {isNil} from 'lodash'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

export function EventVisualsOverviewDescription({control}: {control: Control<EventVisualsForm>}) {
  const {currentlyAddingSection, setCurrentlyAddingSection} = useEventVisualsContext()
  const {
    field: {onChange: setDescriptionOnForm, value: description},
  } = useController({
    control,
    name: 'description',
  })

  const removeDescription = () => {
    setCurrentlyAddingSection(undefined)
    setDescriptionOnForm(null)
  }

  if (isNil(description) && currentlyAddingSection !== 'text') return null

  return (
    <EventVisualsSection
      headerText='Description'
      underlined
      rightIcon={
        <EventVisualsHeaderButtons
          deleteButtonProps={{
            onPress: removeDescription,
            confirmationModalTitle: 'Are you sure you want to remove your description?',
            confirmationButtonText: 'Delete Description',
            cancelButtonText: 'Cancel',
            confirmationModalSubtitle: 'This action cannot be undone',
            disabled: false,
            deleteWithoutModalOnSectionEmpty: true,
            isSectionEmpty: !description,
          }}
        />
      }>
      <EventVisualsMarkdownEditor value={description ?? ''} onChange={setDescriptionOnForm} />
    </EventVisualsSection>
  )
}
