import React from 'react'

import {Instagram, Profile} from 'components/assets/Icons'
import Button from 'components/form/Button'

const openInstagramProfile = (username: string) => {
  window.open(`https://instagram.com/${username}`)
}

const openProfile = (username: string) => {
  window.open(`https://posh.vip/p/${username}`)
}

const ViewSocialsButtons = ({username, instagram}: {username?: string; instagram?: string}) => {
  return (
    <div className='ApprovalModal-Content-ViewSocials'>
      {username && (
        <Button onClick={() => openProfile(username)} className='ApprovalModal-Content-ViewSocials-Button'>
          <Profile viewBox='0 0 16 16' height={12} width={12} /> View Profile
        </Button>
      )}
      {instagram && (
        <Button onClick={() => openInstagramProfile(instagram)} className='ApprovalModal-Content-ViewSocials-Button'>
          <Instagram />
          View Instagram
        </Button>
      )}
    </div>
  )
}

export default ViewSocialsButtons
