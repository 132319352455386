import React, {CSSProperties, useEffect, useRef, useState} from 'react'
import {Line} from 'react-chartjs-2'
import Loader from 'react-loader-spinner'

import {CategoryScale, Filler} from 'chart.js'
import Chart, {Plugin} from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom'
import {refreshUrl} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useDimensions} from 'hooks/useDimensions'

import './styles.scss'

interface LineGraphProps {
  label?: string
  labels: string[]
  data: number[]
  secondaryData?: number[]
  secondaryLabel?: string
  tooltipValueFormatter?: (value: number, label?: string) => string | undefined
  yAxisLabelFormatter?: (value: string) => string
  canvasStyle: CSSProperties
  secondaryColor?: boolean
  responsive?: boolean
  displayPrompt: boolean
  hideRefresh?: boolean
}

const FontFamily = "'Inter', sans-serif"

const TickStyling = {
  color: '#CBD5E0',
  font: {
    size: 10,
    lineHeight: 1.5,
    family: FontFamily,
  },
}

const verticalLine: Plugin = {
  id: 'verticalLine',
  beforeDatasetsDraw(chart) {
    const {
      tooltip,
      ctx,
      chartArea: {bottom, top},
      scales: {x},
    } = chart

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    if (tooltip && tooltip?._active.length > 0) {
      const xCoordinate = x.getPixelForValue(tooltip.dataPoints[0].dataIndex)

      ctx.save()
      ctx.beginPath()
      ctx.lineWidth = 2
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)'
      ctx.moveTo(xCoordinate, top)
      ctx.lineTo(xCoordinate, bottom)
      ctx.stroke()
      ctx.closePath()
    }
  },
}

export default function LineGraph(props: LineGraphProps) {
  const {
    label,
    labels,
    data,
    secondaryData,
    secondaryLabel,
    tooltipValueFormatter = () => undefined,
    yAxisLabelFormatter = value => value,
    canvasStyle,
    secondaryColor,
    responsive,
    displayPrompt,
    hideRefresh,
  } = props
  const chartRef = useRef<Chart>(null)
  const [isChartRefSet, setIsRefSet] = useState(false)
  const chart = chartRef.current?.ctx
  const {isMobile} = useDimensions()
  const gradient = chart?.createLinearGradient(0, 0, 0, 390)
  gradient?.addColorStop(0, `${secondaryColor ? 'rgba(197, 0, 71, 0.9)' : 'rgba(0, 170, 212, 0.9)'}`)
  gradient?.addColorStop(0.8, `${secondaryColor ? 'rgba(197, 0, 71, 0)' : 'rgba(0, 170, 212, 0)'}`)

  useEffect(() => {
    if (chartRef.current) {
      setIsRefSet(true)
    }
  }, [chartRef])

  const lineGraphData = {
    labels,
    datasets: [
      {
        label,
        data: data,
        fill: 'start',
        backgroundColor: gradient,
        borderColor: secondaryColor ? '#FF005C' : '#00CCFF',
        borderWidth: isMobile ? 1 : 3,
        lineTension: 0.4,
        pointRadius: 0,
        pointHitRadius: 100,
        yAxisID: 'y',
      },
    ],
  }

  if (secondaryData) {
    const secondaryGradient = chart?.createLinearGradient(0, 0, 0, 390)
    secondaryGradient?.addColorStop(0, 'rgba(0, 204, 255, 0.7)')
    secondaryGradient?.addColorStop(0.6, 'rgba(0, 204, 255, 0)')
    lineGraphData.datasets.push({
      label: secondaryLabel,
      data: secondaryData,
      fill: 'start',
      backgroundColor: secondaryGradient,
      borderColor: '#00CCFF',
      borderWidth: isMobile ? 1 : 3,
      lineTension: 0.4,
      pointRadius: 0,
      pointHitRadius: 100,
      yAxisID: 'y',
    })
  }

  const options = {
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'scrollKey',
        },
        zoom: {
          drag: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      tooltip: {
        enabled: true,
        titleFont: {
          family: FontFamily,
          weight: 'bold',
          size: 12,
          color: 'white',
        },
        bodyFont: {
          family: FontFamily,
          size: 10,
        },
        usePointStyle: true,
        titleSpacing: 10,
        padding: 15,
        callbacks: {
          label: function (tooltipItem: {raw: number; formattedValue: string; dataset: any}) {
            const formattedFromParams = tooltipValueFormatter(tooltipItem.raw, tooltipItem.dataset.label)
            return formattedFromParams ?? tooltipItem.formattedValue
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: responsive,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          ...TickStyling,
          maxRotation: 90,
          minRotation: 90,
        },
      },
      y: {
        min: 0,
        border: {
          dash: [4, 3],
        },
        grid: {
          color: '#909193',
          drawTicks: false,
        },
        ticks: {
          precision: 0,
          callback: yAxisLabelFormatter,
          autoSkip: true,
          beginAtZero: true,
          count: 6,
          padding: 10,
          ...TickStyling,
        },
      },
    },
  }

  Chart.register(CategoryScale, Filler, zoomPlugin)

  const resetZoom = () => {
    chartRef.current?.resetZoom()
  }

  return (
    <div className='LineGraph'>
      {!isMobile && (
        <>
          {!hideRefresh && (
            <Button style={{padding: '12px'}} onClick={resetZoom} className='dark noMargin smallImg LineGraph-refresh'>
              <PoshImage src={refreshUrl} />
            </Button>
          )}
          {displayPrompt && <div className='LineGraph-zoomPrompt'>Hold down and drag between dates to zoom in.</div>}
        </>
      )}
      <Line
        data={lineGraphData}
        ref={chartRef as any}
        style={canvasStyle}
        options={options as any}
        plugins={[verticalLine]}
      />
    </div>
  )
}

interface LineGraphLoadingProps {
  style?: CSSProperties
}

const LineGraphLoading = (props: LineGraphLoadingProps) => {
  const {style} = props
  return (
    <div className='LineGraphLoading' style={style}>
      <Loader type='Grid' color='grey' height={100} width={100} />
    </div>
  )
}

LineGraph.Loading = LineGraphLoading
