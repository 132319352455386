import {
  FileStatus,
  MultiFileUploadFile,
  MultiFileUploadManagerActionTypes,
  UploadManagerAction,
  UseMultiFileUploadManagerState,
} from './types'

export const MAX_FILES = 50

export function uploadManagerStateReducer(
  state: UseMultiFileUploadManagerState,
  action: UploadManagerAction,
): UseMultiFileUploadManagerState {
  switch (action.type) {
    case MultiFileUploadManagerActionTypes.ADD_FILES: {
      const {files, status, onExceedMaxFiles} = action

      const newUploads: MultiFileUploadFile[] = files.map(file => {
        const errorText = action.getFileErrorMessage(file)
        return {
          id: file.name,
          file,
          error: errorText,
          status: errorText ? FileStatus.ERROR : status,
          progress: -1,
        }
      })
      const newFiles: MultiFileUploadFile[] = [...state.files, ...newUploads]
      if (newFiles.length > MAX_FILES) {
        onExceedMaxFiles()
        return {
          ...state,
          files: newFiles.slice(0, MAX_FILES),
        }
      }

      return {
        ...state,
        files: newFiles,
      }
    }
    case MultiFileUploadManagerActionTypes.CLEAR_FILES: {
      return {
        ...state,
        files: [],
      }
    }
    case MultiFileUploadManagerActionTypes.QUEUE_FILES: {
      const {files} = state
      const newFiles: MultiFileUploadFile[] = files.reduce<MultiFileUploadFile[]>((files, currentFile) => {
        return [
          ...files,
          {
            ...currentFile,
            status: FileStatus.QUEUED,
          },
        ]
      }, [])
      return {
        ...state,
        files: newFiles,
      }
    }
    case MultiFileUploadManagerActionTypes.SET_STATUS_UPLOADING: {
      const {id} = action
      const {files} = state
      const newFiles = files.reduce<MultiFileUploadFile[]>((files, currentFile) => {
        if (currentFile.id === id) {
          return [
            ...files,
            {
              ...currentFile,
              status: FileStatus.UPLOADING,
              progress: 0,
            },
          ]
        }
        return [...files, currentFile]
      }, [])

      return {
        ...state,
        files: newFiles,
      }
    }
    case MultiFileUploadManagerActionTypes.SET_UPLOAD_PROGRESS: {
      const {id, progress} = action
      const {files} = state
      const newFiles = files.reduce<MultiFileUploadFile[]>((files, currentFile) => {
        if (currentFile.id === id) {
          return [
            ...files,
            {
              ...currentFile,
              progress,
            },
          ]
        }
        return [...files, currentFile]
      }, [])
      return {
        ...state,
        files: newFiles,
      }
    }
    case MultiFileUploadManagerActionTypes.REMOVE_UPLOAD: {
      const {id} = action
      const {files} = state
      const newFiles = files.reduce<MultiFileUploadFile[]>((files, currentFile) => {
        if (currentFile.id === id) {
          return [...files]
        }
        return [...files, currentFile]
      }, [])
      return {
        ...state,
        files: newFiles,
      }
    }
    case MultiFileUploadManagerActionTypes.SET_STATUS: {
      const {id, status, s3Uri} = action
      const {files} = state
      const newFiles = files.reduce<MultiFileUploadFile[]>((files, currentFile) => {
        if (currentFile.id === id) {
          return [
            ...files,
            {
              ...currentFile,
              status,
              s3Uri,
            },
          ]
        }
        return [...files, currentFile]
      }, [])
      return {
        ...state,
        files: newFiles,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
