import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchCampaignAnalyticsOptions = ReactQueryOptions['poshAdmin']['poshAdminCampaignRouter']['fetchCampaignAnalytics']
type FetchCampaignAnalyticsInput = RouterInputs['poshAdmin']['poshAdminCampaignRouter']['fetchCampaignAnalytics']
export type FetchCampaignAnalyticsAdminOutput =
  RouterOutput['poshAdmin']['poshAdminCampaignRouter']['fetchCampaignAnalytics']

export function useFetchCampaignAnalytics(campaignId?: string) {
  return trpc.poshAdmin.poshAdminCampaignRouter.fetchCampaignAnalytics.useQuery(
    {campaignId: campaignId!},
    {
      enabled: !!campaignId,
    },
  )
}
