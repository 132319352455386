import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface ShouldDisplayProfilePicModal {
  eventId?: string
  userId?: string | null
}

export async function fetchShouldDisplayProfilePictureModal(params: ShouldDisplayProfilePicModal) {
  const response = await PoshApi.fetchShouldDisplayProfilePictureModal(params)
  const shouldShow = response.data as boolean
  return shouldShow
}

export function useFetchShouldDisplayProfilePictureModal(params: ShouldDisplayProfilePicModal) {
  return useQuery<boolean>(
    ['shouldDisplayProfilePicModal', params],
    () => fetchShouldDisplayProfilePictureModal(params),
    {
      enabled: !!params.eventId && !!params.userId,
    },
  )
}
