import React from 'react'
import {useNavigate} from 'react-router-dom'

import {CampaignLink} from 'apis/AdminDashboard'
import {useCreateCampaignLink} from 'apis/AdminDashboard/useCreateCampaignLink'
import {useDeleteCampaignLink} from 'apis/AdminDashboard/useDeleteCampaignLink'
import useFetchCampaignLinks from 'apis/AdminDashboard/useFetchCampaignLinks'
import {useUpdateCampaignLink} from 'apis/AdminDashboard/useUpdateCampaignLink'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useToast} from 'components/toasts/ToastProvider'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import CRUDTable, {CRUDTableDataMap, CRUDTableMap} from '../CRUDTable'

const CampaignLinkTableMap: CRUDTableMap = [
  {
    label: 'Name',
    key: 'name',
    type: 'string',
    editable: true,
  },
  {
    label: 'Source',
    key: 'source',
    type: 'string',
    editable: true,
  },
  {
    label: 'Medium',
    key: 'medium',
    type: 'select',
    editable: true,
    options: ['email', 'social', 'organic', 'paid', 'referral', 'other'],
  },
  {
    label: 'Destination URL',
    key: 'destination_url',
    type: 'url',
    editable: true,
  },
  {
    label: 'Short URL',
    key: 'short_url',
    type: 'string',
    editable: true,
  },
  {
    label: 'Category',
    key: 'category',
    type: 'string',
    editable: true,
  },
  {
    label: 'Clicks',
    key: 'totalClicks',
    type: 'string',
    editable: false,
  },
]

export interface CampaignLinkData extends CRUDTableDataMap {
  key: 'campaign_link'
  data: CampaignLink[]
}

const CampaignLinks = () => {
  const {data: data, isFetching: isLoading, refetch} = useFetchCampaignLinks()
  const navigate = useNavigate()
  const {showToast} = useToast()

  const {mutateAsync: updateLink} = useUpdateCampaignLink({
    onSuccess: d => {
      showToast({type: 'success', title: `Updated campaign link`})
    },
    onError: err => {
      showToast({type: 'error', title: `Something went wrong when updating campaign link`})
    },
  })

  const updateCampaignLink = async ({id, item}: {id: string; item?: CampaignLink}) => {
    if (item) {
      const updates = {
        name: item.name,
        source: item.source,
        medium: item.medium,
        destination_url: item.destination_url,
        short_url: item.short_url,
        category: item.category,
        tags: item.tags,
      }
      await updateLink({
        campaignLinkId: id,
        updates,
      })
    }
  }

  const {mutateAsync: createLink, isLoading: isCreateLoading} = useCreateCampaignLink()

  const createCampaignLink = async ({item}: {item?: any}) => {
    try {
      await createLink(item)
    } catch (err: any) {
      showToast({type: 'error', title: err})
    }
  }

  const {mutateAsync: deleteCustomCheckoutField} = useDeleteCampaignLink({
    onSuccess: () => {
      showToast({type: 'success', title: `Deleted campaign link`})
    },
    onError: () => {
      showToast({type: 'error', title: `Something went wrong when deleting campaign link`})
    },
  })

  const deleteCampaignLink = async ({id}: {id: string}) => {
    const campaignLink = data?.find(c => c._id === id)
    if (!campaignLink) return
    if (campaignLink.totalClicks === 0) await deleteCustomCheckoutField({campaignLinkId: id})
    else showToast({type: 'error', title: `Cannot delete campaign link with clicks`})
  }

  const copyCampaignLink = async ({id}: {id: string}) => {
    const campaignLink = data?.find(c => c._id === id)
    if (campaignLink) {
      navigator.clipboard.writeText(`https://posh.vip/cpn/${campaignLink.short_url}`)
      showToast({type: 'success', title: `Copied campaign link to clipboard`})
    }
  }

  const moreInfoAction = async ({id}: {id: string}) => {
    const campaignLink = data?.find(c => c._id === id)
    if (campaignLink) {
      navigate(`/admin/campaign-links/${campaignLink._id}/analytics`)
    }
  }

  if (isLoading && !data) return <SpinLoader height={40} />

  const CRUDPayload: CampaignLinkData = {
    key: 'campaign_link',
    data: data as CampaignLink[],
    refetchDataFunction: refetch,
    entityName: 'Campaign Link',
    createFunction: createCampaignLink,
    isCreateFunctionLoading: isCreateLoading,
    updateFunction: updateCampaignLink,
    deleteFunction: deleteCampaignLink,
    copyFunction: copyCampaignLink,
    moreInfoFunction: moreInfoAction,
  }

  return (
    <>
      <BackAndTitle title={'Campaign Links'} />
      <CRUDTable payload={CRUDPayload} map={CampaignLinkTableMap} />
    </>
  )
}

export default CampaignLinks
