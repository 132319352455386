import React from 'react'

import {NotificationType} from '@posh/types'
import {FetchNotificationsOutput} from 'apis/Notifications/useFetchNotifications'

import {FollowedByNotification} from './FollowedBy/FollowedByNotification'
import {GuestlistLikeNotification} from './GuestlistLike/GuestlistLikeNotification'
import {PayoutsDisabledNotification} from './PayoutsDisabled/PayoutsDisabledNotification'
import {TicketTierSoldOut} from './TicketTierSoldOut/TicketTierSoldOutNotification'

export const NotificationComponent = ({notification}: {notification: FetchNotificationsOutput[number]}) => {
  const {type} = notification

  switch (type) {
    case NotificationType.TICKET_TIER_SOLD_OUT: {
      return <TicketTierSoldOut {...notification} />
    }
    case NotificationType.PAYOUTS_DISABLED: {
      const {orgName, orgId} = notification
      return <PayoutsDisabledNotification orgName={orgName} orgId={orgId} />
    }
    case NotificationType.LIKED_NOTIFICATION: {
      return <GuestlistLikeNotification {...notification} />
    }
    case NotificationType.FOLLOWED_BY_USER: {
      return <FollowedByNotification {...notification} />
    }
    default:
      return null
  }
}
