import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'

import useVerifyEmail from 'apis/Accounts/useVerifyEmail'

import './VerifyEmail.scss'

const VerifyEmail = () => {
  const {accountId, emailVerificationToken} = useParams<{accountId: string; emailVerificationToken: string}>()
  const {mutateAsync: verifyEmail} = useVerifyEmail()

  useEffect(() => {
    async function f() {
      if (accountId && emailVerificationToken) {
        const res = await verifyEmail({accountId, token: emailVerificationToken})
        console.log(res)
      }
    }
    f()
  }, [])

  return (
    <div className='VerifyEmail'>
      <div style={{marginBottom: '10px'}}>Your email has been verified!</div>
      <div>
        You can close this window or go to the <a href='https://posh.vip'>homepage</a>.
      </div>
    </div>
  )
}

export default VerifyEmail
