import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {EventModel} from 'apis/Events/types'
import useFetchEventAnalytics from 'apis/Events/useFetchEventAnalytics'
import {useFetchGroupData} from 'apis/Groups/useFetchGroupData'
import {useGetGroup} from 'apis/Groups/useGetGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import {RoleKey} from 'apis/Roles/Role'
import {Card} from 'components/Card'
import BlastsTable from 'components/data/BlastsTable'
import Button from 'components/form/Button'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import usePermissionsContext from 'domains/Teams/PermissionsContext'
import {isPaidEvent} from 'helpers/isPaidEvent'
import {orderBy} from 'lodash'
import moment from 'moment'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'
import {calculateEventStatus} from 'pages/Util/timezoneHelper'

import {useFetchGroupEvents} from '../../../apis/Groups/useFetchGroupEvents'
import AnalyticsGrid, {Statistic} from '../../../components/data/AnalyticsGrid'
import EventsTable, {EventRowAction} from '../../../components/data/EventsTable'
import RequirePermissions from '../../../components/RequirePermissions'
import useSessionContext from '../../../domains/Auth/SessionContext'
import {useGroupMatchParams} from '../../../domains/Groups/helpers'
import {useGroupBaseUrl} from '../../../domains/Groups/helpers'
import {useNavigateToCreateEvent} from '../../CreateEvent/useNavigateToCreateEvent'
import {usePageParams} from '../../PoshAppLayout'
import {getGroupPayoutsBanner} from '../Finance/PayoutsBanner'
import OrdersListView from '../Orders/OrdersListView'
import DeleteEventModal from './DeleteEventModal'
import DuplicateEventModal from './DuplicateEventsModal'
import GroupAnalyticsView from './GroupAnalyticsView'
import PastEvent from './PastEvent'

import './styles.scss'

const Overview = () => {
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {groupId} = useGroupMatchParams()
  const {domain} = usePageParams()
  const {currentUser} = useSessionContext()
  const {navigateToCreateEvent} = useNavigateToCreateEvent()

  const {data: groupData} = useFetchGroupData(groupId ?? '')
  const {data: groupEvents, isFetching: groupEventsIsFetching} = useFetchGroupEvents(groupId ?? '')
  const {data: getGroupData, isFetching: isFetchingGroup} = useGetGroup({groupId: groupId ?? ''})
  const {accountRole} = usePermissionsContext()
  const {group} = getGroupData ?? {}

  const [isShowingDeleteEventModal, setIsShowingDeleteEventModal] = useState(false)
  const [actionEvent, setActionEvent] = useState<EventModel | undefined>(undefined)
  const [isShowingDuplicateEventModal, setIsShowingDuplicateEventModal] = useState(false)

  const search = useLocation().search

  const currencySymbol = getCurrencySymbol(group?.currency) as string

  const events = groupEvents?.filter(e => e.status !== 'deleted')
  const groupHasPaidEvents = events?.some(isPaidEvent) ?? false

  const sortedEvents = orderBy(
    events,
    ({start}) => {
      return moment(start).valueOf()
    },
    ['desc'],
  )

  const lastEvent = sortedEvents.length > 0 ? sortedEvents.reverse().slice(-1)[0] : null

  const {data: analytics} = useFetchEventAnalytics(
    lastEvent ? lastEvent._id : null,
    accountRole?.roleKey ?? ('host' as RoleKey),
  )

  // live or draft
  const activeEvents = sortedEvents.filter(
    e =>
      calculateEventStatus(moment.tz(e.endUtc, e.timezone)) == 'live' ||
      calculateEventStatus(moment.tz(e.endUtc, e.timezone)) == 'draft',
  )

  const length = groupData ? groupData.eventsLength : undefined
  const attendees = groupData ? groupData.totalAttendees : undefined
  const rev = groupData ? groupData.totalRevenue : undefined

  const analyticsData: (Statistic | null)[] = [
    {
      name: 'Events',
      value: length,
    },
    {
      name: 'Total Attendees',
      value: attendees,
    },
    groupHasPaidEvents
      ? {
          name: 'Total Revenue',
          value: rev,
          isCurrency: true,
        }
      : null,
  ]

  const handleRowAction = (action: EventRowAction, eventId: string) => {
    if (action == EventRowAction.EDIT) {
      navigate(`/${domain}/groups/${groupId}/events/${eventId}/overview${search}`)
    }

    if (action == EventRowAction.DELETE) {
      setActionEvent(events!.find(event => event._id == eventId)!)
      setIsShowingDeleteEventModal(true)
    }

    if (action == EventRowAction.DUPLICATE) {
      setActionEvent(events!.find(event => event._id == eventId)!)
      setIsShowingDuplicateEventModal(true)
    }

    if (action == EventRowAction.VIEW) {
      const event = events!.find(event => event._id == eventId)!
      window.open(`/e/${event.url}` + search, '_blank')
    }
  }

  const baseUrl = useGroupBaseUrl()

  return (
    <>
      <PoshHelmet title={`Overview - ${group?.name}`} />
      {!!group &&
        groupHasPaidEvents &&
        getGroupPayoutsBanner({
          canInitiatePayouts: group.canInitiatePayouts,
          canAcceptPayments: group.canAcceptPayments,
          needsStripeOnboarding: group.needsStripeOnboarding,
          financeTabUrl: `${baseUrl}/finance`,
        })}
      <RequirePermissions
        requiredPermissions={[
          {
            permissionKey: 'view_sales_analytics',
            applicableScopes: ['group'],
          },
        ]}>
        <GroupAnalyticsView
          group={group}
          groupId={groupId}
          currentUser={currentUser}
          isFetchingGroup={isFetchingGroup}
          baseUrl={baseUrl}
          search={search}
          groupHasPaidEvents={groupHasPaidEvents}
        />
      </RequirePermissions>
      <div className='Overview-content'>
        <div className='Overview-content-cols'>
          <div className='Overview-content-cols-top'>
            <RequirePermissions
              requiredPermissions={[
                {
                  permissionKey: 'view_sales_analytics',
                  applicableScopes: ['group'],
                },
              ]}>
              <AnalyticsGrid currencySymbol={currencySymbol} stats={analyticsData} GroupDash={true} gold={false} />
            </RequirePermissions>
            <RequirePermissions
              requiredPermissions={[
                {
                  permissionKey: 'create_event',
                  applicableScopes: ['group'],
                },
              ]}>
              <Button
                className='darker goldHover large fullWidth'
                style={{maxWidth: '600px'}}
                onClick={() => {
                  trackMixpanelEvent('Create New Event -  Curator overview above recent orders')
                  navigateToCreateEvent({groupId, optimizelyEntryPoint: 'Organization Overview Create Event Button'})
                }}>
                Create New Event +
              </Button>
            </RequirePermissions>
          </div>
          {sortedEvents.length > 0 && (
            <>
              <div className='Overview-content-cols-left'>
                <Card title={'Events'} animationIndex={1}>
                  <>
                    {activeEvents.length > 0 ? (
                      <>
                        <div className='Overview-eventsTable'>
                          <EventsTable
                            data={activeEvents ?? []}
                            onRowAction={handleRowAction}
                            isHost={(accountRole?.roleKey ?? ('host' as RoleKey)) === 'host'}
                            activeOnly={true}
                            isFetching={groupEventsIsFetching}
                          />
                          <Button
                            className='light dark fullWidth'
                            onClick={() => navigate(`${baseUrl}/overview/all-events` + search)}>
                            View Past Events
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {sortedEvents.length > 0 && (
                          <>
                            <PastEvent
                              sortedEvents={sortedEvents}
                              lastEvent={lastEvent}
                              analytics={analytics}
                              groupId={groupId ?? ''}
                              currencySymbol={getCurrencySymbol(group?.currency)}
                            />
                            <Button
                              onClick={() => navigate(`${baseUrl}/overview/all-events` + search)}
                              className='light dark fullWidth'>
                              View Past Events
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </>
                </Card>
                <RequirePermissions
                  requiredPermissions={[
                    {
                      permissionKey: 'view_blasts',
                      applicableScopes: ['group'],
                    },
                  ]}>
                  <Card title={'Recent SMS Campaigns'} animationIndex={3}>
                    <BlastsTable limit={3} />
                  </Card>
                </RequirePermissions>
              </div>
              <div className='Overview-content-cols-right'>
                <RequirePermissions
                  requiredPermissions={[
                    {
                      permissionKey: 'view_orders_list',
                      applicableScopes: ['group'],
                    },
                  ]}>
                  <Card title={'Recent Orders'} animationIndex={2}>
                    <>
                      <OrdersListView expanded={false} />
                      <Button
                        className='light dark fullWidth'
                        onClick={() => {
                          navigate(`/${domain}/groups/${groupId}/orders` + search)
                        }}>
                        View More
                      </Button>
                    </>
                  </Card>
                </RequirePermissions>
              </div>
            </>
          )}
        </div>

        <DeleteEventModal
          event={actionEvent}
          isOpen={isShowingDeleteEventModal}
          onClose={() => setIsShowingDeleteEventModal(false)}
        />
        {actionEvent && isShowingDuplicateEventModal && (
          <DuplicateEventModal
            event={actionEvent}
            isOpen={isShowingDuplicateEventModal}
            onClose={() => setIsShowingDuplicateEventModal(false)}
            width={830}
          />
        )}
      </div>
    </>
  )
}

export default Overview
