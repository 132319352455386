import React, {useEffect, useState} from 'react'

import useFetchEvent from 'apis/Events/useFetchEvent'
import {useMixpanel} from 'apis/MixPanelHandler'
import {RoleKey} from 'apis/Roles/Role'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {isEmpty} from 'lodash'
import {ActionBarButton} from 'pages/GroupPage/Marketing/ActionBarButton'
import {AttendeesSearchBar} from 'pages/GroupPage/Marketing/AttendeesSearchBar'
import AttendeesCrmActionsModal from 'pages/GroupPage/Marketing/Filter'
import {FilterAlert} from 'pages/GroupPage/Marketing/Filter/filterAlert'
import {TagsModal} from 'pages/GroupPage/Marketing/TagsModal'

import useFetchEventAttendees from '../../../apis/Events/useFetchEventAttendees'
import {AccountDomain} from '../../../apis/Roles/AccountDomain'
import {filterUrl, tagWhiteUrl} from '../../../components/assets/Icons'
import RequirePermissions, {requirePermissionAndScope} from '../../../components/RequirePermissions'
import useSessionContext from '../../../domains/Auth/SessionContext'
import useAttendeesCrmContext from '../../GroupPage/Marketing/AttendeesCrmContext'
import AttendeesTable from '../../GroupPage/Marketing/AttendeesTable'
import {useResourcePageParams} from '../../PoshAppLayout'

import '../../GroupPage/Marketing/styles.scss'

const Attendees = () => {
  const {
    page,
    setPage,
    setIsEventScoped,
    selectedTags,
    isFilterActive,
    selectedTrackingLinks,
    selectedTickets,
    filterByData,
    filterByOrder,
    matchAllTags,
    matchAllTrackingLinks,
    matchAllTickets,
    genderFilter,
    currentCheckoutField,
  } = useAttendeesCrmContext()
  const {eventId, domain} = useResourcePageParams()
  const {currentUser} = useSessionContext()
  const [searchQuery, setSearchQuery] = useState('')
  const userHasSearched = searchQuery !== ''
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const {data: eventAttendeesData, isFetching} = useFetchEventAttendees({
    eventId: eventId!,
    page,
    domain: (domain ?? '') as RoleKey,
    currentCheckoutField,
    trackingLink: currentUser?.trackingLink,
    selectedTrackingLinks: selectedTrackingLinks,
    selectedTags: selectedTags,
    selectedTickets: selectedTickets,
    filterByData: filterByData,
    searchQuery: searchQuery,
    filterByOrder: filterByOrder,
    matchAllTags: matchAllTags,
    matchAllTrackingLinks: matchAllTrackingLinks,
    matchAllTickets: matchAllTickets,
    genderFilter: genderFilter,
  })
  const {data: eventResponse} = useFetchEvent(eventId!)

  const [tagModalIsOpen, setTagModalIsOpen] = useState(false)
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false)
  const {setSelectedAttendeeIds} = useAttendeesCrmContext()

  const isHost = domain == AccountDomain.HOST

  const attendees = eventAttendeesData?.attendees
  const attendeesExist = !!attendees && attendees.length > 0
  const hasMore = eventAttendeesData?.hasMore ?? false
  const eventHasCustomCheckoutFields = eventAttendeesData?.eventHasCustomCheckoutFields
  const checkoutFieldNamesAndIds = eventAttendeesData?.checkoutFieldNamesAndIds
  const tickets = eventResponse?.event.tickets

  const shouldShowActions = () => {
    if (attendees && isEmpty(attendees)) return false
    return true
  }

  useEffect(() => {
    setIsEventScoped(true)
  }, [])

  useEffect(() => {
    if (page === 1) return
    setPage(1)
  }, [isFilterActive, currentCheckoutField])

  const onApplyFilters = () => {
    trackMixpanelEvent('Apply Filters- Curators Attendees Filter Modal', {
      sortBy: filterByOrder,
      maleOrFemale: genderFilter,
      eventStatus: eventResponse?.event.status ?? '',
    })
  }

  const onClickCreateTag = () => {
    trackMixpanelEvent('Create New Tag- Curators Attendees Page Tag Modal', {
      eventStatus: eventResponse?.event.status ?? '',
    })
  }

  return (
    <>
      <PoshHelmet title={`Attendees - ${eventResponse?.event.name}`} />
      <div className='Attendees'>
        <div className='Attendees-content noBack'>
          {!isHost && shouldShowActions() && (
            <div className='Attendees-actionBar'>
              <ActionBarButton
                img={filterUrl}
                title={'FILTER'}
                onClick={() => setFilterModalIsOpen(true)}
                info={'Filter your attendees by tag, total spend, date added, or tracking link!'}
              />
              <RequirePermissions
                requiredPermissions={[requirePermissionAndScope('tag_attendees', ['event', 'group'])]}>
                <ActionBarButton
                  img={tagWhiteUrl}
                  title={'TAG'}
                  onClick={() => setTagModalIsOpen(true)}
                  info={'Tag your attendees for better filtering.'}
                />
              </RequirePermissions>
            </div>
          )}
          {(attendeesExist || userHasSearched) && <AttendeesSearchBar setSearchQuery={setSearchQuery} />}
          <FilterAlert onOpenModal={() => setFilterModalIsOpen(true)} />
          {!isFetching && isEmpty(attendees) && (
            <div className='Attendees-emptyState'>
              {userHasSearched
                ? `No attendees found for your search. Try a ${
                    searchQuery.length === 1 ? 'longer' : 'different'
                  } search!`
                : `Once people begin ${eventResponse?.event?.isRSVPEvent ? 'RSVPing' : 'purchasing tickets'}, attendee
                information will appear here!`}
            </div>
          )}
          {attendeesExist && (
            <AttendeesTable
              attendeeRows={attendees}
              isHost={isHost}
              eventHasCustomCheckoutFields={eventHasCustomCheckoutFields}
              checkoutFieldNamesAndIds={checkoutFieldNamesAndIds}
              hasMoreAttendees={hasMore}
              isFetching={isFetching}
            />
          )}
        </div>
      </div>
      <TagsModal
        isOpen={tagModalIsOpen}
        onClose={() => {
          setSelectedAttendeeIds([])
          setTagModalIsOpen(false)
        }}
        onClickCreateTag={onClickCreateTag}
      />
      <AttendeesCrmActionsModal
        isOpen={filterModalIsOpen}
        onClose={() => setFilterModalIsOpen(false)}
        tickets={tickets}
        onApplyFilters={onApplyFilters}
      />
    </>
  )
}

export default Attendees
