import React, {PropsWithChildren, useEffect, useMemo} from 'react'
import {useNavigate} from 'react-router-dom'

import {AccountRoleResponse} from 'apis/Roles'
import {PermissionKey} from 'apis/Roles/Permission'
import {ScopeKey} from 'apis/Roles/Role'
import difference from 'lodash/difference'
import includes from 'lodash/includes'

import useSessionContext from '../domains/Auth/SessionContext'
import usePermissionsContext from '../domains/Teams/PermissionsContext'
import {useResourcePageParams} from '../pages/PoshAppLayout'

export function requirePermissionAndScope(
  permKey: PermissionKey,
  applicableScopes: ScopeKey[],
): RequiredPermissionAndScope {
  return {
    permissionKey: permKey,
    applicableScopes: applicableScopes,
  }
}

export interface RequiredPermissionAndScope {
  permissionKey: PermissionKey
  applicableScopes: ScopeKey[]
}

interface Props {
  requiredPermissions?: RequiredPermissionAndScope[]
  allowPartialMatch?: boolean
  fallbackComponent?: JSX.Element
}

export default function RequirePermissions(props: PropsWithChildren<Props>) {
  const {requiredPermissions, allowPartialMatch} = props
  const {accountRoleGroupIdMap, accountRoles, setAccountRoleGroupIdMap} = useSessionContext()
  const {isFetching, permissions, accountRole} = usePermissionsContext()

  const {groupId} = useResourcePageParams()
  const permissionContext = usePermissionsContext()
  const navigate = useNavigate()

  useEffect(() => {
    // This shouldn't need to run in the posh admin case but there might be edge cases
    if (!permissionContext.accountRole) {
      if (!accountRoleGroupIdMap) {
        if (!accountRoles) {
          navigate(`/dashboard`)
          return
        }

        const newAccountRoleGroupIdMap: {[key: string]: AccountRoleResponse} = {}
        accountRoles.forEach(role => {
          newAccountRoleGroupIdMap[role.entity] = role
        })
        setAccountRoleGroupIdMap(newAccountRoleGroupIdMap)

        const accountRole = newAccountRoleGroupIdMap![groupId!]
        permissionContext.setAccountRole(accountRole)
      } else {
        const accountRole = accountRoleGroupIdMap![groupId!]
        permissionContext.setAccountRole(accountRole)
      }
    }
  }, [accountRoleGroupIdMap, groupId, history, permissionContext, setAccountRoleGroupIdMap, accountRoles])

  const isAuthorized = useMemo(() => {
    if (!permissions || !accountRole) return false
    if (!requiredPermissions) return true
    const enabledPermissionKeys = permissions.filter(p => p.enabled).map(p => p.key)
    const requiredPermissionKeys = requiredPermissions.map(p => p.permissionKey)
    const hasSomeRequiredPermissions =
      difference(requiredPermissionKeys, enabledPermissionKeys).length < requiredPermissionKeys.length
    const hasAllRequiredPermissions = difference(requiredPermissionKeys, enabledPermissionKeys).length === 0
    const hasRequiredScope =
      requiredPermissions.filter(rP => {
        return !includes(rP.applicableScopes, accountRole.scope)
      }).length == 0
    const hasSomeOfRequiredScopes =
      requiredPermissions.filter(rP => {
        return includes(rP.applicableScopes, accountRole.scope)
      }).length > 0

    // Check if required permissions array includes the view_attendee_list or the view_global_attendee_list permission
    const isPartialMatch = hasSomeRequiredPermissions && hasSomeOfRequiredScopes
    const isFullMatch = hasAllRequiredPermissions && hasRequiredScope

    const isMatch = allowPartialMatch ? isPartialMatch : isFullMatch
    return isMatch
  }, [accountRole, permissions, requiredPermissions, allowPartialMatch])

  if (isFetching) return null

  if (isAuthorized) {
    return <>{props.children}</>
  } else {
    return props.fallbackComponent ?? null
  }
}
