import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface DeleteEventCustomFeeProps {
  name: string
  eventId: string
}

async function deleteEventCustomFee(props: DeleteEventCustomFeeProps) {
  const response = await PoshApi.deleteEventCustomFee(props)
  return response.data
}

export default function useDeleteEventCustomFee() {
  return useMutation((props: DeleteEventCustomFeeProps) => deleteEventCustomFee(props))
}
