import React from 'react'
import {Route, Routes} from 'react-router-dom'

import PageNotFound from 'pages/PageNotFound/PageNotFound'

import HostBreakdownHandler from './HostBreakdown'
import TeamMembersIndex from './TeamMembers'

import './index.scss'

const TeamMembers = () => {
  return (
    <Routes>
      <Route path={``} element={<TeamMembersIndex />} />
      <Route path={`host-breakdown/:hostId`} element={<HostBreakdownHandler />} />
      <Route path={`tl-breakdown/:trackingLink`} element={<HostBreakdownHandler />} />
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  )
}

export default TeamMembers
