import {useDecision} from '@optimizely/react-sdk'
import {EOptimizelyTests} from '@posh/types'

import {getRandomEventNameAndDescription, getRandomFlyer, getStartAndEndDate} from './createEventDefaultAttributes'

const UNDEFINED_DEFAULT_ATTRIBUTES = {
  defaultAttributes: undefined,
}

export const useDeterminePendingEventDefaultAttributes = () => {
  const [decision, clientReady] = useDecision(EOptimizelyTests.CREATE_EVENT_DEFAULT_ATTRIBUTES)
  if (!clientReady) {
    return UNDEFINED_DEFAULT_ATTRIBUTES
  }
  switch (decision.variationKey) {
    case 'no_default_attributes':
      return UNDEFINED_DEFAULT_ATTRIBUTES
    case 'preselected_flyer':
      return {
        defaultAttributes: {
          flyer: getRandomFlyer(),
          name: undefined,
          descriptionOrShortDescription: undefined,
          startUtc: undefined,
          endUtc: undefined,
        },
      }
    case 'predefined_attributes_and_flyer':
      const {name, description} = getRandomEventNameAndDescription()
      const {startDate, endDate} = getStartAndEndDate()
      return {
        defaultAttributes: {
          flyer: getRandomFlyer(),
          name,
          descriptionOrShortDescription: description,
          startUtc: startDate,
          endUtc: endDate,
        },
      }
    default:
      return UNDEFINED_DEFAULT_ATTRIBUTES
  }
}
