import React from 'react'

import {CreateEventPinResponse} from 'apis/Events/useCreateEventPin'
import Button from 'components/form/Button'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import HeaderCopyBlock from 'pages/GroupPage/PageComponents/HeaderCopyBlock'

import './styles.scss'

export interface TicketScannerPinModalProps extends PoshStyledModalProps {
  pinResponse: CreateEventPinResponse
  eventId: string
}

export const TicketScannerPinModal = (props: TicketScannerPinModalProps) => {
  const {pinResponse, eventId, ...modalProps} = props
  const {onClose} = modalProps
  const {pin} = pinResponse

  return (
    <PoshStyledModal {...modalProps}>
      <div className='PinModal'>
        <p className='center noMargin'>
          This PIN grants access to ticket scanning, orders, and guest list for this event on the Posh mobile app. This
          PIN can be deleted at any time, disabling access for those using it.
        </p>
        <HeaderCopyBlock text={pin} />
        <div className='buttonWrapper column fullWidth'>
          <Button onClick={onClose} className='dark fullWidth'>
            Done
          </Button>
        </div>
      </div>
    </PoshStyledModal>
  )
}
