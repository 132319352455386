import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {captureException, ErrorBoundary} from '@sentry/react'

import PageNotFound from '../PageNotFound/PageNotFound'
import SMSCampaignBreakdown from './Breakdown'
import {SMSCampaignPage} from './index'
import SMSCampaignBackground from './SMSCampaignBackground'
import SMSCampaignStatusPage from './SMSCampaignStatusPage'

const SMSCampaignLayout = () => {
  return (
    <Routes>
      <Route
        path='/details'
        element={
          <SMSCampaignBreakdown>
            <SMSCampaignBreakdown.Details />
          </SMSCampaignBreakdown>
        }
      />
      <Route
        path='/recipients'
        element={
          <SMSCampaignBreakdown>
            <SMSCampaignBreakdown.Recipients />
          </SMSCampaignBreakdown>
        }
      />
      <Route
        path='/create'
        element={
          <ErrorBoundary
            fallback={
              <SMSCampaignBackground center>
                <div>
                  <h3 className='large' style={{textAlign: 'center'}}>
                    We encountered an unexpected error! Please refresh your page. If the error persists please reach out
                    to support@posh.vip.
                  </h3>
                </div>
              </SMSCampaignBackground>
            }
            onError={(error, info) => {
              captureException(error, {extra: {info}})
            }}>
            <SMSCampaignPage />
          </ErrorBoundary>
        }
      />
      <Route path='/status' element={<SMSCampaignStatusPage />} />
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  )
}

export default SMSCampaignLayout
