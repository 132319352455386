import React, {PropsWithChildren, useCallback} from 'react'

import {BaseModal, BaseModalProps} from '../BaseModal'
import {CenteredModal} from './Centered'

export interface BlurBackgroundModalProps extends Omit<BaseModalProps, 'Background'> {
  radius?: number
}

function BlurBackground({radius = 5}: {radius?: number}) {
  return (
    <div
      style={{
        WebkitBackdropFilter: `blur(${radius}px)`,
        height: '100%',
      }}
    />
  )
}

export function BlurBackgroundModal({children, ...props}: PropsWithChildren<BlurBackgroundModalProps>) {
  const Background = useCallback(() => BlurBackground({...props}), [props])
  return (
    <BaseModal {...props} Background={Background}>
      {children}
    </BaseModal>
  )
}

function BlurBackgroundCentered(props: BlurBackgroundModalProps) {
  const Background = useCallback(() => BlurBackground({...props}), [props])
  return <CenteredModal {...props} Background={Background} />
}

BlurBackgroundModal.Centered = BlurBackgroundCentered
