import {RouterInputs, trpc} from 'lib/trpc'

import useTextBlastParams from './useTextBlastParams.helpers'

const useFetchTextBlastRecipientsWithStatus = (
  input: Omit<RouterInputs['textBlasts']['getRecipientsWithStatus'], 'textBlastId' | 'groupId'>,
) => {
  const {blastId, groupId} = useTextBlastParams()
  return trpc.textBlasts.getRecipientsWithStatus.useQuery(
    {groupId, textBlastId: blastId, ...input},
    {enabled: !!blastId && !!groupId},
  )
}

export default useFetchTextBlastRecipientsWithStatus
