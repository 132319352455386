import React from 'react'
import {useNavigate} from 'react-router-dom'

import {PoshImage} from 'components/PoshImage/PoshImage'

import styles from './styles.module.scss'

export function CurrentUser({fullName, avi}: {fullName: string; avi: string}) {
  const navigate = useNavigate()
  const handleNavigation = () => {
    navigate('/dashboard/account')
  }
  return (
    <>
      <PoshImage className={styles.avi} src={avi} onClick={handleNavigation} />
      <p className={styles.name} onClick={handleNavigation}>
        {fullName}{' '}
      </p>
    </>
  )
}
