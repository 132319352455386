import React from 'react'
import {PropsWithChildren} from 'react'

import classNames from 'classnames'

const CheckoutFieldResponseLabel = ({children}: PropsWithChildren<unknown>) => {
  return <p className='grey-light'>{children}</p>
}

interface ValueProps {
  onClick?: () => void
  className?: string
}

const CheckoutFieldResponseValue = ({children, onClick, className = ''}: PropsWithChildren<ValueProps>) => {
  return (
    <p
      className={classNames('white', {
        [className]: !!className,
        ['grey italic']: !children,
      })}
      onClick={onClick}>
      {children}
      {!children && 'No Response'}
    </p>
  )
}

const CheckoutFieldResponse = ({children}: PropsWithChildren<unknown>) => {
  return <div className='CheckoutFieldResponse'>{children}</div>
}

CheckoutFieldResponse.Label = CheckoutFieldResponseLabel
CheckoutFieldResponse.Value = CheckoutFieldResponseValue

export default CheckoutFieldResponse
