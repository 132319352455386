import useSessionContext from 'domains/Auth/SessionContext'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type ListGroupsFollowingOptions = ReactQueryOptions['social']['account']['listGroupsFollowing']
export type ListGroupsFollowingsInput = RouterInputs['social']['account']['listGroupsFollowing']
export type ListGroupsFollowingOutput = RouterOutput['social']['account']['listGroupsFollowing']

export function useListGroupsFollowing(input: ListGroupsFollowingsInput, opts?: ListGroupsFollowingOptions) {
  return trpc.social.account.listGroupsFollowing.useQuery(input, opts)
}

export function useIsCurrentUserFollowingGroup(groupId?: string, opts?: ListGroupsFollowingOptions) {
  const {currentUser} = useSessionContext()
  const {data, isLoading} = useListGroupsFollowing(undefined, {
    ...opts,
    enabled: !!currentUser,
  })

  if (!data || !groupId) {
    return {isCurrentUserFollowingGroup: false, isLoading}
  }

  const isCurrentUserFollowingGroup = data.followed.some(group => group.to === groupId)

  return {isCurrentUserFollowingGroup, isLoading}
}
