import React from 'react'

import {SORT_OPTIONS, SortOption, WHEN_OPTIONS, WhenOption, WHERE_OPTIONS, WhereOption} from '@posh/types'
import {
  UnderlinedDropdown,
  UnderlinedDropdownOption,
  UnderlinedDropdownOptionType,
} from 'components/Dropdown/UnderlinedDropdown'

import './styles.scss'

export interface EventMarketplaceMainFilters {
  sort: SortOption
  when: WhenOption
  where: WhereOption
}
export const MainFilters = ({
  sort,
  when,
  where,
  onUpdateFilter,
  setCoordinates,
}: EventMarketplaceMainFilters & {
  onUpdateFilter: (filter: keyof EventMarketplaceMainFilters, value: SortOption | WhenOption | WhereOption) => void
  setCoordinates: React.Dispatch<React.SetStateAction<[number, number] | undefined>>
}) => {
  const onSelectSort = (value: SortOption) => {
    onUpdateFilter('sort', value)
  }

  const onSelectWhen = (value: WhenOption) => {
    onUpdateFilter('when', value)
  }

  const onSelectWhere = (value: WhereOption, coordinates?: [number, number]) => {
    onUpdateFilter('where', value)
    setCoordinates(coordinates)
  }

  const sortOptions = SORT_OPTIONS.filter(o => o !== sort).map(o => ({
    type: 'text' as UnderlinedDropdownOptionType,
    value: o,
  }))
  const whenOptions = WHEN_OPTIONS.filter(o => o !== when).map(o => ({
    type: 'text' as UnderlinedDropdownOptionType,
    value: o,
  }))
  const whereOptions: UnderlinedDropdownOption[] = WHERE_OPTIONS.filter(o => o !== where).map(o => ({
    type: 'text' as UnderlinedDropdownOptionType,
    value: o,
  }))
  whereOptions.push({type: 'city-input'})
  return (
    <div className='MainFilters' onClick={e => e.stopPropagation()}>
      <UnderlinedDropdown
        selectedValue={sort}
        options={sortOptions}
        onSelect={value => onSelectSort(value as SortOption)}
      />
      <UnderlinedDropdown
        selectedValue={when}
        options={whenOptions}
        onSelect={value => onSelectWhen(value as WhenOption)}
      />
      <div className='MainFilters-whereContainer'>
        <p>in</p>
        <UnderlinedDropdown
          selectedValue={where}
          options={whereOptions}
          onSelect={(value, coordinates) => onSelectWhere(value as WhereOption, coordinates)}
        />
      </div>
    </div>
  )
}
