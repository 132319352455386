import React from 'react'

import {EventVisualsLocationInput} from './Location'
import {PlainNumberInput} from './Number'
import {PlainPhoneInput} from './Phone'
import {PlainTextInput} from './Text'

/**
 * Note: Do not use this component directly. Make a variant instead.
 */
export class EventVisualsTextInput extends React.Component {
  static Text = PlainTextInput
  static Location = EventVisualsLocationInput
  static Phone = PlainPhoneInput
  static Number = PlainNumberInput
}
