import React from 'react'

import {IDispute} from 'apis/Groups'
import {OrderModel} from 'apis/Orders'
import {Card} from 'components/Card'
import {isEmpty} from 'lodash'
import moment from 'moment'
import mongoose from 'mongoose'
import Stripe from 'stripe'

const OrderCardTimeline = ({
  order,
  dispute,
  refund,
}: {
  order: OrderModel
  dispute?: IDispute | null
  refund?: Stripe.Refund | null
}) => {
  const orderDate = new mongoose.Types.ObjectId(order._id).getTimestamp()

  interface timelineEvent {
    date: Date
    jsx: JSX.Element
  }

  const timelineEvents: timelineEvent[] = [
    {
      date: orderDate,
      jsx: (
        <>
          {!order.isComplimentaryOrder && (
            <tr>
              <td>
                <div className='OrderPage-Timeline-Dot'>
                  <div></div>
                </div>
              </td>
              <td>
                {order.paymentPlan && order.paymentPlan[0]
                  ? `A down payment of $${order.paymentPlan[0].amount} was made.`
                  : order.tables.length > 0
                  ? `The table was purchased for $${order.subtotal.toFixed(2)}.`
                  : `The tickets were purchased for $${order.subtotal.toFixed(2)}.`}
              </td>
              <td>{moment(orderDate).format('h:mm A')}</td>
            </tr>
          )}
          <tr>
            <td>
              <div className='OrderPage-Timeline-Dot'>
                <div></div>
              </div>
            </td>
            <td>The receipt for the order was sent.</td>
            <td>{moment(orderDate).format('h:mm A')}</td>
          </tr>
        </>
      ),
    },
  ]

  if (order.paymentPlan && order.paymentPlan[0]) {
    order.paymentPlan.slice(1).forEach(p => {
      if (p.status == 'paid') {
        timelineEvents.push({
          date: new Date(p.date),
          jsx: (
            <tr>
              <td>
                <div className='OrderPage-Timeline-Dot'>
                  <div></div>
                </div>
              </td>
              <td>A payment installment of ${p.amount.toFixed(2)} was made.</td>
              <td>{moment(p.date).format('h:mm A')}</td>
            </tr>
          ),
        })
      } else if (p.status == 'requires_action' || p.status == 'failed') {
        timelineEvents.push({
          date: new Date(p.date),
          jsx: (
            <tr>
              <td>
                <div className='OrderPage-Timeline-Dot OrderPage-Timeline-Dot--red'>
                  <div></div>
                </div>
              </td>
              <td style={{color: 'red'}}>A payment installment of ${p.amount.toFixed(2)} failed to charge.</td>
              <td>{moment(p.date).format('h:mm A')}</td>
            </tr>
          ),
        })
      }
    })
  }

  if (order.scanned && order.scannedAt) {
    timelineEvents.push({
      date: new Date(order.scannedAt),
      jsx: (
        <tr>
          <td>
            <div className='OrderPage-Timeline-Dot'>
              <div></div>
            </div>
          </td>
          <td>The order was scanned.</td>
          <td>{moment(order.scannedAt).format('h:mm A')}</td>
        </tr>
      ),
    })
  }

  if (dispute) {
    timelineEvents.push({
      date: new Date(dispute.createdAt),
      jsx: (
        <tr>
          <td>
            <div className='OrderPage-Timeline-Dot OrderPage-Timeline-Dot--red'>
              <div></div>
            </div>
          </td>
          <td style={{color: 'red'}}>The payment associated with the order was disputed.</td>
          <td>{moment(dispute.createdAt).format('h:mm A')}</td>
        </tr>
      ),
    })
  }

  if (refund) {
    const refundText = !isEmpty(order.paymentPlan)
      ? 'The payment plan was refunded.'
      : `The order was refunded for ${(refund.amount / 100).toFixed(2)}`
    timelineEvents.push({
      date: new Date(moment.unix(refund.created).format()),
      jsx: (
        <tr>
          <td>
            <div className='OrderPage-Timeline-Dot OrderPage-Timeline-Dot--orange'>
              <div></div>
            </div>
          </td>
          <td style={{color: 'orange'}}>{refundText}</td>
          <td>{moment.unix(refund.created).format('h:mm A')}</td>
        </tr>
      ),
    })
  }

  const presentDates: string[] = []

  return (
    <Card title={'Timeline'} animationIndex={3}>
      <div className='OrderPage-Timeline'>
        <table>
          <tbody>
            {timelineEvents
              .sort((a, b) => (a.date > b.date ? 1 : -1))
              .map(t => {
                if (presentDates.includes(moment(t.date).format('MMMM Do'))) {
                  return t.jsx
                } else {
                  presentDates.push(moment(t.date).format('MMMM Do'))
                  return (
                    <>
                      <tr>
                        <th colSpan={3}>{moment(t.date).format('MMMM Do')}</th>
                      </tr>
                      {t.jsx}
                    </>
                  )
                }
              })}
          </tbody>
        </table>
      </div>
    </Card>
  )
}

export default OrderCardTimeline
