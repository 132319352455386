import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetPendingEventOptions = ReactQueryOptions['pendingEvents']['getPendingEvent']
type GetPendingEventInput = RouterInputs['pendingEvents']['getPendingEvent']
export type GetPendingEventRawOutput = RouterOutput['pendingEvents']['getPendingEvent']
export type GetPendingEventOutput = Extract<GetPendingEventRawOutput, {isNewSchema: boolean}>['pendingEvent']

export function useGetPendingEvent(
  input: Omit<GetPendingEventInput, 'useCreateEventThreeSchema'>,
  opts?: GetPendingEventOptions,
) {
  const {data, ...rest} = trpc.pendingEvents.getPendingEvent.useQuery({...input, useCreateEventThreeSchema: true}, opts)
  if (data && !('isNewSchema' in data))
    throw new Error('useGetPendingEvent: data is not compatible with the new schema')
  return {data: data?.pendingEvent, ...rest}
}
