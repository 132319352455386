import React from 'react'

import {Item, NavigationSecondaryListItem} from './Item'

import styles from './styles.module.scss'

interface NavigationSecondaryListProps {
  items: Item[]
  selectedIndex: number
}

export function NavigationSecondaryList(props: NavigationSecondaryListProps) {
  const {items, selectedIndex} = props

  return (
    <div className={styles.NavigationSecondaryList}>
      <div className={styles.VerticalLineContainer}>
        <div className={styles.VerticalLine} />
      </div>
      <div className={styles.ItemsContainer}>
        {items.map((item, index) => (
          <NavigationSecondaryListItem key={index} item={item} isSelected={selectedIndex === index} />
        ))}
      </div>
    </div>
  )
}
