import {useCallback, useEffect, useMemo, useState} from 'react'

import {useGeneratePresignedGalleryPutUrl} from 'apis/Util/useGetPresignedGalleryPut'
import {asyncGetBase64Encoding} from 'components/FileUpload/FileUpload'
import isDevEnv from 'helpers/isDevEnv'

import {FileStatus} from '../useMultiFileUploadManager/types'
import {UseMultiFileUploadManager} from '../useMultiFileUploadManager/useMultiFileUploadManager'
import {UploadFileProps, xmlUploadFileToS3} from './uploadFile'

const isdev = isDevEnv()
const bucketName = `posh-spark-gallery-${isdev ? 'staging' : 'production'}`
const SPARK_S3_BUCKET_URL = `https://${bucketName}.s3.amazonaws.com`

function replaceSpacesWithPlus(filename: string): string {
  return filename.split(' ').join('-')
}

type UseUploadFilesProps = Pick<
  UseMultiFileUploadManager,
  'setUploadingFile' | 'setUploadProgress' | 'setUploadSuccess' | 'files' | 'onUploadComplete'
> & {metadata?: Record<string, string>; eventId: string}

export async function uploadFile({
  file,
  progressCallback,
  errorCallback,
  completeCallback,
  presignedUrl,
}: UploadFileProps) {
  return xmlUploadFileToS3(presignedUrl, file, progressCallback).then(completeCallback, errorCallback)
}

export function useUploadFiles({
  files,
  setUploadingFile,
  setUploadProgress,
  setUploadSuccess,
  metadata,
  eventId,
  onUploadComplete,
}: UseUploadFilesProps) {
  const [status, setStatus] = useState<'idle' | 'uploading' | 'uploaded'>('idle')
  const {mutateAsync: generatePresignedGalleryPutUrl} = useGeneratePresignedGalleryPutUrl()

  const filesUploaded = useMemo(() => files.filter(({status}) => status === FileStatus.UPLOADED), [files])
  const filesReadyToUpload = useMemo(() => files.filter(({status}) => status === FileStatus.QUEUED), [files])

  const onError = useCallback((error: string) => {
    console.error(error)
  }, [])

  const onProgress = useCallback(
    (id: string) => (progress: {loaded: number; total: number}) => {
      const progressPercentage = progress.total === 0 ? 100 : Math.floor((progress.loaded / progress.total) * 100)
      setUploadProgress({id, progress: progressPercentage})
    },
    [setUploadProgress],
  )

  const onComplete = useCallback(
    (id: string, s3Key: string) => () => {
      const fileS3Url = `${SPARK_S3_BUCKET_URL}/${s3Key}`
      setUploadSuccess({id, s3Uri: fileS3Url})
    },
    [setUploadSuccess],
  )

  const generatePresignedUrl = useCallback(
    async (s3Key: string, file: File) => {
      const base64File = await asyncGetBase64Encoding(file)
      const presignedUrl = await generatePresignedGalleryPutUrl({
        s3Key: s3Key,
        eventId: eventId,
        base64File: base64File as string,
        shouldSearchFaces: metadata?.['search-faces'] === 'true',
      })
      return presignedUrl
    },
    [generatePresignedGalleryPutUrl, eventId, metadata],
  )

  useEffect(() => {
    if (status === 'uploading' && filesUploaded.length === files.length) {
      setStatus('uploaded')
      onUploadComplete?.()
    }
  }, [filesUploaded, status, onUploadComplete])

  useEffect(() => {
    if (filesReadyToUpload.length > 0) {
      setStatus('uploading')
    }

    filesReadyToUpload.forEach(({file, id}) => {
      if (!file) {
        return
      }

      const s3Key = `events/${eventId}/${replaceSpacesWithPlus(file.name)}`

      generatePresignedUrl(s3Key, file)
        .then(presignedUrl => {
          uploadFile({
            file,
            progressCallback: onProgress(id),
            errorCallback: onError,
            completeCallback: onComplete(id, s3Key),
            metadata: metadata ?? {},
            presignedUrl,
            s3Key,
          })
        })
        .catch(onError)

      setUploadingFile({id})
    })
  }, [filesReadyToUpload, setUploadProgress, setUploadSuccess, setUploadingFile, onProgress, onComplete, onError])

  return {status}
}
