import React from 'react'
import {Collapse} from 'react-collapse'

import {CircleX, Search} from 'components/assets/Icons'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import {EventVisualsTextInputWrapper} from '../TextInput/EventVisualsTextInputWrapper'
import {EventVisualsBaseHtmlInput} from '../TextInput/Text'

import styles from './styles.module.scss'

const ICON_SIZE = 18

interface DropdownSearchInputProps<T> {
  item: T | null
  isFetching: boolean
  searchedItems?: T[]
  onSelectItem: (item: T | null) => void
  onDeselectItem: () => void
  search: string
  setSearch: (search: string) => void
  accentColor: string
  lightMode: boolean
  placeholder: string
  icon: React.ReactNode
  renderItem: (item: T) => React.ReactNode
  renderSelectedItem: (item: T) => React.ReactNode
  fallbackMessage?: string
  fallbackAction?: () => void
}

/**
 * This component is a combination of a dropdown input and a search input that allows for searching and selecting items from a list.
 * You should control your debounced search state outside of this component and pass it in as a prop.
 * @param props
 * @param props.item The currently selected item (optional)
 * @param props.isFetching Whether the component is currently fetching data, should come from a useQuery hook
 * @param props.searchedItems The items that match the search query (optional)
 * @param props.search The current search query (non-debounced)
 * @param props.setSearch The function to update the search query
 * @param props.accentColor The accent color for the component
 * @param props.lightMode Whether the component is in light mode
 * @param props.placeholder The placeholder for the input ie. "Search for a song"
 * @param props.icon The icon to display to the left of the input when no item is selected
 * @param props.renderItem The function to render each item in the dropdown
 * @param props.renderSelectedItem The function to render the selected item
 * @param props.onSelectItem The function to call when an item is selected from the dropdown
 * @param props.onDeselectItem The function to call when the selected item is deselected, this may not always be setting the item to null
 * @param props.fallbackMessage The message to display when there are no results (optional)
 * @param props.fallbackAction The action to take when there are no results (optional) needs to be defined with the message
 */
export const DropdownSearchInput = <T,>(props: DropdownSearchInputProps<T>) => {
  const {
    item,
    isFetching,
    searchedItems,
    search,
    setSearch,
    placeholder,
    renderSelectedItem,
    renderItem,
    onSelectItem,
    onDeselectItem,
    accentColor,
    lightMode,
    icon,
    fallbackMessage,
    fallbackAction,
  } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
  }

  const showDropdown = search !== ''
  const hasNoResults = !!searchedItems && searchedItems.length === 0
  const deleteIconColor = lightMode ? '#e0e0e0' : 'grey'

  return (
    <EventVisualsTextInputWrapper accentColor={accentColor} lightMode={lightMode} blur>
      <Collapse isOpened className={styles.CollapseWrapper}>
        <div className={styles.Input}>
          {item ? (
            <>
              {renderSelectedItem(item)}
              <div onClick={onDeselectItem} className={styles.TrashIconContainer}>
                <CircleX height={ICON_SIZE} width={ICON_SIZE} color={deleteIconColor} />
              </div>
            </>
          ) : (
            <>
              <Icon isLoading={isFetching} icon={icon} />
              <EventVisualsBaseHtmlInput
                placeholder={placeholder}
                accentColor={accentColor}
                lightMode={lightMode}
                value={search}
                onChange={handleChange}
              />
              <Search height={ICON_SIZE} width={ICON_SIZE} />
            </>
          )}
        </div>
        <div className={styles.Results}>
          {showDropdown && (
            <>
              {searchedItems && searchedItems.length > 0 ? (
                searchedItems.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSearch('')
                      onSelectItem(item)
                    }}>
                    {renderItem(item)}
                  </div>
                ))
              ) : (
                <EmptySection
                  isFetching={isFetching}
                  hasNoResults={hasNoResults}
                  fallbackMessage={fallbackMessage}
                  fallbackAction={fallbackAction}
                />
              )}
            </>
          )}
        </div>
      </Collapse>
    </EventVisualsTextInputWrapper>
  )
}

function Icon({isLoading, icon}: {isLoading: boolean; icon: React.ReactNode}) {
  return isLoading ? <SpinLoader height={ICON_SIZE} /> : icon
}

const EmptySection = (props: {
  isFetching: boolean
  hasNoResults: boolean
  fallbackMessage?: string
  fallbackAction?: () => void
}) => {
  const {isFetching, hasNoResults, fallbackMessage, fallbackAction} = props
  const hasFallback = !!fallbackMessage && !!fallbackAction
  if (isFetching) {
    return <p className='noMargin center'>Searching...</p>
  } else if (hasNoResults) {
    return (
      <div>
        <p className='noMargin center'>No results found</p>
        {hasFallback && (
          <p className='underline clickable noMargin center' onClick={fallbackAction}>
            {fallbackMessage}
          </p>
        )}
      </div>
    )
  }
  return null
}
