import { definePalette } from '../../values/palette';
export const base = definePalette({
    transparent: '#FFFFFF00',
    white: '#FFFFFF',
    black: '#000000',
}, { description: 'Our anchors for color blending and transparencies' });
export const brandClassic = definePalette({
    25: '#FEFDF0',
    50: '#FEFBE8',
    100: '#FEF7C3',
    200: '#FEEE95',
    300: '#FDE272',
    400: '#FAC515',
    500: '#EAAA08',
    600: '#CA8504',
    700: '#A15C07',
    800: '#854A0E',
    900: '#713B12',
});
export const brand = definePalette({
    300: '#777777',
    600: '#444444',
    700: '#333333',
    800: '#222222',
    900: '#111111',
    950: '#000000',
});
export const grayscale = definePalette({
    25: '#f9f9f9',
    50: '#fdfdfd',
    100: '#fdfefd',
    200: '#d1d1d1',
    300: '#cbcbcb',
    400: '#c6c6c6',
    500: '#c0c0c0',
    700: '#fbfbfb',
    900: '#afafaf',
});
export const blue = definePalette({
    600: '#1570EFFF',
});
export const blueDark = definePalette({
    400: '#528BFFFF',
});
export const blueLight = definePalette({
    400: '#36BFFAFF',
});
export const cyan = definePalette({
    400: '#22CCEEFF',
    500: '#06AED4FF',
});
export const error = definePalette({
    500: '#F04438FF',
});
export const fuchsia = definePalette({
    300: '#EEAAFDFF',
    500: '#D444F1FF',
    600: '#BA24D5FF',
});
export const grayDarkMode = definePalette({
    100: '#F0F1F1FF',
});
export const grayLightMode = definePalette({
    100: '#C2BFD3FF',
});
export const grayIron = definePalette({
    25: '#FCFCFCFF',
    50: '#FAFAFAFF',
    100: '#F4F4F5FF',
    200: '#E4E4E7FF',
    300: '#D1D1D6FF',
    400: '#A0A0ABFF',
    500: '#70707BFF',
    600: '#51525CFF',
    700: '#3F3F46FF',
    800: '#26272BFF',
    900: '#1A1A1EFF',
    950: '#131316FF',
});
export const grayTrue = definePalette({
    950: '#0F0F0FFF',
});
export const grayWarm = definePalette({
    25: '#FDFDFCFF',
    50: '#FAFAF9FF',
    100: '#F5F5F4FF',
    200: '#E7E5E4FF',
    300: '#D7D3D0FF',
    400: '#A9A29DFF',
    500: '#79716BFF',
    600: '#57534EFF',
    700: '#44403CFF',
    800: '#292524FF',
    900: '#1C1917FF',
});
export const green = definePalette({
    400: '#3CCB7FFF',
});
export const greenLight = definePalette({
    400: '#85E13AFF',
    500: '#66C61CFF',
});
export const indigo = definePalette({
    300: '#A4BCFDFF',
});
export const rose = definePalette({
    400: '#FD6F8EFF',
    500: '#F63D68FF',
    600: '#E31B54FF',
    700: '#C01048FF',
});
export const success = definePalette({
    950: '#053321FF',
});
export const teal = definePalette({
    300: '#5FE9D0FF',
    900: '#134E48FF',
});
export const warning = definePalette({
    400: '#FDB022FF',
});
export const yellow = definePalette({
    400: '#FAC515FF',
    500: '#EAAA08FF',
    600: '#CA8504FF',
});
