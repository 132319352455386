import {RefundRequestForm} from '@posh/model-types'
import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export type CreateRefundRequestParams = {
  eventId: string
} & RefundRequestForm

export async function createRefundRequest(params: CreateRefundRequestParams): Promise<string> {
  const response = await PoshApi.createRefundRequest(params)
  return response.data
}

export function useCreateRefundRequest() {
  return useMutation((params: CreateRefundRequestParams) => createRefundRequest(params))
}
