import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface FetchMailChimpAuthLinkParams {
  groupId: string
  scope?: string
}

async function fetchMailChimpAuthLink(params: FetchMailChimpAuthLinkParams): Promise<string> {
  const response = await PoshApi.fetchMailChimpAuthLink(params)
  return response.data.link
}

export default function useFetchMailChimpAuthLink() {
  return useMutation<string, unknown, FetchMailChimpAuthLinkParams>(params => fetchMailChimpAuthLink(params))
}
