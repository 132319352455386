import React from 'react'

import './index.scss'

interface Props {
  info?: string
}

const PoshHoverModal = (props: Props) => {
  const {info} = props
  return (
    <div className='PoshHoverModal'>
      <span>{info}</span>
    </div>
  )
}

export default PoshHoverModal
