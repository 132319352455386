import {captureException} from '@sentry/react'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    optimizely: any
    dataLayer: any[]
  }
}

type OptimizelyAttribute = 'Opt.GuestlistClickAudience' | 'Opt.EventPageKickbackClicks'

/**
 * These are NOT to be used with the Optimizely web SDK and only for the Optimizely Rollouts Product
 * Do not use this when using Optimizely Feature Experiments
 */
const setOptimizelyUserCustomAttribute = (attribute: OptimizelyAttribute, value?: string | null) => {
  try {
    window.optimizely = window.optimizely || []
    window['optimizely'].push({
      type: 'user',
      attributes: {
        [attribute]: value === undefined ? 'true' : value,
      },
    })
  } catch (e) {
    captureException(e)
  }
}

export default setOptimizelyUserCustomAttribute
