import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {CurrencyCode} from '@posh/types'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import {OrderModel} from 'apis/Orders'
import Badge, {BadgeVariant} from 'components/Badge'
import Table from 'components/Table'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import moment from 'moment'
import mongoose from 'mongoose'
import {useResourcePageParams} from 'pages/PoshAppLayout'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'
import Stripe from 'stripe'

import {CuratorStatus, GroupDispute} from '../../../../apis/Groups'
import {useFetchOrder} from '../../../../apis/Orders/useFetchOrder'
import {DisputeRowAttributes} from '..'

import './styles.scss'

interface Props {
  disputes: GroupDispute[]
}

interface DisputesTableRowProps {
  attributes: DisputeRowAttributes
  currency: CurrencyCode
  stripeJson: Stripe.Dispute
}

type DisputeStatusBadgeMap = {
  [key in Stripe.Dispute.Status]: JSX.Element
}

const DISPUTE_STATUS_BADGE_MAP: DisputeStatusBadgeMap = {
  charge_refunded: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--green'>
      Charge Refunded
    </Badge>
  ),
  lost: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--red'>
      Lost
    </Badge>
  ),
  needs_response: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--fadedRed'>
      Needs Response
    </Badge>
  ),
  under_review: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--fadedRed'>
      Under Review
    </Badge>
  ),
  warning_closed: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--fadedRed'>
      Closed
    </Badge>
  ),
  warning_needs_response: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--red'>
      Needs Response
    </Badge>
  ),
  warning_under_review: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--fadedRed'>
      Under Review
    </Badge>
  ),
  won: (
    <Badge variant={BadgeVariant.SQUARE} className='DisputesTable-badge Badge--greenTwo'>
      Won
    </Badge>
  ),
}

const DisputesTableRow = ({attributes, currency, stripeJson}: DisputesTableRowProps) => {
  const {trackEvent} = useMixpanel()
  const [isExpanded, setIsExpanded] = useState(false)
  const [orderDate, setOrderDate] = useState('')
  const {data: order} = useFetchOrder(attributes.disputedOrder.id)
  const handleViewOrder = () => {
    if (order) {
      setIsExpanded(!isExpanded)
      const x = new mongoose.Types.ObjectId(attributes.disputedOrder.id).getTimestamp()
      setOrderDate(moment(x).format('MM/DD/YY h:mm A'))
    }
  }

  const renderCreatedAt = () => moment(attributes.disputeCreatedAt).format('MM/DD/YY hh:mm A')
  const renderEvent = () => <a>{attributes.disputedEvent.name}</a>
  const renderOrderId = () => `#${attributes.disputedOrder.number}`
  const renderCustomerName = () => <a>{attributes.disputer.name}</a>
  const renderDisputeAmount = () => `${getCurrencySymbol(currency)}${attributes.disputedOrder.amount.toFixed(2)}`
  const renderStatus = () => {
    return DISPUTE_STATUS_BADGE_MAP[attributes.status]
  }

  const {domain, groupId} = useResourcePageParams()
  const navigate = useNavigate()

  const renderCuratorStatus = () => {
    switch (attributes.curatorStatus) {
      case CuratorStatus.COVERED:
        return (
          <Badge variant={BadgeVariant.SQUARE} className='Badge--green'>
            {CuratorStatus.COVERED}
          </Badge>
        )
      case CuratorStatus.DEBITED:
        return (
          <Badge variant={BadgeVariant.SQUARE} className='Badge--redTwo'>
            {CuratorStatus.DEBITED}
          </Badge>
        )
      case CuratorStatus.TBD:
        return (
          <Badge variant={BadgeVariant.SQUARE} className='Badge--gray'>
            {CuratorStatus.TBD}
          </Badge>
        )
    }
  }

  const onClickViewOrderInfo = (order: OrderModel) => {
    trackEvent('View Order Info- Curator Dispute Page')
    navigate(`/${domain}/groups/${groupId}/orders/${order._id}`)
  }

  return (
    <>
      <tr style={{cursor: 'pointer'}} onClick={handleViewOrder}>
        <td>{renderCreatedAt()}</td>
        {/* <td>{renderEvent()}</td> */}
        <td>{renderOrderId()}</td>
        <td>{renderCustomerName()}</td>
        <td>{attributes.disputedOrder.trackingLink}</td>
        <td>{renderDisputeAmount()}</td>
        <td>$15.00</td>
        <td style={{textDecoration: 'none', width: '180px'}}>{renderStatus()}</td>
        <td style={{textDecoration: 'none', width: '180px'}}>{renderCuratorStatus()}</td>
      </tr>
      {isExpanded && order && (
        <>
          <tr style={{backgroundColor: '#141516'}}>
            <td colSpan={2} style={{backgroundColor: '#141516'}}>
              <p>Order Date: {orderDate}</p>
            </td>
            <td colSpan={2} style={{backgroundColor: '#141516'}}>
              <p>Customer&apos;s Reason: {stripeJson.reason}</p>
            </td>
            <td colSpan={2} style={{backgroundColor: '#141516'}}>
              <p>
                <a
                  href={
                    'mailto:' + attributes.disputer.email + '?subject=' + 'Dispute for ' + attributes.disputedEvent.name
                  }
                  onClick={() => {
                    trackEvent('Email Disputer- Curator Dispute Page')
                  }}
                  style={{color: '#fc0', cursor: 'pointer', fontWeight: 'lighter'}}>
                  Email Disputer
                </a>
              </p>
            </td>
            <td colSpan={2} style={{backgroundColor: '#141516'}}>
              <a
                style={{color: '#fc0', cursor: 'pointer', fontWeight: 'lighter'}}
                onClick={() => onClickViewOrderInfo(order)}>
                View Order Info
              </a>
            </td>
          </tr>
        </>
      )}
    </>
  )
}

const headers = [
  'Dispute Created',
  // 'Event',
  'Order',
  'Customer Name',
  'Tracking Link',
  'Disputed Amount',
  'Dispute Fee',
  'Dispute Status',
  'Curator Status',
]

export default function DisputesTable(props: Props) {
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)

  return (
    <Table>
      <tbody>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
        {props.disputes.map(dispute => (
          <DisputesTableRow
            currency={group?.currency as CurrencyCode}
            key={dispute._id}
            attributes={{
              disputeId: dispute._id,
              disputeCreatedAt: dispute.createdAt,
              disputer: {
                accountId: dispute.attendee.id,
                email: dispute.attendee.email,
                phoneNumber: dispute.attendee.phoneNumber ?? '',
                name: dispute.attendee.name,
              },
              disputedEvent: {
                id: dispute.event.id,
                name: dispute.event.name,
              },
              disputedOrder: {
                id: dispute.order.id,
                trackingLink: dispute.order.trackingLink ?? '',
                amount: dispute.order.amount,
                fee: dispute.order.amount,
                number: dispute.order.number,
              },
              status: dispute.stripeDisputeStatus,
              curatorStatus: dispute.curatorStatus,
            }}
            stripeJson={dispute.stripeDisputeJson}
          />
        ))}
      </tbody>
    </Table>
  )
}
