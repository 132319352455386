import React, {useEffect, useState} from 'react'

import {useFetchEventTickets} from 'apis/Events/useFetchEventTickets'
import {KickbackOffer} from 'apis/Kickback/useFetchKickBackOffers'
import useFetchKickbackUsage from 'apis/Kickback/useFetchKickbackUsage'
import {useUpdateKickBackActivation} from 'apis/Kickback/useUpdateKickBackActivation'
import {useUpdateKickBackValue} from 'apis/Kickback/useUpdateKickBackValue'
import {useMixpanel} from 'apis/MixPanelHandler'
import Input from 'components/form/Input'
import PoshSwitch from 'components/form/PoshSwitch'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import {trpc} from 'lib/trpc'
import RecentKickbacks from 'pages/EventManagementPages/Kickstart/KickBackOffersTable/RecentKickbacks'
import SaveDiscardBottomBar from 'pages/EventManagementPages/SaveDiscardBottomBar'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import './styles.scss'

interface EditKickbackOfferProps {
  kickback: KickbackOffer
  setEditKickback: React.Dispatch<React.SetStateAction<KickbackOffer | undefined>>
}

export const EditKickbackOffer = (props: EditKickbackOfferProps) => {
  const {kickback, setEditKickback} = props
  const {mutateAsync: updateKickBackValue} = useUpdateKickBackValue()
  const {mutateAsync: updateKickBackActivation} = useUpdateKickBackActivation()
  const {eventId} = useResourcePageParams()
  const {data: tickets} = useFetchEventTickets(eventId!)
  const {data: kickbackUsage, isFetching} = useFetchKickbackUsage({kickbackId: kickback._id})
  const [newKickBack, setUpdatedKickback] = useState(kickback)
  const [showSave, setShowSave] = useState(false)
  const queryClient = trpc.useContext()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {showToast} = useToast()

  useEffect(() => {
    if (newKickBack.value !== kickback.value || newKickBack.enabled !== kickback.enabled) setShowSave(true)
    else setShowSave(false)
  }, [newKickBack])

  const handleUpdateKickBackValue = () => {
    const isKickbackWithInvalidPercentage = newKickBack.type === 'percentage' && newKickBack.value > 100

    if (newKickBack.value < 0 || isKickbackWithInvalidPercentage) {
      return showToast({type: 'error', title: 'Please enter a valid Kickback offer'})
    } else {
      if (newKickBack.value !== kickback.value) updateKickBackValue({id: newKickBack._id, value: newKickBack.value})
      if (newKickBack.enabled !== kickback.enabled)
        updateKickBackActivation({id: newKickBack._id, enabled: newKickBack.enabled})

      queryClient.kickbacks.getKickbackOffers.invalidate()
      setShowSave(false)
    }
  }

  const handleGoBack = () => {
    queryClient.kickbacks.getKickbackOffers.invalidate()
    setEditKickback(undefined)
  }

  const getAppliesTo = () => {
    const ticket = tickets?.find(t => t.ticket.id.toString() === newKickBack.appliesTo.toString())
    return ticket?.ticket.name ?? ''
  }

  return (
    <div className='EditKickbackOffer'>
      <div className='EditKickbackOffer-backBtn'>
        <PoshImage
          src='https://images.posh.vip/b2/left-arrow-in-circular-button-black-symbol.svg'
          onClick={() => handleGoBack()}
          className='AddKickBack-backBtn'
        />
      </div>
      <h3>{`${newKickBack.type === 'flat' ? '$' : ''}${newKickBack.value}${
        newKickBack.type === 'percentage' ? '%' : ''
      } per ${newKickBack.equation === 'perOrder' ? 'Order' : getAppliesTo()} Kickback`}</h3>
      <div className='EditKickbackOffer-inputs'>
        <div className='EditKickbackOffer-inputSection'>
          <label>Price per Kickback</label>
          <p>
            This is the amount each kickback attendee will make per order they sell. Changing this value will notify any
            existing affiliates that their kickback offer has changed
          </p>
          <Input
            type='number'
            placeholder={'Price'}
            value={newKickBack.value}
            onChange={e => setUpdatedKickback({...newKickBack, value: Number(e.target.value)})}
          />
        </div>
        <div className='EditKickbackOffer-inputSection'>
          <label>Activated</label>
          <p>
            Deactivating a kickback will notify all affiliates that they may no longer get paid for promoting this event
          </p>
          <PoshSwitch
            switchOptions={{
              checked: newKickBack.enabled,
              onChange: checked => {
                trackMixpanelEvent('Toggle Kickback Activation-  Curator Kickback Offer Edit Page')
                setUpdatedKickback({...newKickBack, enabled: checked})
              },
            }}
          />
        </div>
      </div>
      <div>
        {isFetching ? (
          <SpinLoader height={30} />
        ) : (
          <>
            <h3>Usage History</h3>
            <>{kickbackUsage && <RecentKickbacks recentKickbacks={kickbackUsage} />}</>
          </>
        )}
      </div>
      {showSave && (
        <SaveDiscardBottomBar
          isOpen={showSave}
          onSave={() => handleUpdateKickBackValue()}
          onDiscard={() => {
            setUpdatedKickback(kickback)
            setShowSave(false)
          }}
        />
      )}
    </div>
  )
}
