import {CountryCode, CurrencyCode, OrgTypeCode} from '@posh/types'
import {OrderModel} from 'apis/Orders'
import Stripe from 'stripe'

import {EventModel} from '../Events/types'
import {EventFee} from './useGetGroupForFinancials'

export enum AccountRole {
  OWNER = 'owner',
  HOST = 'host',
}

export type WebhookType = 'new_order' | 'new_order_request'

export interface Webhook {
  type: WebhookType
  enabled: boolean
}

interface GroupSocials {
  instagram: string
  twitter: string
  website: string
  linkedIn: string
}

interface Subscription {
  subscriptionID: string
  created: Date
  canceled?: Date
}

export interface IGroupLink {
  title: string
  url: string
}

export interface Group {
  _id: string
  owners: string[]
  name: string
  aviLocation: string
  socials: GroupSocials
  contactEmail: string
  contactPhone: string
  url: string
  hosts: string[]
  subdomain: string
  tags: string
  header: string
  connectId: string // Stripe Connect ID
  currency: CurrencyCode
  country: CountryCode
  profileEnabled: boolean
  hasThirdPartySite?: boolean
  gmv?: number
  textBlastsDisabled?: boolean
  groupPhoneNumber?: string
  payoutsDisabled?: boolean
  bio?: string
  location?: string
  createdAt?: Date
  isDeleted?: boolean
  groupLinks?: IGroupLink[]
  organizationType?: OrgTypeCode
  communityEnabled?: boolean
  defaultFees?: EventFee[]
  verified?: boolean
  subscriptions?: Subscription[]
  enabledWebhooks?: Webhook[]
  forceAllowTextBlasts?: boolean
  webhookSecret?: string
  webhookRoute?: string
  displayAttendeeCount: boolean
}

export interface GroupEventsResponse {
  events: EventModel[]
}

export interface Tag {
  _id: string
  groupID: string
  name: string
  background: string
  accounts: string[]
}

export interface AttendeeRowAttributes {
  ticketIds: string[]
  id: string
  name: string
  ticketsBought: number
  totalSpend: number
  tags: Tag[]
  trackingLinks?: string[]
  email: string
  joinAt: string
  instagramUrl?: string
  joined: string
  phoneNumber: string
  attending: string[]
  avi?: string
  lastOrder?: string
  checkoutFieldResponse?: string
  smsOptIn?: boolean
}

export interface GroupAttendeesResponse {
  attendees: AttendeeRowAttributes[]
  hasMore: boolean
}

export interface FailedNumber {
  accountId?: string
  number: string
  errorDescriptor: string
}

export type Status = 'delivered' | 'queued' | 'failed' | 'sent' | 'undelivered'

export interface Recipient {
  accountID: string
  phone?: string
  sid: string
  status?: Status
  errorCode?: number
  reason?: string
  firstName?: string
}

export interface TextBlast {
  _id: string
  recipients?: Recipient[]
  groupID: string
  eventID?: string
  dateCreated: Date
  trackingLink?: string
  message?: string
  delivered: number
  deliveredMedia: number
  totalAttempted?: number
  totalFailed?: number
  failedMedia?: number
  failedNumbers: FailedNumber[]
}

export interface IDispute {
  _id: string
  eventID: string
  groupID: string
  orderID: string
  createdAt: string
  amount: number
  fee: number
  stripeDisputeStatus: Stripe.Dispute.Status
  stripeDisputeJson: Stripe.Dispute
  paymentIntentId: string
}

export interface GroupDispute extends IDispute {
  curatorStatus: CuratorStatus
  attendee: {
    id: string
    name: string
    email: string
    phoneNumber?: string
  }
  event: {
    id: string
    name: string
  }
  order: {
    id: string
    trackingLink?: string
    amount: number
    fee: number
    number: number
  }
}

export type FilterData = 'attendees' | 'total spend' | 'tickets purchased' | 'last purchased' | ''
export type FilterBy = 'newest to oldest' | 'oldest to newest' | 'highest to lowest' | 'lowest to highest' | ''
export type Gender = 'male' | 'female' | null

export interface FetchGroupAttendeesParams {
  groupId: string
  page: number
  selectedEvents?: string[]
  matchAllEvents?: boolean
  selectedTags?: string[]
  matchAllTags?: boolean
  filterByData?: FilterData
  filterByOrder?: FilterBy
  searchQuery?: string
  genderFilter?: Gender
}

export type GraphType = 'revenue' | 'tickets'
export type GraphInterval = 'week' | 'month' | 'all'

export type PayoutType = 'instant' | 'standard'

export interface UpdateGroupAttributes {
  contactEmail?: string
  contactPhone?: string
  socials?: {
    twitter?: string
    instagram?: string
    website?: string
    linkedIn?: string
  }
  name?: string
  aviLocation?: string
  currency?: string
  country?: string
  header?: string
  location?: string
  bio?: string
  accentColor?: string
  url?: string
  isDeleted?: boolean
  organizationType?: string
  groupLinks?: IGroupLink[]
  displayAttendeeCount?: boolean
}

export enum CuratorStatus {
  TBD = 'tbd',
  OWED = 'money owed',
  COVERED = 'covered',
  DEBITED = 'debited',
}

export interface GroupOrder {
  order: OrderModel
  firstName: string
  lastName: string
  eventName: string
  avi: string
}
