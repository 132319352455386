import {OrderResponse} from '@posh/model-types'

const getResponseValue = (response: OrderResponse) => {
  switch (response.type) {
    case 'input':
      return response.answer
    case 'checkboxes':
      return response.answers.join(', ')
    case 'dropdown':
      return response.answer
    default:
      return response.fileName
  }
}

export default getResponseValue
