import React from 'react'

import {zodResolver} from '@hookform/resolvers/zod'
import {CurrencyCode} from '@posh/types'
import {useCreateTicketType} from 'apis/Events/useCreateTicketType'
import Button from 'components/form/Button'
import {useToast} from 'components/toasts/ToastProvider'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'
import {useResourcePageParams} from 'pages/PoshAppLayout'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import {TicketSettings} from '../TicketSettings'
import {useRefetchAndGoBack} from '../TicketSettings/helpers'
import {CreateTicketFormSchema} from '../TicketSettings/ticketSettingsForm/schema'
import {useTicketSettingsForm} from '../TicketSettings/ticketSettingsForm/useTicketSettingsForm'
import {useTrackCreateTicketMixpanelEvent} from './useTrackCreateTicketMixpanelEvent'

import './styles.scss'

interface CreateTicketProps {
  currency: CurrencyCode
  timezone: string
}

const CreateTicket = (props: CreateTicketProps) => {
  const {currency, timezone} = props
  const {eventId} = useResourcePageParams()
  const {showToast} = useToast()
  const {mutateAsync: addTicketType, isLoading} = useCreateTicketType()
  const currencySymbol = getCurrencySymbol(currency)
  const {refetchAndGoBack, isRefetching} = useRefetchAndGoBack(eventId!)
  const {trackCreateTicketMixpanelEvent} = useTrackCreateTicketMixpanelEvent()

  const form = useTicketSettingsForm({resolver: zodResolver(CreateTicketFormSchema)})

  const createTicket = form.handleSubmit(
    async ticket => {
      if (!eventId) throw new Error('There was an error creating the ticket.')
      trackCreateTicketMixpanelEvent(ticket)
      try {
        const res = await addTicketType({eventId, ticket})
        showToast({type: 'success', title: res.message})
        await refetchAndGoBack()
      } catch (error) {
        showToast({
          type: 'error',
          title: 'There was an error creating the ticket.',
          subtitle: error.response.data.error,
        })
      }
    },
    (err: any) => showToast({type: 'error', title: err.message || 'Check the form for errors.'}),
  )

  return (
    <div className='TicketEditor'>
      <BackAndTitle />
      <TicketSettings form={form} isEditing={false} timezone={timezone} currencySymbol={currencySymbol} />
      <Button
        onClick={createTicket}
        isLoading={isLoading}
        disabled={isLoading || isRefetching || !form.formState.isValid}>
        Create Ticket Type
      </Button>
    </div>
  )
}

export default CreateTicket
