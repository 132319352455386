import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchAffiliatesOptions = ReactQueryOptions['kickbacks']['getAffiliates']
type FetchAffiliatesInput = RouterInputs['kickbacks']['getAffiliates']
export type FetchAffiliatesResponse = RouterOutput['kickbacks']['getAffiliates']
export type Affiliate = FetchAffiliatesResponse[0]

export default function useFetchAffiliates(input: FetchAffiliatesInput, options?: FetchAffiliatesOptions) {
  return trpc.kickbacks.getAffiliates.useQuery(input, options)
}
