import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import Footer from 'pages/HomePage/Footer'

import './PageNotFound.scss'

const PageNotFound = () => {
  const {trackEvent} = useMixpanel()
  const navigate = useNavigate()

  useEffect(() => {
    trackEvent('Error Page View - Lil Terrio')
  }, [])

  return (
    <>
      <div className='PageNotFound'>
        <h3>Sorry, we can&apos;t find the page you&apos;re looking for.</h3>
        <br />
        <PoshImage
          src='https://media0.giphy.com/media/13n7XeyIXEIrbG/giphy.gif?cid=ecf05e47oi00umqywd0j391to5n550b8mpvdizfjzeiyjn5u&rid=giphy.gif&ct=g'
          style={{width: '300px'}}
        />
        <br />
        <div className='buttonWrapper'>
          <Button onClick={() => navigate(-1)}>Go Back</Button>
          <Button onClick={() => navigate('/')}>Go Home</Button>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PageNotFound
