import {useLocation} from 'react-router-dom'

const useGetCurrentPathWithParams = () => {
  const location = useLocation()
  const pathname = location.pathname
  const searchParams = location.search
  const currentPath = `${pathname}${searchParams}`

  return {path: currentPath, encodedPath: encodeURIComponent(currentPath)}
}

export default useGetCurrentPathWithParams
