import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {Group, UpdateGroupAttributes} from '.'

export interface IUpdateGroupParams {
  groupId?: string
  updates: UpdateGroupAttributes
}

export async function updateGroup({groupId, updates}: IUpdateGroupParams): Promise<Group> {
  const response = await PoshApi.updateGroup(groupId!, updates)
  const data = response.data
  return data.group
}

export default function useUpdateGroup() {
  return useMutation<unknown, unknown, IUpdateGroupParams>(params => updateGroup(params))
}
