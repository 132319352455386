import React, {useEffect} from 'react'

import {FilterBy, FilterData} from 'apis/Groups'
import Select from 'components/form/Select'

import useAttendeesCrmContext from '../AttendeesCrmContext'

import './styles.scss'

interface DataFilterProps {
  data: FilterData
  setData: React.Dispatch<React.SetStateAction<FilterData>>
  filterBy: FilterBy
  setFilterBy: React.Dispatch<React.SetStateAction<FilterBy>>
}

const DataFilters = (props: DataFilterProps) => {
  const {data, setData, filterBy, setFilterBy} = props
  const {filterByData, filterByOrder} = useAttendeesCrmContext()

  useEffect(() => {
    if (data === 'total spend' || data === 'tickets purchased') setFilterBy('highest to lowest')
    else setFilterBy('newest to oldest')
  }, [data])

  const isDataSelected = (value: string) => {
    if (filterByData && !data) {
      return value === filterByData
    } else return value === data
  }

  const isOrderSelected = (value: string) => {
    if (filterByOrder && !data) {
      return value === filterByOrder
    } else return value === filterBy
  }

  return (
    <div className='DataFiltersSection'>
      <div className='FilterModal-topRow'>
        <h6>Sort By</h6>
      </div>
      <div className='DataFiltersSection-selectWrapper'>
        <Select onChange={e => setData(e.target.value as FilterData)}>
          <option value='attendees' selected={isDataSelected('attendees')}>
            Attendees
          </option>
          <option value='total spend' selected={isDataSelected('total spend')}>
            Total Spend
          </option>
          <option value='tickets purchased' selected={isDataSelected('tickets purchased')}>
            Tickets Purchased
          </option>
          <option value='last purchased' selected={isDataSelected('last purchased')}>
            Last Purchased
          </option>
        </Select>
        {!isDataSelected('total spend') && !isDataSelected('tickets purchased') ? (
          <Select onChange={e => setFilterBy(e.target.value as FilterBy)}>
            <option selected={isOrderSelected('newest to oldest')} value='newest to oldest'>
              Newest to Oldest
            </option>
            <option selected={isOrderSelected('oldest to newest')} value='oldest to newest'>
              Oldest to Newest
            </option>
          </Select>
        ) : (
          <Select onChange={e => setFilterBy(e.target.value as FilterBy)}>
            <option selected={isOrderSelected('highest to lowest')} value='highest to lowest'>
              Highest to Lowest
            </option>
            <option selected={isOrderSelected('lowest to highest')} value='lowest to highest'>
              Lowest to Highest
            </option>
          </Select>
        )}
      </div>
    </div>
  )
}

export default DataFilters
