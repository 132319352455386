import React, {useState} from 'react'

import Button from 'components/form/Button'

import {AccountData} from '../Accounts'
import {CampaignLinkData} from '../CampaignLinks'
import {EventData} from '../Events'
import {OrgData} from '../Orgs'
import CRUDCreateModal from './createModal'
import CRUDTableRow from './row'

import './styles.scss'

type CRUDTableMapType = 'string' | 'select' | 'multiSelect' | 'url'

export interface CRUDTableMapObj {
  label: string
  key: string
  type: CRUDTableMapType
  editable: boolean
  options?: string[]
}

export type CRUDTableMap = CRUDTableMapObj[]

export type CRUDFunction = ({id, item}: {id: string; item?: any}) => Promise<void>

export type CRUDTableDataMap = {
  refetchDataFunction: any
  entityName: string
  createFunction?: CRUDFunction
  isCreateFunctionLoading?: boolean
  updateFunction: CRUDFunction
  isUpdateFunctionLoading?: boolean
  moreInfoFunction?: CRUDFunction
  deleteFunction?: CRUDFunction
  viewFunction?: CRUDFunction
  copyFunction?: CRUDFunction
}

// Add interfaces here to accept new data types
export type CRUDTableData = CampaignLinkData | AccountData | EventData | OrgData

interface CRUDTableProps {
  payload: CRUDTableData
  map: CRUDTableMap
}

const CRUDTable = (props: CRUDTableProps) => {
  const {payload, map} = props
  const {
    data,
    refetchDataFunction,
    entityName,
    createFunction,
    isCreateFunctionLoading,
    updateFunction,
    deleteFunction,
    viewFunction,
    copyFunction,
    moreInfoFunction,
  } = payload
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  return (
    <>
      <table className='CRUDTable'>
        <tbody>
          <tr>
            {map.map((obj, index) => (
              <th key={index}>{obj.label}</th>
            ))}
            <th>Actions</th>
          </tr>
          {data &&
            data.length > 0 &&
            data.map((row: any, index: number) => (
              <CRUDTableRow
                key={JSON.stringify(row)}
                row={row}
                map={map}
                refetchDataFunction={refetchDataFunction}
                updateFunction={updateFunction}
                deleteFunction={deleteFunction}
                viewFunction={viewFunction}
                copyFunction={copyFunction}
                moreInfoFunction={moreInfoFunction}
              />
            ))}
        </tbody>
      </table>
      {createFunction && (
        <div className='CRUDTable-CreateBtnWrapper'>
          <Button onClick={() => setIsCreateModalOpen(true)}>+ Create {entityName}</Button>
        </div>
      )}
      <CRUDCreateModal
        refetchDataFunction={refetchDataFunction}
        entityName={entityName}
        map={map}
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(!isCreateModalOpen)}
        createFunction={createFunction}
        isCreateFunctionLoading={isCreateFunctionLoading}
      />
    </>
  )
}

export default CRUDTable
