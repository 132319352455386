import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {EventTicketAndTicketSold} from './types'

export interface EventTicketsResponse {
  eventTickets: EventTicketAndTicketSold[]
}

export async function fetchEventTickets(eventId: string): Promise<EventTicketAndTicketSold[]> {
  const response = await PoshApi.fetchEventTickets(eventId)
  const data: EventTicketsResponse = response.data
  return data.eventTickets
}

export function useFetchEventTickets(eventId: string) {
  return useQuery(['eventTickets', eventId], () => fetchEventTickets(eventId))
}
