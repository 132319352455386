import {MAX_TICKET_QUANTITY} from '@posh/model-types'
import {Ticket} from 'apis/Events/types'

import {BaseTicketForm} from './schema'

/**
 * Transforms the form values into a format that can be used by the update API
 * @param data The form data to transform
 * @returns The transformed ticket object
 */
export function transformFormValuesToApiInputType<T extends BaseTicketForm>(data: T): Partial<Ticket> {
  const {quantityAvailable, validity} = data
  const {validBefore, validAfter} = validity || {}
  return {
    ...data,
    quantityAvailable: !quantityAvailable ? MAX_TICKET_QUANTITY : quantityAvailable,
    validity: {
      validAfter: validAfter ? new Date(validAfter) : undefined,
      validBefore: validBefore ? new Date(validBefore) : undefined,
    },
  }
}
