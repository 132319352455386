import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'

import {GroupAccountRoleUnion} from 'apis/Accounts'
import {useFetchAccountGroups} from 'apis/Accounts/useFetchAccountGroups'
import classNames from 'classnames'
import {useGroupMatchParams} from 'domains/Groups/helpers'

import {BaseNavigationOrgSelect, BaseNavigationOrgSelectProps} from './BaseOrgSelect'
import {useGetCurrentGroup} from './useGetCurrentGroup'

import styles from './styles.module.scss'

interface AccountGroupUnionOrgSelectProps {
  currentGroup: GroupAccountRoleUnion | undefined
  accountGroupUnions: GroupAccountRoleUnion[] | undefined
  onSelectGroupAccountRoleUnion: (groupAccountRoleUnion: GroupAccountRoleUnion) => void
  onPressCreateNewOrganization: () => void
  onExpand?: () => void
  className?: string
}

export function AccountGroupUnionOrgSelect(props: AccountGroupUnionOrgSelectProps) {
  const {currentGroup, accountGroupUnions, onSelectGroupAccountRoleUnion, onPressCreateNewOrganization, onExpand} =
    props

  const {selectedIndex, groups} = useMemo(
    // we need an array of each of the groups from the accountGroupUnions and the index of the currentGroup, determined by the currentGroup's group._id
    // instead of using a map and a find, we can do this in one pass with a reduce
    () =>
      (accountGroupUnions ?? []).reduce(
        (acc, agu, index) => {
          acc.groups.push(agu.group)
          if (currentGroup?.group._id === agu.group._id) acc.selectedIndex = index
          return acc
        },
        {selectedIndex: 0, groups: []} as {selectedIndex: number; groups: BaseNavigationOrgSelectProps['groups']},
      ),
    [currentGroup, accountGroupUnions],
  )

  return (
    <BaseNavigationOrgSelect
      selectedIndex={selectedIndex}
      groups={groups}
      onSelectGroup={index => accountGroupUnions && onSelectGroupAccountRoleUnion(accountGroupUnions[index])}
      onPressCreateNewOrganization={onPressCreateNewOrganization}
      onExpand={onExpand}
      className={classNames([styles.AccountGroupUnionOrgSelect, props.className])}
    />
  )
}

type Optional = 'onPressCreateNewOrganization' | 'onSelectGroupAccountRoleUnion'
type Omitted = 'currentGroup' | 'accountGroupUnions' | Optional

export type WithDataAccountGroupUnionOrgSelectProps = Omit<AccountGroupUnionOrgSelectProps, Omitted> &
  Partial<Pick<AccountGroupUnionOrgSelectProps, Optional>>

function WithData(props: WithDataAccountGroupUnionOrgSelectProps) {
  const navigate = useNavigate()
  const {groupId} = useGroupMatchParams()
  const {data: accountGroupUnions} = useFetchAccountGroups()
  const {currentGroup, setCurrentGroup} = useGetCurrentGroup(accountGroupUnions, groupId)

  const onPressCreateNewOrganization = () => {
    navigate({pathname: `/create_group`})
    props.onPressCreateNewOrganization && props.onPressCreateNewOrganization()
    return
  }

  const onSelectGroupAccountRoleUnion = (groupAccountRoleUnion: GroupAccountRoleUnion) => {
    setCurrentGroup(groupAccountRoleUnion)
    props.onSelectGroupAccountRoleUnion && props.onSelectGroupAccountRoleUnion(groupAccountRoleUnion)
    navigate(`/${groupAccountRoleUnion.accountRole.roleKey}/groups/${groupAccountRoleUnion.group._id}/overview`)
    return
  }

  return (
    <AccountGroupUnionOrgSelect
      {...props}
      currentGroup={currentGroup}
      accountGroupUnions={accountGroupUnions}
      onPressCreateNewOrganization={onPressCreateNewOrganization}
      onSelectGroupAccountRoleUnion={onSelectGroupAccountRoleUnion}
    />
  )
}

AccountGroupUnionOrgSelect.WithData = WithData
