import Compress from 'react-image-file-resizer'

import PoshApi from '../PoshApi'
import {ImageSize, UploadImageResponse} from '.'

export async function uploadImage(file: File, size?: ImageSize): Promise<string> {
  const resizeFile = () =>
    new Promise(resolve => {
      Compress.imageFileResizer(
        file!,
        size ?? ImageSize.DEFAULT,
        size ?? ImageSize.DEFAULT,
        'JPEG',
        60,
        0,
        uri => {
          resolve(uri)
        },
        'blob',
      )
    })
  // We dont wanna compress gifs because that turns them into jpegs
  if (file.type === 'image/gif') {
    const response = await PoshApi.uploadImage(file)
    const data: UploadImageResponse = response.data
    return data.domain
  } else {
    const image = await resizeFile()
    const response = await PoshApi.uploadImage(image as File)
    const data: UploadImageResponse = response.data
    return data.domain
  }
}
