import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {PromoCode} from './types'

export interface CreatePromoCodeResponse {
  created: PromoCode
}
export interface CreatePromoCodeParams extends Omit<PromoCode, '_id'> {
  eventId: string
}

async function createPromoCode(data: CreatePromoCodeParams): Promise<CreatePromoCodeResponse> {
  const response = await PoshApi.createPromoCode(data)
  return response.data
}

export function useCreatePromoCode(opts?: UseMutationOptions<CreatePromoCodeResponse, any, CreatePromoCodeParams>) {
  return useMutation((params: CreatePromoCodeParams) => createPromoCode(params), opts)
}
