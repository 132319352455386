import React, {PropsWithChildren, useMemo} from 'react'
import {useMediaQuery} from 'react-responsive'

import {useFetchFeaturedEvents} from 'apis/Events/EventMarketplace/useFetchFeaturedEvents'
import {
  FetchMarketplaceEventsInput,
  useFetchMarketplaceEvents,
} from 'apis/Events/EventMarketplace/useFetchMarketplaceEvents'
import {PoshBoatLoader} from 'components/Loaders/PoshBoatLoader'
import {useToast} from 'components/toasts/ToastProvider'
import {useIntersectionObserverRef} from 'hooks/useIntersectionObserver'
import moment from 'moment-timezone'
import {EventCard, EventCardSkeleton} from 'pages/EventCard'

/**
 * This matches up to the CSS logic for .EventCardGrid in apps/client/src/assets/stylesheets/globals/_spacing.scss
 */
const useGetItemsPerRow = () => {
  const isOne = useMediaQuery({
    query: '(max-width: 576px)',
  })
  const isTwo = useMediaQuery({
    query: '(max-width: 1000px)',
  })
  const isThree = useMediaQuery({
    query: '(max-width: 1400px)',
  })
  const isFour = useMediaQuery({
    query: '(max-width: 1800px)',
  })
  const isFive = useMediaQuery({
    query: '(max-width: 2100px)',
  })
  return useMemo(() => {
    if (isOne) return 1
    if (isTwo) return 2
    if (isThree) return 3
    if (isFour) return 4
    if (isFive) return 5
    return 6
  }, [isOne, isTwo, isThree, isFour, isFive])
}

const getItemsPerPage = (itemsPerRow: number) => {
  if (itemsPerRow === 1) return 6
  return itemsPerRow * 3
}

export const EventListings = ({children}: PropsWithChildren<unknown>) => {
  return <div className='Explore-body-main-results EventCardGrid'>{children}</div>
}

export const FeaturedEventsMobile = () => {
  const {data: featuredEvents, isLoading} = useFetchFeaturedEvents()
  if (isLoading) return <PoshBoatLoader />
  return (
    <EventListings>
      {featuredEvents?.map(event => (
        <EventCard
          key={`${event.name}_${event.venue?.name}_${event.startUtc}`}
          backgroundImage={event.flyer!}
          url={event.url}
          useTrackingLink={true}
          name={event.name}
          startUtc={event.startUtc ? new Date(event.startUtc) : new Date()}
          timezone={event.timezone}
          venueName={event.venue?.name}
          groupAvi={event.groupAvi}
        />
      ))}
    </EventListings>
  )
}

const clientTimezone = moment.tz.guess()
export const MarketplaceEvents = (props: Omit<FetchMarketplaceEventsInput, 'clientTimezone'>) => {
  const {showToast} = useToast()
  const itemsPerRow = useGetItemsPerRow()
  const itemsPerPage = getItemsPerPage(itemsPerRow)
  const {
    data,
    isLoading,
    hasNextPage: hasMoreEvents,
    isFetching: isFetchingMoreEvents,
    fetchNextPage,
  } = useFetchMarketplaceEvents(
    {...props, limit: itemsPerPage, clientTimezone},
    {
      onError: () => {
        showToast({type: 'error', title: 'There was an error fetching events. Please try again later.'})
      },
    },
  )
  const events = useMemo(() => {
    if (data?.pages) {
      return data.pages.flatMap(page => page.events)
    }
    return []
  }, [data])

  const observedElementRef = useIntersectionObserverRef<HTMLDivElement>({
    callback: entries => {
      if (entries[0].isIntersecting && hasMoreEvents && !isFetchingMoreEvents) {
        fetchNextPage()
      }
    },
    options: {
      threshold: 0.25,
    },
  })
  // We want to have the observed element that will trigger fetching the next page
  // to be an element in the second to last row of the list
  const observedElementIndex = events.length - 1 - itemsPerRow

  if (isLoading) {
    return (
      <div style={{height: '100vh'}}>
        <PoshBoatLoader />
      </div>
    )
  }

  if (events.length === 0) {
    return <h2>No Events Found!</h2>
  }

  return (
    <EventListings>
      {events.map((event, index) => (
        <EventCard
          ref={index === observedElementIndex ? observedElementRef : undefined}
          key={`${event.name}_${event.venue?.address}_${event.startUtc}`}
          backgroundImage={event?.flyer}
          ranking={index + 1}
          url={event.url}
          useTrackingLink={true}
          name={event.name}
          startUtc={event.startUtc ? new Date(event.startUtc) : new Date()}
          timezone={event.timezone}
          groupAvi={event.groupAvi}
          venueName={event.venue?.name}
        />
      )) ?? <div style={{height: '10000px'}}></div>}
      {isFetchingMoreEvents &&
        new Array(itemsPerRow).fill(0).map((_, index) => <EventCardSkeleton key={'skeleton' + index} />)}
    </EventListings>
  )
}
