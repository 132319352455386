import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface SetNotificationActionTakenResponse {
  message: string
}

export interface SetNotificationActionTakenParams {
  notificationId: string
}

async function setNotificationActionTaken(
  data: SetNotificationActionTakenParams,
): Promise<SetNotificationActionTakenResponse> {
  const response = await PoshApi.setNotificationActionTaken(data)
  return response.data
}

export function useSetNotificationActionTaken(
  opts?: UseMutationOptions<SetNotificationActionTakenResponse, any, SetNotificationActionTakenParams>,
) {
  return useMutation((params: SetNotificationActionTakenParams) => setNotificationActionTaken(params), opts)
}
