import React, {useEffect, useState} from 'react'
import {Navigate, Outlet, useLocation} from 'react-router-dom'

import {SpinLoader} from 'components/Loaders/SpinLoader'

import useCheckPoshAdmin from '../../apis/AdminDashboard/useCheckPoshAdmin'
import {setSessionContext} from '../../apis/Auth/setSessionContext'
import useSessionContext from '../../domains/Auth/SessionContext'

const AdminProtectedRoute = () => {
  const sessionContext = useSessionContext()
  const {userId} = sessionContext
  const [ready, setReady] = useState(false)

  const search = useLocation().search
  const wvAuth = new URLSearchParams(search).get('wvAuth')

  const {data: check, isFetched, isFetching: isLoading} = useCheckPoshAdmin()

  //This is to support mobile webview
  useEffect(() => {
    if (wvAuth && !userId) {
      try {
        const auth = JSON.parse(atob(wvAuth))
        setSessionContext(auth, sessionContext)
      } catch (ignored) {}
    }
    setReady(true)
  }, [search])

  return (
    <>
      {isLoading && (
        <div
          className='wrapper'
          style={{
            marginTop: '1rem',
          }}>
          <SpinLoader height={80} />
        </div>
      )}
      {isFetched && (
        <>
          {check?.authorized && <Outlet />}
          {!check?.authorized && <Navigate to='/' />}
        </>
      )}
    </>
  )
}

export default AdminProtectedRoute
