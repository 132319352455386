import React from 'react'

import 'react-phone-number-input/style.css'

import {CountryCode} from '@posh/types'
import PhoneNumberInput from 'components/form/PhoneNumberInput'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

export type PhoneNumberFieldCheckoutProps = {
  field_key: string
  value?: string
  field_type: 'phone_number'
  required: boolean | undefined
  placeholder?: string
  defaultCountry?: string
  className?: string
  disabled?: boolean
}

export const PhoneNumberFieldCheckout = (props: PhoneNumberFieldCheckoutProps) => {
  const {value: initialValue, field_key, required, placeholder, defaultCountry, className, disabled} = props
  const {updateCartFormField} = useLiveCartContext()

  const onChange = (e: string) => {
    updateCartFormField(field_key, e)
  }
  return (
    <PhoneNumberInput
      className={className ?? 'CheckoutPage-phoneNumberInput'}
      placeholder={placeholder}
      defaultCountry={defaultCountry as CountryCode}
      value={initialValue ?? ''}
      onChange={onChange}
      required={required}
      disabled={disabled}
    />
  )
}
