import React from 'react'

export const PhoneSVG = () => {
  return (
    <svg height='469pt' viewBox='-106 0 469 469.33333' width='469pt' xmlns='http://www.w3.org/2000/svg'>
      <path d='m206.246094 0h-156.160156c-27.558594.03125-49.890626 22.363281-49.917969 49.921875v369.492187c.027343 27.558594 22.359375 49.890626 49.917969 49.917969h156.160156c27.558594-.027343 49.890625-22.359375 49.921875-49.917969v-369.492187c-.03125-27.558594-22.363281-49.890625-49.921875-49.921875zm-78.078125 426.667969c-11.785157 0-21.335938-9.550781-21.335938-21.335938 0-11.78125 9.550781-21.332031 21.335938-21.332031 11.78125 0 21.332031 9.550781 21.332031 21.332031 0 11.785157-9.550781 21.335938-21.332031 21.335938zm32-362.667969h-64c-5.890625 0-10.667969-4.777344-10.667969-10.667969s4.777344-10.664062 10.667969-10.664062h64c5.890625 0 10.664062 4.773437 10.664062 10.664062s-4.773437 10.667969-10.664062 10.667969zm0 0' />
    </svg>
  )
}

export const EmailSVG = () => {
  return (
    <svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512'>
      <g>
        <g>
          <path
            d='M331.756,277.251l-42.881,43.026c-17.389,17.45-47.985,17.826-65.75,0l-42.883-43.026L26.226,431.767
                        C31.959,434.418,38.28,436,45,436h422c6.72,0,13.039-1.58,18.77-4.232L331.756,277.251z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M467,76H45c-6.72,0-13.041,1.582-18.772,4.233l164.577,165.123c0.011,0.011,0.024,0.013,0.035,0.024
                        c0.011,0.011,0.013,0.026,0.013,0.026l53.513,53.69c5.684,5.684,17.586,5.684,23.27,0l53.502-53.681c0,0,0.013-0.024,0.024-0.035
                        c0,0,0.024-0.013,0.035-0.024L485.77,80.232C480.039,77.58,473.72,76,467,76z'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M4.786,101.212C1.82,107.21,0,113.868,0,121v270c0,7.132,1.818,13.79,4.785,19.788l154.283-154.783L4.786,101.212z' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M507.214,101.21L352.933,256.005L507.214,410.79C510.18,404.792,512,398.134,512,391V121
                        C512,113.866,510.18,107.208,507.214,101.21z'
          />
        </g>
      </g>
    </svg>
  )
}
