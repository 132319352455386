import React from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'

interface INumberItem {
  title: string
  value: string
  iconUrl: string
  onClick: () => void
  shouldShowDetailIcon: boolean
}

const NumberItem = ({title, value, iconUrl, onClick, shouldShowDetailIcon}: INumberItem) => {
  return (
    <div
      className={`AdminDashboard-analytics-${
        shouldShowDetailIcon ? 'numberItemContainerHover' : 'numberItemContainer'
      }`}
      onClick={onClick}>
      <div className='AdminDashboard-analytics-icon'>
        <PoshImage src={iconUrl} />
      </div>
      <div className='AdminDashboard-analytics-bottomTextContainer'>
        <div className='AdminDashboard-analytics-largeText'>{value}</div>
        <div className='AdminDashboard-analytics-smallText'>{title}</div>
      </div>
    </div>
  )
}

export default NumberItem
