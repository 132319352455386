import React from 'react'
import {Route, Routes} from 'react-router-dom'

import PageNotFound from 'pages/PageNotFound/PageNotFound'

import AccountHome from './Home'
import AccountSettings from './Settings'

const Account = () => {
  return (
    <div className='OwnerPage'>
      <Routes>
        <Route path={''} element={<AccountHome />} />
        <Route path={'settings'} element={<AccountSettings />} />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </div>
  )
}

export default Account
