import React from 'react'
import {Link} from 'react-router-dom'

import {formatEventPlaylistDate} from '@posh/model-types'
import {TEventPlaylist} from 'apis/Events/playlists/useGetEventPlaylist'
import {useMixpanel} from 'apis/MixPanelHandler'
import {PoshImage} from 'components/PoshImage/PoshImage'
import UserListPreview from 'components/UserListPreview'
import VerifiedGroupBadge from 'components/VerifiedGroupBadge'
import {motion} from 'framer-motion'
import {useDimensions} from 'hooks/useDimensions'

import styles from './EventPlaylistCard.module.scss'

export interface EventPlaylistCardProps {
  event: TEventPlaylist['events'][number]
  playlistSlug: string
  backgroundImagePalette: TEventPlaylist['backgroundImagePalette']
}

export const EventPlaylistCard = (props: EventPlaylistCardProps) => {
  const {event, playlistSlug} = props

  const {
    id,
    name,
    flyer,
    startUtc,
    groupName,
    groupAvi,
    groupVerified,
    groupUrl,
    ctaText,
    url,
    attendeePreview,
    attendeeCount,
    timezone,
    location,
  } = event

  const {trackEvent} = useMixpanel()
  const {day, time, timezoneAbbr} = formatEventPlaylistDate(startUtc, timezone)

  const {isTablet} = useDimensions()
  const smallView = isTablet

  return (
    <motion.a
      whileHover={{scale: 1.01, transition: {duration: 0.25}, cursor: 'pointer'}}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      href={`/e/${url}?t=posh-playlist-${playlistSlug}`}
      onClick={() => trackEvent('Event Click - Playlists', event)}
      className={styles.EventPlaylistCard}>
      <PoshImage src={flyer} alt={`${name} Event Flyer`} />
      <div className={styles.EventPlaylistCardContainer}>
        <div className={styles.EventDetails}>
          <div className={styles.GroupAttributes}>
            <Link className={styles.GroupLink} onClick={e => e.stopPropagation()} to={`/g/${groupUrl}`}>
              <UserListPreview>
                <UserListPreview.User imageSrc={groupAvi} alt={groupName} />
              </UserListPreview>
              <p>{groupName}</p>
            </Link>
            {groupVerified && <VerifiedGroupBadge id={id} size={15} />}
          </div>
          <div>
            <h3>{name}</h3>
          </div>

          <div className={styles.AttendingAndCta}>
            <>
              {!smallView && (
                <>
                  {attendeePreview && attendeePreview.length > 0 && (
                    <UserListPreview>
                      {attendeePreview.map((attendee, index) => (
                        <UserListPreview.User
                          key={index}
                          imageSrc={attendee.avi}
                          alt={`${attendee.firstName} ${attendee.lastName} Profile Picture`}
                        />
                      ))}
                    </UserListPreview>
                  )}
                  {!!attendeeCount && (
                    <>
                      <p className={styles.Attending__count}>
                        {new Intl.NumberFormat().format(attendeeCount)}+ attending
                      </p>
                      <div className={styles.Attending__divider} />
                    </>
                  )}
                  <p className={styles.CtaText}>{ctaText}</p>
                </>
              )}
            </>
            {smallView && (
              <div>
                <p>
                  {day} {time} {timezoneAbbr && `(${timezoneAbbr})`}
                </p>
                <p className={styles.Location}>{location}</p>
              </div>
            )}
          </div>
        </div>
        <div className={styles.Divider} />
        {!smallView && (
          <div className={styles.EventDate}>
            <p>{day.toUpperCase()}</p>
            <p>{time}</p>
            {timezoneAbbr && <p style={{fontSize: 13}}>({timezoneAbbr})</p>}
          </div>
        )}
      </div>
    </motion.a>
  )
}
