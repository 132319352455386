import {capitalize} from 'lodash'

const multipleCapitalize = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(w => capitalize(w))
    .join(' ')
}

export default multipleCapitalize
