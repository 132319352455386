import React from 'react'

interface FilterIconProps {
  label: string
  color: string
  onRemove: () => void
}

const FilterIcon = (props: FilterIconProps) => {
  const {label, color, onRemove} = props
  return (
    <div className='FilterIcon' style={{backgroundColor: color}}>
      <p>{label}</p>
      <a onClick={() => onRemove()}>ⓧ</a>
    </div>
  )
}
export default FilterIcon
