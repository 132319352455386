import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface DeleteGroupCustomFeeProps {
  name: string
  groupId: string
}

async function deleteGroupCustomFee(props: DeleteGroupCustomFeeProps) {
  const response = await PoshApi.deleteGroupCustomFee(props)
  return response.data
}

export default function useDeleteGroupCustomFee() {
  return useMutation((props: DeleteGroupCustomFeeProps) => deleteGroupCustomFee(props))
}
