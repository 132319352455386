import React, {useState} from 'react'

import {CurrencyCode} from '@posh/types'
import {useQueryClient} from '@tanstack/react-query'
import {OrderModel} from 'apis/Orders'
import useRefundOrder from 'apis/Orders/useRefundOrder'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import './styles.scss'

interface PartialRefundProps extends PoshStyledModalProps {
  order: OrderModel
  currency: CurrencyCode | undefined
}
export default function PartialRefundModal(props: PartialRefundProps) {
  const {onClose, currency, order} = props
  const {partialRefund, subtotal} = order
  const {mutateAsync: sendPartialRefund, isLoading} = useRefundOrder()
  const queryClient = useQueryClient()
  const symbol = getCurrencySymbol(currency)
  const [refundAmount, setRefundAmount] = useState(0)
  const {showToast} = useToast()

  const handleSubmit = async () => {
    try {
      if (!order.eventID) throw new Error('There was an issue processing your refund. Please try again later.')
      else if (!refundAmount) throw new Error('You must input a value to be refunded.')
      else if (refundAmount < 0) throw new Error('You cannot refund a negative amount.')
      else if (refundAmount > subtotal)
        throw new Error('You cannot process a refund that is greater than what was originally charged.')
      else if (partialRefund && partialRefund + refundAmount > subtotal)
        throw new Error(
          `This order has already been refunded ${symbol}${partialRefund}. A follow up partial refund cannot exceed ${symbol}${
            Math.floor((subtotal - partialRefund) * 100) / 100
          }`,
        )
      await sendPartialRefund({
        orderId: order._id,
        eventId: order.eventID,
        partialAmount: refundAmount,
        isReselling: false,
      })

      showToast({
        type: 'success',
        title:
          'Your partial refund has been processed successfully. A confirmation email has been sent to the attendee.',
      })
      onClose()
      await queryClient.invalidateQueries(['order', order._id])
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  return (
    <PoshStyledModal {...props}>
      <div className='PartialRefundModal'>
        <h4 className='center'>Partial Refund</h4>
        <p>{`Total Charged: ${symbol}${subtotal.toFixed(2)}`}</p>
        <Input type='number' onChange={e => setRefundAmount(Number(e.target.value))} currencySymbol={symbol} />
        <Button
          className='fullWidth gold'
          disabled={isLoading || refundAmount <= 0 || (partialRefund ?? 0) + refundAmount > subtotal}
          onClick={handleSubmit}
          isLoading={isLoading}>
          {`Process ${symbol}${refundAmount.toFixed(2) || 0} Refund`}
        </Button>
      </div>
    </PoshStyledModal>
  )
}
