import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

async function fetchCampaignLinkByShortUrl(campaignShortUrl?: string, gclid?: string): Promise<string | undefined> {
  if (!campaignShortUrl) return undefined
  const response = await PoshApi.fetchCampaignLinkByShortUrl(campaignShortUrl, gclid)
  return response.data.destination_url
}

export function useFetchCampaignLinkByShortUrl(
  campaignShortUrl?: string,
  gclid?: string,
  opts?: UseQueryOptions<string | undefined>,
) {
  return useQuery<string | undefined>(
    ['campaignLink', {campaignShortUrl, gclid}],
    () => fetchCampaignLinkByShortUrl(campaignShortUrl, gclid),
    {
      ...opts,
      refetchOnMount: false,
      retry: false,
      enabled: !!campaignShortUrl,
    },
  )
}
