import Color from 'color'

/**
 * This function is used to get a color from a fixed list of items so that they are evenly spaced
 * @param index The index of the item in the list
 * @param total The total number of items in the list
 * @returns A color in hsl format (e.g. `hsl(0, 100%, 50%)`)
 */
export const getColorEvenlySpaced = (index: number, total: number, offset?: number): Color => {
  if (index < 0 || index > total) {
    throw new Error('Index must be between 0 and the total')
  }

  const hue = (index / total) * 360 + (offset ?? 0)
  return new Color().hsl(hue, 100, 50)
}
