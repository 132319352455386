import React, {PropsWithChildren} from 'react'

import {Error} from '../Error/Error'

import styles from './InputContainer.module.scss'

// This component is just a shallow wrapper that takes an optional error to maintain a consistent style across
//  all input components and select, radio, and checkbox components.

export interface InputContainerProps {
  error?: string
}

export const InputContainer = (props: PropsWithChildren<InputContainerProps>) => {
  const {children, error} = props

  return (
    <div className={styles.InputContainer}>
      {children}
      {error && <Error error={error} />}
    </div>
  )
}
