// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form__KU6J7{display:flex;flex-direction:column;gap:24px}.Form__KU6J7 .FormSubmitRow__bWlgF{display:flex;gap:12px}", "",{"version":3,"sources":["webpack://./src/components/DynamicForm/FormBase/FormBase.module.scss"],"names":[],"mappings":"AAAA,aACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,mCACE,YAAA,CACA,QAAA","sourcesContent":[".Form {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  .FormSubmitRow {\n    display: flex;\n    gap: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "Form__KU6J7",
	"FormSubmitRow": "FormSubmitRow__bWlgF"
};
export default ___CSS_LOADER_EXPORT___;
