import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface DeleteCampaignLinkParams {
  campaignLinkId: string
}

async function deleteCampaignLink(data: DeleteCampaignLinkParams): Promise<any> {
  const response = await PoshApi.deleteCampaignLink(data)
  return response.data
}

export function useDeleteCampaignLink(opts?: UseMutationOptions<any, any, DeleteCampaignLinkParams>) {
  return useMutation((params: DeleteCampaignLinkParams) => deleteCampaignLink(params), opts)
}
