import React, {PropsWithChildren} from 'react'

import './style.scss'

interface Props {
  className?: string
  variant?: PageVariant
}

export enum PageVariant {
  LIGHT = 'light',
  GRAY = 'gray',
  DARK = 'dark',
}

export default function Page(props: PropsWithChildren<Props>) {
  const getVariant = () => {
    switch (props.variant) {
      case PageVariant.LIGHT:
        return 'Page-light'
      case PageVariant.DARK:
        return 'Page-dark'
      case PageVariant.GRAY:
        return 'Page-gray'
      default:
        return 'Page-dark'
    }
  }
  return <div className={`Page ${getVariant()} ${props.className}`}>{props.children}</div>
}
