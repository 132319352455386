import React from 'react'

import {Card} from 'components/Card'
import Stripe from 'stripe'

const OrderCardFraudAnalysis = ({paymentIntentOutcome}: {paymentIntentOutcome?: Stripe.Charge.Outcome}) => {
  if (!paymentIntentOutcome) return <></>

  const width =
    paymentIntentOutcome?.risk_level == 'normal'
      ? '33%'
      : paymentIntentOutcome?.risk_level == 'elevated'
      ? '66%'
      : '100%'

  const background =
    paymentIntentOutcome?.risk_level == 'normal'
      ? 'lime'
      : paymentIntentOutcome?.risk_level == 'elevated'
      ? 'orange'
      : 'red'

  return (
    <Card
      title={'Fraud Risk Analysis'}
      animationIndex={5}
      info={
        'This is the likelihood that the payment for this order is fraudulent, on a scale of 0-100, evaluated by Stripe.'
      }>
      <div className='OrderPage-fraudBar-score'>{paymentIntentOutcome?.risk_score} / 100</div>

      <div className='OrderPage-fraudBar'>
        <div className='OrderPage-fraudBar-bar'>
          <div className='OrderPage-fraudBar-bar-inner' style={{width: width, background: background}} />
        </div>
        <div className='OrderPage-fraudBar-labels'>
          <span>NORMAL</span>
          <span>ELEVATED</span>
          <span>HIGHEST</span>
        </div>
      </div>
    </Card>
  )
}

export default OrderCardFraudAnalysis
