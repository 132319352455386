import React from 'react'

import {ICrossSection} from 'apis/Analytics/useGetSectionalData'
import {WithToolTip} from 'components/form/PoshInfo'

import {CrossSection} from '../../CrossSection/CrossSection'
import {Text} from '../../Shared/Text/Text'
import {parseNumberValue} from '../../Shared/utils'

export const AttendeeRetention = ({
  attendeeRetentionCrossSection,
}: {
  attendeeRetentionCrossSection: ICrossSection | undefined
}) => {
  if (!attendeeRetentionCrossSection) return null

  return (
    <CrossSection key={attendeeRetentionCrossSection.title}>
      <CrossSection.Row>
        <Text>{attendeeRetentionCrossSection.title}</Text>
        <WithToolTip
          info={
            'A breakdown of customers attending your event and whether its their first, second, third, or fourth+ purchase.'
          }>
          <CrossSection.Icon name='info' />
        </WithToolTip>
      </CrossSection.Row>
      <CrossSection.Row>
        <Text size='xl' bold>
          {parseNumberValue(attendeeRetentionCrossSection.mainValue)}
        </Text>
      </CrossSection.Row>
      <CrossSection.Row>
        {attendeeRetentionCrossSection.label && (
          <CrossSection.Subtitle muted>{attendeeRetentionCrossSection.label}</CrossSection.Subtitle>
        )}
      </CrossSection.Row>
      {attendeeRetentionCrossSection.chart && (
        <CrossSection.Row style={{marginTop: 5}}>
          <CrossSection.Chart
            chart={attendeeRetentionCrossSection.chart}
            id={`${attendeeRetentionCrossSection.title}-chart`}
          />
        </CrossSection.Row>
      )}
    </CrossSection>
  )
}
