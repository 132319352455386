import {useEffect} from 'react'

function useCheckIfClickedOutsideDiv(ref: React.MutableRefObject<HTMLDivElement | null>, hideDiv: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        hideDiv()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default useCheckIfClickedOutsideDiv
