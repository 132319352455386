import React from 'react'

import useAttendeesCrmContext from '../AttendeesCrmContext'

const ContactsSelected = () => {
  const {selectedAttendeeIds} = useAttendeesCrmContext()
  return <div className='TagsModal-contactsSelected'>{selectedAttendeeIds.length} contacts selected</div>
}

export default ContactsSelected
