import {createContext, useContext} from 'react'

import {AvailableFeatures} from '../../apis/Util/useGetAvailableFeatures'

export interface AvailableFeatureContext {
  availableFeatures: AvailableFeatures | undefined
  isLoading: boolean
}

export const AvailableFeaturesContext = createContext<AvailableFeatureContext | undefined>(undefined)

export function useAvailableFeatures() {
  const context = useContext(AvailableFeaturesContext)
  if (!context) throw 'AvailableFeatureContext must be provided'
  return context
}
