import React from 'react'

import {useFetchRefundRequests} from 'apis/AdminDashboard/useFetchRefundRequests'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import BackAndTitle from '../../../pages/GroupPage/PageComponents/BackAndTitle'
import {FinishedTable} from './tables/Finished'
import {PendingTable} from './tables/Pending'

import './styles.scss'

export const FullRefundDashboard = () => {
  const {data: refunds, isFetching: isLoading, isSuccess} = useFetchRefundRequests()
  return (
    <>
      <BackAndTitle title={'Posh Mass Refunds'} />
      {isLoading && (
        <div
          className='wrapper'
          style={{
            marginTop: '1rem',
          }}>
          <SpinLoader height={80} />
        </div>
      )}
      {refunds && isSuccess && (
        <div className='FullRefundDashboard'>
          <PendingTable refunds={refunds.pending} />
          <FinishedTable action='Approved' refunds={refunds.approved} />
          <FinishedTable action='Denied' refunds={refunds.denied} />
        </div>
      )}
    </>
  )
}
