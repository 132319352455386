import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {useResourcePageParams} from 'pages/PoshAppLayout'

const AttendeePanes = ({startNewCampaign}: {startNewCampaign: () => void}) => {
  const {groupId} = useGroupMatchParams()
  const {domain} = useResourcePageParams()
  const search = useLocation().search
  const {trackEvent} = useMixpanel()

  const onClickNewSMSCampaign = () => {
    trackEvent('New SMS Campaign- Group Marketing Page')
    startNewCampaign()
  }

  const onClickViewSMSCampaign = () => {
    trackEvent('View SMS Campaign- Group Marketing Page')
    navigate(`/${domain}/groups/${groupId}/marketing/view-sms` + search)
  }

  const navigate = useNavigate()

  return (
    <div className='Attendees-Panes'>
      <div className='Attendees-Panes-Pane' onClick={onClickNewSMSCampaign}>
        <span>+ New SMS Campaign</span>
        <PoshImage src='https://images.posh.vip/b2/quill.svg' />
      </div>
      <div className='Attendees-Panes-Pane sms' onClick={onClickViewSMSCampaign}>
        <span>View SMS Campaigns</span>
        <PoshImage src='https://images.posh.vip/b2/1041916.png' />
      </div>
      {/* <div className='Attendees-Panes-Pane Email Locked'>
        <span>
          <PoshImage src='https://images.posh.vip/b2/lock.svg' />
          View Email Campaigns
        </span>
        <PoshImage src='https://images.posh.vip/b2/440459.png' />
      </div> */}
    </div>
  )
}

export default AttendeePanes
