import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

const downloadUnsplashImage = async (downloadUrl: string) => {
  const response = await PoshApi.downloadUnsplashImage(downloadUrl)
  const images = response.data.images
  return images
}

export function useDownloadUnsplashImage() {
  return useMutation((params: {downloadUrl: string}) => downloadUnsplashImage(params.downloadUrl))
}
