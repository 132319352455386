import React from 'react'

import BlastsTable from 'components/data/BlastsTable'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import './styles.scss'

const SmsBlastsPage = () => {
  return (
    <>
      <div className='GroupPage'>
        <div className='GroupPage-content'>
          <div className='GroupPage-content-inner'>
            <BackAndTitle title={'SMS Campaigns'} />
            <BlastsTable limit={50} fullHistory={true} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SmsBlastsPage
