import PoshApi from '../PoshApi'
import {EventTrackingLinksResponse} from './types'

export async function fetchEventTrackingLinksHost(eventId: string): Promise<EventTrackingLinksResponse> {
  const response = await PoshApi.fetchEventTrackingLinksHost(eventId)
  return response.data
}

export async function trackingLinkClick(eventId: string, trackingLink: string): Promise<any> {
  const response = await PoshApi.trackingLinkClick(eventId ?? '', trackingLink ?? '')
  const data: any = response.data
  return data
}

export async function affiliateTrackingLinkClick(eventId: string, affiliateTrackingLink: string): Promise<any> {
  const response = await PoshApi.affiliateTrackingLinkClick(eventId, affiliateTrackingLink)
  const data: any = response.data
  return data
}
