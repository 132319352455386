import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {PromoCode} from './types'

export interface EventPromoCodesResponse extends PromoCode {
  uses: number
}

export async function fetchEventPromoCodesAndUses(eventId: string): Promise<EventPromoCodesResponse[]> {
  const response = await PoshApi.fetchEventPromoCodesAndUses(eventId)
  const data: EventPromoCodesResponse[] = response.data.promoCodesAndUses
  return data
}

export function useFetchEventPromoCodesAndUses(eventId: string) {
  return useQuery(['eventPromoCodes', eventId], () => fetchEventPromoCodesAndUses(eventId))
}
