import React, {useEffect} from 'react'

import {useAuthenticateMailChimpUser} from 'apis/MailChimp/useAuthenticateMailChimpUser'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import './styles.scss'

export const MailChimpOAuthHandler = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get('code')
  const state = urlParams.get('state')
  const {data: redirectLink, isFetching, isError} = useAuthenticateMailChimpUser({code: code!, state: state!})
  const title = redirectLink
    ? 'Didnt get redirected?'
    : isError
    ? 'Error Authenticating Your Session'
    : 'Authenticating Your Session...'

  useEffect(() => {
    if (redirectLink) window.location.href = redirectLink
  }, [redirectLink])

  if (!code || !state) return <p className='MailChimpOAuth'>No code or state provided</p>

  return (
    <div className='MailChimpOAuth'>
      <h3 className='center'>{title}</h3>
      {isFetching && <SpinLoader height={30} />}
      {isError && (
        <a href='/' className='white'>
          Go Home
        </a>
      )}
      {redirectLink && (
        <a href={redirectLink} className='white'>
          Click here to go back to your dashboard
        </a>
      )}
    </div>
  )
}
