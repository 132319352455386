import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
import Stripe from 'stripe'

export async function retrieveCustomerPaymentMethods(accountId: string): Promise<Stripe.PaymentMethod[]> {
  const response = await PoshApi.retrieveCustomerPaymentMethods(accountId)
  const data = response.data
  return data.paymentMethods
}

export function useFetchCustomerPaymentMethods(accountId?: string) {
  return useQuery<Stripe.PaymentMethod[], any>(
    ['customerPaymentMethods', accountId],
    () => retrieveCustomerPaymentMethods(accountId ?? ''),
    {
      enabled: !!accountId && !!localStorage.getItem('token'),
      retry: false,
    },
  )
}
