import React, {PropsWithChildren} from 'react'

import {SortDirection} from '@tanstack/react-table'

import {TableIcon} from '../TableIcons/TableIcon'

import styles from './TableHeader.module.scss'

interface SortableTableHeaderProps extends TableHeaderProps {
  sorted: false | SortDirection
  onToggleSort: () => void
}

interface TableHeaderProps {
  text: string
}

const DEFAULT_ICON_SIZE = 14

export const TableHeader = (props: PropsWithChildren<TableHeaderProps>) => {
  const {text, children} = props

  return (
    <div className={styles.TableHeader}>
      {text}
      {children}
    </div>
  )
}

const TableHeaderSortable = (props: SortableTableHeaderProps) => {
  const {text, sorted, onToggleSort} = props

  return (
    <TableHeader text={text}>
      <TableIcon
        name={getSortIcon(sorted)}
        width={DEFAULT_ICON_SIZE}
        height={DEFAULT_ICON_SIZE}
        onClick={onToggleSort}
        cursor={'pointer'}
      />
    </TableHeader>
  )
}

TableHeader.Sortable = TableHeaderSortable

const getSortIcon = (sorted: false | SortDirection) => {
  if (!sorted) return 'arrows-up-down'
  return sorted === 'asc' ? 'arrow-up' : 'arrow-down'
}
