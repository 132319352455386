import React from 'react'
import {Control, useController} from 'react-hook-form'

import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import CommentComponent from 'pages/EventPage/ActivitySection/CommentComponent'
import {fakeComments} from 'pages/EventPage/ActivitySection/fakeComments'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

interface EventVisualsActivitySectionProps {
  control: Control<EventVisualsForm>
}

const tooltip = (isHidden: boolean) =>
  isHidden ? 'Allow guests to comment on your event.' : 'Disable comments from guests.'

export const EventVisualsActivitySection = (props: EventVisualsActivitySectionProps) => {
  const {control} = props
  const {
    palette: {accentColor, lightmode, textContrasting},
  } = useEventVisualsContext()
  const {
    field: {onChange: setActivityEnabled, value: activityEnabled},
  } = useController({
    control,
    name: 'activityEnabled',
  })

  const {
    field: {value: eventId},
  } = useController({
    control,
    name: '_id',
  })

  const isHidden = !activityEnabled

  return (
    <EventVisualsSection.WithEye
      headerText='Activity'
      isHidden={!activityEnabled}
      eyeProps={{onClick: () => setActivityEnabled(!activityEnabled), fill: textContrasting}}
      tooltipInfo={tooltip(isHidden)}
      underlined>
      <p className='noMargin' style={{color: textContrasting}}>
        Guest comments will appear here
      </p>
      <div className='ActivitySection disabled'>
        {fakeComments.map(c => (
          <CommentComponent
            key={c._id}
            comment={c}
            accentColor={accentColor}
            isLightMode={lightmode}
            eventId={eventId}
            isReply={false}
            replyEnabled={false}
          />
        ))}
      </div>
    </EventVisualsSection.WithEye>
  )
}
