import React, {CSSProperties} from 'react'
import {Doughnut} from 'react-chartjs-2'

import {ArcElement, ChartData, ChartOptions, Legend, Tooltip} from 'chart.js'
import Chart from 'chart.js/auto'

import {CHART_JS_DEFAULT_COLORS, CHART_LEGEND_OPTIONS} from './charts.utils'

interface DoughnutChartProps {
  id: string
  labels: string[]
  data: number[]
  canvasStyle: CSSProperties
  bgColors?: string[]
  chartOptions?: ChartOptions<'doughnut'>
}

export const DoughnutChart = (props: DoughnutChartProps) => {
  const {id, chartOptions = {}, bgColors = CHART_JS_DEFAULT_COLORS, data, labels, canvasStyle} = props

  const graphData: ChartData<'doughnut', number[], string> = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: bgColors,
        hoverBorderColor: bgColors,
        borderWidth: 0,
        hoverBorderWidth: 4,
        hoverOffset: 24,
      },
    ],
  }

  const options: ChartOptions<'doughnut'> = {
    ...chartOptions,
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      ...chartOptions.plugins,
      legend: {
        ...chartOptions.plugins?.legend,
        ...CHART_LEGEND_OPTIONS,
      },
    },
    layout: {
      ...chartOptions.layout,
      padding: chartOptions.layout?.padding ?? 15,
    },
  }

  Chart.register(ArcElement, Tooltip, Legend)

  return <Doughnut id={id} data={graphData} options={options} style={canvasStyle} />
}
