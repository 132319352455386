import {useFetchGroupPhoneNumber} from 'apis/Groups/useFetchGroupPhoneNumber'
import {useFetchPendingTextBlast} from 'apis/PendingTextBlasts/useFetchPendingTextBlast'

import {ITRPCQueries} from './types'

export const useTRPCQueries = ({blastId, groupId, queryClient}: ITRPCQueries) => {
  const {data: pendingTextBlast, isLoading} = useFetchPendingTextBlast(
    {
      pendingTextBlastId: blastId ?? '',
      groupId: groupId ?? '',
    },
    // We only retry here if the user had the response in their cache before
    // We don't retry in other cases because then the redirect from the error takes TOO long
    {retry: !!queryClient.pendingTextBlasts.fetchPendingTextBlast.getData()},
  )

  const {data: groupPhoneNumberFetch} = useFetchGroupPhoneNumber({groupId: groupId ?? ''})

  return {
    pendingTextBlast,
    isLoading,
    groupPhoneNumberFetch,
  }
}
