import React, {useState} from 'react'

import {TBooking} from 'apis/Services/useGetServiceBookings'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'

import {Booking} from './Booking'

import './ActiveBookings.styles.scss'
interface ActiveBookingProps {
  bookings: TBooking[]
}

export const ActiveBookings = (props: ActiveBookingProps) => {
  const {bookings} = props
  const [selectedBookingCancel, setSelectedBookingCancel] = useState<TBooking | undefined>()
  return (
    <>
      <h4 className='ActiveBooking-header'>Active Bookings</h4>
      <div className='ActiveBookings'>
        {bookings.map(booking => (
          <Booking key={booking._id} booking={booking} setSelectedBookingCancel={setSelectedBookingCancel} />
        ))}
      </div>
      {selectedBookingCancel && (
        <PoshStyledModal isOpen={!!selectedBookingCancel} onClose={() => setSelectedBookingCancel(undefined)}>
          <h3>Want to cancel your booking?</h3>
          <p>
            Please reach out to the service provider{' '}
            {selectedBookingCancel.provider && (
              <span>
                (<b>{selectedBookingCancel.provider.name}</b>)
              </span>
            )}
            .
          </p>
        </PoshStyledModal>
      )}
    </>
  )
}
