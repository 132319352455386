import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import useSessionContext from '../../domains/Auth/SessionContext'

export interface GroupDataResponse {
  totalRevenue: number
  totalAttendees: number
  eventsLength: number
}

export async function getGroupData(groupId: string): Promise<GroupDataResponse> {
  const response = await PoshApi.fetchGroupData(groupId)
  return response.data
}

export function useFetchGroupData(groupId?: string) {
  const {userId} = useSessionContext()
  if (!userId) throw 'User is not authenticated'
  return useQuery(['groupData', groupId], () => getGroupData(groupId ?? ''), {
    enabled: !!groupId,
  })
}
