import {useQuery, UseQueryOptions} from '@tanstack/react-query'

import PoshApi from '../PoshApi'

export async function checkIsAffiliate(userId: string): Promise<boolean> {
  const response = await PoshApi.checkIsAffiliate(userId)
  return response.data
}

export default function useCheckIsAffiliate(userId: string, options?: UseQueryOptions<boolean>) {
  return useQuery<boolean>(['isAffiliate', userId], () => checkIsAffiliate(userId), {
    enabled: !!userId,
    ...options,
  })
}
