import {useMutation} from '@tanstack/react-query'
import {LongLat} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

import {GalleryImage} from '.'

export interface CreateVenue {
  name: string
  address: string
  coordinates: LongLat
  website?: string
  maxCapacity?: number
  ageLimit?: number
  baseBuyout?: number
  instagram?: string
  galleryImages: GalleryImage[]
  coverImage?: string
}

async function createVenue(venueData: CreateVenue) {
  const response = await PoshApi.createVenue(venueData)
  return response.data
}

export default function useCreateVenue() {
  return useMutation((params: CreateVenue) => createVenue(params))
}
