import React from 'react'
import ResponsiveGallery from 'react-responsive-gallery'
import {ResponsiveGalleryProps} from 'react-responsive-gallery/dist/components/Gallery/Gallery.types'

import {useGetFaceMatches} from 'apis/Photos/useGetFaceMatches'

interface PhotosOfYouProps extends Omit<ResponsiveGalleryProps, 'images'> {
  eventId: string | undefined
}

const PhotosOfYouStyle: React.CSSProperties = {marginTop: 15, marginBottom: 15}

export const PhotosOfYou = (props: PhotosOfYouProps) => {
  const {eventId, ...rest} = props
  const {data: photosOfYou, isSuccess} = useGetFaceMatches({eventId: eventId}, {enabled: !!eventId})
  if (!photosOfYou || photosOfYou.length === 0 || !isSuccess) {
    return null
  }

  return (
    <>
      <div style={PhotosOfYouStyle}>
        <h3>Photos of You</h3>
        <ResponsiveGallery
          {...rest}
          images={
            photosOfYou.map(eventPhoto => {
              return {src: eventPhoto.uri}
            }) ?? []
          }
        />
      </div>
      <hr />
    </>
  )
}
