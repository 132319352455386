import React from 'react'

import {Card} from 'components/Card'

const OrderCardCanceled = () => {
  return (
    <Card title={'Canceled Order'} animationIndex={1} className='OrderPage-disputeCard'>
      <p>This order was canceled and is no longer valid for entry.</p>
    </Card>
  )
}

export default OrderCardCanceled
