import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {EventTicketAndTicketsAvailable} from './types'

export interface EventTicketAndTicketSoldResponse {
  eventTickets: EventTicketAndTicketsAvailable[]
  totalTicketsSold?: number
  hasPasswordProtectedTickets: boolean
  rsvpPublicTicketAvailabilityText?: string
}

export async function fetchEventTicketsUrl(
  eventUrl: string,
  password?: string,
): Promise<EventTicketAndTicketSoldResponse> {
  const response = await PoshApi.fetchEventTicketsUrl(eventUrl, password)
  const data: EventTicketAndTicketSoldResponse = response.data
  return data
}

export function useFetchEventTicketsUrl(eventUrl: string, password?: string) {
  return useQuery(['eventTickets', eventUrl], () => fetchEventTicketsUrl(eventUrl, password), {
    refetchOnMount: false,
  })
}
