import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

async function fetchAdminGroupDashboardData(): Promise<any[]> {
  const response = await PoshApi.fetchAdminGroupDashboardData()
  return response.data
}

export default function useFetchAdminGroupDashboardData() {
  return useQuery<any[]>(['admin-group-dashboard'], () => fetchAdminGroupDashboardData(), {
    refetchOnMount: false,
  })
}
