import React, {PropsWithChildren} from 'react'

import PoshInfo from 'components/form/PoshInfo'

import './styles.scss'

export interface CardProps {
  className?: string
  title?: string
  animationIndex?: number
  info?: string
  actions?: React.ReactNode
}

export const Card = (props: PropsWithChildren<CardProps>) => {
  return (
    <div
      className={`Card ${props.className}`}
      style={{animationDelay: props.animationIndex ? `${props.animationIndex * 0.2}s` : 'none'}}>
      <div className='Card-titleContainer'>
        {props.title && (
          <h5>
            {props.title}
            {props.info && <PoshInfo info={props.info} />}
          </h5>
        )}
        {props.actions && <div className='Card-titleContainer-actions'>{props.actions}</div>}
      </div>
      {props.children}
    </div>
  )
}

interface CardSHProps {
  title?: string
}

export const CardSH = (props: PropsWithChildren<CardSHProps>) => {
  return (
    <div className={`Card-SH`}>
      <hr />
      {props.title && <h6>{props.title}</h6>}
      {props.children}
    </div>
  )
}
