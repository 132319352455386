import React from 'react'

import {CurrencyCode} from '@posh/types'
import useFetchCheckoutSummary from 'apis/Checkout/useFetchCheckoutSummary'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'

import PaymentPlanInfo from './PaymentPlanInfo'
import PaymentPlanOption from './PaymentPlanOption'

const PaymentPlans = ({currency}: {currency?: CurrencyCode}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const cartId = urlParams.get('cartId') as string
  const {choosePaymentPlan, selectedPaymentPlan} = useLiveCartContext()
  const {data: summary} = useFetchCheckoutSummary({cartId})

  const installments = summary?.paymentPlansInfo?.paymentPlans
  const shouldShowPaymentPlans = summary?.cartInfo.total && summary?.cartInfo.total > 0

  if (!shouldShowPaymentPlans) return null
  return (
    <div className='CheckoutPage-Section fade'>
      <PaymentPlanInfo />
      <div
        className={`PaymentPlans-defaultPlanOption ${selectedPaymentPlan == null ? 'selected' : ''}`}
        onClick={() => {
          choosePaymentPlan(undefined)
        }}>
        Option #1
        <br />
        <span style={{color: 'white'}}>Pay in Full</span>
      </div>
      <div className='PaymentPlans-Options'>
        {installments?.map((installmentPlan, index) => (
          <>
            <PaymentPlanOption
              cartTotal={summary?.cartInfo.total}
              installments={installmentPlan}
              onSelectPaymentPlanOption={() => {
                // for type safety here
                if (index !== 0 && index !== 1) return
                choosePaymentPlan(index)
              }}
              currency={currency}
              index={index}
              isSelected={selectedPaymentPlan === index}
            />
            {index > 1 && <div className='PaymentPlanOptions-divider' />}
          </>
        ))}
      </div>
    </div>
  )
}

export default PaymentPlans
