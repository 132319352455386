import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import Community from '../Community'

const CommunityHandler = () => {
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)

  return (
    <>
      <PoshHelmet title={`Overview - ${group?.name}`} />
      <Routes>
        <Route path='' element={<Community groupNav />} />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default CommunityHandler
