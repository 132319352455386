import React from 'react'

import {CreatePromoCodeParams} from 'apis/Events/useCreatePromoCode'
import {NumberInput} from 'components/DynamicForm/Inputs/NumberInput/NumberInput'
import {DynamicFormConfig} from 'components/DynamicForm/internals/types'
import {z, ZodSchema} from 'zod'

import {PromoCode} from '../PromoCodesSection'

interface PromoCodeFormBase {
  code: CreatePromoCodeParams['code']
  type: CreatePromoCodeParams['type']
  value: CreatePromoCodeParams['value']
  limit?: CreatePromoCodeParams['limit']
  limitedTo?: CreatePromoCodeParams['limitedToTicket']
}

export type CreatePromoCodeForm = PromoCodeFormBase
export type UpdatePromoCodeForm = PromoCodeFormBase & {
  id: string
  enabled: boolean
}

export type TicketMapping = {
  id: string
  name: string
}

export const getCreatePromoCodeFormConfig = (
  ticketMapping: TicketMapping[],
): DynamicFormConfig<CreatePromoCodeForm>[] => {
  return [
    {
      key: 'code',
      type: 'text',
      label: 'Promo Code',
      placeholder: 'e.g. summer2024',
    },
    {
      key: 'type',
      type: 'select',
      label: 'Discount Type',
      options: [
        {label: 'Percentage', value: 'percentage'},
        {label: 'Flat Rate', value: 'flat'},
      ],
    },
    {
      key: 'value',
      type: 'custom',
      label: 'Discount Value',
      render: form => {
        const {watch} = form
        const type = watch('type')
        if (type === 'flat') {
          return <NumberInput.Controlled name='value' control={form.control} icon='dollar-sign' />
        } else {
          return <NumberInput.Controlled name='value' control={form.control} icon='percent' />
        }
      },
    },
    {
      key: 'limit',
      type: 'number',
      label: 'Usage Limit',
      decimalPlaces: 0,
      toggleable: {
        enableText: 'Limit this promo code to a specific number of uses',
        disableText: 'Allow this promo code to be used on an unlimited amount of orders',
      },
    },
    {
      key: 'limitedTo',
      type: 'select',
      label: 'Ticket Type Restriction',
      subLabel: 'Restrict which ticket types this promo code can be used on.',
      toggleable: {
        enableText: 'Limit this promo code to only apply to certain ticket types',
        disableText: 'Allow this promo code to be used on any order',
      },
      options: [
        ...ticketMapping.map(ticket => {
          return {label: ticket.name, value: ticket.id}
        }),
        {label: 'Select a ticket type...', value: undefined},
      ],
    },
  ]
}

export const getUpdatePromoCodeFormConfig = (
  ticketMapping: TicketMapping[],
): DynamicFormConfig<UpdatePromoCodeForm>[] => {
  const createPromoCodeFormConfig = getCreatePromoCodeFormConfig(ticketMapping)

  return [
    ...createPromoCodeFormConfig,
    {
      key: 'enabled',
      type: 'switch',
      label: 'Promo Code Enabled',
    },
  ] as DynamicFormConfig<UpdatePromoCodeForm>[]
}

export const getCreatePromoCodeFormSchema = (promoCodes: PromoCode[], currentPromoCode?: PromoCode) => {
  return z.object({
    code: z
      .string()
      .trim()
      .toLowerCase()
      .transform(value => value.replace(/\s+/g, '-'))
      .refine(
        value => promoCodes.find(promo => promo.code === value && currentPromoCode?.code !== value) === undefined,
        {
          message: 'A promo code with this name already exists.',
        },
      ),
    type: z.enum(['percentage', 'flat']),
    value: z.number().positive(),
    limit: z.number().positive().optional(),
    limitedTo: z.string().optional(),
  })
}

export const getUpdatePromoCodeFormSchema = (
  promoCodes: PromoCode[],
  currentPromoCode?: PromoCode,
): ZodSchema<UpdatePromoCodeForm> => {
  return z
    .object({
      id: z.string(),
      enabled: z.boolean(),
    })
    .merge(getCreatePromoCodeFormSchema(promoCodes, currentPromoCode))
}
