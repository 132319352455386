import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface FreeOrderParams {
  accountId: string
  ticketType: string
  quantity: number
  eventId: string
}

async function sendFreeTickets(params: FreeOrderParams): Promise<string> {
  const response = await PoshApi.sendFreeTickets(params)
  return response.data
}

export default function useSendFreeTickets() {
  return useMutation((params: FreeOrderParams) => sendFreeTickets(params), {
    retry: false,
  })
}
