import React, {useState} from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import classNames from 'classnames'
import {ControlledFormProps} from 'components/DynamicForm/Inputs/shared/types'
import {LocationInput, LocationInputProps, UseLocationInputRefParams} from 'components/form/PoshLocationInput'
import {getEventTitleFont} from 'components/Text/EventTitleFontStyleProvider'

import {EventVisualsTextInputWrapper, EventVisualsTextInputWrapperProps} from './EventVisualsTextInputWrapper'

import styles from './styles.module.scss'

type EventVisualsLocationInputProps = LocationInputProps &
  EventVisualsTextInputWrapperProps & {
    icon?: React.ReactNode
    rightIcon?: React.ReactNode
    googlePlacesInputOptions: Omit<UseLocationInputRefParams, 'onPlaceSelected'>
    onPlaceSelected: UseLocationInputRefParams['onPlaceSelected'] // make required and top-level
  }

/**
 * Note: Do not use this directly. Use it as EventVisualsTextInput.Location instead
 */
export function EventVisualsLocationInput(props: EventVisualsLocationInputProps) {
  const {googlePlacesInputOptions, onPlaceSelected, icon, rightIcon, required, value, ...rest} = props
  const {lightMode, accentColor, fontFamily} = rest

  const DEFAULT_FONT_SIZE = 16

  const lightModeClassName = lightMode ? styles.light : styles.dark
  const inputClassName = classNames(styles.input, lightModeClassName)
  const fontSize = props.size ?? DEFAULT_FONT_SIZE

  const isOutlined = required === true && !value

  return (
    <EventVisualsTextInputWrapper.Row {...rest} isOutlined={isOutlined}>
      {icon}
      <LocationInput
        {...props}
        googlePlacesInputOptions={{...googlePlacesInputOptions, onPlaceSelected}}
        className={inputClassName}
        style={{fontSize, color: accentColor, fontFamily: getEventTitleFont(fontFamily)}}
        placeholder={props.placeholder + (props.required ? '*' : '')}
      />
      {rightIcon}
    </EventVisualsTextInputWrapper.Row>
  )
}

type ControlledEventVisualsLocationInputProps<T extends FieldValues, K extends Path<T>> = Omit<
  EventVisualsLocationInputProps,
  'value' | 'onPlaceSelected'
> &
  ControlledFormProps<T, K> & {
    formatPlace: (data: google.maps.places.PlaceResult) => string
    onPlaceSelected?: EventVisualsLocationInputProps['onPlaceSelected'] // make optional
  }

/**
 * A controlled version of the EventVisualsLocationInput component. Takes the same props as EventVisualsLocationInput, but adds a `control` prop for use with react-hook-form.
 * @param formatPlace: A function that takes a PlaceResult and returns a string. This is used to format the place data before it is passed to the form.
 *
 * Do not use this directly. Use it as EventVisualsTextInput.Location.Controlled instead
 */
function ControlledLocationInput<T extends FieldValues, K extends Path<T>>({
  name,
  control,
  googlePlacesInputOptions,
  formatPlace,
  onPlaceSelected,
  rules,
  ...props
}: ControlledEventVisualsLocationInputProps<T, K>) {
  const isRequired = typeof rules?.required === 'object' ? rules.required.value : !!rules?.required
  // Need this value so we dont mark the field as set before the user has selected an option
  const [localValue, setLocalValue] = useState<string | undefined>(undefined)
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState}) => {
        return (
          <EventVisualsLocationInput
            {...props}
            {...field}
            {...fieldState}
            value={localValue ?? field.value}
            onChange={e => {
              setLocalValue(e.target.value)
              props.onChange?.(e)
            }}
            required={isRequired}
            googlePlacesInputOptions={googlePlacesInputOptions}
            onPlaceSelected={(place, ref, autocompleteRef) => {
              onPlaceSelected && onPlaceSelected(place, ref, autocompleteRef)
              field.onChange(formatPlace(place))
              setLocalValue(formatPlace(place))
            }}
          />
        )
      }}
    />
  )
}

EventVisualsLocationInput.Controlled = ControlledLocationInput
