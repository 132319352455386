import React from 'react'

import {PaymentStatus} from 'apis/Checkout'
import {OrderModel} from 'apis/Orders'
import {TaggedGroupOrder} from 'apis/Orders/useAggregateOrders'
import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'

export interface TaggedGroupOrderModel extends TaggedGroupOrder {
  type: 'tagged'
}
export interface UntaggedOrderModel extends OrderModel {
  type: 'untagged'
}

const generateTags = (order: OrderModel) => {
  const tags: string[] = []
  if (order.scanned) tags.push('scanned')
  if (order.refunded) tags.push('refunded')
  if (order.disputeId) tags.push('disputed')
  if (order.tables && order.tables.length > 0) tags.push('table')
  return tags
}

const OrderTags = ({tags}: {tags: string[]}) => {
  const getTagDsiplayName = (tag: string) => {
    switch (tag) {
      case 'tables':
        return 'Table Order'
      default:
        return tag.charAt(0).toUpperCase() + tag.slice(1)
    }
  }
  return (
    <>
      {tags.map((tag, index) => (
        <div key={index} className={classNames({error: tag === 'disputed'})}>
          {getTagDsiplayName(tag)}
        </div>
      ))}
    </>
  )
}

const OrderDescriptors = ({order}: {order: TaggedGroupOrderModel | UntaggedOrderModel}) => {
  const orderModel = order.type === 'tagged' ? order.order : order
  const tags = order.type === 'tagged' ? order.tags : generateTags(orderModel)

  const paymentPlanIssue =
    orderModel.paymentPlan?.map(p => p.status).includes(PaymentStatus.REQUIRES_ACTION) ||
    orderModel.paymentPlan?.map(p => p.status).includes(PaymentStatus.FAILED)

  return (
    <div className='OrderDescriptors'>
      <OrderTags tags={tags} />
      {paymentPlanIssue && <div className='warning'>Payment Plan Issue</div>}
      {orderModel.promoCode && (
        <div>
          <PoshImage src='https://images.posh.vip/b2/promocode.svg' />
          {orderModel.promoCode}
        </div>
      )}
      {orderModel.trackingLink && (
        <div>
          <PoshImage src='https://images.posh.vip/b2/link+(4).svg' />
          {orderModel.trackingLink}
        </div>
      )}
    </div>
  )
}

export default OrderDescriptors
