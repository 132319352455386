import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

async function fetchAdminTextBlasts(): Promise<any[]> {
  const response = await PoshApi.fetchAdminTextBlasts()
  return response.data
}

export default function useFetchAdminTextBlasts() {
  return useQuery<any[]>(['admin-text-blasts'], () => fetchAdminTextBlasts(), {
    refetchOnMount: false,
  })
}
