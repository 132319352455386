import React, {HTMLAttributes, PropsWithChildren} from 'react'

import classNames from 'classnames'
import UserListPreviewSkeleton from 'components/UserListPreview/UserListPreview.Skeleton'
import UserListPreviewUser from 'components/UserListPreview/UserListPreview.User'

import './UserListPreview.styles.scss'

const UserListPreview = ({children, className, ...rest}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div className={classNames('UserListPreview', className)} {...rest}>
      {children}
    </div>
  )
}

UserListPreview.User = UserListPreviewUser
UserListPreview.Skeleton = UserListPreviewSkeleton

export default UserListPreview
