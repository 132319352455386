import React, {useState} from 'react'

import useCreateMailChimpList from 'apis/MailChimp/useCreateMailChimpList'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import moment from 'moment'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {MailChimpLoader} from './mailChimpLoader'
import {useExportAttendeeBatchesToMailchimp} from './useExportAttendeeBatchesToMailchimp'

export const ListCreator = () => {
  const [listName, setListName] = useState(`Posh ${moment().format('MMMM Do YYYY')} list`)
  const {mutateAsync: createMailChimpList, isLoading: isCreatingNewList} = useCreateMailChimpList()
  const {isAddingToMailChimpList, completedData, handleExportToList} = useExportAttendeeBatchesToMailchimp()
  const {groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const isLoading = isCreatingNewList || isAddingToMailChimpList
  const handleMailChimpExport = async () => {
    try {
      if (!groupId) throw new Error('There was a problem with your request. Please refresh try again.')
      const {listId} = await createMailChimpList({listName, groupId})
      await handleExportToList(listId)
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  return (
    <>
      {isLoading || completedData ? (
        <MailChimpLoader
          isCreatingNewList={isCreatingNewList}
          isAddingToMailChimpList={isAddingToMailChimpList}
          completedData={completedData}
        />
      ) : (
        <>
          <h3 className='center noMargin'>Send Attendees to Mailchimp</h3>
          <p>What would you like to name the list?</p>
          <Input
            className='fullWidth'
            placeholder='List Name'
            value={listName}
            onChange={e => setListName(e.target.value)}
          />
          <div className='buttonWrapper center'>
            <Button className='mailChimpGradient' onClick={handleMailChimpExport}>
              Create List and Send Contacts
              <PoshImage src='https://images.posh.vip/psh-assets/icons/mailchimp-logo.svg' />
            </Button>
          </div>
        </>
      )}
    </>
  )
}
