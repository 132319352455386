import React from 'react'
import {PropsWithChildren} from 'react'

import {useDimensions} from 'hooks/useDimensions'

import {Panel, PanelProps} from './Panel'

export type SlideOutModalProps = PanelProps

/**
 * This is an extension of the Panel component, it doesnt take the full screen height and has a different background color
 */
export const SlideOutModal = (props: PropsWithChildren<SlideOutModalProps>) => {
  const background = 'rgb(0 0 0 / 50%)'
  const {isMobile} = useDimensions()

  return (
    <Panel
      {...props}
      slideUp={isMobile}
      backgroundStyle={{background}}
      panelStyle={{height: 'auto', top: 55}}
      headerStyle={{borderBottom: 'none'}}>
      {props.children}
    </Panel>
  )
}
