import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetEventPlaylistForEditorOptions = ReactQueryOptions['events']['playlists']['manage']['getEventPlaylistForEditor']
type GetEventPlaylistForEditorInput = RouterInputs['events']['playlists']['manage']['getEventPlaylistForEditor']
export type GetEventPlaylistForEditorOutput = RouterOutput['events']['playlists']['manage']['getEventPlaylistForEditor']

export function useGetEventPlaylistForEditor(
  input: GetEventPlaylistForEditorInput,
  opts?: GetEventPlaylistForEditorOptions,
) {
  return trpc.events.playlists.manage.getEventPlaylistForEditor.useQuery(input, opts)
}
