export const CHART_LEGEND_OPTIONS = {
  display: false,
  position: 'right',
  align: 'start',
  labels: {
    color: 'white',
    font: {
      family: 'Inter',
      size: 12,
    },
    boxWidth: 21,
    boxHeight: 8,
  },
} as const

export const CHART_JS_DEFAULT_COLORS = [
  'rgb(54, 162, 235)', // blue
  'rgb(255, 99, 132)', // red
  'rgb(255, 159, 64)', // orange
  'rgb(255, 205, 86)', // yellow
  'rgb(75, 192, 192)', // green
  'rgb(153, 102, 255)', // purple
  'rgb(201, 203, 207)', // grey
]
