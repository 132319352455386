import React, {InputHTMLAttributes} from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import classNames from 'classnames'
import {ControlledFormProps} from 'components/DynamicForm/Inputs/shared/types'
import {getEventTitleFont} from 'components/Text/EventTitleFontStyleProvider'

import {EventVisualsTextInputWrapper, EventVisualsTextInputWrapperProps} from './EventVisualsTextInputWrapper'

import styles from './styles.module.scss'

type EventVisualsTextInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'style' | 'className' | 'name'> &
  EventVisualsTextInputWrapperProps & {
    icon?: React.ReactNode
  }
const DEFAULT_FONT_SIZE = 16

export function EventVisualsBaseHtmlInput(props: EventVisualsTextInputProps) {
  const {lightMode, accentColor, fontFamily, ...rest} = props
  const lightModeClassName = lightMode ? styles.light : styles.dark
  const inputClassName = classNames(styles.input, lightModeClassName)
  const fontSize = props.size ?? DEFAULT_FONT_SIZE

  return (
    <input
      {...rest}
      className={inputClassName}
      style={{fontSize, color: accentColor, fontFamily: getEventTitleFont(fontFamily)}}
      placeholder={props.placeholder + (props.required ? '*' : '')}
    />
  )
}

export function PlainTextInput({icon, ...props}: EventVisualsTextInputProps) {
  const {required, value} = props
  const isOutlined = required === true && !value

  return (
    <EventVisualsTextInputWrapper.Row {...props} isOutlined={isOutlined}>
      {icon}
      <EventVisualsBaseHtmlInput {...props} />
    </EventVisualsTextInputWrapper.Row>
  )
}

type ControlledEventVisualsTextInputProps<
  T extends FieldValues,
  K extends Path<T>,
> = EventVisualsTextInputWrapperProps & ControlledFormProps<T, K> & EventVisualsTextInputProps

function ControlledPlainTextInput<T extends FieldValues, K extends Path<T>>({
  name,
  control,
  rules,
  ...props
}: ControlledEventVisualsTextInputProps<T, K>) {
  const isRequired = typeof rules?.required === 'object' ? rules.required.value : !!rules?.required

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState}) => <PlainTextInput {...props} {...field} {...fieldState} required={isRequired} />}
    />
  )
}

PlainTextInput.Controlled = ControlledPlainTextInput
