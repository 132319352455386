import React, {forwardRef} from 'react'

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(({className, ...props}, ref) => (
  <textarea ref={ref} className={'poshTextArea ' + (className || '')} {...props} />
))
TextArea.displayName = 'TextArea'

export default TextArea
