import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

export type UpdateGroupSettingsOptions = ReactQueryOptions['groups']['updateGroupSettings']
export type UpdateGroupSettings = RouterInputs['groups']['updateGroupSettings']['updates']
export type UpdateGroupSettingsOutput = RouterOutput['groups']['updateGroupSettings']

export function useUpdateGroupSettings(opts?: UpdateGroupSettingsOptions) {
  const qc = trpc.useContext()
  return trpc.groups.updateGroupSettings.useMutation({
    ...opts,
    onSettled: (_data, _error, input) => {
      qc.groups.getGroup.invalidate({groupId: input.groupId})
    },
  })
}
