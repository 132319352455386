import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface ConfirmPendingEventParams {
  pendingEventId: string
  groupId: string
}

const confirmPendingEvent = async (params: ConfirmPendingEventParams) => {
  const response = await PoshApi.confirmPendingEvent(params)
  const eventUrl = response.data.eventUrl
  return eventUrl
}

export function useDEPRECATEDConfirmPendingEvent() {
  return useMutation<string, any, ConfirmPendingEventParams>(params => confirmPendingEvent(params))
}

import {ReactQueryOptions, RouterOutput, trpc} from 'lib/trpc'

type ConfirmPendingEventOptions = ReactQueryOptions['pendingEvents']['confirmPendingEvent']
export type ConfirmPendingEventOutput = RouterOutput['pendingEvents']['confirmPendingEvent']

export const useConfirmPendingEvent = (opts?: ConfirmPendingEventOptions) => {
  return trpc.pendingEvents.confirmPendingEvent.useMutation(opts)
}
