import React from 'react'

import General from './General'
import Integrations from './Integrations'
import Webhooks from './Webhooks'

export interface SettingsPage {
  title: string
  icon: string
  route: string
  content: JSX.Element | JSX.Element[]
}

export interface SettingsCategory {
  category: string
  pages: SettingsPage[]
}

const SettingsMap: SettingsCategory[] = [
  {
    category: 'Organization',
    pages: [
      {
        title: 'General',
        route: 'general',
        icon: 'https://images.posh.vip/b2/house.svg',
        content: <General />,
      },
      // {
      //   title: 'Subscriptions',
      //   route: 'subscriptions',
      //   icon: 'https://images.posh.vip/b2/settings-page-icons/subscription.svg',
      //   content: <Subscriptions />,
      // },
      {
        title: 'Integrations',
        route: 'integrations',
        icon: 'https://images.posh.vip/b2/settings-page-icons/puzzle.svg',
        content: <Integrations />,
      },
    ],
  },
  {
    category: 'API',
    pages: [
      // {
      //   title: 'API Keys',
      //   route: 'api-keys',
      //   icon: 'https://images.posh.vip/b2/door-key.svg',
      //   content: <ApiKeys />,
      // },
      {
        title: 'Webhooks',
        route: 'webhooks',
        icon: 'https://images.posh.vip/b2/wifi-signal.svg',
        content: <Webhooks />,
      },
    ],
  },
]

export default SettingsMap

// Add: Subscriptions, Integrations, Event Defaults
