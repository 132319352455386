import React, {ButtonHTMLAttributes} from 'react'

import classNames from 'classnames'
import {FilledHeart, Heart} from 'components/assets/Icons'

import './SocialButton.styles.scss'

interface SocialButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  filled?: boolean
  size?: 'small' | 'large'
}

const SocialButton = ({filled = false, size = 'small', onClick, className, ...props}: SocialButtonProps) => {
  return (
    <button
      {...props}
      onClick={onClick}
      className={classNames('SocialButton', {small: size === 'small', large: size === 'large', filled}, className)}>
      {filled ? <FilledHeart /> : <Heart />}
    </button>
  )
}

export default SocialButton
