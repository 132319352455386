import React, {ComponentProps, PropsWithChildren} from 'react'
import SortableList, {SortableItem} from 'react-easy-sort'

type DraggableListProps = ComponentProps<typeof SortableList>

export function DraggableList({children, ...props}: PropsWithChildren<DraggableListProps>) {
  return (
    <SortableList {...props}>
      {React.Children.map(children, (child, index) => (
        <SortableItem key={index}>
          <div>{child}</div>
        </SortableItem>
      ))}
    </SortableList>
  )
}
