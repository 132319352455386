import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {KickbackOrders} from './Affiliation'

export interface AffiliationDataResponse {
  eventLink: string
  clicks: number
  flyer: string
  kickbackedRevenue: number
  totalTickets: number
  currentKickbackOfferings?: string[]
  kickbackOrders?: KickbackOrders[]
}

const fetchAffiliationData = async (affiliateId: string) => {
  const response = await PoshApi.fetchAffiliationData(affiliateId)
  return response.data.affiliationResponse
}

export default function useFetchAffiliationData(affiliationId: string) {
  return useQuery<AffiliationDataResponse>(['affiliationData', affiliationId], () =>
    fetchAffiliationData(affiliationId),
  )
}
