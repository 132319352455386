import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {UpdateTicketAttributes} from './types'

export interface CreateTicketTypeParams {
  eventId: string
  ticket: UpdateTicketAttributes
}

type CreateTicketTypeResponse = {
  message: string
  ticketId: string
}

export async function addTicketTypeToEvent(params: CreateTicketTypeParams): Promise<CreateTicketTypeResponse> {
  const response = await PoshApi.addTicketTypeToEvent(params)
  return response.data
}

export function useCreateTicketType(
  options?: UseMutationOptions<CreateTicketTypeResponse, unknown, CreateTicketTypeParams>,
) {
  return useMutation((params: CreateTicketTypeParams) => addTicketTypeToEvent(params), options)
}
