import {useQuery} from '@tanstack/react-query'
import {EventModel} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

import {Tag} from '.'

export interface GroupTagsAndEventsResponse {
  tags: Tag[]
  events: EventModel[]
}

export async function fetchGroupEventsAndTags(groupId: string): Promise<GroupTagsAndEventsResponse> {
  const response = await PoshApi.fetchGroupEventsAndTags(groupId)
  const data: GroupTagsAndEventsResponse = response.data
  return data
}
export default function useFetchGroupTagsAndEvents(groupId?: string) {
  return useQuery(['groupEventsAndTags', groupId], () => fetchGroupEventsAndTags(groupId ?? ''), {
    enabled: !!groupId,
  })
}
