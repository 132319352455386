import {RouterInputs, RouterOutput, trpc} from 'lib/trpc'
export type SearchPendingTextBlastRecipientsCursor =
  RouterOutput['pendingTextBlasts']['searchPendingTextBlastRecipients']['cursor']
export type SearchPendingTextBlastRecipient =
  RouterOutput['pendingTextBlasts']['searchPendingTextBlastRecipients']['accounts'][number]

export const useSearchPendingTextBlastRecipients = (
  input: RouterInputs['pendingTextBlasts']['searchPendingTextBlastRecipients'],
) => {
  return trpc.pendingTextBlasts.searchPendingTextBlastRecipients.useInfiniteQuery(input, {
    getNextPageParam: lastPage => lastPage.cursor,
    staleTime: Infinity,
  })
}
