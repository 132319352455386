import React from 'react'

import {
  ArrowDown,
  ArrowsUpDown,
  ArrowUp,
  AttendeesSolid,
  CopySquares,
  DollarSign,
  ExternalLink,
  Filter,
  InfoSolid,
  Map,
  OrdersOutlined,
  Pencil,
  Percent,
  PlusSolid,
  Refresh,
  Search,
  Settings,
  Tickets,
  TrashIcon,
  TriangleDown,
  Warning,
} from 'components/assets/Icons'

const TableIcons = {
  settings: Settings,
  pencil: Pencil,
  plus: PlusSolid,
  'arrows-up-down': ArrowsUpDown,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  refresh: Refresh,
  search: Search,
  'triangle-down': TriangleDown,
  'dollar-sign': DollarSign,
  percent: Percent,
  trash: TrashIcon,
  'copy-squares': CopySquares,
  info: InfoSolid,
  'external-link': ExternalLink,
  filter: Filter,
  warning: Warning,
  'orders-outlined': OrdersOutlined,
  ticket: Tickets,
  attendees: AttendeesSolid,
  map: Map,
} as const

export type TTableIcon = keyof typeof TableIcons

export interface TableIconProps extends React.SVGProps<SVGSVGElement> {
  name: TTableIcon
}

const DEFAULT_TABLE_ICON_SIZE = 14
export const TableIcon = (props: TableIconProps) => {
  const {name, width = DEFAULT_TABLE_ICON_SIZE, height = DEFAULT_TABLE_ICON_SIZE, ...rest} = props
  const IconComponent = TableIcons[name]

  return <IconComponent width={width} height={height} {...rest} />
}

export const Icon = TableIcon
