import React from 'react'

import useFetchFlaggedOrders from 'apis/AdminDashboard/useFetchFlaggedOrders'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import moment from 'moment'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

const FraudOrders = () => {
  const {data: data, isFetching: isLoading} = useFetchFlaggedOrders()

  return (
    <>
      <BackAndTitle title={'Recent Orders Over $300'} />
      {isLoading ? (
        <SpinLoader height={40} />
      ) : (
        <table>
          <tbody>
            <tr>
              <th>Purchase Time</th>
              <th>Order #</th>
              <th>Purchaser</th>
              <th>Subtotal</th>
              <th>Number of Items</th>
              <th>Organizer</th>
              <th>Organizer Status</th>
              <th>Payment Intent</th>
            </tr>
            {data?.map((d, index) => {
              const ISIO = d.order.tickets.length + d.order.tables.length

              return (
                <tr key={index}>
                  <td>{moment(d.order.createdAt).format('L LT')}</td>
                  <td>#{d.order.number}</td>
                  <td>{d.order.accountName}</td>
                  <td>${d.order.subtotal.toFixed(2)}</td>
                  <td>{ISIO}</td>
                  <td>{d.group.name}</td>
                  <td>
                    {d.group.verified ? (
                      <b style={{color: '#00ccff'}}>Verified</b>
                    ) : (
                      <b style={{color: 'red'}}>Not Verified</b>
                    )}
                  </td>
                  <td>
                    <a
                      href={`https://dashboard.stripe.com/payments/${d.order.paymentIntent}`}
                      target='_blank'
                      rel='noreferrer'>
                      {d.order.paymentIntent} &#x2197;
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </>
  )
}

export default FraudOrders
