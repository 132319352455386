import React, {useMemo, useState} from 'react'

import {IPendingApproval} from 'apis/Orders/useGetPendingApprovals'

import {ApprovalCrmContext} from './ApprovalCrmContext'

const ApprovalCrmContextProvider = (props: {children: React.ReactNode}) => {
  const [selectedApprovalIds, setSelectedApprovalIds] = useState<string[]>([])
  const [approvalProfile, setShowApprovalProfile] = useState<IPendingApproval | null>(null)

  const contextState = useMemo(() => {
    return {
      selectedApprovalIds,
      setSelectedApprovalIds,
      approvalProfile,
      setShowApprovalProfile,
    }
  }, [selectedApprovalIds, approvalProfile, setSelectedApprovalIds, setShowApprovalProfile])

  return <ApprovalCrmContext.Provider value={contextState}>{props.children}</ApprovalCrmContext.Provider>
}

export default ApprovalCrmContextProvider
