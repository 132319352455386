import React from 'react'

import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'
const DeletedEvent = () => {
  return (
    <div className='RemovedEvent'>
      <div className='RemovedEvent-block'>
        <h3>Uh-Oh :(</h3>
        <h3>This event has been removed</h3>
        <PoshImage src='https://images.posh.vip/b2/computer+(1).png' />
        <Button className='Button--rounded' onClick={() => window.open('https://whatstheword.vip')}>
          View Other Events
        </Button>
      </div>
    </div>
  )
}
export default DeletedEvent
