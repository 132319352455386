import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type TGetGroupForFinancialsInput = RouterInputs['groups']['getGroupForFinancials']
export type TGetGroupForFinancialsOutput = RouterOutput['groups']['getGroupForFinancials']
type TGetGroupForFinancialsOptions = ReactQueryOptions['groups']['getGroupForFinancials']
export type EventFee = NonNullable<TGetGroupForFinancialsOutput['defaultFees']>[number]

export const useGetGroupForFinancials = (input: TGetGroupForFinancialsInput, opts?: TGetGroupForFinancialsOptions) => {
  return trpc.groups.getGroupForFinancials.useQuery(input, opts)
}
