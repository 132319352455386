import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {EventTablePurchaseData} from './types'

export interface EditEventTableParams {
  name?: string
  price?: number
  description?: string
  seatingLimit?: number
  disabled?: boolean
  purchaseData?: EventTablePurchaseData
  mapPosition?: [number, number]
  sold?: boolean
  filterKey?: string
}

export async function updateEventTable(eventId: string, tableId: string, tableParams: EditEventTableParams) {
  const response = await PoshApi.updateEventTable(eventId, tableId, tableParams)
  return response.data
}

export function useUpdateEventTable() {
  return useMutation((params: {eventId: string; tableId: string; tableParams: EditEventTableParams}) =>
    updateEventTable(params.eventId, params.tableId, params.tableParams),
  )
}
