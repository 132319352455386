import {useNavigate} from 'react-router-dom'

import {useResourcePageParams} from 'pages/PoshAppLayout'

/**
 * This hook is used to navigate to different pages within the organization
 */
export const useNavigateToOrgPages = () => {
  const {groupId} = useResourcePageParams()
  const {domain} = useResourcePageParams()

  const navigate = useNavigate()

  // Navigate to an attendee's specific order page to view their order information
  const navigateToOrderPage = (orderId: string) => {
    return navigate(`/${domain}/groups/${groupId}/orders/${orderId}`)
  }

  return {navigateToOrderPage}
}
