import React, {PropsWithChildren} from 'react'

import {BaseModal, BaseModalProps} from '../../BaseModal'

import styles from './styles.module.scss'

export function CenteredModal({children, ...props}: PropsWithChildren<BaseModalProps>) {
  return (
    <BaseModal {...props}>
      <div className={styles.centered}>{children}</div>
    </BaseModal>
  )
}
