import {useMutation} from '@tanstack/react-query'
import {LongLat} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

import {GalleryImage, Tag} from '.'

export interface UpdateVenue {
  id: string
  name?: string
  address?: string
  coordinates?: LongLat
  description?: string
  website?: string
  email?: string
  location?: LongLat
  galleryImages?: GalleryImage[]
  baseBuyout?: number
  maxCapacity?: number
  coverImage?: string
  tags?: Tag[]
  instagram?: string
}

async function updateVenue(venueData: UpdateVenue) {
  const response = await PoshApi.updateVenue(venueData)
  return response.data
}

export default function useUpdateVenue() {
  return useMutation((params: UpdateVenue) => updateVenue(params))
}
