import React, {useEffect, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {UpdateEventAttributes_DEPRECATED, useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import Select from 'components/form/Select'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {BaseEventSettingsProps, EventSettingsComponent} from '../eventSettingsComponent'

export const EventThirdPartySettings = (props: BaseEventSettingsProps) => {
  const {event} = props
  const {eventId, groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const [updateEventAttrs, setUpdateEventAttrs] = useState<UpdateEventAttributes_DEPRECATED>({})
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const [isShowingSave, setIsShowingSave] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!isEmpty(updateEventAttrs)) setIsShowingSave(true)
    else setIsShowingSave(false)
  }, [updateEventAttrs])

  const onSave = async () => {
    try {
      await updateEvent({eventId: eventId!, groupId: groupId!, eventData: updateEventAttrs})
      setIsShowingSave(false)
      queryClient.refetchQueries(['event'])
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
      setIsShowingSave(false)
    }
  }

  const onDiscard = () => {
    setUpdateEventAttrs({})
  }

  return (
    <EventSettingsComponent onDiscard={onDiscard} onSave={onSave} isShowingSave={isShowingSave}>
      <div style={{width: '246px'}}>
        <Select
          defaultValue={event.category}
          className='CreateEvent-form-content-innerForm-input select'
          style={{width: '246px !important'}}
          onChange={e => {
            setUpdateEventAttrs({...updateEventAttrs, category: e.target.value})
          }}>
          <option value=''>Select a Category</option>
          <option value='nightlife'>Nightlife</option>
          <option value='concert'>Concert</option>
          <option value='holiday'>Holiday</option>
          <option value='haunted-yachts'>Haunted Yachts</option>
          <option value='venues'>Venues</option>
          <option value='rooftops'>Rooftops</option>
          <option value='attractions'>Attractions</option>
          <option value='sightseeing'>Sightseeing</option>
          <option value='landevents'>Land Events</option>
        </Select>
      </div>
    </EventSettingsComponent>
  )
}
