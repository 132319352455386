import React, {PropsWithChildren} from 'react'

import {Charts, Compass, KickbackLogo, Tickets} from 'components/assets/Icons'

import {OrganizationsSecondary} from '../../../desktop/OrganizationsSecondary/index'

const ICON_SIZE = 24

type Page = 'organizations' | 'kickback' | 'orders' | 'explore'

type NavigationIconConfig = PropsWithChildren<{
  title: string
  icon: React.ReactNode
}>

export const navigationIcons: Record<Page, NavigationIconConfig> = {
  organizations: {
    title: 'Organizations',
    icon: <Charts height={ICON_SIZE} width={ICON_SIZE} />,
    children: <OrganizationsSecondary />,
  },
  kickback: {
    title: 'Kickback',
    icon: <KickbackLogo height={ICON_SIZE} width={ICON_SIZE} />,
  },
  orders: {
    title: 'Orders',
    icon: <Tickets height={ICON_SIZE} width={ICON_SIZE} />,
  },
  explore: {
    title: 'Explore',
    icon: <Compass height={ICON_SIZE} width={ICON_SIZE} />,
  },
}
