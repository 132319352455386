import React, {useEffect, useState} from 'react'

import {RSVP_ERROR} from '@posh/model-types'
import {useQueryClient} from '@tanstack/react-query'
import {useCreateFreeOrder} from 'apis/Checkout/useCreateFreeOrder'
import Button from 'components/form/Button'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import CustomCheckoutFields from 'pages/NewCheckoutPage/CustomCheckoutFields'
import LoginForm from 'pages/NewCheckoutPage/LoginForm'

import './styles.scss'

export const RSVPModal = (props: PoshStyledModalProps & {rsvpButtonText?: string}) => {
  const {isOpen, onClose} = props
  const {userId} = useSessionContext()
  const {showToast} = useToast()
  const {cartId, validateAllRequiredCheckoutFields, cartHasCustomCheckoutFields} = useLiveCartContext()
  const [filledCheckoutFields, setFilledCheckoutFields] = useState(false)
  const [showFields, setShowFields] = useState(false)
  const queryClient = useQueryClient()

  const {mutateAsync: createFreeOrder, isLoading} = useCreateFreeOrder()
  const shouldOpenModal = isOpen && (!userId || (!!userId && !filledCheckoutFields && !isLoading))

  const handleCreateFreeOrder = async () => {
    try {
      if (!cartId) return onClose()
      if (!validateAllRequiredCheckoutFields()) return
      await createFreeOrder({cartId})
      setFilledCheckoutFields(true)
      onClose()
    } catch (error) {
      queryClient.invalidateQueries(['eventTickets'])
      if (error.message === RSVP_ERROR.CLOSED) {
        showToast({type: 'warning', title: 'The event is no longer accepting RSVPs.'})
      }
      // Our catch all error handler should show the error message and unauthorize them
      else if (error.data.code !== 'UNAUTHORIZED') {
        showToast({type: 'error', title: 'There was an error trying to RSVP.', subtitle: error.message})
      }
      onClose()
    }
  }
  useEffect(() => {
    if (!userId) return
    if (cartHasCustomCheckoutFields || !validateAllRequiredCheckoutFields()) setShowFields(true)
    else handleCreateFreeOrder()
  }, [userId, cartHasCustomCheckoutFields])

  return (
    <PoshStyledModal {...props} isOpen={shouldOpenModal}>
      <div className='RSVPModal'>
        {!userId ? (
          <LoginForm />
        ) : (
          showFields && (
            <>
              <CustomCheckoutFields />
              <Button className='fit center' onClick={handleCreateFreeOrder} isLoading={isLoading} disabled={isLoading}>
                {props.rsvpButtonText ?? 'RSVP'}
              </Button>
            </>
          )
        )}
      </div>
    </PoshStyledModal>
  )
}
