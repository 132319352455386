import React from 'react'

import styles from './styles.module.scss'

export type Item = {
  title: string
  onClick: () => void
}

export function NavigationSecondaryListItem(props: {item: Item; isSelected: boolean}) {
  const {item, isSelected} = props

  return (
    <div
      className={styles.NavigationSecondaryListItem}
      data-is-selected={isSelected}
      onClick={e => {
        e.stopPropagation()
        item.onClick()
      }}>
      <p className='noMargin'>{item.title}</p>
    </div>
  )
}
