import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import classNames from 'classnames'
import {TableIcon, TTableIcon} from 'components/TableUI/TableIcons/TableIcon'

import {InputContainer} from '../shared/InputContainer/InputContainer'
import {ControlledFormProps} from '../shared/types'

import styles from './TextInput.module.scss'

export interface TextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'className'> {
  value?: string
  onChange: (value: string) => void
  error?: string
  // TODO: Rename this to TIcon and move it to a shared location
  icon?: TTableIcon
  className?: string
  containerClassName?: string
}

const ICON_SIZE = 16

export const TextInput = (props: TextInputProps) => {
  const {value, onChange, icon, error, prefix, className, containerClassName, ...rest} = props

  return (
    <InputContainer error={error}>
      {/** TODO: Replace with generic <Icon /> component in shared location */}
      {icon && (
        <TableIcon name={icon} className={styles.InputIcon} width={ICON_SIZE} height={ICON_SIZE} color={'#909193'} />
      )}
      <div className={classNames([styles.Container, containerClassName])}>
        {prefix && <span className={styles.Prefix}>{prefix}</span>}
        <input
          {...rest}
          className={classNames(styles.TextInput, {[styles.TextInputIcon]: !!icon}, className)}
          value={value ? value : ''}
          onChange={e => onChange(e.target.value)}
        />
      </div>
    </InputContainer>
  )
}

interface ControlledTextInputProps<T extends FieldValues, K extends Path<T>>
  extends Omit<TextInputProps, 'value' | 'onChange' | 'name'>,
    ControlledFormProps<T, K> {}

const ControlledTextInput = <T extends FieldValues, K extends Path<T>>(props: ControlledTextInputProps<T, K>) => {
  const {control, name, rules, ...textInputProps} = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState, formState: _formState}) => {
        return <TextInput {...field} error={fieldState.error?.message} {...textInputProps} />
      }}
    />
  )
}

TextInput.Controlled = ControlledTextInput
