import React from 'react'

export const AttendeeCheckBox = ({
  onChecked,
  defaultChecked,
}: {
  onChecked: (defaultChecked: boolean) => void
  defaultChecked: boolean
}) => {
  const onChange = () => {
    onChecked(!defaultChecked)
  }

  return (
    <div className='AttendeesTable-checkBox'>
      <input className='AttendeesTable-checkBox--input' type='checkbox' onChange={onChange} />
      <span className={`AttendeesTable-checkBox--checkmark ${defaultChecked ? 'checked' : ''}`} />
    </div>
  )
}
