import React, {useEffect, useState} from 'react'
import {ActionMeta, MultiValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'

import {Tag} from 'apis/Community'
import {EntityDetails} from 'pages/CommunityDashboard/dashboardHelpers'

import defaultTags from './defaultTags'

interface ListingTagsProps {
  setSettings: React.Dispatch<React.SetStateAction<EntityDetails>>
  settings: EntityDetails
}

const ListingTags = (props: ListingTagsProps) => {
  const {setSettings, settings} = props
  const [selectedTags, setSelectedTags] = useState<Tag[]>(settings.tags ?? [])
  const [options, setOptions] = useState<Tag[]>(defaultTags)

  const creatableSelectOnChange = (selectValue: MultiValue<Tag>, actionMeta: ActionMeta<Tag>) => {
    if (actionMeta.action === 'remove-value') {
      setSelectedTags(selectValue as Tag[])
      setOptions([...options, actionMeta.removedValue])
    } else {
      setSelectedTags(selectValue as Tag[])
      const removedOption = selectValue[selectValue.length - 1]
      const optionsAfterSelect = options.filter(option => option.value !== removedOption?.value)
      setOptions(optionsAfterSelect)
    }
  }

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, option: Tag) => {
    const tag = option
    setSelectedTags([...selectedTags, tag])
    const optionsAfterClick = options.filter(option => option.value !== tag.value)
    setOptions(optionsAfterClick)
  }

  useEffect(() => {
    setSettings({...settings, tags: selectedTags})
  }, [selectedTags])

  useEffect(() => {
    if (settings && settings.tags) setSelectedTags(settings.tags)
  }, [settings])

  const styles = {
    control: (provided: any) => ({
      ...provided,
      border: '0 !important',
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
      color: '#0a0b0d',
      background: 'none',
      outline: 'none',
      padding: '0',
      borderRadius: '100px',
      overflow: 'hidden',
    }),
    input: (provided: any) => ({
      ...provided,
      border: 'none',
      color: 'white',
      outline: 'none',
    }),
    container: (provided: any) => ({
      ...provided,
      border: 'none !important',
      padding: '0',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      backgroundColor: '#262729',
      flexDirection: 'row',
      border: 'none',
      padding: '6px 14px',
      outline: 'none',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      background: '#ffcc00',
      padding: '4px 8px',
      borderRadius: '999px',
      boxShadow: '0px 3px 15px -4px #000',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      backgroundColor: 'black',
      border: 'none',
      display: 'none',
    }),
    menu: () => ({
      backgroundColor: '#0a0b0d',
      width: '100%',
    }),
  }

  return (
    <div className='TagInput'>
      <div className='TagInput-TagList'>
        {options.map((option, i) => (
          <div onClick={event => handleOnClick(event, option)} className='TagInput-TagList-Tag' key={i}>
            {option.value}
          </div>
        ))}
      </div>
      {selectedTags ? (
        <CreatableSelect
          className='CreatableSelect'
          isMulti
          onChange={creatableSelectOnChange}
          styles={styles}
          value={selectedTags}
          placeholder={'Type to input tags...'}
        />
      ) : (
        <p>Add Tags to your profile to make it easier to be found!</p>
      )}
    </div>
  )
}

export default ListingTags
