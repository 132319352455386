import React, {useState} from 'react'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {SpinLoader} from 'components/Loaders/SpinLoader'
import AddToCalendarModal from 'pages/EventPage/AddToCalendarModal'

import {EventResponse, useFetchAllAttendeeOrdersAndEvents} from '../../apis/Accounts/useFetchAllAttendeeOrdersAndEvents'
import AttendeeEvent from '../../components/data/AttendeeEvent'

const AccountOrders = () => {
  const {data: ordersAndEvents, isFetching} = useFetchAllAttendeeOrdersAndEvents()
  const [eventForCalendar, setEventForCalendar] = useState<EventResponse | undefined>(undefined)

  const setAddCalendarModalOpen = (open: boolean) => {
    if (!open) setEventForCalendar(undefined)
  }

  return (
    <div className='GroupsDash'>
      <div className='GroupsDash-inner'>
        <h2>My Orders</h2>
        {ordersAndEvents && ordersAndEvents.length > 0 ? (
          <div className='OrdersCont'>
            {ordersAndEvents.map(orderAndEvent => (
              <AttendeeEvent
                key={orderAndEvent.order._id}
                orderAndEvent={orderAndEvent}
                onAddToCalendar={() => setEventForCalendar(orderAndEvent.event)}
              />
            ))}
          </div>
        ) : (
          <>
            {isFetching ? <SpinLoader height={80} /> : <p style={{color: 'grey'}}>You have no tickets to any events</p>}
          </>
        )}
        {eventForCalendar && (
          <AddToCalendarModal
            event={eventForCalendar}
            addCalendarModalOpen={eventForCalendar !== undefined}
            setAddCalendarModalOpen={setAddCalendarModalOpen}
          />
        )}
      </div>
    </div>
  )
}

export default AccountOrders
