import React from 'react'

import {FileOrderResponse} from '@posh/model-types'
import {useDownloadCustomCheckoutFieldFile} from 'apis/CustomCheckoutFields/useDownloadCustomCheckoutFieldFile'
import getResponseValue from 'components/CheckoutFieldResponses/getResponseValue.helper'
import RequirePermissions from 'components/RequirePermissions'

import CheckoutFieldResponse from './CheckoutFieldResponse'

const CheckoutFieldFileResponse = ({response, eventId}: {response: FileOrderResponse; eventId: string}) => {
  const {mutate: downloadFileFromS3} = useDownloadCustomCheckoutFieldFile()
  const downloadFile = () => {
    downloadFileFromS3({key: response.url, eventId: eventId})
  }

  return (
    <RequirePermissions
      requiredPermissions={[{permissionKey: 'access_attendee_files', applicableScopes: ['event', 'group']}]}>
      <CheckoutFieldResponse>
        <CheckoutFieldResponse.Label>{response.prompt}</CheckoutFieldResponse.Label>
        <CheckoutFieldResponse.Value className='primary-link' onClick={downloadFile}>
          {getResponseValue(response)}
        </CheckoutFieldResponse.Value>
      </CheckoutFieldResponse>
    </RequirePermissions>
  )
}

export default CheckoutFieldFileResponse
