import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

async function fetchFlaggedOrders(): Promise<any[]> {
  const response = await PoshApi.fetchFlaggedOrders()
  return response.data
}

export default function useFetchFlaggedOrders() {
  return useQuery<any[]>(['flagged-orders'], () => fetchFlaggedOrders(), {
    refetchOnMount: false,
  })
}
