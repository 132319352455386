import React from 'react'

import {RoleKey} from 'apis/Roles/Role'
import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'

export interface RoleOptionAttributes {
  title: string
  description: string
  icon: string
  roleKey: RoleKey
}

interface RoleOptionProps {
  optionAttributes: RoleOptionAttributes
  selected: boolean
  onClick: () => void
  disabled?: boolean
}

export default function RoleOption(props: RoleOptionProps) {
  const {selected, optionAttributes, onClick, disabled} = props
  return (
    <label
      className={classNames('AddTeamMemberModal-option', {
        selected: selected,
      })}>
      <h4 className='noMargin'>{optionAttributes.title}</h4>
      <PoshImage className='AddTeamMemberModal-icon' src={optionAttributes.icon} />
      <p className='noMargin text-small'>{optionAttributes.description}</p>
      <input
        className='AddTeamMemberModal-radio'
        type='radio'
        checked={selected}
        disabled={disabled}
        onClick={onClick}
      />
    </label>
  )
}
