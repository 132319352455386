import React from 'react'

import {GroupAccount} from 'apis/Groups/useGetGroupAccount'
import {OrderModel} from 'apis/Orders'
import {Card} from 'components/Card'
import moment from 'moment'

interface CustomerCardTimelineProps {
  orders?: OrderModel[]
  account: GroupAccount
}

const CustomerCardTimeline = (props: CustomerCardTimelineProps) => {
  const {orders, account} = props

  const {joinedDate, firstName, lastName} = account

  interface timelineEvent {
    date: Date | string
    jsx: JSX.Element
  }

  const timelineEvents: timelineEvent[] = [
    {
      date: joinedDate,
      jsx: (
        <>
          <tr>
            <td>
              <div className='OrderPage-Timeline-Dot'>
                <div></div>
              </div>
            </td>
            <td>
              {firstName} {lastName} joined POSH.
            </td>
            <td>{joinedDate}</td>
          </tr>
        </>
      ),
    },
  ]

  orders?.map(order => {
    if (order.createdAt) {
      timelineEvents.push({
        date: order.createdAt,
        jsx: (
          <tr>
            <td>
              <div className='OrderPage-Timeline-Dot'>
                <div></div>
              </div>
            </td>
            <td>
              {account.firstName} made Order #{order.number} for ${order.subtotal.toFixed(2)}.
            </td>
            <td>{moment(order.createdAt).format('h:mm A')}</td>
          </tr>
        ),
      })
    }
  })

  const presentDates: string[] = []

  return (
    <Card title={'Timeline'} animationIndex={3}>
      <div className='OrderPage-Timeline'>
        <table>
          <tbody>
            {timelineEvents
              .sort((a, b) => (a.date > b.date ? 1 : -1))
              .map(t => {
                if (presentDates.includes(moment(t.date).format('MMMM Do'))) {
                  return t.jsx
                } else {
                  presentDates.push(moment(t.date).format('MMMM Do'))
                  return (
                    <>
                      <tr>
                        <th colSpan={3}>{moment(t.date).format('MMMM Do')}</th>
                      </tr>
                      {t.jsx}
                    </>
                  )
                }
              })}
          </tbody>
        </table>
      </div>
    </Card>
  )
}

export default CustomerCardTimeline
