import {useParams} from 'react-router-dom'

import {z} from 'zod'

const approvalSectionParamsSchema = z.object({
  eventId: z.string(),
})
const useApprovalSectionParams = () => {
  const params = useParams()
  const parsedParams = approvalSectionParamsSchema.safeParse(params)
  if (!parsedParams.success) throw new Error('Invalid Approval Section params')
  return parsedParams.data
}

export default useApprovalSectionParams
