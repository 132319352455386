import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {ExportKeyObject} from './types'

export interface ExportReportParams {
  eventId: string
  exportKeys: ExportKeyObject[]
  options?: {
    includeCancelledOrders: boolean
  }
}

// Parsing is done manually because the response is not a JSON object due to res.write being used on the server side
export async function exportReport(params: ExportReportParams): Promise<string> {
  const response = await PoshApi.exportReport(params)
  let data = response.data
  // Response is parsed to at the reportUrl key
  data = data.split('reportUrl')[1]
  // 3 is the length to remove the colon, space, and quote
  // data.length - 2 is the length to remove the quote and newline
  return data.substring(3, data.length - 2)
}

export function useExportReport() {
  return useMutation((params: ExportReportParams) => exportReport(params))
}
