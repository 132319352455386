export default [
  {
    label: 'Mixologist',
    value: 'Mixologist',
  },
  {
    label: 'Food Handler Certified',
    value: 'Food Handler Certified',
  },
  {
    label: 'Roof-Top',
    value: 'Roof-Top',
  },
  {
    label: 'Drink Specials',
    value: 'Drink Specials',
  },
  {
    label: 'House',
    value: 'House',
  },
  {
    label: 'Synthwave',
    value: 'Synthwave',
  },
]
