import {useQuery} from '@tanstack/react-query'

import {getAccountRoleGroupIdMap} from '../../domains/Auth/SessionContextProvider'
import {useResourcePageParams} from '../../pages/PoshAppLayout'
import PoshApi from '../PoshApi'
import {TeamMemberResponse} from '.'
import {ScopeKey} from './Role'

async function fetchTeam(entity: string, scope: ScopeKey, accountRoleId: string): Promise<TeamMemberResponse[]> {
  const response = await PoshApi.fetchTeam(entity, scope, accountRoleId)
  const data = response.data
  return data.teamMembers ?? []
}

export default function useFetchTeam(entity: string, scope: ScopeKey) {
  const {groupId} = useResourcePageParams()
  const accountRoleGroupIdMap = getAccountRoleGroupIdMap()
  const accountRole = accountRoleGroupIdMap ? accountRoleGroupIdMap[groupId!] : undefined
  return useQuery(['team', entity, scope], () => fetchTeam(entity, scope, accountRole!._id), {
    enabled: !!accountRole?._id,
  })
}
