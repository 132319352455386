import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetImageUploadIntentOptions = ReactQueryOptions['images']['getImageUploadIntent']
export type GetImageUploadIntentInput = RouterInputs['images']['getImageUploadIntent']
export type ImageUploadType = GetImageUploadIntentInput['imageType']
export type GetImageUploadIntentOutput = RouterOutput['images']['getImageUploadIntent']

export function useGetImageUploadIntent(opts?: GetImageUploadIntentOptions) {
  return trpc.images.getImageUploadIntent.useMutation(opts)
}
