import {ImageUploadType} from 'apis/Images/useGetUploadIntent'

import {UploadImageError, useUploadImage} from './useUploadImage'

export interface UseUploadMultipleImagesParams {
  imageType: ImageUploadType
  onSuccess: (responses: {imageId: string; imageUrl: string}[]) => Promise<void> | void
  onError?: (error: UploadImageError) => Promise<void> | void
}

export function useUploadMultipleImages(params: UseUploadMultipleImagesParams) {
  const {onSuccess} = params

  const images: {imageId: string; imageUrl: string}[] = []

  const {uploadImage, ...rest} = useUploadImage({
    ...params,
    onSuccess: (imageId, imageUrl) => {
      images.push({imageId, imageUrl})
    },
    onError: error => {
      params.onError?.(error)
      throw error
    },
  })

  const uploadMultipleImages = async (files: File[]) => {
    await Promise.all(files.map(uploadImage))
    if (images.length !== files.length) {
      throw new Error('Not all images were uploaded successfully')
    }
    onSuccess(images)
  }

  return {uploadMultipleImages, ...rest}
}
