import React from 'react'

import PoshHoverModal from 'components/form/PoshHoverModal'
import {PoshImage} from 'components/PoshImage/PoshImage'

import useAttendeesCrmContext from '../AttendeesCrmContext'

interface ActionBarButtonProps {
  onClick?: () => void
  img: string
  title: string
  info: string
  isDisabled?: boolean
}

export const ActionBarButton = ({onClick, img, title, info, isDisabled}: ActionBarButtonProps) => {
  const {selectedAttendeeIds} = useAttendeesCrmContext()

  const disabled = isDisabled || (['TEXT', 'TAG', 'EMAIL'].includes(title) && selectedAttendeeIds.length <= 0)

  if (disabled)
    return (
      <button className='Attendees-actionBarButton disabled' onClick={onClick} disabled={disabled}>
        <PoshImage className='Attendees-actionBarButton--image' src={img} />
        {title}
        <PoshHoverModal info={info} />
      </button>
    )

  return (
    <button className='Attendees-actionBarButton' onClick={onClick} disabled={disabled}>
      <PoshImage className='Attendees-actionBarButton--image' src={img} />
      {title}
      <PoshHoverModal info={info} />
    </button>
  )
}
