import React from 'react'

import {useDimensions} from 'hooks/useDimensions'
import {CreateEventProgressBar, CreateEventProgressBarProps} from 'pages/CreateEvent/components/ProgressBar'

import {EventVisualsActionRow, EventVisualsActionRowProps} from '../ActionRow'

import styles from './styles.module.scss'

interface CreateEventActionRowProps extends EventVisualsActionRowProps, CreateEventProgressBarProps {}

export function CreateEventActionRow(props: CreateEventActionRowProps) {
  const {isMobile} = useDimensions()
  const title = isMobile ? undefined : 'Create Event'
  return (
    <EventVisualsActionRow {...props} title={title} exitTitle='Exit Event Creator'>
      <div className={styles.progressBarContainer}>
        <CreateEventProgressBar {...props} />
      </div>
    </EventVisualsActionRow>
  )
}
