import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetTrackingLinksWithHostOptions = ReactQueryOptions['events']['trackingLinks']['getTrackingLinksWithHost']
type GetTrackingLinksWithHostInput = RouterInputs['events']['trackingLinks']['getTrackingLinksWithHost']
export type GetTrackingLinksWithHostOutput = RouterOutput['events']['trackingLinks']['getTrackingLinksWithHost']
export type TTrackingLinkWithHost = GetTrackingLinksWithHostOutput['trackingLinks'][0]

export function useGetTrackingLinksWithHost(
  input: GetTrackingLinksWithHostInput,
  opts?: GetTrackingLinksWithHostOptions,
) {
  return trpc.events.trackingLinks.getTrackingLinksWithHost.useQuery(input, opts)
}
