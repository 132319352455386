import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface TextBlastResponse {
  recipients: number
  failed: number
  delivered: number
  sending: number
  // Means that the sms server has picked it up and created a text blast
  initiated?: boolean
  textBlastId?: string
}

const fetchTextBlast = async (textBlastId: string): Promise<TextBlastResponse> => {
  const response = await PoshApi.fetchTextBlast(textBlastId)
  return response.data.textBlastResponse
}

export function useFetchTextBlast(textBlastId: string, isEnabled: boolean, refetchInterval: number | false) {
  return useQuery(['fetchTextBlast', textBlastId], async () => await fetchTextBlast(textBlastId), {
    refetchInterval: refetchInterval,
    enabled: isEnabled,
  })
}
