import {useQuery} from '@tanstack/react-query'
import {Venue} from 'apis/Community'
import {EventPublic} from 'apis/Events/types'

import PoshApi from '../PoshApi'

export interface VenueUrlResponse {
  venue?: Venue
  upcomingEvents: EventPublic[]
  pastEvents: EventPublic[]
  numOfAttendees: number
}

async function getVenue(url: string): Promise<VenueUrlResponse> {
  const response = await PoshApi.fetchVenueByUrl(url)
  return response.data
}

export default function useGetVenueByUrl(url?: string) {
  return useQuery(['venueUrl', url], () => getVenue(url ?? ''), {
    enabled: !!url,
  })
}
