import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useQueryClient} from '@tanstack/react-query'
import {EventTicketAndTicketSold} from 'apis/Events/types'
import useFetchEvent from 'apis/Events/useFetchEvent'
import {useFetchEventTickets} from 'apis/Events/useFetchEventTickets'
import {useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import ComplimentaryTickets from 'pages/EventManagementPages/TeamMembers/ComplimentaryTickets'
import TicketGroupTable from 'pages/EventManagementPages/TeamMembers/TicketGroupTable'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import PromoCodesSection from '../PromoCodes/PromoCodesSection'
import SortableTicketList from './SortableTicketsList'

const TicketsSection = () => {
  const {eventId, groupId} = useResourcePageParams()
  const {data: eventTicketsAndTicketSold, isFetching, refetch} = useFetchEventTickets(eventId!)
  const {data: event} = useFetchEvent(eventId!)
  const queryClient = useQueryClient()
  const [orderedTickets, setOrderedTickets] = useState(eventTicketsAndTicketSold ?? [])
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const navigate = useNavigate()
  useEffect(() => {
    setOrderedTickets(eventTicketsAndTicketSold ?? [])
  }, [eventTicketsAndTicketSold])

  const onRerenderTickets = async (tickets: EventTicketAndTicketSold[]) => {
    setOrderedTickets(tickets)
    const eventTickets = tickets.map(t => t.ticket)
    await updateEvent({eventId: eventId!, groupId: groupId!, eventData: {tickets: eventTickets}})
    queryClient.refetchQueries(['event', event?.event.url, 'eventTickets'])
  }
  const {data: group} = useFetchGroup(groupId!)
  const currency = group?.currency

  const onClickAddTicketType = () => {
    trackMixpanelEvent('Add Ticket Type- Curator Event Ticketing', {eventStatus: event?.event.status ?? ''})
    navigate('create')
  }

  const onClickCreateTicketGroup = () => {
    trackMixpanelEvent('Create Ticket Group- Curator Event Ticketing', {eventStatus: event?.event.status ?? ''})
  }

  const ticketGroups = event?.event.ticketGroups

  const isRSVPEvent = event?.event.isRSVPEvent

  return (
    <>
      <div className='eventSection'>
        <div className='eventSection-header'>
          <h3>Ticket Types</h3>
          <Button className='dark light' onClick={onClickAddTicketType}>
            + Add Ticket Type
          </Button>
        </div>
        <SortableTicketList
          tickets={orderedTickets}
          setTickets={onRerenderTickets}
          isEditingDisabled={isFetching}
          currency={currency}
          timezone={event?.event.timezone}
        />
      </div>
      <RequirePermissions
        requiredPermissions={[requirePermissionAndScope('view_and_send_complimentary_tickets', ['event', 'group'])]}>
        {ticketGroups && (
          <TicketGroupTable
            ticketGroups={event?.event.ticketGroups}
            refetch={refetch}
            onClickCreateTicketGroup={onClickCreateTicketGroup}
          />
        )}
        <ComplimentaryTickets />
      </RequirePermissions>
      {!isRSVPEvent && (
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('add_promo_code', ['event', 'group'])]}>
          {group && <PromoCodesSection tickets={eventTicketsAndTicketSold} group={group} />}
        </RequirePermissions>
      )}
    </>
  )
}

export default TicketsSection
