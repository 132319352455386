import {useNavigate, useParams} from 'react-router-dom'

const useTextBlastParams = () => {
  const {blastId, groupId} = useParams()
  const navigate = useNavigate()

  if (!blastId || !groupId) {
    console.error('Missing blastId or groupId in query parameters')
    navigate('/')
    return {blastId: '', groupId: ''}
  }

  return {blastId, groupId}
}

export default useTextBlastParams
