import {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {censorBadLinks, parseBlastMessage} from '@posh/utils'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import useDebounce from 'hooks/useDebounce'

import {trpc} from '../../../lib/trpc'
import formatPhoneNumber from '../formatPhoneNumber.helpers'
import {useMessage} from './messageHooks'
import {useSMSModal} from './modalHooks'
import {useOnClick} from './onClickHooks'
import {useTRPCMutations} from './trpcMutations'
import {useTRPCQueries} from './trpcQueries'
import {IChosenEvent} from './types'

export const useSMSCampaign = () => {
  const {blastId, eventId: eventIdParam, groupId} = useParams()
  if (!blastId || !groupId) throw new Error('Missing blastId or groupId')
  const {data: group} = useFetchGroup(groupId)
  const ORG_NAME = group?.name ?? 'Loading...'
  const organizationLabel = ORG_NAME + ':'

  const navigate = useNavigate()
  const {trackEvent} = useMixpanel()
  const {showToast} = useToast()
  const {currentUser} = useSessionContext()

  const [isRecipientsModalOpen, setIsRecipientsModalOpen] = useState(false)
  const [isCensored, setIsCensored] = useState(false)
  const [chosenEvent, setChosenEvent] = useState<IChosenEvent>(undefined)
  const [isEventsModalOpen, setIsEventsModalOpen] = useState(false)
  const [initialMessageValue, setInitialMessageValue] = useState<string | undefined>(undefined)
  const [message, setMessage] = useState('')
  const debouncedMessage = useDebounce(message, 100)
  const queryClient = trpc.useContext()

  // Modal Hooks
  const {onCloseRecipientsModal, onCloseEventsModal} = useSMSModal({
    setIsRecipientsModalOpen,
    setIsEventsModalOpen,
  })

  const {
    setEventForPendingTextBlast,
    setMessageForPendingTextBlast,
    confirmPendingTextBlast,
    deletePendingTextBlast,
    censorMessageContent,
  } = useTRPCMutations({
    setChosenEvent,
    setMessage,
    setIsCensored,
    navigate,
    showToast,
    queryClient,
  })

  const {pendingTextBlast, isLoading, groupPhoneNumberFetch} = useTRPCQueries({
    blastId,
    groupId,
    queryClient,
  })

  const {onClickViewAndEditRecipients, onClickEventLinkAction, onClickCancel, onClickSend} = useOnClick({
    setIsRecipientsModalOpen,
    trackEvent,
    pendingTextBlast,
    setEventForPendingTextBlast,
    setChosenEvent,
    setIsEventsModalOpen,
    deletePendingTextBlast,
    navigate,
    message,
    showToast,
    confirmPendingTextBlast,
    blastId,
    groupId,
  })

  const isInEventScope = !!eventIdParam
  const {
    recipientsSectionSubtitle,
    previewMessage,
    onMessageChange,
    messageContainsBadLink,
    messageContainsInappropriateWords,
  } = useMessage({
    pendingTextBlast,
    chosenEvent,
    setChosenEvent,
    setMessageForPendingTextBlast,
    blastId,
    groupId,
    currentUser,
    censorMessageContent,
    group,
    isInEventScope,
    organizationLabel,
    isCensored,
    setMessage,
    initialMessageValue,
    setInitialMessageValue,
    message,
    debouncedMessage,
    censorBadLinks,
    parseBlastMessage,
    setIsCensored,
  })
  const formattedPhoneNumber = formatPhoneNumber(groupPhoneNumberFetch?.groupPhoneNumber)
  const previewRecipients = pendingTextBlast?.previewRecipients
  const isEventLinkAttached = !!chosenEvent?.url

  const onChooseEvent = (eventId: string, hexUrl?: string) => {
    setChosenEvent({id: eventId, url: `https://posh.vip/f/${hexUrl}?t=tb-2`})
    setEventForPendingTextBlast({pendingTextBlastId: blastId ?? '', eventId, groupId: groupId ?? ''})
  }
  const pendingTextBlastIsSent = pendingTextBlast?.status === 'sent'

  return {
    organizationLabel,
    previewRecipients,
    isRecipientsModalOpen,
    isEventsModalOpen,
    isLoading,
    recipientsSectionSubtitle,
    isEventLinkAttached,
    previewMessage,
    formattedPhoneNumber,
    onClickViewAndEditRecipients,
    onCloseRecipientsModal,
    onCloseEventsModal,
    onClickEventLinkAction,
    onMessageChange,
    onChooseEvent,
    onClickCancel,
    onClickSend,
    pendingTextBlastIsSent,
    initialMessageValue,
    messageContainsInappropriateWords,
    messageContainsBadLink,
    groupHasCustomNumber: Boolean(groupPhoneNumberFetch?.groupPhoneNumber),
  }
}
