import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {OrderTag} from 'apis/Orders'
import {useGetEventOrders} from 'apis/Orders/useGetEventOrders'
import {TextInput} from 'components/DynamicForm/Inputs/TextInput/TextInput'
import Button from 'components/form/Button'
import {Icon} from 'components/TableUI/TableIcons/TableIcon'
import useDebounce from 'hooks/useDebounce'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {Card} from '../Shared/Card/Card'
import {Text} from '../Shared/Text/Text'
import {OrderFilterModal} from './OrderFilterModal/OrderFilterModal'
import {OrderList} from './OrderList/OrderList'

import styles from './OrdersSection.module.scss'

const SEARCH_ICON_SIZE = 17
const FILTER_ICON_SIZE = 20

interface OrdersSectionProps {
  eventId: string
  groupId: string
  isRSVPEvent?: boolean
  isLoading?: boolean
}

export const OrdersSection = (props: OrdersSectionProps) => {
  const {eventId, groupId, isRSVPEvent} = props
  const [search, setSearch] = useState<string>()
  // Use this for the query
  const debouncedSearch = useDebounce(search, 300)
  const [isOrderFilterModalOpen, setIsOrderFilterModalOpen] = useState(false)
  const [filters, setFilters] = useState<OrderTag[]>([])
  const {domain} = useResourcePageParams()
  const navigate = useNavigate()

  const {
    data: orders,
    isLoading,
    isError,
    isSuccess,
  } = useGetEventOrders(
    {
      groupId: groupId,
      eventId: eventId,
      limit: 50,
      activeFilters: filters,
      search: debouncedSearch,
    },
    {
      enabled: props.isLoading !== true,
    },
  )

  const hasFilters = filters.length > 0 || !!debouncedSearch

  const navigateToOrdersPage = () => {
    navigate(`/${domain}/groups/${groupId}/orders?e=${encodeURIComponent(eventId)}`)
  }

  const OrdersOrRSVPs = isRSVPEvent ? 'RSVPs' : 'Orders'

  return (
    <div className={styles.OrderSection}>
      <div className={styles.OrderSectionHeader}>
        <Text size='large' bold>
          {`Recent ${OrdersOrRSVPs}`}
        </Text>
        <Button onClick={navigateToOrdersPage}>View All {OrdersOrRSVPs}</Button>
      </div>
      <Card>
        <div className={styles.OrderSectionContent}>
          <div className={styles.OrderSearchFilterSection}>
            <div style={{flexGrow: 1, position: 'relative'}}>
              <TextInput
                placeholder={`Search ${OrdersOrRSVPs}`}
                value={search}
                onChange={value => setSearch(value)}
                className={styles.OrderSearchInput}
              />
              <Icon
                width={SEARCH_ICON_SIZE}
                height={SEARCH_ICON_SIZE}
                color={'white'}
                name='search'
                className={styles.OrderSearchIcon}
              />
            </div>
            <Icon
              width={FILTER_ICON_SIZE}
              height={FILTER_ICON_SIZE}
              color={'white'}
              name='filter'
              onClick={() => setIsOrderFilterModalOpen(true)}
              className={styles.OrderFilterIcon}
            />
          </div>

          {(isLoading || props.isLoading) && <OrderList.Loading />}

          {isError && <OrderList.Error isRSVPEvent={isRSVPEvent} />}

          {isSuccess && <OrderList hasFilters={hasFilters} orders={orders} isRSVPEvent={isRSVPEvent} />}
        </div>
      </Card>
      {isOrderFilterModalOpen && (
        <OrderFilterModal
          isOpen={isOrderFilterModalOpen}
          onClose={() => setIsOrderFilterModalOpen(false)}
          filters={filters}
          onChangeFilters={setFilters}
          isRSVPEvent={isRSVPEvent}
        />
      )}
    </div>
  )
}
