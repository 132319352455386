import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface CreateAccountWithTempPasswordParams {
  email?: string
  firstName: string
  lastName: string
  phone?: string
}

const createAccountWithTempPassword = async (params: CreateAccountWithTempPasswordParams): Promise<string> => {
  const response = await PoshApi.createAccountWithTempPassword(params)
  return response.data.id
}

export function useCreateAccountWithTempPassword() {
  return useMutation<string, unknown, CreateAccountWithTempPasswordParams>(params =>
    createAccountWithTempPassword(params),
  )
}
