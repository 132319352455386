import React, {ReactNode} from 'react'

import Button from 'components/form/Button'
import UserListPreview from 'components/UserListPreview/UserListPreview'
import EventsModal from 'pages/GroupPage/Marketing/EventsModal'
import IMessagePreview from 'pages/SMSCampaign/IMessagePreview'
import MessageEditor from 'pages/SMSCampaign/MessageEditor'
import RecipientsModal from 'pages/SMSCampaign/RecipientsModal'
import SMSCampaignBackground from 'pages/SMSCampaign/SMSCampaignBackground'
import {useSMSCampaign} from 'pages/SMSCampaign/SMSCampaignHooks'

import PurchaseCustomPhoneNumberButton from './PurchaseCustomPhoneNumberButton'

import './styles.scss'

const CampaignCustomization = ({children}: {children: ReactNode}) => {
  return <div className='Campaign-customization'>{children}</div>
}

// TODO: fix weight here
const CustomizationSection = ({title, subtitle, children}: {title: string; subtitle?: string; children: ReactNode}) => {
  return (
    <div className='Campaign-customizationSection'>
      <h4 className='noMargin'>{title}</h4>
      {subtitle && <p className='noMargin'>{subtitle}</p>}
      {children}
    </div>
  )
}

export const SMSCampaignPage = () => {
  const {
    organizationLabel,
    previewRecipients,
    isRecipientsModalOpen,
    isEventsModalOpen,
    isLoading,
    recipientsSectionSubtitle,
    isEventLinkAttached,
    previewMessage,
    formattedPhoneNumber,
    onClickViewAndEditRecipients,
    onCloseRecipientsModal,
    onCloseEventsModal,
    onClickEventLinkAction,
    onMessageChange,
    onChooseEvent,
    onClickCancel,
    onClickSend,
    pendingTextBlastIsSent,
    initialMessageValue,
    messageContainsBadLink,
    messageContainsInappropriateWords,
    groupHasCustomNumber,
  } = useSMSCampaign()

  const errorMessage = messageContainsInappropriateWords
    ? 'It looks like you are using some inappropriate words! Please change your message to adhere to messaging carrier guidelines. If you believe this is a mistake please contact support@posh.vip.'
    : messageContainsBadLink
    ? 'It looks like you are using a non-Posh link, please use a Posh link.'
    : undefined

  return (
    <SMSCampaignBackground>
      <div className='Campaign-wrapper'>
        <h2 className='noMargin center'>New SMS Campaign</h2>
        <div className='Campaign-customizationContainer'>
          <CampaignCustomization>
            <CustomizationSection title='Recipients' subtitle={recipientsSectionSubtitle}>
              <UserListPreview>
                {isLoading && <UserListPreview.Skeleton baseColor='#305da4' />}
                {previewRecipients?.slice(0, 8).map((recipient, index) => {
                  const {avi} = recipient
                  return <UserListPreview.User imageSrc={avi} key={index} alt={`Posh profile picture`} />
                })}
                <p
                  className='primary-link text-xs'
                  style={{margin: '0 0 0 10px'}}
                  onClick={onClickViewAndEditRecipients}>
                  View & Edit Recipients
                </p>
              </UserListPreview>
              <RecipientsModal isOpen={isRecipientsModalOpen} onClose={onCloseRecipientsModal} />
            </CustomizationSection>
            {!groupHasCustomNumber && (
              <CustomizationSection
                title='Custom Phone Number'
                subtitle='Purchase a custom phone number to further solidify your brand.'>
                <PurchaseCustomPhoneNumberButton />
              </CustomizationSection>
            )}
            <CustomizationSection title='Compose Your Message'>
              <CampaignCustomization.MessageEditor
                organizationLabel={organizationLabel}
                isEventLinkAttached={isEventLinkAttached}
                onClickEventLink={onClickEventLinkAction}
                onMessageChange={onMessageChange}
                initialMessageValue={initialMessageValue}
                errorMessage={errorMessage}
              />
              <EventsModal isOpen={isEventsModalOpen} onClose={onCloseEventsModal} onChooseEvent={onChooseEvent} />
            </CustomizationSection>
          </CampaignCustomization>
          <CustomizationSection title='Messages'>
            <IMessagePreview phoneNumber={formattedPhoneNumber} message={previewMessage} />
          </CustomizationSection>
        </div>
        <div className='Campaign-footer'>
          <Button className='darker' onClick={onClickCancel}>
            Cancel
          </Button>
          <Button onClick={onClickSend} disabled={pendingTextBlastIsSent || Boolean(errorMessage)}>
            Send
          </Button>
        </div>
      </div>
    </SMSCampaignBackground>
  )
}

CampaignCustomization.MessageEditor = MessageEditor
