import React from 'react'

import {Moon, Sun} from 'components/assets/Icons'
import PoshSwitch, {PoshSwitchProps} from 'components/form/PoshSwitch'

interface EventVisualsSwitchProps extends PoshSwitchProps {
  iconType?: 'sunMoon'
  accentColor: string
  lightMode: boolean
}

const ICON_SIZE = 15

const getIcons = (iconType: EventVisualsSwitchProps['iconType'], accentColor: string) => {
  switch (iconType) {
    case 'sunMoon':
      return {
        checkedHandleIcon: <Sun color={accentColor} width={ICON_SIZE} height={ICON_SIZE} />,
        uncheckedHandleIcon: <Moon color={accentColor} width={ICON_SIZE} height={ICON_SIZE} />,
      }
    default:
      return {
        checkedHandleIcon: undefined,
        uncheckedHandleIcon: undefined,
      }
  }
}

const SWITCH_WIDTH = 50
const SWITCH_HEIGHT = 30

export const EventVisualsSwitch = (props: EventVisualsSwitchProps) => {
  const {accentColor, iconType} = props

  const {checkedHandleIcon, uncheckedHandleIcon} = getIcons(iconType, accentColor)

  return (
    <PoshSwitch
      {...props}
      onColor={accentColor}
      width={SWITCH_WIDTH}
      height={SWITCH_HEIGHT}
      switchOptions={{
        checkedHandleIcon: checkedHandleIcon,
        uncheckedHandleIcon: uncheckedHandleIcon,
        ...props.switchOptions,
      }}
    />
  )
}
