import React from 'react'

import classNames from 'classnames'
import {ArrowWithDirection, PhotoGallery, Star, TextLines, YoutubeLogoRed} from 'components/assets/Icons'
import {GlassView} from 'components/GlassView'
import {
  EventVisualsSection as EventVisualsSectionOptions,
  useEventVisualsContext,
} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'

import styles from './styles.module.scss'

interface CustomSectionItemProps {
  section: EventVisualsSectionOptions
  onSelectSection: (section: EventVisualsSectionOptions) => void
}

const ICON_SIZE = 16

const getSectionItemInfo = (section: EventVisualsSectionOptions, color: string) => {
  switch (section) {
    case 'youtube':
      return {
        title: 'Add YouTube Video',
        subtitle: 'Embed a YouTube video to give guests a preview of what to expect.',
        icon: <YoutubeLogoRed height={ICON_SIZE} width={ICON_SIZE} />,
      }
    case 'text':
      return {
        title: 'Add a Description',
        subtitle: `Include additional info about your event to set the tone for guests.`,
        icon: <TextLines height={ICON_SIZE} width={ICON_SIZE} color={color} />,
      }
    case 'lineup':
      return {
        title: 'Add Event Features',
        subtitle: `From DJs to sponsors, showcase your event's key players.`,
        icon: <Star height={ICON_SIZE} width={ICON_SIZE} color={color} />,
      }
    case 'gallery':
      return {
        title: 'Add a Gallery',
        subtitle: `Add images to showcase your event's vibe.`,
        icon: <PhotoGallery height={ICON_SIZE} width={ICON_SIZE} />,
      }
  }
}

export const CustomSectionItem = (props: CustomSectionItemProps) => {
  const {section, onSelectSection} = props
  const {
    palette: {blurTint, textContrasting, textContrastingWithOpacity, accentColor},
  } = useEventVisualsContext()
  const {title, icon, subtitle} = getSectionItemInfo(section, accentColor)

  return (
    <GlassView
      blurTint={blurTint}
      className={styles.CustomSectionItem}
      variants={['noBorder']}
      onClick={() => onSelectSection(section)}>
      <p className='noMargin bold' style={{color: textContrasting}}>
        {subtitle}
      </p>
      <GlassView
        blurTint={blurTint}
        className={classNames(styles.CustomSectionItem, styles.button)}
        variants={['noBorder']}>
        {icon}
        <h6 className='noMargin bold' style={{color: textContrastingWithOpacity}}>
          {title}
        </h6>
        <ArrowWithDirection direction='east' height={ICON_SIZE} width={ICON_SIZE} color={textContrasting} />
      </GlassView>
    </GlassView>
  )
}
