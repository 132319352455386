import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import dark from 'react-syntax-highlighter/dist/esm/styles/hljs/dark'

import {useResourcePageParams} from 'pages/PoshAppLayout'

import {EventSettingsComponent} from '../eventSettingsComponent'

export const EventEmbed = () => {
  const {eventId} = useResourcePageParams()
  const codeBlock = `<iframe
        src="https://embed.posh.vip/ticket-iframe/${eventId}/"
        height='600px'
        width='100%'
        style="border: none"
    ></iframe>`
  return (
    <EventSettingsComponent isShowingSave={false}>
      <p>
        Use the code below to embed checkout for this event on another website. To fine tune how the ticket types are
        displayed, edit the height and width attributes.
      </p>
      <SyntaxHighlighter language='html' style={dark}>
        {codeBlock}
      </SyntaxHighlighter>
      <p>This is how it will look:</p>
      <iframe
        src={`https://embed.posh.vip/ticket-iframe/${eventId}/`}
        height='2000px'
        width='100%'
        style={{border: 'none'}}
      />
    </EventSettingsComponent>
  )
}
