import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {DEFAULT_ACCENT_COLOR} from '@posh/types'
import {GetEventForVisualEditorOutput, useGetEventForVisualEditor} from 'apis/Events/useGetEventForVisualEditor'
import {transformGetEventForVisualEditorOutputToUpdateEventInput, useUpdateEvent} from 'apis/Events/useUpdateEvent'
import {
  EventVisualsForm,
  getEventVisualsAttributes,
} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useToast} from 'components/toasts/ToastProvider'
import {useResourcePageParams} from 'pages/PoshAppLayout'
import PoshLoaderTransition from 'pages/PoshLoaderTransition'

import {EventVisualsContextProvider} from './components/context/EventVisualsContext'
import {ActionRowSaveButtonProps, EventVisualsActionRow} from './components/page-sections/ActionRow'
import {EventVisualsActivitySection} from './components/page-sections/ActivitySection/EventVisualsActivitySection'
import {EventVisualsBackgroundImage} from './components/page-sections/BackgroundImage'
import {EventVisualsCustomSections} from './components/page-sections/CustomSections'
import {EventDetailsFormSection} from './components/page-sections/EventDetails'
import {EventFlyerColorSongSection} from './components/page-sections/EventFlyer/EventFlyerColorSongSection'
import {EventSettingsSection} from './components/page-sections/EventSettings'
import {EventVisualsGroupDisplaySettingsSection} from './components/page-sections/GroupDisplay'
import {EventVisualsGuestlistSection} from './components/page-sections/GuestListSection/EventVisualsGuestlistSection'
import {EventVisuals} from './components/page-sections/Layout'

export const UPGRADED_VISUAL_EDITOR_FEATURE_FLAG = 'web_visual_editor_upgrade'

import {VisualEditorWrapper} from './components/page/VisualEditorWrapper'

function VisualEditor({event, refetchEvent}: {event: GetEventForVisualEditorOutput; refetchEvent: () => void}) {
  const navigate = useNavigate()

  const {showToast} = useToast()
  const {
    mutate: updateEvent,
    isLoading: isSaving,
    isSuccess: hasUpdatedEvent,
  } = useUpdateEvent({
    onSuccess: () => {
      showToast({
        type: 'success',
        title: 'Successfully updated your event!',
      })
      refetchEvent()
    },
    onError: error => {
      showToast({
        type: 'error',
        title: 'Something went wrong!',
        subtitle: error.message,
      })
    },
  })

  const {
    control,
    watch,
    handleSubmit,
    formState: {dirtyFields, isValid},
    reset,
  } = useForm<EventVisualsForm>({
    defaultValues: transformGetEventForVisualEditorOutputToUpdateEventInput(event),
    mode: 'onChange',
  })
  useEffect(() => {
    reset(transformGetEventForVisualEditorOutputToUpdateEventInput(event))
  }, [event])

  const isDirty = Object.keys(dirtyFields).length > 0

  const {flyer, lightmode, accentColor, eventTitleFont, hasFilledNonControlledFields} = getEventVisualsAttributes(watch)

  const submit = handleSubmit(data => {
    return updateEvent(data)
  })

  const navigateToEvent = () => {
    navigate(`/e/${event.url}`)
  }

  const tooltipText = (() => {
    if (!isValid) return 'Please fill out the required fields'
    if (!hasFilledNonControlledFields) return 'Please add a flyer'
  })()

  const actionButton = ((): ActionRowSaveButtonProps => {
    if (hasUpdatedEvent && !isDirty)
      return {
        title: 'View Event',
        isDisabled: false,
        isLoading: false,
        className: 'success semiRound',
        onPress: navigateToEvent,
      }
    else if (isSaving)
      return {
        title: 'Saving...',
        isDisabled: true,
        isLoading: true,
        className: 'gold semiRound',
        onPress: () => {},
      }
    else
      return {
        title: 'Save Changes',
        isDisabled: !isDirty || !isValid || !hasFilledNonControlledFields,
        isLoading: isSaving,
        className: 'gold semiRound',
        onPress: submit,
        tooltipText,
      }
  })()

  return (
    <>
      <EventVisualsContextProvider
        lightmode={lightmode ?? false}
        accentColor={accentColor ?? DEFAULT_ACCENT_COLOR}
        fontFamily={eventTitleFont}>
        <VisualEditorWrapper>
          <EventVisualsActionRow
            actionButton={actionButton}
            isDirty={isDirty}
            title='Edit Event'
            isLightMode={!!lightmode}
          />
          <EventVisualsBackgroundImage flyer={flyer}>
            <EventVisuals.Content>
              <EventVisuals.Row>
                <EventFlyerColorSongSection control={control} />
                <EventVisuals.Column>
                  <EventDetailsFormSection control={control} />
                  <EventVisualsGroupDisplaySettingsSection control={control} />
                  <EventVisualsGuestlistSection.Preview
                    attendeesCount={event.attendingCount}
                    attendees={event.guestlistPreview}
                    control={control}
                  />
                </EventVisuals.Column>
              </EventVisuals.Row>
              <EventVisuals.Row>
                <EventSettingsSection control={control} />
              </EventVisuals.Row>
              <EventVisuals.Row>
                <EventVisuals.Column>
                  <EventVisualsActivitySection control={control} />
                </EventVisuals.Column>
                <EventVisuals.Column>
                  <EventVisualsGuestlistSection.Expanded attendees={event.guestlistPreview} control={control} />
                </EventVisuals.Column>
              </EventVisuals.Row>
              <EventVisualsCustomSections control={control} watch={watch} />
            </EventVisuals.Content>
          </EventVisualsBackgroundImage>
        </VisualEditorWrapper>
      </EventVisualsContextProvider>
    </>
  )
}

export function VisualEditorPage() {
  const {eventId} = useResourcePageParams()
  const {data: event, refetch: refetchEvent} = useGetEventForVisualEditor({eventId: eventId!})

  if (!event) return <PoshLoaderTransition pageIsChanging={true} fadeOutTransition={true} />

  return <VisualEditor event={event} refetchEvent={refetchEvent} />
}
