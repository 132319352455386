import React from 'react'
import {useMatch, useNavigate} from 'react-router-dom'

import classNames from 'classnames'
import {PoshSailBoat} from 'components/assets/Icons'
import {PoshRoutes} from 'models/PoshRoutes'

import {BaseNavigationItem} from '../BaseNavigationItem'
import {MobileBaseNavigationItemProps} from '../BaseNavigationItem/mobile'

import styles from './styles.module.scss'

const ICON_SIZE = 32

export function MobileLogoNavigationItem(
  props: Omit<MobileBaseNavigationItemProps, 'body' | 'icon' | 'onClick' | 'isSelected'>,
) {
  const navigate = useNavigate()
  const isOnExplore = useMatch(PoshRoutes.EXPLORE)

  return (
    <BaseNavigationItem.Mobile
      {...props}
      icon={<PoshSailBoat height={ICON_SIZE} width={ICON_SIZE} />}
      className={classNames([styles.Logo, styles.mobile])}
      isSelected={Boolean(isOnExplore)}
      onClick={() => navigate(PoshRoutes.EXPLORE)}
    />
  )
}
