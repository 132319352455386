import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CommunityCategory, CommunityEntityResponse, OpenToOtherDatesChoice} from '.'

export interface FetchBookingRequestByIdResponse {
  id: string
  bookerId: string
  communityId: string
  bookingDate: Date
  location: string
  startTime: Date
  endTime: Date
  message: string
  communityCategory: CommunityCategory
  otherDates?: OpenToOtherDatesChoice
  venueName?: string
  approximateBudget?: string
  averageTicketPrice?: string
  organizationName?: string
  eventName: string
  eventType?: string
  communityEntity?: CommunityEntityResponse
}

async function fetchBookingRequestById(bookingRequestId: string): Promise<FetchBookingRequestByIdResponse> {
  const response = await PoshApi.fetchBookingRequestById(bookingRequestId)
  return response.data
}

export default function useFetchBookingRequestById(bookingRequestId: string) {
  return useQuery(['fetchBookingRequestById', bookingRequestId], () => fetchBookingRequestById(bookingRequestId), {
    enabled: !!bookingRequestId,
    refetchOnMount: false,
  })
}
