import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import {linkRound, whiteCircularPencilUrl} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useCopyLink} from 'hooks/useCopyLink'

import './styles.scss'

interface EventPageToolbarParams {
  eventId: string
  groupId: string
}

export const EventPageToolbar = (params: EventPageToolbarParams) => {
  const {eventId, groupId} = params
  const {trackEvent} = useMixpanel()
  const {copyLink, justCopied} = useCopyLink()
  const navigate = useNavigate()
  const link = window.location.href

  const handleEdit = () => {
    trackEvent('Edit Event Button- New RSVP Event Page')
    const editLink = `/owner/groups/${groupId}/events/${eventId}/overview`
    navigate(editLink)
  }

  return (
    <div className='EventPageToolbar'>
      <div className='EventPageToolbar-item'>
        {justCopied && <span>Link copied!</span>}
        <PoshImage
          className='EventPageToolbar-item link'
          src={linkRound}
          alt='Copy Link'
          onClick={() => {
            trackEvent('Copy Link Button- New RSVP Event Page')
            copyLink(link)
          }}
        />
      </div>
      <div className='EventPageToolbar-item'>
        <PoshImage className='pencil' src={whiteCircularPencilUrl} alt='Edit Event' onClick={handleEdit} />
      </div>
    </div>
  )
}

export default EventPageToolbar
