import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {PaymentStatus} from 'apis/Checkout'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import {OrderTag} from 'apis/Orders'
import {useAggregateOrders} from 'apis/Orders/useAggregateOrders'
import {searchIconUrl} from 'assets/images/s3images'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import TextIconInput from 'components/TextInput'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import useDebounce from 'hooks/useDebounce'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import FilterModal from '../FilterModal'
import OrderDescriptors, {TaggedGroupOrderModel} from '../OrderDescriptors'

interface OrdersListViewProps {
  expanded: boolean
  showFilter?: boolean
}

const OrdersListView = (props: OrdersListViewProps) => {
  const {expanded, showFilter} = props
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {groupId, domain} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId ?? '')
  const currency = group?.currency
  const urlSearch = new URLSearchParams(window.location.search)
  const activeFilterParams = expanded ? urlSearch.get('f') : undefined
  const searchParam = expanded ? urlSearch.get('s') : undefined
  const eventIdSearchParam = expanded ? urlSearch.get('e') : undefined
  const [search, setSearch] = useState<string | undefined>(searchParam ?? undefined)
  const debouncedSearch = useDebounce(search, 500)
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState<OrderTag[]>(
    activeFilterParams ? (activeFilterParams.split(',') as OrderTag[]) : [],
  )
  const navigate = useNavigate()

  const {data: orders, isFetching: isFetchingOrders} = useAggregateOrders({
    expanded,
    activeFilters,
    search: debouncedSearch,
    groupId: groupId ?? '',
    eventId: eventIdSearchParam,
  })

  const handleFilterConfirm = (selected: OrderTag[]) => {
    setActiveFilters(selected)
    setFilterModalIsOpen(false)
  }

  const navigateToOrder = (orderId: string) => {
    if (expanded) navigate(`${orderId}`)
    else navigate(`/${domain}/groups/${groupId}/orders/${orderId}`)
  }

  useEffect(() => {
    if (expanded) setActiveFilters(activeFilterParams ? (activeFilterParams.split(',') as OrderTag[]) : [])
  }, [activeFilterParams])

  return (
    <>
      <FilterModal
        onConfirm={selected => handleFilterConfirm(selected as OrderTag[])}
        isOpen={filterModalIsOpen}
        onClose={() => setFilterModalIsOpen(false)}
        defaultSelected={activeFilters}
      />
      <div className='Orders-toolbar'>
        {showFilter && (
          <RequirePermissions requiredPermissions={[requirePermissionAndScope('filter_orders', ['group'])]}>
            <div onClick={() => setFilterModalIsOpen(true)} className='Orders-toolbar-btn filter-btn'>
              <PoshImage src='https://images.posh.vip/b2/filter.svg' />
              <span>Filter</span>
            </div>
          </RequirePermissions>
        )}
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('search_orders', ['group'])]}>
          <TextIconInput
            icon={searchIconUrl}
            placeholder={`Search (${eventIdSearchParam ? '' : 'Event Name, '}Attendee Name, Email, Order #)`}
            value={search ?? ''}
            onChange={e => setSearch(e.target.value)}
          />
        </RequirePermissions>
      </div>
      <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_orders_list', ['group'])]}>
        {!isFetchingOrders ? (
          <div className='Orders-orderContainer'>
            {orders?.map(order => {
              const poshFee = order.order.feeBreakdown?.find(o => o.type == 'POSH-FEE')
              const displayedOrderTotal = order.order.total - (poshFee?.totalFee ?? 0)
              const paymentPlanIssue =
                order.order.paymentPlan?.map(p => p.status).includes(PaymentStatus.REQUIRES_ACTION) ||
                order.order.paymentPlan?.map(p => p.status).includes(PaymentStatus.FAILED)
              const disputed = order.tags.includes('disputed')
              const orderDescriptorsProp: TaggedGroupOrderModel = {
                ...order,
                type: 'tagged',
              }
              return (
                <a
                  className='Orders-orderLink'
                  key={order.order._id}
                  onClick={() => {
                    trackMixpanelEvent('Order List View Order Click-  Order List Element', {expanded})
                    navigateToOrder(`${order.order._id}`)
                  }}>
                  <div
                    key={order.order._id}
                    className={`Orders-order ${paymentPlanIssue ? 'warning' : ''} ${disputed ? 'error' : ''} ${
                      order.order.kickbackAmount ? 'kickbackOrder' : ''
                    }`}>
                    <div className='Orders-order-info'>
                      <div className='Orders-order-info-avi'>
                        <PoshImage src={order.avi ? order.avi : 'https://images.posh.vip/b2/blank-avi.png'} />
                      </div>
                      <div className='Orders-order-info-info'>
                        <div className='Orders-order-number'>
                          Order #{order.number} - {moment(new Date(order.date)).format('MM/DD/YYYY, h:mm A')}
                          <br />
                          {order.eventName}
                        </div>
                        <div className='Orders-order-ename'></div>
                        <div className={`Orders-order-name ${order.order.kickbackAmount ? 'kickbackOrder' : ''}`}>
                          {order.firstName} {order.lastName}
                        </div>
                        <div className='Orders-order-total'>
                          {getCurrencySymbol(currency)}
                          {displayedOrderTotal.toFixed(2)}
                        </div>
                        <OrderDescriptors order={orderDescriptorsProp} />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </a>
              )
            })}
            {isEmpty(orders) && <div style={{color: 'white'}}>No orders found.</div>}
          </div>
        ) : (
          <div className='Orders-loading'>
            <p className='Orders-fetching'>Fetching Orders...</p>
            <div className='Orders-load'>
              <SpinLoader />
            </div>
          </div>
        )}
      </RequirePermissions>
    </>
  )
}

export default OrdersListView
