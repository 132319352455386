import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'

import {GuestList} from 'apis/Events/useFetchEventGuestlist'
import {ProfilePreviewModalState} from 'pages/EventPage'

interface HookParams {
  isEventDataFetching: boolean
  setProfilePreviewModal: React.Dispatch<React.SetStateAction<ProfilePreviewModalState>>
  guestlist?: GuestList
}

export const useShowProfileFromLink = ({isEventDataFetching, setProfilePreviewModal, guestlist}: HookParams) => {
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (isEventDataFetching) return
    const guestlistUser = searchParams.get('gl')
    const guestlistElement = document.getElementById('guestlist')
    let timeoutOne: NodeJS.Timeout | undefined
    let timeoutTwo: NodeJS.Timeout | undefined
    const profile = guestlist?.find(g => g.attendeeAccountId === guestlistUser)
    if (guestlistUser && guestlistElement && profile) {
      timeoutOne = setTimeout(() => guestlistElement.scrollIntoView({block: 'center'}), 1100)
      timeoutTwo = setTimeout(
        () =>
          setProfilePreviewModal({
            isOpen: true,
            profile: {
              accountId: profile.attendeeAccountId.toString(),
              firstName: profile.attendeeFirstName,
              lastName: profile.attendeeLastName,
              profileAvi: profile.attendeeAvi,
              instagramVerified: profile.accountProfile.instagramVerified,
              poshVerified: profile.accountProfile.poshVerified,
              instagram: profile.accountProfile.instagram,
              twitter: profile.accountProfile.twitter,
            },
          }),
        1500,
      )
    }

    return () => {
      clearTimeout(timeoutOne)
      clearTimeout(timeoutTwo)
    }
  }, [guestlist, isEventDataFetching, searchParams, setProfilePreviewModal])
}
