import React, {forwardRef, HTMLProps} from 'react'

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import {
  EventCategoryOptions as CategoryOptions,
  EventParentCategory,
  eventParentCategory,
  EventParentCategorySubcategories,
} from '@posh/model-types'
import {Accordion, AccordionItem} from '@szhsin/react-accordion'
import {Menu, MenuItem, SubMenu} from '@szhsin/react-menu'
import {useMixpanel} from 'apis/MixPanelHandler'
import {DownChevron} from 'components/assets/Icons'
import Input from 'components/form/Input'
import {useQueryParams} from 'hooks/QueryParams/useQueryParams'
import {useDimensions} from 'hooks/useDimensions'

import './CategorizationDropdown.styles.scss'

interface Props extends HTMLProps<HTMLDivElement> {
  value?: string
}

const CategorizationDropdownButton = forwardRef<HTMLDivElement, Props>(({value, ...props}, ref) => {
  return (
    <div ref={ref} {...props}>
      <Input placeholder='Event Category' value={value} style={{cursor: 'pointer'}} />
      <DownChevron className='CategorizationDropdownButton-chevron' />
    </div>
  )
})

CategorizationDropdownButton.displayName = 'CategorizationDropdownButton'

type Categories<T extends EventParentCategory> = {
  parentCategory: T
  subCategory: EventParentCategorySubcategories<T>[number]
}

type OnChooseCategoryCallback = <T extends EventParentCategory>(params: Categories<T>) => void

type CategorizationDropdownProps = {
  onChooseCategory: OnChooseCategoryCallback
  value?: string
}

export function WithCategorySelectDropdown({
  menuButton,
  onChooseCategory,
}: {
  menuButton: React.ReactElement
  onChooseCategory: OnChooseCategoryCallback
}) {
  const {isMobile} = useDimensions()
  if (isMobile) {
    return (
      <Menu direction='bottom' menuButton={menuButton} align='center'>
        <Accordion transition transitionTimeout={150}>
          {eventParentCategory.map(parentCategory => {
            return (
              <AccordionItem header={parentCategory} key={parentCategory}>
                {CategoryOptions[parentCategory].map(subCategory => {
                  return (
                    <MenuItem
                      className='noMargin center'
                      key={subCategory}
                      onClick={() => onChooseCategory({parentCategory, subCategory})}>
                      {subCategory}
                    </MenuItem>
                  )
                })}
              </AccordionItem>
            )
          })}
        </Accordion>
      </Menu>
    )
  }

  return (
    <Menu direction='right' menuButton={menuButton}>
      {eventParentCategory.map(parentCategory => {
        return (
          <SubMenu label={parentCategory} key={parentCategory}>
            {CategoryOptions[parentCategory].map(subCategory => {
              return (
                <MenuItem
                  key={parentCategory + subCategory}
                  onClick={() => onChooseCategory({parentCategory, subCategory})}>
                  {subCategory}
                </MenuItem>
              )
            })}
          </SubMenu>
        )
      })}
    </Menu>
  )
}

const CategorizationDropdown = (props: CategorizationDropdownProps) => {
  const {value} = props
  const mixpanel = useMixpanel()
  const {setQueryParams} = useQueryParams()
  const onChooseCategory: OnChooseCategoryCallback = <T extends EventParentCategory>(params: Categories<T>) => {
    const {parentCategory, subCategory} = params
    mixpanel.trackEvent('Select Category- Create Event Details Page', {parentCategory, subCategory})
    setQueryParams('parentCategory', parentCategory)
    setQueryParams('subCategory', subCategory)

    props.onChooseCategory({parentCategory, subCategory})
  }

  return (
    <WithCategorySelectDropdown
      menuButton={<CategorizationDropdownButton value={value} />}
      onChooseCategory={onChooseCategory}
    />
  )
}

export default CategorizationDropdown
