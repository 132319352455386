import React from 'react'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'
import ChangePassword from 'pages/OwnerPage/GroupsSelector/AccountSettings/ChangePassword'
import GeneralSettings from 'pages/OwnerPage/GroupsSelector/AccountSettings/GeneralSettings'
import PrivacySettings from 'pages/OwnerPage/GroupsSelector/AccountSettings/PrivacySettings'

import useSessionContext from '../../../domains/Auth/SessionContext'

import './../styles.scss'

const AccountSettings = () => {
  const {currentUser} = useSessionContext()

  if (!currentUser) return <></>

  return (
    <div className='GroupsDash'>
      <div className='GroupsDash-inner'>
        <BackAndTitle title='Account Settings' />
        <div className='GroupsDash-expandableRow'>
          <h6>General Settings</h6>
        </div>
        <GeneralSettings />
        <div className='GroupsDash-expandableRow'>
          <h6>Privacy Settings</h6>
        </div>
        <PrivacySettings />
        <div className='GroupsDash-expandableRow'>
          <h6>Account Security</h6>
        </div>
        <ChangePassword />
      </div>
    </div>
  )
}

export default AccountSettings
