import React from 'react'

import Table from 'components/Table'

import {CSVModalCofig} from './modalConfigs'

interface PreviewTableProps {
  config: CSVModalCofig
  csvData: any
}

export const PreviewTable = (props: PreviewTableProps) => {
  const {config, csvData} = props
  const ROWS = 4
  return (
    <div style={{maxHeight: '290px', overflowY: 'scroll', marginBottom: '30px'}}>
      <Table>
        <thead>
          <tr>
            {config.headers.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {csvData.map((row: any, index: number) => (
            <tr key={index}>
              {Object.values(row)
                .slice(0, ROWS)
                .map((cell: any) => (
                  <td key={cell}>{cell}</td>
                ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
