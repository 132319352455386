import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetEventsForEventPlaylistOptions = ReactQueryOptions['events']['playlists']['manage']['getEventsForEventPlaylist']
export type GetEventsForEventPlaylistInput = RouterInputs['events']['playlists']['manage']['getEventsForEventPlaylist']
export type GetEventsForEventPlaylistOutput = RouterOutput['events']['playlists']['manage']['getEventsForEventPlaylist']

export type TEventForEventPlaylist = GetEventsForEventPlaylistOutput['events'][number]

export function useGetEventsForEventPlaylist(opts?: GetEventsForEventPlaylistOptions) {
  return trpc.events.playlists.manage.getEventsForEventPlaylist.useMutation(opts)
}
