import React, {createContext, PropsWithChildren, useEffect, useMemo, useState} from 'react'

import {AccountRoleResponse} from 'apis/Roles'
import {PermissionResponse} from 'apis/Roles/Permission'

import useFetchAccountRolePermissions from '../../apis/Roles/useFetchAccountRolePermissions'

function useProviderValue() {
  const [accountRolePermissions, setAccountRolePermissions] = useState<PermissionResponse[]>([])
  const [accountRole, setAccountRole] = useState<AccountRoleResponse | null>(null)
  const {data: permissions, isFetching, refetch} = useFetchAccountRolePermissions(accountRole?._id)

  useEffect(() => {
    if (accountRole) {
      refetch()
    }
  }, [accountRole, refetch])

  return useMemo(
    () => ({
      accountRolePermissions,
      setAccountRolePermissions,
      accountRole,
      setAccountRole,
      isFetching,
      permissions,
    }),
    [accountRole, accountRolePermissions, isFetching, permissions],
  )
}

export type PermissionsContext = ReturnType<typeof useProviderValue>

const context = createContext<PermissionsContext | null>(null)
context.displayName = 'PermissionsContext'

export default function usePermissionsContext() {
  const permissionsContext = React.useContext(context)
  if (!permissionsContext) throw 'PermissionsContext must be provided'
  return permissionsContext
}

export const PermissionsModalContextProvider = ({children}: PropsWithChildren<unknown>) => {
  const value = useProviderValue()
  return <context.Provider value={value}>{children}</context.Provider>
}
