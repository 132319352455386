import * as Yup from 'yup'

export const submitSchemaVal = Yup.object().shape({
  reason: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  contact: Yup.string().required('Required'),
  internalNoteForPosh: Yup.string().required('Required'),
})

export const localSchemaVal = Yup.object().shape({
  reason: Yup.string(),
  description: Yup.string(),
  contact: Yup.string(),
  internalNoteForPosh: Yup.string(),
})
