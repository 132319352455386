import React from 'react'

import {FilledChat, FilledPhone, infoButton, Instagram} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {AttendeeCheckBox} from 'pages/GroupPage/Marketing/AttendeeCheckBox'

import './styles.scss'

const Attendees = [
  {
    name: 'Vanessa Hutt',
    tickets: 6,
    spent: 90,
  },
  {
    name: 'Mac Powell',
    tickets: 5,
    spent: 70.5,
  },
  {
    name: 'Jennifer Doe',
    tickets: 3,
    spent: 83.45,
  },
  {
    name: 'Jamal Wilkins',
    tickets: 7,
    spent: 80,
  },
  {
    name: 'Devante Donald',
    tickets: 3,
    spent: 33.48,
  },
  {
    name: 'Shi Song',
    tickets: 9,
    spent: 22.5,
  },
  {
    name: 'Xavier Smith',
    tickets: 3,
    spent: 108,
  },
  {
    name: 'Pamela Anderz',
    tickets: 6,
    spent: 44,
  },
]

export const SmsModalLandingPage = () => {
  return (
    <div className='SectionElementFrameA'>
      <div className='AttendeeListLP'>
        {Attendees.map(attendee => {
          return (
            <div className='AttendeeListLP-attendee' key={attendee.name}>
              <AttendeeCheckBox defaultChecked={true} onChecked={() => null} />
              <div className='AttendeeListLP-attendee-name'>{attendee.name}</div>
              <div className='AttendeeListLP-attendee-info'>{attendee.tickets} tickets</div>
              <div className='AttendeeListLP-attendee-info'>${attendee.spent.toFixed(2)}</div>
              <div className='AttendeeListLP-attendee-socials'>
                <Instagram className='AttendeesTable-icon' />
                <FilledChat className='AttendeesTable-icon' fill={'#c0c1c3'} />
                <FilledPhone className='AttendeesTable-icon' fill={'#c0c1c3'} />
              </div>
              <div className='AttendeeListLP-attendee-open'>
                <PoshImage src={infoButton} />
              </div>
            </div>
          )
        })}
      </div>
      <div className='SmsModalLandingPage'>
        <div className='SmsModal'>
          <div className='TagsModal-contactsSelected'>3,827 attendees selected</div>
          <div className='SmsModal-toolbar'>
            <a className='SmsModal-toolbar-actionButton'>Insert First Name</a>
            <a className='SmsModal-toolbar-actionButton'>Attach Event Link</a>
          </div>
          <div className='SmsModal-textArea'>
            <div className='SmsModal-textArea-gname'>Diamond Events:</div>
            <textarea value={''} onChange={() => null} placeholder={'Enter your message here...'} maxLength={160} />
          </div>
          <div className='SmsModal-textCount'>(7/160)</div>
          <Button className='fullWidth'>Send Text Blast</Button>
          <div className='SmsModal-customNumber'>Custom Number Enabled &mdash; +19399292848</div>
        </div>
      </div>
    </div>
  )
}

export default SmsModalLandingPage
