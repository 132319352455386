import React from 'react'

import {useFetchAllEventTrackingLinks} from 'apis/Events/useFetchAllEventTrackingLinks'
import Multiselect from 'multiselect-react-dropdown'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import MatchAllInput from './matchAllInput'
import multiSelectStyles from './multi-select-styles'

import './styles.scss'

interface TrackingLinkFilterProps {
  selectedTrackingLinks: string[] | undefined
  setTrackingLinks: React.Dispatch<React.SetStateAction<string[]>>
  matchAllTrackingLinks: boolean
  setMatchAllTrackingLinks: React.Dispatch<React.SetStateAction<boolean>>
}
interface SelectOption {
  name: string
  id: string
}

const TrackingLinkFilters = (props: TrackingLinkFilterProps) => {
  const {selectedTrackingLinks, setTrackingLinks, matchAllTrackingLinks, setMatchAllTrackingLinks} = props
  const {eventId, groupId} = useResourcePageParams()
  const {data: trackingLinks} = useFetchAllEventTrackingLinks(eventId!, groupId!)

  const handleUpdate = (value: SelectOption[]) => {
    const trackingLinkIds = value.map(trackingLink => {
      return trackingLink.name
    })
    setTrackingLinks(trackingLinkIds)
  }

  const preSelectedTrackingLinks: SelectOption[] = []

  selectedTrackingLinks?.map(t => {
    const trackingLink = trackingLinks?.find(trackingLink => trackingLink == t)
    if (trackingLink) preSelectedTrackingLinks.push({name: trackingLink, id: trackingLink})
  })

  const options: SelectOption[] | undefined = trackingLinks?.map(trackingLink => {
    return {name: trackingLink, id: trackingLink}
  })

  return (
    <div className='DataFiltersSection'>
      {trackingLinks && trackingLinks.length > 0 ? (
        <>
          <div className='FilterModal-topRow'>
            <h6>Filter By Tracking Links</h6>
            <MatchAllInput setMatchAll={setMatchAllTrackingLinks} matchAll={matchAllTrackingLinks} />
          </div>
          <Multiselect
            selectedValues={preSelectedTrackingLinks}
            options={options} // Options to display in the dropdown
            onSelect={value => handleUpdate(value)} // Function will trigger on select event
            onRemove={value => handleUpdate(value)} // Function will trigger on remove event
            displayValue='name' // Property name to display in the dropdown options
            style={multiSelectStyles}
            closeOnSelect={false}
            placeholder={'Select Tracking Links'}
          />
        </>
      ) : (
        <p>You haven&apos;t created any tracking links yet. Do it here...</p>
      )}
    </div>
  )
}

export default TrackingLinkFilters
