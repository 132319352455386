import {useQuery} from '@tanstack/react-query'
import {EventPublic} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

export async function fetchPastEvents(username: string): Promise<EventPublic[] | null> {
  const response = await PoshApi.fetchPastEvents(username)
  const data = response.data
  return data.pastEventsResponse
}

export function useFetchPastEvents(username: string) {
  return useQuery(['pastEvents', username], () => fetchPastEvents(username), {
    enabled: !!username,
  })
}
