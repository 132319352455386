import {useMutation} from '@tanstack/react-query'

import PoshApi from '../PoshApi'

export interface CheckEventPasswordProps {
  password: string
  eventId: string
}

async function checkEventPassword(props: CheckEventPasswordProps): Promise<boolean> {
  const response = await PoshApi.checkEventPassword(props)
  return response.data
}

export function useCheckEventPassword() {
  return useMutation((params: CheckEventPasswordProps) => checkEventPassword(params))
}
