import React from 'react'

import {Services} from 'components/assets/Icons'

import './TipBadge.styles.scss'

interface TipBadgeProps {
  text: string
  type: IconType
  color?: Color
  glowing?: boolean
}

type IconType = 'services' | 'none'

type Color = 'primary' | 'secondary' | 'grey'

export const TipBadge = (props: TipBadgeProps) => {
  const {text, type, color = 'secondary', glowing} = props
  const icon = getIconByType(type)
  return (
    <div className={`TipBadge ${color} ${glowing ? 'glowing' : ''}`}>
      {icon}
      <div className='TipBadge-text'>{text}</div>
    </div>
  )
}

const getIconByType = (type: IconType) => {
  if (type === 'services') {
    return <Services className={`TipBadge-icon`} />
  } else if (type === 'none') {
    return null
  }
}
