// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrganizationSecondary__rBhlr{display:grid;gap:10px;margin-top:10px}", "",{"version":3,"sources":["webpack://./src/components/Navigation/Primary/components/desktop/OrganizationsSecondary/styles.module.scss"],"names":[],"mappings":"AAAA,8BACI,YAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".OrganizationSecondary {\n    display: grid;\n    gap: 10px;\n    margin-top: 10px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OrganizationSecondary": "OrganizationSecondary__rBhlr"
};
export default ___CSS_LOADER_EXPORT___;
