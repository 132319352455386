import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export async function checkVerificationCode(token: string): Promise<boolean> {
  const response = await PoshApi.checkVerificationCode(token)
  return response.data.success
}

export function useCheckVerificationCode() {
  return useMutation((params: {token: string}) => checkVerificationCode(params.token))
}
