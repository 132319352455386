import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetServiceBookingsOptions = ReactQueryOptions['services']['getServiceBookings']
type GetServiceBookingsInput = RouterInputs['services']['getServiceBookings']
export type GetServiceBookingsOutput = RouterOutput['services']['getServiceBookings']

export type TBooking = GetServiceBookingsOutput['bookings'][number]
export type TBookingStatus = TBooking['status']
export type TBookingProvider = TBooking['provider']
export type TBookingType = TBooking['type']

export function useGetServiceBookings(input: GetServiceBookingsInput, opts?: GetServiceBookingsOptions) {
  return trpc.services.getServiceBookings.useQuery(input, opts)
}
