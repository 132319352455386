import React from 'react'

import {PlusSolid} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {useNavigateToCreateEvent} from 'pages/CreateEvent/useNavigateToCreateEvent'

export interface HeaderContextButtonProps {
  content: React.ReactNode
  onClick: () => void
}

export function useGetDefaultContextButton(): HeaderContextButtonProps {
  const {navigateToCreateEvent} = useNavigateToCreateEvent()
  return {
    content: (
      <>
        <PlusSolid />
        Create Event
      </>
    ),
    onClick: () => navigateToCreateEvent({optimizelyEntryPoint: 'Navigation Bar Header', useSearchParams: true}),
  }
}

export function HeaderContextButton(props: HeaderContextButtonProps) {
  const {content, onClick} = props
  return (
    <Button className='poshBtn gold' onClick={onClick}>
      {content}
    </Button>
  )
}
