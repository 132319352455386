import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import PoshSwitch from 'components/form/PoshSwitch'

import {InputContainer} from '../shared/InputContainer/InputContainer'
import {ControlledFormProps} from '../shared/types'

export interface SwitchProps {
  value: boolean
  onChange: (value: boolean) => void
  error?: string
  className?: string
}

export const Switch = (props: SwitchProps) => {
  const {value, onChange, error, ...rest} = props

  return (
    <InputContainer error={error}>
      <PoshSwitch
        {...rest}
        switchOptions={{
          checked: value,
          onChange: onChange,
        }}
      />
    </InputContainer>
  )
}

interface ControlledSwitchProps<T extends FieldValues, K extends Path<T>>
  extends Omit<SwitchProps, 'value' | 'onChange' | 'error'>,
    ControlledFormProps<T, K> {}

const ControlledSwitch = <T extends FieldValues, K extends Path<T>>(props: ControlledSwitchProps<T, K>) => {
  const {control, name, rules, ...CheckboxGroupProps} = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState, formState: _formState}) => {
        return <Switch {...field} error={fieldState.error?.message} {...CheckboxGroupProps} />
      }}
    />
  )
}

Switch.Controlled = ControlledSwitch
