import React, {PropsWithChildren} from 'react'

import {BlurBackgroundModal, BlurBackgroundModalProps} from 'components/coreComponents/Modal/variants/BlurBackground'
import {GlassView, GlassViewProps} from 'components/GlassView'

interface GlassModalProps extends BlurBackgroundModalProps, GlassViewProps {}

export function GlassModal({children, className, ...props}: PropsWithChildren<GlassModalProps>) {
  return (
    <BlurBackgroundModal.Centered {...props}>
      <GlassView {...props} className={className}>
        {children}
      </GlassView>
    </BlurBackgroundModal.Centered>
  )
}
