import React from 'react'

import {Breadcrumb, Breadcrumbs} from '../Breadcrumbs'

import styles from './styles.module.scss'

type Title = string | ((h1ClassName: string) => React.ReactNode)

export interface PageTitleProps {
  title: Title
  breadcrumbs?: Breadcrumb[]
  maxBreadcrumbs?: number
}

function TitleComponent(props: {title: Title}) {
  if (typeof props.title === 'string') return <h1 className={styles.title}>{props.title}</h1>
  return <>{props.title(styles.title)}</>
}

export function PageTitle(props: PageTitleProps) {
  const {title, breadcrumbs = [], maxBreadcrumbs} = props

  return (
    <div className={styles.PageTitle}>
      <TitleComponent title={title} />
      {props.breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} maxBreadcrumbs={maxBreadcrumbs} />}
    </div>
  )
}
