import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import {PoshImage} from 'components/PoshImage/PoshImage'

import {useNavigateToCreateEvent} from '../../../CreateEvent/useNavigateToCreateEvent'

interface SignupSecondStepProps {
  referredBy?: string | null
}

export const SignupSecondStep = (props: SignupSecondStepProps) => {
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {navigateToCreateEvent} = useNavigateToCreateEvent()
  const {referredBy} = props
  const handleCreateGroupReroute = () => {
    trackMixpanelEvent('I want to create an event button-  Sign up routing page')
    if (referredBy) {
      navigate(`/create_group?t=${referredBy}`)
    } else {
      navigateToCreateEvent({optimizelyEntryPoint: 'Signup Second Step'})
    }
  }
  return (
    <>
      <div className='OnboardPage-navOptions'>
        <div onClick={() => handleCreateGroupReroute()}>
          <PoshImage src='https://images.posh.vip/b2/magic-wand.svg' />
          <span>I want to create an event</span>
        </div>
        <a href={`/explore`}>
          <div>
            <PoshImage src='https://images.posh.vip/b2/tickets+(3).svg' />
            <span>I want to attend events</span>
          </div>
        </a>
      </div>
    </>
  )
}
