import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {PermissionResponse} from './Permission'

async function fetchAccountRolePermissions(accountRoleId: string): Promise<PermissionResponse[]> {
  const response = await PoshApi.fetchAccountRolePermissions(accountRoleId)
  const data = response.data
  return data.permissions
}

export default function useFetchAccountRolePermissions(accountRoleId?: string) {
  return useQuery(
    ['accountRolePermissions', accountRoleId],
    () => {
      if (!accountRoleId) {
        return
      }
      return fetchAccountRolePermissions(accountRoleId)
    },
    {
      enabled: false,
    },
  )
}
