import React from 'react'

import classNames from 'classnames'
import {PropsWithClassName} from 'helpers/props'

import styles from './Text.module.scss'

interface BaseTextProps {
  children: string | React.ReactNode
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl'
  bold?: boolean
  italic?: boolean
  muted?: boolean
  style?: React.CSSProperties
}

export type TextProps = PropsWithClassName<BaseTextProps>

/**
 * A Text component (used mainly for Analytics)
 * @param props
 * @param props.className A class name to override styles of the Text
 * @param props.children The text to display
 * @param props.size The size of the text
 * @param props.bold Whether the text should be bold
 * @param props.italic Whether the text should be italic
 * @param props.muted Whether the text should be muted
 */
export const Text = (props: TextProps) => {
  const {className, children, size = 'medium', bold = false, italic = false, muted = false, style} = props

  return (
    <span
      className={classNames(
        styles.Text,
        styles[size],
        {[styles.bold]: bold},
        {[styles.italic]: italic},
        {[styles.muted]: muted},
        className,
      )}
      style={style}>
      {children}
    </span>
  )
}

const TextLoading = (props: Pick<TextProps, 'size'> & {width?: number}) => {
  const {size = 'medium', width = 50} = props
  return <div className={classNames(styles.TextLoading, styles[size])} style={{width}} />
}

Text.Loading = TextLoading
