import React, {HTMLAttributes} from 'react'

import {DEFAULT_ACCOUNT_AVI} from '@posh/model-types'
import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'

const UserListPreviewUser = ({
  imageSrc,
  alt,
  className,
  ...rest
}: {imageSrc: string; alt?: string} & HTMLAttributes<HTMLImageElement>) => {
  return (
    <PoshImage
      {...rest}
      className={classNames('UserListPreview-user', className)}
      src={imageSrc}
      alt={alt}
      onError={e => (e.currentTarget.src = DEFAULT_ACCOUNT_AVI)}
    />
  )
}

export default UserListPreviewUser
