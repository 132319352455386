// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PartyFoulContainer__8KoSf{width:100%;height:100%;display:flex;flex-direction:column;padding-top:100px;align-items:center}.PartyFoulContainer__8KoSf .ButtonFowlContainer__OsMmt{width:200px;height:300px;display:flex;flex-direction:column;justify-content:flex-end;position:relative}.PartyFoulContainer__8KoSf .ButtonFowlContainer__OsMmt .FowlImage__-W12O{position:absolute;height:250px;bottom:20px;left:50%;transform:translateX(-45%);pointer-events:none;z-index:2}.PartyFoulContainer__8KoSf .ButtonFowlContainer__OsMmt .BirthdayHat__dpPUZ{width:50px;position:absolute;bottom:187px;left:210px;transform:rotateZ(20deg);pointer-events:none;z-index:3}", "",{"version":3,"sources":["webpack://./src/pages/PageNotFound/PartyFoul/PartyFoul.module.scss"],"names":[],"mappings":"AAAA,2BACE,UAAA,CACA,WAAA,CAEA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,kBAAA,CAEA,uDACE,WAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,wBAAA,CACA,iBAAA,CAEA,yEACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,QAAA,CACA,0BAAA,CACA,mBAAA,CACA,SAAA,CAGF,2EACE,UAAA,CACA,iBAAA,CACA,YAAA,CACA,UAAA,CACA,wBAAA,CACA,mBAAA,CACA,SAAA","sourcesContent":[".PartyFoulContainer {\n  width: 100%;\n  height: 100%;\n\n  display: flex;\n  flex-direction: column;\n  padding-top: 100px;\n  align-items: center;\n\n  .ButtonFowlContainer {\n    width: 200px;\n    height: 300px;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n    position: relative;\n\n    .FowlImage {\n      position: absolute;\n      height: 250px;\n      bottom: 20px;\n      left: 50%;\n      transform: translateX(-45%);\n      pointer-events: none;\n      z-index: 2;\n    }\n\n    .BirthdayHat {\n      width: 50px;\n      position: absolute;\n      bottom: 187px;\n      left: 210px;\n      transform: rotateZ(20deg);\n      pointer-events: none;\n      z-index: 3;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PartyFoulContainer": "PartyFoulContainer__8KoSf",
	"ButtonFowlContainer": "ButtonFowlContainer__OsMmt",
	"FowlImage": "FowlImage__-W12O",
	"BirthdayHat": "BirthdayHat__dpPUZ"
};
export default ___CSS_LOADER_EXPORT___;
