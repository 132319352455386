import {TEventStatus} from '@posh/server/dist/src/models/event'
import {Ticket} from 'apis/Events/types'
import {isEmpty} from 'lodash'
import moment from 'moment'

export const calculateEventStatus = (eventEndDate?: moment.Moment, status?: string): TEventStatus => {
  if (!eventEndDate) return 'incomplete'
  if (status === 'draft') return 'draft'
  const date = moment.utc().format()
  const today = moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
  if (eventEndDate.isBefore(today)) {
    return 'ended'
  } else if (eventEndDate.isAfter(today)) {
    return 'live'
  } else {
    return 'incomplete'
  }
}

export const isTicketOnSale = (t: Ticket, timezone: string) => {
  const date = moment.tz(timezone ?? 'America/New_York')
  const today = moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
  const endSale = moment.tz(t.endSaleUtc, timezone ?? 'America/New_York')
  const startSale = moment.tz(t.onSaleUtc, timezone ?? 'America/New_York')

  if (t.closed || (!isEmpty(t.endSaleUtc) && endSale.isBefore(today))) return 0
  else if (t.isHidden) return 3
  else if (
    !isEmpty(t.onSaleUtc) &&
    startSale.isAfter(today) &&
    (isEmpty(t.endSaleUtc) || (!isEmpty(t.endSaleUtc) && endSale.isAfter(today)))
  )
    return 2
  else return 1
}
