import React from 'react'

import {Tag} from 'apis/Groups'
import Multiselect from 'multiselect-react-dropdown'

import MatchAllInput from './matchAllInput'
import multiSelectStyles from './multi-select-styles'

import './styles.scss'

interface TagFilterProps {
  tags: Tag[]
  selectedTags: string[] | undefined
  setTags: React.Dispatch<React.SetStateAction<string[]>>
  matchAllTags: boolean
  setMatchAllTags: React.Dispatch<React.SetStateAction<boolean>>
}
interface SelectOption {
  name: string
  id: string
}

const TagFilters = (props: TagFilterProps) => {
  const {tags, selectedTags, setTags, matchAllTags, setMatchAllTags} = props

  const preSelectedTags: SelectOption[] = []
  selectedTags?.map(selectedtag => {
    const tag = tags.find(tag => tag._id == selectedtag)
    if (tag) preSelectedTags.push({name: tag.name, id: tag._id})
  })

  const handleUpdate = (value: SelectOption[]) => {
    const tagIds = value.map(tag => {
      return tag.id
    })
    setTags(tagIds)
  }
  const options: SelectOption[] = tags.map(tag => {
    return {name: tag.name, id: tag._id}
  })

  return (
    <div className='DataFiltersSection'>
      {tags && tags.length > 0 ? (
        <>
          <div className='FilterModal-topRow'>
            <h6>Filter By Tags</h6>
            <MatchAllInput setMatchAll={setMatchAllTags} matchAll={matchAllTags} />
          </div>
          <Multiselect
            selectedValues={preSelectedTags}
            options={options} // Options to display in the dropdown
            onSelect={value => handleUpdate(value)} // Function will trigger on select event
            onRemove={value => handleUpdate(value)} // Function will trigger on remove event
            displayValue='name' // Property name to display in the dropdown options
            style={multiSelectStyles}
            closeOnSelect={false}
            placeholder={'Select Tags'}
          />
        </>
      ) : (
        <p>Create tags for your attendees to start filtering by them!</p>
      )}
    </div>
  )
}

export default TagFilters
