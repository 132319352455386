import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {Recipient} from '.'

export async function fetchTextBlastStatus(groupId: string, blastId: string): Promise<Recipient[]> {
  const response = await PoshApi.fetchTextBlastStatus(groupId, blastId)
  return response.data.textBlast
}

export function useFetchTextBlastStatus(groupId?: string, blastId?: string) {
  return useQuery(['textBlast', groupId, blastId], () => fetchTextBlastStatus(groupId ?? '', blastId ?? ''), {
    enabled: !!groupId && !!blastId,
  })
}
