import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'
import {motion} from 'framer-motion'

import styles from './styles.module.scss'

const EventVisualsRow = (props: PropsWithChildren) => {
  return <div className={styles.VisualEditorRow}>{props.children}</div>
}
const EventVisualsColumn = (props: PropsWithChildren) => {
  return <div className={styles.VisualEditorColumn}>{props.children}</div>
}

export const EventVisuals = (props: PropsWithChildren & {className?: string}) => {
  return (
    <motion.div
      animate={{opacity: 1, transition: {duration: 0.5}}}
      initial={{opacity: 0}}
      exit={{opacity: 0}}
      className={classNames([styles.VisualEditor, props.className])}>
      {props.children}
    </motion.div>
  )
}

EventVisuals.Content = EventVisuals
EventVisuals.Row = EventVisualsRow
EventVisuals.Column = EventVisualsColumn
