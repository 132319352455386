import React from 'react'
import QRCode from 'react-qr-code'
import {useParams} from 'react-router-dom'

import Page from 'components/Page'

import './styles.scss'

export default function OrderQrCodePage() {
  const {orderId} = useParams()
  return (
    <Page className='OrderQrCodePage'>
      <div className='OrderQrCodePage-container'>
        <QRCode value={`https://posh.vip/scan-ticket/${orderId}`} bgColor='rgba(0,0,0,0)' fgColor='#fc0' size={260} />
      </div>
    </Page>
  )
}
