import React from 'react'

import useFetchEventAttendeeLocations from 'apis/Events/useFetchEventAttendeeLocations'
import {WithToolTip} from 'components/form/PoshInfo'
import {Icon} from 'components/TableUI/TableIcons/TableIcon'

import {CrossSection} from '../../CrossSection/CrossSection'
import {Card} from '../../Shared/Card/Card'
import {Text} from '../../Shared/Text/Text'

import styles from './AttendeeLocation.module.scss'

interface AttendeeLocationProps {
  eventId: string
  isRSVPEvent?: boolean
}

export const AttendeeLocation = (props: AttendeeLocationProps) => {
  const {eventId, isRSVPEvent} = props

  const {
    data: eventAttendeeData,
    isLoading: isEventAttendeeDataLoading,
    isError: isEventAttendeeDataError,
    isSuccess: isEventAttendeeDataSuccess,
  } = useFetchEventAttendeeLocations(eventId)

  return (
    <CrossSection>
      <CrossSection.Row style={{marginBottom: 15}}>
        <CrossSection.Title>Attendee Location</CrossSection.Title>
        <WithToolTip
          align='left'
          info={`Where your attendees are located, calculated by where their ${
            isRSVPEvent ? 'RSVP' : 'purchase'
          } was made.`}>
          <CrossSection.Icon name='info' />
        </WithToolTip>
      </CrossSection.Row>
      <div className={styles.AttendeeLocationList}>
        {isEventAttendeeDataLoading && (
          <>
            {[1, 2, 3].map(index => (
              <CrossSection.Row key={index}>
                <Card className={styles.AttendeeLocationCard}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text.Loading width={150} />
                    <Text.Loading width={30} />
                  </div>
                </Card>
              </CrossSection.Row>
            ))}
          </>
        )}

        {isEventAttendeeDataError && (
          <>
            <Text size='xl' bold>
              Error
            </Text>
            <Text>{`Something went wrong fetching attendee location data.`}</Text>
            <Text>Contact support@posh.vip for help.</Text>
          </>
        )}

        {isEventAttendeeDataSuccess && (
          <>
            {eventAttendeeData.length > 0 ? (
              <>
                {eventAttendeeData.map((location, index) => (
                  <CrossSection.Row key={index}>
                    <Card className={styles.AttendeeLocationCard}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text>{location.city ?? ''}</Text>
                        <Text>{location.attendees?.toString() ?? ''}</Text>
                      </div>
                    </Card>
                  </CrossSection.Row>
                ))}
              </>
            ) : (
              <CrossSection.Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: 150,
                    gap: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Icon name='map' width={60} height={60} color={'white'} />
                  <Text size='large'>No attendee location available</Text>
                </div>
              </CrossSection.Row>
            )}
          </>
        )}
      </div>
    </CrossSection>
  )
}
