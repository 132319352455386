import React from 'react'

import {PoshLogo} from 'components/PoshLogo'

import './styles.scss'

const PoshLoaderTransition = ({
  pageIsChanging,
  fadeOutTransition,
}: {
  pageIsChanging: boolean
  fadeOutTransition: boolean
}) => {
  if (!pageIsChanging) return <></>
  return (
    <div className={`PoshLoaderTransition ${fadeOutTransition ? 'fadeOutTransition' : ''}`}>
      <PoshLogo size='lg' />
    </div>
  )
}

export default PoshLoaderTransition
