import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface FetchHostByTrackingLinkResponse {
  name: string
  avi: string
}

export async function fetchHostByTrackingLink(trackingLink: string): Promise<FetchHostByTrackingLinkResponse> {
  const response = await PoshApi.fetchHostByTrackingLink(trackingLink)
  const data = response.data
  return data
}

export function useFetchHostByTrackingLink(trackingLink: string) {
  return useQuery(['host', trackingLink], () => fetchHostByTrackingLink(trackingLink), {
    enabled: !!trackingLink,
  })
}
