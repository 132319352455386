import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type SearchForEventsOptions = ReactQueryOptions['search']['searchForEvents']
type SearchForEventsInput = RouterInputs['search']['searchForEvents']
export type SearchForEventsOutput = RouterOutput['search']['searchForEvents']['events'][0]

export function useSearchForEvents(input: SearchForEventsInput, opts?: SearchForEventsOptions) {
  return trpc.search.searchForEvents.useQuery(input, opts)
}

export function useSearchForInfiniteEvents(
  input: SearchForEventsInput,
  opts?: Pick<SearchForEventsOptions, 'enabled'>,
) {
  const res = trpc.search.searchForEvents.useInfiniteQuery(input, {
    getNextPageParam: lastPage => lastPage.nextCursor,
    ...opts,
  })
  const events = res.data?.pages.flatMap(page => page.events) ?? []
  return {
    ...res,
    events,
  }
}
