import React, {CSSProperties} from 'react'
import {Doughnut} from 'react-chartjs-2'

import {ArcElement, Legend, Tooltip} from 'chart.js'
import Chart from 'chart.js/auto'

import './styles.scss'

interface Props {
  id: string
  labels: string[]
  data: number[]
  bgColors: string[]
  canvasStyle: CSSProperties
}

export default function DoughnutGraph(props: Props) {
  const {bgColors, data, labels} = props
  const graphData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: bgColors,
        hoverBorderColor: bgColors,
        borderWidth: 0,
        hoverBorderWidth: 4,
        hoverOffset: 24,
      },
    ],
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      cutoutPercentage: 50,
    },
    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 30,
        bottom: 30,
      },
      width: 'fit-content',
    },
  }

  Chart.register(ArcElement, Tooltip, Legend)

  return <Doughnut data={graphData} options={options} style={props.canvasStyle} />
}
