import React, {ComponentType, PropsWithChildren} from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'

import Button from '../Button'

import './styles.scss'
export type IconType = string | ComponentType

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconType
}

export default function IconButton(props: PropsWithChildren<Props>) {
  const iconElement = typeof props.icon === 'string' ? <PoshImage src={props.icon} /> : <props.icon />

  return (
    <Button {...props} className={props.className}>
      <span className='icon VerticalButton'>{iconElement}</span>
      {props.children && <span>{props.children}</span>}
    </Button>
  )
}
