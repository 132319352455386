import React from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'
import {PoshLogo} from 'components/PoshLogo'

import './styles.scss'

const MobileAppLanding = () => {
  return (
    <>
      <div className='MALWrapper'>
        <PoshLogo.Link />
        <div className='MAL-CTAs'>
          <a href='https://apps.apple.com/us/app/posh-curator/id1556928106'>
            <PoshImage src='https://images.posh.vip/b3/app-ctas/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.posh.mobile&hl=en_US'>
            <PoshImage src='https://images.posh.vip/b3/app-ctas/google-play-badge.png' />
          </a>
        </div>
      </div>
    </>
  )
}

export default MobileAppLanding
