import {ReactComponent as Wordmark} from '../../../../assets/branding/logos/posh-wordmark.svg'

type LogoColor = 'white' | 'black'
const DEFAULT_LOGO_COLOR: LogoColor = 'white'
type LogoSize = 'sm' | 'md' | 'lg'
const DEFAULT_LOGO_SIZE: LogoSize = 'md'

const logoSizes: Record<LogoSize, number> = {
  sm: 24,
  md: 48,
  lg: 96,
}

export interface PoshWordmarkProps {
  color?: LogoColor
  size?: LogoSize
}

export function PoshWordmark({color = DEFAULT_LOGO_COLOR, size = DEFAULT_LOGO_SIZE}: PoshWordmarkProps) {
  const height = logoSizes[size]
  return Wordmark({color, height})
}
