import React from 'react'
import {useNavigate} from 'react-router-dom'

import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

const FraudDashboard = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackAndTitle title={'Posh Fraud Prevention'} />

      <div className='AdminDashboard-menu'>
        <div onClick={() => navigate('/admin/fraud/orders')}>Recent Orders over $300</div>
        <div onClick={() => navigate('/admin/fraud/ticket-types')}>Recent Ticket Types over $200</div>
        <div onClick={() => navigate('/admin/fraud/groups')}>Un-verfied Groups Over $500 GMV This Week</div>
      </div>
    </>
  )
}

export default FraudDashboard
