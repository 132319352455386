import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {useFetchEventByUrl} from 'apis/Events/useFetchEvent'

interface CuratorPolicyFormProps {
  setHasAgreedToCuratorTos: React.Dispatch<React.SetStateAction<boolean>>
}

const CuratorPolicyForm = (props: CuratorPolicyFormProps) => {
  const {setHasAgreedToCuratorTos} = props
  const {eventUrl} = useParams<{eventUrl: string}>()
  const {data: eventsResp} = useFetchEventByUrl(eventUrl)
  const [hasAgreed, setHasAgreed] = useState(true)

  useEffect(() => {
    setHasAgreedToCuratorTos(hasAgreed)
  }, [hasAgreed])

  return (
    <div className='CheckoutPage-CheckboxTOS'>
      <input type='checkbox' checked={hasAgreed} onChange={() => setHasAgreed(!hasAgreed)} />
      <p>
        {`I agree to ${eventsResp?.event.name}'s `}
        <a target='_blank' href={`/e/${eventUrl}/tos`} rel='noreferrer'>
          Terms of Service
        </a>
        .
      </p>
    </div>
  )
}

export default CuratorPolicyForm
