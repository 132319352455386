import React, {useEffect, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {GalleryImage} from 'apis/Community'
import useUpdateArtist from 'apis/Community/useUpdateArtist'
import useUpdateStaffMember from 'apis/Community/useUpdateStaffMember'
import useUpdateVenue from 'apis/Community/useUpdateVenue'
import {uploadImage} from 'apis/Util/useUploadImage'
import GalleryEditor from 'components/form/GalleryEditor'
import {PictureState} from 'components/form/ImagePicker'
import Input from 'components/form/Input'
import {isNull, isUndefined} from 'lodash'
import SaveDiscardBottomBar from 'pages/EventManagementPages/SaveDiscardBottomBar'
import {SettingInput, SettingLocationInput} from 'pages/GroupPage/Profile'

import {EntityDetails, getFeeName} from '../dashboardHelpers'
import ListingTags from './ListingTags'

import './styles.scss'

interface ErrorMessage {
  [key: string]: string
}

interface EditListingProps {
  entityDetails: EntityDetails
}

const EditListing = (props: EditListingProps) => {
  const {entityDetails} = props
  const queryClient = useQueryClient()
  const [settings, setSettings] = useState<EntityDetails>(entityDetails)
  const [showSave, setShowSave] = useState(false)
  const {mutateAsync: updateArtist} = useUpdateArtist()
  const {mutateAsync: updateStaffMember} = useUpdateStaffMember()
  const {mutateAsync: updateVenue} = useUpdateVenue()
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({})
  const galleryPictureUrls = entityDetails?.pictures?.map(im => ({file: null, url: im})) ?? []
  const [gallery, setGallery] = useState<PictureState[]>(galleryPictureUrls)

  const handleDiscard = () => {
    setSettings({...entityDetails})
    setGallery(galleryPictureUrls)
    setShowSave(false)
  }

  const handleSave = async () => {
    let filteredImages: GalleryImage[] | undefined = undefined

    if (gallery.length !== entityDetails.pictures?.length) {
      const galleryImagePromises = gallery.map(async image => {
        if (image.file) return await uploadImage(image.file)
        else return image.url
      })

      const images = await Promise.all(galleryImagePromises)
      filteredImages = images.map(image => {
        if (!isNull(image) && !isUndefined(image)) {
          return {url: image}
        }
      }) as GalleryImage[]
    }

    try {
      if (entityDetails.id) {
        switch (entityDetails.type) {
          case 'artist':
            await updateArtist({
              id: entityDetails.id,
              bio: settings.bio,
              artistName: settings.title,
              location: settings.location,
              coordinates: settings.coordinates,
              email: settings.email,
              baseBookingFee: settings.fee,
              tags: settings.tags,
              spotifyUrl: settings.spotifyUrl,
              website: settings.website,
              galleryImages: filteredImages,
            })
            break
          case 'staff':
            const firstName = settings.title?.split(' ')[0]
            const lastName = settings.title?.split(' ').slice(1).join(' ')
            await updateStaffMember({
              id: entityDetails.id,
              bio: settings.bio,
              firstName,
              lastName,
              location: settings.location,
              coordinates: settings.coordinates,
              email: settings.email,
              hourlyRate: settings.fee,
              tags: settings.tags,
              website: settings.website,
              yearsOfExperience: settings.yearsOfExperience,
              galleryImages: filteredImages,
              minimumPrice: settings.minimumPrice,
            })
            break
          case 'venue':
            await updateVenue({
              id: entityDetails.id,
              description: settings.bio,
              name: settings.title,
              address: settings.location,
              coordinates: settings.coordinates,
              email: settings.email,
              baseBuyout: settings.fee,
              tags: settings.tags,
              website: settings.website,
              maxCapacity: settings.capacity,
              galleryImages: filteredImages,
            })
            break
          default:
            break
        }
      }
      queryClient.invalidateQueries(['fetchCommunityEntities'])
      setShowSave(false)
    } catch (error) {
      const message = error.data.response.error
      console.log(error)
      setErrorMessage({onSave: message})
      console.log(message)
    }
  }

  useEffect(() => {
    if (
      entityDetails.title !== settings.title ||
      entityDetails.bio !== settings.bio ||
      entityDetails.email !== settings.email ||
      entityDetails.website !== settings.website ||
      entityDetails.instagramName !== settings.instagramName ||
      entityDetails.spotifyUrl !== settings.spotifyUrl ||
      entityDetails.location !== settings.location ||
      entityDetails.fee !== settings.fee ||
      entityDetails.capacity !== settings.capacity ||
      entityDetails.tags?.length !== settings.tags?.length ||
      gallery.length !== entityDetails?.pictures?.length ||
      entityDetails.yearsOfExperience !== settings.yearsOfExperience ||
      entityDetails.minimumPrice !== settings.minimumPrice
    ) {
      setShowSave(true)
    }
  }, [settings, gallery])

  return (
    <>
      <div className='EditListing'>
        <Input
          style={{fontSize: '28px'}}
          type='text'
          value={settings.title ?? entityDetails.title}
          onChange={e => setSettings({...settings, title: e.target.value})}
        />
        {errorMessage.onSave && <div className='Error'>{errorMessage.onSave}</div>}
        <hr />
        <table className='SettingsTable'>
          <h3>Contact Info</h3>
          <SettingInput
            title={'Email'}
            placeHolder={'Email Address'}
            value={settings.email ?? entityDetails?.email}
            onChangeFunc={e => setSettings({...settings, email: e.target.value})}
          />
        </table>
        <hr />
        <table className='SettingsTable'>
          <h3>Profile Info</h3>
          <SettingInput
            title={entityDetails.type === 'venue' ? 'Description' : 'Biography'}
            placeHolder={entityDetails.type === 'venue' ? 'Description' : 'Biography'}
            value={settings?.bio ?? entityDetails?.bio}
            onChangeFunc={e => setSettings({...settings, bio: e.target.value})}
            className='Large'
          />
          <SettingInput
            title={'Website'}
            placeHolder={'https://www.whatstheword.vip'}
            value={settings.website ?? entityDetails?.website}
            onChangeFunc={e => setSettings({...settings, website: e.target.value})}
          />
          <SettingInput
            title={'Instagram'}
            placeHolder={'Instagram Username'}
            value={settings.instagramName ?? entityDetails?.instagramName}
            onChangeFunc={e => setSettings({...settings, instagramName: e.target.value})}
          />
          {entityDetails.type === 'artist' && (
            <SettingInput
              title={'Spotify URL'}
              placeHolder={'https://open.spotify.com/artist/0TnOYISbd1XYRBk9myaseg'}
              value={settings.spotifyUrl ?? entityDetails?.spotifyUrl}
              onChangeFunc={e => setSettings({...settings, spotifyUrl: e.target.value})}
            />
          )}
          <SettingInput
            title={getFeeName(entityDetails.type)}
            placeHolder={'Over 9000'}
            value={settings.fee ?? entityDetails?.fee}
            onChangeFunc={e => setSettings({...settings, fee: Number(e.target.value)})}
          />
          {entityDetails.type === 'staff' && (
            <>
              <SettingInput
                title={'Years of Experience'}
                placeHolder={'5yrs'}
                value={settings.yearsOfExperience ?? entityDetails?.yearsOfExperience}
                onChangeFunc={e => setSettings({...settings, yearsOfExperience: Number(e.target.value)})}
              />
              <SettingInput
                title={'Minimum Price'}
                placeHolder={'$25'}
                value={settings.minimumPrice ?? entityDetails?.minimumPrice}
                onChangeFunc={e => setSettings({...settings, minimumPrice: Number(e.target.value)})}
              />
            </>
          )}
          <SettingLocationInput
            title={entityDetails.type === 'venue' ? 'Address' : 'Location'}
            placeHolder={'1 Rocket Road, Hawthorne, CA'}
            value={settings.location ?? entityDetails?.location}
            setLocationAndCoordinates={(location, type, coordinates) =>
              setSettings({...settings, location, coordinates: {type, coordinates}})
            }
          />
          {entityDetails.type === 'venue' && (
            <SettingInput
              title={'Capacity'}
              placeHolder={'100'}
              value={settings.capacity ?? entityDetails?.capacity}
              onChangeFunc={e => setSettings({...settings, capacity: Number(e.target.value)})}
            />
          )}
        </table>
        <hr />
        <h3>Tags</h3>
        <ListingTags settings={settings} setSettings={setSettings} />
        <>
          <hr />
          <h3>Photos</h3>
          <div className='EditListing-venuePictures'>
            <GalleryEditor images={gallery} setImages={setGallery} />
          </div>
        </>
      </div>
      <SaveDiscardBottomBar onDiscard={handleDiscard} onSave={handleSave} isOpen={showSave} />
    </>
  )
}

export default EditListing
