// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventPlaylistList__51GZr{display:flex;flex-direction:column;gap:10px}", "",{"version":3,"sources":["webpack://./src/pages/EventPlaylistPage/EventPlaylist/EventPlaylistList/EventPlaylistList.module.scss"],"names":[],"mappings":"AAAA,0BACE,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".EventPlaylistList {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EventPlaylistList": "EventPlaylistList__51GZr"
};
export default ___CSS_LOADER_EXPORT___;
