import React from 'react'
import {DateRangePicker, FocusedInputShape} from 'react-dates'

import {dateFromObjectId} from '@posh/model-types'
import moment from 'moment'
import {useEventManagementContext} from 'pages/EventManagementPages/EventManagementContext'

import './AnalyticsDateRangePicker.scss'

const FORMAT = 'M/D/YYYY'

export type Dates = {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
}

type AnalyticsDateRangePickerProps = {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  setDates: (arg: Dates) => void
}

const getMomentFromObjectId = (objectId: string) => {
  const epoch = dateFromObjectId(objectId).getTime()
  return moment(epoch)
}

export const AnalyticsDateRangePicker = ({startDate, endDate, setDates}: AnalyticsDateRangePickerProps) => {
  const [focusedInput, setFocusedInput] = React.useState<FocusedInputShape | null>(null)
  const {event} = useEventManagementContext()
  const minDate = getMomentFromObjectId(event._id)
  const maxDate = moment()

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId='startDate'
      endDate={endDate}
      endDateId='endDate'
      onDatesChange={setDates}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      isOutsideRange={day => day.isBefore(minDate) || day.isAfter(maxDate)}
      readOnly
      small
      noBorder
      hideKeyboardShortcutsPanel
      numberOfMonths={1}
      displayFormat={FORMAT}
    />
  )
}
