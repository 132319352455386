import {useMutation} from '@tanstack/react-query'
import {EventFee} from 'apis/Groups/useGetGroupForFinancials'
import PoshApi from 'apis/PoshApi'

export interface CreateEventCustomFeeProps {
  name: string
  amount: number
  type: EventFee['type']
  eventId: string
  limitedToId: string[]
}

async function createEventCustomFee(props: CreateEventCustomFeeProps) {
  const response = await PoshApi.createEventCustomFee(props)
  return response.data
}

export default function useCreateEventCustomFee() {
  return useMutation((props: CreateEventCustomFeeProps) => createEventCustomFee(props))
}
