import React, {PropsWithChildren} from 'react'

import {BaseModal, BaseModalProps} from './BaseModal'
import {BlurBackgroundModal} from './variants/BlurBackground'
import {CenteredModal} from './variants/Centered'
import {GlassModal} from './variants/Glass'

export function Modal(props: PropsWithChildren<BaseModalProps>) {
  return <BaseModal {...props} />
}

Modal.Blur = BlurBackgroundModal
Modal.Centered = CenteredModal
Modal.Glass = GlassModal
