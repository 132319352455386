import React, {useEffect, useState} from 'react'

import {CurrencyCode} from '@posh/types'
import {useQueryClient} from '@tanstack/react-query'
import useFetchCustomCheckoutFields from 'apis/Carts/useFetchCustomCheckoutFields'
import useDeleteEventCustomFee from 'apis/Events/useDeleteEventCustomFee'
import {UpdateEventAttributes_DEPRECATED, useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import Input from 'components/form/Input'
import PoshSwitch from 'components/form/PoshSwitch'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'
import {AddCustomFeesModal} from 'pages/GroupPage/Finance/AddCustomFeesModal'
import CustomFees from 'pages/GroupPage/Finance/CustomFees'
import {EditCustomFeeModal} from 'pages/GroupPage/Finance/EditCustomFeeModal'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import EventSetting from '../EventSetting'
import {BaseEventSettingsProps, EventSettingsComponent} from '../eventSettingsComponent'

interface EventCheckoutSettingsProps extends BaseEventSettingsProps {
  currency: CurrencyCode
}

export const EventCheckoutSettings = (props: EventCheckoutSettingsProps) => {
  const {event, currency} = props
  const {eventId, groupId} = useResourcePageParams()
  const {showToast} = useToast()
  const [updateEventAttrs, setUpdateEventAttrs] = useState<UpdateEventAttributes_DEPRECATED>({})
  const [isShowingSave, setIsShowingSave] = useState(false)
  const [feeToEdit, setFeeToEdit] = useState('')
  const {data: customCheckoutFields} = useFetchCustomCheckoutFields(eventId!)
  const [isShowingAddCustomFees, setIsShowingAddCustomFees] = useState(false)
  const [feeToDelete, setFeeToDelete] = useState('')
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const {mutateAsync: deleteEventCustomFee} = useDeleteEventCustomFee()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!isEmpty(updateEventAttrs)) setIsShowingSave(true)
    else setIsShowingSave(false)
  }, [updateEventAttrs])

  const onSave = async () => {
    try {
      await updateEvent({eventId: eventId!, groupId: groupId!, eventData: updateEventAttrs})
      setIsShowingSave(false)
      queryClient.refetchQueries(['event'])
    } catch (error) {
      showToast({type: 'error', title: error.response.data.error})
      setIsShowingSave(false)
    }
  }

  const onDiscard = () => {
    setIsShowingSave(false)
    setUpdateEventAttrs({})
  }

  return (
    <>
      <EventSettingsComponent isShowingSave={isShowingSave} onDiscard={onDiscard} onSave={onSave}>
        {!event.isRSVPEvent && (
          <>
            <EventSetting title={'Confirmation Message'} isTextField={true}>
              <Input
                type='text'
                placeholder={'Your Order Has Been Submitted'}
                value={updateEventAttrs.confirmationMessage ?? event.confirmationMessage}
                onChange={e => setUpdateEventAttrs({...updateEventAttrs, confirmationMessage: e.target.value})}
              />
            </EventSetting>
            <EventSetting
              title={'Include Fees in Event Page Pricing'}
              info={'Switching this on only will change ticket and table pricing on the event page to include fees'}>
              <PoshSwitch
                switchOptions={{
                  checked: updateEventAttrs.showFeesInPrice ?? (event.showFeesInPrice ? event.showFeesInPrice : false),
                  onChange: checked => setUpdateEventAttrs({...updateEventAttrs, showFeesInPrice: checked}),
                }}
              />
            </EventSetting>
            <EventSetting
              title={'Long Form Add To Cart Button'}
              info={'This will change the button text on the event page to "Add to Cart" instead of a plus sign.'}>
              <PoshSwitch
                switchOptions={{
                  checked:
                    updateEventAttrs.longFormAddToCartButton ??
                    (event.longFormAddToCartButton ? event.longFormAddToCartButton : false),
                  onChange: checked => setUpdateEventAttrs({...updateEventAttrs, longFormAddToCartButton: checked}),
                }}
              />
            </EventSetting>
            <EventSetting title={'Checkout Submit Button'} isTextField={true}>
              <Input
                type='text'
                placeholder={event.customCheckoutSubmitText ?? 'Purchase & Signup'}
                value={updateEventAttrs.customCheckoutSubmitText}
                onChange={e => setUpdateEventAttrs({...updateEventAttrs, customCheckoutSubmitText: e.target.value})}
              />
            </EventSetting>
          </>
        )}
        {!event.isRSVPEvent && (
          <EventSetting
            title={'Custom Fees'}
            info={'You can add fees to your event, such as a processing fee or a tax.'}
            block={true}>
            <CustomFees
              setFeeToDelete={setFeeToDelete}
              setFeeToEdit={setFeeToEdit}
              setIsShowingAddCustomFees={setIsShowingAddCustomFees}
              fees={event.fees}
              currency={currency ?? 'USD'}
            />
          </EventSetting>
        )}
        <EventSetting title={'Legacy RSVP Flow'}>
          <PoshSwitch
            switchOptions={{
              checked: updateEventAttrs.legacyRSVPFlow ?? (event.legacyRSVPFlow ? event.legacyRSVPFlow : false),
              onChange: checked => setUpdateEventAttrs({...updateEventAttrs, legacyRSVPFlow: checked}),
            }}
          />
        </EventSetting>
      </EventSettingsComponent>
      <AddCustomFeesModal
        currency={currency ?? 'USD'}
        scope='event'
        width={600}
        isOpen={isShowingAddCustomFees}
        onClose={() => {
          queryClient.invalidateQueries(['event'])
          setIsShowingAddCustomFees(false)
        }}
        eventId={eventId}
      />
      {event.fees && (
        <>
          <EditCustomFeeModal
            width={600}
            isOpen={feeToEdit !== ''}
            onClose={() => {
              queryClient.invalidateQueries(['event'])
              setFeeToEdit('')
            }}
            fee={feeToEdit}
            currency={currency ?? 'USD'}
            allFees={event.fees}
            scope='event'
            eventId={eventId}
          />
          <ConfirmationModal
            isOpen={feeToDelete !== ''}
            onClose={() => {
              queryClient.invalidateQueries(['event'])
              setFeeToDelete('')
            }}
            title={`Are you sure you want to delete ${feeToDelete}?`}
            errorMessage={`Could not delete ${feeToDelete}`}
            onClick={async () => await deleteEventCustomFee({eventId: eventId!, name: feeToDelete})}
            bodyText={`This will delete ${feeToDelete} from this event. This action cannot be undone.`}
            confirmButtonText={'Delete Fee'}
            confirmationMessage={`Successfully deleted fee`}
          />
        </>
      )}
    </>
  )
}
