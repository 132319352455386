import {useMutation} from '@tanstack/react-query'
import {LongLat} from 'apis/Events/types'
import PoshApi from 'apis/PoshApi'

import {ArtistType, GalleryImage} from '.'

export interface CreateArtist {
  artistName: string
  artistType: ArtistType
  instagram?: string
  spotifyUrl?: string
  website?: string
  baseBookingFee?: number
  location: string
  coordinates: LongLat
  bio?: string
  avi: string
  galleryImages: GalleryImage[]
}

async function createArtist(artistData: CreateArtist) {
  const response = await PoshApi.createArtist(artistData)
  return response.data
}

export default function useCreateArtist() {
  return useMutation((params: CreateArtist) => createArtist(params))
}
