import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export async function fetchRefundRequests() {
  const response = await PoshApi.fetchRefundRequests()
  return response.data
}

export function useFetchRefundRequests() {
  return useQuery(['refundRequests'], fetchRefundRequests)
}
