import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'

import styles from './styles.module.scss'

export interface EventVisualsInputContainerProps {
  accentColor: string
  lightMode: boolean
  style?: React.CSSProperties
  className?: string
  isOutlined?: boolean
  blur?: boolean
}

export function EventVisualsInputContainer(props: PropsWithChildren<EventVisualsInputContainerProps>) {
  const {className, lightMode, children, style, blur = false} = props

  const inputContainer = classNames(styles.inputContainer, lightMode ? styles.light : styles.dark, {
    [styles.blur]: blur,
  })

  return (
    <div className={classNames(inputContainer, className)} style={style}>
      {children}
    </div>
  )
}

export function Outlined({className, ...props}: PropsWithChildren<EventVisualsInputContainerProps>) {
  return <EventVisualsInputContainer {...props} className={classNames(styles.outlined, className)} />
}

function Row({className, ...props}: PropsWithChildren<EventVisualsInputContainerProps>) {
  return <EventVisualsInputContainer {...props} className={classNames(styles.row, className)} />
}

EventVisualsInputContainer.Outlined = Outlined
EventVisualsInputContainer.Row = Row

Outlined.Row = Row
