// Copy of apps/mobile/hooks/platform-hooks/useDebounce.ts, thank you @JonBeaubien

import {useEffect, useState} from 'react'

function useDebounce<T>(value: T, delay?: number, enabled?: boolean): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    if (enabled === false) {
      return
    }
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay, enabled])

  return debouncedValue
}

export default useDebounce
