import React from 'react'

import './styles.scss'

interface TableFilterProps {
  filterKeys: string[]
  accentColor: string
  isLightMode: boolean
  selectedFilter: string
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>
}

const TableFilter = (props: TableFilterProps) => {
  const {filterKeys, accentColor, selectedFilter, setSelectedFilter, isLightMode} = props
  return (
    <div className='TableFilter'>
      <div className='TableFilter-list' style={{border: `1px solid ${accentColor}`}}>
        {filterKeys.map((filterKey, index) => {
          const isSelected = selectedFilter === filterKey
          return (
            <div
              className={`TableFilter-item ${isLightMode ? 'lightmode' : ''} ${isSelected ? 'selected' : 'unselected'}`}
              onClick={() => setSelectedFilter(filterKey)}
              style={{background: isSelected ? accentColor : 'transparent'}}
              key={index}>
              {filterKey}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TableFilter
