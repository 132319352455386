import React, {PropsWithChildren} from 'react'

import {BaseNavigationItem} from '../BaseNavigationItem'
import {DesktopBaseNavigationItemProps} from '../BaseNavigationItem/desktop'
import {navigationIcons} from './icons'
import {usePageNavigation} from './pageNavigation'

interface PageProps extends Omit<DesktopBaseNavigationItemProps, 'body'> {
  title: string
}

export function DesktopPageNavigationItem(props: PropsWithChildren<PageProps>) {
  const {title, children, ...rest} = props
  return (
    <BaseNavigationItem.Desktop body={<span>{title}</span>} {...rest}>
      {children}
    </BaseNavigationItem.Desktop>
  )
}

type PageVariantProps = Pick<DesktopBaseNavigationItemProps, 'isExpanded'>

function Organizations({isExpanded}: PageVariantProps) {
  const {navigate, isOnPage} = usePageNavigation().organizations
  return (
    <DesktopPageNavigationItem
      {...navigationIcons.organizations}
      isExpanded={isExpanded}
      isSelected={isOnPage}
      onClick={navigate}
    />
  )
}

function Kickback({isExpanded}: PageVariantProps) {
  const {navigate, isOnPage, shouldShowKickback} = usePageNavigation().kickback
  if (!shouldShowKickback) return <></>
  return (
    <DesktopPageNavigationItem
      {...navigationIcons.kickback}
      isExpanded={isExpanded}
      isSelected={isOnPage}
      onClick={navigate}
    />
  )
}

function Orders({isExpanded}: PageVariantProps) {
  const {navigate, isOnPage} = usePageNavigation().orders
  return (
    <DesktopPageNavigationItem
      {...navigationIcons.orders}
      isExpanded={isExpanded}
      isSelected={isOnPage}
      onClick={navigate}
    />
  )
}

function Explore({isExpanded}: PageVariantProps) {
  const {navigate, isOnPage} = usePageNavigation().explore
  return (
    <DesktopPageNavigationItem
      {...navigationIcons.explore}
      isExpanded={isExpanded}
      isSelected={isOnPage}
      onClick={navigate}
    />
  )
}

DesktopPageNavigationItem.Organizations = Organizations
DesktopPageNavigationItem.Kickback = Kickback
DesktopPageNavigationItem.Orders = Orders
DesktopPageNavigationItem.Explore = Explore
