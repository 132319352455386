import React, {useState} from 'react'
import Loader from 'react-loader-spinner'

import {EventPublic} from 'apis/Events/types'
import {useCheckEventPassword} from 'apis/Events/useCheckEventPassword'
import classNames from 'classnames'
import {rightArrow} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {PoshLogo} from 'components/PoshLogo'

interface EventPagePasswordProps {
  event: EventPublic
  setIsLocked: React.Dispatch<React.SetStateAction<boolean>>
}

const EventPagePassword = (props: EventPagePasswordProps) => {
  const {event, setIsLocked} = props
  const {accentColor, lightmode} = event
  const [passwordInput, setPasswordInput] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const {mutateAsync: checkEventPassword, isLoading} = useCheckEventPassword()

  const checkPassword = async (e?: React.FormEvent<HTMLFormElement>) => {
    setErrorMessage('')
    e?.preventDefault()
    const isUnlocked = await checkEventPassword({eventId: event.id, password: passwordInput})
    setIsLocked(!isUnlocked)
    if (!isUnlocked) setErrorMessage('Invalid Password')
  }

  return (
    <>
      <div className='pageContainer'>
        <div className='loading'>
          <PoshLogo.Animated />
        </div>
        <div className={classNames('EventPage-passwordWrapper', {lightmode})}>
          <div
            className='EventPage-passwordInput'
            style={{borderColor: accentColor, boxShadow: `0 0 30px -5px ${accentColor}`}}>
            <p className='text-large' style={{color: `${accentColor}`}}>
              Enter password to view this page
            </p>
            <form className='EventPage-passwordFormWrapper' onSubmit={e => checkPassword(e)}>
              <input
                type='text'
                style={{borderColor: accentColor}}
                className={classNames('EventPage-passwordInputField', {lightmode})}
                value={passwordInput}
                placeholder={'Password'}
                onChange={e => setPasswordInput(e.target.value)}
              />
              <button
                style={{backgroundColor: accentColor, borderColor: accentColor}}
                className={classNames('EventPage-submitPassword', {lightmode})}
                onClick={() => checkPassword()}>
                {isLoading ? (
                  <Loader type='TailSpin' color='black' height={9} width={9} />
                ) : (
                  <PoshImage className={classNames('EventPage-arrowRight', {lightmode})} src={rightArrow} />
                )}
              </button>
            </form>
            {errorMessage && <p className='EventPage-errorMessage noMargin text-small'>{errorMessage}</p>}
          </div>
        </div>
      </div>
    </>
  )
}
export default EventPagePassword
