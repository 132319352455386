// Put these types in a package?
import React, {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState} from 'react'

import {Breadcrumb} from '../Header/Desktop/components/Breadcrumbs'
import {HeaderContextButtonProps} from '../Header/Desktop/components/ContextButton'
import {PageTitleProps} from '../Header/Desktop/components/PageTitle'

type SetPageTitleFunction = (title: PageTitleProps['title']) => void

export interface NavigationContext {
  pageTitleProps: PageTitleProps | undefined
  setPageTitleProps: Dispatch<SetStateAction<PageTitleProps | undefined>>
  contextButtonProps: HeaderContextButtonProps | undefined
  setContextButtonProps: Dispatch<SetStateAction<HeaderContextButtonProps | undefined>>
  clearPageTitle: () => void
  clearContextButton: () => void
  setPageTitle: SetPageTitleFunction
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
}

const fallbackFunction = () => {
  throw new Error('NavigationContextProvider not found')
}

const NavigationContext = createContext<NavigationContext>({
  setPageTitle: fallbackFunction,
  pageTitleProps: undefined,
  setBreadcrumbs: fallbackFunction,
  setPageTitleProps: fallbackFunction,
  clearPageTitle: fallbackFunction,
  contextButtonProps: undefined,
  setContextButtonProps: fallbackFunction,
  clearContextButton: fallbackFunction,
})

export const useNavigation = () => useContext(NavigationContext)

export const NavigationContextProvider = ({children}: PropsWithChildren) => {
  const [pageTitleProps, setPageTitleProps] = useState<PageTitleProps | undefined>(undefined)
  const [contextButtonProps, setContextButtonProps] = useState<HeaderContextButtonProps | undefined>(undefined)

  /**
   * Set the page title
   */
  const setPageTitle: SetPageTitleFunction = title => {
    setPageTitleProps(v => ({...v, title}))
  }

  /**
   * Set the breadcrumbs for the page title
   */
  const setBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
    setPageTitleProps(v => {
      if (!v) return v
      return {...v, breadcrumbs}
    })
  }

  /**
   * Clears the page title, including the title and breadcrumbs
   */
  const clearPageTitle = () => setPageTitleProps(undefined)

  const clearContextButton = () => setContextButtonProps(undefined)

  return (
    <NavigationContext.Provider
      value={{
        pageTitleProps,
        setPageTitleProps,
        contextButtonProps,
        setContextButtonProps,
        setPageTitle,
        setBreadcrumbs,
        clearPageTitle,
        clearContextButton,
      }}>
      {children}
    </NavigationContext.Provider>
  )
}

/**
 * Set the page title and breadcrumbs. Clears the page title when the component unmounts
 * @param title - The page title
 * @param breadcrumbs - The breadcrumbs for the page title
 */
export function useSetPageTitle(title?: PageTitleProps['title'], breadcrumbs?: Breadcrumb[]) {
  const {setPageTitleProps, clearPageTitle} = useNavigation()
  useEffect(() => {
    if (!title) return clearPageTitle()
    setPageTitleProps({title, breadcrumbs})

    return () => clearPageTitle()
  }, [title, breadcrumbs])
}

/**
 * Set the context button. Clears the context button when the component unmounts
 * @param contextButtonProps - The context button props (title, onClick)
 * @param clearOnUnmount - Clear the context button when the component unmounts
 */
export function useSetContextButton(contextButtonProps: HeaderContextButtonProps | undefined) {
  const {setContextButtonProps, clearContextButton} = useNavigation()

  useEffect(() => {
    if (!contextButtonProps) return clearContextButton()
    setContextButtonProps(contextButtonProps)

    return () => clearContextButton()
  }, [])
}
