import React, {PropsWithChildren} from 'react'

import './styles.scss'

export enum BadgeVariant {
  OVAL = 'Badge-oval',
  SQUARE = 'Badge-square',
}

interface Props {
  className?: string
  variant?: BadgeVariant
}

export default function Badge(props: PropsWithChildren<Props>) {
  const getVariant = props.variant || 'Badge-oval'

  return <div className={getVariant + ' ' + (props.className || '')}>{props.children}</div>
}
