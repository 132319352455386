import React, {useState} from 'react'

import classNames from 'classnames'

import {CRUDFunction, CRUDTableMap} from '.'
import CRUDTableCell from './cell'
import CRUDTableRowActions from './rowActions'

interface CRUDTableRowProps {
  row: any
  map: CRUDTableMap
  refetchDataFunction: any
  updateFunction: CRUDFunction
  deleteFunction?: CRUDFunction
  viewFunction?: CRUDFunction
  copyFunction?: CRUDFunction
  moreInfoFunction?: CRUDFunction
}

const CRUDTableRow = (props: CRUDTableRowProps) => {
  const {row, map, refetchDataFunction, updateFunction, deleteFunction, viewFunction, copyFunction, moreInfoFunction} =
    props
  const [editing, setEditing] = useState(false)

  const [savedRow, setSavedRow] = useState(row)
  const [tempRow, setTempRow] = useState(row)

  const handleUpdate = ({key, value}: {key: string; value: any}) => {
    setTempRow({...tempRow, [key]: value})
  }

  const handleSave = async () => {
    await updateFunction({
      id: row._id as string,
      item: tempRow,
    })
    setSavedRow(tempRow)
    setEditing(false)
  }

  const handleDelete = async () => {
    if (deleteFunction) {
      await deleteFunction({
        id: row._id as string,
      })
      refetchDataFunction()
    }
  }

  const handleView = async () => {
    if (viewFunction) {
      viewFunction({
        id: row._id as string,
      })
    }
  }

  const handleCopy = async () => {
    if (copyFunction) {
      copyFunction({
        id: row._id as string,
      })
    }
  }

  const handleMoreInfo = async () => {
    if (moreInfoFunction) {
      moreInfoFunction({
        id: row._id as string,
      })
    }
  }

  return (
    <tr className={classNames('', {editing})}>
      {map.map((obj, index) => (
        <CRUDTableCell key={index} item={tempRow} mapObj={obj} editing={editing} handleUpdate={handleUpdate} />
      ))}
      <td>
        <CRUDTableRowActions
          editing={editing}
          setEditing={setEditing}
          handleSave={handleSave}
          handleDelete={deleteFunction ? handleDelete : undefined}
          handleView={viewFunction ? handleView : undefined}
          handleCopy={copyFunction ? handleCopy : undefined}
          handleMoreInfo={moreInfoFunction ? handleMoreInfo : undefined}
        />
      </td>
    </tr>
  )
}

export default CRUDTableRow
