import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import {DatePicker, DatePickerProps} from '@material-ui/pickers'

import {
  ControlledEventVisualsDateInputProps,
  EventVisualsDateInputProps,
  EventVisualsDateInputWrapper,
  getSharedPickerProps,
} from './EventVisualsDateInputWrapper'

export function DateInput(props: EventVisualsDateInputProps<DatePickerProps>) {
  const pickerProps = getSharedPickerProps(props)
  const format = props.format ?? 'MM/DD/YYYY'

  return (
    <EventVisualsDateInputWrapper {...props}>
      <DatePicker {...pickerProps} format={format} fullWidth />
    </EventVisualsDateInputWrapper>
  )
}

function ControlledDate<T extends FieldValues, K extends Path<T>>(
  props: ControlledEventVisualsDateInputProps<T, K, DatePickerProps>,
) {
  const {name, control, rules} = props
  const isRequired = typeof rules?.required === 'object' ? rules.required.value : !!rules?.required

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {value, onChange}}) => (
        <DateInput {...props} value={value} onChange={onChange} required={isRequired} />
      )}
    />
  )
}

DateInput.Controlled = ControlledDate
