import React, {useState} from 'react'

import MUISlider, {Mark, SliderProps} from '@material-ui/core/Slider'
import {styled} from '@material-ui/core/styles'

const CustomizedSlider = styled(MUISlider)<SliderProps>(() => ({
  width: '94%',
  minWidth: 200,
  color: '#00CCFF',
  marginLeft: '13px',
  '& .MuiSlider-rail': {
    backgroundColor: '#7A7B7D',
    height: 1,
  },
  '& .MuiSlider-track': {
    backgroundColor: '#00CCFF',
    height: 1,
  },
  '& .MuiSlider-mark': {
    width: 1,
    height: 16,
    top: 6,
    backgroundColor: '#7A7B7D',
  },
  '& .MuiSlider-markLabel': {
    color: '#FFF',
    fontSize: 13,
    fontWeight: 300,
    top: -24,
  },
  '& .MuiSlider-thumb': {
    borderRadius: 0,
    clipPath: 'polygon(50% 100%, 100% 69%, 100% 0, 0 0, 0 69%);',
    width: 11.5,
    height: 14.5,
    marginLeft: -5.65,
    marginTop: -13,
  },
}))

// Internal values idea, and handleChange from https://stackoverflow.com/questions/69640151/mui-slider-with-restricted-values-but-equal-spacing
export const MarkedSlider = ({
  marks,
  label,
  onValueChanged,
  defaultValue,
}: {
  label: string
  marks: Mark[]
  onValueChanged: (newValue: [number, number]) => void
  defaultValue?: [number, number]
}) => {
  const internalMax = marks.length - 1
  /* The internal value just maps to the index of the actual value in marks array.
   This is done specifically to get even spacing while given values don't scale linearly */
  const getDefaultInternalValue = (): [number, number] => {
    if (defaultValue) {
      const lowerValue = marks.findIndex(mark => mark.value === defaultValue[0])
      const upperValue = marks.findIndex(mark => mark.value === defaultValue[1])

      return [lowerValue === -1 ? 0 : lowerValue, upperValue === -1 ? internalMax : upperValue]
    } else {
      return [0, internalMax]
    }
  }
  const [internalValue, setInternalValue] = useState<[number, number]>(getDefaultInternalValue)
  const handleChange = (newInternalValue: [number, number]) => {
    // To prevent unnecessary re-renders because of restricted values from marks
    if (internalValue === newInternalValue) return
    setInternalValue(newInternalValue)
    const actualValue: [number, number] = [marks[newInternalValue[0]].value, marks[newInternalValue[1]].value]
    onValueChanged(actualValue)
  }
  const internalMarks = marks.map((mark, index) => ({
    ...mark,
    value: index,
  }))
  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '32px'}}>
      <h5>{label}</h5>
      <CustomizedSlider
        value={internalValue}
        onChange={(_, v) => handleChange(v as [number, number])}
        min={0}
        max={internalMax}
        marks={internalMarks}
        step={null}
      />
    </div>
  )
}
