import React from 'react'
import ReactTooltip from 'react-tooltip'

import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'

interface AttendeeAlertsProps {
  textDisabled: boolean
}

const AttendeeAlerts = (props: AttendeeAlertsProps) => {
  const {textDisabled} = props

  return (
    <div className='AttendeeAlerts'>
      <ReactTooltip className='poshToolTip' id='attendeeAlerts' effect='solid' place='top' />
      {textDisabled && (
        <div data-tip='This attendee is unsubscribed from SMS alerts.' data-for='attendeeAlerts'>
          <PoshImage src='https://images.posh.vip/b3/icons/sms-block.svg' />
        </div>
      )}
    </div>
  )
}

export default AttendeeAlerts
