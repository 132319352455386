import {FieldValues} from 'react-hook-form'

/**
 * This function is used to get the dirty values from a form. (i.e. the values that have been changed)
 * @param formData The current form data returned from react-hook-form
 * @param dirtyFields The dirty fields returned from react-hook-form
 * @returns The dirty values
 */
export function dirtyValues<T extends FieldValues>(formData: T, dirtyFields: Partial<Record<keyof T, any>>) {
  const dirtyValues: Partial<T> = {}
  const keys = Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>
  keys.forEach(key => {
    dirtyValues[key] = formData[key]
  })
  return dirtyValues
}
