import {DefaultValues} from 'react-hook-form'

import {MAX_TICKET_QUANTITY, transformNullToUndefined} from '@posh/model-types'
import {Ticket} from 'apis/Events/types'

import {UpdateTicketForm} from './schema'

/**
 * Transforms the response from the server into a format that can be used by the form
 * @param ticket  The ticket object to transform
 * @returns The transformed ticket object
 */
export function transformDefaultValues(ticket?: Ticket): DefaultValues<UpdateTicketForm> {
  const {quantityAvailable, validity, ...rest} = ticket
    ? transformNullToUndefined(ticket)
    : {
        quantityAvailable: undefined,
        validity: undefined,
      }

  const {validBefore, validAfter} = validity || {}
  return {
    ...rest,
    quantityAvailable:
      !quantityAvailable || quantityAvailable === MAX_TICKET_QUANTITY
        ? undefined // undefined means unlimited
        : quantityAvailable,
    validity: {
      validAfter: validAfter?.toString(),
      validBefore: validBefore?.toString(),
    },
  }
}
