import React from 'react'
import {Route, Routes} from 'react-router-dom'

import {CreateEvent} from 'pages/CreateEvent'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

export const CreateEventRouter = () => {
  return (
    <Routes>
      <Route path='' element={<CreateEvent />} />
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  )
}
