import {useMutation, useQueryClient} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'
import {UploadType} from 'pages/CSVImportModal'

export interface CSVUploadProps {
  data: Record<string, string | number>[]
  clarifier?: string
  groupId: string
  eventId: string
  type: string
}

async function csvUpload(props: CSVUploadProps) {
  const response = await PoshApi.csvUpload(props.groupId, props.eventId, props.type, props.data, props.clarifier)
  return response.data
}

export default function useCSVUpload(uploadType: UploadType) {
  const qc = useQueryClient()
  return useMutation((props: CSVUploadProps) => csvUpload(props), {
    retry: false,
    onSuccess: (__, variables) => {
      switch (uploadType) {
        case 'complimentaryTickets':
          qc.invalidateQueries(['complimentaryOrders', variables.eventId])
          qc.refetchQueries({queryKey: ['complimentaryOrders', variables.eventId], exact: true})
          break
        case 'promoCodes':
          qc.invalidateQueries(['eventPromoCodes', variables.eventId])
          qc.refetchQueries({queryKey: ['eventPromoCodes', variables.eventId], exact: true})
          break
      }
    },
  })
}
