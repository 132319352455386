import React from 'react'

import {SpotifySong} from 'apis/Util/useGetSpotifySongs'
import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'

import styles from './styles.module.scss'

export function Song(props: {song: SpotifySong; lightMode?: boolean; interactive?: boolean}) {
  const {song, lightMode, interactive = true} = props
  const textClassName = lightMode ? styles.light : styles.dark
  return (
    <div className={classNames(styles.SongListItem, {[styles.interactive]: interactive})}>
      <PoshImage src={song.albumCover} alt={song.name} />
      <h6 className={classNames('noMargin', 'spotifySearchInputSong', textClassName)}>
        {song.name} - {song.artist}
      </h6>
    </div>
  )
}

function Selected(props: {song: SpotifySong; lightMode?: boolean}) {
  const {song, lightMode} = props
  return (
    <div className={styles.ChosenSong}>
      <Song song={song} lightMode={lightMode} interactive={false} />
    </div>
  )
}

Song.Selected = Selected
