import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {BookingStatus} from '.'

export interface UpdateBookingRequestData {
  id: string
  status?: BookingStatus
  canceled?: boolean
  opened?: boolean
}

async function updateBookingRequest(bookingRequestData: UpdateBookingRequestData) {
  const response = await PoshApi.updateBookingRequest(bookingRequestData)
  return response.data
}

export default function useUpdateBookingRequest() {
  return useMutation((bookingRequestData: UpdateBookingRequestData) => updateBookingRequest(bookingRequestData))
}
