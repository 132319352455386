import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {CampaignLink} from '.'

export interface UpdateCampaignLinkResponse {
  updated: CampaignLink
}

export interface UpdateCampaignLinkParams {
  campaignLinkId: string
  updates: CampaignLink
}

async function updateCampaignLink(data: UpdateCampaignLinkParams): Promise<UpdateCampaignLinkResponse> {
  const response = await PoshApi.updateCampaignLink(data)
  return response.data
}

export function useUpdateCampaignLink(
  opts?: UseMutationOptions<UpdateCampaignLinkResponse, any, UpdateCampaignLinkParams>,
) {
  return useMutation((params: UpdateCampaignLinkParams) => updateCampaignLink(params), opts)
}
