import {createInstance} from '@optimizely/react-sdk'
import {EOptimizelyEvents, OptimizelyExperimentAttribute} from '@posh/types'
import {captureException} from '@sentry/react'

const OPTIMIZELEY_SDK_KEY = 'CNhDBVY2Kyq8G5dDVTwT1'
export const optimizelyClient = createInstance({sdkKey: OPTIMIZELEY_SDK_KEY})

/**
 *
 * @param eventName The name of the event to track
 * @param overrideAttributes The attributes to override for the event, this assigns the user to a specific experiment
 */
export const trackOptimizelyEvent = (
  eventName: EOptimizelyEvents,
  overrideAttributes?: OptimizelyExperimentAttribute,
) => {
  try {
    // The second argument is the user ID, which we don't ever want to override so we pass undefined
    optimizelyClient.track(eventName, undefined, overrideAttributes)
  } catch (e) {
    captureException(e)
  }
}
