import React from 'react'

import {OrdersForTicket} from 'apis/Orders'
import {useDimensions} from 'hooks/useDimensions'

interface TicketOrdersTableProps {
  ticketId: string
  ordersAndNames?: OrdersForTicket[]
}

const TicketOrdersTable = (props: TicketOrdersTableProps) => {
  const {ticketId, ordersAndNames} = props
  const {isMobile} = useDimensions()

  if (ordersAndNames && ordersAndNames?.length > 0) {
    return (
      <>
        <h4>Purchasers</h4>
        <table className='EditPromoCode-ordersArea-table'>
          <tr>
            <th>Name</th>
            <th>Quantity</th>
            {!isMobile && <th>Phone</th>}
            {!isMobile && <th>Email</th>}
          </tr>
          {ordersAndNames?.map((order, index) => (
            <tr key={index}>
              <td>{order.name ? order.name : <span style={{color: 'grey'}}>Account not found</span>}</td>
              <td>{order.tickets.filter(t => t.typeID == ticketId).length}</td>
              {!isMobile && <td>{order.phone}</td>}
              {!isMobile && <td>{order.email}</td>}
            </tr>
          ))}
        </table>
      </>
    )
  } else {
    return <></>
  }
}

export default TicketOrdersTable
