import React from 'react'

import {useGetSectionalData} from 'apis/Analytics/useGetSectionalData'
import {useEventManagementContext} from 'pages/EventManagementPages/EventManagementContext'

import {CrossSection} from '../CrossSection/CrossSection'
import {Text} from '../Shared/Text/Text'
import {parseNumberValue} from '../Shared/utils'

interface SummaryProps {
  eventId: string
  groupId: string
  isLoading?: boolean
}

const getLabel = (label?: string) => {
  if (!label) return null

  if (label.includes('From Explore Page:')) {
    return (
      <Text size='small'>
        From Explore Page:
        <Text size='small' style={{color: '#00ffa3', fontWeight: 700}}>
          {label.split(':')[1]}
        </Text>
      </Text>
    )
  }

  return <Text size='small'>{label}</Text>
}

export const Summary = (props: SummaryProps) => {
  const {eventId, groupId} = props

  const {event} = useEventManagementContext()
  const isRSVPEvent = event.isRSVPEvent

  const {data, isLoading, isError} = useGetSectionalData(
    {
      eventId,
      groupId,
      dataPoints: isRSVPEvent
        ? ['totalRSVP', 'totalPageVisits', 'conversion']
        : [
            'totalGmvOverview',
            'totalTicketsSold',
            'paymentInstallments',
            'customFees',
            'totalPageVisits',
            'conversion',
          ],
    },
    {
      enabled: props.isLoading !== true,
    },
  )

  if (props.isLoading || isLoading) {
    return (
      <CrossSection.Section>
        {[...Array(5)].map((_, index) => (
          <CrossSection key={index}>
            <CrossSection.Row>
              <Text.Loading width={150} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='xl' width={100} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='small' />
            </CrossSection.Row>
          </CrossSection>
        ))}
      </CrossSection.Section>
    )
  }

  if (isError) {
    return (
      <CrossSection.Section style={{display: 'flex', width: '100%', height: 136, justifyContent: 'center'}}>
        <CrossSection>
          <CrossSection.Row>
            <Text size='xl' bold>
              Error
            </Text>
          </CrossSection.Row>
          <CrossSection.Row>
            <Text>Something went wrong. Contact support@posh.vip for help.</Text>
          </CrossSection.Row>
        </CrossSection>
      </CrossSection.Section>
    )
  }

  return (
    <CrossSection.Section>
      {data.map((crossSection, index) => {
        if (
          crossSection.type === 'totalGmvOverview' &&
          crossSection.mainValue?.type === 'currency' &&
          crossSection.mainValue?.value === 0
        ) {
          return null
        }

        return (
          <CrossSection key={index}>
            <CrossSection.Row>
              <Text>{crossSection.title}</Text>
            </CrossSection.Row>
            <CrossSection.Row>
              <Text size='xl' bold>
                {parseNumberValue(crossSection.mainValue)}
              </Text>
            </CrossSection.Row>
            <CrossSection.Row>{getLabel(crossSection.label)}</CrossSection.Row>
          </CrossSection>
        )
      })}
    </CrossSection.Section>
  )
}
