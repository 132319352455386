import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

import {AnalyticsObject} from './types'

export async function fetchEventAttendeeLocations(eventId: string): Promise<AnalyticsObject[]> {
  const response = await PoshApi.fetchEventAttendeeLocations(eventId)
  return response.data.attendeeLocationByCity
}

export default function useFetchEventAttendeeLocations(eventId?: string) {
  return useQuery(['eventAttendeeLocations', eventId], () => fetchEventAttendeeLocations(eventId!), {
    enabled: !!eventId,
  })
}
