import {Control, useController, useFormState} from 'react-hook-form'

import {PAID_TICKET_PRESET, RSVP_TICKET_PRESET} from '@posh/model-types'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'

type Ticket = {name?: string; price?: number}
const isTicketPreset = (ticket: Ticket, preset: Ticket) => {
  return ticket.price === preset.price && ticket.name === preset.name
}

export function useGetCreateEventProgress(
  control: Control<EventVisualsForm>,
  isRSVPEvent: boolean,
): {
  progress: `${number}%`
  numberOfSteps: number
} {
  const {isValid: isEventDetailsDone} = useFormState({control})

  const {
    field: {value: flyer},
  } = useController({name: 'flyer', control})
  const {
    field: {value: tickets},
  } = useController({name: 'tickets', control})

  const isCustomizePageDesignDone = !!flyer

  const isTicketsDone =
    tickets &&
    !tickets.every(ticket => isTicketPreset(ticket, PAID_TICKET_PRESET) || isTicketPreset(ticket, RSVP_TICKET_PRESET))

  const checklistItems = [isCustomizePageDesignDone, isEventDetailsDone]
  if (!isRSVPEvent) checklistItems.push(!!isTicketsDone)

  const progress = checklistItems.filter(item => item).length / checklistItems.length

  return {progress: `${progress * 100}%`, numberOfSteps: checklistItems.length}
}
