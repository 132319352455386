import React from 'react'

import './Counter.styles.scss'

interface CounterProps {
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  step?: number
}

export const Counter = (props: CounterProps) => {
  const {value, onChange, min = 0, max = Number.MAX_SAFE_INTEGER, step = 1} = props

  const onIncrement = () => {
    if (value + step <= max) {
      onChange(value + step)
    }
  }

  const onDecrement = () => {
    if (value - step >= min) {
      onChange(value - step)
    }
  }

  return (
    <div className='Counter'>
      <button className='Counter-button' onClick={onDecrement}>
        –
      </button>
      <div style={{width: 20, textAlign: 'center'}}>{value}</div>
      <button className='Counter-button flipped' onClick={onIncrement}>
        +
      </button>
    </div>
  )
}
