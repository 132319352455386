import React, {Dispatch, SetStateAction, useMemo, useState} from 'react'

import {AccountPublicProfilePermissions} from '@posh/models'
import {useFetchAccount} from 'apis/Accounts/useFetchAccount'
import {UpdateAccountSettingsInput, useUpdateAccountSettings} from 'apis/Accounts/useUpdateAccountSettings'
import {AuthedAccount} from 'apis/Auth/useLoginWithEmail'
import PoshSwitch from 'components/form/PoshSwitch'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'

import useDebounce from '../../../../../hooks/useDebounce'

type SwitchOptionsKeyTypes =
  | keyof Omit<UpdateAccountSettingsInput, 'profilePermissions'>
  | keyof AccountPublicProfilePermissions
const getSwitchOptions =
  (
    setAccountUpdates: Dispatch<SetStateAction<UpdateAccountSettingsInput | null>>,
    currentUser: AuthedAccount | undefined,
    accountUpdates: UpdateAccountSettingsInput | null,
  ) =>
  (key: SwitchOptionsKeyTypes) => {
    if (!currentUser) return {checked: false, onChange: () => {}}
    const checked = (() => {
      const updateIsPublicProfile = accountUpdates?.profilePermissions?.isPublicProfile
      const currentIsPublicProfile = currentUser.profilePermissions?.isPublicProfile
      const isPublicProfile = updateIsPublicProfile ?? currentIsPublicProfile ?? true
      switch (key) {
        case 'smsOptIn':
        case 'hideMeFromGuestLists':
          return (accountUpdates && accountUpdates[key]) ?? currentUser[key] ?? false
        case 'isPublicProfile':
          return isPublicProfile
        case 'showGoingToEvents':
        case 'showPhotosOfMe':
          // These are only enabled if the user has a public profile
          if (!isPublicProfile) return false
          const current = currentUser.profilePermissions?.[key]
          const update = accountUpdates?.profilePermissions?.[key]
          return update ?? current ?? false
      }
    })()
    const onChange = (checked: boolean) => {
      switch (key) {
        case 'smsOptIn':
        case 'hideMeFromGuestLists':
          // these are top-level properties
          setAccountUpdates({...accountUpdates, [key]: checked})
          break
        case 'isPublicProfile':
        case 'showGoingToEvents':
        case 'showPhotosOfMe':
          // these are nested properties
          setAccountUpdates({
            ...accountUpdates,
            profilePermissions: {...accountUpdates?.profilePermissions, [key]: checked},
          })
          break
      }
    }
    return {checked, onChange}
  }

const PrivacySettings = () => {
  const {userId: id, currentUser} = useSessionContext()
  const {refetch: updateCurrentUser} = useFetchAccount(id!)
  const {showToast} = useToast()
  const [accountUpdates, setAccountUpdates] = useState<UpdateAccountSettingsInput | null>(null)
  const {mutateAsync: updateAccountSettings} = useUpdateAccountSettings({
    onSuccess: () => {
      showToast({type: 'success', title: 'Successfully updated account!'})
      updateCurrentUser()
    },
    onError: error => {
      showToast({type: 'error', title: 'Error updating account.', subtitle: error.message})
    },
  })

  const switchOptions = useMemo(
    () => getSwitchOptions(setAccountUpdates, currentUser, accountUpdates),
    [setAccountUpdates, currentUser, accountUpdates],
  )
  useDebounce(
    () => {
      if (accountUpdates) updateAccountSettings(accountUpdates)
    },
    1000,
    !!accountUpdates,
  )

  if (!currentUser) return null
  return (
    <div className='AccountSettings-wrapper'>
      <PoshSwitch title='Public Profile' switchOptions={switchOptions('isPublicProfile')} />
      <PoshSwitch title='Hide Me From Guest Lists' switchOptions={switchOptions('hideMeFromGuestLists')} />
      <PoshSwitch
        title="Show events I'm attending"
        switchOptions={switchOptions('showGoingToEvents')}
        disabled={!switchOptions('isPublicProfile').checked}
      />
      <PoshSwitch title='Opt in for SMS updates' switchOptions={switchOptions('smsOptIn')} />
    </div>
  )
}

export default PrivacySettings
