import React, {useEffect, useRef, useState} from 'react'
import {useInViewport} from 'react-in-viewport'
import {useMediaQuery} from 'react-responsive'

export const SectionWrapper = ({
  children,
  className,
  index,
  setSectionIndex,
  loadOnce,
  loadOnceMobile,
}: {
  children: React.ReactNode
  className?: string
  index?: number
  setSectionIndex: React.Dispatch<React.SetStateAction<number>>
  loadOnce?: boolean
  loadOnceMobile?: boolean
}) => {
  const myRef = useRef(null)
  const {inViewport} = useInViewport(myRef)
  const [loadedOnce, setLoadedOnce] = useState<boolean>(false)
  const isMobile = useMediaQuery({query: `(max-width: 600px)`})
  const [isLoadedOnceMobile, setIsLoadedOnceMobile] = useState<boolean>(false)

  useEffect(() => {
    if (inViewport) {
      setSectionIndex(index ?? 0)
      if (loadOnce) setLoadedOnce(true)
      if (loadOnceMobile && isMobile) setIsLoadedOnceMobile(true)
    }
  }, [inViewport])

  return (
    <div className={`LandingPage-section ${className} LandingPage-section-${index}`}>
      {(inViewport || loadedOnce || isLoadedOnceMobile) && children}
      <div className='LandingPage-section-scrollTarget' ref={myRef} />
    </div>
  )
}

export default SectionWrapper
