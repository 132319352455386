import React, {useRef, useState} from 'react'
import PhoneInput from 'react-phone-number-input'

import {isValidPhoneNumber} from '@posh/utils'
import {useLoginWithPhone} from 'apis/Auth/useLoginWithPhone'
import {useMixpanel} from 'apis/MixPanelHandler'
import CloudflareTurnstile, {TurnstileRef} from 'components/CloudflareTurnstile'
import Button from 'components/form/Button'
import {useToast} from 'components/toasts/ToastProvider'
import {tokenCheckoutFormSchema} from 'domains/LiveCart/LiveCartContext'

import {CreateEventFormDataModalStepProps} from '../util/createEventFormData'

export const CreateEventPhoneEntryForm = (props: CreateEventFormDataModalStepProps) => {
  const {
    createEventFormData: {
      showSignUp,
      userData: {phone},
    },
    setCreateEventFormData,
  } = props
  const {trackEvent} = useMixpanel()
  const {showToast} = useToast()
  const {mutateAsync: sendLoginCode, data: loginWithPhoneRes, isLoading: isSendingCode} = useLoginWithPhone()
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(undefined)
  const captchaRef = useRef<TurnstileRef>(null)

  const handlePhoneCheckClick = async () => {
    try {
      trackEvent('Create Event Enter Phone Continue- Enter Your Phone Modal')
      const validphone = tokenCheckoutFormSchema.validateSyncAt(
        'phone_number',
        {phone_number: phone},
        {abortEarly: false},
      )
      if (!validphone) {
        showToast({type: 'error', title: 'Please input a valid phone number'})
        return
      }
      const {
        success,
        hasAccount: hasExistingAccount,
        needsToConfirmAccount,
      } = await sendLoginCode({phone, cfTurnstileResponse: captchaToken})
      if (success) {
        setCreateEventFormData(u => ({
          ...u,
          hasAccount: true,
          hasExistingAccount,
          needsToConfirmAccount,
          userData: {
            ...u.userData,
            phone,
          },
          loginStep: {type: 'verifySMS', previous: u.loginStep},
        }))
      } else
        showToast({
          type: 'error',
          title:
            'Could not find account associated with phone number. Refresh and login with email or create a new account',
        })
      resetCaptcha()
    } catch (error: any) {
      const validationError = error.inner[0]
      const {message} = validationError
      showToast({type: 'error', title: message})
    }
  }

  const resetCaptcha = () => {
    setCaptchaToken(undefined)
    captchaRef.current?.reset()
  }

  const handleCaptchaToken = (token: string) => {
    setCaptchaToken(token)
  }

  return (
    <div className='CreateEventLogin'>
      <h4>Enter your phone number to launch your event!</h4>
      <PhoneInput
        className='AccountSettings-phoneInput'
        onChange={p => setCreateEventFormData(u => ({...u, userData: {...u.userData, phone: p ?? ''}}))}
        value={phone}
        type={'customer'}
        required={true}
        placeholder={'Phone Number'}
        defaultCountry={'US'}
        disabled={showSignUp}
      />
      {!showSignUp && (
        <>
          <CloudflareTurnstile successCallback={handleCaptchaToken} ref={captchaRef} />
          <Button
            className='createEventGrad'
            disabled={isSendingCode || (!!phone && !isValidPhoneNumber(phone)) || !captchaToken}
            onClick={handlePhoneCheckClick}>
            Continue
          </Button>
        </>
      )}
    </div>
  )
}
