import {ReactQueryOptions, RouterInputs, trpc} from 'lib/trpc'

import {GetAccountOutput} from './useGetAccount'

type UpdateOwnAccountOptions = ReactQueryOptions['accounts']['updateOwnAccount']
export type UpdateOwnAccountInput = RouterInputs['accounts']['updateOwnAccount']

type Response = {
  previousData: GetAccountOutput | undefined
}

export function useUpdateOwnAccount(options?: UpdateOwnAccountOptions) {
  const qc = trpc.useContext()
  return trpc.accounts.updateOwnAccount.useMutation<Response | undefined>({
    ...options,
    onMutate: async input => {
      qc.accounts.getAccount.cancel()
      const previousData = qc.accounts.getAccount.getData()
      qc.accounts.getAccount.setData(undefined, old => {
        const oldData = old as GetAccountOutput
        return {
          ...oldData,
          ...input,
        }
      })
      return {previousData}
    },
    onError: (_, input, ctx) => {
      if (ctx) {
        qc.accounts.getAccount.setData(undefined, ctx.previousData)
      }
    },
    onSettled: () => {
      qc.accounts.getAccount.invalidate()
    },
  })
}
