import React from 'react'

import {useGetPendingEvent} from 'apis/PendingEvent/useGetPendingEvent'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import moment from 'moment-timezone'

import {KeepEditing} from '../components/KeepEditing'

import styles from './styles.module.scss'

export type Event = {
  name: string
  flyer: string
  startUtc: Date
  accentColor: string
  timezone: string
}

export interface LaunchBodyProps {
  pendingEventId: string
  groupId: string
  onConfirm: (status: 'live' | 'draft', shouldNavigateToFinanceSettings: false) => void
  onClose: () => void
}

function EventInfo({event}: {event: Event}) {
  return (
    <div className={styles.eventInfo}>
      <h3 className='center noMargin'>{event.name}</h3>
      <h5 className='center noMargin'>{moment.tz(event.startUtc, event.timezone).format('M/D/YYYY @ h:mmA')}</h5>
    </div>
  )
}

export function LaunchBody({pendingEventId, groupId, onConfirm, onClose}: LaunchBodyProps) {
  const {data: pendingEvent} = useGetPendingEvent({_id: pendingEventId!, groupId}, {enabled: !!pendingEventId})

  if (!pendingEvent) return null

  const pendingEventData: Event = {
    name: pendingEvent.name,
    flyer: pendingEvent.flyer!,
    startUtc: new Date(Date.parse(pendingEvent.startUtc)),
    accentColor: pendingEvent.accentColor,
    timezone: pendingEvent.timezone,
  }

  return (
    <div className={styles.Launch}>
      <div
        className={styles.container}
        style={{
          background: `linear-gradient(120deg, ${pendingEventData.accentColor}40 0%, rgba(255, 255, 255, 0.0) 100%)`,
        }}>
        <h4 className={'center'}>Ready to launch your event?</h4>
        <div className={styles.imageContainer}>
          <PoshImage src={pendingEventData.flyer} alt={`Flyer for ${pendingEventData.name}`} className={styles.image} />
          <EventInfo event={pendingEventData} />
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={() => onConfirm('draft', false)} className='outline noShadow' style={{flex: 1}}>
            Save as draft
          </Button>
          <Button onClick={() => onConfirm('live', false)} className='noShadow goldHover' style={{flex: 1}}>
            Launch
          </Button>
        </div>
        <KeepEditing onClose={onClose} />
      </div>
    </div>
  )
}
