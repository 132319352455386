import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {GroupAccountRoleUnion} from 'apis/Accounts'
import {useFetchAccountGroups} from 'apis/Accounts/useFetchAccountGroups'
import useFetchCommunityEntity from 'apis/Community/useFetchCommunityEntity'
import useCheckIsAffiliate from 'apis/Kickback/useCheckIsAffiliate'
import {useMixpanel} from 'apis/MixPanelHandler'
import getAvi from 'apis/Util/getAvi'
import classNames from 'classnames'
import {Activity, Feed, Ticket} from 'components/assets/Icons'
import {KickbackIcon} from 'components/assets/KickbackIcon'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import {isEmpty} from 'lodash'

import './styles.scss'

declare global {
  interface Window {
    ReactNativeWebView: any
  }
}

const getNonAttendeeRoles = ({groups}: {groups?: GroupAccountRoleUnion[]}) => {
  return groups?.filter(g => g.accountRole.roleKey != 'attendee') ?? []
}

const PoshMobileNav = () => {
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const currentUrl = window.location.pathname

  type Tab = 'explore' | 'orgs' | 'orders' | 'kickback' | 'community' | 'account'
  const [selectedTab, setSelectedTab] = useState<Tab>('account')

  const {currentUser, userId} = useSessionContext()
  const {data: isAnAffiliate} = useCheckIsAffiliate(userId!)

  const {data: accountGroupUnions} = useFetchAccountGroups()

  const {data: communityEntity} = useFetchCommunityEntity()

  const avi = getAvi(currentUser)

  const [isOwnerOrHost, setIsOwnerOrHost] = useState<boolean>(() => {
    return !isEmpty(getNonAttendeeRoles({groups: accountGroupUnions}))
  })
  const [isAttendeeOnly, setIsAttendeeOnly] = useState<boolean>(false)

  useEffect(() => {
    if (accountGroupUnions) {
      if (getNonAttendeeRoles({groups: accountGroupUnions}).length == 0) {
        setIsAttendeeOnly(true)
      } else {
        setIsOwnerOrHost(true)
      }
    }
  }, [accountGroupUnions])

  useEffect(() => {
    if (currentUrl.includes('/dashboard/orgs') || currentUrl.includes('/groups/')) setSelectedTab('orgs')
    else if (currentUrl.includes('/explore')) setSelectedTab('explore')
    else if (currentUrl.includes('/dashboard/orders')) setSelectedTab('orders')
    else if (currentUrl.includes('/dashboard/kickback')) setSelectedTab('kickback')
    else if (currentUrl.includes('/dashboard/community')) setSelectedTab('community')
    else if (currentUrl.includes('/dashboard/account') || currentUrl.includes('/p/')) setSelectedTab('account')
  }, [currentUrl])

  if (!currentUser) return null

  const onClickExploreTab = () => {
    navigate('/explore')
    trackMixpanelEvent('Explore Nav Click- Mobile Web Navigation Bar')
  }

  const onClickOrgsTab = () => {
    navigate('/dashboard/orgs')
    trackMixpanelEvent('Group Overview Click- Mobile Web Navigation Bar')
  }

  const onClickOrdersTab = () => {
    navigate('/dashboard/orders')
    trackMixpanelEvent('My Orders Click- Mobile Web Navigation Bar')
  }

  const onClickKickbackTab = () => {
    navigate('/dashboard/kickback')
    trackMixpanelEvent('Kickback Dashboard Click- Mobile Web Navigation Bar')
  }

  const onClickCommunityTab = () => {
    navigate('/dashboard/community')
  }

  const onClickAccountTab = () => {
    navigate('/dashboard/account')
    trackMixpanelEvent('Profile Settings Click- Mobile Web Navigation Bar')
  }

  if (window.ReactNativeWebView) {
    return null
  }

  return (
    <div className='pMobileNav' onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>
      <div onClick={onClickExploreTab}>
        <Feed className={classNames('pMobileNav-icon', {selected: selectedTab === 'explore'})} />
      </div>
      {isOwnerOrHost && (
        <div onClick={onClickOrgsTab}>
          <Activity className={classNames('pMobileNav-icon', {selected: selectedTab === 'orgs'})} />
        </div>
      )}
      <div onClick={onClickOrdersTab}>
        <Ticket className={classNames('pMobileNav-icon', {selected: selectedTab === 'orders'})} />
      </div>
      {isAnAffiliate && (
        <div onClick={onClickKickbackTab}>
          <KickbackIcon
            size={35}
            primaryColor={selectedTab === 'kickback' ? '#ffffff' : '#7A7B7D'}
            secondaryColor={'black'}
          />
        </div>
      )}
      {communityEntity?.type && (
        <div onClick={onClickCommunityTab}>
          <PoshImage
            src={`https://images.posh.vip/b2/NavBarIcons/Community${
              selectedTab === 'community' ? '' : '-disabled'
            }.svg`}
            className={classNames('pMobileNav-icon', {selected: selectedTab === 'community'})}
          />
        </div>
      )}
      <div onClick={onClickAccountTab}>
        <PoshImage src={avi} className={`pMobileNav-avi ${selectedTab === 'account' && 'selected'}`} />
      </div>
    </div>
  )
}

export default PoshMobileNav
