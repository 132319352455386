import React, {useEffect} from 'react'

import Slider from '@material-ui/core/Slider'
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {MarketFilters} from 'apis/Community'
import {CommunityFiltersResponse} from 'apis/Community/useFetchCommunityFilters'

interface FilterProps {
  filter: CommunityFiltersResponse
  handleMarketSelection: (market: MarketFilters) => void
  market: MarketFilters
  selectedChecks: string[]
  setSelectedChecks: React.Dispatch<React.SetStateAction<string[]>>
  range: number[]
  setRange: React.Dispatch<React.SetStateAction<number[]>>
}

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: '#fc0',
      },
      valueLabel: {
        color: '#fc0',
      },
      track: {
        color: '#fc0',
      },
      rail: {
        color: '#505153',
      },
    },
  },
})

const Filter = (props: FilterProps) => {
  const {filter, handleMarketSelection, market, selectedChecks, setSelectedChecks, range, setRange} = props
  const [localRange, setLocalRange] = React.useState<number[]>(range)

  useEffect(() => {
    const delayedDebounce = setTimeout(() => {
      if (localRange[1] !== 0) setRange(localRange)
    }, 1000)
    return () => clearTimeout(delayedDebounce)
  }, [localRange])

  useEffect(() => {
    setLocalRange(range)
  }, [range])

  const handleRangeChange = (newValue: number | number[]) => {
    if (!Array.isArray(newValue)) return
    setLocalRange(newValue as number[])
  }

  const handleSelectedChecks = (value: string) => {
    if (selectedChecks.includes(value)) {
      setSelectedChecks(selectedChecks.filter(item => item !== value))
    } else {
      setSelectedChecks([...selectedChecks, value])
    }
  }

  return (
    <>
      {filter.type === 'select' && (
        <div className='CommunitySearchWindow-filter-section'>
          <h4>{filter.label}</h4>
          <div className='CommunitySearchWindow-filter-tags'>
            {filter.values?.map(t => (
              <div
                className={`CommunitySearchWindow-filter-tag ${t == market ? 'selected' : ''}`}
                onClick={() => handleMarketSelection(t as MarketFilters)}
                key={t}>
                <span>{t}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {filter.type === 'checkbox' && (
        <div className='CommunitySearchWindow-filter-section'>
          <h4>{filter.label}</h4>
          <div className='CommunitySearchWindow-filter-tags'>
            {filter.values?.map(t => (
              <div
                onClick={() => {
                  handleSelectedChecks(t)
                }}
                className={`CommunitySearchWindow-filter-tag ${selectedChecks.includes(t) ? 'selected' : ''}`}
                key={t}>
                <input type='checkbox' className={`${selectedChecks.includes(t) ? 'selected' : ''}`} />
                <span>{t}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {filter.type === 'range' && (
        <div className='CommunitySearchWindow-filter-section'>
          <h4>{filter.label}</h4>
          <div className='CommunitySearchWindow-filter-tags'>
            <MuiThemeProvider theme={muiTheme}>
              <Slider
                value={localRange}
                onChange={(e, v) => handleRangeChange(v)}
                valueLabelFormat={value => `$${value.toLocaleString('en-US')}`}
                valueLabelDisplay='on'
                max={filter.defaultMax ?? 0}
                min={0}
              />
            </MuiThemeProvider>
          </div>
        </div>
      )}
    </>
  )
}

export default Filter
