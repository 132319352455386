import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import {AccountDomain} from 'apis/Roles/AccountDomain'
import CopyBlock from 'components/CopyBlock'
import Button from 'components/form/Button'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import useSessionContext from 'domains/Auth/SessionContext'
import {EventStatus} from 'pages/GroupPage/EventLaunch'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import {usePageParams} from '../../PoshAppLayout'
import {useEventManagementContext} from '../EventManagementContext'
import {generateTrackingLink} from '../Overview'
import {AttendeesSection} from './Analytics/Attendees/Attendees'
import {OrdersSection} from './Analytics/Orders/OrdersSection'
import {TimeSeriesSection} from './Analytics/Overview/TimeSeriesSection'
import {Text} from './Analytics/Shared/Text/Text'
import {Summary} from './Analytics/Summary/Summary'
import {TicketsSection} from './Analytics/Tickets/Tickets'

import styles from './Overview.module.scss'

export const OverviewUpdated = ({isLoading}: {isLoading: boolean}) => {
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {currentUser} = useSessionContext()
  const {domain} = usePageParams()

  // Yay! We have both the event and group objects
  const {event, group} = useEventManagementContext()
  const eventId = event._id
  const groupId = group._id
  const eventStatus = event.status as EventStatus
  const eventLinks = [`https://posh.vip/e/${event.url}`, `https://posh.vip/f/${event.hexUrl}`]
  const isHost = domain == AccountDomain.HOST
  if (isHost) {
    eventLinks.unshift(
      generateTrackingLink({
        eventUrl: event.url,
        eventHex: event.hexUrl,
        trackingLink: currentUser?.trackingLink ?? '',
      }),
    )
  }

  const navigateToEventPage = () => {
    // Navigate to the event page
    navigate(`/e/${event.url}`)
  }

  const onCopyLink = () => {
    trackMixpanelEvent('Copy Link- Curator Event Overview Page', {eventStatus})
  }

  const onSwapLink = () => {
    trackMixpanelEvent('Shortlink Click- Curator Event Overview Page', {eventStatus})
  }

  if (!eventId || !groupId) {
    return <PageNotFound />
  }

  const isRSVPEvent = event.isRSVPEvent

  return (
    <>
      <PoshHelmet title={`Overview - `} />
      <div className={styles.Overview}>
        <div className={styles.OverviewHeader}>
          <Text size='xl' bold>
            {`${event.name} Dashboard`}
          </Text>
          <div className={styles.OverviewHeaderButtons}>
            <CopyBlock links={eventLinks} onCopy={onCopyLink} onSwapLink={onSwapLink} />
            <Button className='gold' onClick={navigateToEventPage}>
              View Event Page
            </Button>
          </div>
        </div>
        <hr />
        <Summary eventId={eventId} groupId={groupId} isLoading={isLoading} />
        <div className={styles.OverviewOrdersSection}>
          <TimeSeriesSection eventId={eventId} groupId={groupId} groupCurrency={group.currency} isLoading={isLoading} />
          {/** <OrdersSection /> handles the copy and display logic for RSVP events as well. */}
          <OrdersSection eventId={eventId} groupId={groupId} isRSVPEvent={isRSVPEvent} isLoading={isLoading} />
        </div>
        <hr />
        <TicketsSection eventId={eventId} groupId={groupId} isRSVPEvent={isRSVPEvent} isLoading={isLoading} />
        <AttendeesSection eventId={eventId} groupId={groupId} isRSVPEvent={isRSVPEvent} isLoading={isLoading} />
      </div>
    </>
  )
}
