import React, {useState} from 'react'

import {getAccountFinancialCapabilityStatus, parseStripeOnboardingRequirements} from '@posh/model-types'
import {CountryCode} from '@posh/types'
import {useToast} from 'components/toasts/ToastProvider'
import {trpc} from 'lib/trpc'

import {AccountStatusActionButton} from './AccountStatusActionButton'
import {AccountStatusRow} from './AccountStatusRow'
import {FinanceCard} from './PayoutCards/FinanceCard'

import styles from './AccountStatusCard.module.scss'

export type AccountStatusCardProps = {
  canAcceptPayments: boolean
  canInitiatePayouts: boolean
  needsStripeOnboarding: boolean
  currentOnboardingRequirements?: string[]
  pendingDocumentsVerification: boolean
  hasBankAccount: boolean
  createdAt: Date
}

export const AccountStatusCard = ({
  canAcceptPayments,
  canInitiatePayouts,
  hasBankAccount,
  needsStripeOnboarding,
  pendingDocumentsVerification,
  currentOnboardingRequirements,
  createdAt,
  groupId,
  groupCountry,
  onClickAddBankAccount,
}: AccountStatusCardProps & {groupId: string; onClickAddBankAccount: () => void; groupCountry: CountryCode}) => {
  const {sellTicketsStatus, payoutsStatus} = getAccountFinancialCapabilityStatus({
    canAcceptPayments,
    canInitiatePayouts,
    needsStripeOnboarding,
    pendingDocumentsVerification,
    hasBankAccount,
    createdAt,
  })

  const {showToast} = useToast()
  const {mutateAsync: createLink, isLoading} = trpc.groups.createStripeOnboardingLink.useMutation({
    onError: error => {
      showToast({type: 'error', title: 'An error occurred', subtitle: error.message})
      setIsRedirecting(false)
    },
  })
  const [isRedirecting, setIsRedirecting] = useState(false)
  const onClickSubmitInfo = async () => {
    setIsRedirecting(true)
    const stripeSetupLink = await createLink({groupId, redirectUrl: document.location.pathname}).catch()
    if (stripeSetupLink) {
      window.location.href = stripeSetupLink
    }
  }

  const [viewingListOfRequirements, setViewingListOfRequirements] = useState(false)

  return (
    <FinanceCard title='Account Status'>
      <div className={styles.AccountStatusContainer}>
        {/*TODO: get currently due deadline to display here as well*/}
        <AccountStatusRow capability='Sell Tickets' capabilityStatus={sellTicketsStatus} />
        <AccountStatusRow capability='Transfer Balance' capabilityStatus={payoutsStatus} />
        {needsStripeOnboarding && (
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <p
              onClick={() => setViewingListOfRequirements(!viewingListOfRequirements)}
              className='noMargin'
              style={{textDecoration: 'underline', cursor: 'pointer', alignSelf: 'center'}}>{`${
              viewingListOfRequirements ? 'Hide' : 'View'
            } Info Requirements`}</p>
            {viewingListOfRequirements && (
              <div className={styles.OnboardingRequested_requirementsList}>
                <ul style={{margin: '0'}}>
                  {parseStripeOnboardingRequirements(currentOnboardingRequirements, groupCountry)
                    ?.sort((a, b) => a.length - b.length)
                    .map(r => {
                      return <li key={r}>{r}</li>
                    })}
                </ul>
              </div>
            )}
          </div>
        )}
        <AccountStatusActionButton
          sellTicketsStatus={sellTicketsStatus}
          payoutsStatus={payoutsStatus}
          onClickAddBankAccount={onClickAddBankAccount}
          onClickSubmitInfo={onClickSubmitInfo}
          isLoading={isLoading || isRedirecting}
        />
      </div>
    </FinanceCard>
  )
}
