// Brought to you by the mobile team
const endingsNeedingEs = ['s', 'x', 'z', 'ch', 'sh']
/**
 *
 * @description Pluralizes a word based on a count. Takes into account the word's ending to detemine if it should end with `s` or `es`.
 * @param word The __singular__ word to pluralize
 * @param count The value determining whether the word should be pluralized (e.g, 1 - singular, othwerwise plural)
 * @param customPlural If the word should be pluralized to something other than (word + 's' or word + 'es'), pass it here (e.g, 'children' or 'sheep')
 * @returns the pluralized word
 */
export default function pluralize(word: string, count: number, customPlural?: string) {
  if (Math.abs(count) === 1) {
    return word
  }
  if (customPlural) {
    return customPlural
  }
  if (endingsNeedingEs.some(ending => word.endsWith(ending))) {
    return `${word}es`
  }
  return `${word}s`
}
