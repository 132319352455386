import React from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import {BaseLineupItem, LineupItemProps} from './BaseLineupItem'
import {EditableLineupItem} from './EditableLineupItem'

export function LineupItem(props: LineupItemProps) {
  return <BaseLineupItem {...props} editable={false} />
}

LineupItem.Editable = EditableLineupItem
LineupItem.Editing = EditableLineupItem.Editing
