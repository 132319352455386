import React from 'react'

import CatererServiceImage from 'assets/images/caterer-service.png'
import DJPerformerServiceImage from 'assets/images/dj-performer-service.png'
import PhotographyServiceImage from 'assets/images/photography-service.png'
import SecurityServiceImage from 'assets/images/security-service.png'
import VideographerServiceImage from 'assets/images/videographer-service.png'

import {Service, ServiceProps} from './Service'

import './Services.styles.scss'

const SERVICES: Omit<ServiceProps, 'pending' | 'setActive'>[] = [
  {
    title: 'photographer',
    path: 'photographer',
    description: 'Grow your brand by providing your attendees with professional photos.',
    recommended: true,
    active: true,
    image: PhotographyServiceImage,
  },
  {
    title: 'dj / performer',
    path: 'performer',
    description: 'Turn up the music with a live performance act.',
    image: DJPerformerServiceImage,
  },
  {
    title: 'security',
    path: 'security',
    description: 'Ensure the safety of your attendees with a professionally-certified security guard.',
    image: SecurityServiceImage,
  },
  {
    title: 'videographer',
    path: 'videographer',
    description: 'Prepare for your next event and expand your brand with a professional aftermovie of your event.',
    image: VideographerServiceImage,
  },
  {
    title: 'caterer',
    path: 'caterer',
    description: 'Take your experience to the next level by serving world-class food and drinks.',
    image: CatererServiceImage,
  },
]

interface ServicesProps {
  pending: boolean
  onChangeActive?: (title: string) => void
}

export const Services = (props: ServicesProps) => {
  const {pending, onChangeActive} = props
  return (
    <>
      <h4>Select a professional you&apos;d like to book...</h4>

      <div className='ServicesContainer'>
        {SERVICES.map(serviceProps => {
          return (
            <Service key={serviceProps.title} {...serviceProps} pending={pending} onChangeActive={onChangeActive} />
          )
        })}
      </div>
    </>
  )
}
