import {useEffect, useRef, useState} from 'react'

import {useDimensions} from 'hooks/useDimensions'

const DEFAULT = 0.25

export const useGetKickbackButtonPosition = ({
  isCartOverlayOpen,
  isCartBreakdownExpanded,
  numberOfItems,
}: {
  isCartOverlayOpen: boolean
  isCartBreakdownExpanded: boolean
  numberOfItems: number
}) => {
  const cartOverlayRef = useRef<HTMLDivElement>(null)
  const [cartOverlayHeight, setCartOverlayHeight] = useState(0)
  const {width, height} = useDimensions()

  useEffect(() => {
    setCartOverlayHeight(cartOverlayRef?.current?.clientHeight ?? 0)
  }, [cartOverlayRef, isCartOverlayOpen, isCartBreakdownExpanded, numberOfItems])

  const defaultHeight = height * DEFAULT
  const shouldAdjust = cartOverlayHeight && isCartOverlayOpen && defaultHeight < cartOverlayHeight
  return {cartOverlayRef, position: {bottom: shouldAdjust ? cartOverlayHeight : defaultHeight}}
}
